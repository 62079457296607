import React, { useState, useEffect } from "react";
import { Checkbox } from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";
import { css, StyleSheet } from "@workpoint/components/lib/helpers/aphroditeWithExtensions";
import { useIntl } from "react-intl";
import {
  INotificationType,
  INotificationTypeOptions
} from "../../../../models/NotificationSettings";
import { globalSelector } from "../../../../store/globalReducer";
import { useAppSelector } from "../../../../store/hooks";
import { useApiClient } from "@workpoint/components/lib/clients/ApiProvider";

const styles = StyleSheet.create({
  list: {
    marginTop: "10px",
    marginBottom: "10px",
    listStyle: "none",
    padding: 0
  },
  listLoading: {
    minHeight: 80,
    opacity: 0.6,

    ":after": {
      content: "''",
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundImage: "url(https://workpoint.azureedge.net/Images/gears_anv4.gif)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundColor: "transparent"
    }
  },
  listHeader: {
    display: "flex",
    flexDirection: "row",
    listStyle: "none",
    padding: 0,
    height: "40px",
    margin: 0,
    alignItems: "center",
    color: "#605e5c",
    fontWeigt: 500
  },
  listHeaderName: {},
  listHeaderEnable: {},
  listHeaderEmail: {},
  listHeaderTeams: {},
  listHeaderLocked: {},
  listItem: {
    display: "flex",
    flexDirection: "column",
    listStyle: "none",
    padding: 0
  },
  listRow: {
    display: "flex",
    flexDirection: "row",
    listStyle: "none",
    padding: 0,
    height: "40px",
    margin: "0px 0px 2px 0px",
    alignItems: "center",
    backgroundColor: "#fff"
  },
  listRowLocked: {
    display: "flex",
    flexDirection: "row",
    listStyle: "none",
    padding: 0,
    height: "40px",
    margin: "0px 0px 2px 0px",
    alignItems: "center",
    backgroundColor: "#faf9f7"
  },
  listColumn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    margin: 0,
    padding: 0
  },
  listColumnExpander: {
    flexGrow: 0,
    width: "30px",
    margin: 0,
    padding: 0
  },
  listExpander: {
    display: "flex",
    background: "none",
    border: "none",
    color: "#605e5c",
    height: "40px",
    width: "30px",
    justifyContent: "center",
    alignItems: "center",
    cursor: "default",
    padding: "none",
    fontSize: "12px",
    outline: "transparent",
    position: "relative"
  },
  listColumnName: {
    margin: 0,
    padding: 0,
    maxWidth: "60%",
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-start",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "50px",
    overflow: "hidden"
  },
  listColumnNameGroupNumber: {
    textIndent: "3px"
  },
  listColumnCheckbox: {
    flexGrow: 1,
    maxWidth: "10%",
    minWidth: "50px",
    margin: 0,
    padding: 0
  },
  listColumnLocked: {
    flexGrow: 1,
    maxWidth: "10%",
    minWidth: "50px",
    margin: 0,
    padding: 0
  },
  listChildren: {
    margin: 0,
    padding: 0,
    height: "auto",
    listStyle: "none",
    display: "none"
  },
  listChildrenExpanded: {
    display: "block"
  },
  saveButton: {
    alignSelf: "flex-end"
  }
});

interface IRecursiveNotificationType extends INotificationType {
  Children?: IRecursiveNotificationType[];
}

interface IStringDict<T> {
  [key: string]: T;
}

interface IListProps {
  items: IRecursiveNotificationType[];
  update: (update: INotificationType, changeProp: INotificationTypeOptions) => void;
  columnNames: IStringDict<string>;
}

interface IListItemProps {
  setting: IRecursiveNotificationType;
  update: (update: INotificationType, changeProp: INotificationTypeOptions) => void;
  itemsExpanded: string[];
  toggleItemExpanded(item: string): void;
}

interface INotificationTypeListProps {
  notificationTypes: INotificationType[];
  changeCallback(notificationTypes: INotificationType[]): void;
}

const combineNotificationTypes = (
  settings: INotificationType[],
  terms: INotificationType[]
): INotificationType[] => {
  const combinedNotificationTypes = terms.map((t) => {
    const matchingSetting = settings
      .filter((s) => {
        if (s.ManagedMetadataId) {
          return t.ManagedMetadataId === s.ManagedMetadataId;
        } else {
          return t.ManagedMetadataName === s.ManagedMetadataName;
        }
      })
      .pop();

    if (matchingSetting)
      return {
        ...matchingSetting,
        ManagedMetadataName: t.ManagedMetadataName,
        ParentManagedMetadataName: t.ParentManagedMetadataName,
        ManagedMetadataId: t.ManagedMetadataId,
        ParentManagedMetadataId: t.ParentManagedMetadataId
      };

    return { ...t };
  });

  return combinedNotificationTypes;
};

export const NotificationTypeList: React.FC<INotificationTypeListProps> = ({
  notificationTypes,
  changeCallback
}): JSX.Element => {
  const { spHostUrl } = useAppSelector(globalSelector);
  const [items, setItems] = useState<IRecursiveNotificationType[]>([]);
  const [terms, setTerms] = useState<any[]>([]);
  const [settings, setSettings] = useState<INotificationType[]>([]);
  const [termsLoaded, setTermsLoaded] = useState<boolean>(false);
  const intl = useIntl();
  const { apiClient } = useApiClient();

  const columnNames = {
    Enable: intl.formatMessage({
      id: "enable",
      defaultMessage: "Enable"
    }),
    Email: intl.formatMessage({
      id: "email",
      defaultMessage: "Email"
    }),
    Teams: intl.formatMessage({
      id: "teams",
      defaultMessage: "Teams"
    }),
    Locked: intl.formatMessage({
      id: "locked",
      defaultMessage: "Locked"
    })
  };

  const loadTerms = async () => {
    const terms = await apiClient.getWP(`/Notifications/Terms`);
    setTerms(terms);
    setTermsLoaded(true);
  };

  useEffect(() => {
    if (!termsLoaded) loadTerms();
  }, []);

  const initializeList = () => {
    const combinedNotificationTypes = combineNotificationTypes(notificationTypes, terms);
    if (notificationTypes.length > 0 && items.length < 1) {
      /**
       * This ensures any changes to the terms in the term store are included when the Notification Profile is modified.
       */
      changeCallback(combinedNotificationTypes);
    }
    setSettings(combinedNotificationTypes);
    setItems(createList(combinedNotificationTypes));
  };

  // Initial run
  useEffect(() => {
    if (termsLoaded && items.length < 1) initializeList();
  }, [termsLoaded, items]);

  // Notification type
  useEffect(() => {
    if (notificationTypes && items.length > 0) initializeList();
  }, [notificationTypes]);

  /**
   * Update the values when stuff changes
   */
  const update = (update: INotificationType, changeProp: INotificationTypeOptions): void => {
    const elementChangedIndex = settings.findIndex(
      (u) => u && u.ManagedMetadataId === update.ManagedMetadataId
    );

    settings[elementChangedIndex] = update;

    /**
     * Iterate possible children
     */
    const potentialParent =
      settings[elementChangedIndex].ManagedMetadataId ??
      settings[elementChangedIndex].ManagedMetadataName;

    _childIterator(potentialParent, changeProp, update[changeProp] as boolean, settings);

    changeCallback(settings);
  };

  return items ? (
    <List items={items} update={update} columnNames={columnNames} />
  ) : (
    <div className={css(styles.listLoading)}></div>
  );
};

const _childIterator = (
  parent: string,
  propName: INotificationTypeOptions,
  propValue: boolean,
  collection: INotificationType[]
) => {
  collection.forEach((notificationType: INotificationType) => {
    if (
      notificationType.ParentManagedMetadataId
        ? notificationType.ParentManagedMetadataId === parent
        : notificationType.ParentManagedMetadataName === parent
    ) {
      notificationType[propName] = propValue;
      _childIterator(
        notificationType.ManagedMetadataId ?? notificationType.ManagedMetadataName,
        propName,
        propValue,
        collection
      );
    }
  });
};

const List: React.FC<IListProps> = ({ items, update, columnNames }): JSX.Element => {
  const [itemsExpanded, setItemsExpanded] = useState<string[]>([]);

  const toggleItemExpanded = (item: string) => {
    let items = new Set(itemsExpanded);
    if (items.has(item)) items.delete(item);
    else items.add(item);

    setItemsExpanded(Array.from(items));
  };

  return (
    <ul className={css(styles.list)}>
      <li>
        <ul className={css(styles.listHeader)}>
          <li className={[css(styles.listColumn), css(styles.listColumnName)].join(" ")}></li>
          <li className={[css(styles.listColumn), css(styles.listColumnCheckbox)].join(" ")}>
            {columnNames.Enable}
          </li>
          <li className={[css(styles.listColumn), css(styles.listColumnCheckbox)].join(" ")}>
            {columnNames.Email}
          </li>
          <li className={[css(styles.listColumn), css(styles.listColumnCheckbox)].join(" ")}>
            {columnNames.Teams}
          </li>
          <li className={[css(styles.listColumn), css(styles.listColumnLocked)].join(" ")}>
            {columnNames.Locked}
          </li>
        </ul>
      </li>
      {items.map((item, index) => (
        <ListItem
          itemsExpanded={itemsExpanded}
          toggleItemExpanded={toggleItemExpanded}
          key={index}
          setting={item}
          update={update}
        />
      ))}
    </ul>
  );
};

const ListItem: React.FC<IListItemProps> = ({
  setting,
  update,
  itemsExpanded,
  toggleItemExpanded
}): JSX.Element => {
  const expanded = itemsExpanded.indexOf(setting.ManagedMetadataName) !== -1;

  const onChange = (ev: any, defaultChecked: any): void => {
    switch (ev.currentTarget.title) {
      case "enable":
        update(
          {
            ...setting,
            Enable: defaultChecked
          },
          "Enable"
        );
        break;
      case "enableEmail":
        update(
          {
            ...setting,
            EnableEmail: defaultChecked
          },
          "EnableEmail"
        );
        break;
      case "enableTeams":
        update(
          {
            ...setting,
            EnableTeams: defaultChecked
          },
          "EnableTeams"
        );
        break;
      case "locked":
        update(
          {
            ...setting,
            IsLocked: defaultChecked
          },
          "IsLocked"
        );
        break;
    }
  };

  return (
    <li className={css(styles.listItem)}>
      <ul className={!setting.IsLocked ? css(styles.listRow) : css(styles.listRowLocked)}>
        {setting.Children ? (
          <li className={[css(styles.listColumn), css(styles.listColumnExpander)].join(" ")}>
            <div
              className={css(styles.listExpander)}
              onClick={() => toggleItemExpanded(setting.ManagedMetadataName)}
            >
              <Icon iconName={expanded ? "ChevronDownMed" : "ChevronRightMed"} />
            </div>
          </li>
        ) : (
          <li className={[css(styles.listColumn, styles.listColumnExpander)].join(" ")}></li>
        )}
        <li className={[css(styles.listColumn), css(styles.listColumnName)].join(" ")}>
          {setting.ManagedMetadataName}
          {setting.Children && (
            <span className={css(styles.listColumnNameGroupNumber)}>
              ({setting.Children.length})
            </span>
          )}
        </li>
        <li className={[css(styles.listColumn), css(styles.listColumnCheckbox)].join(" ")}>
          <Checkbox
            title="enable"
            checked={setting.Enable}
            styles={{
              checkbox: {
                marginRight: "0px"
              }
            }}
            onChange={onChange}
          />
        </li>
        <li className={[css(styles.listColumn), css(styles.listColumnCheckbox)].join(" ")}>
          <Checkbox
            title="enableEmail"
            checked={setting.EnableEmail}
            styles={{
              checkbox: {
                marginRight: "0px"
              }
            }}
            onChange={onChange}
          />
        </li>
        <li className={[css(styles.listColumn), css(styles.listColumnCheckbox)].join(" ")}>
          <Checkbox
            title="enableTeams"
            checked={setting.EnableTeams}
            styles={{
              checkbox: {
                marginRight: "0px"
              }
            }}
            onChange={onChange}
          />
        </li>
        <li className={[css(styles.listColumn), css(styles.listColumnLocked)].join(" ")}>
          <Checkbox
            title="locked"
            checked={setting.IsLocked}
            styles={{
              checkbox: {
                marginRight: "0px"
              }
            }}
            onChange={onChange}
          />
        </li>
      </ul>
      {setting.Children && expanded && (
        <ul
          className={[css(styles.listChildren), css(expanded && styles.listChildrenExpanded)].join(
            " "
          )}
        >
          {setting.Children.map((_setting, index) => (
            <ListItem
              key={`${_setting.ManagedMetadataName}-${index}`}
              setting={_setting}
              update={update}
              itemsExpanded={itemsExpanded}
              toggleItemExpanded={toggleItemExpanded}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

function createList(settings: INotificationType[]): IRecursiveNotificationType[] {
  const list: IRecursiveNotificationType[] = [];

  settings.map((setting, index) => {
    const children: IRecursiveNotificationType[] = [];
    if (setting.ParentManagedMetadataName === null) {
      settings.map((_setting, _index) => {
        if (_setting.ParentManagedMetadataName === setting.ManagedMetadataName) {
          children.push({
            ManagedMetadataName: _setting.ManagedMetadataName,
            ManagedMetadataId: _setting.ManagedMetadataId,
            ParentManagedMetadataName: _setting.ParentManagedMetadataName,
            ParentManagedMetadataId: _setting.ParentManagedMetadataId,
            Enable: _setting.Enable,
            EnableEmail: _setting.EnableEmail,
            EnableTeams: _setting.EnableTeams,
            IsLocked: _setting.IsLocked
          });
        }
      });
      list.push({
        ManagedMetadataName: setting.ManagedMetadataName,
        ManagedMetadataId: setting.ManagedMetadataId,
        ParentManagedMetadataName: setting.ParentManagedMetadataName,
        ParentManagedMetadataId: setting.ParentManagedMetadataId,
        Enable: setting.Enable,
        EnableEmail: setting.EnableEmail,
        EnableTeams: setting.EnableTeams,
        IsLocked: setting.IsLocked,
        Children: children && children.length > 0 ? children : undefined
      });
    }
  });

  return list;
}
