import { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { AppRoutes } from "../routes";
import {
  ErrorBoundary,
  ErrorBoundaryProps
} from "@workpoint/components/lib/components/common/ErrorBoundary";
import { Dialog } from "./common/Dialog";
import { useIntl } from "react-intl";
import { trackPageView } from "@workpoint/components/lib/helpers/insights";
import { AuthenticatedApp } from "./admin";
import { Unauthorized } from "./common/Unauthorized";
import { LoggedOut } from "./common/LoggedOut";

initializeIcons();

const AppBoundary = (props: ErrorBoundaryProps) => {
  const navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    trackPageView();
  }, [location]);

  return (
    <ErrorBoundary
      {...props}
      close={() => {
        navigate(-1);
      }}
    >
      {props.children}
    </ErrorBoundary>
  );
};

export function App() {
  const intl: any = useIntl();

  return (
    <BrowserRouter>
      <Dialog />
      <AppBoundary intl={intl}>
        <Routes>
          <Route path={"/*"} element={<AuthenticatedApp />} />
          <Route path={AppRoutes.Unauthorized} element={<Unauthorized />} />
          <Route path={AppRoutes.LoggedOut} element={<LoggedOut />} />
        </Routes>
      </AppBoundary>
    </BrowserRouter>
  );
}
