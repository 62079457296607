import { useNavigate, useParams } from "react-router-dom";
import { ProcessStepConfiguration } from "@workpoint/components/lib/models/ProcessConfiguration";
import { ToRoute } from "../../../../routes";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { processSelector, updateConfiguration } from "../../../../store/processReducer";
import { RowType } from "../../../../utils/processUtils";
import { ListBase } from "./ListBase";

export const StepList = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { process } = useAppSelector(processSelector);

  const _updateSteps = (steps: ProcessStepConfiguration[]) => {
    const newConfig = { ...process.configuration, steps };
    dispatch(updateConfiguration(newConfig));
  };

  const _openNewStepPanel = (index: string) => {
    navigate(ToRoute.processNewStep(index));
  };

  const _editStep = (index: string) => {
    navigate(ToRoute.processStep(process, index));
  };

  return (
    <ListBase
      items={process?.configuration?.steps ?? []}
      updateItems={_updateSteps}
      openNewItemPanel={_openNewStepPanel}
      editItem={_editStep}
      rowType={RowType.Step}
    />
  );
};

