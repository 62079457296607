import React, { FC } from "react";
import { Control, Controller, UseControllerProps } from "react-hook-form";
import { DatePicker, IDatePickerProps } from "@fluentui/react";
import "./datePickerFix.css";

export interface FormControlProps {
  control: Control<any>;
  name: string;
  rules?: UseControllerProps["rules"];
  defaultValue?: any;
}

export const ControlledDatePicker: FC<FormControlProps & IDatePickerProps> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || ""}
      render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => (
        <DatePicker
          {...props}
          onSelectDate={(date?: Date | null) => {
            onChange(date);
            if (props.onSelectDate) props.onSelectDate(date);
          }}
          value={value instanceof Date ? value : new Date(value)}
          onBlur={onBlur}
        />
      )}
    />
  );
};
