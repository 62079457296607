import { Stack, Image, ImageFit, Text, IStackStyles } from "@fluentui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import WorkPointTheme from "@workpoint/components/lib/constants";
import { globalSelector } from "../../store/globalReducer";
import { useAppSelector } from "../../store/hooks";
import { renderStatusIcon } from "./TileCard";

export interface IQuickLinkProps {
  title: string;
  link: string;
  imageUrl: string;
  view: "compact" | "card";
  status: any;
  linksPerRow?: number;
}

export const QuickLink = (props: IQuickLinkProps) => {
  const navigate = useNavigate();
  const { spHostUrl } = useAppSelector(globalSelector);

  const LinksPerRow = props.linksPerRow ?? 3;

  const cardHeader: Partial<IStackStyles> = {
    root: {
      height: `110px`,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: `${WorkPointTheme.palette.themeLighter}`,
      cursor: "pointer",
      padding: "6px"
    }
  };

  const cardContent: Partial<IStackStyles> = {
    root: {
      height: `44px`,
      alignItems: "center"
    }
  };

  const QuickLinkContainer = styled(Stack)`
    width: ${(100 - 2 * LinksPerRow) / LinksPerRow}%;
    min-width: 200px;
    align-items: center;
  `;

  return (
    <QuickLinkContainer horizontal>
      {props.view === "card" ? (
        <>
          <Stack style={{ width: "100%", boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.1)" }}>
            <Stack
              onClick={() => {
                navigate({
                  pathname: props.link,
                  search: `SPHostUrl=${spHostUrl}`
                });
              }}
              styles={cardHeader}
              tokens={{ childrenGap: 6 }}
            >
              <Image imageFit={ImageFit.cover} src={props.imageUrl} width={40} height={40} />
              <Text
                title={props.title}
                variant={"large"}
                style={{
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  fontWeight: "600"
                }}
              >
                {props.title}
              </Text>
            </Stack>
            <Stack
              styles={cardContent}
              horizontal
              tokens={{ childrenGap: 8, padding: 8 }}
              style={{ alignItems: "center" }}
            >
              {renderStatusIcon(props.status)}
              <Text variant={"smallPlus"}>{props.status}</Text>
            </Stack>
          </Stack>
        </>
      ) : (
        <Stack
          onClick={() => {
            navigate({
              pathname: props.link,
              search: `SPHostUrl=${spHostUrl}`
            });
          }}
          horizontal
          // style={{ width: "100%", cursor: "pointer" }}
          styles={quickLinkStyles}
          tokens={{ childrenGap: 6 }}
        >
          <Image imageFit={ImageFit.cover} src={props.imageUrl} width={18} height={18} />
          <Text
            variant={"medium"}
            title={props.title}
            style={{
              textOverflow: "ellipsis",
              maxWidth: "calc(100% - 50px)",
              overflow: "hidden",
              whiteSpace: "nowrap"
            }}
          >
            {props.title}
          </Text>
        </Stack>
      )}
    </QuickLinkContainer>
  );
};

const quickLinkStyles: Partial<IStackStyles> = {
  root: {
    width: "100%",
    cursor: "pointer",
    height: "20px"
  }
};
