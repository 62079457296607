import { cloneDeep, set } from "lodash";
import { OpenAPIV2 } from "openapi-types";
import { useEffect, useState } from "react";
import { Control, FieldValues, UseFormSetValue, UseFormTrigger } from "react-hook-form";
import { AdminParameterConfiguration } from "@workpoint/components/lib/models/AdminConfiguration";
import { ProcessValidationMessage } from "../../../models/ProcessValidation";
import { adminSelector } from "../../../store/adminReducer";
import { useAppSelector } from "../../../store/hooks";
import { getCustomizedControlParams } from "../../../utils/adminUtils";
import { ParametersForm } from "../../common/parametersForm/ParametersForm";
import { PageProps } from "../ConfigurePageControl";
import { TestHttpEndpoint } from "../TestHttpEndpoint";

interface AdminParametersControlProps extends PageProps {
  close(): any;
  update(data: any, context?: any, closePanel?: boolean): ProcessValidationMessage[];
}

export const AdminParametersForm = (props: AdminParametersControlProps) => {
  const { document, pageItem } = useAppSelector(adminSelector);
  const [definition, setDefinition] = useState<OpenAPIV2.OperationObject>();

  useEffect(() => {
    /**
     * Payload mapper for get operations
     */
    if (
      (
        (props.operation.definition.responses["200"] as OpenAPIV2.ResponseObject)
          ?.schema as OpenAPIV2.SchemaObject
      )?.properties
    ) {
      const defi: OpenAPIV2.OperationObject = cloneDeep(props.operation.definition);
      if (!defi.parameters) {
        defi.parameters = [];
      }
      const properties = (
        (defi.responses["200"] as OpenAPIV2.ResponseObject).schema as OpenAPIV2.SchemaObject
      ).properties;
      if (properties) {
        defi.parameters!.push({
          name: "response",
          in: "body",
          ...defi.responses["200"]
        } as any);
      }

      setDefinition(defi);
    }
  }, [props.operation]);

  const _getDefaultFormData = (configuration?: any) => {
    const fieldValues: any = {};
    if (configuration) {
      configuration.parameters?.forEach((p: AdminParameterConfiguration) => {
        set(fieldValues, p.name, p);
        // let fieldValue: any = {};
        // set(fieldValues, (p.parent ? p.parent + "." : "") + p.name, fieldValue);
        // fieldValue["type"] = p.type;
        // fieldValue["dataType"] = p.dataType;
        // fieldValue["parent"] = p.parent;
        // if (!Array.isArray(p.value?.data)) {
        //   fieldValue[ProcessParameterProperty.Value] = merge(defaultExpression(), p.value);
        // } else {
        //   fieldValue[ProcessParameterProperty.Value] = p.value;
        // }
      });
    }
    return fieldValues;
  };

  const contextInit = (fieldValues: Record<string, any>, selectedPivot?: string) => {
    // dispatch(startLoadingContext());
    // defaultContext(
    //   dispatch,
    //   process.configuration,
    //   document,
    //   props.configuration,
    //   {
    //     ...dynamicData,
    //     ...fieldValues
    //   },
    //   contextType
    // ).then((ctx) => {
    //   dispatch(setContext(ctx));
    // });
  };

  const _update = (data: any) => {
    // dispatch(updateDetails(data));

    props.update(data);

    return [];
  };

  return (
    <>
      {pageItem !== undefined && definition !== undefined && (
        <ParametersForm
          key={process.version.toString()}
          formTitle={"Properties"}
          close={props.close}
          update={_update}
          document={document!}
          definition={definition}
          configuration={pageItem.data}
          dynamicData={{}}
          validationMessages={[]}
          getDefaultFormData={_getDefaultFormData}
          onRender={(pivots, control, openLocalizationPanel, setValue, trigger) => {
            return;
          }}
          additionalPivots={(
            control: Control<FieldValues>,
            openLocalizationPanel: (name: string, title: string) => void,
            setValue: UseFormSetValue<any>,
            trigger: UseFormTrigger<any>
          ) => {
            return [];
          }}
          contextInit={contextInit}
          getCustomizedControlParams={getCustomizedControlParams}
          customControls={{ TestHttpEndpoint: TestHttpEndpoint }}
        />
      )}
    </>
  );
};
