import {
  Dropdown,
  IDropdownOption,
  IModalProps,
  Modal,
  PrimaryButton,
  Text
} from "@fluentui/react";
import * as React from "react";
import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { ToRoute } from "../../../../../routes";
import { useAppDispatch } from "../../../../../store/hooks";
import { addNewLanguage } from "../../../../../store/processReducer";
import { tryParseJson } from "../../../../../utils/commonUtils";
import { FileUpload, FileUploadRef } from "../../../../common/FileUpload";
import { getLocalizationLanguages } from "../configLanguages";
import { TextDescriptor } from "@workpoint/components/lib/models/ProcessConfiguration";

const StyledModalContent = ({ className, children, isOpen, onDismiss }: any) => (
  <Modal isOpen={isOpen} onDismiss={onDismiss} containerClassName={className}>
    {children}
  </Modal>
);

type LanguageType = {
  nativeName: string;
  nativeLanguageCode: string;
  languageName: string;
  regionCode: string;
  languageCode: string;
  regionName: string;
};

enum TextTypes {
  Title = "xxLarge",
  SubTitle = "xLarge",
  Description = "medium"
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 20px;
`;

const StyledModal = styled(StyledModalContent)`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  height: 300px;
  width: 600px;
  margin-right: 50%;
`;

const TextStyle = ({ className, children, type }: any) => (
  <Text variant={type ?? "medium"} className={className}>
    {children}
  </Text>
);

const StyledText = styled(TextStyle)`
  margin-bottom: 10px;
`;

export const NewLanguageModal = (props: IModalProps) => {
  const { processId } = useParams() as any;
  const navigate = useNavigate();
  const intl = useIntl();
  const [selectedLanguage, setSelectedLanguage] = React.useState<LanguageType>();
  const [availableLanguages, setAvailableLanguages] = React.useState<IDropdownOption[]>();
  const dispatch = useAppDispatch();

  const uploadRef = useRef<FileUploadRef>(null);

  useEffect(() => {
    getAvailableLanguages();
  }, []);

  const getAvailableLanguages = () => {
    const allLanguages: LanguageType[] = getLocalizationLanguages();
    const languages: IDropdownOption[] = [];
    allLanguages.forEach((language) => {
      if (!languages.find((x) => x.key === `${language.languageCode}-${language.regionCode}`)) {
        languages.push({
          key: `${language.languageCode}-${language.regionCode}`,
          text: language.languageName,
          data: language
        });
      }
    });

    setAvailableLanguages(languages);
  };

  const onImportClick = (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement,
      MouseEvent
    >
  ) => {
    uploadRef.current!.open();
  };

  const onFileUpload = (data: string | ArrayBuffer | null) => {
    const json = tryParseJson(data as string);
    if (json) {
      const updatedJson = addSingleQuotes(json);
      dispatch(
        addNewLanguage({
          language: `${selectedLanguage?.languageCode}-${selectedLanguage?.regionCode}`,
          messages: updatedJson
        })
      );
      close();
    }
  };

  const addSingleQuotes = (input: { [key: string]: Partial<TextDescriptor> }) => {
    const result: { [key: string]: Partial<TextDescriptor> } = {};
    Object.keys(input).forEach((key) => {
      result[key] = input[key];
      if (input[key].defaultText) {
        result[key].defaultText = `'${input[key].defaultText}'`;
      }
    });
    return result;
  };

  const onLanguageChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption | undefined,
    index?: number | undefined
  ): void => {
    setSelectedLanguage(option!.data);
  };

  const close = () => {
    navigate(ToRoute.processLocalization());
  };

  return (
    <StyledModal isOpen={true} onDismiss={close}>
      <StyledContainer>
        <StyledText type={TextTypes.Title}>
          {intl.formatMessage({
            id: "additaion-languages",
            defaultMessage: "Additional languages"
          })}
        </StyledText>
        <StyledText type={TextTypes.Description}>
          {intl.formatMessage({
            id: "additional-languages-description",
            defaultMessage:
              "Select a language and upload a JSON file with the localized strings for the portions of the process you want to localize"
          })}
        </StyledText>
        <Dropdown
          selectedKey={
            selectedLanguage
              ? `${selectedLanguage.languageCode}-${selectedLanguage.regionCode}`
              : undefined
          }
          onChange={onLanguageChange}
          options={availableLanguages ?? []}
          label={intl.formatMessage({ id: "select-language", defaultMessage: "Select a language" })}
          placeholder={intl.formatMessage({
            id: "select-language-placeholder",
            defaultMessage: "Click here to select a language"
          })}
          styles={{ dropdownItemsWrapper: { maxHeight: "250px", overflow: "auto" } }}
        />

        <FileUpload onUpload={onFileUpload} ref={uploadRef} />
        <PrimaryButton
          text="Import"
          onClick={onImportClick}
          disabled={selectedLanguage ? false : true}
        />
      </StyledContainer>
    </StyledModal>
  );
};
