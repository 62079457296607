import styled from "styled-components";
export const pageHeaderHeight = 50;
export const sideNavWidth = 280;
export const searchFilterHeight = 47;
export const detailsCommandBarHeight = 47;
export const tabHeight = 38;

export const AppContainer = styled.div`
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Container = styled.div`
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
  margin: 0 auto;
  width: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
`;

export const Content = styled.div`
  box-sizing: border-box;
  max-width: calc(100% - ${sideNavWidth}px);
  width: calc(100% - ${sideNavWidth}px);
  overflow-y: auto;
  max-height: calc(100vh - ${pageHeaderHeight}px);
`;

export const ContentPadding = styled.div`
  padding-left: 24px;
`;
