import { EditorPlugin, IEditor } from "roosterjs";
import { getPlainContent } from "@workpoint/components/lib/components/form/roosterjs/controls/plugins";

export class ExpressionPlugin implements EditorPlugin {
  private editor: IEditor | undefined | null;

  constructor(
    private onClick?: (expression: string) => void,
    private onOpenPanel?: (expression: string, initialValue: string) => void,
    private value?: string,
    private onChange?: (newValue: string) => void,
    private closeContextBrowser?: () => void,
    private isSingleLine?: boolean,
    private isPlainText?: boolean
  ) {}

  getName() {
    return "Expression";
  }

  initialize(editor: IEditor) {
    this.editor = editor;
  }

  dispose() {
    this.editor = null;
  }

  onPluginEvent(e: any /* PluginEvent */) {
    if (this.editor) {
      if (e.eventType == 6 /* PluginEventType.MouseUp */) {
        const target = e.rawEvent.target as HTMLElement;
        const parentNode = target.parentNode as HTMLElement;

        if (target.nodeName === "BUTTON" && parentNode.hasAttribute("data-wpexpression")) {
          this.editor.deleteNode(parentNode);
          this.editor.focus();

          const newContent = this.editor.getContent();

          if (this.value !== newContent) {
            let value = newContent;
            if (value?.startsWith("<div>")) {
              value = value.substring(5);
            }
            if (value?.endsWith("</div>")) {
              value = value.substring(0, value.length - 6);
            }
            this.value = value;
            if (this.onChange) {
              this.onChange(value);
            }
          }
        }

        if (target.hasAttribute("data-wpexpression") && this.onOpenPanel) {
          target.setAttribute("contenteditable", "false");

          const value = target.getAttribute("data-wpexpression");

          this.onOpenPanel(target.outerHTML, value ?? "");

          parentNode.removeChild(target);
        } else if (this.onClick) {
          this.onClick("");
        }
      } else if (e.eventType === 0 /* PluginEventType.KeyDown */) {
        if (this.isSingleLine && e.rawEvent.key === "Enter") {
          e.rawEvent.preventDefault();
        }
        this.closeContextBrowser && this.closeContextBrowser();
        const target = e.rawEvent.target as HTMLElement;
        const span = target.querySelectorAll("span[data-wpexpression]");

        if (span && span.length > 0) {
          span.forEach((s) => s.setAttribute("contenteditable", "false"));
        }
      }

      if (e.eventType === 7 /* PluginEventType.ContentChanged */) {
        const newContent = this.editor.getContent();

        if (this.value !== newContent) {
          let value = newContent;
          if (e.source === "Paste" && this.isPlainText) {
            value = getPlainContent(value).content;
          }
          if (value?.startsWith("<div>")) {
            value = value.substring(5);
          }
          if (value?.endsWith("</div>")) {
            value = value.substring(0, value.length - 6);
          }
          if (value?.endsWith("<br>")) {
            value = value.substring(0, value.length - 4);
          }
          this.value = value;
          if (this.onChange) {
            this.onChange(value);
          }
        }
      }
    }
  }
}
