import {
  CommandBar,
  DefaultButton,
  Dropdown,
  ICommandBarItemProps,
  IDropdownOption,
  Panel,
  PanelType,
  PrimaryButton,
  SelectionMode,
  Text
} from "@fluentui/react";
import * as React from "react";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  ExpressionDescriptorRule,
  TextDescriptor
} from "@workpoint/components/lib/models/ProcessConfiguration";
import { AppRoutes, ToRoute } from "../../../../routes";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  downloadLanguagePack,
  processSelector,
  updateLocalization
} from "../../../../store/processReducer";
import { getLocalizationLanguages } from "./configLanguages";
import { LanguageList } from "./languageList";
import { NewLanguageModal } from "./newLanguage/newLanguageModal";
import { isRulesEmpty } from "../../../../utils/processUtils";

enum TextTypes {
  Title = "xxLarge",
  SubTitle = "xLarge",
  Description = "medium"
}

type LanguageType = {
  nativeName: string;
  nativeLanguageCode: string;
  languageName: string;
  regionCode: string;
  languageCode: string;
  regionName: string;
};

const TextStyle = ({ className, children, type }: any) => (
  <Text variant={type ?? "medium"} className={className}>
    {children}
  </Text>
);

const StyledHeaderContent = ({ className, children, barItems }: any) => (
  <div className={className}>
    <h3>{children}</h3>
    <StyledCommandBar items={barItems}></StyledCommandBar>
  </div>
);

const StyledCommandBar = styled(CommandBar)`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledPanelHeader = styled(StyledHeaderContent)`
  display: flex;
  align-items: center;
`;

const StyledText = styled(TextStyle)`
  margin-bottom: 10px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 20px;
`;

const StyledListContainer = styled.div`
  max-height: 200px;
  overflow: auto;
`;

const StyledButtonContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  button {
    margin-right: 20px;
  }
`;

const StyledDropdown = styled(Dropdown)``;

export const LocalizationPanel = () => {
  const intl = useIntl();
  const { processId } = useParams() as any;
  const navigate = useNavigate();
  const { process } = useAppSelector(processSelector);
  const dispatch = useAppDispatch();

  const [selectedLanguage, setSelectedLanguage] = React.useState<LanguageType>();
  const [selectedRegion, setSelectedRegion] = React.useState<LanguageType>();
  const [regions, setAvailableRegions] = React.useState<IDropdownOption[]>();
  const [languages, setAvailableLanguages] = React.useState<IDropdownOption[]>();
  const [allLanguages, setAllLanguages] = React.useState<LanguageType[]>();

  const supportedLanguages = Object.assign(
    [],
    process.configuration.localization?.additionalLanguages
  );

  useEffect(() => {
    getAvailableLanguages();
  }, []);

  useEffect(() => {
    getAvailableRegions();
  }, [selectedLanguage, allLanguages]);

  const getAvailableLanguages = () => {
    const allLanguages: LanguageType[] = getLocalizationLanguages();
    const languages: IDropdownOption[] = [];
    allLanguages.forEach((language) => {
      if (!languages.find((x) => x.key === language.languageCode)) {
        languages.push({
          key: language.languageCode,
          text: language.languageName,
          data: language
        });
      }
    });

    setAvailableLanguages(languages);
    setAllLanguages(allLanguages);
  };

  const getAvailableRegions = () => {
    let regions: LanguageType[] = [];
    if (selectedLanguage || process.configuration.localization?.defaultLanguage) {
      const defaultLanguage = selectedLanguage
        ? selectedLanguage.languageCode
        : process.configuration.localization?.defaultLanguage.split("-")[0].trim();
      regions = allLanguages ? allLanguages.filter((x) => x.languageCode === defaultLanguage) : [];
    }

    const availableRegions = regions.map((language) => {
      return {
        key: `${language.languageCode}-${language.regionCode}`,
        text: language.regionName,
        data: language
      };
    });

    setAvailableRegions(availableRegions);
  };

  const onLanguageChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption | undefined,
    index?: number | undefined
  ): void => {
    setSelectedLanguage(option!.data);
    setSelectedRegion(option!.data);
  };

  const onRegionChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption | undefined,
    index?: number | undefined
  ): void => {
    setSelectedRegion(option!.data);
  };

  const saveLanguages = () => {
    let defaultLanguage;
    if (selectedRegion) {
      defaultLanguage = `${selectedRegion.languageCode}-${selectedRegion.regionCode}`;
    } else if (selectedLanguage) {
      defaultLanguage =
        defaultLanguage = `${selectedLanguage.languageCode}-${selectedLanguage.regionCode}`;
    }

    dispatch(
      updateLocalization({
        defaultLanguage: defaultLanguage
          ? defaultLanguage
          : process.configuration.localization?.defaultLanguage ?? "",
        additionalLanguages: supportedLanguages
      })
    );
    _closeLocalizationPanel();
  };

  const _closeLocalizationPanel = () => {
    navigate(ToRoute.process(processId));
  };

  const _openNewLanguageModal = () => {
    navigate(ToRoute.processLocalizationImport());
  };

  const panelBarItems: ICommandBarItemProps[] = [
    {
      key: "ok",
      text: intl.formatMessage({ id: "option-ok", defaultMessage: "OK" }),
      iconProps: { iconName: "SaveAndClose" },
      onClick: saveLanguages
    },
    {
      key: "close",
      text: intl.formatMessage({ id: "option-close", defaultMessage: "Close" }),
      iconProps: { iconName: "ChromeClose" },
      onClick: _closeLocalizationPanel
    }
  ];

  const getLocalizationTemplate = () => {
    const data: TextDescriptor[] = getTextDescriptors(process.configuration);
    const result: { [key: string]: Partial<TextDescriptor> } = mapTextDescriptor(data);

    dispatch(
      downloadLanguagePack(
        result,
        process.configuration.localization?.defaultLanguage ?? "defaultTemplate"
      )
    );
  };

  const getTextDescriptors = (object: any): TextDescriptor[] => {
    const result: TextDescriptor[] = [];

    for (const [key] of Object.entries(object)) {
      if (isTextDescriptor(object[key])) {
        result.push(object[key]);
      } else if (Array.isArray(object[key])) {
        object[key].forEach((obj: any) => {
          result.push(...getTextDescriptors(obj));
        });
      } else if (typeof object[key] === "object") {
        result.push(...getTextDescriptors(object[key]));
      }
    }

    return result;
  };

  const isTextDescriptor = (obj: any): obj is TextDescriptor => {
    const result =
      typeof obj?.id === "string" &&
      (typeof obj?.defaultText === "string" ||
        typeof obj?.expression === "string" ||
        Array.isArray(obj?.rules));
    return result;
  };

  const mapTextDescriptor = (
    texts: TextDescriptor[]
  ): { [key: string]: Partial<TextDescriptor> } => {
    const obj: any = {};
    texts.forEach((text) => {
      obj[text.id] = {};
      if (text.defaultText) {
        if (isRulesEmpty(text.rules) && !text.expression) {
          obj[text.id] =
            text.defaultText.startsWith("'") && text.defaultText.endsWith("'")
              ? text.defaultText.slice(1, -1)
              : text.defaultText;
        } else {
          obj[text.id].defaultText =
            text.defaultText.startsWith("'") && text.defaultText.endsWith("'")
              ? text.defaultText.slice(1, -1)
              : text.defaultText;
        }
      }
      if (!isRulesEmpty(text.rules)) {
        let rules: Partial<ExpressionDescriptorRule>[] = [];
        for (const rule of text.rules!) {
          if (rule.comparisonOperator) {
            rules.push(rule);
          } else {
            rules.push({
              value1: rule.value1,
              type1: rule.type1
            });
          }
          obj[text.id].rules = rules;
        }
      }
      if (text.expression) obj[text.id].expression = text.expression;
    });

    return obj;
  };

  return (
    <>
      <Panel
        isOpen={true}
        onDismiss={_closeLocalizationPanel}
        type={PanelType.smallFluid}
        onOuterClick={() => {
          // Need this to disable outerclicks when NewLanguageModal is open.
        }}
        isLightDismiss
        styles={{ main: { boxShadow: "0px 0px 0px 0px" }, commands: { display: "none" } }}
      >
        <StyledContainer>
          <StyledPanelHeader barItems={panelBarItems}>
            <StyledText type={TextTypes.Title}>
              {intl.formatMessage({ id: "localization-title", defaultMessage: "Languages" })}
            </StyledText>
          </StyledPanelHeader>

          <StyledText type={TextTypes.Description}>
            {intl.formatMessage({
              id: "localization-description",
              defaultMessage: "Set up or change the languages that your process supports."
            })}
          </StyledText>

          <StyledText type={TextTypes.SubTitle}>
            {intl.formatMessage({ id: "default-language", defaultMessage: "Default Language" })}
          </StyledText>
          <StyledText type={TextTypes.Description}>
            {intl.formatMessage({
              id: "default-language-description",
              defaultMessage:
                'The default language is the language that you have configured the Process for. For example, if you created your process with English Terms, you should choose "English" as your default language. Likewise, if you used Danish you should choose "Danish".'
            })}
          </StyledText>
          <StyledText type={TextTypes.Description}>
            {intl.formatMessage({
              id: "default-language-description-note",
              defaultMessage:
                "Please note: this is a configuration option and will not change the language for the user when using the Process. If you require additional language support, please import additional languages below."
            })}
          </StyledText>

          <StyledDropdown
            selectedKey={
              selectedLanguage
                ? `${selectedLanguage.languageCode}`
                : process.configuration.localization?.defaultLanguage.split("-")[0].trim() ??
                  undefined
            }
            onChange={onLanguageChange}
            label={intl.formatMessage({
              id: "select-language",
              defaultMessage: "Select a language"
            })}
            placeholder={intl.formatMessage({
              id: "select-language-placeholder",
              defaultMessage: "Click here to select a language"
            })}
            options={languages ?? []}
            styles={{ dropdownItemsWrapper: { maxHeight: "250px", overflow: "auto" } }}
          />
          <StyledDropdown
            selectedKey={
              selectedRegion
                ? `${selectedRegion.languageCode}-${selectedRegion.regionCode}`
                : process.configuration.localization?.defaultLanguage
            }
            onChange={onRegionChange}
            label={intl.formatMessage({
              id: "select-language-region",
              defaultMessage: "Select a region"
            })}
            placeholder={intl.formatMessage({
              id: "select-language-region-placeholder",
              defaultMessage: "Click here to select a region"
            })}
            options={regions ?? []}
            disabled={regions && regions.length <= 2 ? true : false}
            styles={{ dropdownItemsWrapper: { maxHeight: "250px", overflow: "auto" } }}
          />

          <StyledText type={TextTypes.SubTitle}>
            {intl.formatMessage({
              id: "supported-language",
              defaultMessage: "Currently supported languages"
            })}
          </StyledText>
          <StyledText type={TextTypes.Description}>
            {intl.formatMessage({
              id: "supported-language-description",
              defaultMessage:
                "Add support for additional languages by importing localized manifest to your process."
            })}
          </StyledText>

          <StyledListContainer>
            <LanguageList
              items={process.configuration.localization?.additionalLanguages ?? []}
              selectionMode={SelectionMode.none}
              isHeaderVisible={false}
            />
          </StyledListContainer>

          <StyledText type={TextTypes.SubTitle}>
            {intl.formatMessage({ id: "add-language", defaultMessage: "Add another language" })}
          </StyledText>
          <StyledText type={TextTypes.Description}>
            {intl.formatMessage({
              id: "add-language-description",
              defaultMessage:
                "Add support for multiple languages to your process by defining additional localized manifest files. Download a template for your localized manifest files based on your current process configuration."
            })}
          </StyledText>

          <StyledButtonContainer>
            <PrimaryButton text="Import" onClick={_openNewLanguageModal} />
            <DefaultButton text="Download" onClick={getLocalizationTemplate} />
          </StyledButtonContainer>
        </StyledContainer>
      </Panel>
      <Routes>
        <Route path={AppRoutes.ProcessLocalizationImport} element={<NewLanguageModal />} />
      </Routes>
    </>
  );
};
