import { Customizer, LayerHost } from "@fluentui/react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { AppRoutes } from "../../../../routes";
import { detailsCommandBarHeight } from "../../../common/AppChrome";
import { LocalizationPanel } from "../localization/localizationPanel";
import { ProcessParametersPanel } from "./ProcessParametersPanel";
import { StepParametersPanel } from "./StepParametersPanel";
import { TriggerParametersPanel } from "./TriggerParametersPanel";

const RightSideInner = ({ className }: any) => {
  return (
    <>
      <Customizer scopedSettings={{ Layer: { hostId: "layerHostId" } }}>
        <Routes>
          <Route path={AppRoutes.ProcessLocalization + "/*"} element={<LocalizationPanel />} />
          <Route path={AppRoutes.ProcessProperties} element={<ProcessParametersPanel />} />
          <Route path={AppRoutes.ProcessStep + "/*"} element={<StepParametersPanel />} />
          <Route path={AppRoutes.ProcessTrigger + "/*"} element={<TriggerParametersPanel />} />
        </Routes>
      </Customizer>
      <LayerHost id={"layerHostId"} className={className}></LayerHost>
    </>
  );
};

const RightSide = styled(RightSideInner)`
  position: absolute;
  right: 0px;
  top: ${detailsCommandBarHeight}px;
  bottom: 0px;
  left: 50%;
  background-color: #f5f9fb;
`;

export default RightSide;
