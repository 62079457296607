import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { IDropdownProps, Dropdown, IDropdownOption } from "@fluentui/react";
import { FormControlProps } from "./ControlledTextField";

export const ControlledDropdown: FC<FormControlProps & IDropdownProps> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || ""}
      render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => (
        <Dropdown
          {...props}
          onChange={(
            event: React.FormEvent<HTMLDivElement>,
            option?: IDropdownOption,
            index?: number
          ) => {
            onChange(option?.key);
            if (props.onChange) props.onChange(event, option, index);
          }}
          placeholder={props.placeholder}
          onBlur={onBlur}
          errorMessage={error && error.message}
          selectedKey={value}
        />
      )}
    />
  );
};
