import { useEffect } from "react";
import { RequestState } from "../../../models/RequestState";
import { globalSelector } from "../../../store/globalReducer";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import {
  fetchNotificationSettingsForSolution,
  notificationSelector,
  notificationStatusSelector
} from "./notificationSettingsReducer";

export function useNotificationSettings() {
  const { spHostUrl: solutionAbsoluteUrl } = useAppSelector(globalSelector);
  const dispatch = useAppDispatch();

  const status = useAppSelector((state) => notificationStatusSelector(state));

  const notificationSettings = useAppSelector((state) => notificationSelector(state));

  useEffect(() => {
    if (
      status === undefined &&
      typeof solutionAbsoluteUrl === "string" &&
      solutionAbsoluteUrl.trim() !== ""
    ) {
      dispatch(fetchNotificationSettingsForSolution());
    }
  }, [status, solutionAbsoluteUrl]);

  /**
   * Solution url has changed
   */
  useEffect(() => {
    if (status !== undefined) {
      dispatch(fetchNotificationSettingsForSolution());
    }
  }, [solutionAbsoluteUrl]);

  // derive status booleans for ease of use
  const loading = status === RequestState.pending; // || status === undefined
  const error = status === RequestState.rejected;
  const success = status === RequestState.fulfilled;
  const unprovisioned = status === RequestState.unprovisioned;
  const unlicensed = status === RequestState.unlicensed;

  return {
    notificationSettings,
    loading,
    error,
    success,
    unprovisioned,
    unlicensed
  };
}
