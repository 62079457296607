import { OpenAPIV2 } from "openapi-types";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { newTrigger, processSelector } from "../../../../store/processReducer";
import { getAllOperations } from "../../../../utils/swaggerUtils";
import NewRowPanelBase from "./NewRowPanelBase";
import { v4 as uuid } from "uuid";
import {
  ProcessStepParameterConfiguration,
  ConfigurationParameterType
} from "@workpoint/components/lib/models/ProcessConfiguration";
import { useParams } from "react-router-dom";
import { RowType } from "../../../../utils/processUtils";

export const NewTriggerPanel = () => {
  const [allItems, setAllItems] = useState<OpenAPIV2.OperationObject[]>();
  const { document } = useAppSelector(processSelector);
  const { insertIndex } = useParams() as any;

  const dispatch = useAppDispatch();

  useEffect(() => {
    const actions = getAllOperations(document!).filter(
      (step: OpenAPIV2.OperationObject) =>
        !step["tags"]?.includes("Hidden") &&
        (step as any)["x-workpoint-type"] === "trigger" &&
        (((step as any)["x-ms-visibility"] !== "internal" &&
          !(step as any)["x-workpoint-visibility"]) ||
          ((step as any)["x-workpoint-visibility"] &&
            (step as any)["x-workpoint-visibility"] !== "internal"))
    );
    setAllItems(actions);
  }, [document]);

  const _addNewTrigger = (item: any) => {
    const id = uuid();
    dispatch(
      newTrigger({
        trigger: {
          id,
          businessModuleId: "",
          title: { id: `${id}-title`, defaultText: item.summary ?? "" },
          parameters: item.parameters!.map((p: any) => {
            const definition = p as OpenAPIV2.Parameter;
            const paramConfig: ProcessStepParameterConfiguration = {
              name: definition.name,
              title: {
                id: `${id}-${definition.name}-title`,
                defaultText: definition["x-ms-summary"] ?? ""
              },
              description: {
                id: `${id}-${definition.name}-description`,
                defaultText: definition.description ?? ""
              },
              type: ConfigurationParameterType.Static
            };

            return paramConfig;
          }),
          type: item.operationId,
          disabled: false
        },
        rowIndex: insertIndex
      })
    );
  };

  return <NewRowPanelBase allItems={allItems} addNewItem={_addNewTrigger} type={RowType.Trigger} />;
};

