import { OpenAPIV2 } from "openapi-types";
import { useEffect, useState } from "react";
import {
  Control,
  FieldValues,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormUnregister,
  useWatch
} from "react-hook-form";
import { OrderDirection } from "@workpoint/components/lib/models/ProcessConfiguration";
import { defaultExpression, isExpressionEmpty } from "../../../utils/processUtils";
import { AccordionControl, AccordionListContainer } from "./AccordionControl";
import { ParameterControl } from "./ParameterControl";

interface DynamicColumnFieldsProps {
  controlsData: any;
  definition: OpenAPIV2.Parameter;
  onDelete: (fieldName: string) => void;
  onOrderChange: (fieldName: string, direction: OrderDirection) => void;
  control: Control<FieldValues>;
  setValue: UseFormSetValue<any>;
  trigger: UseFormTrigger<any>;
  getValues: (controlName: string) => any;
  register: UseFormRegister<any>;
  unregister: UseFormUnregister<any>;
  fieldValues: any;
  document?: OpenAPIV2.Document;
}

export const DynamicColumnFields = (props: DynamicColumnFieldsProps) => {
  const [dynamicFieldValues, setDynamicFieldValues] = useState<any>();
  const fieldArray = useFieldArray({
    control: props.control,
    name: props.definition.name + ".value.data"
  });

  const watch = useWatch({
    control: props.control,
    name: `${props.definition.name}.value.data`
  });

  useEffect(() => {
    if (!watch || watch.length === 0) {
      const temp =
        props.controlsData?.data?.map(
          (d: { WebRelativeUrl: string; Title: string }, index: number) => {
            const obj: any = {
              key: d.WebRelativeUrl
            };
            props.definition["x-workpoint-dynamic-columns"].parameters.forEach(
              (p: OpenAPIV2.Parameter) => {
                obj[p.name] = defaultExpression();
              }
            );
            return obj;
          }
        ) ?? [];
      props.setValue(props.definition.name + ".value.data", temp);
    } else if (props.controlsData?.data) {
      //&& props.controlsData.data.length !== watch.length
      const valuesToAdd: any[] = [];
      props.controlsData.data.forEach((d: any) => {
        const currentElement = watch.find((w: any) => w.key === d.WebRelativeUrl);
        if (!currentElement) {
          valuesToAdd.push({ key: d.WebRelativeUrl });
        } else {
          valuesToAdd.push({ ...currentElement });
        }
      });
      props.setValue(props.definition.name + ".value.data", [...valuesToAdd]);
    }
  }, [props.controlsData]);

  useEffect(() => {
    const dfVal: any = {};
    fieldArray?.fields?.forEach((field: any) => {
      dfVal[field.key] = { ...props.fieldValues, parameter: field.key };
    });
    setDynamicFieldValues(dfVal);
  }, [fieldArray.fields, props.fieldValues]);

  const checkModified = (name: string) => {
    let modified = false;
    const val = props.getValues(name);
    Object.keys(val).forEach((v) => {
      if (!isExpressionEmpty(val[v], false)) {
        modified = true;
      }
    });
    return modified;
  };

  return (
    <AccordionListContainer>
      {fieldArray?.fields?.map((field: any, index: number) => {
        return (
          <AccordionControl
            definition={props.definition}
            key={field.key}
            expanded={false}
            modified={checkModified(`${props.definition.name}.value.data[${index}]`)}
            onDelete={props.onDelete}
            onOrderChange={props.onOrderChange}
            title={field.key}
          >
            {props.definition["x-workpoint-dynamic-columns"].parameters.map(
              (p: OpenAPIV2.Parameter) => {
                return (
                  <ParameterControl
                    key={`${props.definition.name}valuedata${index}${p.name}`}
                    control={props.control}
                    setValue={props.setValue}
                    trigger={props.trigger}
                    required={false}
                    definition={p}
                    register={props.register}
                    unregister={props.unregister}
                    fieldValues={dynamicFieldValues?.[field.key]}
                    getValues={props.getValues}
                    document={props.document}
                    dynamic
                    name={`${props.definition.name}.value.data[${index}].${p.name}`}
                    skipPropertyTypeInName={true}
                  />
                );
              }
            )}
          </AccordionControl>
        );
      })}
    </AccordionListContainer>
  );
};
