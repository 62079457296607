import Editor, { useMonaco, Monaco } from "@monaco-editor/react";
import React from "react";
import { Control, Controller, FieldValues, Noop } from "react-hook-form";
import {
  registerWorkflowLanguageProviders,
  createThemeData,
  languageId
} from "./monacoConfiguration";
import * as monacoEditor from "monaco-editor/esm/vs/editor/editor.api";
import { Text } from "@fluentui/react";
import styled from "styled-components";
import { useAppSelector } from "../../../store/hooks";
import { globalSelector } from "../../../store/globalReducer";

export interface IConditionsAdvancedProps {
  name?: string;
  control?: Control<FieldValues>;
  defaultValue?: string;
  initialValue?: string;
  onChange?: (...event: any[]) => void;
  onBlur?: Noop;
  contextExtension?: any;
}

const contextRef = { current: {} };

export const ConditionsAdvanced = (props: IConditionsAdvancedProps) => {
  const { context } = useAppSelector(globalSelector);
  const editorRef = React.useRef<monacoEditor.editor.IStandaloneCodeEditor>();
  const monaco = useMonaco();
  React.useEffect(() => {
    if (monaco) {
      registerWorkflowLanguageProviders(monaco, contextRef);
      monaco.editor.defineTheme("wp", createThemeData() as any);
      monaco.editor.setTheme("wp");
    }
  }, [monaco]);

  React.useEffect(() => {
    contextRef.current = props.contextExtension
      ? ({ ...context, ...props.contextExtension } as any)
      : (context as any);
  }, [context]);

  const handleEditorDidMount = (
    editor: monacoEditor.editor.IStandaloneCodeEditor,
    monaco: Monaco
  ) => {
    editorRef.current = editor;
  };
  return (
    <>
      <StyledContainer>
        <Text variant="xLarge">Expression</Text>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </StyledContainer>
      {props.onChange ? (
        <MyEditor
          value={props.initialValue}
          onChange={props.onChange}
          handleEditorDidMount={handleEditorDidMount}
        />
      ) : (
        <>
          <Controller
            name={props.name as any}
            control={props.control}
            defaultValue={props.defaultValue}
            render={({
              field: { onChange, onBlur, name: fieldName, value },
              fieldState: { error }
            }) => (
              <MyEditor
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                handleEditorDidMount={handleEditorDidMount}
              />
            )}
          />
        </>
      )}
    </>
  );
};

const StyledContainer = styled.div`
  margin: 16px 32px;
`;

interface IEditor {
  value: any;
  onChange: (...event: any[]) => void;
  handleEditorDidMount: (editor: monacoEditor.editor.IStandaloneCodeEditor, monaco: Monaco) => void;
  onBlur?: Noop;
}

const MyEditor = ({ onBlur, value, onChange, handleEditorDidMount }: IEditor) => {
  return (
    <div onBlur={onBlur} style={{ height: "100%" }}>
      <Editor
        height="400px"
        language={languageId}
        value={value}
        onChange={onChange}
        onMount={handleEditorDidMount}
        options={{
          scrollbar: {
            vertical: "hidden",
            alwaysConsumeMouseWheel: false
          },
          hideCursorInOverviewRuler: true,
          scrollBeyondLastLine: false
        }}
      />
    </div>
  );
};
