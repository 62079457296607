import {
  ShimmeredDetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  IColumn,
  IDetailsColumnStyles,
  SelectionMode,
  Stack,
  StackItem
} from "@fluentui/react";
import React from "react";
import { FieldError } from "react-hook-form";
import { useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import { unpackErrors } from "./ErrorMessage";
import { EditProfileButton } from "./EditProfile";
import {
  INotificationProfile,
  NotificationEmailSchedule
} from "../../../../models/NotificationSettings";
import { ToRoute } from "../../../../routes";

export interface INotificationProfilesListProps {
  profiles: INotificationProfile[];
  loading: boolean;
  error: FieldError | any;
  disabled: boolean;
}

const headerStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    fontWeight: "bold"
  }
};

export const NotificationProfilesList: React.FC<INotificationProfilesListProps> = (props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { error, disabled } = props;

  const columns: IColumn[] = [
    {
      styles: headerStyle,
      key: "column1",
      name: "Name",
      fieldName: "Name",
      minWidth: 110,
      maxWidth: 220,
      isRowHeader: true,
      isResizable: true,
      isPadded: true
    },
    {
      styles: headerStyle,
      key: "column2",
      name: "Target",
      minWidth: 110,
      maxWidth: 350,
      isResizable: true,
      onRender: (item: INotificationProfile) => {
        return item.Target?.Name;
      },
      isPadded: true
    },
    {
      styles: headerStyle,
      key: "column3",
      name: "Delivery",
      minWidth: 70,
      maxWidth: 90,
      isResizable: true,
      onRender: (item: INotificationProfile) => {
        let translatedSchedule: string | null = null;
        switch (item.EmailSchedule) {
          case NotificationEmailSchedule.SendImmediatetly:
            translatedSchedule = intl.formatMessage({
              id: "send-immediately",
              defaultMessage: "Send immediately"
            });
            break;
          case NotificationEmailSchedule.SendDaily:
            translatedSchedule = intl.formatMessage({
              id: "send-daily",
              defaultMessage: "Send daily"
            });
            break;
          case NotificationEmailSchedule.SendWeekly:
            translatedSchedule = intl.formatMessage({
              id: "send-weekly",
              defaultMessage: "Send weekly"
            });
            break;
        }
        return <span>{translatedSchedule}</span>;
      },
      isPadded: true
    },
    {
      key: "column4",
      name: "",
      fieldName: "button",
      minWidth: 70,
      maxWidth: 90,
      onRender: (item: INotificationProfile) => {
        return (
          <span>
            <Link to={ToRoute.editNotificationProfile(item.Id)}>
              <EditProfileButton text="Edit" />
            </Link>
          </span>
        );
      }
    }
  ];
  return (
    <Stack>
      <StackItem>
        <ShimmeredDetailsList
          items={(Array.isArray(props.profiles) && props.profiles) || []}
          columns={columns}
          onRenderRow={(props: any) => (
            <DetailsRow
              {...props}
              styles={{ root: { cursor: "pointer" } }}
              onClick={() => !disabled && navigate(ToRoute.editNotificationProfile(props.item.Id))}
            />
          )}
          enableShimmer={props.loading}
          selectionMode={SelectionMode.none}
          listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
        />
      </StackItem>

      {error && <StackItem>{unpackErrors(error)}</StackItem>}
    </Stack>
  );
};
