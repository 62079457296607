import React from "react";
import { Icon, Stack, Text } from "@fluentui/react";
import WorkPointTheme from "@workpoint/components/lib/constants";
import styled from "styled-components";

const theme = WorkPointTheme;

const AddStepButtonInner = ({ className, openNewStepModal, rowType }: any) => {
  return (
    <Stack className={className} horizontal gap="10px" onClick={openNewStepModal}>
      <AddIcon iconName="Add" />
      <AddText>Add new {rowType}</AddText>
    </Stack>
  );
};

export const AddStepButton = styled(AddStepButtonInner)`
  height: fit-content;
  margin-left: 35px;

  :hover {
    cursor: pointer;
  }
`;

const AddIcon = styled(Icon)`
  font-size: 15px;
  color: ${theme.palette.white};
  background-color: ${theme.palette.themePrimary};
  border: 5px solid ${theme.palette.themePrimary};
  border-radius: 100px;
`;

const AddText = styled(Text)`
  display: flex;
  align-self: center;
`;
