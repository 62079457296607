import { DefaultButton, Label, Spinner, Stack, StackItem } from "@fluentui/react";
import { OpenAPIV2 } from "openapi-types";
import React, { useState } from "react";
import { useWatch } from "react-hook-form";
import { useApiClient } from "@workpoint/components/lib/clients/ApiProvider";
import { getParameterValue } from "../../store/adminReducer";
import { useAppDispatch } from "../../store/hooks";
import {
  getDefinitionByOperationId,
  getDynamicData,
  OperationDefinition
} from "../../utils/swaggerUtils";
import { ParameterPropertyControlParams } from "../common/parametersForm/ParameterPropertyControl";
import { StyledCodeMirror } from "./notification/components/ControlledRichText";

export interface CustomControlParameterPropertyControlParams
  extends ParameterPropertyControlParams {
  document?: OpenAPIV2.Document;
}

export function TestHttpEndpoint(props: CustomControlParameterPropertyControlParams) {
  const { apiClient } = useApiClient();
  const dispatch = useAppDispatch();

  const { document, control, setValue } = props;
  const [testResponse, setTestResponse] = useState<string>("");
  const [testLoading, setTestLoading] = useState<boolean>(false);
  const [schemaLoading, setSchemaLoading] = useState<boolean>(false);

  const [parameterValues, setParameterValues] = useState<any>({});

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setParameterValues({
      ...parameterValues,
      [name]: value
    });
  };

  const httpEndpointActionEntryFormValues = useWatch({ control });

  const runTest = async (event: any): Promise<void> => {
    try {
      setTestLoading(true);

      const actionDefinition = getDefinitionByOperationId(document!, "TestHttpConnection");

      const payload = createTestPayload(
        actionDefinition!,
        httpEndpointActionEntryFormValues,
        parameterValues
      );

      const testResponsePayload = await getDynamicData(
        apiClient,
        dispatch,
        document!,
        {
          operationId: "TestHttpConnection",
          "value-path": "",
          "value-title": "",
          parameters: {}
        },
        payload
      );
      setTestResponse(testResponsePayload.data);
    } finally {
      setTestLoading(false);
    }
  };

  const generateSchema = async (event: any): Promise<void> => {
    try {
      setSchemaLoading(true);
      const actionDefinition = getDefinitionByOperationId(document!, "GenerateSchema");

      const jsonArgument = {
        jsonInput: testResponse
      };

      const payload = createSchemaPayload(actionDefinition!, jsonArgument);

      const schemaResponse = await getDynamicData(
        apiClient,
        dispatch,
        document!,
        {
          operationId: "GenerateSchema",
          "value-path": "",
          "value-title": "",
          parameters: {}
        },
        payload
      );

      /**
       * @todo: Can we improve this? Naming is not optimal for Admin purposes.
       */
      setValue("Schema.value.data", schemaResponse.data, { shouldValidate: false });
    } finally {
      setSchemaLoading(false);
    }
  };

  return (
    <Stack>
      {httpEndpointActionEntryFormValues?.Parameters?.value?.data &&
        Array.isArray(httpEndpointActionEntryFormValues.Parameters.value.data) && (
          <Stack>
            <Label>Parameter test values (configured under options)</Label>
            {httpEndpointActionEntryFormValues.Parameters.value.data.map((p: any) => {
              const rowKey = `param-${p.key}`;
              return (
                <React.Fragment key={rowKey}>
                  <Label htmlFor={rowKey}>{p.key}</Label>
                  <input id={rowKey} name={p.key} onChange={handleInputChange} />
                </React.Fragment>
              );
            })}
          </Stack>
        )}
      <Stack horizontalAlign="end" horizontal tokens={{ childrenGap: 15 }} style={{ marginTop: 5 }}>
        <Stack horizontal tokens={{ childrenGap: 15 }}>
          {schemaLoading && <Spinner />}
          <DefaultButton
            onClick={generateSchema}
            disabled={!testResponse || testResponse === "" || schemaLoading || testLoading}
            text="Generate schema"
            iconProps={{ iconName: "TextDocument" }}
          />
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 15 }}>
          {testLoading && <Spinner />}
          <DefaultButton
            onClick={runTest}
            disabled={testLoading}
            text="Test"
            iconProps={{ iconName: "TestBeakerSolid" }}
          />
        </Stack>
      </Stack>
      <Label>Test response</Label>
      <StyledCodeMirror
        value={testResponse}
        options={{
          mode: "application/json",
          lineNumbers: false,
          lineWrapping: true,
          tabMode: "indent",
          matchBrackets: true,
          indentUnit: 4,
          line: true
        }}
        onBeforeChange={(editor, data, value) => {
          setTestResponse(value);
        }}
      />
    </Stack>
  );
}

const createSchemaPayload = (actionDefinition: OperationDefinition, formValues: any) => {
  /**
   * Filter out unwanted parameters.
   * Input: { HttpEndpointEntry, StrParameterKVP }
   */
  const payloadParameter: { name: string; schema: any } = (
    actionDefinition!.definition.parameters! as any[]
  ).filter((p: any) => p.in === "body")[0];

  /**
   * Iterate body members individual parameters
   */
  let bodyPayload = Object.keys(payloadParameter.schema.properties).map((propertyName: string) => {
    let dataContainer = null;

    if (Array.isArray(formValues)) {
      const paramIndex = formValues.findIndex((p) => p.name === propertyName);
      dataContainer = formValues[paramIndex];
    } else {
      dataContainer = formValues[propertyName];
    }

    return {
      key: propertyName,
      value: dataContainer
    };
  });

  const payload = { [payloadParameter.name]: objectifyCollection(bodyPayload) };

  return payload;
};

const createTestPayload = (
  actionDefinition: OperationDefinition,
  formValues: any,
  addtionalValues?: any
) => {
  /**
   * Filter out unwanted parameters.
   * Input: { HttpEndpointEntry, StrParameterKVP }
   */
  const payloadParameter: { name: string; schema: any } = (
    actionDefinition!.definition.parameters! as any[]
  ).filter((p: any) => p.in === "body")[0];

  /**
   * Iterate payload memebers and return a body payload:
   * Input eg.: { HttpEndpointEntry, StrParameterKVP }
   */
  let bodyPayload = Object.keys(payloadParameter.schema.properties).map(
    (bodyPayloadMemberName: string) => {
      /**
       * @todo: Handle addtional params.
       */
      if (
        payloadParameter.schema.properties[bodyPayloadMemberName].hasOwnProperty(
          "additionalProperties"
        )
      )
        if (!!addtionalValues) return { key: bodyPayloadMemberName, value: addtionalValues };
        else return null;

      const bodyMember = payloadParameter.schema.properties[bodyPayloadMemberName];

      /**
       * Iterate body members individual parameters
       */
      const bodyMemberPayload = Object.keys(bodyMember?.properties).map((propertyName: string) => {
        const property = bodyMember?.properties?.[propertyName];

        let dataContainer = null;

        if (Array.isArray(formValues)) {
          const paramIndex = formValues.findIndex((p) => p.name === propertyName);
          dataContainer = formValues[paramIndex];
        } else {
          dataContainer = formValues[propertyName];
        }

        return {
          key: propertyName,
          value: getParameterValue(dataContainer!.value.data, property, propertyName)
        };
      });

      return {
        key: bodyPayloadMemberName,
        value: objectifyCollection(bodyMemberPayload)
      };
    }
  );

  const payload = { [payloadParameter.name]: objectifyCollection(bodyPayload) };

  return payload;
};

const objectifyCollection = (collection: any[]) =>
  collection.reduce<any>((collector, value) => {
    if (!!value) {
      collector[value.key] = value.value;
      return collector;
    }
  }, {});
