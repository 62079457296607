import { backendPool } from "@workpoint/components/lib/constants";
import { isEqual } from "lodash";

export const isEmpty = (value: any) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

export const isEqualOrEmpty = (v1: any, v2: any) => {
  const v1IsEmpty = isEmpty(v1);
  const v2IsEmpty = isEmpty(v2);
  if (v1IsEmpty !== v2IsEmpty || (!v1IsEmpty && !v2IsEmpty && !isEqual(v1, v2))) {
    return false;
  }

  return true;
};

export const isObjectEqual = (m1: any, o2: any) => {
  const m1IsEmpty = isEmpty(m1);
  const o2IsEmpty = isEmpty(o2);
  if (m1IsEmpty !== o2IsEmpty) {
    return false;
  } else if (m1IsEmpty && o2IsEmpty) {
    return true;
  } else {
    const keys = Object.keys(m1);
    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      if (!isEqualOrEmpty(m1[k], o2[k])) {
        return false;
      }
    }

    return true;
  }
};

const jsonObjectFromFile: { [key: string]: any } = {};

export const getJsonObjectFromFile = (path: string) => {
  if (!jsonObjectFromFile[path]) {
    var request = new XMLHttpRequest();
    request.open("GET", `${path}${backendPool}`, false);
    request.overrideMimeType("application/json");
    request.send(null);
    const obj = JSON.parse(request.responseText);
    jsonObjectFromFile[path] = obj;
  }
  return jsonObjectFromFile[path];
};

export const parseHTML = (str: string): HTMLElement => {
  var tmp = document.implementation.createHTMLDocument("");
  tmp.body.innerHTML = str;
  return tmp.body;
};

export const tryParseJson = (text: string): any | undefined => {
  try {
    const parsed = JSON.parse(text);
    return parsed;
  } catch {
    return undefined;
  }
};

export const getSPHostUrl = (): string | undefined => {
  const urlBasedSpHostUrl = extractSpHostUrl(new URLSearchParams(window.location.search));

  if (urlBasedSpHostUrl) return urlBasedSpHostUrl;

  /**
   * Side loaded cache sphostUrl for auth purposes @todo: Improve this!
   * persist:global
   */
  const storedRootValue = window.sessionStorage?.getItem?.("persist:global");

  if (storedRootValue) {
    try {
      let values = JSON.parse(storedRootValue);
      return decodeURI(values["spHostUrl"].split('"').join(""));
    } catch (error) {
      console.log("Could not determine SP host url from cache in 'getspHostUrl' call. ", error);
    }
  }

  /**
   * @todo: We should remove this as spHostUrl must determine what solution is chosen.
   */
  // if (
  //   process.env.NODE_ENV === "development" &&
  //   typeof process.env.REACT_APP_DEV_SOLUTION === "string"
  // )
  //   return process.env.REACT_APP_DEV_SOLUTION!;

  return undefined;
};

/**
 * Fetch SphostUrl arguemnent from URL.
 * Extra Sanity check for compatible URL.
 */
export const extractSpHostUrl = (urlSearchParams: URLSearchParams): string | null => {
  /**
   * Possible solution URL identifiers.
   */
  const possibleKeys = ["sphosturl", "solutionurl"];

  for (const [key, value] of Array.from(urlSearchParams.entries())) {
    if (
      possibleKeys.indexOf(key.toLowerCase()) !== -1 &&
      typeof value === "string" &&
      value !== "" &&
      value.toLowerCase().indexOf("https") !== -1
    )
      return value;
  }

  return null;
};

/**
 * Extracts non-empty GET parameters from URL.
 */
export const extractGetParameter = (
  urlSearchParams: URLSearchParams,
  parameterNames: string[]
): string | null => {
  for (const [key, value] of Array.from(urlSearchParams.entries())) {
    if (
      parameterNames.indexOf(key.toLowerCase()) !== -1 &&
      typeof value === "string" &&
      value !== ""
    )
      return value;
  }

  return null;
};

export const parseHtmlForNewTabLinks = (html: string) => {
  try {
    const el = document.createElement("html");
    el.innerHTML = html;
    const aTags = el.getElementsByTagName("a");
    for (let aTag of aTags as any) {
      const target = aTag.getAttribute("target");
      if (!target || target !== "_blank") {
        aTag.setAttribute("target", "_blank");
      }
    }
    return el.innerHTML;
  } catch {
    return html;
  }
};

/**
 * Recursively gets the child nodes of each business module and orders them accordingly.
 * Also adds an "Indentation" property to each node.
 * @param businessModules All business modules.
 * @param nodes The business modules of which the children are found.
 * @param result List contaiing the final resualt of the hierarchically sorted list of business modules.
 * @param indentation the level of indentation to add for visually showing the hierarchy in a list.
 */
const getChildNodes = (
  businessModules: any[],
  nodes: any[],
  result: any[],
  indentation: number
) => {
  // Adding indentation for the list to visually indicate the parent/child relation between business modules.
  nodes.forEach((node) => {
    if (!result.some((m) => m.Id === node.Id)) {
      result.push({ ...node, Indentation: indentation });
    }
    const childModules = businessModules
      .filter((bm) => bm.Parent === node.Id)
      .sort((a, b) => a.Title.localeCompare(b.Title));
    if (childModules.length > 0) {
      getChildNodes(businessModules, childModules, result, indentation + 1);
    }
  });
};

export const sortBusinessModulesByHierarchy = (businessModules: any[]) => {
  const rootNodes = businessModules
    .filter((bm) => !bm.Parent)
    .sort((a, b) => a.Title.localeCompare(b.Title));
  const result: any[] = [];
  getChildNodes(businessModules, rootNodes, result, 0);

  return result;
};

export const isSameOrder = (array1: any, array2: any) => {
  if (array1 === undefined && array2 === undefined) {
    return true;
  }

  if (array1?.length !== array2?.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    if (!isObjectEqual(array1[i], array2[i])) {
      return false;
    }
  }

  return true;
};
