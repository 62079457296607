import React, { useEffect, useState } from "react";
import { DefaultButton } from "@fluentui/react";
import { OpenAPIV2 } from "openapi-types";
import {
  Control,
  FieldValues,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormUnregister,
  useWatch
} from "react-hook-form";
import {
  OrderDirection,
  ProcessParameterProperty,
  ProcessStepConfiguration
} from "@workpoint/components/lib/models/ProcessConfiguration";
import {
  defaultDataSource,
  defaultExpression,
  defaultTextDesciptor,
  isExpressionEmpty
} from "../../../utils/processUtils";
import { AccordionControl, AccordionListContainer } from "./AccordionControl";
import { AddFieldContainer, ParameterControl } from "./ParameterControl";
import {
  ParameterPropertyControl,
  ParameterPropertyControlParams
} from "./ParameterPropertyControl";
import { FormControlType } from "../parametersForm/ParametersForm";
import { v4 as uuid } from "uuid";
import { useAppSelector } from "../../../store/hooks";
import { evaluateProcessExpressionLimited } from "@workpoint/components/lib/helpers/expressionUtils";
import { globalSelector } from "../../../store/globalReducer";

interface DynamicTabsProps {
  name?: string;
  controlsData: any;
  definition: OpenAPIV2.Parameter;
  control: Control<FieldValues>;
  setValue: UseFormSetValue<any>;
  trigger: UseFormTrigger<any>;
  getValues: (controlName: string) => any;
  register: UseFormRegister<any>;
  unregister: UseFormUnregister<any>;
  fieldValues: any;
  document?: OpenAPIV2.Document;
  localizationIdPrefix?: string;
  getCustomizedControlParams?: (
    definition: OpenAPIV2.Parameter,
    name: string,
    propertyType: ProcessParameterProperty
  ) => Partial<ParameterPropertyControlParams>;
  skipValueInName?: boolean;
  stepConfiguration?: ProcessStepConfiguration;
}

export const DynamicTabs = (props: DynamicTabsProps) => {
  const { definition, control, fieldValues } = props;
  const { context } = useAppSelector(globalSelector);
  const [dynamicFieldValues, setDynamicFieldValues] = useState<any>();

  /**
   * We check if the definition name already exists, as we don't want it added twice if
   * we are using DynamicTabs recursively.
   */
  const name = `${props.name ?? ""}${
    !props.name
      ? definition.name
      : (props.name as string)?.endsWith("." + definition.name)
      ? ""
      : "." + definition.name
  }${props.skipValueInName ? "" : ".value.data"}`;

  const fieldArray = useFieldArray({ control, name });

  const watch = useWatch({ control, name });

  useEffect(() => {
    const dfVal: any = {};
    fieldArray?.fields?.forEach((field: any) => {
      dfVal[field.key] = { ...fieldValues, parameter: field.key };
      Object.keys(field).forEach((paramName) => {
        if (!isExpressionEmpty(field[paramName])) {
          const evaluatedValue =
            evaluateProcessExpressionLimited(field[paramName], context) ?? field[paramName]?.data;
          dfVal[field.key][paramName] = evaluatedValue;
        }
      });
    });
    setDynamicFieldValues(dfVal);
  }, [fieldArray.fields, fieldValues, watch]);

  const addTab = (tabType: string, tab: any) => {
    const key = uuid().replaceAll("-", "");
    const obj: any = {
      key,
      type: tabType
    };
    if (tab.title) {
      obj.title = {
        id: `${props.localizationIdPrefix}-${key}`,
        defaultText: "'" + tab.title + "'"
      };
    }
    tab.parameters.forEach((p: OpenAPIV2.Parameter, index: number) => {
      if (p["x-workpoint-control"]?.translatable && props.stepConfiguration) {
        const id = props.stepConfiguration?.id + "-" + key + "-" + p.name;
        obj[p.name] = defaultTextDesciptor(id);
      } else if (p["x-workpoint-control"]?.type === FormControlType.DataSource) {
        obj[p.name] = defaultDataSource();
      } else if (p["x-workpoint-control"]?.type !== FormControlType.Dropdown) {
        obj[p.name] = defaultExpression();
      }
    });
    fieldArray.append(obj);
  };

  const onDelete = (id: string, index: number): void => {
    fieldArray.remove(index);
  };

  const onOrderChange = (id: string, direction: OrderDirection, index: number): void => {
    if (direction === OrderDirection.Up) {
      if (index > 0) {
        fieldArray.move(index, index - 1);
      }
    } else {
      if (index < fieldArray.fields.length - 1) {
        fieldArray.move(index, index + 1);
      }
    }
  };

  const checkModified = (name: string) => {
    let modified = false;
    const val = props.getValues(name);
    if (val) {
      Object.keys(val).forEach((v) => {
        if (!isExpressionEmpty(val[v], false)) {
          modified = true;
        }
      });
    }
    return modified;
  };

  const allTabNames = Object.keys(definition["x-workpoint-dynamic-configuration"]);

  return (
    <>
      <AddFieldContainer>
        {allTabNames.length > 1 ? (
          <DefaultButton
            text="New configuration"
            iconProps={{ iconName: "Add" }}
            menuProps={{
              items: allTabNames.map((tabName: any) => ({
                key: tabName,
                text: definition["x-workpoint-dynamic-configuration"][tabName].title ?? tabName,
                onClick: () =>
                  addTab(tabName, definition["x-workpoint-dynamic-configuration"][tabName])
              }))
            }}
          />
        ) : (
          <DefaultButton
            text={definition["x-workpoint-dynamic-configuration"][allTabNames[0]].title ?? "New"}
            iconProps={{ iconName: "Add" }}
            onClick={() =>
              addTab(
                allTabNames[0],
                definition["x-workpoint-dynamic-configuration"][allTabNames[0]]
              )
            }
          />
        )}
      </AddFieldContainer>
      <AccordionListContainer>
        {fieldArray?.fields?.map((field: any, index: number) => {
          return (
            <AccordionControl
              definition={definition}
              key={`${field.key}${index}`}
              expanded={false}
              modified={checkModified(`${name}[${index}]`)}
              onDelete={(id) => onDelete(id, index)}
              onOrderChange={(id: string, direction: OrderDirection) =>
                onOrderChange(id, direction, index)
              }
              title={
                field.title?.defaultText ??
                `${field.type} ${
                  field[
                    definition["x-workpoint-dynamic-configuration"][field.type]?.parameters[0]?.name
                  ]?.data ?? ""
                }`
              }
              showDelete={true}
              showSorting={true}
            >
              {definition["x-workpoint-dynamic-configuration"][field.type]?.title !== undefined && (
                <ParameterPropertyControl
                  key={`${definition.name}valuedata${index}`}
                  control={control}
                  setValue={props.setValue}
                  trigger={props.trigger}
                  name={`${name}[${index}]`}
                  label="Title"
                  description="The title that will be used to display your field"
                  defaultValue={field.title}
                  propertyType={ProcessParameterProperty.Title}
                  controlType={FormControlType.PlainText}
                  required={true}
                  definition={{ name: "" } as any}
                  dynamic
                  enableLocalization={true}
                  hideConditions
                  {...(props.getCustomizedControlParams
                    ? props.getCustomizedControlParams(
                        { name: "" } as any,
                        `${name}[${index}]`,
                        ProcessParameterProperty.Title
                      )
                    : undefined)}
                  stepConfiguration={props.stepConfiguration}
                  rows={1}
                />
              )}
              {definition["x-workpoint-dynamic-configuration"][field.type]?.parameters.map(
                (p: OpenAPIV2.Parameter) => {
                  return (
                    <ParameterControl
                      key={`${definition.name}valuedata${index}${p.name}`}
                      control={control}
                      setValue={props.setValue}
                      trigger={props.trigger}
                      required={p.required ?? false}
                      definition={p}
                      register={props.register}
                      unregister={props.unregister}
                      fieldValues={dynamicFieldValues?.[field.key]}
                      getValues={props.getValues}
                      document={props.document}
                      dynamic
                      name={`${name}[${index}].${p.name}`}
                      skipPropertyTypeInName={true}
                      getCustomizedControlParams={props.getCustomizedControlParams}
                      stepConfiguration={props.stepConfiguration}
                      rows={p["x-workpoint-control"]?.rows}
                      hideConditions={p["x-workpoint-control"]?.type === FormControlType.Dropdown}
                    />
                  );
                }
              )}
            </AccordionControl>
          );
        })}
      </AccordionListContainer>
    </>
  );
};
