import React, { useState, useEffect, useRef } from "react";
import {
  Control,
  FieldValues,
  FormProvider,
  useForm,
  UseFormSetValue,
  UseFormTrigger,
  useWatch
} from "react-hook-form";
import { Pivot, PivotItem, ProgressIndicator } from "@fluentui/react";
import { ControlledTextField } from "../form/ControlledTextField";
import styled from "styled-components";
import { OpenAPIV2 } from "openapi-types";
import {
  ProcessStepConfiguration,
  ProcessParameterProperty,
  ProcessTrigger,
  ProcessStepParameterConfiguration
} from "@workpoint/components/lib/models/ProcessConfiguration";
import { AdminConfiguration } from "@workpoint/components/lib/models/AdminConfiguration";
import { ParameterControl } from "./ParameterControl";
import { ParameterLocalizationPanel } from "../../process/detail/localization/parameterLocalizationPanel";
import { DynamicDataDefinition } from "../../../utils/swaggerUtils";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { usePrevious } from "../../../utils/hooks";
import { internalParameters, isProcessExpressionModel } from "../../../utils/processUtils";
import { isEqualOrEmpty } from "../../../utils/commonUtils";
import { ProcessValidationMessage } from "../../../models/ProcessValidation";
import { globalSelector, setSelectedPivot } from "../../../store/globalReducer";
import { evaluateProcessExpressionLimited } from "@workpoint/components/lib/helpers/expressionUtils";
import { ProcessContext } from "@workpoint/components/lib/models/ProcessInstance";
import { ParameterPropertyControlParams } from "./ParameterPropertyControl";
import { ControlledNumber } from "../form/ControlledNumber";
import { ControlledDropdown } from "../form/ControlledDropdown";
import { get } from "lodash";

export interface ParametersFormProps {
  formTitle: string;
  close(): any;
  update(data: any, context?: ProcessContext, closePanel?: boolean): ProcessValidationMessage[];
  document: OpenAPIV2.Document;
  definition?: OpenAPIV2.OperationObject;
  configuration?: ProcessStepConfiguration | ProcessTrigger | AdminConfiguration;
  onRender?: (
    pivots: {
      [pivotName: string]: JSX.Element[];
    },
    control: Control<FieldValues>,
    openLocalizationPanel: (name: string, title: string) => void,
    setValue: UseFormSetValue<any>,
    trigger: UseFormTrigger<any>,
    context: any
  ) => void;
  additionalPivots?: (
    control: Control<FieldValues>,
    openLocalizationPanel: (name: string, title: string) => void,
    setValue: UseFormSetValue<any>,
    trigger: UseFormTrigger<any>,
    getValues: any,
    register: any,
    unregister: any
  ) => JSX.Element[];
  validationMessages: ProcessValidationMessage[];
  dynamicData?: any;
  getDefaultFormData: (
    configuration?: ProcessStepConfiguration | ProcessTrigger | AdminConfiguration
  ) => any;
  contextInit: (fieldValues: Record<string, any>, selectedPivot?: string) => void;
  getCustomizedControlParams?: (
    definition: OpenAPIV2.Parameter,
    name: string,
    propertyType: ProcessParameterProperty
  ) => Partial<ParameterPropertyControlParams>;
  customControls?: { [key: string]: (props: ParameterPropertyControlParams) => JSX.Element };
}

export enum DynamicDataType {
  Values,
  Schema
}

export interface DynamicData {
  loading: boolean;
  name: string;
  data: any;
  type: DynamicDataType;
  parameters: string[];
}

export enum PropertyExpressionType {
  Basic = "rules",
  Advanced = "expression"
}

export enum FormControlType {
  Text = "text",
  Dropdown = "dropdown",
  Number = "number",
  Boolean = "boolean",
  Template = "template",
  ValuePicker = "valuePicker",
  DynamicSchema = "dynamicSchema",
  RichText = "richText",
  PlainText = "plainText",
  SubProcess = "subProcess",
  QueryFilter = "queryFilter",
  DataSource = "dataSource",
  Conditions = "conditions"
}

const _getDefaultFieldValues = (
  configuration?: ProcessStepConfiguration | ProcessTrigger | AdminConfiguration,
  context?: any
) => {
  const fieldValues: any = {};
  configuration?.parameters?.forEach((p: ProcessStepParameterConfiguration) => {
    fieldValues[p.name] = evaluateProcessExpressionLimited(p.value, context) ?? p?.value?.data;
  });

  return fieldValues;
};

const _getParentParametersRecursive = (
  parentParameters: string[],
  paramRef: OpenAPIV2.SchemaObject,
  dynamicData?: any
) => {
  if (paramRef.properties) {
    Object.values(paramRef.properties).forEach((property) => {
      if (property.properties) {
        _getDynamicParameters(property as OpenAPIV2.Parameter, parentParameters, dynamicData);
        _getParentParametersRecursive(parentParameters, property, dynamicData);
      } else {
        _getDynamicParameters(property as OpenAPIV2.Parameter, parentParameters, dynamicData);
      }
    });
  }
  return parentParameters;
};

const _getParentParameters = (definition?: OpenAPIV2.OperationObject, dynamicData?: any) => {
  const parentParameters: string[] = [];
  definition?.parameters?.forEach((param) => {
    const paramConfig = param as OpenAPIV2.Parameter;
    _getDynamicParameters(param as OpenAPIV2.Parameter, parentParameters, dynamicData);
    if (paramConfig?.schema) {
      _getParentParametersRecursive(parentParameters, paramConfig?.schema, dynamicData);
    }
  });
  return parentParameters;
};

const _getDynamicParameters = (
  paramConfig: OpenAPIV2.Parameter,
  parentParameters: string[],
  dynamicData?: any
) => {
  ["x-ms-dynamic-schema", "x-ms-dynamic-values"].forEach((dynamicPropName) => {
    const dynamicDefinition: DynamicDataDefinition = paramConfig[dynamicPropName]?.parameters
      ? paramConfig[dynamicPropName]
      : paramConfig.schema?.[dynamicPropName]?.parameters
      ? paramConfig.schema[dynamicPropName]
      : undefined;
    if (dynamicDefinition?.parameters) {
      Object.keys(dynamicDefinition.parameters).forEach((paramName) => {
        if (parentParameters.indexOf(dynamicDefinition.parameters[paramName].parameter) < 0) {
          parentParameters.push(dynamicDefinition.parameters[paramName].parameter);
        }
      });
    }
  });

  if (paramConfig?.schema?.["x-ms-dynamic-schema"] && dynamicData?.[paramConfig.name]) {
    Object.keys(dynamicData[paramConfig.name]).forEach((paramName) => {
      const fieldsParamDefinition = dynamicData[paramConfig.name][paramName];
      ["x-ms-dynamic-schema", "x-ms-dynamic-values"].forEach((dynamicPropName) => {
        const dynamicDefinition2: DynamicDataDefinition = fieldsParamDefinition[dynamicPropName]
          ?.parameters
          ? fieldsParamDefinition[dynamicPropName]
          : fieldsParamDefinition.schema?.[dynamicPropName]?.parameters
          ? fieldsParamDefinition.schema[dynamicPropName]
          : undefined;
        if (dynamicDefinition2?.parameters) {
          Object.keys(dynamicDefinition2.parameters).forEach((paramName) => {
            if (parentParameters.indexOf(dynamicDefinition2.parameters[paramName].parameter) < 0) {
              parentParameters.push(
                `${paramConfig.name}.${dynamicDefinition2.parameters[paramName].parameter}`
              );
            }
          });
        }
      });
    });
  }
};

export const ParametersForm = (props: ParametersFormProps) => {
  const { dynamicData, validationMessages, getDefaultFormData } = props;
  const { selectedPivot, loadingContext, context } = useAppSelector(globalSelector);
  const [formControls, setFormControls] = useState<{
    controls: JSX.Element[];
    reset: boolean;
  }>({ controls: [], reset: false });
  const dispatch = useAppDispatch();
  const [localizationPanelProps, setLocalizationPanelProps] = useState<
    { name: string; title: string } | undefined
  >(undefined);
  const defaultValues = useRef<any>(getDefaultFormData(props.configuration));

  const defaultStaticFieldValues = _getDefaultFieldValues(props.configuration, context);

  const formHandler = useForm({
    mode: "onBlur",
    defaultValues: defaultValues.current,
    resolver: (values: any, ctx?: object, options?: Object) => {
      let err: any = {};
      const errorss: ProcessValidationMessage[] = props.update(values, context, false);
      errorss.forEach(
        (error) =>
          (err[`${error.formFieldName}`] = {
            type: error.type,
            message: error.message
          })
      );
      defaultValues.current = values;
      return { values, errors: err };
    }
  });

  const {
    control,
    setValue,
    trigger,
    reset,
    setError,
    register,
    unregister,
    getValues,
    // handleSubmit,
    formState: { errors }
  } = formHandler;

  const [fieldValues, setFieldValues] = useState<Record<string, any>>(defaultStaticFieldValues);
  const watchAllFields: any = useWatch({ defaultValue: defaultValues, control });

  const prevConfig = usePrevious(props.configuration);
  const prevDynamicData = usePrevious(dynamicData);
  const prevFieldValues = usePrevious(fieldValues);
  const prevSelectedPivot = usePrevious(selectedPivot);

  useEffect(() => {
    defaultValues.current = getDefaultFormData(props.configuration);
    if (prevConfig?.id !== props.configuration?.id) {
      if (prevConfig) {
        reset(defaultValues.current, {
          keepErrors: false,
          keepDirty: false,
          keepValues: false,
          keepDefaultValues: false,
          keepIsSubmitted: false,
          keepTouched: false,
          keepIsValid: false,
          keepSubmitCount: false
        });
      }
      setErrors();
      setFormControls({ controls: [], reset: true });
      //_setControls();
    }
  }, [props.configuration]);

  const setErrors = () => {
    validationMessages.forEach((msg) => {
      if (msg.stepId === props.configuration?.id) {
        setError(msg.formFieldName!, { message: msg.message });
      }
    });
  };

  useEffect(() => {
    _setControls();
  }, [fieldValues]);

  useEffect(() => {
    if (formControls.reset) {
      _setControls();
    }
  }, [formControls]);

  useEffect(() => {
    if (
      prevConfig?.id !== props.configuration?.id ||
      prevSelectedPivot !== selectedPivot ||
      !isEqualOrEmpty(dynamicData, prevDynamicData) ||
      !isEqualOrEmpty(fieldValues, prevFieldValues)
    ) {
      props.contextInit(
        fieldValues,
        prevSelectedPivot && prevSelectedPivot !== selectedPivot ? selectedPivot : undefined
      );
    }
  }, [props.configuration, fieldValues, dynamicData, selectedPivot]);

  useEffect(() => {
    if (!watchAllFields.current && Object.keys(watchAllFields).length > 0) {
      let formFieldValueHasChanged = false;
      const parentParameters = _getParentParameters(
        props.definition,
        props.configuration?.id ? dynamicData?.[props.configuration.id] : undefined
      );
      parentParameters.forEach((paramName) => {
        if (internalParameters.indexOf(paramName) < 0) {
          const evaluatedValue = evaluateProcessExpressionLimited(
            get(watchAllFields, paramName)?.[ProcessParameterProperty.Value],
            context
          );
          if (
            !isEqualOrEmpty(
              get(fieldValues, paramName.split(".").pop() ?? paramName),
              evaluatedValue
            )
          ) {
            formFieldValueHasChanged = true;
          }
        }
      });
      if (formFieldValueHasChanged) {
        var newFieldValues = _getFieldValues();
        setFieldValues(newFieldValues);
      }
      // if (!isObjectEqual(watchAllFields, defaultValues)) {
      //   _update(watchAllFields, false);
      // }
    }
  }, [watchAllFields]);

  const _getFieldValues = () => {
    const fieldValues: any = {};
    Object.keys(watchAllFields).forEach((key) => {
      if (typeof watchAllFields[key] === "object") {
        if ("value" in watchAllFields[key]) {
          fieldValues[key] = evaluateProcessExpressionLimited(watchAllFields[key].value, context);
        } else {
          Object.keys(watchAllFields[key]).forEach((x) => {
            if (typeof watchAllFields[key][x] === "object" && "value" in watchAllFields[key][x]) {
              fieldValues[x] = evaluateProcessExpressionLimited(
                watchAllFields[key][x].value,
                context
              );
            }
          });
        }
      }
    });
    return fieldValues;
  };

  const _setControlsRecursive = (
    pivots: { [pivotName: string]: JSX.Element[] },
    paramRef: OpenAPIV2.SchemaObject
  ) => {
    Object.keys(paramRef.properties!).forEach((paramPropName) => {
      if (
        paramRef.properties![paramPropName].properties &&
        !isProcessExpressionModel(paramRef.properties![paramPropName]) &&
        Object.keys(paramRef.properties![paramPropName].properties!).length > 0
      ) {
        _setControlsRecursive(pivots, paramRef.properties![paramPropName]);
      } else if (
        (paramRef.properties![paramPropName]["x-ms-visibility"] !== "internal" &&
          !paramRef.properties![paramPropName]["x-workpoint-visibility"]) ||
        (paramRef.properties![paramPropName]["x-workpoint-visibility"] &&
          paramRef.properties![paramPropName]["x-workpoint-visibility"] !== "internal")
      ) {
        const required =
          paramRef.required !== undefined && paramRef.required.indexOf(paramPropName) > -1;
        const paramGroupName = paramRef.properties![paramPropName]["x-workpoint-parameter-group"]
          ? paramRef.properties![paramPropName]["x-workpoint-parameter-group"]
          : "General";
        if (!pivots[paramGroupName]) {
          pivots[paramGroupName] = [];
        }
        pivots[paramGroupName].push(
          <ParameterControl
            key={paramPropName}
            control={control}
            setValue={setValue}
            register={register}
            unregister={unregister}
            trigger={trigger}
            definition={{ name: paramPropName, ...paramRef.properties![paramPropName] } as any}
            fieldValues={fieldValues}
            required={required}
            document={props.document}
            stepConfiguration={props.configuration}
            getValues={getValues}
            getCustomizedControlParams={props.getCustomizedControlParams}
          />
        );
      }
    });
  };

  const _setControls = () => {
    const controls: JSX.Element[] = [];
    let pivots: { [pivotName: string]: JSX.Element[] } = {
      General: []
    };

    props.definition?.parameters?.forEach((param) => {
      const paramConfig = param as OpenAPIV2.Parameter;
      if (internalParameters.indexOf(paramConfig.name) < 0) {
        if (
          paramConfig.schema &&
          paramConfig.schema.properties &&
          Object.keys(paramConfig.schema.properties).length > 0 &&
          !isProcessExpressionModel(paramConfig.schema.properties)
        ) {
          const paramRef = paramConfig.schema as OpenAPIV2.SchemaObject;
          _setControlsRecursive(pivots, paramRef);
        } else if (
          (paramConfig["x-ms-visibility"] !== "internal" &&
            !paramConfig["x-workpoint-visibility"]) ||
          (paramConfig["x-workpoint-visibility"] &&
            paramConfig["x-workpoint-visibility"] !== "internal")
        ) {
          const paramGroupName = paramConfig["x-workpoint-parameter-group"]
            ? paramConfig["x-workpoint-parameter-group"]
            : "General";
          if (!pivots[paramGroupName]) {
            pivots[paramGroupName] = [];
          }
          pivots[paramGroupName].push(
            <ParameterControl
              key={paramConfig.name}
              control={control}
              setValue={setValue}
              register={register}
              unregister={unregister}
              trigger={trigger}
              definition={paramConfig}
              fieldValues={fieldValues}
              required={paramConfig.required ?? false}
              document={props.document}
              stepConfiguration={props.configuration}
              getValues={getValues}
              getCustomizedControlParams={props.getCustomizedControlParams}
              customControls={props.customControls}
            />
          );
        }
      }
    });

    if (props.onRender !== undefined) {
      props.onRender(pivots, control, openParameterLocalizationPanel, setValue, trigger, context);
    }

    Object.keys(pivots).forEach((key) => {
      controls.push(
        <PivotItem key={key} headerText={key} itemKey={key} alwaysRender={true}>
          {pivots[key].map((item) => item)}
        </PivotItem>
      );
    });

    if (props.additionalPivots) {
      controls.push(
        ...props.additionalPivots(
          control,
          openParameterLocalizationPanel,
          setValue,
          trigger,
          getValues,
          register,
          unregister
        )
      );
    }

    setFormControls({ controls, reset: false });
  };

  const openParameterLocalizationPanel = (name: string, title: string) => {
    setLocalizationPanelProps({ name, title });
  };

  return (
    <FormProvider {...formHandler}>
      <StyledPanelContainer>
        <LoadingContainer>
          {loadingContext && <ProgressIndicator label="" description="" />}
        </LoadingContainer>
        <form>
          <Pivot
            selectedKey={selectedPivot}
            onLinkClick={(item) => {
              dispatch(setSelectedPivot(item?.props.itemKey ?? ""));
            }}
          >
            {formControls.controls}
          </Pivot>
        </form>
      </StyledPanelContainer>
      {localizationPanelProps !== undefined && (
        <ParameterLocalizationPanel
          localizationId={localizationPanelProps.name}
          parameterTitle={localizationPanelProps.title}
          onClose={() => {
            setLocalizationPanelProps(undefined);
          }}
        />
      )}
    </FormProvider>
  );
};

const StyledPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 15px;
`;

export const StyledFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const StyledControlledTextField = styled(ControlledTextField)`
  flex: 1;
  width: 100%;
`;

export const StyledControlledNumber = styled(ControlledNumber)`
  flex: 1;
  width: 100%;
`;

export const StyledControlledDropdown = styled(ControlledDropdown)`
  flex: 1;
  width: 100%;
`;

const LoadingContainer = styled.div`
  height: 2px;
`;
