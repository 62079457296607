import { DefaultButton, Stack } from "@fluentui/react";
import styled from "styled-components";

export const ExpandableHeader = ({ className, children, title, isExpanded, toggleExpand }: any) => {
  const root = {
    fontSize: "12px",
    backgroundColor: "rgb(243, 242, 241)",
    borderRadius: "100px",
    width: "17px",
    height: "17px",
    border: "3px solid rgb(243, 242, 241)"
  };
  return (
    <Stack>
      <ExpandableButton
        onClick={toggleExpand}
        iconProps={
          isExpanded
            ? {
                iconName: "ChevronUp",
                styles: { root }
              }
            : {
                iconName: "ChevronDown",
                styles: { root }
              }
        }
      >
        {title}
      </ExpandableButton>
      {isExpanded && children}
    </Stack>
  );
};

const ExpandableButton = styled(DefaultButton)`
  background-color: rgba(0, 0, 0, 0);
  border-width: 0px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
`;
