import React, { FC, useState } from "react";
import { Control, Controller, UseControllerProps } from "react-hook-form";
import { ITextFieldProps, TextField } from "@fluentui/react";

export interface FormControlProps {
  control: Control<any>;
  name: string;
  rules?: UseControllerProps["rules"];
  defaultValue?: number;
}

export const ControlledNumber: FC<FormControlProps & ITextFieldProps> = (props) => {
  const [numberValue, setNumberValue] = useState<string>();
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue}
      render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => {
        let loadValue = numberValue ?? value;
        if (getNumberValue(numberValue) != value) {
          loadValue = value;
        }
        return (
          <TextField
            {...props}
            onChange={(
              event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              newValue?: string
            ) => {
              const updatedValue = getNumberValue(newValue);
              setNumberValue(newValue);
              onChange(updatedValue);
              if (props.onChange) props.onChange(event, updatedValue as any);
            }}
            value={loadValue}
            onBlur={onBlur}
            name={fieldName}
            errorMessage={error && error.message}
            defaultValue={undefined}
            type={"number"}
          />
        );
      }}
    />
  );
};

const getNumberValue = (newValue?: string) => {
  if (newValue && !isNaN(newValue as any)) {
    return Number(newValue);
  }
  return "";
};
