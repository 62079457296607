import { FC } from "react";
import { Controller } from "react-hook-form";
import { IButtonStyles, DefaultButton, Icon, ITextFieldProps } from "@fluentui/react";
import { FormControlProps } from "./ControlledTextField";

const styles: IButtonStyles = {
  root: {
    backgroundColor: "#ebebeb;",
    color: "#474747",
    border: "none"
  },

  rootCheckedHovered: {
    backgroundColor: "#F7941E",
    color: "white",
    fontWeight: "bold"
  },

  rootChecked: {
    backgroundColor: "#F7941E",
    color: "white",
    fontWeight: "bold"
  }
};

export interface IEnableDisableButtonProps extends ITextFieldProps {
  onText: string;
  offText: string;
}

export const ControlledEnableDisableButton: FC<FormControlProps & IEnableDisableButtonProps> = (
  props
) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || ""}
      render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => {
        const changeCallback = (value: boolean) => {
          onChange(value);
          onBlur();
        };

        return (
          <>
            <DefaultButton
              styles={styles}
              {...(value === false && {
                onRenderText: iconRendering
              })}
              onBlur={onBlur}
              disabled={props.readOnly}
              text={props.offText}
              onClick={() => !props.disabled && changeCallback(false)}
              defaultChecked={props.defaultValue}
              checked={value === false}
            />
            <DefaultButton
              styles={styles}
              {...(value === true && {
                onRenderText: iconRendering
              })}
              onBlur={onBlur}
              disabled={props.readOnly}
              text={props.onText}
              onClick={() => !props.disabled && changeCallback(true)}
              defaultChecked={props.defaultValue}
              checked={value === true}
            />
          </>
        );
      }}
    />
  );
};

/**
 * Used to place Icon on the right side of control.
 */
const iconRendering = (props: any) => (
  <div>
    {props.text} <Icon iconName="Accept" />
  </div>
);
