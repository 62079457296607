import React, { useEffect, useState } from "react";
import {
  CommandButton,
  IconButton,
  IDropdownOption,
  IPanelProps,
  Label,
  Panel,
  PanelType,
  PrimaryButton,
  Stack
} from "@fluentui/react";
import {
  Control,
  FieldValues,
  useFieldArray,
  UseFormSetValue,
  UseFormTrigger,
  useWatch
} from "react-hook-form";
import styled from "styled-components";
import { Endpoint } from "@workpoint/components/lib/models/Endpoint";
import {
  DataSource,
  DataSourceParameterDisplayType,
  DataSourceParameterType,
  DataSourceType,
  ProcessParameterProperty
} from "@workpoint/components/lib/models/ProcessConfiguration";
import { ControlledTextField } from "../form/ControlledTextField";
import { ConditionsPanel } from "./ConditionsPanel";
import { ControlledBoolean } from "../form/ControlledBoolean";
import { ParameterLocalizationPanel } from "../../process/detail/localization/parameterLocalizationPanel";
import { PanelHeader } from "../PanelHeader";
import { ControlledDropdown } from "../form/ControlledDropdown";
import {
  defaultDataSource,
  defaultExpression,
  getSchemaObject,
  isConditionsEmpty,
  isExpressionEmpty
} from "../../../utils/processUtils";
import { get } from "lodash";
import FieldInfo from "../FieldInfo";
import { ControlledObjectBrowser } from "./ObjectBrowser";
import { v4 as uuid } from "uuid";
import $RefParser from "@apidevtools/json-schema-ref-parser";
import { ControlledValuePicker } from "./ControlledValuePicker";
import { OpenAPIV2 } from "openapi-types";
import { AccordionControl, AccordionListContainer } from "./AccordionControl";
import { ParameterPropertyControl } from "./ParameterPropertyControl";
import { FormControlType } from "./ParametersForm";
import { useAppSelector } from "../../../store/hooks";
import { processSelector } from "../../../store/processReducer";
import { useApiClient } from "@workpoint/components/lib/clients/ApiProvider";

interface IDataSourcePanelProps {
  name: string;
  fieldDescription: string;
  definition: OpenAPIV2.Parameter;
  dataSource?: DataSource;
  onClose?: () => void;
  control: Control<FieldValues>;
  setValue: UseFormSetValue<any>;
  trigger: UseFormTrigger<any>;
}

const DataSourcePanel: React.FunctionComponent<IPanelProps & IDataSourcePanelProps> = (props) => {
  const [endpointSchema, setEndpointSchema] = useState<any>({});
  const watchDataSourceFields: any = useWatch({ name: props.name, control: props.control });
  const { step } = useAppSelector(processSelector);
  const { apiClient } = useApiClient();
  const fieldsWatch = useWatch({ control: props.control, name: props.name + ".tabs" });

  const [contextExtension, setContextExtension] = useState<any>();

  const _getDefaultValue = (name: string) => {
    return get(watchDataSourceFields, name);
  };

  const dataSourceValue: DataSource = useWatch({
    name: props.name,
    control: props.control
  });
  const dataSourceType: string = useWatch({
    name: `${props.name}.type`,
    control: props.control
  });
  const endpointSchemaPath: string = useWatch({
    name: `${props.name}.path.data`,
    control: props.control
  });
  const dataSourceEndpoint: string = useWatch({
    name: `${props.name}.endpoint`,
    control: props.control
  });
  const tabsArray = useFieldArray({
    control: props.control,
    name: props.name + ".tabs"
  });
  const parametersArray = useFieldArray({
    control: props.control,
    name: props.name + ".parameters"
  });

  useEffect(() => {
    let paramNames: any = {};
    parametersArray.fields.map((field: any, index: any) => {
      paramNames[field.name] = "";
    });

    if (
      JSON.stringify(contextExtension?.full?.SearchQuery) !==
      JSON.stringify({ Default: "", ...paramNames })
    ) {
      let Element = { ...paramNames };
      if (
        props.definition["x-workpoint-field"]?.type === "User" ||
        props.definition["x-workpoint-field"]?.type === "UserMulti" ||
        props.definition.dataType === "User" ||
        props.definition.dataType === "UserMulti"
      ) {
        Element = {
          ...Element,
          PrincipalType: "PrincipalType",
          UserPrincipalName: "UserPrincipalName",
          Email: "Email",
          DisplayName: "DisplayName",
          Department: "Department",
          JobTitle: "JobTitle"
        };
      }
      setContextExtension({
        full: { SearchQuery: { Default: "", ...paramNames }, Element },
        stripped: { SearchQuery: { Default: "", ...paramNames } }
      });
    }
  }, [parametersArray]);

  const [conditionsPanelProps, setConditionsPanelProps] = useState<
    { name: string; title: string; contextExtension?: any } | undefined
  >(undefined);
  const [dataSourcePanelProps, setDataSourcePanelProps] = useState<
    { name: string; title: string } | undefined
  >(undefined);
  const [localizationPanelProps, setLocalizationPanelProps] = useState<
    { name: string; title: string } | undefined
  >(undefined);
  const [endpoints, setEndpoints] = useState<Endpoint[]>([]);

  useEffect(() => {
    apiClient.getEndpoints().then((result) => {
      result.sort((a, b) =>
        a.title.toLowerCase() > b.title.toLowerCase()
          ? 1
          : a.title.toLowerCase() < b.title.toLowerCase()
          ? -1
          : 0
      );
      setEndpoints(result);
    });
  }, []);

  const setData = async () => {
    try {
      const endpointConfig = endpoints.find((endpoint) => endpoint.id === dataSourceEndpoint);
      if (endpointConfig && endpointConfig.schema) {
        let obj = JSON.parse(endpointConfig.schema);
        let schema = await $RefParser.dereference(obj);
        const dataPayload = getSchemaObject(schema).data;
        setEndpointSchema(dataPayload);
      }
    } catch (error) {
      const err = error;
      setEndpointSchema({});
    }
  };
  useEffect(() => {
    if (endpoints && dataSourceEndpoint) {
      setData();
    }
  }, [endpoints, dataSourceEndpoint]);

  const _onTypeChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption | undefined,
    index?: number | undefined
  ) => {
    props.setValue(`${props.name}.endpoint`, undefined, {});
    props.setValue(`${props.name}.property`, defaultExpression(), {});
    props.setValue(`${props.name}.filter`, defaultExpression(), {});
    props.setValue(`${props.name}.typeahead`, true, {});
    props.setValue(`${props.name}.anchor`, defaultExpression(), {});
    props.setValue(`${props.name}.parameters`, [], {
      shouldValidate: true
    });
  };

  const _onEndpointChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption | undefined,
    index?: number | undefined
  ) => {
    if (option) {
      props.setValue(`${props.name}.filter`, defaultExpression(), {});
      props.setValue(`${props.name}.multi`, false, {});
      props.setValue(`${props.name}.path`, defaultExpression(), {});
      props.setValue(`${props.name}.valueProperty`, defaultExpression(), {});
      props.setValue(`${props.name}.titleProperty`, defaultExpression(), {});
      props.setValue(`${props.name}.descriptionProperty`, defaultExpression(), {});
      props.setValue(`${props.name}.typeahead`, true, {});
      props.setValue(`${props.name}.tabs`, [], {
        shouldValidate: true
      });
      props.setValue(
        `${props.name}.parameters`,
        (option.data as Endpoint).parameters.map((p) => {
          return {
            name: p,
            value: defaultExpression(),
            title: {
              id: `${step?.configuration?.id}-${props.name}-params-${p}-title`,
              defaultText: ""
            },
            description: {
              id: `${step?.configuration?.id}-${props.name}-params-${p}-description`,
              defaultText: ""
            }
          };
        }) ?? [],
        {
          shouldValidate: true
        }
      );
    }
  };

  const _onRenderHeader = () => {
    return (
      <PanelHeader
        title="Data binding"
        closePanel={props.onClose!}
        iconName="LinkedDatabase"
        additionalButtons={[
          <CommandButton
            iconProps={{ iconName: "Delete" }}
            text="Clear"
            onClick={() => {
              props.setValue(
                `${props.name}`,
                defaultDataSource(
                  dataSourceValue?.title?.id ?? `${props.name}-title`,
                  dataSourceValue?.errorMessage?.id ?? `${props.name}-errorMessage`
                )
              );
              tabsArray.replace([]);
              // props.setValue(`${props.name}.parameters`, [], {});
              // props.setValue(`${props.name}.tabs`, [], {});
            }}
          />
        ]}
      >
        <FieldInfo label1={"Field"} label2={props.fieldDescription} iconName={"LinkedDatabase"} />
      </PanelHeader>
    );
  };

  let endpointSchemaObject = endpointSchema;
  if (endpointSchemaPath) {
    endpointSchemaObject = get(endpointSchema, endpointSchemaPath);
    if (!endpointSchemaObject) {
      endpointSchemaObject = get(endpointSchema, `${endpointSchemaPath}[0]`);
    }
    if (Array.isArray(endpointSchemaObject)) {
      endpointSchemaObject = endpointSchemaObject[0];
    }
  }
  const isParameterModified = (name: string): boolean | undefined => {
    const defaultValue = _getDefaultValue(name);
    if (defaultValue) {
      for (let i = 0; i < parametersArray.fields.length; i++) {
        const modified = !isExpressionEmpty(defaultValue[ProcessParameterProperty.Value]);
        if (modified) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <>
      <Panel
        isOpen={true}
        type={PanelType.smallFluid}
        onRenderHeader={_onRenderHeader}
        hasCloseButton={false}
        styles={{
          root: {
            width: "100%",
            borderLeftWidth: "1px",
            borderLeftStyle: "solid",
            borderLeftColor: "#E5E5E5"
          },
          main: { boxShadow: "0px 0px 0px 0px" },
          commands: { display: "none" }
        }}
        isLightDismiss={false}
        onDismiss={() => {}}
        focusTrapZoneProps={{
          isClickableOutsideFocusTrap: true,
          forceFocusInsideTrap: false
        }}
        {...props}
      >
        <ControlStack
          iconName="Translate"
          iconTitle="Translation"
          onIconClick={() => {
            setLocalizationPanelProps({ name: props.dataSource!.title!.id, title: "Title" });
          }}
        >
          <ControlledTextField
            name={props.name + ".title.defaultText"}
            label="Title"
            control={props.control}
            required={false}
          />
        </ControlStack>
        <ControlStack iconName="Info" iconTitle="Description" onIconClick={() => {}}>
          <ControlledDropdown
            name={props.name + ".type"}
            label="Type"
            control={props.control}
            required={false}
            options={getDataSourceTypeOptions(props.definition["x-workpoint-field"])}
            onChange={_onTypeChange}
          />
        </ControlStack>
        {dataSourceType === DataSourceType.Endpoint && (
          <>
            <ControlStack iconName="Info" iconTitle="Description" onIconClick={() => {}}>
              <ControlledDropdown
                name={props.name + ".endpoint"}
                label="Endpoint"
                control={props.control}
                required={false}
                options={endpoints?.map((data: Endpoint) => {
                  return {
                    key: data.id,
                    text: data.title,
                    data
                  };
                })}
                onChange={_onEndpointChange}
                styles={{ dropdownItemsWrapper: { maxHeight: 200 } }}
              />
            </ControlStack>
            <div>
              <Label>Parameters</Label>
              <hr />
            </div>
            {parametersArray.fields.map((field: any, index) => (
              <AccordionListContainer key={field.name}>
                <AccordionControl
                  definition={props.definition}
                  title={field.name}
                  key={field.name}
                  modified={isParameterModified(`parameters.${index}`)}
                >
                  <ParameterPropertyControl
                    control={props.control}
                    label="Title"
                    defaultValue={undefined}
                    definition={props.definition}
                    name={`${props.name}.parameters.${index}`}
                    propertyType={ProcessParameterProperty.Title}
                    required={true}
                    setValue={props.setValue}
                    trigger={props.trigger}
                    dynamic
                    controlType={FormControlType.Text}
                    hideConditions
                    enableLocalization
                    description="The title of the parameter."
                    contextExtension={contextExtension?.stripped}
                  />
                  <ParameterPropertyControl
                    control={props.control}
                    label="Description"
                    defaultValue={undefined}
                    definition={props.definition}
                    name={`${props.name}.parameters.${index}`}
                    propertyType={ProcessParameterProperty.Description}
                    required={false}
                    setValue={props.setValue}
                    trigger={props.trigger}
                    dynamic
                    controlType={FormControlType.Text}
                    hideConditions
                    enableLocalization
                    description="The description of the parameter."
                    contextExtension={contextExtension?.stripped}
                  />
                  <ParameterPropertyControl
                    control={props.control}
                    label="Type"
                    defaultValue={undefined}
                    definition={props.definition}
                    name={`${props.name}.parameters.${index}.type`}
                    propertyType={ProcessParameterProperty.DataType}
                    required={false}
                    setValue={props.setValue}
                    trigger={props.trigger}
                    skipPropertyTypeInName
                    dynamic
                    controlType={FormControlType.Dropdown}
                    hideConditions
                    options={Object.keys(DataSourceParameterType).map((type) => ({
                      key: type,
                      text: type
                    }))}
                    description="The type of the parameter."
                    contextExtension={contextExtension?.stripped}
                  />
                  {(dataSourceValue?.parameters?.[index]?.type === DataSourceParameterType.Choice ||
                    dataSourceValue?.parameters?.[index]?.type ===
                      DataSourceParameterType.MultiChoice) && (
                    <ParameterPropertyControl
                      control={props.control}
                      label="Choices"
                      defaultValue={undefined}
                      definition={{ "x-workpoint-multi": true } as any}
                      name={`${props.name}.parameters.${index}.choices`}
                      propertyType={ProcessParameterProperty.Value}
                      required={false}
                      setValue={props.setValue}
                      trigger={props.trigger}
                      skipPropertyTypeInName
                      dynamic
                      controlType={FormControlType.ValuePicker}
                      hideConditions
                      description="The choices of the parameter."
                      contextExtension={contextExtension?.stripped}
                    />
                  )}
                  <ParameterPropertyControl
                    control={props.control}
                    label="Display"
                    defaultValue={undefined}
                    definition={props.definition}
                    name={`${props.name}.parameters.${index}.display`}
                    propertyType={ProcessParameterProperty.DataType}
                    required={false}
                    setValue={props.setValue}
                    trigger={props.trigger}
                    skipPropertyTypeInName
                    dynamic
                    controlType={FormControlType.Dropdown}
                    hideConditions
                    options={Object.keys(DataSourceParameterDisplayType).map((type) => ({
                      key: type,
                      text: type
                    }))}
                    description="The display type of the parameter."
                    contextExtension={contextExtension?.stripped}
                  />
                  <ParameterPropertyControl
                    control={props.control}
                    label="Value"
                    defaultValue={undefined}
                    definition={props.definition}
                    name={`${props.name}.parameters.${index}`}
                    propertyType={ProcessParameterProperty.Value}
                    required={false}
                    setValue={props.setValue}
                    trigger={props.trigger}
                    dynamic
                    controlType={FormControlType.ValuePicker}
                    // key={field.name}
                    description="The value of the parameter."
                    contextExtension={contextExtension?.stripped}
                  />
                  <ParameterPropertyControl
                    control={props.control}
                    label="Query conditions"
                    defaultValue={undefined}
                    definition={props.definition}
                    name={`${props.name}.parameters.${index}`}
                    propertyType={ProcessParameterProperty.QueryConditions}
                    required={false}
                    setValue={props.setValue}
                    trigger={props.trigger}
                    dynamic
                    controlType={FormControlType.Conditions}
                    description="The conditions to be met before the query is executed."
                    // key={field.name}
                    contextExtension={contextExtension?.stripped}
                  />
                  <ParameterPropertyControl
                    control={props.control}
                    label="Query conditions message"
                    defaultValue={undefined}
                    definition={props.definition}
                    name={`${props.name}.parameters.${index}`}
                    propertyType={ProcessParameterProperty.ValidMessage}
                    required={false}
                    setValue={props.setValue}
                    trigger={props.trigger}
                    dynamic
                    controlType={FormControlType.PlainText}
                    description="The message when the query conditions aren't met."
                    contextExtension={contextExtension?.full}
                    rows={2}
                  />
                </AccordionControl>
              </AccordionListContainer>
            ))}
          </>
        )}
        {dataSourceType === DataSourceType.Taxonomy && (
          <ControlStack
            iconName="CodeEdit"
            iconTitle="Expression"
            onIconClick={() => {
              setConditionsPanelProps({
                title: "Anchor",
                name: `${props.name}.anchor`
              });
            }}
            modified={!isConditionsEmpty(_getDefaultValue("anchor"))}
          >
            <ControlledValuePicker
              name={props.name + ".anchor.data"}
              label="Anchor"
              control={props.control}
              required={false}
              disabled={false}
              defaultValue={undefined}
              setValue={props.setValue}
              trigger={props.trigger}
              propertyType={ProcessParameterProperty.DataSource}
              definition={{} as any}
            />
          </ControlStack>
        )}
        {dataSourceType && (
          <>
            <ControlStack
              iconName="CodeEdit"
              iconTitle="Expression"
              onIconClick={() => {
                setConditionsPanelProps({ title: "Filter", name: `${props.name}.filter` });
              }}
              modified={!isConditionsEmpty(_getDefaultValue("filter"))}
            >
              <ControlledValuePicker
                name={props.name + ".filter.data"}
                label="Filter"
                control={props.control}
                required={false}
                disabled={false}
                defaultValue={undefined}
                setValue={props.setValue}
                trigger={props.trigger}
                propertyType={ProcessParameterProperty.DataSource}
                definition={{} as any}
                contextExtension={contextExtension?.full}
              />
            </ControlStack>
            <ControlStack
              iconName="Translate"
              iconTitle="Translation"
              onIconClick={() => {
                setLocalizationPanelProps({
                  name: props.dataSource!.errorMessage!.id,
                  title: "Error message"
                });
              }}
            >
              <ControlStack
                iconName="CodeEdit"
                iconTitle="Expression"
                onIconClick={() => {
                  setConditionsPanelProps({
                    title: "Error message",
                    name: `${props.name}.errorMessage`,
                    contextExtension: {
                      ...contextExtension?.full,
                      Error: {
                        Message: "Message",
                        StatusCode: "StatusCode"
                      }
                    }
                  });
                }}
                modified={!isConditionsEmpty(_getDefaultValue("errorMessage"))}
              >
                <ControlledValuePicker
                  name={props.name + ".errorMessage.defaultText"}
                  label="Error message"
                  control={props.control}
                  required={false}
                  disabled={false}
                  defaultValue={undefined}
                  setValue={props.setValue}
                  trigger={props.trigger}
                  propertyType={ProcessParameterProperty.DataSource}
                  definition={{} as any}
                  contextExtension={{
                    Error: {
                      Message: "Message",
                      StatusCode: "StatusCode"
                    }
                  }}
                />
              </ControlStack>
            </ControlStack>
            {dataSourceType === DataSourceType.Endpoint && (
              <>
                <ControlStack>
                  <ControlledBoolean
                    name={props.name + ".multi"}
                    label="Multiselect"
                    control={props.control}
                  />
                </ControlStack>
                <ControlStack
                  iconName="CodeEdit"
                  iconTitle="Expression"
                  onIconClick={() => {
                    setConditionsPanelProps({
                      title: "Path",
                      name: `${props.name}.path`
                    });
                  }}
                  modified={!isConditionsEmpty(_getDefaultValue("path"))}
                >
                  <ControlledObjectBrowser
                    name={props.name + ".path.data"}
                    control={props.control}
                    label={"Path"}
                    object={endpointSchema}
                    setValue={props.setValue}
                  />
                </ControlStack>
                <ControlStack
                  iconName="CodeEdit"
                  iconTitle="Expression"
                  onIconClick={() => {
                    setConditionsPanelProps({
                      title: "Value Property",
                      name: `${props.name}.valueProperty`
                    });
                  }}
                  modified={!isConditionsEmpty(_getDefaultValue("valueProperty"))}
                >
                  <ControlledObjectBrowser
                    name={props.name + ".valueProperty.data"}
                    control={props.control}
                    label={"Value Property"}
                    object={endpointSchemaObject}
                    setValue={props.setValue}
                  />
                </ControlStack>
              </>
            )}
            <ControlStack
              iconName="CodeEdit"
              iconTitle="Expression"
              onIconClick={() => {
                setConditionsPanelProps({
                  title: "Title Property",
                  name: `${props.name}.titleProperty`
                });
              }}
              modified={!isConditionsEmpty(_getDefaultValue("titleProperty"))}
            >
              <ControlledObjectBrowser
                name={props.name + ".titleProperty.data"}
                control={props.control}
                label={"Title Property"}
                object={endpointSchemaObject}
                setValue={props.setValue}
              />
            </ControlStack>
            <ControlStack
              iconName="CodeEdit"
              iconTitle="Expression"
              onIconClick={() => {
                setConditionsPanelProps({
                  title: "Description Property",
                  name: `${props.name}.descriptionProperty`
                });
              }}
              modified={!isConditionsEmpty(_getDefaultValue("descriptionProperty"))}
            >
              <ControlledObjectBrowser
                name={props.name + ".descriptionProperty.data"}
                control={props.control}
                label={"Description Property"}
                object={endpointSchemaObject}
                setValue={props.setValue}
              />
            </ControlStack>
            {/* <ControlStack iconName="">
              <ControlledTextField
                name={props.name + ".itemDisplayTemplate.template"}
                label="Item Display Template"
                control={props.control}
                multiline={true}
                resizable
                required={false}
              />
            </ControlStack> */}
            <ControlStack>
              <ControlledBoolean
                name={props.name + ".typeahead"}
                label="Typeahead"
                control={props.control}
              />
            </ControlStack>
            <ControlStack>
              <ControlledBoolean
                name={props.name + ".linkTypeahead"}
                label="Link typeahead with panel"
                control={props.control}
              />
            </ControlStack>
            <ControlStack>
              <ControlledBoolean
                name={props.name + ".disableOpenOnFocus"}
                label="Disable panel opening on focus"
                control={props.control}
                onText={"Open panel manually"}
                offText={"Open panel on input focus"}
              />
            </ControlStack>
          </>
        )}
        {props.name.indexOf(".tabs.") < 0 && (
          <>
            <div style={{ paddingTop: 20 }}>
              <Label>Tabs</Label>
              <hr />
            </div>
            {fieldsWatch?.map((field: any, index: any) => (
              <ControlStack
                key={field.id}
                iconName="LinkedDatabase"
                iconTitle="Data source"
                onIconClick={() => {
                  setDataSourcePanelProps({
                    title: `Tab - ${index + 1}`,
                    name: `${props.name}.tabs.${index}`
                  });
                }}
                onDelete={() => {
                  tabsArray.remove(index);
                }}
                style={{ paddingLeft: "40px" }}
              >
                <>{field.title?.defaultText ?? "not defined"}</>
              </ControlStack>
            ))}
            <div style={{ textAlign: "right" }}>
              <PrimaryButton
                onClick={() => {
                  tabsArray.append(
                    defaultDataSource(
                      dataSourceValue.title?.id?.replace(
                        `-${ProcessParameterProperty.DataSource}-`,
                        `-${ProcessParameterProperty.DataSource}-tab-${uuid()}-`
                      ) ?? `${props.name}-title`,
                      dataSourceValue.errorMessage?.id?.replace(
                        `-${ProcessParameterProperty.DataSource}-`,
                        `-${ProcessParameterProperty.DataSource}-tab-${uuid()}-`
                      ) ?? `${props.name}-errorMessage`,
                      `Tab ${tabsArray.fields.length + 1}`
                    )
                  );
                }}
              >
                Add tab
              </PrimaryButton>
            </div>
          </>
        )}
      </Panel>
      {conditionsPanelProps !== undefined && (
        <ConditionsPanel
          name={conditionsPanelProps.name}
          fieldDescription={props.fieldDescription}
          propertyIconName={"LinkedDatabase"}
          propertyDescription={`Data Source - ${conditionsPanelProps.title}`}
          onClose={() => {
            setConditionsPanelProps(undefined);
          }}
          control={props.control}
          setValue={props.setValue}
          trigger={props.trigger}
          settings={{ hideComparison: false, addSearchQuery: true }}
          contextExtension={conditionsPanelProps.contextExtension ?? contextExtension?.full}
        />
      )}
      {localizationPanelProps !== undefined && (
        <ParameterLocalizationPanel
          localizationId={localizationPanelProps.name}
          parameterTitle={localizationPanelProps.title}
          onClose={() => {
            setLocalizationPanelProps(undefined);
          }}
        />
      )}
      {dataSourcePanelProps !== undefined && (
        <DataSourcePanel
          name={dataSourcePanelProps.name}
          fieldDescription={`${props.fieldDescription} - ${dataSourcePanelProps.title}`}
          onClose={() => {
            setDataSourcePanelProps(undefined);
          }}
          control={props.control}
          dataSource={props.dataSource}
          setValue={props.setValue}
          trigger={props.trigger}
          definition={props.definition}
        />
      )}
    </>
  );
};

const getDataSourceTypeOptions = (field: any): IDropdownOption[] => {
  const types: IDropdownOption[] = [
    {
      key: DataSourceType.Default,
      text: DataSourceType.Default
    },
    {
      key: DataSourceType.Endpoint,
      text: DataSourceType.Endpoint
    }
  ];
  if (
    field?.type === "Lookup" ||
    field?.type === "LookupMulti" ||
    field?.type === "User" ||
    field?.type === "UserMulti"
  ) {
    types.push({
      key: DataSourceType.Lookup,
      text: DataSourceType.Lookup
    });
  } else if (field?.type === "TaxonomyFieldType" || field?.type === "TaxonomyFieldTypeMulti") {
    types.push({
      key: DataSourceType.Taxonomy,
      text: "Managed Metadata"
    });
  }

  return types;
};

const ControlStack = styled(
  ({ className, children, iconName, iconTitle, onIconClick, icon2, style, modified, onDelete }) => (
    <Stack className={className} horizontal style={style}>
      <Stack.Item grow>{children}</Stack.Item>
      {icon2 !== undefined && <Stack.Item>{icon2}</Stack.Item>}
      {onDelete !== undefined && (
        <Stack.Item>
          <IconButton
            onClick={onDelete}
            iconProps={{ iconName: "Delete" }}
            title={"Delete"}
            ariaLabel={"Delete"}
          />
        </Stack.Item>
      )}
      {iconName !== undefined && (
        <Stack.Item>
          <IconButton
            onClick={onIconClick}
            iconProps={{ iconName }}
            title={iconTitle}
            ariaLabel={iconTitle}
            style={
              modified ? { borderRadius: "4px", backgroundColor: "rgba(247, 148, 30, 0.4)" } : {}
            }
          />
        </Stack.Item>
      )}
    </Stack>
  )
)`
  display: flex;
  align-items: flex-end;
`;

export default DataSourcePanel;
