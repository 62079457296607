import {
  FontIcon,
  Text,
  Stack,
  IStackStyles,
  DefaultButton,
  IButtonStyles,
  PivotLinkSize,
  Pivot,
  PivotItem,
  PivotLinkFormat,
  mergeStyleSets,
  IStyle,
  Spinner,
  SpinnerSize
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useApiClient } from "@workpoint/components/lib/clients/ApiProvider";
import WorkPointTheme from "@workpoint/components/lib/constants";
import { HierarchicalList } from "./HierarchicalList";
import { IQuickLinkProps } from "./QuickLink";
import { QuickLinkGroup } from "./QuickLinkGroup";
import { IStatus, TileCard, tileWidth } from "./TileCard";

enum Views {
  Cards = "Cards",
  List = "List"
}

export const Dashboard = () => {
  const { apiClient } = useApiClient();
  const [businessModules, setBusinessModules] = useState<any[]>([]);
  const [view, setView] = useState<Views>(Views.List);
  const [loadingModules, setLoadingModules] = useState<boolean>(false);

  const onPivotSelect = (
    item?: PivotItem | undefined,
    ev?: React.MouseEvent<HTMLElement, MouseEvent> | undefined
  ) => {
    setView(item!.props.itemKey as any);
  };

  /**
   * @todo: loading indicator
   */

  const fetchBusinessModules = async () => {
    setLoadingModules(true);
    const bmList: any[] = await apiClient.getWP("/BusinessModules");
    setBusinessModules(bmList);
    setLoadingModules(false);
  };

  useEffect(() => {
    fetchBusinessModules();
  }, []);

  return (
    <Stack tokens={{ padding: "0px 40px" }}>
      <Stack horizontal style={{ justifyContent: "space-between", margin: "16px 0px" }}>
        <Text variant={"large"}>{"Business Modules"}</Text>
        <Pivot
          linkSize={"large"}
          linkFormat={"tabs"}
          styles={pivotStyles}
          onLinkClick={onPivotSelect}
          selectedKey={view}
        >
          <PivotItem
            itemKey={Views.Cards}
            headerText={Views.Cards}
            itemIcon={view === Views.Cards ? "CheckMark" : ""}
          />
          <PivotItem
            itemKey={Views.List}
            headerText={Views.List}
            itemIcon={view === Views.List ? "CheckMark" : ""}
          />
        </Pivot>
      </Stack>
      {!loadingModules ? (
        <>
          {view === Views.Cards && (
            <Stack horizontal wrap tokens={{ childrenGap: "2%" }} style={{ marginBottom: "37px" }}>
              {businessModules
                .sort((a, b) => a.Title.localeCompare(b.Title))
                .map((bm, index) => {
                  return <TileCard key={`${index}_${bm.Title}`} bm={bm} />;
                })}
              <DefaultButton
                styles={createButton}
                onRenderText={() => {
                  return (
                    <Stack key={"create-new-bm"} styles={cardHeader} tokens={{ childrenGap: 8 }}>
                      <FontIcon
                        style={{
                          fontSize: "35px",
                          color: `${WorkPointTheme.palette.themePrimary}`
                        }}
                        iconName={"Add"}
                      />
                      <Text variant={"xLarge"}>{"Create"}</Text>
                    </Stack>
                  );
                }}
                onClick={() => console.log("@todo: go to create new business module route.")}
              />
            </Stack>
          )}
          {view === Views.List && (
            <div>
              <HierarchicalList businessModules={businessModules} />
              {AddBMButton(view)}
            </div>
          )}
        </>
      ) : (
        <Spinner size={SpinnerSize.large} style={{ marginTop: "20px" }} />
      )}
      <Stack tokens={{ childrenGap: 12 }}>
        <Text variant={"large"} style={{ fontWeight: "600" }}>
          {"Configuration"}
        </Text>
        <QuickLinkGroup quickLinks={quickLinks} groupName={"User experience"} />
        <QuickLinkGroup quickLinks={quickLinks} groupName={"User experience2"} />
        <QuickLinkGroup quickLinks={quickLinks} groupName={"User experience3"} />
      </Stack>
    </Stack>
  );
};

const AddBMButton = (view: Views) => {
  if (view === Views.Cards) {
    return (
      <DefaultButton
        styles={createButton}
        onRenderText={() => {
          return (
            <Stack key={"create-new-bm"} styles={cardHeader} tokens={{ childrenGap: 8 }}>
              <FontIcon
                style={{
                  fontSize: "35px",
                  color: `${WorkPointTheme.palette.themePrimary}`
                }}
                iconName={"Add"}
              />
              <Text variant={"xLarge"}>{"Create"}</Text>
            </Stack>
          );
        }}
        onClick={() => console.log("@todo: go to create new business module route.")}
      />
    );
  } else {
    return (
      <DefaultButton
        styles={listCreateButton}
        iconProps={{ iconName: "Add" }}
        text={"Create"}
        onClick={() => console.log("@todo: go to create new business module route.")}
      />
    );
  }
};

const createButton: Partial<IButtonStyles> = {
  root: {
    width: `${tileWidth}`,
    height: `154px`,
    boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.1)",
    border: "0px",
    borderBottomRightRadius: "5px",
    borderBottomLeftRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
};
const listCreateButton: Partial<IButtonStyles> = {
  root: {
    float: "right",
    margin: "16px"
  }
};

const cardHeader: Partial<IStackStyles> = {
  root: {
    width: `${tileWidth}`,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  }
};

const pivotStyles = mergeStyleSets({
  linkIsSelected: {
    borderRadius: "5px",
    padding: "0px 15px",
    margin: "2px",
    color: `white`,
    fontSize: "14px",
    height: "30px"
  },
  root: {
    backgroundColor: WorkPointTheme.palette.neutralLighter,
    borderRadius: "5px",
    maxWidth: "167px"
  },
  link: {
    backgroundColor: "transparent",
    padding: "0px 15px",
    selectors: {
      ":hover": {
        borderRadius: "5px",
        backgroundColor: "transparent",
        color: `-internal-light-dark(black, white)`
      }
    },
    fontSize: "14px",
    height: "30px"
  }
});

const quickLinks: IQuickLinkProps[] = [
  {
    title: "Integration Settings(mock)",
    link: "/configure/IntegrationSettings",
    imageUrl: "https://workpoint.azureedge.net/Images/Icons/Color/Project_32.png",
    view: "card",
    status: IStatus.Warning
  },
  {
    title: "Integration Settings 2(mock)",
    link: "/configure/IntegrationSettings",
    imageUrl: "https://workpoint.azureedge.net/Images/Icons/Color/Project_32.png",
    view: "card",
    status: IStatus.Warning
  },
  {
    title: "Integration Settings 3(mock)",
    link: "/configure/IntegrationSettings",
    imageUrl: "https://workpoint.azureedge.net/Images/Icons/Color/Project_32.png",
    view: "card",
    status: IStatus.Published
  },
  {
    title: "Integration Settings 4(mock)",
    link: "/configure/IntegrationSettings",
    imageUrl: "https://workpoint.azureedge.net/Images/Icons/Color/Project_32.png",
    view: "card",
    status: IStatus.Published
  }
];
