import { Routes, Route } from "react-router-dom";
import { AppRoutes } from "../../../routes";
import { ProfileModalForm } from "./components/NotificationProfileModalForm";
import { NotificationSettings } from "./NotificationSettings";
import { ContentPadding } from "../../common/AppChrome";

export const NotificationPage = () => {
  return (
    <ContentPadding>
      <Routes>
        <Route path="/" element={<NotificationSettings />}>
          {/* Modal routes */}
          <Route path={AppRoutes.NewNotificationProfile} element={<ProfileModalForm />} />
          <Route path={AppRoutes.EditNotificationProfile} element={<ProfileModalForm />} />
        </Route>
      </Routes>
    </ContentPadding>
  );
};
