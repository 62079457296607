/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-throw-literal */
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { Monaco } from "@monaco-editor/react";
import { __spreadArrays, __values } from "tslib";

function isNUll(e: any) {
  return null == e;
}
function equals(e: any, t: any, r?: any) {
  return (
    void 0 === r && (r = !0),
    r
      ? !isNUll(e) && !isNUll(t) && e.toLowerCase() === t.toLowerCase()
      : !isNUll(e) && !isNUll(t) && e === t
  );
}
const format = function (e: any, ...args: any[]) {
  let t: any[] = [];
  for (let r = 0; r < args.length; r++) t[r - 1] = args[r];
  var n = /\{[a-zA-Z$_\d]*\}/g,
    i = /\{(\d+)\}/g,
    o = !1,
    a = e;
  if (t && 1 === t.length && t[0] && "object" == typeof t[0]) {
    var s = t[0];
    a = e.replace(n, function (e: any) {
      var t = e.substring(1, e.length - 1);
      return s.hasOwnProperty(t) ? ((o = !0), s[t]) : e;
    });
  }
  return (
    o ||
      (a = e.replace(i, function (e: any, r: any) {
        return t.length > r ? t[r] : e;
      })),
    a
  );
};
const first = function (e: any, t: any) {
  var n, i;
  try {
    for (var o = __values(t), a = o.next(); !a.done; a = o.next()) {
      var s = a.value;
      if (e(s)) return s;
    }
  } catch (e) {
    n = {
      error: e
    };
  } finally {
    try {
      a && !a.done && (i = o.return) && i.call(o);
    } finally {
      if (n) throw n.error;
    }
  }
};
const map = function (e: any, t: any) {
  var n, i;
  if (!t) throw new Error("key required");
  e = e || [];
  var o: any = {};
  try {
    for (
      var a = __values(
          e.filter(function (e: any) {
            return e.hasOwnProperty(t);
          })
        ),
        s = a.next();
      !s.done;
      s = a.next()
    ) {
      var u = s.value;
      o[u[t]] = u;
    }
  } catch (e) {
    n = {
      error: e
    };
  } finally {
    try {
      s && !s.done && (i = a.return) && i.call(a);
    } finally {
      if (n) throw n.error;
    }
  }
  return o;
};
function getPropertyValue(e: any, t: any, i?: any) {
  var o, a;
  void 0 === i && (i = !0);
  var s = (e = e || {})[t];
  if (void 0 !== s) return s;
  try {
    for (var u = __values(Object.keys(e)), c = u.next(); !c.done; c = u.next()) {
      var d = c.value;
      if (equals(d, t, i)) return e[d];
    }
  } catch (e) {
    o = {
      error: e
    };
  } finally {
    try {
      c && !c.done && (a = u.return) && a.call(u);
    } finally {
      if (o) throw o.error;
    }
  }
}
const Parser = {
  isWhitespace: function (e: any) {
    switch (e) {
      case " ":
      case " ":
      case " ":
      case " ":
      case " ":
      case " ":
      case " ":
      case " ":
      case " ":
      case " ":
      case " ":
      case " ":
      case " ":
      case " ":
      case " ":
      case "　":
      case "\u2028":
      case "\u2029":
      case "\t":
      case "\n":
      case "\v":
      case "\f":
      case "\r":
      case "":
      case " ":
        return !0;
      default:
        return !1;
    }
  },
  expectLiteral: function (e: any) {
    let r = /^false$/i,
      n = /^null$/i,
      i = /^true$/i;

    return i.test(e)
      ? {
          value: !0
        }
      : r.test(e)
      ? {
          value: !1
        }
      : n.test(e)
      ? {
          value: null
        }
      : null;
  }
};
export const descriptions = {
  ABBREVIATED_DAYS: "d",
  ABBREVIATED_MINUTES: "m",
  ABBREVIATED_HOURS: "h",
  ABBREVIATED_SECONDS: "s",
  ACTION_PALETTE_NEXT_TITLE: "Show next action",
  ACTION_PALETTE_PREVIOUS_TITLE: "Show previous action",
  ACTION_PALETTE_CALLOUT_HINT_TITLE: "Add your next step",
  ACTION_PALETTE_CALLOUT_HINT_TEXT: "And what happens when the trigger runs",
  ADD_ACTION: "Add an action",
  ADD_CONDITION: "Add a condition",
  ADD_DO_UNTIL: "Add a do until",
  ADD_MORE: "More",
  ADD_PARALLEL: "Add a parallel branch",
  ADD_SCOPE: "Add a scope",
  ADD_SWITCH: "Add a switch case",
  ADVANCED_CONDITION_MODE: "Edit in advanced mode",
  API_CATEGORIES_APP_SERVICES: "Show APIs for App Services in the same region",
  API_CATEGORIES_FUNCTIONS: "Show Azure Functions in the same region",
  API_CATEGORIES_FLOW_APIS: "Show triggers from other Logic Apps",
  API_CATEGORIES_IT_MANAGED: "Show IT managed APIs",
  API_CATEGORIES_MICROSOFT_MANAGED: "Show Microsoft managed APIs",
  API_CATEGORIES_WORKFLOWS: "Show Logic Apps in the same region",
  API_CATEGORIES_API_MANAGEMENT: "Show API Management APIs in my subscription",
  APIMANAGEMENT_DISPLAYNAME: "Azure API Management",
  APIMANAGEMENT_SUMMARY_ACTION: "Choose an Azure API Management action",
  APIMANAGEMENT_SUMMARY_TRIGGER: "Choose an Azure API Management trigger",
  APPSERVICES_DISPLAYNAME: "Azure App Services",
  APPSERVICES_SUMMARY_ACTION: "Choose an Azure App Services action",
  APPSERVICES_SUMMARY_TRIGGER: "Choose an Azure App Services trigger",
  AUTH_BUTTON: "Sign in",
  AUTH_FAILED: "Failed to sign in {0} with error: {1}",
  AUTH_LOADING: "Loading...",
  AUTH_SIGN_IN: "Sign in to create a connection to {0}.",
  AUTH_SIGNING_IN: "Signing in...",
  AUTH_TITLE: "{0} - {1}",
  AUTHENTICATION: "Authentication",
  AZURE_FUNCTION_CREATE_DESCRIPTION: "Create Azure Function in subscription",
  AZURE_FUNCTION_CREATE_TEXT: "Create New Function",
  AZUREFUNCTIONS_DISPLAYNAME: "Azure Functions",
  AZUREFUNCTIONS_SUMMARY_ACTION: "Choose an Azure function",
  BASIC_CONDITION_MODE: "Edit in basic mode",
  BODY_PARAMETER: "Body",
  BODY_PARAMETER_RAW_INPUTS_SUMMARY: "Raw body inputs",
  BOOLEAN: "boolean",
  BOOLEAN_PARAMETER_DISPLAYNAME_DEFAULT: "Default",
  BOOLEAN_PARAMETER_DISPLAYNAME_TRUE: "Yes",
  BOOLEAN_PARAMETER_DISPLAYNAME_FALSE: "No",
  BUTTON_ADD_PARAMETER_MENU_COLLAPSED_TITLE: "Add an input",
  BUTTON_ADD_PARAMETER_MENU_EXPANDED_TITLE: "Choose the type of user input",
  BUTTON_PARAMETER_IN_USE_MESSAGE:
    "The current parameter cannot be deleted because an action depends on it",
  BUTTON_PARAMETER_TITLE_INVALID_EMPTY: "Title length should be at least one character",
  BUTTON_PARAMETER_TITLE_INVALID_FORMAT: "Title may not include quotes",
  BUTTON_PARAMETER_TITLE_INVALID_IN_USE: "Input title is already being used in this step",
  BUTTON_PARAMETER_TITLE_REVERTED: "Title was reverted to the previous valid one",
  BUTTON_PARAMETER_TITLE_INPUT_LABEL: "Input title",
  BUTTON_PARAMETER_TITLE_INPUT_DEFAULT_VALUE: "Input",
  BUTTON_PARAMETER_TITLE_INPUT_PLACEHOLDER: "Enter title",
  BUTTON_PARAMETER_DESCRIPTION_LABEL: "Input description",
  BUTTON_PARAMETER_DESCRIPTION_INPUT_PLACEHOLDER: "Enter description",
  BUTTON_PARAMETER_DESCRIPTION_INPUT_DEFAULT_VALUE: "Please enter your input",
  BUTTON_TEXT_PARAMETER_LABEL: "Add text input",
  BUTTON_TRIGGER_DESCRIPTION: "Manually trigger a flow",
  BUTTON_TRIGGER_DISPLAY_NAME: "Flow button for mobile - Manually trigger a flow",
  BUTTON_TRIGGER_OUTPUT_EMAIL: "User email",
  BUTTON_TRIGGER_OUTPUT_FORMATED_DATE: "Date",
  BUTTON_TRIGGER_OUTPUT_LOCATION_CITY: "City",
  BUTTON_TRIGGER_OUTPUT_LOCATION_COUNTRY: "Country/Region",
  BUTTON_TRIGGER_OUTPUT_LOCATION_FULL_ADDRESS: "Full address",
  BUTTON_TRIGGER_OUTPUT_LOCATION_LATITUDE: "Latitude",
  BUTTON_TRIGGER_OUTPUT_LOCATION_LONGITUDE: "Longitude",
  BUTTON_TRIGGER_OUTPUT_LOCATION_POSTALCODE: "Postal code",
  BUTTON_TRIGGER_OUTPUT_LOCATION_STATE: "State",
  BUTTON_TRIGGER_OUTPUT_LOCATION_STREET: "Street",
  BUTTON_TRIGGER_OUTPUT_TIMESTAMP: "Timestamp",
  BUTTON_TRIGGER_OUTPUT_USERNAME: "User name",
  BYTE: "byte",
  CANCEL: "Cancel",
  CANNOT_BE_INSIDE_FOREACH:
    "The output you selected is inside a collection and needs to be looped over to be accessed. This action cannot be inside a foreach.",
  CARD_DESCRIPTION_DISPLAY_NAME: "View operation description",
  CHANGE_CONNECTION_TEXT: "Change connection.",
  CHOOSE_PROFILE: "Connected to {0}. ",
  CHOOSE: "Choose",
  CLEAR_FILTER: "Clear Filter",
  COMMENT: "Comment",
  COMMENT_MENU_ITEM_ADD: "Add a comment",
  COMMENT_MENU_ITEM_EDIT: "Edit comment",
  COMMENT_PLACEHOLDER_TEXT: "Add a short comment",
  COMPOSE: "Compose",
  COMPOSE_DESCRIPTION: "Compose",
  COMPOSE_INPUTS: "Inputs",
  COMPOSE_INPUTS_DESCRIPTION: "Inputs",
  COMPOSE_OUTPUTS: "Outputs",
  CONDITION: "Condition",
  CONDITION_ADD_ACTION_INSIDE: "Add an action",
  CONDITION_CONTAINS: "contains",
  CONDITION_DO_NOTHING: ", do nothing",
  CONDITION_DOES_NOT_CONTAIN: "does not contain",
  CONDITION_DOES_NOT_END_WITH: "does not end with",
  CONDITION_DOES_NOT_START_WITH: "does not start with",
  CONDITION_ENDS_WITH: "ends with",
  CONDITION_EQUALS: "is equal to",
  CONDITION_GREATER_THAN: "is greater than",
  CONDITION_GREATER_THAN_OR_EQUAL: "is greater than or equal to",
  CONDITION_IF_NO: "If no",
  CONDITION_IF_YES: "If yes",
  CONDITION_LESS_THAN: "is less than",
  CONDITION_LESS_THAN_OR_EQUAL: "is less than or equal to",
  CONDITION_NOT_EQUAL: "is not equal to",
  CONDITION_OBJECT_NAME_LABEL: "Object Name",
  CONDITION_STARTS_WITH: "starts with",
  CONDITION_TITLE: "Condition",
  CONDITION_TOGGLE_COLLAPSE: "Collapse condition",
  CONDITION_TOGGLE_EXPAND: "Expand condition",
  CONDITION_UNKNOWN: "(unknown)",
  CONDITION_VALUE_LABEL: "Value",
  CONNECTION_CONFIGURATION_NO_RESOURCES: "No resources of this type found under this subscription.",
  CONNECTION_CONTAINER_ADD_NEW: "+ Add new connection",
  CONNECTION_CONTAINER_CONNECTION_PLURAL: "connections",
  CONNECTION_CONTAINER_CONNECTION_REQUIRED: "Connection required",
  CONNECTION_CONTAINER_CONNECTION_SINGULAR: "connection",
  CONNECTION_CONTAINER_CREATE: "Create",
  CONNECTION_CONTAINER_DEFAULT_TITLE: "Create connection",
  CONNECTION_CONTAINER_DESC: "To use this template:",
  CONNECTION_CONTAINER_LAUNCH_BUTTON: "Continue",
  CONNECTION_CONTAINER_CREATE_BUTTON: "Create Flow",
  CONNECTION_CONTAINER_RETRY_BUTTON: "Retry",
  CONNECTION_CONTAINER_WAITING_BUTTON: "Working ...",
  CONNECTION_CONTAINER_SELECT_A_CONNECTION: "Select a connection",
  CONNECTION_CONTAINER_SIGN_IN: "Sign in",
  CONNECTION_CONTAINER_SIGN_IN_REQUIRED: "Sign in required",
  CONNECTION_CONTAINER_SWITCH_ACCOUNT: "Switch account",
  CONNECTION_CONTAINER_VIEW_PERMISSIONS: "View permissions",
  CONNECTION_NAME_DISPLAY: "Connection Name",
  CONNECTION_NAME_PLACEHOLDER: "Enter name for connection",
  CONNECTION_NO_LONGER_EXISTS: "Connection no longer exists",
  CONNECTION_TYPE_SELECTOR_CREATING_LINKED_CONNECTION: "Creating connection from {0} ...",
  CONNECTION_TYPE_SELECTOR_LOADING_TEXT: "Loading...",
  CONNECTION_TYPE_SELECTOR_SELECT_EXISTING_CONNECTION_TITLE: "Select an existing connection",
  CONNECTION_TYPE_SELECTOR_SELECT_NEW_CONNECTION_TITLE: "Create a new connection",
  CONNECTIONS_MENU_ITEM_TITLE: "My connections",
  CONNECTION_UPDATE_TEXT: "Update",
  CONTENT_DISPLAYNAME: "Content",
  CONTROL_DISPLAYNAME: "Control",
  CREATE_CONFIG_CONNECTION_CREATE_BUTTON_TEXT: "Create",
  CREATE_CONFIG_CONNECTION_ACCEPT_AND_CREATE_BUTTON_TEXT: "Accept",
  CREATE_CONNECTION_LOADING_BUTTON_TEXT: "Creating...",
  UPDATE_CONNECTION_LOADING_BUTTON_TEXT: "Updating...",
  CONNECTION_CREATION_FAILED_TEXT:
    "Please check your account info and/or permissions and try again. Details: {0}",
  CONNECTION_CREATION_FAILED_DEFAULT_TEXT:
    "Please check your account info and/or permissions and try again.",
  CREATE_SIMPLE_CONNECTION_DESCRIPTION: "Create a connection for {0}.",
  CREATE_SIMPLE_CONNECTION_RETRY_BUTTON_TEXT: "Create",
  CREATE_SIMPLE_CONNECTION_ACCEPT_AND_RETRY_BUTTON_TEXT: "Accept",
  CUSTOM_OPTION: "Enter custom value",
  CUSTOM_VALUE_TEXT: "Custom Value",
  DATAOPERATIONS_DISPLAYNAME: "Data Operations",
  DATETIME: "datetime",
  DICTIONARY_ITEM_DELETE: "Click to delete item",
  DICTIONARY_SWITCH_KEY_VALUE_MDOE: "Switch to key value mode",
  DICTIONARY_SWITCH_TEXT_MDOE: "Switch to text mode",
  DONE: "Done",
  DOUBLE: "double",
  DROPDOWN_TEXT: "Dropdown",
  DESCRIPTION_TERMINATE: "Terminate the workflow run.",
  DISPLAY_TEXT_COLUMNS: "Columns",
  DISPLAY_TEXT_FORMAT: "Format",
  DISPLAY_TEXT_FUNCTION_NAME: "Function name",
  DISPLAY_TEXT_HOST: "Host",
  DISPLAY_TEXT_PATH: "Path",
  DISPLAY_TEXT_RELATIVE_PATH: "Relative path",
  DISPLAY_TEXT_RELATIVE_PATH_PARAMETERS: "Relative path parameters",
  DISPLAY_TEXT_SCHEMA: "Schema",
  DISPLAY_TEXT_TERMINATE: "Terminate",
  DISPLAY_TEXT_TERMINATE_CODE: "Code",
  DISPLAY_TEXT_TERMINATE_MESSAGE: "Message",
  DISPLAY_TEXT_TERMINATE_STATUS: "Status",
  DISPLAY_TEXT_UNTYPED_INPUTS: "Inputs",
  DISPLAY_TEXT_UNTYPED_OUTPUTS: "Outputs",
  EDIT_LIMIT_VALUES: "Change limits",
  EMAIL: "email",
  EMPTY_NODE_GENERATION_MODE_DISPLAYNAME: "Mode of empty node generation",
  ENUM_LOADING_ERROR_FORMAT_TEXT:
    "Cannot evaluate the parameter '{0}' at design-time; it is empty or has a runtime expression.",
  ENUM_LOADING_FAILED: "Could not retrieve values",
  ENUM_LOADING_TEXT: "Loading ...",
  ENUM_NO_ITEMS: "No Items",
  ENUM_NO_MATCH: "No values matched your search",
  ENTER_KEY: "Enter key",
  ENTER_VALUE: "Enter value",
  ERROR_INVALID_DEFINITION_FOR_BUILT_IN_TYPE: "Step definition is invalid for built in type {0}.",
  ERROR_CANNOT_CREATE_CONNECTION_OPERATION_FOR_TYPE:
    "Cannot create connection operation for type '{0}'.",
  ERROR_CANNOT_LOAD_DYNAMIC_INPUTS: "Error loading inputs. Changes to this card will not be saved.",
  ERROR_CODE: "Code",
  ERROR_CONDITION_BASIC_MODE:
    "Condition is too complex or invalid. Unable to switch to basic mode.",
  ERROR_CONDITION_MISSING_IF_ACTION:
    "Condition card '{0}' must have at least one card in its 'If yes' branch",
  ERROR_DEFINITION_REQUIREMENT: "Should at least contain one trigger and one action",
  ERROR_DESERIALIZATION_ACTION: "Error loading operation from definition. Exception: {0}",
  ERROR_DESIGNER_CANNOT_RENDER:
    "Cannot render designer due to multiple triggers '{0}' in definition.",
  ERROR_FLOW_PARSE_FAILED: "Failed to parse flow definition",
  ERROR_INCOMPATIBLE_SCHEMA: "Designer is bound to schema '{0}' and cannot load schema '{1}'.",
  ERROR_INVALID_CONNECTION: "Invalid connection.",
  ERROR_INVALID_DEFINITION: "Invalid Workflow Definition",
  ERROR_INVALID_KIND_FOR_REQUEST_TRIGGER:
    "Invalid value '{0}' for kind attribute in the request trigger definition. Supported values are: '{1}'.",
  ERROR_INVALID_PROPERTY_FOR_UNTIL_ACTION:
    "The '{0}' property for action of type '{1}' needs to have either count or timeout set.",
  ERROR_INVALID_OPERATION_ID_FOR_DYNAMIC_URI:
    "Cannot generate URI for dynamic calls for built in operation '{0}'. The operation id is invalid.",
  ERROR_INVALID_RECURRENCE_INTERVAL_FOR_SKU: "Recurrence interval invalid for SKU.",
  ERROR_INVALID_SCHEMA_FOR_OPERATION:
    "An operation of type '{0}' cannot be used in schema version '{1}'.",
  ERROR_MISSING_PARAMETER: "Missing required parameter '{0}'.",
  ERROR_MISSING_PROPERTY: "Missing '{0}' property on definition.",
  ERROR_MESSAGE: "Message",
  ERROR_NODE_NOT_FOUND_IN_ACTIONS_STORE:
    "Node in graph not found in the actions store. Node id: '{0}'.",
  ERROR_NOT_FIRST_PARTY_OR_NO_AUTH: "Not a first-party or no-auth connection!",
  ERROR_PROPERTY_CONFLICT:
    "Operations cannot have both '{0}' and '{1}' set. Please use only one of them.",
  ERROR_REASON: "Reason",
  ERROR_REQUIRED_PARAMETERS_NOT_SET: "Required parameters '[{0}]' not set or invalid.",
  ERROR_RETRIEVING_USERS: "An error occurred while retrieving Azure AD users.",
  ERROR_SERIALIZATION_EMPTY_DEFINITION_FOR_ACTION:
    "Got empty definition for workflow action. Action name: '{0}'.",
  ERROR_STORE_UPDATE_FAILED: "Failed to update the operation parameters and outputs store",
  ERROR_TYPE_UNRECOGNIZED: "Action or trigger type not recognized.",
  ERROR_UNDEFINED_CONNECTION_REFERENCE: "Undefined connection reference.",
  ERROR_UNDEFINED_CONNECTION_PROVIDER_PROPERTIES_OR_SWAGGER:
    "Connection provider '{id}' is missing properties or swagger.",
  ERROR_UNINITIALIZED_DESIGNER_CONTEXT: "Please call loadWorkflow before using the designer.",
  ERROR_UNRECOGNIZED_FEATURE: "Unrecognized feature '{0}'. Supported features are: {1}.",
  ERROR_UNSUPPORTED_SCENARIO_TOGGLE_ADVANCED_PARAMETERS:
    "Unsupported scenario for toggling advanced parameters. NodeId: '{0}', ParameterGroupId: '{1}'.",
  ERROR_UNSUPPORTED_SCHEMA: "Unsupported schema '{0}'.",
  ERROR_UNSUPPORTED_SCHEMA_VERSION: "Unsupported schema version '{0}'.",
  ERROR_UNSUPPORTED_SCOPE: "Scope type {0} is not supported.",
  ERROR_UNSUPPORTED_TYPE_FOR_OPERATION: "Operation {0} is not supported for type - {1}.",
  ERROR_EXPRESSION_BUILDER_INVALID_TYPE: "Invalid expression type '{0}'.",
  ERROR_EXPRESSION_SCANNER_LIMIT_EXCEEDED:
    "Expression limit exceeded: maximum '{0}' and actual '{1}'.",
  ERROR_EXPRESSION_SCANNER_TOKEN_NOT_FOUND: "Failed to get expression token of type '{0}'.",
  ERROR_EXPRESSION_SCANNER_STRING_LITERAL_NOT_TERMINATED: "The string literal is not terminated.",
  ERROR_EXPRESSION_SCANNER_UNEXPECTED_CHARACTER: "Unexpected character '{0}'.",
  ERROR_EXPRESSION_NOT_EXPRESSION: "The value is not an expression.",
  ERROR_EXPRESSION_UNEXPECTED_DEREFERENCE: "Unexpected dereference expression.",
  ERROR_EXPRESSION_SEGMENT_NOT_TERMINATED: "String interpolation segment is not terminated.",
  EVERY_DAY_TRIGGER_DESCRIPTION: "Every day",
  EVERY_HOUR_TRIGGER_DESCRIPTION: "Every hour",
  EVERY_MINUTE_TRIGGER_DESCRIPTION: "Every minute",
  FILENAME: "{0} file name",
  FLATFILE_DISPLAYNAME: "Flat File",
  FLAT_FILE_DECODING_CONTENT_DESCRIPTION: "The Flat File content to decode.",
  FLAT_FILE_DECODING_DISPLAYNAME: "Flat File Decoding",
  FLAT_FILE_ENCODING_CONTENT_DESCRIPTION: "The XML content to encode.",
  FLAT_FILE_ENCODING_DISPLAYNAME: "Flat File Encoding",
  FLAT_FILE_ENCODING_EMPTY_NODE_GENERATION_MODE_DESCRIPTION:
    "The mode of empty node generation for Flat File encoding.",
  FLAT_FILE_SCHEMA_NAME_DESCRIPTION:
    "The name of the Flat File schema to use from the associated integration account.",
  FLOW_STATUS_ABORTED: "Aborted",
  FLOW_STATUS_CANCELLED: "Cancelled",
  FLOW_STATUS_FAILED: "Failed",
  FLOW_STATUS_FAULTED: "Faulted",
  FLOW_STATUS_IGNORED: "Ignored",
  FLOW_STATUS_NOT_SPECIFIED: "Not specified",
  FLOW_STATUS_PAUSED: "Paused",
  FLOW_STATUS_RUNNING: "Running",
  FLOW_STATUS_SKIPPED: "Skipped",
  FLOW_STATUS_SUCCEEDED: "Succeeded",
  FLOW_STATUS_SUSPENDED: "Suspended",
  FLOW_STATUS_TIMED_OUT: "Timed out",
  FLOW_STATUS_WAITING: "Waiting",
  COAUTHORED_CONNECTIONS_MENU_ITEM_TITLE: "Other connections",
  FOREACH: "For each",
  FOREACH_EMPTY_ARRAY: "The input array was empty.",
  FOREACH_PARAMETER_FOREACH_SUMMARY: "Select an output from previous steps",
  FOREACH_IMPLICIT_INFORMATION_MESSAGE:
    "Based on the output parameters you selected, we've added a for each container for you. For each enables you to perform actions for each individual item in a set of values",
  FUNCTION: "Function",
  FUNCTION_INPUTS_DISPLAYNAME: "Request Body",
  FUNCTION_INPUTS_PLACEHOLDER: "Context object to be passed to function: { .. } ",
  FUNCTION_QUERIES_DISPLAYNAME: "Queries",
  FUNCTION_CREATE_PROGRESS_TEXT: "Creating Function ...",
  FUNCTION_CREATE_NAME_FIELD: "Function Name",
  FUNCTION_CREATE_TITLE: "Function Creation",
  FUNCTION_INPUTS_TITLE: "Configure Function Inputs",
  FUNCTION_EDITOR_TITLE: "Function Authoring",
  FUNCTION_EDITOR_SAVE_LABEL: "Create",
  FUNCTION_EDITOR_SAVING_LABEL: "Creating",
  FUNCTION_EDITOR_NAME_LABEL: "Function name",
  FUNCTION_EDITOR_NAME_PLACEHOLDER: "Enter function name",
  FUNCTION_EDITOR_EDITOR_LABEL: "Code",
  FUNCTION_EDITOR_EDITOR_VALID_ERROR: "Either name or code can't be empty",
  NO_VALID_FUNCTION_ID_FOUND:
    "Cannot open the Azure Function because no valid Azure Function is found for this action.",
  FLOAT: "float",
  GATEWAY_TEXT: "Connect via on-premise data gateway",
  GATEWAY_TOOLTIP: "Select this if you are configuring an on-prem connection",
  GATEWAYS_CREATE_CONNECTION: "+ Install gateway",
  GATEWAYS_LABEL: "Gateways",
  GATEWAYS_NONE_FOUND: "No gateways found",
  HINT_TEXT_NAME: "Choose a value",
  HINT_TEXT_VALUE: "Choose a value",
  HINT_TEXT_TERMINATE_CODE: "Enter error code",
  HINT_TEXT_TERMINATE_MESSAGE: "Enter error message",
  HTTP_AUTHENTICATION_DISPLAYNAME: "Authentication",
  HTTP_AUTHENTICATION_NAME: "authentication",
  HTTP_AUTHENTICATION_TYPE_DISPLAYNAME: "Authentication",
  HTTP_AUTHENTICATION_TYPE_NONE: "None",
  HTTP_AUTHENTICATION_TYPE_BASIC: "Basic",
  HTTP_AUTHENTICATION_TYPE_RAW: "Raw",
  HTTP_AUTHENTICATION_TYPE_CERTIFICATE: "Client Certificate",
  HTTP_AUTHENTICATION_TYPE_OAUTH: "Active Directory OAuth",
  HTTP_AUTHENTICATION_BASIC_USERNAME_DISPLAYNAME: "Username",
  HTTP_AUTHENTICATION_BASIC_USERNAME_PLACEHOLDER: "Enter username",
  HTTP_AUTHENTICATION_BASIC_PASSWORD_DISPLAYNAME: "Password",
  HTTP_AUTHENTICATION_BASIC_PASSWORD_PLACEHOLDER:
    "Enter password as plain text or use a secure parameter",
  HTTP_AUTHENTICATION_RAW_VALUE_DISPLAYNAME: "Value",
  HTTP_AUTHENTICATION_RAW_VALUE_PLACEHOLDER: "Enter the value of the Authorization header",
  HTTP_AUTHENTICATION_CERTIFICATE_PFX_DISPLAYNAME: "Pfx",
  HTTP_AUTHENTICATION_CERTIFICATE_PFX_PLACEHOLDER: "Enter PFX",
  HTTP_AUTHENTICATION_CERTIFICATE_PASSWORD_DISPLAYNAME: "Password",
  HTTP_AUTHENTICATION_CERTIFICATE_PASSWORD_PLACEHOLDER:
    "Enter password as plain text or use a secure parameter",
  HTTP_AUTHENTICATION_OAUTH_AUTHORITY_DISPLAYNAME: "Authority",
  HTTP_AUTHENTICATION_OAUTH_TENANT_DISPLAYNAME: "Tenant",
  HTTP_AUTHENTICATION_OAUTH_TENANT_PLACEHOLDER: "Enter tenant",
  HTTP_AUTHENTICATION_OAUTH_AUDIENCE_DISPLAYNAME: "Audience",
  HTTP_AUTHENTICATION_OAUTH_AUDIENCE_PLACEHOLDER: "Enter audience",
  HTTP_AUTHENTICATION_OAUTH_CLIENT_ID_DISPLAYNAME: "Client ID",
  HTTP_AUTHENTICATION_OAUTH_CLIENT_ID_PLACEHOLDER: "Enter client ID",
  HTTP_AUTHENTICATION_OAUTH_SECRET_DISPLAYNAME: "Secret",
  HTTP_AUTHENTICATION_OAUTH_SECRET_PLACEHOLDER:
    "Enter secret as plain text or use a secure parameter",
  HTTP_BODY_DISPLAYNAME: "Body",
  HTTP_BODY_NAME: "body",
  HTTP_ACTION_DESCRIPTION: "Choose a REST API to invoke.",
  HTTP_TRIGGER_DESCRIPTION: "Trigger an event based on a select REST API.",
  HTTP_DISPLAYNAME: "HTTP",
  HTTP_HEADERS_DISPLAYNAME: "Headers",
  HTTP_QUERIES_DISPLAYNAME: "Queries",
  HTTP_INPUTS_RAW_INPUTS_SUMMARY: "Raw inputs",
  HTTP_METHOD_DISPLAYNAME: "Method",
  HTTP_METHOD_VALUES: ["GET", "PUT", "POST", "PATCH", "DELETE"],
  HTTP_STATUS_CODE_DISPLAYNAME: "Status code",
  HTTP_URI_DISPLAYNAME: "Uri",
  INCREMENT_VARIABLE_NAME_DISPLAYNAME: "Name",
  INCREMENT_VARIABLE_NAME_PLACEHOLDER: "Enter variable name",
  INCREMENT_VARIABLE_VALUE_DISPLAYNAME: "Increment By",
  INCREMENT_VARIABLE_VALUE_PLACEHOLDER: "Enter a value",
  INCREMENT_VARIABLE_SUMMARY: "Increment variable",
  INCREMENT_VARIABLE_DESCRIPTION: "Increments a variable.",
  INITIALIZE_VARIABLE_NAME_DISPLAYNAME: "Name",
  INITIALIZE_VARIABLE_NAME_PLACEHOLDER: "Enter variable name",
  INITIALIZE_VARIABLE_TYPE_DISPLAYNAME: "Type",
  INITIALIZE_VARIABLE_VALUE_DISPLAYNAME: "Value",
  INITIALIZE_VARIABLE_VALUE_PLACEHOLDER: "Enter initial value",
  INITIALIZE_VARIABLE_SUMMARY: "Initialize variable",
  INITIALIZE_VARIABLE_DESCRIPTION: "Initializes a variable.",
  INPUTS: "Inputs",
  INSERT_NEW_STEP_TOOLTIP: "Click to insert a new step",
  INTEGER: "integer",
  INTEGRATIONACCOUNT_DISPLAYNAME: "Integration Account",
  INTEGRATION_ACCOUNT_ARTIFACT_LOOKUP_ARTIFACT_NAME_DISPLAYNAME: "Artifact Name",
  INTEGRATION_ACCOUNT_ARTIFACT_LOOKUP_ARTIFACT_NAME_DESCRIPTION:
    "Name of the artifact to be fetched.",
  INTEGRATION_ACCOUNT_ARTIFACT_LOOKUP_ARTIFACT_TYPE_AGREEMENT: "Agreement",
  INTEGRATION_ACCOUNT_ARTIFACT_LOOKUP_ARTIFACT_TYPE_DISPLAYNAME: "Artifact Type",
  INTEGRATION_ACCOUNT_ARTIFACT_LOOKUP_ARTIFACT_TYPE_DESCRIPTION:
    "Type of the artifact to be fetched.",
  INTEGRATION_ACCOUNT_ARTIFACT_LOOKUP_ARTIFACT_TYPE_MAP: "Map",
  INTEGRATION_ACCOUNT_ARTIFACT_LOOKUP_ARTIFACT_TYPE_PARTNER: "Partner",
  INTEGRATION_ACCOUNT_ARTIFACT_LOOKUP_ARTIFACT_TYPE_SCHEMA: "Schema",
  INTEGRATION_ACCOUNT_ARTIFACT_LOOKUP_DISPLAYNAME: "Integration Account Artifact Lookup",
  INTEGRATION_ACCOUNT_ARTIFACT_LOOKUP_DESCRIPTION:
    "Looks up an artifact with the specified artifact type and name.",
  INTEGRATION_ACCOUNT_ARTIFACT_LOOKUP_OUTPUT_BODY_DISPLAYNAME: "Body",
  INTEGRATION_ACCOUNT_ARTIFACT_LOOKUP_OUTPUT_PROPERTIES_DISPLAYNAME: "Properties",
  INTEGRATION_ACCOUNT_ARTIFACT_LOOKUP_OUTPUT_NAME_DISPLAYNAME: "Name",
  INVALID_ARRAY_INPUT:
    "This output is an array. An array cannot be inserted into an action which outputs an array.",
  INVALID_ARRAY_NESTING:
    "This output is an array. A foreach cannot be nested inside of another foreach.",
  INVALID_ARRAY_CONTAINS_FOREACH:
    "This output is an array. A foreach cannot contain another foreach.",
  INVALID_DROP_CONTAINER:
    "Cannot drop a scope, condition, foreach, do until, or switch inside itself.",
  INVALID_DROP_TRIGGER: "Cannot drop a trigger below an action.",
  INVALID_DROP_ABOVE_DEPENDS_ON: "This action cannot be dragged above actions it depends on.",
  INVALID_DROP_BELOW_DEPENDENTS: "This action cannot be dragged below actions that depend on it.",
  INVALID_DROP_LYNCHPIN_IF_CHILD:
    "The only action in the 'if' branch cannot be dragged out of it while there are still actions in the 'else' branch.",
  INVALID_DROP_NESTED_FOREACH: "A foreach cannot be dragged inside of a foreach or a do until.",
  INVALID_DROP_NESTED_CONDITION: "A condition cannot be dragged inside of a condition.",
  INVALID_DROP_INITIALIZE_VARIABLE_CANNOT_BE_NESTED:
    "An initialize variable action can only be at top level.",
  INVALID_CONNECTIONS:
    "Some of the connections are not authorized yet. If you just created a workflow from a template, please click Continue to add the authorized connections to your workflow before saving.",
  INVALID_FOREACH_INSIDE_UNTIL:
    "This output is from an array and needs a foreach to be accessed. Foreach cannot be used within a do-until.",
  INVALID_JSON_FORMAT: "Invalid json format. Missing beginning '{' or ending '}'",
  INVALID_PARAMETERS: "Definition contains invalid parameters.",
  INVALID_SWAGGER_ENDPOINT_RESPONSE: "Invalid response: {0}",
  INVALID_USE_OF_X_MS_HEADERS_EXTENSION:
    "Invalid swagger. Parameters with location 'header' and operation extension 'x-ms-headers' can't be used together.",
  INVALID_EXPRESSION: "The expression is invalid.",
  ITEM: "Item",
  JOIN_DESCRIPTION:
    'Joins all the elements of an array into a string, using the specified "Join with" separator between each element.',
  JOIN_FROM_DISPLAYNAME: "From",
  JOIN_JOIN_WITH_DISPLAYNAME: "Join with",
  JOIN_TITLE: "Join",
  JSON: "json",
  OUTPUT: "Output",
  PARSE_JSON_CONTENT_DISPLAYNAME: "Content",
  PARSE_JSON_ERRORS_DISPLAYNAME: "Errors",
  PARSE_JSON_SCHEMA_DISPLAYNAME: "Schema",
  PARSE_JSON_TITLE: "Parse JSON",
  PARSE_JSON_DESCRIPTION: "Specify the schema of JSON content.",
  LOAD_MORE: "Load More",
  LOADING_CONNECTION_PROVIDER_SELECT: "{0} loading...",
  LOADING_TEXT: "Loading...",
  MANUAL_TRIGGER_CALLBACK_URL_LABEL: "HTTP {0} URL",
  MANUAL_TRIGGER_CALLBACK_URL_METHOD_UNKNOWN: "request",
  MANUAL_TRIGGER_RELATIVE_PATH_PARAMETER_DESCRIPTION: "This is a path parameter.",
  MENU_ACTION_DISABLED_ALREADY_DELETED: "This operation has already been deleted.",
  MENU_BUTTON_ARIA_LABEL: "Menu",
  MENU_COMMENT_ACTION_DISABLED: "Comments can only be added while editing the inputs of a step.",
  MENU_DELETE: "Delete",
  MENU_DELETE_ACTION_DISABLED_CONDITION:
    "A condition must have at least one action in the 'if' branch if there are actions in the 'else' branch.",
  MENU_DELETE_ACTION_DISABLED_DEPENDENCIES:
    "The current step cannot be deleted because an action depends on its outputs.",
  MENU_DELETE_ACTION_DISABLED_EXISTING_SEARCH:
    "The trigger cannot be deleted while you are searching for actions.",
  MENU_DELETE_ACTION_DISABLED_SWITCH_CASE:
    "A switch must have at least one non-default case. To remove the entire switch, delete from the switch action above.",
  MENU_DELETE_ACTION_DISABLED_SWITCH_DEFAULT:
    "A switch must have one default case. To remove the entire switch, delete from the switch action above.",
  MENU_RENAME: "Rename",
  MENU_RENAME_ACTION_DISABLED_DEPENDENCIES:
    "This step cannot be renamed because an action depends on its outputs.",
  MENU_RENAME_ACTION_DISABLED_PHASE: "This step can only be renamed while editing its inputs.",
  MENU_RENAME_ACTION_DISABLED_UNSUPPORTED: "Rename is not supported for this step.",
  MENU_RUN_AFTER: "Configure run after",
  MENU_OPEN_AZURE_FUNCTION: "Open Azure Function",
  NAME_ALREADY_EXIST_OR_INVALID: "The name already exists or is invalid.",
  NAVIGATOR_TEXT: "Show contents",
  NESTEDWORKFLOWS_DISPLAYNAME: "Azure Logic Apps",
  NESTEDWORKFLOWS_MONITOR_HEADER: "Show Logic App run details",
  NESTEDWORKFLOWS_SUMMARY_ACTION: "Choose a Logic Apps workflow",
  NEW_STEP: "+ New step",
  NEXT: "Next",
  NO_ASSOCIATED_INTEGRATION_ACCOUNT:
    "There is no Integration Account associated with this Logic App.",
  NO_ERROR: "No error",
  NO_INPUTS: "No inputs",
  NO_INPUTS_OR_OUTPUTS: "There are no inputs or outputs for this operation.",
  NO_ITEMS_TEXT: "No Items",
  NO_OUTPUTS: "No outputs",
  NO_PARAMETERS:
    "No additional information is needed for this step. You will be able to use the outputs in subsequent steps.",
  NO_MATCH: "No operations matched your search",
  NO_MATCH_SITES: "No sites matched your search",
  NO_MATCH_FUNCTION: "No functions matched your search",
  NO_MATCH_WORKFLOWS: "No Logic Apps with manual triggers matched your search",
  NO_TRIGGERS_MESSAGE: "There is no trigger for this Logic App.",
  NUMBER: "number",
  OPERATION_UNSUPPORTED_INSIDE_FOREACH: "The operation '{0}' cannot be used inside a for each.",
  OPERATION_UNSUPPORTED_CANNOT_NEST: "The operation '{0}' can only be used at top level.",
  OUTPUTS: "Outputs",
  OUTPUTS_FROM: "Outputs from {0}",
  PAGER_NEXT: "Next",
  PAGER_PREVIOUS: "Previous",
  PAGER_OF: "of",
  PAGER_SHOW_RUN: "Show run",
  PARTIAL_PARAMETER_DESCRIPTION: ". This parameter is used as part of ",
  PHONE: "phone",
  PLACEHOLDER_ACTION_SEARCH: "Search for more actions",
  PLACEHOLDER_DELAY_TIMESTAMP: "Example: 2016-07-11T14:45Z",
  PLACEHOLDER_HTTP_AUTHENTICATION: "Enter JSON object of authentication parameters",
  PLACEHOLDER_HTTP_BODY: "Enter request content",
  PLACEHOLDER_HTTP_HEADERS: "Enter JSON object of request headers",
  PLACEHOLDER_HTTP_SWAGGER_ENDPOINT_URL: "Example: https://myapi.azurewebsites.net/swagger/docs/v1",
  PLACEHOLDER_HTTP_URL: "Enter request URL",
  PLACEHOLDER_OPERATION_TYPE: "Choose operations category",
  PLACEHOLDER_RECURRENCE_START_TIME: "Example: 2017-03-24T15:00:00Z",
  PLACEHOLDER_RESPONSE_BODY: "Enter response content",
  PLACEHOLDER_RESPONSE_HEADERS: "Enter JSON object of response headers",
  PLACEHOLDER_TRIGGER_SEARCH: "Search for more triggers",
  PLACEHOLDER_WORKFLOW_SERACH: "Select a Logic App or search for more",
  PLACEHOLDER_EXPRESSION: "Write expression here",
  PREMIUM: "PREMIUM",
  PREVIEW: "Preview",
  PROFIIE_NEW_PROFILE: "Create new",
  PROFILE_DESCRIPTION: "Select an existing connection or create a new one:",
  PROFILE_TITLE: "Connections",
  PROFILE_WAITING: "Waiting...",
  QUERY_DISPLAYNAME: "Filter array",
  QUERY_FROM_DISPLAYNAME: "From",
  QUERY_FROM_PLACEHOLDER: "Array to filter",
  QUERY_OUTPUT_TITLE: "Body",
  RECOMMENDATION_ACTIONS_TEXT: "Actions ({0})",
  RECOMMENDATION_CALLOUT_HINT_TRIGGER_TITLE: "Add a trigger",
  RECOMMENDATION_CALLOUT_HINT_TRIGGER_TEXT: "Begin by selecting or searching for a trigger",
  RECOMMENDATION_CALLOUT_HINT_FIRST_ACTION_TITLE: "Add an action",
  RECOMMENDATION_CALLOUT_HINT_FIRST_ACTION_TEXT: "This step will be executed when the trigger runs",
  RECOMMENDATION_CHOOSE_AN_ACTION: "Choose an action",
  RECOMMENDATION_CREATE_ACTION_HELP_TEXT: "{0} actions can be created using",
  RECOMMENDATION_CREATE_OPERATION_HELP_TEXT: "these steps",
  RECOMMENDATION_ERROR_SELECTING_ACTION: "Please select a trigger before selecting an action.",
  RECOMMENDATION_CREATE_TRIGGER_HELP_TEXT: "{0} triggers can be created using",
  RECOMMENDATION_NO_MATCHES_TEXT: "There are no results for your search",
  RECOMMENDATION_SEARCH_BUTTON_GO_BACK: "Go back",
  RECOMMENDATION_SEARCH_PLACEHOLDER_ACTIONS: "Search all actions",
  RECOMMENDATION_SEARCH_PLACEHOLDER_SERVICES: "Search all services",
  RECOMMENDATION_SEARCH_PLACEHOLDER_SERVICES_AND_ACTIONS: "Search all services and actions",
  RECOMMENDATION_SEARCH_PLACEHOLDER_SERVICES_AND_TRIGGERS: "Search all services and triggers",
  RECOMMENDATION_SEARCH_PLACEHOLDER_TRIGGERS: "Search all triggers",
  RECOMMENDATION_SEE_ALL_TEXT: "See more",
  RECOMMENDATION_SERVICES_TEXT: "Services",
  RECOMMENDATION_TRIGGERS_TEXT: "Triggers ({0})",
  RECOMMENDATION_USERVOICE_HEADER_TEXT: "Tell us what you need",
  RECOMMENDATION_USERVOICE_LINK_TEXT: "UserVoice",
  RECOMMENDATION_USERVOICE_TEXT: "Help us decide which services and triggers to add next with ",
  RECURRENCE_SECTION_DESCRIPTION: "How often do you want to check for items?",
  RECURRENCE_COUNT_DISPLAYNAME: "Count",
  RECURRENCE_END_TIME_DISPLAYNAME: "End time",
  RECURRENCE_FREQUENCY_DISPLAYNAME: "Frequency",
  RECURRENCE_FREQUENCY_VALUES: ["Day", "Hour", "Minute", "Second"],
  RECURRENCE_INTERVAL_DISPLAYNAME: "Interval",
  RECURRENCE_SCHEDULE_DISPLAYNAME: "Schedule",
  RECURRENCE_START_TIME_DISPLAYNAME: "Start time",
  RECURRENCE_TIMEZONE_DISPLAYNAME: "Time zone",
  RECURRENCE_TIMEZONE_VALUES: [
    "(UTC-12:00) International Date Line West",
    "(UTC-11:00) Coordinated Universal Time-11",
    "(UTC-10:00) Aleutian Islands",
    "(UTC-10:00) Hawaii",
    "(UTC-09:30) Marquesas Islands",
    "(UTC-09:00) Alaska",
    "(UTC-09:00) Coordinated Universal Time-09",
    "(UTC-08:00) Baja California",
    "(UTC-08:00) Coordinated Universal Time-08",
    "(UTC-08:00) Pacific Time (US & Canada)",
    "(UTC-07:00) Arizona",
    "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
    "(UTC-07:00) Mountain Time (US & Canada)",
    "(UTC-06:00) Central America",
    "(UTC-06:00) Central Time (US & Canada)",
    "(UTC-06:00) Easter Island",
    "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
    "(UTC-06:00) Saskatchewan",
    "(UTC-05:00) Bogota, Lima, Quito, Rio Branco",
    "(UTC-05:00) Chetumal",
    "(UTC-05:00) Eastern Time (US & Canada)",
    "(UTC-05:00) Haiti",
    "(UTC-05:00) Havana",
    "(UTC-05:00) Indiana (East)",
    "(UTC-04:00) Asuncion",
    "(UTC-04:00) Atlantic Time (Canada)",
    "(UTC-04:00) Caracas",
    "(UTC-04:00) Cuiaba",
    "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
    "(UTC-04:00) Santiago",
    "(UTC-04:00) Turks and Caicos",
    "(UTC-03:30) Newfoundland",
    "(UTC-03:00) Araguaina",
    "(UTC-03:00) Brasilia",
    "(UTC-03:00) Cayenne, Fortaleza",
    "(UTC-03:00) City of Buenos Aires",
    "(UTC-03:00) Greenland",
    "(UTC-03:00) Montevideo",
    "(UTC-03:00) Saint Pierre and Miquelon",
    "(UTC-03:00) Salvador",
    "(UTC-02:00) Coordinated Universal Time-02",
    "(UTC-02:00) Mid-Atlantic - Old",
    "(UTC-01:00) Azores",
    "(UTC-01:00) Cabo Verde Is.",
    "(UTC) Coordinated Universal Time",
    "(UTC+00:00) Casablanca",
    "(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
    "(UTC+00:00) Monrovia, Reykjavik",
    "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
    "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    "(UTC+01:00) West Central Africa",
    "(UTC+01:00) Windhoek",
    "(UTC+02:00) Amman",
    "(UTC+02:00) Athens, Bucharest",
    "(UTC+02:00) Beirut",
    "(UTC+02:00) Cairo",
    "(UTC+02:00) Chisinau",
    "(UTC+02:00) Damascus",
    "(UTC+02:00) Gaza, Hebron",
    "(UTC+02:00) Harare, Pretoria",
    "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    "(UTC+02:00) Istanbul",
    "(UTC+02:00) Jerusalem",
    "(UTC+02:00) Kaliningrad",
    "(UTC+02:00) Tripoli",
    "(UTC+03:00) Baghdad",
    "(UTC+03:00) Kuwait, Riyadh",
    "(UTC+03:00) Minsk",
    "(UTC+03:00) Moscow, St. Petersburg, Volgograd",
    "(UTC+03:00) Nairobi",
    "(UTC+03:30) Tehran",
    "(UTC+04:00) Abu Dhabi, Muscat",
    "(UTC+04:00) Astrakhan, Ulyanovsk",
    "(UTC+04:00) Baku",
    "(UTC+04:00) Izhevsk, Samara",
    "(UTC+04:00) Port Louis",
    "(UTC+04:00) Tbilisi",
    "(UTC+04:00) Yerevan",
    "(UTC+04:30) Kabul",
    "(UTC+05:00) Ashgabat, Tashkent",
    "(UTC+05:00) Ekaterinburg",
    "(UTC+05:00) Islamabad, Karachi",
    "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    "(UTC+05:30) Sri Jayawardenepura",
    "(UTC+05:45) Kathmandu",
    "(UTC+06:00) Astana",
    "(UTC+06:00) Dhaka",
    "(UTC+06:00) Novosibirsk",
    "(UTC+06:30) Yangon (Rangoon)",
    "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    "(UTC+07:00) Barnaul, Gorno-Altaysk",
    "(UTC+07:00) Hovd",
    "(UTC+07:00) Krasnoyarsk",
    "(UTC+07:00) Tomsk",
    "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    "(UTC+08:00) Irkutsk",
    "(UTC+08:00) Kuala Lumpur, Singapore",
    "(UTC+08:00) Perth",
    "(UTC+08:00) Taipei",
    "(UTC+08:00) Ulaanbaatar",
    "(UTC+08:30) Pyongyang",
    "(UTC+08:45) Eucla",
    "(UTC+09:00) Chita",
    "(UTC+09:00) Osaka, Sapporo, Tokyo",
    "(UTC+09:00) Seoul",
    "(UTC+09:00) Yakutsk",
    "(UTC+09:30) Adelaide",
    "(UTC+09:30) Darwin",
    "(UTC+10:00) Brisbane",
    "(UTC+10:00) Canberra, Melbourne, Sydney",
    "(UTC+10:00) Guam, Port Moresby",
    "(UTC+10:00) Hobart",
    "(UTC+10:00) Vladivostok",
    "(UTC+10:30) Lord Howe Island",
    "(UTC+11:00) Bougainville Island",
    "(UTC+11:00) Chokurdakh",
    "(UTC+11:00) Magadan",
    "(UTC+11:00) Norfolk Island",
    "(UTC+11:00) Sakhalin",
    "(UTC+11:00) Solomon Is., New Caledonia",
    "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky",
    "(UTC+12:00) Auckland, Wellington",
    "(UTC+12:00) Coordinated Universal Time+12",
    "(UTC+12:00) Fiji",
    "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
    "(UTC+12:45) Chatham Islands",
    "(UTC+13:00) Nuku'alofa",
    "(UTC+13:00) Samoa",
    "(UTC+14:00) Kiritimati Island"
  ],
  RECURRENCE_TRIGGER_DESCRIPTION: "Trigger an event to run at regular, customized time intervals.",
  RECURRENCE_TRIGGER_DISPLAYNAME: "Recurrence",
  RELATIONSHIP: "Relationship",
  REQUESTRESPONSE_DISPLAYNAME: "Request / Response",
  RESPONSE_BODY_DISPLAYNAME: "Body",
  RESPONSE_DISPLAYNAME: "Response",
  RESPONSE_DESCRIPTION:
    "This is an incoming API call that could use the results of an action to trigger this flow.",
  RESPONSE_HEADERS_DISPLAYNAME: "Headers",
  RESPONSE_STATUSCODE_DISPLAYNAME: "Status Code",
  RETRY_HISTORY_STATUS: "Status:",
  RETRY_HISTORY_CLIENT_REQUEST_ID: "Client request ID:",
  RETRY_HISTORY_DURATION: "Duration:",
  RETRY_HISTORY_END_TIME: "End time:",
  RETRY_HISTORY_ERROR: "Error:",
  RETRY_HISTORY_SERVICE_REQUEST_ID: "Service request ID:",
  RETRY_HISTORY_START_TIME: "Start time:",
  RETRY_MESSAGEBAR_TEXT: "{0} retries occurred.",
  RETRY_PANEL_VIEW: "View",
  RETRY_PAGER_TITLE: "Retry",
  RUN_AFTER_TIMEDOUT_STATUS: "has timed out",
  RUN_AFTER_CONFIGURATION_CARD_TITLE: "'{0}' should run after:",
  RUN_AFTER_DISABLED_ACTION_FOLLOWING_TRIGGER:
    "Cannot configure run after on an action directly following a trigger.",
  RUN_AFTER_DISABLED_TRIGGER: "Cannot configure run after on a trigger.",
  RUN_AFTER_DISABLED_UNSUPPORTED_PHASE:
    "Run after can only be configured while editing the inputs of a step.",
  RUN_AFTER_FAILED_STATUS: "has failed",
  RUN_AFTER_SKIPPED_STATUS: "is skipped",
  RUN_AFTER_STATUS_NOT_SELECTED: "Every run after must have at least one status checked.",
  RUN_AFTER_SUCCEEDED_STATUS: "is successful",
  SCHEDULE_DISPLAYNAME: "Schedule",
  SCHEMA_NAME_DISPLAYNAME: "Schema Name",
  SCHEMA_EDITOR_SAMPLE_PAYLOAD: "Use sample payload to generate schema",
  SCHEMA_EDITOR_SAMPLE_PAYLOAD_DESCRIPTION: "Enter or paste a sample JSON payload.",
  SCHEMA_EDITOR_SAMPLE_PAYLOAD_ERROR: "Unable to generate schema: {0}.",
  SCOPE: "Scope",
  SEE_RAW_INPUTS: "Show raw inputs",
  SEE_RAW_OUTPUTS: "Show raw outputs",
  SELECT_DESCRIPTION:
    "Select the specified properties from all elements of the 'From' array into a new array.",
  SELECT_DISPLAYNAME: "Select",
  SELECT_FROM_DISPLAYNAME: "From",
  SELECT_SELECT_DISPLAYNAME: "Map",
  SERVICE_PRINCIPAL_LEARN_MORE_LINK: "Learn more",
  SERVICE_PRINCIPAL_LINK_CALLOUT_TEXT:
    "Use a service principal to connect using application permissions.",
  SERVICE_PRINCIPAL_LINK_TEXT: "Connect with Service Principal",
  SET_VARIABLE_SUMMARY: "Set variable",
  SET_VARIABLE_DESCRIPTION: "Set the variable value.",
  SET_VARIABLE_NAME_DISPLAYNAME: "Name",
  SET_VARIABLE_NAME_PLACEHOLDER: "Enter variable name",
  SET_VARIABLE_VALUE_DISPLAYNAME: "Value",
  SET_VARIABLE_VALUE_PLACEHOLDER: "Enter variable value",
  SIGN_IN_LINK_TEXT: "Connect with sign in",
  SHOW_PICKER_TEXT: "Show Picker",
  SHOW_ADVANCED_FIELDS: "Show advanced options",
  HIDE_ADVANCED_FIELDS: "Hide advanced options",
  STANDARD: "STANDARD",
  SUGGEST_OPERATION: "Please vote for or make a new suggestion on our ",
  SUGGEST_WORKFLOW_TEXT: "Information on creating manual trigger Logic Apps can be found ",
  SUGGEST_WORKFLOW_LINK_TEXT: "here",
  SWAGGER_CHOOSE_OPERATION: "Choose an operation defined by the provided swagger",
  SWAGGER_ENDPOINT: "Swagger endpoint url",
  SWAGGER_FETCH_FAILED:
    "Failed to fetch swagger. Ensure you have CORS enabled on the endpoint and are calling a valid HTTPS endpoint.",
  SWAGGER_TITLE: "HTTP + Swagger",
  SWAGGER_TRIGGER_DESCRIPTION: "Trigger an event based on a select Swagger-enabled API.",
  SWAGGER_ACTION_DESCRIPTION: "Choose a Swagger-enabled API to invoke.",
  SWITCH: "Switch",
  SWITCH_DEFAULT_CASE_TEXT: "If no case contains a matching value",
  SWITCH_EXPRESSION_LABEL: "On",
  SWITCH_CASE_LABEL: "Equals",
  SWITCH_DEFAULT_TITLE: "Default",
  SWITCH_CASE_TITLE: "Case",
  SWITCH_TO_CREATE_CONFIG_CONNECTION: "Manually enter connection information",
  SWITCH_TO_CREATE_AZURE_SERVICE_CONNECTION: "Browse current subscription",
  TABLE: "Table",
  TABLE_CSV_DISPLAY_NAME: "Create CSV table",
  TABLE_FORMAT_UNSUPPORTED:
    "Unsupported table format '{0}'. Supported formats are 'HTTP' and 'CSV'",
  TABLE_FROM_DISPLAY_NAME: "From",
  TABLE_HEADER_DISPLAY_NAME: "Header",
  TABLE_HTML_DISPLAY_NAME: "Create HTML table",
  TABLE_INCLUDE_HEADERS_DISPLAY_NAME: "Include headers",
  TEMPLATE_DEFAULT_VALUES_EDIT: "Edit",
  TEMPLATE_DEFAULT_VALUES_USE_TEXT: "Using the default values for the parameters. ",
  TESTING_CONNECTION_TEXT: "Testing connection...",
  TOGGLE_ADVANCED_PARAMETERS: "Toggle advanced parameters",
  TOKEN_FUNCTION_SECTION_COLLECTION: "Collection",
  TOKEN_FUNCTION_SECTION_STRING: "String functions",
  TOKEN_FUNCTION_SECTION_LOGICAL: "Logical functions",
  TOKEN_FUNCTION_SECTION_CONVERSION: "Conversion functions",
  TOKEN_FUNCTION_SECTION_MATH: "Math functions",
  TOKEN_FUNCTION_SECTION_DATETIME: "Date and time",
  TOKEN_FUNCTION_SECTION_REFERENCE: "Referencing functions",
  TOKEN_FUNCTION_SECTION_WORKFLOW: "Workflow functions",
  TOKEN_FUNCTION_COLLECTION_CONTAINS:
    "Returns true if a dictionary contains a key, if an array contains a value, or if a string contains a substring",
  TOKEN_FUNCTION_COLLECTION_LENGTH: "Returns the number of elements in an array or string",
  TOKEN_FUNCTION_COLLECTION_EMPTY: "Returns true if an object, array, or string is empty",
  TOKEN_FUNCTION_COLLECTION_INTERSECTION:
    "Returns a single array or object that has common elements between arrays or objects passed in",
  TOKEN_FUNCTION_COLLECTION_UNION:
    "Returns a single array or object with all the elements that are in either the array or object passed to this function",
  TOKEN_FUNCTION_COLLECTION_FIRST: "Returns the first element in the array or string passed in",
  TOKEN_FUNCTION_COLLECTION_LAST: "Returns the last element in the array or string passed in",
  TOKEN_FUNCTION_COLLECTION_TAKE:
    "Returns the first Count elements from the array or string passed in",
  TOKEN_FUNCTION_COLLECTION_SKIP: "Returns the elements in the array starting at index Count",
  TOKEN_FUNCTION_COLLECTION_JOIN:
    "Returns a string with each item of an array joined by a delimiter",
  TOKEN_FUNCTION_FUNCTION_CONCAT: "Combines any number of strings together",
  TOKEN_FUNCTION_FUNCTION_SUBSTRING: "Returns a subset of characters from a string",
  TOKEN_FUNCTION_FUNCTION_REPLACE: "Replaces a string with a given string",
  TOKEN_FUNCTION_FUNCTION_GUID: "Generates a globally unique string (GUID)",
  TOKEN_FUNCTION_FUNCTION_TOLOWER:
    "Converts a string to lowercase using the casing rules of the invariant culture",
  TOKEN_FUNCTION_FUNCTION_TOUPPER:
    "Converts a string to uppercase using the casing rules of the invariant culture",
  TOKEN_FUNCTION_FUNCTION_INDEXOF:
    "Returns the first index of a value within a string (case-insensitive, invariant culture)",
  TOKEN_FUNCTION_FUNCTION_LASTINDEXOF:
    "Returns the last index of a value within a string (case-insensitive, invariant culture)",
  TOKEN_FUNCTION_FUNCTION_STARTSWITH:
    "Checks if the string starts with a value (case-insensitive, invariant culture)",
  TOKEN_FUNCTION_FUNCTION_ENDSWITH:
    "Checks if the string ends with a value (case-insensitive, invariant culture)",
  TOKEN_FUNCTION_FUNCTION_SPLIT: "Splits the string using a separator",
  TOKEN_FUNCTION_LOGICAL_IF:
    "Returns a specified value based on whether the expression resulted in true or false",
  TOKEN_FUNCTION_LOGICAL_EQUALS: "Returns true if two values are equal",
  TOKEN_FUNCTION_LOGICAL_AND: "Returns true if both parameters are true",
  TOKEN_FUNCTION_LOGICAL_OR: "Returns true if either parameter is true",
  TOKEN_FUNCTION_LOGICAL_NOT: "Returns true if the parameters are false",
  TOKEN_FUNCTION_LOGICAL_LESS: "Returns true if the first argument is less than the second",
  TOKEN_FUNCTION_LOGICAL_LESSOREQUALS:
    "Returns true if the first argument is less than or equal to the second",
  TOKEN_FUNCTION_LOGICAL_GREATER: "Returns true if the first argument is greater than the second",
  TOKEN_FUNCTION_LOGICAL_GREATEROREQUALS:
    "Returns true if the first argument is greater than or equal to the second",
  TOKEN_FUNCTION_CONVERSION_JSON: "Convert the input to a JSON type value",
  TOKEN_FUNCTION_CONVERSION_XML: "Covert the input to an Xml type value",
  TOKEN_FUNCTION_CONVERSION_XPATH:
    "Return an array of XML nodes matching the XPath expression of a value that the XPath expression evaluates to",
  TOKEN_FUNCTION_CONVERSION_INT: "Convert the parameter to an integer",
  TOKEN_FUNCTION_CONVERSION_STRING: "Convert the parameter to a string",
  TOKEN_FUNCTION_CONVERSION_FLOAT: "Convert the parameter argument to a floating-point number",
  TOKEN_FUNCTION_CONVERSION_BOOL: "Convert the parameter to a Boolean",
  TOKEN_FUNCTION_CONVERSION_COALESCE:
    "Returns the first non-null object in the arguments passed in",
  TOKEN_FUNCTION_CONVERSION_BASE64: "Returns the base 64 representation of the input string",
  TOKEN_FUNCTION_CONVERSION_BASE64TOBINARY:
    "Returns a binary representation of a base 64 encoded string",
  TOKEN_FUNCTION_CONVERSION_BASE64TOSTRING:
    "Returns a string representation of a base 64 encoded string",
  TOKEN_FUNCTION_CONVERSION_BINARY: "Returns a binary representation of a value",
  TOKEN_FUNCTION_CONVERSION_DATAURITOBINARY: "Returns a binary representation of a data URI",
  TOKEN_FUNCTION_CONVERSION_DATAURITOSTRING: "Returns a string representation of a data URI",
  TOKEN_FUNCTION_CONVERSION_DATAURI: "Returns a data URI of a value",
  TOKEN_FUNCTION_CONVERSION_DECODEBASE64:
    "Returns a string representation of an input based64 string",
  TOKEN_FUNCTION_CONVERSION_ENCODEURICOMPONENT: "Url encodes the input string",
  TOKEN_FUNCTION_CONVERSION_DECODEURICOMPONENT: "Url decodes the input string",
  TOKEN_FUNCTION_CONVERSION_DECODEDATAURI:
    "Returns a binary representation of an input data URI string",
  TOKEN_FUNCTION_CONVERSION_URICOMPONENT: "Returns a URI encoded representation of a value",
  TOKEN_FUNCTION_CONVERSION_URICOMPONENTTOBINARY:
    "Returns a binary representation of a URI encoded string",
  TOKEN_FUNCTION_CONVERSION_URICOMPONENTTOSTRING:
    "Returns a string representation of a URI encoded string",
  TOKEN_FUNCTION_CONVERSION_ARRAY: "Convert the input to an array",
  TOKEN_FUNCTION_CONVERSION_CREATEARRAY: "Creates an array from the parameters",
  TOKEN_FUNCTION_CONVERSION_TRIGGERFORMDATAVALUE:
    "Returns a single value matching the key name from form-data or form-encoded trigger output",
  TOKEN_FUNCTION_CONVERSION_TRIGGERFORMDATAMULTIVALUES:
    "Returns an array of values matching the key name from form-data or form-encoded trigger output",
  TOKEN_FUNCTION_CONVERSION_TRIGGERMULTIPARTBODY:
    "Returns the body for a part in a multipart output of the trigger",
  TOKEN_FUNCTION_CONVERSION_FORMDATAVALUE:
    "Returns a single value matching the key name from form-data or form-encoded action output",
  TOKEN_FUNCTION_CONVERSION_FORMDATAMULTIVALUES:
    "Returns an array of values matching the key name from form-data or form-encoded action output",
  TOKEN_FUNCTION_CONVERSION_MULTIPARTBODY:
    "Returns the body for a part in a multipart output of an action",
  TOKEN_FUNCTION_MATH_MIN: "Returns the minimum value in the input array of numbers",
  TOKEN_FUNCTION_MATH_MAX: "Returns the maximum value in the input array of numbers",
  TOKEN_FUNCTION_MATH_RAND:
    "Generates a random integer within the specified range (inclusive on both ends)",
  TOKEN_FUNCTION_MATH_ADD: "Returns the result from adding the two numbers",
  TOKEN_FUNCTION_MATH_SUB: "Returns the result from subtracting two numbers",
  TOKEN_FUNCTION_MATH_MUL: "Returns the result from multiplying the two numbers",
  TOKEN_FUNCTION_MATH_DIV: "Returns the result from dividing the two numbers",
  TOKEN_FUNCTION_MATH_MOD: "Returns the remainder after dividing the two numbers (modulo)",
  TOKEN_FUNCTION_MATH_RANGE: "Generates an array of integers starting from a certain number",
  TOKEN_FUNCTION_DATETIME_UTCNOW: "Returns the current timestamp as a string",
  TOKEN_FUNCTION_DATETIME_ADDSECONDS:
    "Adds an integer number of seconds to a string timestamp passed in",
  TOKEN_FUNCTION_DATETIME_ADDMINUTES:
    "Adds an integer number of minutes to a string timestamp passed in",
  TOKEN_FUNCTION_DATETIME_ADDHOURS:
    "Adds an integer number of hours to a string timestamp passed in",
  TOKEN_FUNCTION_DATETIME_ADDDAYS: "Adds an integer number of days to a string timestamp passed in",
  TOKEN_FUNCTION_DATETIME_FORMATDATETIME: "Returns a string in date format",
  TOKEN_FUNCTION_DATETIME_STARTOFHOUR:
    "Returns the start of the hour to a string timestamp passed in",
  TOKEN_FUNCTION_DATETIME_STARTOFDAY:
    "Returns the start of the day to a string timestamp passed in",
  TOKEN_FUNCTION_DATETIME_STARTOFMONTH: "Returns the start of the month of a string timestamp",
  TOKEN_FUNCTION_DATETIME_DAYOFWEEK: "Returns the day of week component of a string timestamp",
  TOKEN_FUNCTION_DATETIME_DAYOFMONTH: "Returns the day of month component of a string timestamp",
  TOKEN_FUNCTION_DATETIME_DAYOFYEAR: "Returns the day of year component of a string timestamp",
  TOKEN_FUNCTION_DATETIME_TICKS:
    "Returns the number of ticks (100 nanoseconds interval) since 1 January 1601 00:00:00 UT of a string timestamp",
  TOKEN_FUNCTION_REFERENCE_PARAMETERS:
    "Returns a parameter value that is defined in the definition",
  TOKEN_FUNCTION_REFERENCE_ACTIONS:
    "Enables an expression to derive its value from other JSON name and value pairs or the output of the runtime action",
  TOKEN_FUNCTION_REFERENCE_TRIGGER:
    "Enables an expression to derive its value from other JSON name and value pairs or the output of the runtime trigger",
  TOKEN_FUNCTION_REFERENCE_ACTIONOUTPUTS: "Shorthand for actions('actionName').outputs",
  TOKEN_FUNCTION_REFERENCE_ACTIONBODY: "Shorthand for actions('actionName').outputs.body",
  TOKEN_FUNCTION_REFERENCE_BODY: "Shorthand for actions('actionName').outputs.body",
  TOKEN_FUNCTION_REFERENCE_TRIGGEROUTPUTS: "Shorthand for trigger().outputs",
  TOKEN_FUNCTION_REFERENCE_TRIGGERBODY: "Shorthand for trigger().outputs.body",
  TOKEN_FUNCTION_REFERENCE_ITEM:
    "When used inside a repeating action, this function returns the item that is in the array for this iteration of the action",
  TOKEN_FUNCTION_WORKFLOW_LISTCALLBACKURL: "Returns the URL to invoke the trigger or action",
  TOKEN_FUNCTION_WORKFLOW_WORKFLOW:
    "This function provides you details for the workflow itself at runtime",
  TOKEN_GROUP_VARIABLE: "Variables",
  TOKEN_PICKER_ADD_DYNAMIC_CONTENT_TEXT: "Add dynamic content",
  TOKEN_PICKER_HELP_TEXT: "Add dynamic content from the apps and services used in this flow.",
  TOKEN_PICKER_HIDE_TEXT: "Hide",
  TOKEN_PICKER_INCLUDING_DYNAMIC_CONTEXT_HEADER_TEXT: "Including dynamic content",
  TOKEN_PICKER_INCLUDING_DYNAMIC_CONTEXT_TEXT:
    "If available, dynamic content is automatically generated from the services and actions you choose for your flow.\n\nDynamic content may also be added from other sources.\n",
  TOKEN_PICKER_INCLUDING_DYNAMIC_CONTENT_LEARN_MORE_TEXT: "Learn more",
  TOKEN_PICKER_NO_DYNAMIC_CONTENT_HEADER_TEXT: "No dynamic content available",
  TOKEN_PICKER_NO_DYNAMIC_CONTENT_TEXT: "There is no content available",
  TOKEN_PICKER_NO_MATCHES_SEARCH_TIPS_HEADER_TEXT: "Search tips",
  TOKEN_PICKER_NO_MATCHES_SEARCH_TIPS_TEXT1: "Ensure words are spelled correctly.",
  TOKEN_PICKER_NO_MATCHES_SEARCH_TIPS_TEXT2: "Try less specific keywords.",
  TOKEN_PICKER_NO_MATCHES_TEXT: "There are no results for your search",
  TOKEN_PICKER_SEARCH_PLACEHOLDER_TEXT: "Search dynamic content",
  TOKEN_PICKER_SEE_LESS_TEXT: "See less",
  TOKEN_PICKER_SEE_MORE_TEXT: "See more",
  TOKEN_PICKER_EXPRESSION_MODE: "Expression",
  TOKEN_PICKER_TOKEN_MODE: "Dynamic content",
  TOKEN_PICKER_UPDATE: "Update",
  TOKEN_PICKER_OK: "OK",
  TOKEN_TITLE: "Insert parameters from previous steps",
  TRACE_TOGGLE_DISPLAY_NAME: "Toggle",
  TRACE_VISUALIZE_DISPLAY_NAME: "Visualize",
  UNAUTHORIZED: "Unauthorized",
  UNAUTHORIZED_ACTION_ERROR_MESSAGE:
    "Your IP is not in the access IP range, or you don't have permission to access the action's content.",
  UNAUTHORIZED_TRIGGER_ERROR_MESSAGE:
    "Your IP is not in the access IP range, or you don't have permission to access the trigger's content.",
  UNTIL_COUNT_DISPLAYNAME: "Count",
  UNTIL_TIMEOUT_DISPLAYNAME: "Timeout",
  URI: "uri",
  USERVOICE: "uservoice",
  USERVOICE_LINK: "http://aka.ms/connectorfeedback",
  VALIDATION_FORMAT_ERROR: "Enter a valid {0}.",
  VALIDATION_OVERFLOW_ERROR: "The value is too large.",
  VALIDATION_REQUIRED_ERROR: "Include a {0}.",
  VALIDATION_PATTERN_ERROR: "Enter a valid value for {0}.",
  VALIDATION_NO_EXPRESSION: "Expressions are not allowed.",
  VALIDATION_INITIALIZE_VARIABLE_DUPLICATE_NAME: "The variable can only be initialized once.",
  VALIDATION_VARIABLE_NAME_INVALID_LENGTH: "The length must be between 1 and 80.",
  VALIDATION_VARIABLE_NAME_INVALID_CHAR: "'{0}' is not allowed.",
  VALIDATION_VARIABLE_NAME_INVALID_ENDING: "'{0}' is not allowed to be at the end.",
  VALIDATION_VARIABLE_REFERENCE_NOT_FOUND: "Variable is not initialized.",
  VALUE_TOO_LARGE: "<Value too large to display>",
  VALUES_PANEL_TOGGLE_LESS_TEXT: "Less",
  VALUES_PANEL_TOGGLE_MORE_TEXT: "More",
  VARIABLE_TYPE_INTEGER: "Integer",
  VARIABLE_TYPE_FLOAT: "Float",
  VARIABLE_GROUP_DISPLAYNAME: "Variables",
  WAIT_ACTION_DISPLAYNAME: "Wait",
  WAIT_DELAY_DISPLAYNAME: "Delay",
  WAIT_DELAY_DESCRIPTION: "Set how long an action should be delayed once the flow is triggered.",
  WAIT_DELAY_COUNT_DISPLAYNAME: "Count",
  WAIT_DELAY_COUNT_PLACEHOLDER: "Specify the count of unit to delay",
  WAIT_DELAY_UNIT_DISPLAYNAME: "Unit",
  WAIT_DELAY_UNTIL_DISPLAYNAME: "Delay until",
  WAIT_DELAY_UNTIL_DESCRIPTION:
    "Delay an action until a specific date. For shorter time periods, use the Delay action instead.",
  WAIT_DELAY_UNTIL_TIMESTAMP: "Timestamp",
  WEBSITE: "WEBSITE",
  WORKFLOW_BODY_DISPLAYNAME: "Body",
  WORKFLOW_DISPLAYNAME: "Workflow",
  WORKFLOW_HEADERS_DISPLAYNAME: "Headers",
  WORKFLOW_SELECT_TRIGGER: "Select trigger",
  WORKFLOW_STATUS_CODE_DISPLAYNAME: "Status code",
  WORKFLOW_TITLE: "Workflow",
  WORKFLOW_TRIGGER_NAME_DISPLAYNAME: "Trigger name",
  XML_DISPLAYNAME: "XML",
  XML_VALIDATION_CONTENT_DESCRIPTION: "The XML content to validate.",
  XML_VALIDATION_DISPLAYNAME: "XML Validation",
  XML_VALIDATION_SCHEMA_NAME_DESCRIPTION:
    "The name of the XML schema to use from the associated integration account.",
  XSLT_DISPLAYNAME: "Transform XML",
  XSLT_CONTENT_DESCRIPTION: "The XML content to transform.",
  XSLT_CONTENT_SUMMARY: "Content",
  XSLT_MAP_DESCRIPTION: "The name of the map to use from the associated integration account.",
  XSLT_MAP_SUMMARY: "Map",
  XSLT_CONTAINER_DESCRIPTION: "The function container.",
  XSLT_CONTAINER_SUMMARY: "Function container",
  XSLT_FUNCTION_DESCRIPTION: "The function to use for transform.",
  XSLT_FUNCTION_SUMMARY: "Function",
  XSLT_PARAMETERS_DESCRIPTION: "The map parameters.",
  XSLT_PARAMETERS_SUMMARY: "Map parameters",
  XSLT_ERROR_INPUTS_BUILDER_NOT_AVAILABLE:
    "Inputs builder not supported for built in connection operation: {0}.",
  XSLT_ERROR_UNSUPPORTED_PARAMETER_TYPE:
    "Unsupported type of parameter: {0}. Only string type values are currently supported for map parameters.",
  ZIPCODE: "zipcode",
  ZIPCODE4: "zipcode with 4 digit extension",
  ADD_FOREACH: "Add an apply to each",
  ADD_SAVE_FLOW: "Save flow",
  BUTTON_TRIGGER_API_DISPLAY_NAME: "Flow button for mobile",
  DYNAMICS_REQUEST_APICONNECTION_TRIGGER_API_DISPLAYNAME: "Dynamics 365",
  DYNAMICS_REQUEST_APICONNECTION_TRIGGER_DESCRIPTION:
    "Triggers a flow when a record is selected in Dynamics 365",
  DYNAMICS_REQUEST_APICONNECTION_TRIGGER_DISPLAYNAME: "When a record is selected",
  FOREACH_CARD_TITLE: "Apply to each",
  HTTP_WEBHOOK_ACTION_DESCRIPTION: "Create a custom HTTP callback to occur when something happens.",
  HTTP_WEBHOOK_LIST_CALLBACK_URL_SECTION_TITLE: "Webhook reference information",
  HTTP_WEBHOOK_LIST_CALLBACK_URL_TOKEN_TITLE: "Callback url",
  HTTP_WEBHOOK_SUBSCRIBE_AUTHENTICATION_DISPLAYNAME: "Subscribe - Authentication",
  HTTP_WEBHOOK_SUBSCRIBE_BODY_DISPLAYNAME: "Subscribe - Body",
  HTTP_WEBHOOK_SUBSCRIBE_HEADERS_DISPLAYNAME: "Subscribe - Headers",
  HTTP_WEBHOOK_SUBSCRIBE_METHOD_DISPLAYNAME: "Subscribe - Method",
  HTTP_WEBHOOK_SUBSCRIBE_URI_DISPLAYNAME: "Subscribe - URI",
  HTTP_WEBHOOK_TITLE: "HTTP Webhook",
  HTTP_WEBHOOK_TRIGGER_DESCRIPTION:
    "Create a custom HTTP callback to trigger an action when something happens.",
  HTTP_WEBHOOK_UNSUBSCRIBE_AUTHENTICATION_DISPLAYNAME: "Unsubscribe - Authentication",
  HTTP_WEBHOOK_UNSUBSCRIBE_BODY_DISPLAYNAME: "Unsubscribe - Body",
  HTTP_WEBHOOK_UNSUBSCRIBE_HEADERS_DISPLAYNAME: "Unsubscribe - Headers",
  HTTP_WEBHOOK_UNSUBSCRIBE_METHOD_DISPLAYNAME: "Unsubscribe - Method",
  HTTP_WEBHOOK_UNSUBSCRIBE_URI_DISPLAYNAME: "Unsubscribe - URI",
  MANUAL_TRIGGER_DESCRIPTION:
    "Use a button or link in PowerApps to trigger an action in this flow.",
  MANUAL_TRIGGER_DISPLAYNAME: "PowerApps",
  MANUAL_TRIGGER_PATH_PARAMETERS_DISPLAYNAME: "Path Parameters",
  MANUAL_TRIGGER_TOKEN_TITLE: "Ask in PowerApps",
  MENU_DELETE_CONFIRM_MSG: "This step will be removed from the flow.",
  POWERAPPS_DISPLAYNAME: "PowerApps",
  REQUEST_TRIGGER_BODY_DISPLAYNAME: "Body",
  REQUEST_TRIGGER_CALLBACK_URL_LABEL: "HTTP POST URL",
  REQUEST_TRIGGER_CALLBACK_URL_PLACEHOLDER: "URL will be generated after save",
  REQUEST_TRIGGER_DESCRIPTION:
    "This is an incoming API call that could use actions in a Logic App or other API to trigger this flow.",
  REQUEST_TRIGGER_DISPLAYNAME: "Request",
  REQUEST_TRIGGER_HEADERS_DISPLAYNAME: "Headers",
  REQUEST_TRIGGER_SCHEMA_LABEL: "Request Body JSON Schema",
  REQUEST_TRIGGER_SCHEMA_PLACEHOLDER:
    'Example: \n{ \n \t "type": "object", \n \t "properties": { \n \t \t "address": { \n \t \t \t "type": "string" \n \t \t} \n \t }, \n \t "required": ["address"] \n}',
  UNTIL_CARD_TITLE: "Do until"
} as any;
const FunctionGroupDefinitions = [
  {
    id: "string",
    name: descriptions.TOKEN_FUNCTION_SECTION_STRING,
    functions: [
      {
        name: "concat",
        defaultSignature: "concat(text_1, text_2?, ...)",
        description: descriptions.TOKEN_FUNCTION_FUNCTION_CONCAT,
        signatures: [
          {
            definition: "concat(text_1: string)",
            documentation: descriptions.TOKEN_FUNCTION_FUNCTION_CONCAT,
            parameters: [
              {
                name: "text_1",
                documentation: descriptions.TOKEN_PARAMETER_CONCAT_ALL
              }
            ]
          },
          {
            definition: "concat(text_1: string, ...)",
            documentation: descriptions.TOKEN_FUNCTION_FUNCTION_CONCAT,
            parameters: [
              {
                name: "text_1",
                documentation: descriptions.TOKEN_PARAMETER_CONCAT_ALL,
                type: "string"
              },
              {
                name: "text",
                documentation: descriptions.TOKEN_PARAMETER_CONCAT_ALL,
                type: "string",
                isVariable: !0
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "substring",
        defaultSignature: "substring(text, startIndex, length?)",
        description: descriptions.TOKEN_FUNCTION_FUNCTION_SUBSTRING,
        signatures: [
          {
            definition: "substring(text: string, startIndex: integer, length?: integer)",
            documentation: descriptions.TOKEN_FUNCTION_FUNCTION_SUBSTRING,
            parameters: [
              {
                name: "text",
                documentation: descriptions.TOKEN_PARAMETER_SUBSTRING_TEXT
              },
              {
                name: "startIndex",
                documentation: descriptions.TOKEN_PARAMETER_SUBSTRING_STARTINDEX
              },
              {
                name: "length",
                documentation: descriptions.TOKEN_PARAMETER_SUBSTRING_LENGTH
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "replace",
        defaultSignature: "replace(text, oldText, newText)",
        description: descriptions.TOKEN_FUNCTION_FUNCTION_REPLACE,
        signatures: [
          {
            definition: "replace(text: string, oldText: string, newText: string)",
            documentation: descriptions.TOKEN_FUNCTION_FUNCTION_REPLACE,
            parameters: [
              {
                name: "text",
                documentation: descriptions.TOKEN_PARAMETER_REPLACE_TEXT
              },
              {
                name: "oldText",
                documentation: descriptions.TOKEN_PARAMETER_REPLACE_TEXT
              },
              {
                name: "newText",
                documentation: descriptions.TOKEN_PARAMETER_REPLACE_TEXT
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "guid",
        defaultSignature: "guid()",
        description: descriptions.TOKEN_FUNCTION_FUNCTION_GUID,
        signatures: [
          {
            definition: "guid()",
            documentation: descriptions.TOKEN_FUNCTION_FUNCTION_GUID,
            parameters: []
          },
          {
            definition: "guid(format: string)",
            documentation: descriptions.TOKEN_FUNCTION_FUNCTION_GUID,
            parameters: [
              {
                name: "format",
                documentation: descriptions.TOKEN_PARAMETER_GUID_FORMAT
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "toLower",
        defaultSignature: "toLower(text)",
        description: descriptions.TOKEN_FUNCTION_FUNCTION_TOLOWER,
        signatures: [
          {
            definition: "toLower(text: string)",
            documentation: descriptions.TOKEN_FUNCTION_FUNCTION_TOLOWER,
            parameters: [
              {
                name: "text",
                documentation: descriptions.TOKEN_PARAMETER_TOLOWER_TEXT
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "toUpper",
        defaultSignature: "toUpper(text)",
        description: descriptions.TOKEN_FUNCTION_FUNCTION_TOUPPER,
        signatures: [
          {
            definition: "toUpper(text: string)",
            documentation: descriptions.TOKEN_FUNCTION_FUNCTION_TOUPPER,
            parameters: [
              {
                name: "text",
                documentation: descriptions.TOKEN_PARAMETER_TOUPPER_TEXT
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "indexOf",
        defaultSignature: "indexOf(text, searchText)",
        description: descriptions.TOKEN_FUNCTION_FUNCTION_INDEXOF,
        signatures: [
          {
            definition: "indexOf(text: string, searchText: string)",
            documentation: descriptions.TOKEN_FUNCTION_FUNCTION_INDEXOF,
            parameters: [
              {
                name: "text",
                documentation: descriptions.TOKEN_PARAMETER_INDEXOF_TEXT
              },
              {
                name: "searchText",
                documentation: descriptions.TOKEN_PARAMETER_INDEXOF_SEARCHTEXT
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "lastIndexOf",
        defaultSignature: "lastIndexOf(text, searchText)",
        description: descriptions.TOKEN_FUNCTION_FUNCTION_LASTINDEXOF,
        signatures: [
          {
            definition: "lastIndexOf(text: string, searchText: string)",
            documentation: descriptions.TOKEN_FUNCTION_FUNCTION_LASTINDEXOF,
            parameters: [
              {
                name: "text",
                documentation: descriptions.TOKEN_PARAMETER_INDEXOF_TEXT
              },
              {
                name: "searchText",
                documentation: descriptions.TOKEN_PARAMETER_INDEXOF_SEARCHTEXT
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "startsWith",
        defaultSignature: "startsWith(text, searchText)",
        description: descriptions.TOKEN_FUNCTION_FUNCTION_STARTSWITH,
        signatures: [
          {
            definition: "startsWith(text: string, searchText: string)",
            documentation: descriptions.TOKEN_FUNCTION_FUNCTION_STARTSWITH,
            parameters: [
              {
                name: "text",
                documentation: descriptions.TOKEN_PARAMETER_STARTSWITH_TEXT
              },
              {
                name: "searchText",
                documentation: descriptions.TOKEN_PARAMETER_STARTSWITH_SEARCHTEXT
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "endsWith",
        defaultSignature: "endsWith(text, searchText)",
        description: descriptions.TOKEN_FUNCTION_FUNCTION_ENDSWITH,
        signatures: [
          {
            definition: "endsWith(text: string, searchText: string)",
            documentation: descriptions.TOKEN_FUNCTION_FUNCTION_ENDSWITH,
            parameters: [
              {
                name: "text",
                documentation: descriptions.TOKEN_PARAMETER_ENDSWITH_TEXT
              },
              {
                name: "searchText",
                documentation: descriptions.TOKEN_PARAMETER_ENDSWITH_SEARCHTEXT
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "split",
        defaultSignature: "split(text, separator)",
        description: descriptions.TOKEN_FUNCTION_FUNCTION_SPLIT,
        signatures: [
          {
            definition: "split(text: string, separator: string)",
            documentation: descriptions.TOKEN_FUNCTION_FUNCTION_SPLIT,
            parameters: [
              {
                name: "text",
                documentation: descriptions.TOKEN_PARAMETER_SPLIT_TEXT
              },
              {
                name: "separator",
                documentation: descriptions.TOKEN_PARAMETER_SPLIT_SEPARATOR
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "trim",
        defaultSignature: "trim(text)",
        description: descriptions.TOKEN_FUNCTION_FUNCTION_TRIM,
        signatures: [
          {
            definition: "trim(text: string)",
            documentation: descriptions.TOKEN_FUNCTION_FUNCTION_TRIM,
            parameters: [
              {
                name: "text",
                documentation: descriptions.TOKEN_PARAMETER_TRIM_TEXT
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "formatNumber",
        defaultSignature: "formatNumber(number, format, locale?)",
        description: descriptions.TOKEN_FUNCTION_FUNCTION_FORMATNUMBER,
        signatures: [
          {
            definition: "formatNumber(number: number, format: string, locale?: string)",
            documentation: descriptions.TOKEN_FUNCTION_FUNCTION_FORMATNUMBER,
            parameters: [
              {
                name: "number",
                documentation: descriptions.TOKEN_PARAMETER_FORMATNUMBER_NUMBER
              },
              {
                name: "format",
                documentation: descriptions.TOKEN_PARAMETER_FORMATNUMBER_FORMAT
              },
              {
                name: "locale",
                documentation: descriptions.TOKEN_PARAMETER_FORMATNUMBER_LOCALE
              }
            ]
          }
        ],
        isAdvanced: !0
      }
    ]
  },
  {
    id: "collection",
    name: descriptions.TOKEN_FUNCTION_SECTION_COLLECTION,
    functions: [
      {
        name: "contains",
        defaultSignature: "contains(collection, value)",
        description: descriptions.TOKEN_FUNCTION_COLLECTION_CONTAINS,
        signatures: [
          {
            definition: "contains(collection: array|string, value: string)",
            documentation: descriptions.TOKEN_FUNCTION_COLLECTION_CONTAINS,
            parameters: [
              {
                name: "collection",
                documentation: descriptions.TOKEN_PARAMETER_CONTAINS_COLLECTION
              },
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_CONTAINS_VALUE
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "length",
        defaultSignature: "length(collection)",
        description: descriptions.TOKEN_FUNCTION_COLLECTION_LENGTH,
        signatures: [
          {
            definition: "length(collection: array|string)",
            documentation: descriptions.TOKEN_FUNCTION_COLLECTION_LENGTH,
            parameters: [
              {
                name: "collection",
                documentation: descriptions.TOKEN_PARAMETER_LENGTH_COLLECTION
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "empty",
        defaultSignature: "empty(collection)",
        description: descriptions.TOKEN_FUNCTION_COLLECTION_EMPTY,
        signatures: [
          {
            definition: "empty(collection: object|array|string)",
            documentation: descriptions.TOKEN_FUNCTION_COLLECTION_EMPTY,
            parameters: [
              {
                name: "collection",
                documentation: descriptions.TOKEN_PARAMETER_EMPTY_COLLECTION
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "intersection",
        defaultSignature: "intersection(collection_1, collection_2, ...)",
        description: descriptions.TOKEN_FUNCTION_COLLECTION_INTERSECTION,
        signatures: [
          {
            definition: "intersection(collection_1: object|array, collection_2: object|array)",
            documentation: descriptions.TOKEN_FUNCTION_COLLECTION_INTERSECTION_INFO,
            parameters: [
              {
                name: "collection_1",
                documentation: descriptions.TOKEN_PARAMETER_INTERSECTION_ALL
              },
              {
                name: "collection_2",
                documentation: descriptions.TOKEN_PARAMETER_INTERSECTION_ALL
              }
            ]
          },
          {
            definition: "intersection(collection_1: object|array, collection_2: object|array, ...)",
            documentation: descriptions.TOKEN_FUNCTION_COLLECTION_INTERSECTION_INFO,
            parameters: [
              {
                name: "collection_1",
                documentation: descriptions.TOKEN_PARAMETER_INTERSECTION_ALL
              },
              {
                name: "collection_2",
                documentation: descriptions.TOKEN_PARAMETER_INTERSECTION_ALL
              },
              {
                name: "collection",
                documentation: descriptions.TOKEN_PARAMETER_INTERSECTION_ALL,
                type: "object|array",
                isVariable: !0
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "union",
        defaultSignature: "union(collection_1, collection_2?, ...)",
        description: descriptions.TOKEN_FUNCTION_COLLECTION_UNION,
        signatures: [
          {
            definition: "union(collection_1: object|array, collection_2: object|array)",
            documentation: descriptions.TOKEN_FUNCTION_COLLECTION_UNION_INFO,
            parameters: [
              {
                name: "collection_1",
                documentation: descriptions.TOKEN_PARAMETER_UNION_ALL
              },
              {
                name: "collection_2",
                documentation: descriptions.TOKEN_PARAMETER_UNION_ALL
              }
            ]
          },
          {
            definition: "union(collection_1: object|array, collection_2: object|array, ...)",
            documentation: descriptions.TOKEN_FUNCTION_COLLECTION_UNION_INFO,
            parameters: [
              {
                name: "collection_1",
                documentation: descriptions.TOKEN_PARAMETER_UNION_ALL
              },
              {
                name: "collection_2",
                documentation: descriptions.TOKEN_PARAMETER_UNION_ALL
              },
              {
                name: "collection",
                documentation: descriptions.TOKEN_PARAMETER_UNION_ALL,
                type: "object|array",
                isVariable: !0
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "first",
        defaultSignature: "first(collection)",
        description: descriptions.TOKEN_FUNCTION_COLLECTION_FIRST,
        signatures: [
          {
            definition: "first(collection: array|string)",
            documentation: descriptions.TOKEN_FUNCTION_COLLECTION_FIRST,
            parameters: [
              {
                name: "collection",
                documentation: descriptions.TOKEN_PARAMETER_FIRST_COLLECTION
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "last",
        defaultSignature: "last(collection)",
        description: descriptions.TOKEN_FUNCTION_COLLECTION_LAST,
        signatures: [
          {
            definition: "last(collection: array|string)",
            documentation: descriptions.TOKEN_FUNCTION_COLLECTION_LAST,
            parameters: [
              {
                name: "collection",
                documentation: descriptions.TOKEN_PARAMETER_LAST_COLLECTION
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "take",
        defaultSignature: "take(value, count)",
        description: descriptions.TOKEN_FUNCTION_COLLECTION_TAKE,
        signatures: [
          {
            definition: "take(collection: array|string, count: integer)",
            documentation: descriptions.TOKEN_FUNCTION_COLLECTION_TAKE,
            parameters: [
              {
                name: "collection",
                documentation: descriptions.TOKEN_PARAMETER_TAKE_COLLECTION
              },
              {
                name: "count",
                documentation: descriptions.TOKEN_PARAMETER_TAKE_COUNT
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "skip",
        defaultSignature: "skip(collection, count)",
        description: descriptions.TOKEN_FUNCTION_COLLECTION_SKIP,
        signatures: [
          {
            definition: "skip(collection: array, count: integer)",
            documentation: descriptions.TOKEN_FUNCTION_COLLECTION_SKIP,
            parameters: [
              {
                name: "collection",
                documentation: descriptions.TOKEN_PARAMETER_SKIP_COLLECTION
              },
              {
                name: "count",
                documentation: descriptions.TOKEN_PARAMETER_SKIP_COUNT
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "join",
        defaultSignature: "join(collection, delimiter)",
        description: descriptions.TOKEN_FUNCTION_COLLECTION_JOIN,
        signatures: [
          {
            definition: "join(collection: array, delimiter: string)",
            documentation: descriptions.TOKEN_FUNCTION_COLLECTION_JOIN,
            parameters: [
              {
                name: "collection",
                documentation: descriptions.TOKEN_PARAMETER_JOIN_COLLECTION
              },
              {
                name: "delimiter",
                documentation: descriptions.TOKEN_PARAMETER_JOIN_DELIMITER
              }
            ]
          }
        ],
        isAdvanced: !0
      }
    ]
  },
  {
    id: "logical",
    name: descriptions.TOKEN_FUNCTION_SECTION_LOGICAL,
    functions: [
      {
        name: "if",
        defaultSignature: "if(expression, valueIfTrue, valueIfFalse)",
        description: descriptions.TOKEN_FUNCTION_LOGICAL_IF,
        signatures: [
          {
            definition: "if(expression: boolean, valueIfTrue: any, valueIfFalse: any)",
            documentation: descriptions.TOKEN_FUNCTION_LOGICAL_IF,
            parameters: [
              {
                name: "expression",
                documentation: descriptions.TOKEN_PARAMETER_IF_EXPRESSION
              },
              {
                name: "valueIfTrue",
                documentation: descriptions.TOKEN_PARAMETER_IF_VALUEIFTRUE
              },
              {
                name: "valueIfFalse",
                documentation: descriptions.TOKEN_PARAMETER_IF_VALUEIFFALSE
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "equals",
        defaultSignature: "equals(object1, object2)",
        description: descriptions.TOKEN_FUNCTION_LOGICAL_EQUALS,
        signatures: [
          {
            definition: "equals(object1: any, object2: any)",
            documentation: descriptions.TOKEN_FUNCTION_LOGICAL_EQUALS,
            parameters: [
              {
                name: "object1",
                documentation: descriptions.TOKEN_PARAMETER_EQUALS_OBJECT1
              },
              {
                name: "object2",
                documentation: descriptions.TOKEN_PARAMETER_EQUALS_OBJECT2
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "and",
        defaultSignature: "and(expression1, expression2)",
        description: descriptions.TOKEN_FUNCTION_LOGICAL_AND,
        signatures: [
          {
            definition: "and(expression1: boolean, expression2: boolean)",
            documentation: descriptions.TOKEN_FUNCTION_LOGICAL_AND,
            parameters: [
              {
                name: "expression1",
                documentation: descriptions.TOKEN_PARAMETER_AND_EXPRESSION1
              },
              {
                name: "expression2",
                documentation: descriptions.TOKEN_PARAMETER_AND_EXPRESSION2
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "or",
        defaultSignature: "or(expression1, expression2)",
        description: descriptions.TOKEN_FUNCTION_LOGICAL_OR,
        signatures: [
          {
            definition: "or(expression1: boolean, expression2: boolean)",
            documentation: descriptions.TOKEN_FUNCTION_LOGICAL_OR,
            parameters: [
              {
                name: "expression1",
                documentation: descriptions.TOKEN_PARAMETER_OR_EXPRESSION1
              },
              {
                name: "expression2",
                documentation: descriptions.TOKEN_PARAMETER_OR_EXPRESSION2
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "not",
        defaultSignature: "not(expression)",
        description: descriptions.TOKEN_FUNCTION_LOGICAL_NOT,
        signatures: [
          {
            definition: "not(expression: boolean)",
            documentation: descriptions.TOKEN_FUNCTION_LOGICAL_NOT,
            parameters: [
              {
                name: "expression",
                documentation: descriptions.TOKEN_PARAMETER_NOT_EXPRESSION
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "less",
        defaultSignature: "less(value, compareTo)",
        description: descriptions.TOKEN_FUNCTION_LOGICAL_LESS,
        signatures: [
          {
            definition: "less(value: integer|float|string, compareTo: integer|float|string)",
            documentation: descriptions.TOKEN_FUNCTION_LOGICAL_LESS,
            parameters: [
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_LESS_VALUE
              },
              {
                name: "compareTo",
                documentation: descriptions.TOKEN_PARAMETER_LESS_COMPARETO
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "lessOrEquals",
        defaultSignature: "lessOrEquals(value, compareTo)",
        description: descriptions.TOKEN_FUNCTION_LOGICAL_LESSOREQUALS,
        signatures: [
          {
            definition:
              "lessOrEquals(value: integer|float|string, compareTo: integer|float|string)",
            documentation: descriptions.TOKEN_FUNCTION_LOGICAL_LESSOREQUALS,
            parameters: [
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_LESSOREQUALS_VALUE
              },
              {
                name: "compareTo",
                documentation: descriptions.TOKEN_PARAMETER_LESSOREQUALS_COMPARETO
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "greater",
        defaultSignature: "greater(value, compareTo)",
        description: descriptions.TOKEN_FUNCTION_LOGICAL_GREATER,
        signatures: [
          {
            definition: "greater(value: integer|float|string, compareTo: integer|float|string)",
            documentation: descriptions.TOKEN_FUNCTION_LOGICAL_GREATER,
            parameters: [
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_GREATER_VALUE
              },
              {
                name: "compareTo",
                documentation: descriptions.TOKEN_PARAMETER_GREATER_COMPARETO
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "greaterOrEquals",
        defaultSignature: "greaterOrEquals(value, compareTo)",
        description: descriptions.TOKEN_FUNCTION_LOGICAL_GREATEROREQUALS,
        signatures: [
          {
            definition:
              "greaterOrEquals(value: integer|float|string, compareTo: integer|float|string)",
            documentation: descriptions.TOKEN_FUNCTION_LOGICAL_GREATEROREQUALS,
            parameters: [
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_GREATEROREQUALS_VALUE
              },
              {
                name: "compareTo",
                documentation: descriptions.TOKEN_PARAMETER_GREATEROREQUALS_COMPARETO
              }
            ]
          }
        ],
        isAdvanced: !0
      }
    ]
  },
  {
    id: "conversion",
    name: descriptions.TOKEN_FUNCTION_SECTION_CONVERSION,
    functions: [
      {
        name: "json",
        defaultSignature: "json(value)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_JSON,
        signatures: [
          {
            definition: "json(value: string)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_JSON,
            parameters: [
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_JSON_VALUE
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "xml",
        defaultSignature: "xml(value)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_XML,
        signatures: [
          {
            definition: "xml(value: string)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_XML,
            parameters: [
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_XML_VALUE
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "int",
        defaultSignature: "int(value)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_INT,
        signatures: [
          {
            definition: "int(value: string)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_INT,
            parameters: [
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_INT_VALUE
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "string",
        defaultSignature: "string(value)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_STRING,
        signatures: [
          {
            definition: "string(value: any)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_STRING,
            parameters: [
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_STRING_VALUE
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "float",
        defaultSignature: "float(value)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_FLOAT,
        signatures: [
          {
            definition: "float(value: string)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_FLOAT,
            parameters: [
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_FLOAT_VALUE
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "bool",
        defaultSignature: "bool(value)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_BOOL,
        signatures: [
          {
            definition: "bool(value: any)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_BOOL,
            parameters: [
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_BOOL_VALUE
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "base64",
        defaultSignature: "base64(value)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_BASE64,
        signatures: [
          {
            definition: "base64(value: string)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_BASE64,
            parameters: [
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_BASE64_VALUE
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "base64ToBinary",
        defaultSignature: "base64ToBinary(value)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_BASE64TOBINARY,
        signatures: [
          {
            definition: "base64ToBinary(value: string)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_BASE64TOBINARY,
            parameters: [
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_BASE64TOBINARY_VALUE
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "base64ToString",
        defaultSignature: "base64ToString(value)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_BASE64TOSTRING,
        signatures: [
          {
            definition: "base64ToString(value: string)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_BASE64TOSTRING,
            parameters: [
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_BASE64TOSTRING_VALUE
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "binary",
        defaultSignature: "binary(value)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_BINARY,
        signatures: [
          {
            definition: "binary(value: string)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_BINARY,
            parameters: [
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_BINARY_VALUE
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "dataUriToBinary",
        defaultSignature: "dataUriToBinary(value)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_DATAURITOBINARY,
        signatures: [
          {
            definition: "dataUriToBinary(value: string)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_DATAURITOBINARY,
            parameters: [
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_DATAURITOBINARY_VALUE
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "dataUriToString",
        defaultSignature: "dataUriToString(value)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_DATAURITOSTRING,
        signatures: [
          {
            definition: "dataUriToString(value: string)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_DATAURITOSTRING,
            parameters: [
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_DATAURITOSTRING_VALUE
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "dataUri",
        defaultSignature: "dataUri(value)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_DATAURI,
        signatures: [
          {
            definition: "dataUri(value: string)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_DATAURI,
            parameters: [
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_DATAURI_VALUE
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "decodeBase64",
        defaultSignature: "decodeBase64(value)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_DECODEBASE64,
        signatures: [
          {
            definition: "decodeBase64(value: string)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_DECODEBASE64,
            parameters: [
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_DECODEBASE64_VALUE
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "encodeUriComponent",
        defaultSignature: "encodeUriComponent(value)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_ENCODEURICOMPONENT,
        signatures: [
          {
            definition: "encodeUriComponent(value: string)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_ENCODEURICOMPONENT,
            parameters: [
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_ENCODEURICOMPONENT_VALUE
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "decodeUriComponent",
        defaultSignature: "decodeUriComponent(value)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_DECODEURICOMPONENT,
        signatures: [
          {
            definition: "decodeUriComponent(value: string)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_DECODEURICOMPONENT,
            parameters: [
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_DECODEURICOMPONENT_VALUE
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "decodeDataUri",
        defaultSignature: "decodeDataUri(value)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_DECODEDATAURI,
        signatures: [
          {
            definition: "decodeDataUri(value: string)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_DECODEDATAURI,
            parameters: [
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_DECODEDATAURI_VALUE
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "uriComponent",
        defaultSignature: "uriComponent(value)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_URICOMPONENT,
        signatures: [
          {
            definition: "uriComponent(value: string)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_URICOMPONENT,
            parameters: [
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_URICOMPONENT_VALUE
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "uriComponentToBinary",
        defaultSignature: "uriComponentToBinary(value)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_URICOMPONENTTOBINARY,
        signatures: [
          {
            definition: "uriComponentToBinary(value: string)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_URICOMPONENTTOBINARY,
            parameters: [
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_URICOMPONENTTOBINARY_VALUE
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "uriComponentToString",
        defaultSignature: "uriComponentToString(value)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_URICOMPONENTTOSTRING,
        signatures: [
          {
            definition: "uriComponentToString(value: string)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_URICOMPONENTTOSTRING,
            parameters: [
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_URICOMPONENTTOSTRING_VALUE
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "array",
        defaultSignature: "array(value)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_ARRAY,
        signatures: [
          {
            definition: "array(value: string)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_ARRAY,
            parameters: [
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_ARRAY_VALUE
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "createArray",
        defaultSignature: "createArray(object_1, object_2?, ...)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_CREATEARRAY,
        signatures: [
          {
            definition: "createArray(object_1: any)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_CREATEARRAY,
            parameters: [
              {
                name: "object_1",
                documentation: descriptions.TOKEN_PARAMETER_CREATEARRAY_ALL
              }
            ]
          },
          {
            definition: "createArray(object_1: any, ...)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_CREATEARRAY,
            parameters: [
              {
                name: "object_1",
                documentation: descriptions.TOKEN_PARAMETER_CREATEARRAY_ALL,
                type: "any"
              },
              {
                name: "object",
                documentation: descriptions.TOKEN_PARAMETER_CREATEARRAY_ALL,
                type: "any",
                isVariable: !0
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "triggerFormDataValue",
        defaultSignature: "triggerFormDataValue(key)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_TRIGGERFORMDATAVALUE,
        signatures: [
          {
            definition: "triggerFormDataValue(key: string)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_TRIGGERFORMDATAVALUE,
            parameters: [
              {
                name: "key",
                documentation: descriptions.TOKEN_PARAMETER_TRIGGERFORMDATAVALUE_KEY
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "triggerFormDataMultiValues",
        defaultSignature: "triggerFormDataMultiValues(key)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_TRIGGERFORMDATAMULTIVALUES,
        signatures: [
          {
            definition: "triggerFormDataMultiValues(key: string)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_TRIGGERFORMDATAMULTIVALUES,
            parameters: [
              {
                name: "key",
                documentation: descriptions.TOKEN_PARAMETER_TRIGGERFORMDATAMULTIVALUES_KEY
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "triggerMultipartBody",
        defaultSignature: "triggerMultipartBody(index)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_TRIGGERMULTIPARTBODY,
        signatures: [
          {
            definition: "triggerMultipartBody(index: number)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_TRIGGERMULTIPARTBODY,
            parameters: [
              {
                name: "index",
                documentation: descriptions.TOKEN_PARAMETER_TRIGGERMULTIPARTBODY_INDEX
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "formDataValue",
        defaultSignature: "formDataValue(actionName, key)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_FORMDATAVALUE,
        signatures: [
          {
            definition: "formDataValue(actionName: string, key: string)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_FORMDATAVALUE,
            parameters: [
              {
                name: "actionName",
                documentation: descriptions.TOKEN_PARAMETER_FORMDATAVALUE_ACTIONNAME
              },
              {
                name: "key",
                documentation: descriptions.TOKEN_PARAMETER_FORMDATAVALUE_KEY
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "formDataMultiValues",
        defaultSignature: "formDataMultiValues(actionName, key)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_FORMDATAMULTIVALUES,
        signatures: [
          {
            definition: "formDataMultiValues(actionName: string, key: string)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_FORMDATAMULTIVALUES,
            parameters: [
              {
                name: "actionName",
                documentation: descriptions.TOKEN_PARAMETER_FORMDATAMULTIVALUES_ACTIONNAME
              },
              {
                name: "key",
                documentation: descriptions.TOKEN_PARAMETER_FORMDATAMULTIVALUES_KEY
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "multipartBody",
        defaultSignature: "multipartBody(actionName, index)",
        description: descriptions.TOKEN_FUNCTION_CONVERSION_MULTIPARTBODY,
        signatures: [
          {
            definition: "multipartBody(actionName: string, index: number)",
            documentation: descriptions.TOKEN_FUNCTION_CONVERSION_MULTIPARTBODY,
            parameters: [
              {
                name: "actionName",
                documentation: descriptions.TOKEN_PARAMETER_MULTIPARTBODY_ACTIONNAME
              },
              {
                name: "index",
                documentation: descriptions.TOKEN_PARAMETER_MULTIPARTBODY_INDEX
              }
            ]
          }
        ],
        isAdvanced: !0
      }
    ]
  },
  {
    id: "math",
    name: descriptions.TOKEN_FUNCTION_SECTION_MATH,
    functions: [
      {
        name: "min",
        defaultSignature: "min(collection or item1, item2?, ...)",
        description: descriptions.TOKEN_FUNCTION_MATH_MIN,
        signatures: [
          {
            definition: "min(value_1: array|number)",
            documentation: descriptions.TOKEN_FUNCTION_MATH_MIN,
            parameters: [
              {
                name: "value_1",
                documentation: descriptions.TOKEN_PARAMETER_MIN_ALL
              }
            ]
          },
          {
            definition: "min(value_1: array|number, ...)",
            documentation: descriptions.TOKEN_FUNCTION_MATH_MIN,
            parameters: [
              {
                name: "value_1",
                documentation: descriptions.TOKEN_PARAMETER_MIN_ALL
              },
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_MIN_ALL,
                type: "array|number",
                isVariable: !0
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "max",
        defaultSignature: "max(collection or item1, item2?, ...)",
        description: descriptions.TOKEN_FUNCTION_MATH_MAX,
        signatures: [
          {
            definition: "max(value_1: array|number)",
            documentation: descriptions.TOKEN_FUNCTION_MATH_MAX,
            parameters: [
              {
                name: "value_1",
                documentation: descriptions.TOKEN_PARAMETER_MAX_ALL
              }
            ]
          },
          {
            definition: "max(value_1: array|number, ...)",
            documentation: descriptions.TOKEN_FUNCTION_MATH_MAX,
            parameters: [
              {
                name: "value_1",
                documentation: descriptions.TOKEN_PARAMETER_MAX_ALL
              },
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_MAX_ALL,
                type: "array|number",
                isVariable: !0
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "rand",
        defaultSignature: "rand(minValue, maxValue)",
        description: descriptions.TOKEN_FUNCTION_MATH_RAND,
        signatures: [
          {
            definition: "rand(minValue: integer, maxValue: integer)",
            documentation: descriptions.TOKEN_FUNCTION_MATH_RAND,
            parameters: [
              {
                name: "minValue",
                documentation: descriptions.TOKEN_PARAMETER_RAND_MINVALUE
              },
              {
                name: "maxValue",
                documentation: descriptions.TOKEN_PARAMETER_RAND_MAXVALUE
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "add",
        defaultSignature: "add(summand_1, summand_2)",
        description: descriptions.TOKEN_FUNCTION_MATH_ADD,
        signatures: [
          {
            definition: "add(summand_1: number, summand_2: number)",
            documentation: descriptions.TOKEN_FUNCTION_MATH_ADD,
            parameters: [
              {
                name: "summand_1",
                documentation: descriptions.TOKEN_PARAMETER_ADD_SUMMAND1
              },
              {
                name: "summand_2",
                documentation: descriptions.TOKEN_PARAMETER_ADD_SUMMAND2
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "sub",
        defaultSignature: "sub(minuend, subtrahend)",
        description: descriptions.TOKEN_FUNCTION_MATH_SUB,
        signatures: [
          {
            definition: "sub(minuend: number, subtrahend: number)",
            documentation: descriptions.TOKEN_FUNCTION_MATH_SUB,
            parameters: [
              {
                name: "minuend",
                documentation: descriptions.TOKEN_PARAMETER_SUB_MINUEND
              },
              {
                name: "subtrahend",
                documentation: descriptions.TOKEN_PARAMETER_SUB_SUBTRAHEND
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "mul",
        defaultSignature: "mul(multiplicand_1, multiplicand_2)",
        description: descriptions.TOKEN_FUNCTION_MATH_MUL,
        signatures: [
          {
            definition: "mul(multiplicand_1: number, multiplicand_2: number)",
            documentation: descriptions.TOKEN_FUNCTION_MATH_MUL,
            parameters: [
              {
                name: "multiplicand_1",
                documentation: descriptions.TOKEN_PARAMETER_MUL_MULTIPLICAND1
              },
              {
                name: "multiplicand_2",
                documentation: descriptions.TOKEN_PARAMETER_MUL_MULTIPLICAND2
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "div",
        defaultSignature: "div(dividend, divisor)",
        description: descriptions.TOKEN_FUNCTION_MATH_DIV,
        signatures: [
          {
            definition: "div(dividend: number, divisor: number)",
            documentation: descriptions.TOKEN_FUNCTION_MATH_DIV,
            parameters: [
              {
                name: "dividend",
                documentation: descriptions.TOKEN_PARAMETER_DIV_DIVIDEND
              },
              {
                name: "divisor",
                documentation: descriptions.TOKEN_PARAMETER_DIV_DIVISOR
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "mod",
        defaultSignature: "mod(dividend, divisor)",
        description: descriptions.TOKEN_FUNCTION_MATH_MOD,
        signatures: [
          {
            definition: "mod(dividend: number, divisor: number)",
            documentation: descriptions.TOKEN_FUNCTION_MATH_MOD,
            parameters: [
              {
                name: "dividend",
                documentation: descriptions.TOKEN_PARAMETER_MOD_DIVIDEND
              },
              {
                name: "divisor",
                documentation: descriptions.TOKEN_PARAMETER_MOD_DIVISOR
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "range",
        defaultSignature: "range(startIndex, count)",
        description: descriptions.TOKEN_FUNCTION_MATH_RANGE,
        signatures: [
          {
            definition: "range(startIndex: integer, count: integer)",
            documentation: descriptions.TOKEN_FUNCTION_MATH_RANGE,
            parameters: [
              {
                name: "startIndex",
                documentation: descriptions.TOKEN_PARAMETER_RANGE_STARTINDEX
              },
              {
                name: "count",
                documentation: descriptions.TOKEN_PARAMETER_RANGE_COUNT
              }
            ]
          }
        ],
        isAdvanced: !0
      }
    ]
  },
  {
    id: "dateTime",
    name: descriptions.TOKEN_FUNCTION_SECTION_DATETIME,
    functions: [
      {
        name: "utcNow",
        defaultSignature: "utcNow()",
        description: descriptions.TOKEN_FUNCTION_DATETIME_UTCNOW,
        signatures: [
          {
            definition: "utcNow()",
            documentation: descriptions.TOKEN_FUNCTION_DATETIME_UTCNOW,
            parameters: []
          }
        ],
        isAdvanced: !1
      },
      {
        name: "getFutureTime",
        defaultSignature: "getFutureTime(interval, timeUnit, format?)",
        description: descriptions.TOKEN_FUNCTION_DATETIME_GETFUTURETIME,
        signatures: [
          {
            definition: "getFutureTime(interval: integer, timeUnit: string, format?: string)",
            documentation: descriptions.TOKEN_FUNCTION_DATETIME_GETFUTURETIME,
            parameters: [
              {
                name: "interval",
                documentation: descriptions.TOKEN_PARAMETER_GETFUTURETIME_INTERVAL
              },
              {
                name: "timeUnit",
                documentation: descriptions.TOKEN_PARAMETER_GETFUTURETIME_TIMEUNIT
              },
              {
                name: "format",
                documentation: descriptions.TOKEN_PARAMETER_GETFUTURETIME_FORMAT
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "getPastTime",
        defaultSignature: "getPastTime(interval, timeUnit, format?)",
        description: descriptions.TOKEN_FUNCTION_DATETIME_GETPASTTIME,
        signatures: [
          {
            definition: "getPastTime(interval: integer, timeUnit: string, format?: string)",
            documentation: descriptions.TOKEN_FUNCTION_DATETIME_GETPASTTIME,
            parameters: [
              {
                name: "interval",
                documentation: descriptions.TOKEN_PARAMETER_GETPASTTIME_INTERVAL
              },
              {
                name: "timeUnit",
                documentation: descriptions.TOKEN_PARAMETER_GETPASTTIME_TIMEUNIT
              },
              {
                name: "format",
                documentation: descriptions.TOKEN_PARAMETER_GETPASTTIME_FORMAT
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "addToTime",
        defaultSignature: "addToTime(timestamp, interval, timeUnit, format?)",
        description: descriptions.TOKEN_FUNCTION_DATETIME_ADDTOTIME,
        signatures: [
          {
            definition:
              "addToTime(timestamp: string, interval: integer, timeUnit: string, format?: string)",
            documentation: descriptions.TOKEN_FUNCTION_DATETIME_ADDTOTIME,
            parameters: [
              {
                name: "timestamp",
                documentation: descriptions.TOKEN_PARAMETER_ADDTOTIME_TIMESTAMP
              },
              {
                name: "interval",
                documentation: descriptions.TOKEN_PARAMETER_ADDTOTIME_INTERVAL
              },
              {
                name: "timeUnit",
                documentation: descriptions.TOKEN_PARAMETER_ADDTOTIME_TIMEUNIT
              },
              {
                name: "format",
                documentation: descriptions.TOKEN_PARAMETER_ADDTOTIME_FORMAT
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "subtractFromTime",
        defaultSignature: "subtractFromTime(timestamp, interval, timeUnit, format?)",
        description: descriptions.TOKEN_FUNCTION_DATETIME_SUBTRACTFROMTIME,
        signatures: [
          {
            definition:
              "subtractFromTime(timestamp: string, interval: integer, timeUnit: string, format?: string)",
            documentation: descriptions.TOKEN_FUNCTION_DATETIME_SUBTRACTFROMTIME,
            parameters: [
              {
                name: "timestamp",
                documentation: descriptions.TOKEN_PARAMETER_SUBTRACTFROMTIME_TIMESTAMP
              },
              {
                name: "interval",
                documentation: descriptions.TOKEN_PARAMETER_SUBTRACTFROMTIME_INTERVAL
              },
              {
                name: "timeUnit",
                documentation: descriptions.TOKEN_PARAMETER_SUBTRACTFROMTIME_TIMEUNIT
              },
              {
                name: "format",
                documentation: descriptions.TOKEN_PARAMETER_SUBTRACTFROMTIME_FORMAT
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "addSeconds",
        defaultSignature: "addSeconds(timestamp, seconds, format?)",
        description: descriptions.TOKEN_FUNCTION_DATETIME_ADDSECONDS,
        signatures: [
          {
            definition: "addSeconds(timestamp: string, seconds: integer, format?: string)",
            documentation: descriptions.TOKEN_FUNCTION_DATETIME_ADDSECONDS,
            parameters: [
              {
                name: "timestamp",
                documentation: descriptions.TOKEN_PARAMETER_ADDSECONDS_TIMESTAMP
              },
              {
                name: "seconds",
                documentation: descriptions.TOKEN_PARAMETER_ADDSECONDS_SECONDS
              },
              {
                name: "format",
                documentation: descriptions.TOKEN_PARAMETER_ADDSECONDS_FORMAT
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "addMinutes",
        defaultSignature: "addMinutes(timestamp, minutes, format?)",
        description: descriptions.TOKEN_FUNCTION_DATETIME_ADDMINUTES,
        signatures: [
          {
            definition: "addMinutes(timestamp: string, minutes: integer, format?: string)",
            documentation: descriptions.TOKEN_FUNCTION_DATETIME_ADDMINUTES,
            parameters: [
              {
                name: "timestamp",
                documentation: descriptions.TOKEN_PARAMETER_ADDMINUTES_TIMESTAMP
              },
              {
                name: "minutes",
                documentation: descriptions.TOKEN_PARAMETER_ADDMINUTES_MINUTES
              },
              {
                name: "format",
                documentation: descriptions.TOKEN_PARAMETER_ADDMINUTES_FORMAT
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "addHours",
        defaultSignature: "addHours(timestamp, hours, format?)",
        description: descriptions.TOKEN_FUNCTION_DATETIME_ADDHOURS,
        signatures: [
          {
            definition: "addHours(timestamp: string, hours: integer, format?: string)",
            documentation: descriptions.TOKEN_FUNCTION_DATETIME_ADDHOURS,
            parameters: [
              {
                name: "timestamp",
                documentation: descriptions.TOKEN_PARAMETER_ADDHOURS_TIMESTAMP
              },
              {
                name: "hours",
                documentation: descriptions.TOKEN_PARAMETER_ADDHOURS_HOURS
              },
              {
                name: "format",
                documentation: descriptions.TOKEN_PARAMETER_ADDHOURS_FORMAT
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "addDays",
        defaultSignature: "addDays(timestamp, days, format?)",
        description: descriptions.TOKEN_FUNCTION_DATETIME_ADDDAYS,
        signatures: [
          {
            definition: "addDays(timestamp: string, days: integer, format?: string)",
            documentation: descriptions.TOKEN_FUNCTION_DATETIME_ADDDAYS,
            parameters: [
              {
                name: "timestamp",
                documentation: descriptions.TOKEN_PARAMETER_ADDDAYS_TIMESTAMP
              },
              {
                name: "days",
                documentation: descriptions.TOKEN_PARAMETER_ADDDAYS_DAYS
              },
              {
                name: "format",
                documentation: descriptions.TOKEN_PARAMETER_ADDDAYS_FORMAT
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "convertTimeZone",
        defaultSignature:
          "convertTimeZone(timestamp, sourceTimeZone, destinationTimeZone, format?)",
        description: descriptions.TOKEN_FUNCTION_DATETIME_CONVERTTIMEZONE,
        signatures: [
          {
            definition:
              "convertTimeZone(timestamp: string, sourceTimeZone: string, destinationTimeZone: string, format?: string)",
            documentation: descriptions.TOKEN_FUNCTION_DATETIME_CONVERTTIMEZONE,
            parameters: [
              {
                name: "timestamp",
                documentation: descriptions.TOKEN_PARAMETER_CONVERTTIMEZONE_TIMESTAMP
              },
              {
                name: "sourceTimeZone",
                documentation: descriptions.TOKEN_PARAMETER_CONVERTTIMEZONE_SOURCETIMEZONE
              },
              {
                name: "destinationTimeZone",
                documentation: descriptions.TOKEN_PARAMETER_CONVERTTIMEZONE_DESTINATIONTIMEZONE
              },
              {
                name: "format",
                documentation: descriptions.TOKEN_PARAMETER_CONVERTTIMEZONE_FORMAT
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "convertToUtc",
        defaultSignature: "convertToUtc(timestamp, sourceTimeZone, format?)",
        description: descriptions.TOKEN_FUNCTION_DATETIME_CONVERTTOUTC,
        signatures: [
          {
            definition: "convertToUtc(timestamp: string, sourceTimeZone: string, format?: string)",
            documentation: descriptions.TOKEN_FUNCTION_DATETIME_CONVERTTOUTC,
            parameters: [
              {
                name: "timestamp",
                documentation: descriptions.TOKEN_PARAMETER_CONVERTTOUTC_TIMESTAMP
              },
              {
                name: "sourceTimeZone",
                documentation: descriptions.TOKEN_PARAMETER_CONVERTTOUTC_SOURCETIMEZONE
              },
              {
                name: "format",
                documentation: descriptions.TOKEN_PARAMETER_CONVERTTOUTC_FORMAT
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "convertFromUtc",
        defaultSignature: "convertFromUtc(timestamp, destinationTimeZone, format?)",
        description: descriptions.TOKEN_FUNCTION_DATETIME_CONVERTFROMUTC,
        signatures: [
          {
            definition:
              "convertFromUtc(timestamp: string, destinationTimeZone: string, format?: string)",
            documentation: descriptions.TOKEN_FUNCTION_DATETIME_CONVERTFROMUTC,
            parameters: [
              {
                name: "timestamp",
                documentation: descriptions.TOKEN_PARAMETER_CONVERTFROMUTC_TIMESTAMP
              },
              {
                name: "destinationTimeZone",
                documentation: descriptions.TOKEN_PARAMETER_CONVERTFROMUTC_DESTINATIONTIMEZONE
              },
              {
                name: "format",
                documentation: descriptions.TOKEN_PARAMETER_CONVERTFROMUTC_FORMAT
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "formatDateTime",
        defaultSignature: "formatDateTime(timestamp, format)",
        description: descriptions.TOKEN_FUNCTION_DATETIME_FORMATDATETIME,
        signatures: [
          {
            definition: "formatDateTime(timestamp: string, format?: string)",
            documentation: descriptions.TOKEN_FUNCTION_DATETIME_FORMATDATETIME,
            parameters: [
              {
                name: "timestamp",
                documentation: descriptions.TOKEN_PARAMETER_FORMATDATETIME_TIMESTAMP
              },
              {
                name: "format",
                documentation: descriptions.TOKEN_PARAMETER_FORMATDATETIME_FORMAT
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "startOfHour",
        defaultSignature: "startOfHour(timestamp, format)",
        description: descriptions.TOKEN_FUNCTION_DATETIME_STARTOFHOUR,
        signatures: [
          {
            definition: "startOfHour(timestamp: string, format?: string)",
            documentation: descriptions.TOKEN_FUNCTION_DATETIME_STARTOFHOUR,
            parameters: [
              {
                name: "timestamp",
                documentation: descriptions.TOKEN_PARAMETER_STARTOFHOUR_TIMESTAMP
              },
              {
                name: "format",
                documentation: descriptions.TOKEN_PARAMETER_STARTOFHOUR_FORMAT
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "startOfDay",
        defaultSignature: "startOfDay(timestamp, format)",
        description: descriptions.TOKEN_FUNCTION_DATETIME_STARTOFDAY,
        signatures: [
          {
            definition: "startOfDay(timestamp: string, format?: string)",
            documentation: descriptions.TOKEN_FUNCTION_DATETIME_STARTOFDAY,
            parameters: [
              {
                name: "timestamp",
                documentation: descriptions.TOKEN_PARAMETER_STARTOFDAY_TIMESTAMP
              },
              {
                name: "format",
                documentation: descriptions.TOKEN_PARAMETER_STARTOFDAY_FORMAT
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "startOfMonth",
        defaultSignature: "startOfMonth(timestamp, format)",
        description: descriptions.TOKEN_FUNCTION_DATETIME_STARTOFMONTH,
        signatures: [
          {
            definition: "startOfMonth(timestamp: string, format?: string)",
            documentation: descriptions.TOKEN_FUNCTION_DATETIME_STARTOFMONTH,
            parameters: [
              {
                name: "timestamp",
                documentation: descriptions.TOKEN_PARAMETER_STARTOFMONTH_TIMESTAMP
              },
              {
                name: "format",
                documentation: descriptions.TOKEN_PARAMETER_STARTOFMONTH_FORMAT
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "dayOfWeek",
        defaultSignature: "dayOfWeek(timestamp)",
        description: descriptions.TOKEN_FUNCTION_DATETIME_DAYOFWEEK,
        signatures: [
          {
            definition: "dayOfWeek(timestamp: string)",
            documentation: descriptions.TOKEN_FUNCTION_DATETIME_DAYOFWEEK,
            parameters: [
              {
                name: "timestamp",
                documentation: descriptions.TOKEN_PARAMETER_DAYOFWEEK_TIMESTAMP
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "dayOfMonth",
        defaultSignature: "dayOfMonth(timestamp)",
        description: descriptions.TOKEN_FUNCTION_DATETIME_DAYOFMONTH,
        signatures: [
          {
            definition: "dayOfMonth(timestamp: string)",
            documentation: descriptions.TOKEN_FUNCTION_DATETIME_DAYOFMONTH,
            parameters: [
              {
                name: "timestamp",
                documentation: descriptions.TOKEN_PARAMETER_DAYOFMONTH_TIMESTAMP
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "dayOfYear",
        defaultSignature: "dayOfYear(timestamp)",
        description: descriptions.TOKEN_FUNCTION_DATETIME_DAYOFYEAR,
        signatures: [
          {
            definition: "dayOfYear(timestamp: string)",
            documentation: descriptions.TOKEN_FUNCTION_DATETIME_DAYOFYEAR,
            parameters: [
              {
                name: "timestamp",
                documentation: descriptions.TOKEN_PARAMETER_DAYOFYEAR_TIMESTAMP
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "ticks",
        defaultSignature: "ticks(timestamp)",
        description: descriptions.TOKEN_FUNCTION_DATETIME_TICKS,
        signatures: [
          {
            definition: "ticks(timestamp: string)",
            documentation: descriptions.TOKEN_FUNCTION_DATETIME_TICKS,
            parameters: [
              {
                name: "timestamp",
                documentation: descriptions.TOKEN_PARAMETER_TICKS_TIMESTAMP
              }
            ]
          }
        ],
        isAdvanced: !0
      }
    ]
  },
  {
    id: "reference",
    name: descriptions.TOKEN_FUNCTION_SECTION_REFERENCE,
    functions: [
      {
        name: "parameters",
        defaultSignature: "parameters(parameterName)",
        description: descriptions.TOKEN_FUNCTION_REFERENCE_PARAMETERS,
        signatures: [
          {
            definition: "parameters(parameterName: string)",
            documentation: descriptions.TOKEN_FUNCTION_REFERENCE_PARAMETERS,
            parameters: [
              {
                name: "parameterName",
                documentation: descriptions.TOKEN_PARAMETER_PARAMETERS_PARAMETERNAME
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "result",
        defaultSignature: "result(scopedActionName?)",
        description: descriptions.TOKEN_FUNCTION_REFERENCE_RESULT,
        signatures: [
          {
            definition: "result(scopedActionName?: string)",
            documentation: descriptions.TOKEN_FUNCTION_REFERENCE_RESULT,
            parameters: [
              {
                name: "scopedActionName",
                documentation: descriptions.TOKEN_PARAMETER_RESULT_SCOPEDACTIONNAME,
                type: "string"
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "actions",
        defaultSignature: "actions(actionName)",
        description: descriptions.TOKEN_FUNCTION_REFERENCE_ACTIONS,
        signatures: [
          {
            definition: "actions(actionName: string)",
            documentation: descriptions.TOKEN_FUNCTION_REFERENCE_ACTIONS,
            parameters: [
              {
                name: "actionName",
                documentation: descriptions.TOKEN_PARAMETER_ACTIONS_ACTIONNAME
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "outputs",
        defaultSignature: "outputs(actionName)",
        description: descriptions.TOKEN_FUNCTION_REFERENCE_OUTPUTS,
        signatures: [
          {
            definition: "outputs(actionName: string)",
            documentation: descriptions.TOKEN_FUNCTION_REFERENCE_OUTPUTS,
            parameters: [
              {
                name: "actionName",
                documentation: descriptions.TOKEN_PARAMETER_ACTIONOUTPUTS_ACTIONNAME
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "body",
        defaultSignature: "body(actionName)",
        description: descriptions.TOKEN_FUNCTION_REFERENCE_BODY,
        signatures: [
          {
            definition: "body(actionName: string)",
            documentation: descriptions.TOKEN_FUNCTION_REFERENCE_BODY,
            parameters: [
              {
                name: "actionName",
                documentation: descriptions.TOKEN_PARAMETER_ACTIONBODY_ACTIONNAME
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "triggerOutputs",
        defaultSignature: "triggerOutputs()",
        description: descriptions.TOKEN_FUNCTION_REFERENCE_TRIGGEROUTPUTS,
        signatures: [
          {
            definition: "triggerOutputs()",
            documentation: descriptions.TOKEN_FUNCTION_REFERENCE_TRIGGEROUTPUTS,
            parameters: []
          }
        ],
        isAdvanced: !1
      },
      {
        name: "triggerBody",
        defaultSignature: "triggerBody()",
        description: descriptions.TOKEN_FUNCTION_REFERENCE_TRIGGERBODY,
        signatures: [
          {
            definition: "triggerBody()",
            documentation: descriptions.TOKEN_FUNCTION_REFERENCE_TRIGGERBODY,
            parameters: []
          }
        ],
        isAdvanced: !1
      },
      {
        name: "trigger",
        defaultSignature: "trigger()",
        description: descriptions.TOKEN_FUNCTION_REFERENCE_TRIGGER,
        signatures: [
          {
            definition: "trigger()",
            documentation: descriptions.TOKEN_FUNCTION_REFERENCE_TRIGGER,
            parameters: []
          }
        ],
        isAdvanced: !1
      },
      {
        name: "item",
        defaultSignature: "item()",
        description: descriptions.TOKEN_FUNCTION_REFERENCE_ITEM,
        signatures: [
          {
            definition: "item()",
            documentation: descriptions.TOKEN_FUNCTION_REFERENCE_ITEM,
            parameters: []
          }
        ],
        isAdvanced: !1
      },
      {
        name: "items",
        defaultSignature: "items(loopName)",
        description: descriptions.TOKEN_FUNCTION_REFERENCE_ITEMS,
        signatures: [
          {
            definition: "items(loopName: string)",
            documentation: descriptions.TOKEN_FUNCTION_REFERENCE_ITEMS,
            parameters: [
              {
                name: "loopName",
                documentation: descriptions.TOKEN_PARAMETER_ITEMS_LOOPNAME
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "iterationIndexes",
        defaultSignature: "iterationIndexes(loopName)",
        description: descriptions.TOKEN_FUNCTION_REFERENCE_ITERATIONINDEXES,
        signatures: [
          {
            definition: "iterationIndexes(loopName: string)",
            documentation: descriptions.TOKEN_FUNCTION_REFERENCE_ITERATIONINDEXES,
            parameters: [
              {
                name: "loopName",
                documentation: descriptions.TOKEN_PARAMETER_ITEMS_LOOPNAME
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "variables",
        defaultSignature: "variables(variableName)",
        description: descriptions.TOKEN_FUNCTION_REFERENCE_VARIABLES,
        signatures: [
          {
            definition: "variables(variableName: string)",
            documentation: descriptions.TOKEN_FUNCTION_REFERENCE_VARIABLES,
            parameters: [
              {
                name: "variableName",
                documentation: descriptions.TOKEN_PARAMETER_VARIABLES_VARIABLENAME
              }
            ]
          }
        ],
        isAdvanced: !1
      }
    ]
  },
  {
    id: "workflow",
    name: descriptions.TOKEN_FUNCTION_SECTION_WORKFLOW,
    functions: [
      {
        name: "listCallbackUrl",
        defaultSignature: "listCallbackUrl()",
        description: descriptions.TOKEN_FUNCTION_WORKFLOW_LISTCALLBACKURL,
        signatures: [
          {
            definition: "listCallbackUrl()",
            documentation: descriptions.TOKEN_FUNCTION_WORKFLOW_LISTCALLBACKURL_DETAIL,
            parameters: []
          }
        ],
        isAdvanced: !1
      },
      {
        name: "workflow",
        defaultSignature: "workflow()",
        description: descriptions.TOKEN_FUNCTION_WORKFLOW_WORKFLOW,
        signatures: [
          {
            definition: "workflow()",
            documentation: descriptions.TOKEN_FUNCTION_WORKFLOW_WORKFLOW,
            parameters: []
          }
        ],
        isAdvanced: !1
      }
    ]
  },
  {
    id: "uriParsing",
    name: descriptions.TOKEN_FUNCTION_SECTION_URI_PARSING,
    functions: [
      {
        name: "uriHost",
        defaultSignature: "uriHost(uri)",
        description: descriptions.TOKEN_FUNCTION_FUNCTION_URIHOST,
        signatures: [
          {
            definition: "uriHost(uri: string)",
            documentation: descriptions.TOKEN_FUNCTION_FUNCTION_URIHOST,
            parameters: [
              {
                name: "uri",
                documentation: descriptions.TOKEN_PARAMETER_URI_TEXT
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "uriPath",
        defaultSignature: "uriPath(uri)",
        description: descriptions.TOKEN_FUNCTION_FUNCTION_URIPATH,
        signatures: [
          {
            definition: "uriPath(uri: string)",
            documentation: descriptions.TOKEN_FUNCTION_FUNCTION_URIPATH,
            parameters: [
              {
                name: "uri",
                documentation: descriptions.TOKEN_PARAMETER_URI_TEXT
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "uriPathAndQuery",
        defaultSignature: "uriPathAndQuery(uri: string)",
        description: descriptions.TOKEN_FUNCTION_FUNCTION_URIPATHANDQUERY,
        signatures: [
          {
            definition: "uriPathAndQuery(uri: string)",
            documentation: descriptions.TOKEN_FUNCTION_FUNCTION_URIPATHANDQUERY,
            parameters: [
              {
                name: "uri",
                documentation: descriptions.TOKEN_PARAMETER_URI_TEXT
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "uriPort",
        defaultSignature: "uriPort(uri: string)",
        description: descriptions.TOKEN_FUNCTION_FUNCTION_URIPORT,
        signatures: [
          {
            definition: "uriPort(uri: string)",
            documentation: descriptions.TOKEN_FUNCTION_FUNCTION_URIPORT,
            parameters: [
              {
                name: "uri",
                documentation: descriptions.TOKEN_PARAMETER_URI_TEXT
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "uriScheme",
        defaultSignature: "uriScheme(uri: string)",
        description: descriptions.TOKEN_FUNCTION_FUNCTION_URISCHEME,
        signatures: [
          {
            definition: "uriScheme(uri: string)",
            documentation: descriptions.TOKEN_FUNCTION_FUNCTION_URISCHEME,
            parameters: [
              {
                name: "uri",
                documentation: descriptions.TOKEN_PARAMETER_URI_TEXT
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "uriQuery",
        defaultSignature: "uriQuery(uri: string)",
        description: descriptions.TOKEN_FUNCTION_FUNCTION_URIQUERY,
        signatures: [
          {
            definition: "uriQuery(uri: string)",
            documentation: descriptions.TOKEN_FUNCTION_FUNCTION_URIQUERY,
            parameters: [
              {
                name: "text",
                documentation: descriptions.TOKEN_PARAMETER_URI_TEXT
              }
            ]
          }
        ],
        isAdvanced: !1
      }
    ]
  },
  {
    id: "manipulation",
    name: descriptions.TOKEN_FUNCTION_SECTION_MANIPULATION,
    functions: [
      {
        name: "coalesce",
        defaultSignature: "coalesce(object_1, object_2?, ...)",
        description: descriptions.TOKEN_FUNCTION_MANIPULATION_COALESCE,
        signatures: [
          {
            definition: "coalesce(object_1: any)",
            documentation: descriptions.TOKEN_FUNCTION_MANIPULATION_COALESCE,
            parameters: [
              {
                name: "object_1",
                documentation: descriptions.TOKEN_PARAMETER_COALESCE_ALL,
                type: "any"
              }
            ]
          },
          {
            definition: "coalesce(object_1: any, ...)",
            documentation: descriptions.TOKEN_FUNCTION_MANIPULATION_COALESCE,
            parameters: [
              {
                name: "object_1",
                documentation: descriptions.TOKEN_PARAMETER_COALESCE_ALL,
                type: "any"
              },
              {
                name: "object",
                documentation: descriptions.TOKEN_PARAMETER_COALESCE_ALL,
                type: "any",
                isVariable: !0
              }
            ]
          }
        ],
        isAdvanced: !0
      },
      {
        name: "addProperty",
        defaultSignature: "addProperty(object, property, value)",
        description: descriptions.TOKEN_FUNCTION_MANIPULATION_ADDPROPERTY,
        signatures: [
          {
            definition: "addProperty(object: object, property: string, value: any)",
            documentation: descriptions.TOKEN_FUNCTION_MANIPULATION_ADDPROPERTY,
            parameters: [
              {
                name: "object",
                documentation: descriptions.TOKEN_PARAMETER_ADDPROPERTY_OBJECT,
                type: "object"
              },
              {
                name: "property",
                documentation: descriptions.TOKEN_PARAMETER_ADDPROPERTY_PROPERTY,
                type: "string"
              },
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_ADDPROPERTY_VALUE,
                type: "any"
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "setProperty",
        defaultSignature: "setProperty(object, property, value)",
        description: descriptions.TOKEN_FUNCTION_MANIPULATION_SETPROPERTY,
        signatures: [
          {
            definition: "setProperty(object: object, property: string, value: any)",
            documentation: descriptions.TOKEN_FUNCTION_MANIPULATION_SETPROPERTY,
            parameters: [
              {
                name: "object",
                documentation: descriptions.TOKEN_PARAMETER_SETPROPERTY_OBJECT,
                type: "object"
              },
              {
                name: "property",
                documentation: descriptions.TOKEN_PARAMETER_SETPROPERTY_PROPERTY,
                type: "string"
              },
              {
                name: "value",
                documentation: descriptions.TOKEN_PARAMETER_SETPROPERTY_VALUE,
                type: "any"
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "removeProperty",
        defaultSignature: "removeProperty(object, property)",
        description: descriptions.TOKEN_FUNCTION_MANIPULATION_REMOVEPROPERTY,
        signatures: [
          {
            definition: "removeProperty(object: object, property: string)",
            documentation: descriptions.TOKEN_FUNCTION_MANIPULATION_REMOVEPROPERTY,
            parameters: [
              {
                name: "object",
                documentation: descriptions.TOKEN_PARAMETER_REMOVEPROPERTY_OBJECT,
                type: "object"
              },
              {
                name: "property",
                documentation: descriptions.TOKEN_PARAMETER_REMOVEPROPERTY_PROPERTY,
                type: "string"
              }
            ]
          }
        ],
        isAdvanced: !1
      },
      {
        name: "xpath",
        defaultSignature: "xpath(xml, xpath)",
        description: descriptions.TOKEN_FUNCTION_MANIPULATION_XPATH,
        signatures: [
          {
            definition: "xpath(xml: any, xpath: any)",
            documentation: descriptions.TOKEN_FUNCTION_MANIPULATION_XPATH,
            parameters: [
              {
                name: "xml",
                documentation: descriptions.TOKEN_PARAMETER_XPATH_XML
              },
              {
                name: "xpath",
                documentation: descriptions.TOKEN_PARAMETER_XPATH_XPATH
              }
            ]
          }
        ],
        isAdvanced: !1
      }
    ]
  }
];
const ExpressionScannerExceptionName = "Workflow.ExpressionScannerException";
const ExpressionTokenType = {
  Dot: "Dot",
  Comma: "Comma",
  LeftParenthesis: "LeftParenthesis",
  RightParenthesis: "RightParenthesis",
  LeftSquareBracket: "LeftSquareBracket",
  RightSquareBracket: "RightSquareBracket",
  QuestionMark: "QuestionMark",
  StringLiteral: "StringLiteral",
  IntegerLiteral: "IntegerLiteral",
  FloatLiteral: "FloatLiteral",
  Identifier: "Identifier",
  EndOfData: "EndOfData"
};
const ExpressionScannerErrorCode = {
  LIMIT_EXCEEDED: "LimitExceeded",
  STRING_LITERAL_NOT_TERMINATED: "StringLiteralNotTerminated",
  TOKEN_NOT_FOUND: "TokenNotFound",
  UNEXPECTED_CHARACTER: "UnexpectedCharacter"
};

const esc = (function () {
  function e(e: any, t: any) {
    const that: any = this;
    that._currentToken = {
      type: ExpressionTokenType.EndOfData,
      endPosition: 0,
      startPosition: 0,
      value: ""
    };
    if (e.length > 8192)
      throw {
        message: format(descriptions.ERROR_EXPRESSION_SCANNER_LIMIT_EXCEEDED, 8192, e.length),
        type: ExpressionScannerErrorCode.LIMIT_EXCEEDED
      };
    (that._options = t || {}),
      (that._expression = e),
      (that._startPosition = 0),
      that._options.doNotPrefetch || that._moveNext();
  }

  return (
    Object.defineProperty(e.prototype, "expression", {
      get: function () {
        return this._expression;
      },
      enumerable: !1,
      configurable: !0
    }),
    (e.prototype.getToken = function (e: any) {
      var t = this.tryGetToken(e);
      if (!t)
        throw {
          message: format(descriptions.ERROR_EXPRESSION_SCANNER_TOKEN_NOT_FOUND, e),
          type: ExpressionScannerErrorCode.TOKEN_NOT_FOUND
        };
      return t;
    }),
    (e.prototype.getNextToken = function () {
      return this._moveNext(), this._currentToken;
    }),
    (e.prototype.tryGetToken = function (e: any) {
      if (this._currentToken.type === e) {
        var t = this._currentToken;
        return this._moveNext(), t;
      }
    }),
    (e.prototype.tryGetIdentifier = function (e: any) {
      if (
        this._currentToken.type === ExpressionTokenType.Identifier &&
        equals(e, this._currentToken.value)
      ) {
        var t = this._currentToken;
        return this._moveNext(), t;
      }
    }),
    (e.prototype.tryAcceptToken = function (e: any) {
      return void 0 !== this.tryGetToken(e);
    }),
    (e.prototype.tryAcceptIdentifier = function (e: any) {
      return (
        !(
          this._currentToken.type !== ExpressionTokenType.Identifier ||
          !equals(e, this._currentToken.value)
        ) && (this._moveNext(), !0)
      );
    }),
    (e.prototype._moveNext = function () {
      this._currentToken = this._readNext();
    }),
    (e.prototype._readNext = function () {
      for (
        var e = this, t = this._expression, r = this._startPosition, i = r;
        i < t.length && Parser.isWhitespace(t.charAt(i));

      )
        i++;
      var u = i;
      if (!(i < t.length))
        return (this._startPosition = i), this._createEndOfData(r, this._startPosition);
      switch (t.charAt(i)) {
        case ".":
          return (this._startPosition = i + 1), this._createDot(r, this._startPosition);
        case ",":
          return (this._startPosition = i + 1), this._createComma(r, this._startPosition);
        case "(":
          return (this._startPosition = i + 1), this._createLeftParenthesis(r, this._startPosition);
        case ")":
          return (
            (this._startPosition = i + 1), this._createRightParenthesis(r, this._startPosition)
          );
        case "[":
          return (
            (this._startPosition = i + 1), this._createLeftSquareBracket(r, this._startPosition)
          );
        case "]":
          return (
            (this._startPosition = i + 1), this._createRightSquareBracket(r, this._startPosition)
          );
        case "?":
          return (this._startPosition = i + 1), this._createQuestionMark(r, this._startPosition);
        case "'":
          for (
            ;
            i < t.length &&
            (i = this._scanForward(i + 1, function (e: any) {
              return "'" !== e;
            })) +
              1 <
              t.length &&
            "'" === t.charAt(i + 1);

          )
            i++;
          if (i >= t.length)
            throw {
              message: descriptions.ERROR_EXPRESSION_SCANNER_STRING_LITERAL_NOT_TERMINATED,
              type: ExpressionScannerErrorCode.STRING_LITERAL_NOT_TERMINATED
            };
          this._startPosition = i + 1;
          var d = t.substring(u + 1, i).replace(/''/g, "'");
          return this._createStringLiteral(d, r, this._startPosition);
        default:
          var l = t.charAt(i);
          if ("+" === l || "-" === l || this._isDigit(l)) {
            i = "+" === l || "-" === l ? i + 1 : i;
            var E = !1;
            if (
              ((i = this._scanForward(i, function (t: any) {
                return e._isDigit(t);
              })) < t.length &&
                "." === t.charAt(i) &&
                ((E = !0),
                (i = this._scanForward(i + 1, function (t: any) {
                  return e._isDigit(t);
                }))),
              i + 1 < t.length &&
                equals(t.charAt(i), "e") &&
                ((E = !0),
                (i = "+" === (l = t.charAt(i + 1)) || "-" === l ? i + 2 : i + 1),
                (i = this._scanForward(i, function (t: any) {
                  return e._isDigit(t);
                }))),
              i < t.length && this._isSupportedIdentifierCharacter(t.charAt(i)))
            )
              throw {
                message: format(
                  descriptions.ERROR_EXPRESSION_SCANNER_UNEXPECTED_CHARACTER,
                  t.charAt(i)
                ),
                type: ExpressionScannerErrorCode.UNEXPECTED_CHARACTER
              };
            this._startPosition = i;
            var p = t.substring(u, i);
            return E
              ? this._createFloatLiteral(p, r, this._startPosition)
              : this._createIntegerLiteral(p, r, this._startPosition);
          }
          if (this._isSupportedIdentifierCharacter(l)) {
            this._startPosition = this._scanForward(i, function (t: any) {
              return e._isSupportedIdentifierCharacter(t);
            });
            p = t.substring(u, this._startPosition);
            return this._createIdentifier(p, r, this._startPosition);
          }
          throw {
            message: format(descriptions.ERROR_EXPRESSION_SCANNER_UNEXPECTED_CHARACTER, l),
            type: ExpressionScannerErrorCode.UNEXPECTED_CHARACTER
          };
      }
    }),
    (e.prototype._scanForward = function (e: any, t: any) {
      for (var r = this._expression; e < r.length && t(r.charAt(e)); ) e++;
      return e;
    }),
    (e.prototype._isSupportedIdentifierCharacter = function (e: string) {
      if ("$" === e || "_" === e || this._isDigit(e)) return !0;
      if (Parser.isWhitespace(e)) return !1;
      switch (e) {
        case ".":
        case ",":
        case "(":
        case ")":
        case "[":
        case "]":
        case "?":
        case "'":
        case "{":
        case "}":
        case "@":
          return !1;
        default:
          return !0;
      }
    }),
    (e.prototype._isDigit = function (e: string) {
      switch (e) {
        case "0":
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
        case "7":
        case "8":
        case "9":
          return !0;
        default:
          return !1;
      }
    }),
    (e.prototype._createEndOfData = function (e: any, t: any) {
      return {
        type: ExpressionTokenType.EndOfData,
        value: "",
        startPosition: e,
        endPosition: t
      };
    }),
    (e.prototype._createDot = function (e: any, t: any) {
      return {
        type: ExpressionTokenType.Dot,
        value: ".",
        startPosition: e,
        endPosition: t
      };
    }),
    (e.prototype._createComma = function (e: any, t: any) {
      return {
        type: ExpressionTokenType.Comma,
        value: ",",
        startPosition: e,
        endPosition: t
      };
    }),
    (e.prototype._createLeftParenthesis = function (e: any, t: any) {
      return {
        type: ExpressionTokenType.LeftParenthesis,
        value: "(",
        startPosition: e,
        endPosition: t
      };
    }),
    (e.prototype._createRightParenthesis = function (e: any, t: any) {
      return {
        type: ExpressionTokenType.RightParenthesis,
        value: ")",
        startPosition: e,
        endPosition: t
      };
    }),
    (e.prototype._createLeftSquareBracket = function (e: any, t: any) {
      return {
        type: ExpressionTokenType.LeftSquareBracket,
        value: "[",
        startPosition: e,
        endPosition: t
      };
    }),
    (e.prototype._createRightSquareBracket = function (e: any, t: any) {
      return {
        type: ExpressionTokenType.RightSquareBracket,
        value: "]",
        startPosition: e,
        endPosition: t
      };
    }),
    (e.prototype._createQuestionMark = function (e: any, t: any) {
      return {
        type: ExpressionTokenType.QuestionMark,
        value: "?",
        startPosition: e,
        endPosition: t
      };
    }),
    (e.prototype._createIntegerLiteral = function (e: any, t: any, r: any) {
      return {
        type: ExpressionTokenType.IntegerLiteral,
        value: e,
        startPosition: t,
        endPosition: r
      };
    }),
    (e.prototype._createFloatLiteral = function (e: any, t: any, r: any) {
      return {
        type: ExpressionTokenType.FloatLiteral,
        value: e,
        startPosition: t,
        endPosition: r
      };
    }),
    (e.prototype._createStringLiteral = function (e: any, t: any, r: any) {
      return {
        type: ExpressionTokenType.StringLiteral,
        value: e,
        startPosition: t,
        endPosition: r
      };
    }),
    (e.prototype._createIdentifier = function (e: any, t: any, r: any) {
      return {
        type: ExpressionTokenType.Identifier,
        value: e,
        startPosition: t,
        endPosition: r
      };
    }),
    e
  );
})();
const ExpressionScanner = esc;

// function(e, t, r) {
//         var n, i;
//         n = [r, t, r(0), r(35), r(4), r(1), r(207), r(399), r(155), r(74)],
//         void 0 === (i = function(e, t, r, n, i, o, a, s, u, c) {
//             "use strict";
//             Object.defineProperty(t, "__esModule", {
//                 value: !0
//             }),
//             t.IntellisenseControl = void 0;
//             var d = function(e) {
//                 function t(t) {
//                     var r = e.call(this, t) || this;
//                     return r._onBlurEditorWidget = function() {
//                         var e = r.props.onBlur;
//                         if (e) {
//                             var t = r._editor.getSelection()
//                               , n = r._editor.getPosition().column - 1
//                               , i = t.startLineNumber
//                               , o = t.startColumn
//                               , a = t.endLineNumber
//                               , s = t.endColumn
//                               , u = i === a
//                               , c = u ? o - 1 : n
//                               , d = u ? s - 1 : n;
//                             e({
//                                 currentTarget: r,
//                                 value: r._editor.getValue(),
//                                 selectionStart: c,
//                                 selectionEnd: d
//                             })
//                         }
//                         r.setState({
//                             focused: !1
//                         })
//                     }
//                     ,
//                     r._onFocusEditorWidget = function() {
//                         r.setState({
//                             focused: !0
//                         })
//                     }
//                     ,
//                     r._handleChangeEvent = function(e) {
//                         if ("\r\n" === (e.changes.length ? e.changes[0].text : "")) {
//                             var t = r._editor.getPosition()
//                               , n = r._editor.getValue().replace(/\r\n/g, "");
//                             r._editor.setValue(n);
//                             var i = t.column - 1;
//                             setTimeout((function() {
//                                 return r.setSelection(i, i)
//                             }
//                             ))
//                         }
//                     }
//                     ,
//                     r.state = {},
//                     r
//                 }
//                 return r.__extends(t, e),
//                 t.prototype.componentDidMount = function() {
//                     var e = this;
//                     u.requireConfig(["vs/editor/editor.main"], (function() {
//                         var r = e.props.initialValue;
//                         o.getPropertyValue(t._registeredLanguages, a.LANGUAGE_NAMES.WORKFLOW) || (s.registerWorkflowLanguageProviders(),
//                         t._registeredLanguages[a.LANGUAGE_NAMES.WORKFLOW] = !0);
//                         var n = c.isHighContrastBlackOrInverted();
//                         e._editor = monaco.editor.create(e._editorElement, {
//                             language: a.LANGUAGE_NAMES.WORKFLOW,
//                             folding: !1,
//                             lineNumbers: "off",
//                             scrollbar: {
//                                 horizontal: "hidden",
//                                 vertical: "hidden"
//                             },
//                             minimap: {
//                                 enabled: !1
//                             },
//                             overviewRulerLanes: 0,
//                             overviewRulerBorder: !1,
//                             contextmenu: !1,
//                             theme: n ? "vs-dark" : "vs"
//                         }),
//                         e._editor.layout(),
//                         e._editor.setValue(r),
//                         e._editor.onDidFocusEditorWidget(e._onFocusEditorWidget),
//                         e._editor.onDidBlurEditorWidget(e._onBlurEditorWidget),
//                         e._editor.onDidChangeModelContent(e._handleChangeEvent),
//                         e._editor.focus()
//                     }
//                     ))
//                 }
//                 ,
//                 t.prototype.componentWillUnmount = function() {
//                     this._editor && (this._editor.dispose(),
//                     this._editor = void 0)
//                 }
//                 ,
//                 t.prototype.focus = function() {
//                     this._editor && this._editor.focus()
//                 }
//                 ,
//                 t.prototype.setSelection = function(e, t) {
//                     this._editor && (this._editor.focus(),
//                     void 0 !== e && void 0 !== t && this._editor.setSelection({
//                         startLineNumber: 1,
//                         startColumn: e + 1,
//                         endLineNumber: 1,
//                         endColumn: t + 1
//                     }))
//                 }
//                 ,
//                 t.prototype.setValue = function(e) {
//                     this._editor && this._editor.setValue(e)
//                 }
//                 ,
//                 t.prototype.render = function() {
//                     var e = this
//                       , t = this.state.focused ? "msla-intellisense-editor msla-focused" : "msla-intellisense-editor";
//                     return i.createElement("div", {
//                         className: t
//                     }, i.createElement("div", {
//                         ref: function(t) {
//                             return e._editorElement = t
//                         }
//                     }))
//                 }
//                 ,
//                 t._registeredLanguages = {},
//                 t
//             }(i.Component);
//             t.IntellisenseControl = d,
//             n.registerOnThemeChangeCallback((function() {
//                 if ("monaco"in window) {
//                     var e = c.isHighContrastBlackOrInverted();
//                     monaco.editor.setTheme(e ? "vs-dark" : "vs")
//                 }
//             }
//             ))
//         }
//         .apply(t, n)) || (e.exports = i)
//     }

//t.createSignatureHelpProvider = t.createCompletionItemProviderForValues = t.createCompletionItemProviderForFunctions = t.createThemeData = t.registerWorkflowLanguageProviders = void 0;
var s = "function-name",
  u = "keywords",
  c = "number-literal",
  d = "string-literal",
  l = ["null", "true", "false"];
function createCompletionItemProviderForFunctions(e: any) {
  return {
    triggerCharacters: ["."],
    provideCompletionItems: function () {
      return {
        suggestions: e.map(function (e: any) {
          var t = e.name,
            r = e.description,
            n = e.signatures.every(function (e: any) {
              return 0 === e.parameters.length;
            });
          return {
            label: t,
            kind: monaco.languages.CompletionItemKind.Function,
            insertText: n ? t + "()" : t,
            documentation: r,
            range: void 0
          };
        })
      };
    }
  };
}
function createCompletionItemProviderForValues() {
  return {
    provideCompletionItems: function () {
      return {
        suggestions: l.map(function (e) {
          return {
            label: e,
            kind: monaco.languages.CompletionItemKind.Value,
            insertText: e,
            range: void 0
          };
        })
      };
    }
  };
}
function createSignatureHelpProvider(e: any) {
  return {
    signatureHelpTriggerCharacters: [",", "("],
    provideSignatureHelp: function (t: any, i: any) {
      var a = (function (e, t, r) {
        var i,
          a,
          s: any = new (ExpressionScanner as any)(e, {
            doNotPrefetch: !0
          }),
          u: any[] = [];
        try {
          for (i = a = s.getNextToken(); null !== a && a.type !== ExpressionTokenType.EndOfData; ) {
            var c = a.type,
              d = a.startPosition,
              l = a.endPosition;
            if (t >= d && t < l) break;
            c === ExpressionTokenType.LeftParenthesis
              ? i.type === ExpressionTokenType.Identifier &&
                u.push({
                  name: i.value,
                  argumentsCovered: 0
                })
              : c === ExpressionTokenType.Comma
              ? (u[u.length - 1].argumentsCovered += 1)
              : c === ExpressionTokenType.RightParenthesis && u.pop();
            i = a;
            a = s.getNextToken();
          }
        } catch (e) {
          console.log("-" + e);
        }
        if (!u.length) return null;
        var E =
            a.type === ExpressionTokenType.RightParenthesis &&
            i.type === ExpressionTokenType.LeftParenthesis,
          p = u.pop(),
          T = p.name,
          _ = p.argumentsCovered,
          I = getPropertyValue(r, T);
        if (!I) return null;
        return {
          templateFunction: I,
          argumentsCovered: _,
          hasEmptyArgument: E
        };
      })(t.getValue(), i.column - 1, e);

      return a
        ? (function (e) {
            for (
              var t,
                n,
                i = e.templateFunction,
                a = e.argumentsCovered,
                s = e.hasEmptyArgument,
                u = i.signatures.filter(function (e: any) {
                  return !_(e);
                }),
                c = i.signatures.filter(_),
                d = function (e: any) {
                  return {
                    label: e.definition,
                    documentation: e.documentation,
                    parameters: e.parameters.map(function (e: any) {
                      return {
                        label: e.name,
                        documentation: e.documentation
                      };
                    })
                  };
                },
                l = i.signatures.map(d),
                E = 0;
              E < u.length;
              E++
            ) {
              var p = u[E].parameters.length;
              if ((s && 0 === p) || (p > 0 && p > a)) {
                t = E;
                n = a;
                break;
              }
            }
            if (void 0 !== t && void 0 !== n)
              return {
                dispose: function () {},
                value: {
                  signatures: l,
                  activeSignature: t,
                  activeParameter: n
                }
              };
            if (1 === c.length) {
              var T = u.map(d),
                I = (function (e, t, n) {
                  for (
                    var i = n + 1,
                      a = t.parameters.filter(function (e: any) {
                        return !e.isVariable;
                      }),
                      s = first(function (e: any) {
                        return e.isVariable;
                      }, t.parameters),
                      u = s.name,
                      c = s.documentation,
                      d = s.type,
                      l = a.map(function (e: any) {
                        return {
                          label: e.name,
                          documentation: e.documentation
                        };
                      }),
                      E = [],
                      p =
                        e +
                        "(" +
                        a
                          .map(function (e: any) {
                            return e.name + ": " + e.type;
                          })
                          .join(", "),
                      T = l.length + 1,
                      _ = T;
                    _ <= i;
                    _++
                  ) {
                    for (var I = p + ", ", N = [], A = T; A <= _; A++) {
                      var O = u + "_" + A;
                      I += O + ": " + d;
                      I += A !== _ ? ", " : ")";
                      N.push({
                        label: O,
                        documentation: c
                      });
                    }
                    E.push({
                      label: I,
                      documentation: t.documentation,
                      parameters: __spreadArrays(l, N)
                    });
                  }
                  return E;
                })(i.name, c[0], a),
                N = __spreadArrays(T, I);
              return {
                dispose: function () {},
                value: {
                  signatures: N,
                  activeSignature: N.length - 1,
                  activeParameter: a
                }
              };
            }
            return null;
          })(a)
        : null;
    }
  };
}
function _(e: any) {
  return e.parameters.some(function (e: any) {
    return !!e.isVariable;
  });
}
// Helper function to return the monaco completion item type of a thing
function getType(thing: any, isMember: any) {
  isMember = isMember === undefined ? (typeof isMember == "boolean" ? isMember : false) : false; // Give isMember a default value of false

  switch ((typeof thing).toLowerCase()) {
    case "object":
      return monaco.languages.CompletionItemKind.Class;

    case "function":
      return isMember
        ? monaco.languages.CompletionItemKind.Method
        : monaco.languages.CompletionItemKind.Function;

    default:
      return isMember
        ? monaco.languages.CompletionItemKind.Property
        : monaco.languages.CompletionItemKind.Variable;
  }
}
export const languageId = "TemplateExpressionLanguage";
export const registerWorkflowLanguageProviders = function (
  monaco: Monaco,
  objRef: React.MutableRefObject<any>
) {
  if (monaco.languages.getLanguages().findIndex((lang) => lang.id === languageId) >= 0) {
    return;
  }

  var e = languageId,
    t = (function () {
      for (var e: any[] = [], t = 0, r = FunctionGroupDefinitions; t < r.length; t++) {
        var n = r[t];
        e.push.apply(e, n.functions);
      }
      return e;
    })();
  monaco.languages.register({
    id: e
  });
  monaco.languages.registerCompletionItemProvider(e, createCompletionItemProviderForFunctions(t));
  monaco.languages.registerCompletionItemProvider(
    e,
    createCompletionItemProviderForValues() as any
  );
  // Register object that will return autocomplete items
  monaco.languages.registerCompletionItemProvider(languageId, {
    // Run this function when the period or open parenthesis is typed (and anything after a space)
    triggerCharacters: [".", "("],
    // Function to generate autocompletion results
    provideCompletionItems: function (model: any, position: any, context: any, token: any) {
      // Split everything the user has typed on the current line up at each space, and only look at the last word
      var last_chars = model.getValueInRange({
        startLineNumber: position.lineNumber,
        startColumn: 0,
        endLineNumber: position.lineNumber,
        endColumn: position.column
      });
      var words = last_chars.replace("\t", "").split(" ");
      var active_typing = words[words.length - 1]; // What the user is currently typing (everything after the last space)

      // If the last character typed is a period then we need to look at member objects of the obj object
      var is_member = active_typing.charAt(active_typing.length - 1) === ".";

      // Array of autocompletion results
      var result: any[] = [];

      // Used for generic handling between member and non-member objects
      var last_token = objRef.current;
      var prefix = "";

      if (is_member) {
        // Is a member, get a list of all members, and the prefix
        var parents = active_typing.substring(0, active_typing.length - 1).split(".");
        last_token = objRef.current[parents[0]];
        prefix = parents[0];

        // Loop through all the parents the current one will have (to generate prefix)
        for (var i = 1; i < parents.length; i++) {
          if (last_token.hasOwnProperty(parents[i])) {
            prefix += "." + parents[i];
            last_token = last_token[parents[i]];
          } else {
            // Not valid
            return result;
          }
        }

        prefix += ".";
      }

      // Get all the child properties of the last token
      for (var prop in last_token) {
        // Do not show properites that begin with "__"
        if (last_token.hasOwnProperty(prop) && !prop.startsWith("__")) {
          // Get the detail type (try-catch) incase object does not have prototype
          var details = "";
          try {
            details = last_token[prop].__proto__.constructor.name;
          } catch (e) {
            details = typeof last_token[prop];
          }

          // Create completion object
          var to_push: any = {
            label: prefix + prop,
            kind: getType(last_token[prop], is_member),
            detail: details,
            insertText: prop
          };

          // Change insertText and documentation for functions
          if (to_push.detail.toLowerCase() === "function") {
            to_push.insertText += "(";
            to_push.documentation = last_token[prop].toString().split("{")[0]; // Show function prototype in the documentation popup
          }

          // Add to final results
          result.push(to_push);
        }
      }

      return {
        suggestions: result
      };
    } as any
  });
  monaco.languages.registerSignatureHelpProvider(e, createSignatureHelpProvider(map(t, "name")));
  monaco.languages.setMonarchTokensProvider(
    e,
    (function (e) {
      var t = l.map(function (e) {
          return {
            regex: e,
            action: {
              token: u
            }
          };
        }),
        n = e.map(function (e) {
          return {
            regex: e.name,
            action: {
              token: s
            }
          };
        }),
        i = {
          regex: /'[^']*'/g,
          action: {
            token: d
          }
        },
        o = [
          {
            regex: /\d*\.\d+([eE][\-+]?\d+)?/,
            action: {
              token: c
            }
          },
          {
            regex: /\d+/,
            action: {
              token: c
            }
          }
        ];
      return {
        tokenizer: {
          root: __spreadArrays(t, n, o, [i])
        },
        tokenPostfix: "",
        ignoreCase: !0
      };
    })(t)
  );
  monaco.languages.setLanguageConfiguration(e, {
    autoClosingPairs: [
      {
        open: "(",
        close: ")"
      },
      {
        open: "[",
        close: "]"
      },
      {
        open: "'",
        close: "'"
      }
    ]
  });
};

export const createThemeData = function () {
  return {
    base: "vs",
    inherit: !0,
    rules: [
      {
        token: s,
        foreground: "110188",
        fontStyle: "bold"
      },
      {
        token: d,
        foreground: "a31515"
      },
      {
        token: c,
        foreground: "09885a"
      },
      {
        token: u,
        foreground: "0000ff"
      }
    ],
    colors: {}
  };
};
