import { Spinner, Stack, StackItem } from "@fluentui/react";
import { PrimaryButton } from "@fluentui/react/lib/components/Button";
import React, { useState } from "react";
import { useApiClient } from "@workpoint/components/lib/clients/ApiProvider";
import { useAppDispatch } from "../../../../store/hooks";
import { resetSettings } from "../notificationSettingsReducer";

export interface INotificationEnableProps {
  title: string;
  actionTitle: string;
  description: string;
  provisioningDescription: string;
}

export const NotificationEnable: React.FC<INotificationEnableProps> = ({
  title,
  description,
  actionTitle,
  provisioningDescription
}) => {
  const dispatch = useAppDispatch();
  const [provisioning, setProvisioning] = useState<boolean>(false);

  const { apiClient } = useApiClient();

  async function provision() {
    setProvisioning(true);
    try {
      await apiClient.putWP(`/Notifications/Initialize`);
    } catch (error) {
      console.warn("Something went wrong.", error);
      return error;
    }
    setProvisioning(false);
    dispatch(resetSettings());
  }

  return (
    <div>
      <h4>{title}</h4>
      <p style={{ whiteSpace: "pre-wrap" }}>
        {!provisioning && description}
        {provisioning && provisioningDescription}
      </p>
      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <StackItem>
          <PrimaryButton onClick={provision} disabled={provisioning}>
            {actionTitle}
          </PrimaryButton>
        </StackItem>
        {provisioning && (
          <StackItem align="center">
            <Spinner />
          </StackItem>
        )}
      </Stack>
    </div>
  );
};
