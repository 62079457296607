import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { Toggle, IToggleProps } from "@fluentui/react";
import { FormControlProps } from "./ControlledTextField";

export const ControlledBoolean: FC<FormControlProps & IToggleProps> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || ""}
      render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => (
        <Toggle
          {...props}
          // disabled={readonly}
          onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
            onChange(!value);
            if (props.onChange) props.onChange(event, checked);
          }}
          onBlur={onBlur}
          defaultChecked={props.defaultValue}
          checked={value}
          onText={props.onText}
          offText={props.offText}
        />
      )}
    />
  );
};
