import React from "react";
import {
  CommandBarButton,
  ContextualMenuItemType,
  IContextualMenuItem,
  Stack,
  Text
} from "@fluentui/react";
import {
  ProcessValidationMessage,
  ValidationMessageType
} from "../../../../models/ProcessValidation";
import styled from "styled-components";
import { useAppSelector } from "../../../../store/hooks";
import { processSelector } from "../../../../store/processReducer";
import { ToRoute } from "../../../../routes";
import { useNavigate } from "react-router";
import { RowType } from "../../../../utils/processUtils";
import { isObjectEqual } from "../../../../utils/commonUtils";

interface ErrorDropdownProps {
  validationMessages: ProcessValidationMessage[];
}

const errorColor = "#a4262c";
const warningColor = "#FFBF00";

export const ErrorDropdown = ({ validationMessages }: ErrorDropdownProps) => {
  const errors = validationMessages.filter((msg) => msg.type === ValidationMessageType.Error);
  const warnings = validationMessages.filter((msg) => msg.type === ValidationMessageType.Warning);
  const navigate = useNavigate();
  const { process } = useAppSelector(processSelector, (a, b) =>
    isObjectEqual(a.validationMessages, b.validationMessages)
  );

  const items: IContextualMenuItem[] = [];

  if (errors.length > 0) {
    items.push({
      key: "errors",
      text: "Errors",
      itemType: ContextualMenuItemType.Header,
      style: { color: errorColor, fontSize: "15px" }
    });
    let keyNum = 1;
    errors.forEach((error) =>
      items.push({
        key: `errorNum${keyNum++}`,
        text: error.message,
        onClick: () => {
          switch (error.rowType) {
            case RowType.Step:
              navigate(
                ToRoute.processStep(
                  process,
                  process.configuration.steps
                    .findIndex((step) => step.id === error.stepId)
                    .toString()
                )
              );
              break;
            case RowType.Trigger:
              navigate(
                ToRoute.processTrigger(
                  process,
                  process.configuration
                    .triggers!.findIndex((trigger) => trigger.id === error.stepId)
                    .toString()
                )
              );
              break;
            default:
              console.log("Error: Row type not handled");
          }
        }
      })
    );
  }

  if (errors.length > 0 && warnings.length > 0) {
    items.push({ key: "divider", itemType: ContextualMenuItemType.Divider });
  }

  if (warnings.length > 0) {
    items.push({
      key: "warnings",
      text: "Warnings",
      itemType: ContextualMenuItemType.Header,
      style: { color: warningColor, fontSize: "15px" }
    });
    let keyNum = 1;
    warnings.forEach((warning) =>
      items.push({
        key: `warningNum${keyNum++}`,
        text: warning.message,
        onClick: () =>
          navigate(
            ToRoute.processStep(
              process,
              process.configuration.steps.findIndex((step) => step.id === warning.stepId).toString()
            )
          )
      })
    );
  }

  return (
    <>
      {validationMessages.length === 0 ? (
        <Text style={{ color: "rgba(0, 0, 0, 0.54)" }}>0 Errors / 0 Warnings</Text>
      ) : (
        <CommandBarButton
          style={{
            height: "100%"
          }}
          iconProps={{
            style: {
              color: errors.length > 0 ? errorColor : warningColor
            },
            iconName: validationMessages.length > 0 ? "StatusErrorFull" : ""
          }}
          menuProps={{
            items: items
          }}
        >
          <Label errors={errors} warnings={warnings} />
        </CommandBarButton>
      )}
    </>
  );
};

const Label = ({
  errors,
  warnings
}: {
  errors: ProcessValidationMessage[];
  warnings: ProcessValidationMessage[];
}) => (
  <Stack horizontal gap="5px">
    {errors.length > 0 && (
      <ErrorText>
        {errors.length} Error{errors.length > 1 ? "s" : ""}
      </ErrorText>
    )}
    {errors.length > 0 && warnings.length > 0 && <span>/</span>}
    {warnings.length > 0 && (
      <WarningText>
        {warnings.length} Warning{warnings.length > 1 ? "s" : ""}
      </WarningText>
    )}
  </Stack>
);

const ErrorText = styled.span`
  color: ${errorColor};
`;

const WarningText = styled.span`
  color: ${warningColor};
`;
