import { DefaultButton, IButtonProps, IButtonStyles, Icon } from "@fluentui/react";
import { useState } from "react";

export const EditProfileButton = (props: IButtonProps) => {
  const [isShown, setIsShown] = useState(false);

  const styles: IButtonStyles = {
    root: {
      backgroundColor: "transparent",
      color: "black",
      fontWeight: "bold",
      border: "none"
    }
  };

  return (
    <DefaultButton
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      styles={styles}
      {...props}
      onRenderText={(props) => iconRendering(props, isShown)}
    />
  );
};
const iconRendering = (props: any, textShown: boolean) => {
  return (
    <div style={{ textAlign: "left" }}>
      <Icon iconName="Edit" style={{ color: "orange" }} />
      {textShown && "     " + props.text}
    </div>
  );
};
