import { ITag, SearchBox, Link } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { ControlledTagPicker } from "../form/ControlledTagPicker";
import { ParameterPropertyControlParams } from "./ParameterPropertyControl";
import theme from "@workpoint/components/lib/constants";
import { useWatch } from "react-hook-form";
import styled from "styled-components";
import { useAppSelector } from "../../../store/hooks";
import { globalSelector } from "../../../store/globalReducer";
import { ApiClient } from "@workpoint/components/lib/clients/ApiClient";
import { useApiClient } from "@workpoint/components/lib/clients/ApiProvider";
import { ContextBrowser } from "../ContextBrowser";
import { PrincipalType, UserPrincipalType } from "@workpoint/components/lib/models/DataRequests";

interface ControlledValuePickerProps extends ParameterPropertyControlParams {
  contextExtension?: any;
}

export const ControlledValuePicker = (props: ControlledValuePickerProps) => {
  const {
    name,
    control,
    label,
    setValue,
    required,
    defaultValue,
    disabled,
    definition,
    hideContextBrowser,
    contextExtension
  } = props;
  const { context, loadingContext } = useAppSelector(globalSelector);
  const { apiClient } = useApiClient();
  const controlValue: any = useWatch({ control, name });
  const fieldType = definition["x-workpoint-field"]?.type ?? definition.dataType;
  const allowMulti =
    props.definition["x-workpoint-multi"] === true ||
    props.definition.type === "array" ||
    fieldType === "LookupMulti" ||
    fieldType === "TaxonomyFieldTypeMulti" ||
    fieldType === "UserMulti" ||
    fieldType === "MultiChoice";
  let options = props.options;
  if (definition.type === "boolean") {
    options = [
      { key: "true", text: "Yes" },
      { key: "false", text: "No" }
    ];
  }

  return (
    <ControlledTagPicker
      {...props}
      name={name}
      label={label}
      control={control}
      required={required}
      defaultValue={defaultValue}
      // rules={rules}
      disabled={disabled}
      onResolveSuggestions={(filter: string, selectedItems?: ITag[]) => {
        const resolvedValue: ITag[] = [];
        if (filter) {
          if (fieldType === "DateTime") {
            try {
              const date = new Date(filter).toISOString();
              resolvedValue.push({ key: `'${date}'`, name: date });
            } catch {}
          } else if (
            !fieldType ||
            fieldType === "Text" ||
            fieldType === "Note" ||
            fieldType === "Number" ||
            fieldType === "Choice" ||
            fieldType === "MultiChoice" ||
            fieldType === "Currency" ||
            fieldType === "URL" ||
            fieldType === "Integer" ||
            fieldType === "File"
          ) {
            resolvedValue.push({ key: `'${filter}'`, name: filter });
          } else if (fieldType === "Context") {
            resolvedValue.push({ key: filter, name: filter });
          }
        }

        return resolvedValue.length > 0 &&
          selectedItems?.some((i) => i.key === resolvedValue[0].key)
          ? []
          : resolvedValue;
      }}
      onEmptyResolveSuggestions={(selectedItems?: ITag[]) => {
        return [];
      }}
      onLoadValue={(value: any) => {
        if (value) {
          if (Array.isArray(value)) {
            if (definition["x-ms-dynamic-values"] && !options) {
              if (typeof value[0] === "string") {
                return value.map((v) => {
                  return { key: v, name: v };
                });
              } else {
                return value;
              }
            } else {
              return value.map((arrayItem) => {
                if (arrayItem?.key) {
                  return arrayItem;
                } else if (typeof arrayItem === "object") {
                  const trimmedName = trimText(arrayItem.name, "'");
                  const option = options?.find((o) => o.key === trimmedName);
                  return option
                    ? { key: option.key, name: option.text }
                    : { key: trimmedName, name: trimmedName };
                } else {
                  const option = options?.find((o) => o.key === arrayItem);
                  return option
                    ? { key: option.key, name: option.text }
                    : { key: arrayItem, name: trimText(arrayItem, "'") };
                }
              });
            }
          } else if (typeof value === "object") {
            return [value];
          } else if (options !== undefined) {
            const option = options.find((o) => o.key === value || o.key === `'${value}'`);
            return option
              ? [{ key: option.key, name: option.text }]
              : [{ key: value, name: trimText(value, "'") }];
          } else {
            return [{ key: value, name: trimText(value, "'") }];
          }
        }

        return [];
      }}
      onResolveChangedValue={(items?: ITag[]) => {
        if (items?.length) {
          if (
            fieldType === "Lookup" ||
            fieldType === "LookupMulti" ||
            fieldType === "TaxonomyFieldType" ||
            fieldType === "TaxonomyFieldTypeMulti" ||
            fieldType === "User" ||
            fieldType === "UserMulti"
          ) {
            return allowMulti ? items : items[items.length - 1];
          } else if (allowMulti) {
            return items.map((item) => item.key);
          } else {
            return items[items.length - 1].key;
          }
        }

        return "";
      }}
      styles={{ input: { backgroundColor: theme.palette.white } }}
      pickerSuggestionsProps={{
        suggestionsHeaderText: "",
        noResultsFoundText: "",
        onRenderNoResultFound: () => {
          const isLookupType =
            fieldType === "Lookup" ||
            fieldType === "LookupMulti" ||
            fieldType === "TaxonomyFieldType" ||
            fieldType === "TaxonomyFieldTypeMulti" ||
            fieldType === "User" ||
            fieldType === "UserMulti";

          return isLookupType || options !== undefined || !hideContextBrowser ? (
            <ContextBrowser
              {...props}
              options={options}
              isLookupType={isLookupType}
              allowMulti={allowMulti}
              SearchResults={SearchResults}
              contextExtension={contextExtension}
            />
          ) : (
            <></>
          );
        }
      }}
    />
  );
};

const mapToUserPrincipalType = (value: any) => {
  switch (value) {
    case "SPGroup":
    case 8:
      return UserPrincipalType.SharePointGroup;
    case "SecGroup":
    case "FormsRole":
    case 4:
      return UserPrincipalType.DomainGroup;
    case "User":
    case 1:
      return UserPrincipalType.Person;
    default:
      return UserPrincipalType.Undefined;
  }
};

const SearchResults = (props: {
  name: string;
  field: any;
  apiClient: ApiClient;
  onClick: (ITag: any) => void;
}) => {
  const [options, setOptions] = useState<ITag[]>([]);
  const { apiClient } = props;

  const defaultSearchValues = async () => {
    let newOptions: ITag[] = [];
    if (props.field.type === "User" || props.field.type === "UserMulti") {
      const results = await apiClient.searchPeople({
        webUrl: props.field.web,
        ProcessPersonalFavorites: true,
        Querytext: "*"
      });
      newOptions = results.map((result) => {
        return {
          key: result.UserPrincipalName ?? result.Id,
          name: result.Title!,
          UserPrincipalName: result.UserPrincipalName,
          //LoginName: result.LoginName,
          EntityType: mapToUserPrincipalType(result.PrincipalType),
          Email: result.Email
        };
      });
    }

    setOptions(newOptions);
  };

  useEffect(() => {
    defaultSearchValues();
  }, []);

  return (
    <>
      <SearchBox
        placeholder={"Search"}
        style={{ minHeight: "32px" }}
        onSearch={async (newValue: any) => {
          let newOptions: ITag[] = [];
          if (props.field.type === "Lookup" || props.field.type === "LookupMulti") {
            const results = await apiClient.getLookupChoices(
              props.field.list.replace("{", "").replace("}", ""),
              props.field.web,
              newValue
            );
            newOptions = results.map((result) => {
              return { key: `'${result.LookupId}'`, name: result.LookupValue, ...result };
            });
          } else if (
            props.field.type === "TaxonomyFieldType" ||
            props.field.type === "TaxonomyFieldTypeMulti"
          ) {
            const results = await apiClient.getTermChoices(
              props.field.SspId,
              props.field.TermSetId,
              newValue
            );
            newOptions = results
              .filter((result) => !result.isDeprecated && result.isAvailableForTagging)
              .map((result) => {
                return {
                  key: result.id,
                  name: result.labels[0].name,
                  TermID: result.id,
                  Label: result.labels[0].name,
                  ...result
                };
              });
          } else if (props.field.type === "User" || props.field.type === "UserMulti") {
            if (!newValue) {
              defaultSearchValues();
              return;
            }
            const results = await apiClient.getPeople({
              webUrl: props.field.web,
              queryString: newValue,
              principalType: 5 as PrincipalType
            });
            newOptions = results.map((result) => {
              return {
                key: result.UserPrincipalName ?? result.Id,
                name: result.DisplayName!,
                UserPrincipalName: result.UserPrincipalName,
                //LoginName: result.LoginName,
                EntityType: mapToUserPrincipalType(result.EntityType),
                Email: result.Email
              };
            });
          }

          setOptions(newOptions);
        }}
      />

      {options!.map((option) => {
        return (
          <OptionsLink
            onClick={() => {
              props.onClick(option);
            }}
            title={option.name}
          >
            {option.name}
          </OptionsLink>
        );
      })}
    </>
  );
};

const trimText = (text: string, trim: string) => {
  let trimmed = text.startsWith(trim) ? text.substr(trim.length) : text;
  trimmed = trimmed.endsWith(trim) ? trimmed.substr(0, trimmed.length - trim.length) : trimmed;
  return trimmed;
};

export const OptionsLink = styled(Link)`
  border-bottom: 1px solid rgb(186, 186, 186);
  padding: 10px;
  min-height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  & > * {
    font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  }
  &:hover {
    background: rgb(243, 242, 241);
  }
`;
