import { Route, Routes } from "react-router-dom";
import { AppRoutes } from "../../routes";
import { ConfigurePageControl } from "./ConfigurePageControl";

export function ConfigureApp() {
  return (
    <Routes>
      <Route path={AppRoutes.ConfigurePage + "/*"} element={<ConfigurePageControl />} />
      <Route path={AppRoutes.ConfigureSubPage + "/*"} element={<ConfigurePageControl />} />
    </Routes>
  );
}
