import {
  Dialog as FuiDialog,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  DialogType as FuiDialogType,
  Spinner,
  SpinnerSize
} from "@fluentui/react";
import * as React from "react";
import { dialogSelector } from "../../store/dialogReducer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { hideDialog } from "../../store/dialogReducer";

const dialogStyles = { main: { maxWidth: "450px !important", minHeight: "100px" } };

export enum DialogType {
  Ok,
  OkCancel,
  DeleteCancel,
  YesNo,
  SaveCancel,
  Overwrite,
  Working
}

export enum DialogResult {
  Ok,
  Cancel,
  Alternative
}

export interface DialogProps {
  type: DialogType;
  title: string;
  subText: string;
  onClick: (result: DialogResult) => void;
  onRenderDialog?: () => JSX.Element;
}

export const Dialog: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { hidden, dialogProps } = useAppSelector(dialogSelector);

  const onClick = (result: DialogResult) => {
    dispatch(hideDialog());
    if (dialogProps?.onClick) {
      dialogProps.onClick(result);
    }
  };

  const getButtons = () => {
    switch (dialogProps?.type) {
      case DialogType.Ok:
        return (
          <DialogFooter>
            <PrimaryButton onClick={() => onClick(DialogResult.Ok)} text="OK" />
          </DialogFooter>
        );
      case DialogType.OkCancel:
        return (
          <DialogFooter>
            <PrimaryButton onClick={() => onClick(DialogResult.Ok)} text="OK" />
            <DefaultButton onClick={() => onClick(DialogResult.Cancel)} text="Cancel" />
          </DialogFooter>
        );
      case DialogType.Working:
        return <DialogFooter />;
      case DialogType.DeleteCancel:
        return (
          <DialogFooter>
            <PrimaryButton onClick={() => onClick(DialogResult.Ok)} text="Delete" />
            <DefaultButton onClick={() => onClick(DialogResult.Cancel)} text="Cancel" />
          </DialogFooter>
        );
      case DialogType.YesNo:
        return (
          <DialogFooter>
            <PrimaryButton onClick={() => onClick(DialogResult.Ok)} text="Yes" />
            <DefaultButton onClick={() => onClick(DialogResult.Cancel)} text="No" />
          </DialogFooter>
        );
      case DialogType.SaveCancel:
        return (
          <DialogFooter>
            <PrimaryButton onClick={() => onClick(DialogResult.Ok)} text="Save" />
            <DefaultButton onClick={() => onClick(DialogResult.Cancel)} text="Cancel" />
          </DialogFooter>
        );
      case DialogType.Overwrite:
        return (
          <DialogFooter>
            <PrimaryButton onClick={() => onClick(DialogResult.Ok)} text="Overwrite" />
            <PrimaryButton onClick={() => onClick(DialogResult.Alternative)} text="Save a copy" />
            <DefaultButton onClick={() => onClick(DialogResult.Cancel)} text="Cancel" />
          </DialogFooter>
        );
    }

    return null;
  };

  if (!hidden) {
    return (
      <>
        <FuiDialog
          title={dialogProps?.title}
          subText={dialogProps?.subText}
          hidden={hidden}
          isBlocking={false}
          styles={dialogStyles}
          type={FuiDialogType.normal}
          onDismiss={() => onClick(DialogResult.Cancel)}
        >
          {dialogProps?.onRenderDialog && dialogProps.onRenderDialog()}
          {getButtons()}
        </FuiDialog>
      </>
    );
  }

  return null;
};

export const DefaultErrorMessage = (message: string) => (
  <div style={{ padding: "10px", overflow: "auto", whiteSpace: "pre-wrap" }}>{message}</div>
);

export const WorkingOnIt = () => <Spinner size={SpinnerSize.large} />;
