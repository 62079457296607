import * as pathToRegexp from "path-to-regexp";
import { ProcessItem } from "@workpoint/components/lib/models/ProcessConfiguration";
import { getSPHostUrl } from "./utils/commonUtils";

export const AppRoutes = {
  Home: "/",

  Processes: "/Process",
  ProcessHistory: ":processConfigurationId/history",
  Process: ":processId",
  NewProcess: "new",
  NewProcessDialog: "newProcess",
  ProcessLocalization: "localization",
  ProcessLocalizationImport: "import",
  ProcessProperties: "properties",
  // ProcessRow: ":rowType/:rowIndex",
  ProcessStep: "steps/:rowIndex",
  ProcessTrigger: "triggers/:rowIndex",
  ProcessNewTrigger: "newTrigger/:insertIndex",
  ProcessNewTrigger1: ":rowType/:rowIndex/newTrigger/:insertIndex",
  ProcessNewStep: "newStep/:insertIndex",
  ProcessNewStep1: ":rowType/:rowIndex/newStep/:insertIndex",

  Configure: "/configure",
  ConfigureEndpoints: "/configure/endpoints",
  Notification: "/Notification",
  NewNotificationProfile: "profile/create",
  EditNotificationProfile: "profile/edit/:notificationId",
  Unauthorized: "/Unauthorized",
  LoggedOut: "/Loggedout",
  ConfigurePage: ":pageId",
  ConfigureSubPage: ":pageId/:subPageId",
  ConfigureSubPageDetails: ":rowId",
  ConfigureSubPageDetailsNew: "new",
  ConfigureSettingsParameterPanel: "panel",

  AdminDashboard: "dashboard",
  BusinessModulePage: "businessmodules/:id/settings"
};

const CompiledRoutes = {
  process: pathToRegexp.compile(`${AppRoutes.Processes}/${AppRoutes.Process}`),
  newProcessDialog: pathToRegexp.compile(AppRoutes.NewProcessDialog),
  newProcess: pathToRegexp.compile(`${AppRoutes.Processes}/${AppRoutes.NewProcess}`),
  processHistory: pathToRegexp.compile(`${AppRoutes.Processes}/${AppRoutes.ProcessHistory}`),
  processLocalization: pathToRegexp.compile(AppRoutes.ProcessLocalization),
  processLocalizationImport: pathToRegexp.compile(AppRoutes.ProcessLocalizationImport),
  processProperties: pathToRegexp.compile(AppRoutes.ProcessProperties),
  processStep: pathToRegexp.compile(
    `${AppRoutes.Processes}/${AppRoutes.Process}/${AppRoutes.ProcessStep}`
  ),
  processTrigger: pathToRegexp.compile(
    `${AppRoutes.Processes}/${AppRoutes.Process}/${AppRoutes.ProcessTrigger}`
  ),
  processNewStep: pathToRegexp.compile(AppRoutes.ProcessNewStep),
  processNewTrigger: pathToRegexp.compile(AppRoutes.ProcessNewTrigger),

  editNotificationProfile: pathToRegexp.compile(AppRoutes.EditNotificationProfile),
  newNotificationProfile: pathToRegexp.compile(AppRoutes.NewNotificationProfile),
  adminSettingParameterPanel: pathToRegexp.compile(
    `${AppRoutes.Configure}/${AppRoutes.ConfigureSubPage}/${AppRoutes.ConfigureSettingsParameterPanel}`
  ),
  adminPage: pathToRegexp.compile(`${AppRoutes.Configure}/${AppRoutes.ConfigurePage}`),
  adminSubPageDetails: pathToRegexp.compile(
    `${AppRoutes.Configure}/${AppRoutes.ConfigureSubPage}/${AppRoutes.ConfigureSubPageDetails}`
  ),
  adminSubPageDetailsNew: pathToRegexp.compile(
    `${AppRoutes.Configure}/${AppRoutes.ConfigureSubPage}/${AppRoutes.ConfigureSubPageDetailsNew}`
  ),
  adminDashboard: pathToRegexp.compile(`${AppRoutes.Configure}/${AppRoutes.AdminDashboard}`),
  adminBusinessModulePage: pathToRegexp.compile(
    `${AppRoutes.Configure}/${AppRoutes.BusinessModulePage}`
  )
};

const spHostUrl = getSPHostUrl();
const spHostUrlParam = spHostUrl && !window.location.hash ? `?spHostUrl=${spHostUrl}` : "";

export const ToRoute = {
  home: () => AppRoutes.Home + spHostUrlParam,
  unauthorized: () => AppRoutes.Unauthorized + spHostUrlParam,
  processes: () => AppRoutes.Processes + spHostUrlParam,
  process: (processId: string) => CompiledRoutes.process({ processId }) + spHostUrlParam,
  newProcessDialog: () => CompiledRoutes.newProcessDialog() + spHostUrlParam,
  newProcess: () => CompiledRoutes.newProcess() + spHostUrlParam,
  processHistory: (processConfigurationId: string) =>
    CompiledRoutes.processHistory({ processConfigurationId }) + spHostUrlParam,
  processLocalization: () => CompiledRoutes.processLocalization() + spHostUrlParam,
  processLocalizationImport: () => CompiledRoutes.processLocalizationImport() + spHostUrlParam,
  processProperties: () => CompiledRoutes.processProperties() + spHostUrlParam,
  processNewStep: (insertIndex?: string) =>
    CompiledRoutes.processNewStep({ insertIndex }) + spHostUrlParam,
  processStep: (process: ProcessItem, rowIndex: string, parameterName?: string) =>
    CompiledRoutes.processStep({ processId: process.itemId ?? "new", rowIndex, parameterName }) +
    spHostUrlParam,
  processNewTrigger: (insertIndex?: string) =>
    CompiledRoutes.processNewTrigger({
      insertIndex
    }) + spHostUrlParam,
  processTrigger: (process: ProcessItem, rowIndex: string, parameterName?: string) =>
    CompiledRoutes.processTrigger({ processId: process.itemId ?? "new", rowIndex, parameterName }) +
    spHostUrlParam,
  editNotificationProfile: (notificationId: string) =>
    CompiledRoutes.editNotificationProfile({ notificationId }) + spHostUrlParam,
  newNotificationProfile: () => CompiledRoutes.newNotificationProfile() + spHostUrlParam,
  adminPage: (pageId: String) => CompiledRoutes.adminPage({ pageId }) + spHostUrlParam,
  adminSubPageDetails: (pageId: string, subPageId: string, rowId: string) =>
    CompiledRoutes.adminSubPageDetails({ pageId, subPageId, rowId }) + spHostUrlParam,
  adminSubPageDetailsNew: (pageId: string, subPageId: string) =>
    CompiledRoutes.adminSubPageDetailsNew({ pageId, subPageId }) + spHostUrlParam,
  adminSettingParameterPanel: (pageId: string, subPageId: string) =>
    CompiledRoutes.adminSettingParameterPanel({ pageId, subPageId }) + spHostUrlParam,
  adminDashboard: () => CompiledRoutes.adminDashboard() + spHostUrlParam,
  adminBusinessModulePage: (id: string) =>
    CompiledRoutes.adminBusinessModulePage({ id }) + spHostUrlParam
};
