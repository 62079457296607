import React from "react";
import { CommandButton, Icon, Label, Separator, Stack } from "@fluentui/react";
import styled from "styled-components";
import theme from "@workpoint/components/lib/constants";
import { useIntl } from "react-intl";

interface PanelHeaderProps {
  title: string;
  closePanel: () => void;
  iconName?: string;
  additionalButtons?: JSX.Element[];
  children?: JSX.Element | JSX.Element[];
}

/**
 * Add the configuration to show a step name with icon below the header title
 */
export const PanelHeader = (props: PanelHeaderProps) => {
  const intl = useIntl();
  const { title, closePanel, iconName, additionalButtons, children } = props;

  return (
    <>
      <Header horizontal>
        <Stack.Item styles={{ root: { alignItems: "center" } }}>
          {iconName && <HeaderIcon iconName={iconName} />}
        </Stack.Item>
        <Stack.Item grow>
          <Label style={{ color: theme.palette.blue, fontSize: 16 }}>{title}</Label>
        </Stack.Item>
        {additionalButtons?.map((button: any, index: number) => {
          return <Stack.Item key={index}>{button}</Stack.Item>;
        })}
        <Stack.Item>
          <CommandButton
            iconProps={{ iconName: "ChromeClose" }}
            text={intl.formatMessage({ id: "option-close", defaultMessage: "Close" })}
            onClick={closePanel}
          />
        </Stack.Item>
      </Header>
      <PanelSeperator />
      {children}
      {children && <PanelSeperator />}
    </>
  );
};

const Header = styled(Stack)`
  color: ${theme.palette.blue};
  background-color: #faf9f8;
  display: flex;
  align-items: center;
  padding: 0px 3%;
`;

const PanelSeperator = styled(Separator)`
  padding: 0px;
  margin-bottom: 1px;
  height: 0px;
`;

const HeaderIcon = styled(Icon)`
  margin: 0px 20px 0px 10px;
  font-size: 20px;
  align-self: center;
  transform: translateY(2px);
`;
