import React from "react";
import { Panel, mergeStyleSets, PanelType, CommandButton, IPanelProps } from "@fluentui/react";
import { Control, FieldValues, UseFormSetValue, UseFormTrigger } from "react-hook-form";
import { ComparisonOperator } from "@workpoint/components/lib/models/ProcessConfiguration";
import { useIntl } from "react-intl";
import { PanelHeader } from "../PanelHeader";
import FieldInfo from "../FieldInfo";
import { PropertyExpressionType } from "../parametersForm/ParametersForm";
import { defaultExpressionRule } from "../../../utils/processUtils";
import { ControlledConditions } from "./ControlledConditions";
import { matchPath, useLocation } from "react-router-dom";
import { AppRoutes } from "../../../routes";

export interface ConditionsSettings {
  hideComparison?: boolean;
  addSearchQuery?: boolean;
  isFilter?: boolean;
}
export interface ConditionsPanelProps {
  name: string;
  fieldDescription: string | undefined;
  propertyDescription: string | undefined;
  propertyIconName: string;
  control: Control<FieldValues>;
  setValue: UseFormSetValue<any>;
  trigger: UseFormTrigger<any>;
  onClose(): void;
  settings?: ConditionsSettings;
  definition?: any;
  fieldValues?: any;
  contextExtension?: any;
}

export const ConditionsPanel = (props: ConditionsPanelProps) => {
  const intl = useIntl();
  const location = useLocation();
  const adminPanelProps: IPanelProps = {};

  const isAdminPanel = (): boolean => {
    const isAdmin =
      matchPath(`${AppRoutes.Configure}/${AppRoutes.ConfigurePage}`, location.pathname) !== null;
    if (isAdmin) {
      adminPanelProps.isLightDismiss = true;
      adminPanelProps.onLightDismissClick = () => props.onClose();
      adminPanelProps.style = { marginTop: "50px" };
    }
    return isAdmin;
  };
  const adminPanel = isAdminPanel();

  const _renderPanelHeader = () => {
    return (
      <PanelHeader
        title={`${intl.formatMessage({
          id: "conditions-configuration",
          defaultMessage: "Configuration"
        })} • ${props.propertyDescription ?? ""}`}
        closePanel={props.onClose}
        iconName="CodeEdit"
        additionalButtons={[
          <CommandButton
            iconProps={{ iconName: "Delete" }}
            text="Clear"
            onClick={() => {
              props.setValue(
                props.name + "." + PropertyExpressionType.Basic,
                [
                  defaultExpressionRule(
                    props.settings?.hideComparison ? ComparisonOperator.None : undefined
                  )
                ],
                {
                  shouldDirty: true,
                  shouldValidate: true
                }
              );
              props.setValue(props.name + "." + PropertyExpressionType.Advanced, "", {
                shouldDirty: true,
                shouldValidate: true
              });
            }}
          />
        ]}
      >
        <FieldInfo
          label1={intl.formatMessage({ id: "condition-field", defaultMessage: "Field" })}
          label2={props.fieldDescription || props.name}
        />
        <FieldInfo
          label1={intl.formatMessage({ id: "condition-property", defaultMessage: "Property" })}
          label2={props.propertyDescription ?? ""}
          iconName={props.propertyIconName}
        />
      </PanelHeader>
    );
  };

  return (
    <>
      <Panel
        isOpen={true}
        onRenderHeader={_renderPanelHeader}
        hasCloseButton={false}
        type={adminPanel ? PanelType.medium : PanelType.smallFluid}
        isBlocking={adminPanel}
        onDismiss={() => {}}
        styles={panelStyles}
        focusTrapZoneProps={{
          isClickableOutsideFocusTrap: true,
          forceFocusInsideTrap: false
        }}
        {...adminPanelProps}
      >
        <ControlledConditions
          name={props.name}
          control={props.control}
          setValue={props.setValue}
          trigger={props.trigger}
          settings={props.settings}
          definition={props.definition}
          fieldValues={props.fieldValues}
          isFilter={props.settings?.isFilter}
          contextExtension={props.contextExtension}
        />
      </Panel>
    </>
  );
};

const panelStyles = mergeStyleSets({
  root: {
    width: "100%",
    borderLeftWidth: "1px",
    borderLeftStyle: "solid",
    borderLeftColor: "#E5E5E5"
  },
  navigation: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0px 14px"
  },
  commands: {
    display: "none"
  },
  main: {
    overflow: "hidden"
  },
  scrollableContent: { height: "100%" },
  content: { height: "100%", padding: "0px" }
});
