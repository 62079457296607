export const LocalizationLanguages = {
  languages: [
    {
      name: "Arabic (Saudi Arabia)",
      nativeName: "العربية (المملكة العربية السعودية)",
      code: "ar-sa",
      hasTranslation: true
    },
    {
      name: "Czech (Czech Republic)",
      nativeName: "Čeština (Česká republika)",
      code: "cs-cz",
      hasTranslation: true
    },
    {
      name: "Danish (Denmark)",
      nativeName: "Dansk (Danmark)",
      code: "da-dk",
      hasTranslation: true
    },
    {
      name: "German (Austria)",
      nativeName: "Deutsch (Österreich)",
      code: "de-at",
      translationCode: "de-de"
    },
    {
      name: "German (Switzerland)",
      nativeName: "Deutsch (Schweiz)",
      code: "de-ch",
      translationCode: "de-de"
    },
    {
      name: "German (Germany)",
      nativeName: "Deutsch (Deutschland)",
      code: "de-de",
      hasTranslation: true
    },
    {
      name: "German (Liechtenstein)",
      nativeName: "Deutsch (Liechtenstein)",
      code: "de-li",
      translationCode: "de-de"
    },
    {
      name: "German (Luxembourg)",
      nativeName: "Deutsch (Luxemburg)",
      code: "de-lu",
      translationCode: "de-de"
    },
    {
      name: "English (Caribbean)",
      nativeName: "English (Caribbean)",
      code: "en-029",
      translationCode: "en-us"
    },
    {
      name: "English (Australia)",
      nativeName: "English (Australia)",
      code: "en-au",
      translationCode: "en-gb"
    },
    {
      name: "English (Belize)",
      nativeName: "English (Belize)",
      code: "en-bz",
      translationCode: "en-us"
    },
    {
      name: "English (Canada)",
      nativeName: "English (Canada)",
      code: "en-ca",
      translationCode: "en-gb"
    },
    {
      name: "English (United Kingdom)",
      nativeName: "English (United Kingdom)",
      code: "en-gb",
      hasTranslation: true
    },
    {
      name: "English (Ireland)",
      nativeName: "English (Ireland)",
      code: "en-ie",
      translationCode: "en-gb"
    },
    {
      name: "English (India)",
      nativeName: "English (India)",
      code: "en-in",
      translationCode: "en-gb"
    },
    {
      name: "English (Jamaica)",
      nativeName: "English (Jamaica)",
      code: "en-jm",
      translationCode: "en-us"
    },
    {
      name: "English (Malaysia)",
      nativeName: "English (Malaysia)",
      code: "en-my",
      translationCode: "en-gb"
    },
    {
      name: "English (New Zealand)",
      nativeName: "English (New Zealand)",
      code: "en-nz",
      translationCode: "en-gb"
    },
    {
      name: "English (Republic of the Philippines)",
      nativeName: "English (Philippines)",
      code: "en-ph",
      translationCode: "en-us"
    },
    {
      name: "English (Singapore)",
      nativeName: "English (Singapore)",
      code: "en-sg",
      translationCode: "en-gb"
    },
    {
      name: "English (Trinidad and Tobago)",
      nativeName: "English (Trinidad y Tobago)",
      code: "en-tt",
      translationCode: "en-us"
    },
    {
      name: "English (United States)",
      nativeName: "English (United States)",
      code: "en-us",
      hasTranslation: true
    },
    {
      name: "English (South Africa)",
      nativeName: "English (South Africa)",
      code: "en-za",
      translationCode: "en-gb"
    },
    {
      name: "English (Zimbabwe)",
      nativeName: "English (Zimbabwe)",
      code: "en-zw",
      translationCode: "en-gb"
    },
    {
      name: "Spanish (Argentina)",
      nativeName: "Español (Argentina)",
      code: "es-ar",
      translationCode: "es-mx"
    },
    {
      name: "Spanish (Bolivia)",
      nativeName: "Español (Bolivia)",
      code: "es-bo",
      translationCode: "es-mx"
    },
    {
      name: "Spanish (Chile)",
      nativeName: "Español (Chile)",
      code: "es-cl",
      translationCode: "es-mx"
    },
    {
      name: "Spanish (Colombia)",
      nativeName: "Español (Colombia)",
      code: "es-co",
      translationCode: "es-es"
    },
    {
      name: "Spanish (Costa Rica)",
      nativeName: "Español (Costa Rica)",
      code: "es-cr",
      translationCode: "es-mx"
    },
    {
      name: "Spanish (Dominican Republic)",
      nativeName: "Español (República Dominicana)",
      code: "es-do",
      translationCode: "es-mx"
    },
    {
      name: "Spanish (Ecuador)",
      nativeName: "Español (Ecuador)",
      code: "es-ec",
      translationCode: "es-mx"
    },
    {
      name: "Spanish (Spain, International Sort)",
      nativeName: "Español (España, alfabetización internacional)",
      code: "es-es",
      hasTranslation: true
    },
    {
      name: "Spanish (Guatemala)",
      nativeName: "Español (Guatemala)",
      code: "es-gt",
      translationCode: "es-mx"
    },
    {
      name: "Spanish (Honduras)",
      nativeName: "Español (Honduras)",
      code: "es-hn",
      translationCode: "es-mx"
    },
    {
      name: "Spanish (Mexico)",
      nativeName: "Español (México)",
      code: "es-mx",
      hasTranslation: true
    },
    {
      name: "Spanish (Nicaragua)",
      nativeName: "Español (Nicaragua)",
      code: "es-ni",
      translationCode: "es-mx"
    },
    {
      name: "Spanish (Panama)",
      nativeName: "Español (Panamá)",
      code: "es-pa",
      translationCode: "es-mx"
    },
    {
      name: "Spanish (Peru)",
      nativeName: "Español (Perú)",
      code: "es-pe",
      translationCode: "es-mx"
    },
    {
      name: "Spanish (Puerto Rico)",
      nativeName: "Español (Puerto Rico)",
      code: "es-pr",
      translationCode: "es-mx"
    },
    {
      name: "Spanish (Paraguay)",
      nativeName: "Español (Paraguay)",
      code: "es-py",
      translationCode: "es-mx"
    },
    {
      name: "Spanish (El Salvador)",
      nativeName: "Español (El Salvador)",
      code: "es-sv",
      translationCode: "es-mx"
    },
    {
      name: "Spanish (United States)",
      nativeName: "Español (Estados Unidos)",
      code: "es-us",
      translationCode: "es-mx"
    },
    {
      name: "Spanish (Uruguay)",
      nativeName: "Español (Uruguay)",
      code: "es-uy",
      translationCode: "es-es"
    },
    {
      name: "Spanish (Bolivarian Republic of Venezuela)",
      nativeName: "Español (Republica Bolivariana de Venezuela)",
      code: "es-ve",
      translationCode: "es-mx"
    },
    {
      name: "Finnish (Finland)",
      nativeName: "Suomi (Suomi)",
      code: "fi-fi",
      hasTranslation: true
    },
    {
      name: "French (Belgium)",
      nativeName: "Français (Belgique)",
      code: "fr-be",
      translationCode: "fr-fr"
    },
    {
      name: "Hebrew (Israel)",
      nativeName: "עברית (ישראל)",
      code: "he-il",
      hasTranslation: true
    },
    {
      name: "Hindi (India)",
      nativeName: "हिन्दी (भारत)",
      code: "hi-in",
      hasTranslation: true
    },
    {
      name: "French (Canada)",
      nativeName: "Français (Canada)",
      code: "fr-ca",
      hasTranslation: true
    },
    {
      name: "French (Switzerland)",
      nativeName: "Français (Suisse)",
      code: "fr-ch",
      translationCode: "fr-fr"
    },
    {
      name: "French (France)",
      nativeName: "Français (France)",
      code: "fr-fr",
      hasTranslation: true
    },
    {
      name: "French (Luxembourg)",
      nativeName: "Français (Luxembourg)",
      code: "fr-lu",
      translationCode: "fr-fr"
    },
    {
      name: "French (Monaco)",
      nativeName: "Français (Principauté de Monaco)",
      code: "fr-mc",
      translationCode: "fr-fr"
    },
    {
      name: "Italian (Switzerland)",
      nativeName: "Italiano (Svizzera)",
      code: "it-ch",
      translationCode: "it-it"
    },
    {
      name: "Italian (Italy)",
      nativeName: "Italiano (Italia)",
      code: "it-it",
      hasTranslation: true
    },
    {
      name: "Japanese (Japan)",
      nativeName: "日本語 (日本)",
      code: "ja-jp",
      hasTranslation: true
    },
    {
      name: "Korean (Korea)",
      nativeName: "한국어 (대한민국)",
      code: "ko-kr",
      hasTranslation: true
    },
    {
      name: "Norwegian, Bokmål (Norway)",
      nativeName: "Norsk, Bokmål (Norge)",
      code: "nb-no",
      hasTranslation: true
    },
    {
      name: "Norwegian, Nynorsk (Norway)",
      nativeName: "Norsk, Nynorsk (Norge)",
      code: "nn-no",
      hasTranslation: true
    },
    {
      name: "Dutch (Belgium)",
      nativeName: "Nederlands (België)",
      code: "nl-be",
      translationCode: "nl-nl"
    },
    {
      name: "Dutch (Netherlands)",
      nativeName: "Nederlands (Nederland)",
      code: "nl-nl",
      hasTranslation: true
    },
    {
      name: "Filipino (Philippines)",
      nativeName: "Filipino (Pilipinas)",
      code: "fil-ph",
      hasTranslation: true
    },
    {
      name: "Polish (Poland)",
      nativeName: "Polski (Polska)",
      code: "pl-pl",
      hasTranslation: true
    },
    {
      name: "Slovak (Slovakia)",
      nativeName: "Slovenčina (Slovenská republika)",
      code: "sk-sk",
      hasTranslation: true
    },
    {
      name: "Hungarian (Hungary)",
      nativeName: "Magyar (Magyarország)",
      code: "hu-hu",
      hasTranslation: true
    },
    {
      name: "Greek (Greece)",
      nativeName: "Ελληνικά (Ελλάδας)",
      code: "el-gr",
      hasTranslation: true
    },
    {
      name: "Ukrainian (Ukraine)",
      nativeName: "Українська (Україна)",
      code: "uk-ua",
      hasTranslation: true
    },
    {
      name: "Pseudo",
      nativeName: "Pseudo",
      code: "pseudo",
      hasTranslation: true
    },
    {
      name: "RTL English",
      nativeName: "Shilgny (The Upsidedown)",
      code: "fl-ip",
      translationCode: "en-us"
    },
    {
      name: "Portuguese (Brazil)",
      nativeName: "Português (Brasil)",
      code: "pt-br",
      hasTranslation: true
    },
    {
      name: "Portuguese (Portugal)",
      nativeName: "Português (Portugal)",
      code: "pt-pt",
      hasTranslation: true
    },
    {
      name: "Russian (Russia)",
      nativeName: "Русский (Россия)",
      code: "ru-ru",
      hasTranslation: true
    },
    {
      name: "Swedish (Finland)",
      nativeName: "Svenska (Finland)",
      code: "sv-fi",
      translationCode: "sv-se"
    },
    {
      name: "Swedish (Sweden)",
      nativeName: "Svenska (Sverige)",
      code: "sv-se",
      hasTranslation: true
    },
    {
      name: "Turkish (Turkey)",
      nativeName: "Türkçe (Türkiye)",
      code: "tr-tr",
      hasTranslation: true
    },
    {
      name: "Chinese (Simplified, PRC)",
      nativeName: "中文(中国)",
      code: "zh-cn",
      hasTranslation: true
    },
    {
      name: "Chinese (Simplified, Singapore)",
      nativeName: "中文(新加坡)",
      code: "zh-sg",
      translationCode: "zh-cn"
    },
    {
      name: "Chinese (Traditional, Hong Kong S.A.R.)",
      nativeName: "中文(香港特別行政區)",
      code: "zh-hk",
      translationCode: "zh-tw"
    },
    {
      name: "Chinese (Traditional, Macao S.A.R.)",
      nativeName: "中文(澳門特別行政區)",
      code: "zh-mo",
      translationCode: "zh-tw"
    },
    {
      name: "Chinese (Traditional, Taiwan)",
      nativeName: "中文(台灣)",
      code: "zh-tw",
      hasTranslation: true
    },
    {
      name: "Thai (Thailand)",
      nativeName: "ไทย (ไทย)",
      code: "th-th",
      hasTranslation: true
    },
    {
      name: "Indonesian (Indonesia)",
      nativeName: "Bahasa Indonesia (Indonesia)",
      code: "id-id",
      hasTranslation: true
    },
    {
      name: "Romanian (Romania)",
      nativeName: "Română (România)",
      code: "ro-ro",
      hasTranslation: true
    },
    {
      name: "Vietnamese (Vietnam)",
      nativeName: "Tiếng Việt (Việt Nam)",
      code: "vi-vn",
      hasTranslation: true
    },
    {
      name: "Lithuanian (Lithuania)",
      nativeName: "Lietuvių (Lietuva)",
      code: "lt-lt",
      hasTranslation: true
    },
    {
      name: "Slovenian (Slovenia)",
      nativeName: "Slovenščina (Slovenija)",
      code: "sl-si",
      hasTranslation: true
    },
    {
      name: "Catalan (Catalan)",
      nativeName: "Català (Català)",
      code: "ca-es",
      hasTranslation: true
    },
    {
      name: "Croatian (Croatia)",
      nativeName: "Hrvatski (Hrvatska)",
      code: "hr-hr",
      hasTranslation: true
    },
    {
      name: "Estonian (Estonia)",
      nativeName: "Eesti (Eesti)",
      code: "et-ee",
      hasTranslation: true
    },
    {
      name: "Bulgarian (Bulgaria)",
      nativeName: "Български (България)",
      code: "bg-bg",
      hasTranslation: true
    },
    {
      name: "Welsh (United Kingdom)",
      nativeName: "Cymraeg (Y Deyrnas Unedig)",
      code: "cy-gb",
      hasTranslation: true
    },
    {
      name: "Icelandic (Iceland)",
      nativeName: "Íslenska (Ísland)",
      code: "is-is",
      hasTranslation: true
    },
    {
      name: "Serbian (Latin, Serbia)",
      nativeName: "Srpski (Srbija)",
      code: "sr-latn-rs",
      hasTranslation: true
    },
    {
      name: "Latvian (Latvia)",
      nativeName: "Latviešu (Latvija)",
      code: "lv-lv",
      hasTranslation: true
    },
    {
      name: "Arabic (All)",
      code: "ar"
    },
    {
      name: "Czech (All)",
      code: "cs"
    },
    {
      name: "Danish (All)",
      code: "da"
    },
    {
      name: "German (All)",
      code: "de"
    },
    {
      name: "English (All)",
      code: "en"
    },
    {
      name: "Spanish (All)",
      code: "es"
    },
    {
      name: "Finnish (All)",
      code: "fi"
    },
    {
      name: "French (All)",
      code: "fr"
    },
    {
      name: "Hebrew (All)",
      code: "he"
    },
    {
      name: "Hindi (All)",
      code: "hi"
    },
    {
      name: "Italian (All)",
      code: "it"
    },
    {
      name: "Japnese (All)",
      code: "ja"
    },
    {
      name: "Korean (All)",
      code: "ko"
    },
    {
      name: "Norwegian, bokmal (All)",
      code: "nb"
    },
    {
      name: "Norwegian, Nynorsk (All)",
      code: "nn"
    },
    {
      name: "Dutch (All)",
      code: "nl"
    },
    {
      name: "Filipino (All)",
      code: "fil"
    },
    {
      name: "Polish (All)",
      code: "pl"
    },
    {
      name: "Slovak (All)",
      code: "sk"
    },
    {
      name: "Hungarian (All)",
      code: "hu"
    },
    {
      name: "Greek (All)",
      code: "el"
    },
    {
      name: "Ukrainian (All)",
      code: "uk"
    },
    {
      name: "Pseudo (All)",
      code: "pseudo"
    },
    {
      name: "RTL English (All)",
      code: "fl"
    },
    {
      name: "Portuguese (All)",
      code: "pt"
    },
    {
      name: "Russian (All)",
      code: "ru"
    },
    {
      name: "Swedish (All)",
      code: "sv"
    },
    {
      name: "Turkish (All)",
      code: "tr"
    },
    {
      name: "Chinese (All)",
      code: "zh"
    },
    {
      name: "Thai (All)",
      code: "th"
    },
    {
      name: "Indonesian (All)",
      code: "id"
    },
    {
      name: "Romanian (All)",
      code: "ro"
    },
    {
      name: "Vietnamese (All)",
      code: "vi"
    },
    {
      name: "Lithuanian (All)",
      code: "lt"
    },
    {
      name: "Slovenian (All)",
      code: "sl"
    },
    {
      name: "Catalan (All)",
      code: "ca"
    },
    {
      name: "Croatian (All)",
      code: "hr"
    },
    {
      name: "Estonian (All)",
      code: "et"
    },
    {
      name: "Bulgarian (All)",
      code: "bg"
    },
    {
      name: "Welsh (All)",
      code: "cy"
    },
    {
      name: "Icelandic (All)",
      code: "is"
    },
    {
      name: "Serbian (All)",
      code: "sr"
    },
    {
      name: "Latvian (All)",
      code: "lv"
    }
  ],
  keyboardLayoutLanguages: [
    {
      name: "English (United States)",
      nativeName: "English (United States)",
      code: "en"
    },
    {
      name: "French (France)",
      nativeName: "Français (France)",
      code: "fr"
    },
    {
      name: "German (Germany)",
      nativeName: "Deutsch (Deutschland)",
      code: "de"
    },
    {
      name: "Spanish (Spain, International Sort)",
      nativeName: "Español (España, alfabetización internacional)",
      code: "es"
    }
  ],
  transcriptionLanguages: [
    {
      code: "en-us",
      nativeName: "English",
      BCP47Code: "en"
    },
    {
      code: "de-de",
      nativeName: "Deutsch",
      BCP47Code: "de"
    },
    {
      code: "zh-cn",
      nativeName: "中文简体",
      BCP47Code: "zh-Hans"
    }
  ],
  transcriptionSourceLanguagesForByoe: [
    {
      code: "en",
      nativeName: "English",
      BCP47Code: "en"
    },
    {
      code: "es",
      nativeName: "Español",
      BCP47Code: "es"
    }
  ],
  translationLanguages: [
    {
      code: "af-za",
      nativeName: "Afrikaans",
      BCP47Code: "af"
    },
    {
      code: "ar-eg",
      nativeName: "(العربية (مصري",
      BCP47Code: "ar"
    },
    {
      code: "bg-bg",
      nativeName: "Български",
      BCP47Code: "bg"
    },
    {
      code: "bs-latn",
      nativeName: "Bosanski",
      BCP47Code: "bs"
    },
    {
      code: "ca-es",
      nativeName: "Català",
      BCP47Code: "ca"
    },
    {
      code: "cs-cz",
      nativeName: "Čeština",
      BCP47Code: "cs"
    },
    {
      code: "cy-gb",
      nativeName: "Cymraeg",
      BCP47Code: "cy"
    },
    {
      code: "da-dk",
      nativeName: "Dansk",
      BCP47Code: "da"
    },
    {
      code: "de-de",
      nativeName: "Deutsch",
      BCP47Code: "de"
    },
    {
      code: "el-gr",
      nativeName: "Ελληνική",
      BCP47Code: "el"
    },
    {
      code: "en-us",
      nativeName: "English",
      BCP47Code: "en"
    },
    {
      code: "es-es",
      nativeName: "Español",
      BCP47Code: "es"
    },
    {
      code: "et-ee",
      nativeName: "Eesti",
      BCP47Code: "et"
    },
    {
      code: "fa-ir",
      nativeName: "فارسی",
      BCP47Code: "fa"
    },
    {
      code: "fi-fi",
      nativeName: "Suomi",
      BCP47Code: "fi"
    },
    {
      code: "fil-ph",
      nativeName: "Filipino",
      BCP47Code: "fil"
    },
    {
      code: "fr-fr",
      nativeName: "Français",
      BCP47Code: "fr"
    },
    {
      code: "he-il",
      nativeName: "עברית",
      BCP47Code: "he"
    },
    {
      code: "hi-in",
      nativeName: "हिन्दी",
      BCP47Code: "hi"
    },
    {
      code: "hr-hr",
      nativeName: "Hrvatski",
      BCP47Code: "hr"
    },
    {
      code: "ht-ht",
      nativeName: "Kreyòl ayisyen",
      BCP47Code: "ht"
    },
    {
      code: "hu-hu",
      nativeName: "Magyar",
      BCP47Code: "hu"
    },
    {
      code: "id-id",
      nativeName: "Bahasa Indonesia",
      BCP47Code: "id"
    },
    {
      code: "it-it",
      nativeName: "Italiano",
      BCP47Code: "it"
    },
    {
      code: "ja-jp",
      nativeName: "日本語",
      BCP47Code: "ja"
    },
    {
      code: "ko-kr",
      nativeName: "한국어",
      BCP47Code: "ko"
    },
    {
      code: "lt-lt",
      nativeName: "Lietuvių kalba",
      BCP47Code: "lt"
    },
    {
      code: "lv-lv",
      nativeName: "Latviešu",
      BCP47Code: "lv"
    },
    {
      code: "mg-mg",
      nativeName: "Malagasy",
      BCP47Code: "mg"
    },
    {
      code: "ms-my",
      nativeName: "Bahasa Malaysia",
      BCP47Code: "ms"
    },
    {
      code: "mt-mt",
      nativeName: "Malti",
      BCP47Code: "mt"
    },
    {
      code: "mww",
      nativeName: "Hmoob Dawb",
      BCP47Code: "mww"
    },
    {
      code: "nb-no",
      nativeName: "Norsk",
      BCP47Code: "nb"
    },
    {
      code: "nl-nl",
      nativeName: "Nederlands",
      BCP47Code: "nl"
    },
    {
      code: "otq",
      nativeName: "Querétaro Otomi",
      BCP47Code: "otq"
    },
    {
      code: "pl-pl",
      nativeName: "Polski",
      BCP47Code: "pl"
    },
    {
      code: "pt-br",
      nativeName: "Português",
      BCP47Code: "pt"
    },
    {
      code: "ro-ro",
      nativeName: "Română",
      BCP47Code: "ro"
    },
    {
      code: "ru-ru",
      nativeName: "Русский",
      BCP47Code: "ru"
    },
    {
      code: "sk-sk",
      nativeName: "Slovenčina",
      BCP47Code: "sk"
    },
    {
      code: "sl",
      nativeName: "Slovenščina",
      BCP47Code: "sl"
    },
    {
      code: "sm",
      nativeName: "Samoan",
      BCP47Code: "sm"
    },
    {
      code: "sr-cyrl",
      nativeName: "Српски",
      BCP47Code: "sr-Cyrl"
    },
    {
      code: "sr-latn",
      nativeName: "Srpski (latinica)",
      BCP47Code: "sr-Latn"
    },
    {
      code: "sv-se",
      nativeName: "Svenska",
      BCP47Code: "sv"
    },
    {
      code: "sw-ke",
      nativeName: "Kiswahili",
      BCP47Code: "sw"
    },
    {
      code: "th-th",
      nativeName: "ไทย",
      BCP47Code: "th"
    },
    {
      code: "tlh",
      nativeName: "Klingon",
      BCP47Code: "tlh"
    },
    {
      code: "to",
      nativeName: "Tongan",
      BCP47Code: "to"
    },
    {
      code: "tr-tr",
      nativeName: "Türkçe",
      BCP47Code: "tr"
    },
    {
      code: "ty",
      nativeName: "Tahitian",
      BCP47Code: "ty"
    },
    {
      code: "uk-ua",
      nativeName: "Українська",
      BCP47Code: "uk"
    },
    {
      code: "ur-pk",
      nativeName: "اردو",
      BCP47Code: "ur"
    },
    {
      code: "vi-vn",
      nativeName: "Tiếng Việt",
      BCP47Code: "vi"
    },
    {
      code: "yua",
      nativeName: "Màaya t'àan",
      BCP47Code: "yua"
    },
    {
      code: "zh-cn",
      nativeName: "中文简体",
      BCP47Code: "zh-Hans"
    }
  ],
  supportedOgmaLanguages: [
    {
      isDefault: false,
      LanguageTag: "af",
      LanguageName: "Afrikaans"
    },
    {
      isDefault: false,
      LanguageTag: "ar",
      LanguageName: "Arabic"
    },
    {
      isDefault: false,
      LanguageTag: "bn",
      LanguageName: "Bangla"
    },
    {
      isDefault: false,
      LanguageTag: "bs",
      LanguageName: "Bosnian"
    },
    {
      isDefault: false,
      LanguageTag: "bg",
      LanguageName: "Bulgarian"
    },
    {
      isDefault: false,
      LanguageTag: "yue",
      LanguageName: "Cantonese (Traditional)"
    },
    {
      isDefault: false,
      LanguageTag: "ca",
      LanguageName: "Catalan"
    },
    {
      isDefault: false,
      LanguageTag: "zh-chs",
      LanguageName: "Chinese Simplified"
    },
    {
      isDefault: false,
      LanguageTag: "zh-cht",
      LanguageName: "Chinese Traditional"
    },
    {
      isDefault: false,
      LanguageTag: "hr",
      LanguageName: "Croatian"
    },
    {
      isDefault: false,
      LanguageTag: "cs",
      LanguageName: "Czech"
    },
    {
      isDefault: false,
      LanguageTag: "da",
      LanguageName: "Danish"
    },
    {
      isDefault: false,
      LanguageTag: "nl",
      LanguageName: "Dutch"
    },
    {
      isDefault: false,
      LanguageTag: "en",
      LanguageName: "English"
    },
    {
      isDefault: false,
      LanguageTag: "et",
      LanguageName: "Estonian"
    },
    {
      isDefault: false,
      LanguageTag: "fj",
      LanguageName: "Fijian"
    },
    {
      isDefault: false,
      LanguageTag: "fil",
      LanguageName: "Filipino"
    },
    {
      isDefault: false,
      LanguageTag: "fi",
      LanguageName: "Finnish"
    },
    {
      isDefault: false,
      LanguageTag: "fr",
      LanguageName: "French"
    },
    {
      isDefault: false,
      LanguageTag: "de",
      LanguageName: "German"
    },
    {
      isDefault: false,
      LanguageTag: "el",
      LanguageName: "Greek"
    },
    {
      isDefault: false,
      LanguageTag: "ht",
      LanguageName: "Haitian Creole"
    },
    {
      isDefault: false,
      LanguageTag: "he",
      LanguageName: "Hebrew"
    },
    {
      isDefault: false,
      LanguageTag: "hi",
      LanguageName: "Hindi"
    },
    {
      isDefault: false,
      LanguageTag: "mww",
      LanguageName: "Hmong Daw"
    },
    {
      isDefault: false,
      LanguageTag: "hu",
      LanguageName: "Hungarian"
    },
    {
      isDefault: false,
      LanguageTag: "is",
      LanguageName: "Icelandic"
    },
    {
      isDefault: false,
      LanguageTag: "id",
      LanguageName: "Indonesian"
    },
    {
      isDefault: false,
      LanguageTag: "it",
      LanguageName: "Italian"
    },
    {
      isDefault: false,
      LanguageTag: "ja",
      LanguageName: "Japanese"
    },
    {
      isDefault: false,
      LanguageTag: "sw",
      LanguageName: "Kiswahili"
    },
    {
      isDefault: false,
      LanguageTag: "ko",
      LanguageName: "Korean"
    },
    {
      isDefault: false,
      LanguageTag: "lv",
      LanguageName: "Latvian"
    },
    {
      isDefault: false,
      LanguageTag: "lt",
      LanguageName: "Lithuanian"
    },
    {
      isDefault: false,
      LanguageTag: "mg",
      LanguageName: "Malagasy"
    },
    {
      isDefault: false,
      LanguageTag: "ms",
      LanguageName: "Malay"
    },
    {
      isDefault: false,
      LanguageTag: "mt",
      LanguageName: "Maltese"
    },
    {
      isDefault: false,
      LanguageTag: "nb",
      LanguageName: "Norwegian"
    },
    {
      isDefault: false,
      LanguageTag: "fa",
      LanguageName: "Persian"
    },
    {
      isDefault: false,
      LanguageTag: "pl",
      LanguageName: "Polish"
    },
    {
      isDefault: false,
      LanguageTag: "pt",
      LanguageName: "Portuguese"
    },
    {
      isDefault: false,
      LanguageTag: "otq",
      LanguageName: "Querétaro Otomi"
    },
    {
      isDefault: false,
      LanguageTag: "ro",
      LanguageName: "Romanian"
    },
    {
      isDefault: false,
      LanguageTag: "ru",
      LanguageName: "Russian"
    },
    {
      isDefault: false,
      LanguageTag: "sm",
      LanguageName: "Samoan"
    },
    {
      isDefault: false,
      LanguageTag: "sr-cyrl",
      LanguageName: "Serbian (Cyrillic)"
    },
    {
      isDefault: false,
      LanguageTag: "sr-latn",
      LanguageName: "Serbian (Latin)"
    },
    {
      isDefault: false,
      LanguageTag: "sk",
      LanguageName: "Slovak"
    },
    {
      isDefault: false,
      LanguageTag: "sl",
      LanguageName: "Slovenian"
    },
    {
      isDefault: false,
      LanguageTag: "es",
      LanguageName: "Spanish"
    },
    {
      isDefault: false,
      LanguageTag: "sv",
      LanguageName: "Swedish"
    },
    {
      isDefault: false,
      LanguageTag: "ty",
      LanguageName: "Tahitian"
    },
    {
      isDefault: false,
      LanguageTag: "ta",
      LanguageName: "Tamil"
    },
    {
      isDefault: false,
      LanguageTag: "te",
      LanguageName: "Telugu"
    },
    {
      isDefault: false,
      LanguageTag: "th",
      LanguageName: "Thai"
    },
    {
      isDefault: false,
      LanguageTag: "to",
      LanguageName: "Tongan"
    },
    {
      isDefault: false,
      LanguageTag: "tr",
      LanguageName: "Turkish"
    },
    {
      isDefault: false,
      LanguageTag: "uk",
      LanguageName: "Ukrainian"
    },
    {
      isDefault: false,
      LanguageTag: "ur",
      LanguageName: "Urdu"
    },
    {
      isDefault: false,
      LanguageTag: "vi",
      LanguageName: "Vietnamese"
    },
    {
      isDefault: false,
      LanguageTag: "cy",
      LanguageName: "Welsh"
    },
    {
      isDefault: false,
      LanguageTag: "yua",
      LanguageName: "Yucatec Maya"
    }
  ]
};

type region = {
  nativeName: string;
  nativeLanguageCode: string;
  languageName: string;
  regionCode: string;
  languageCode: string;
  regionName: string;
};

export const wpSupportedLanguages = [
  "English (United States)",
  "Danish (Denmark)",
  "Norwegian, Bokmål (Norway)",
  "Norwegian, Nynorsk (Norway)",
  "Dutch (Netherlands)"
];

export function getLocalizationLanguages() {
  const tempLanguages: region[] = [];

  LocalizationLanguages.languages.forEach((language) => {
    if (
      tempLanguages.filter((x) => x.nativeLanguageCode === language.code).length === 0 &&
      wpSupportedLanguages.indexOf(language.name) !== -1
    ) {
      const region: region = {
        nativeName: language.name,
        nativeLanguageCode: language.code,
        languageName: language.name.split("(")[0]
          ? language.name.split("(")[0].trim()
          : language.name,
        regionName: language.name.split("(")[1]
          ? language.name.split("(")[1].trim().replace(")", "")
          : language.name,
        languageCode: language.code.split("-")[0]
          ? language.code.split("-")[0].trim()
          : language.code,
        regionCode: language.code.split("-")[1] ? language.code.split("-")[1].trim() : language.code
      };
      tempLanguages.push(region);
    }
  });

  tempLanguages.sort((a, b) =>
    (a.nativeName || "").toUpperCase() > (b.nativeName || "").toUpperCase() ? 1 : -1
  );
  return tempLanguages;
}
