import { OpenAPIV2 } from "openapi-types";
import { ProcessParameterProperty } from "@workpoint/components/lib/models/ProcessConfiguration";
import { ParameterPropertyControlParams } from "../components/common/parametersForm/ParameterPropertyControl";
import { FormControlType } from "../components/common/parametersForm/ParametersForm";
import { isProcessExpressionModel } from "./processUtils";

export const getCustomizedControlParams = (
  definition: OpenAPIV2.Parameter,
  name: string,
  propertyType: ProcessParameterProperty
): Partial<ParameterPropertyControlParams> => {
  return {
    controlType: getFormControlType(definition),
    hideConditions: !isProcessExpressionModel(definition)
  };
};

export const getFormControlType = (definition: OpenAPIV2.Parameter) => {
  if (isProcessExpressionModel(definition)) {
    return FormControlType.ValuePicker;
  }
  if (definition?.["enum"]) {
    return FormControlType.Dropdown;
  }

  if (
    definition?.["x-workpoint-control"]?.type === FormControlType.RichText ||
    definition?.["x-workpoint-control"]?.type === FormControlType.PlainText ||
    definition?.["x-workpoint-control"]?.type === FormControlType.Boolean ||
    definition?.["x-workpoint-control"]?.type === FormControlType.ValuePicker
  ) {
    return definition?.["x-workpoint-control"]?.type;
  }

  if (definition?.["x-ms-dynamic-values"] || definition?.["x-ms-dynamic-schema"])
    return FormControlType.Dropdown;

  switch (definition.type) {
    case "array":
      return FormControlType.Dropdown;
    case "boolean":
      return FormControlType.Boolean;
    case "integer":
      return FormControlType.Number;
    case "string":
      return FormControlType.Text;
    default:
      return FormControlType.Text;
  }
};
