import { useEffect, useMemo } from "react";
import { RequestState } from "../models/RequestState";
import {
  fetchNavigationForSolution,
  navigationNavStackSelector,
  navigationSelector,
  navigationStatusSelector,
  setNavStack
} from "../store/navigationReducer";
import { RootState } from "../store/store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { INavigationEntry } from "../models/Navigation";
import { cloneDeep } from "lodash";
import { globalSelector, setspHostUrl } from "../store/globalReducer";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes, ToRoute } from "../routes";
import { getSPHostUrl } from "../utils/commonUtils";

export function useNavigation() {
  const { spHostUrl } = useAppSelector(globalSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const status = useAppSelector((state: RootState) => navigationStatusSelector(state, spHostUrl!));

  const adminMenu = useAppSelector((state: RootState) => navigationSelector(state, spHostUrl!));

  const navStack = useAppSelector((state: RootState) =>
    navigationNavStackSelector(state, spHostUrl!)
  );

  /**
   * It's neccessary for us to clone the menu,
   * as we need to let the FluentUI <Nav> element manipulate the 'isExpanded' dynamic property, that redux makes immutable.
   *
   * We do not care about stable menu items as they are instance loaded. IE. we do not save the manipulated state of the menu.
   */
  const menuItems = cloneDeep(adminMenu?.menuItems ?? []);

  /**
   * Initial load
   */
  useEffect(() => {
    if (status === undefined && typeof spHostUrl === "string" && spHostUrl.trim() !== "") {
      dispatch(fetchNavigationForSolution());
    }
  }, [status, spHostUrl]);

  /**
   * Solution url has changed
   */
  useEffect(() => {
    const currentHostUrl = getSPHostUrl();
    if (
      status !== undefined &&
      typeof spHostUrl === "string" &&
      spHostUrl.trim() !== "" &&
      spHostUrl !== currentHostUrl
    ) {
      dispatch(fetchNavigationForSolution());
      dispatch(setspHostUrl(currentHostUrl ?? ""));
    }
  }, [spHostUrl]);

  // derive status booleans for ease of use
  const menuIsLoading = status === RequestState.pending; // || status === undefined
  const menuIsError = status === RequestState.rejected;
  const menuIsSuccess = status === RequestState.fulfilled;
  const unauthorized = status === RequestState.unauthorized;

  if (unauthorized) navigate(ToRoute.unauthorized());

  const menuReady =
    menuIsSuccess && Array.isArray(menuItems) && menuItems.length > 0 && !menuIsError;

  const memoizedFlattenedMenuItems = useMemo(
    () => buildFlattenedMenuItems(menuReady ? menuItems : []),
    [menuItems, spHostUrl, menuReady]
  );

  useEffect(() => {
    if (menuReady && location.pathname && location.pathname.indexOf(AppRoutes.Configure) !== -1) {
      const activeNav = buildNavStack(menuItems, location.pathname, adminMenu?.rootLink);
      dispatch(setNavStack(activeNav ? activeNav : []));
    }
  }, [menuReady, location.pathname]);

  // return the import data for the caller of the hook to use
  return {
    menuItems,
    flattenedMenuItems: memoizedFlattenedMenuItems as INavigationEntry[],
    menuReady,
    menuIsLoading,
    menuIsError,
    navStack: navStack,
    activeNav: [...navStack].pop()
  };
}

const buildFlattenedMenuItems = (menuItems: INavigationEntry[]) => {
  if (Array.isArray(menuItems) && menuItems.length > 0)
    return menuItems.reduce(flattenMenuItemsWithSubText, []);
  else return [];
};

const flattenMenuItemsWithSubText = (collector: any, currentValue: any): any[] => {
  let newMenuItem: any = {};

  Object.keys(currentValue).forEach(function (keyName) {
    if (keyName !== "links") {
      // Copy value directly.
      newMenuItem[keyName] = currentValue[keyName];
    }
  });

  let secondaryText = newMenuItem.name;

  if (typeof newMenuItem.secondaryText === "string" && newMenuItem.secondaryText !== "")
    secondaryText = `${newMenuItem.secondaryText} > ${secondaryText}`;

  collector.push(newMenuItem);
  if (Array.isArray(currentValue.links)) {
    return currentValue.links
      .map((l: any) => ({ ...l, secondaryText }))
      .reduce(flattenMenuItemsWithSubText, collector);
  }
  return collector;
};

const buildNavStack = (
  menuItems: INavigationEntry[],
  path: string,
  rootLink?: INavigationEntry
) => {
  let match = path.split("/");
  let navStack: INavigationEntry[] = [];

  if (rootLink) navStack.push(rootLink);
  while (match.length > 0) {
    const pathMatch = match.join("/");
    const foundNav = getNavRecursive(menuItems, pathMatch, rootLink);
    if (foundNav.length > 0) navStack.push(...foundNav);
    if (navStack.length > 1) return navStack;
    match.pop();
  }
  return navStack;
};

const getNavRecursive = (
  menuItems: INavigationEntry[],
  path: string,
  parent?: INavigationEntry
): INavigationEntry[] => {
  let foundMatch: INavigationEntry[] = [];
  for (const item of menuItems) {
    const navItem = parent ? { ...item, parent } : item;
    if (navItem.links) {
      foundMatch = getNavRecursive(navItem.links, path, navItem);
    }
    if (navItem.pivots) {
      const foundMatchA = getNavRecursive(navItem.pivots, path, navItem);
      if (foundMatchA.length > 0) {
        foundMatch = foundMatchA;
      }
    }
    if ((path && item.url.toLowerCase() === path.toLowerCase()) || foundMatch.length > 0) {
      foundMatch.splice(0, 0, navItem);
      return foundMatch;
    }
  }

  return foundMatch;
};
