import { Customizer, LayerHost, Stack, Text } from "@fluentui/react";
import { useState } from "react";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { evaluateProcessExpressionForDisplay } from "@workpoint/components/lib/helpers/expressionUtils";
import { ProcessType } from "@workpoint/components/lib/models/ProcessConfiguration";
import { AppRoutes } from "../../../../routes";
import { globalSelector } from "../../../../store/globalReducer";
import { useAppSelector } from "../../../../store/hooks";
import { processSelector } from "../../../../store/processReducer";
import { RowType } from "../../../../utils/processUtils";
import { GuidancePanel } from "../../../common/guidance/GuidancePanel";
import { detailsCommandBarHeight, sideNavWidth } from "../../../common/AppChrome";
import { NewStepPanel } from "../newRow/NewStepPanel";
import { NewTriggerPanel } from "../newRow/NewTriggerPanel";
import { ExpandableHeader } from "./ExpandableHeader";
import { StepList } from "./StepList";
import { TriggerList } from "./TriggerList";

const LeftSide = () => {
  return (
    <>
      <StyledContainer>
        <Routes>
          <Route path={"*"}>
            <Route path={AppRoutes.ProcessNewStep} element={<NewStepPanel />} />
            <Route path={AppRoutes.ProcessNewStep1} element={<NewStepPanel />} />
            <Route path={AppRoutes.ProcessNewTrigger} element={<NewTriggerPanel />} />
            <Route path={AppRoutes.ProcessNewTrigger1} element={<NewTriggerPanel />} />
          </Route>
        </Routes>
        <Routes>
          <Route path={AppRoutes.ProcessStep} element={<List />} />
          <Route path={AppRoutes.ProcessTrigger} element={<List />} />
          <Route path={"*"} element={<List />} />
        </Routes>
      </StyledContainer>
    </>
  );
};

const List = () => {
  const [expandStepList, setExpandStepList] = useState<boolean>(true);
  const [expandTriggerList, setExpandTriggerList] = useState<boolean>(true);
  const params = useParams();
  const location = useLocation();
  const rowIndex = params.rowIndex as unknown as number;
  const { showGuidance } = useAppSelector(globalSelector);
  const { process, document } = useAppSelector(processSelector);

  let stepName: string | undefined;

  if (location.pathname.includes(RowType.Step)) {
    stepName = process.configuration.steps[rowIndex]?.definition;
  } else if (location.pathname.includes(RowType.Trigger)) {
    stepName = process.configuration.triggers?.[rowIndex]?.type;
  }

  return (
    <Stack
      style={{
        padding: "10px 0px 10px 10px"
      }}
    >
      <StyledHeader title={evaluateProcessExpressionForDisplay(process.configuration.title, {})} />
      {process.type === ProcessType.System && (
        <ExpandableHeader
          title={"Triggers"}
          isExpanded={expandTriggerList}
          toggleExpand={() => setExpandTriggerList(!expandTriggerList)}
        >
          <TriggerList />
          <br />
        </ExpandableHeader>
      )}
      <ExpandableHeader
        title={"Steps"}
        isExpanded={expandStepList}
        toggleExpand={() => setExpandStepList(!expandStepList)}
      >
        <StepList />
      </ExpandableHeader>
      {showGuidance && rowIndex > -1 && (
        <Customizer scopedSettings={{ Layer: { hostId: "leftLayerId" } }}>
          <GuidancePanel document={document} operationId={stepName} />
        </Customizer>
      )}
      {showGuidance && rowIndex > -1 && <LeftSideLayerHost id={"leftLayerId"} />}
    </Stack>
  );
};

const Header = ({ className, children, title }: any) => (
  <div className={className}>
    <StyledText variant="xLarge">{title}</StyledText>
  </div>
);

const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
`;

const StyledText = styled(Text)`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0px 30px 0px;
`;

const LeftSideLayerHost = styled(LayerHost)`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 500px;
`;

const StyledContainer = styled.div`
  padding: 0px;
  background-color: #f5f9fb;
  position: absolute;
  top: ${detailsCommandBarHeight}px;
  bottom: 0px;
  left: ${sideNavWidth}px;
  right: 50%;
  overflow: auto;

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #cfcfcf;
    border-radius: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #adadad;
  }
`;

export default LeftSide;
