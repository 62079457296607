import { FC } from "react";
import { Controller } from "react-hook-form";
import { IDropdownProps } from "@fluentui/react";
import { FormControlProps } from "./ControlledTextField";
import { EmailScheduleButton } from "./EmailSchedule";

export const ControlledHorizontalPicker: FC<FormControlProps & IDropdownProps> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || props.options[0].key}
      render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => {
        const changeCallback = (value: string | number) => {
          onChange(value);
          onBlur();
        };

        return (
          <>
            {props?.options.map((o) => (
              <EmailScheduleButton
                key={o.key}
                checked={o.key === value}
                className="buttons"
                text={o.text}
                onClick={() => changeCallback(o.key)}
              />
            ))}
          </>
        );
      }}
    />
  );
};
