import {
  FontIcon,
  IconButton,
  IStackStyles,
  Separator,
  Stack,
  Image,
  Text,
  ImageFit,
  ISpinnerStyles,
  Spinner,
  SpinnerSize
} from "@fluentui/react";
import React from "react";
import { WorkPointTheme } from "@workpoint/components/lib/constants";
import { useBoolean } from "@fluentui/react-hooks";

interface IBusinessModule {
  title: string;
  status: IStatus;
  iconName: string;
  features: IBMFeature[];
  lastSynced: Date;
  lastModified: Date;
  siteCollectionBehavior: string;
  entities: number;
}

interface IBMFeature {
  title: string;
  status: IStatus;
}

export enum SiteCollectionBehavior {
  SingleSite = "Single Site",
  MultiSite = "Multi Site",
  OneSite = "One Site"
}

export enum IStatus {
  Pending = "Pending",
  Published = "Published",
  Warning = "Warning",
  Deleting = "Deleting"
}

export const tileWidth = "23%";

export const TileCard = (props: { bm: any }) => {
  const bm = mockBusinessModule;

  const [hovering, { toggle: toggleHovering }] = useBoolean(false);

  return (
    <div
      onMouseEnter={toggleHovering}
      onMouseLeave={toggleHovering}
      style={{
        width: tileWidth,
        minWidth: "200px"
      }}
    >
      <div style={{ boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.1)" }}>
        <CardHeader bm={props.bm} />
        <CardDescription bm={bm} />
      </div>
      <CardHover bm={bm} hovered={hovering} />
    </div>
  );
};

const CardHeader = (props: { bm: any }) => {
  const cardHeader: Partial<IStackStyles> = {
    root: {
      height: `110px`,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: `${WorkPointTheme.palette.themeLighter}`
    }
  };

  return (
    <Stack styles={cardHeader} tokens={{ childrenGap: 8 }}>
      <FontIcon
        style={{ fontSize: "35px", color: `${WorkPointTheme.palette.themePrimary}` }}
        iconName={props.bm.iconName}
      />
      <Image imageFit={ImageFit.cover} src={props.bm.IconUrl} width={40} height={40} />
      <Text
        title={props.bm.Title}
        variant={"large"}
        style={{
          textOverflow: "ellipsis",
          maxWidth: "100%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          fontWeight: "600",
          padding: "6px"
        }}
      >
        {props.bm.Title}
      </Text>
    </Stack>
  );
};

const CardDescription = (props: { bm: any }) => {
  const cardContent: Partial<IStackStyles> = {
    root: {
      height: `44px`,
      justifyContent: "space-between",
      alignItems: "center"
    }
  };

  return (
    <Stack styles={cardContent} horizontal tokens={{ padding: 8 }}>
      <Stack horizontal tokens={{ childrenGap: 8 }} style={{ alignItems: "center" }}>
        {renderStatusIcon(props.bm.status)}
        <Text variant={"smallPlus"}>{props.bm.status}</Text>
      </Stack>
      <IconButton iconProps={{ iconName: "Edit" }} />
    </Stack>
  );
};

const CardHover = (props: { bm: any; hovered: boolean }) => {
  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        zIndex: "1000"
      }}
    >
      {props.hovered && (
        <Stack
          style={{
            width: "100%",
            padding: "12px",
            position: "absolute",
            boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.1)",
            borderBottomRightRadius: "5px",
            borderBottomLeftRadius: "5px",
            backgroundColor: WorkPointTheme.palette.white
          }}
        >
          <StatusList features={props.bm.features} />
          <DescriptionRow
            iconName={"SyncStatus"}
            description={`Last synced ${props.bm.lastSynced.toLocaleString()}`}
          />
          <DescriptionRow
            iconName={"Edit"}
            description={`Last edited ${props.bm.lastModified.toLocaleString()}`}
          />
          <DescriptionRow
            iconName={"RowsChild"}
            description={`Parent / Child / ${props.bm.title}`}
          />
          <DescriptionRow
            iconName={"BranchFork2"}
            description={`${props.bm.siteCollectionBehavior}`}
          />
          <DescriptionRow
            iconName={"FabricDataConnectionLibrary"}
            description={`${props.bm.entities} entities`}
          />
        </Stack>
      )}
    </div>
  );
};

const StatusList = (props: { features: any[] }) => {
  return (
    <Stack horizontal style={{ justifyContent: "space-between" }} tokens={{ childrenGap: 16 }}>
      <Stack tokens={{ childrenGap: 16 }}>
        {props.features.map((feature: any, index: number) => {
          if (index < props.features.length / 2)
            return <BusinessModuleStatus key={`${index}_${feature.title}`} feature={feature} />;
        })}
      </Stack>
      <Stack tokens={{ childrenGap: 16 }}>
        {props.features.map((feature: any, index: number) => {
          if (index >= props.features.length / 2)
            return <BusinessModuleStatus key={`${index}_${feature.title}`} feature={feature} />;
        })}
      </Stack>
    </Stack>
  );
};

const BusinessModuleStatus = (props: { feature: any }) => {
  return (
    <Stack
      key={`${props.feature.title}`}
      horizontal
      tokens={{ childrenGap: 8 }}
      style={{ alignItems: "center" }}
    >
      {renderStatusIcon(props.feature.status)}
      <Text variant={"smallPlus"}>{props.feature.title}</Text>
    </Stack>
  );
};

const DescriptionRow = (props: { iconName: string; description: string }) => {
  return (
    <>
      <Separator />
      <Stack horizontal style={{ alignItems: "center" }} tokens={{ childrenGap: 8 }}>
        <FontIcon iconName={props.iconName} style={{ fontSize: "18px" }} />
        <Text>{props.description}</Text>
      </Stack>
    </>
  );
};

export const renderStatusIcon = (status: IStatus) => {
  let iconName: string = "";
  let backgroundColor: string = "";

  const spinnerStyle: Partial<ISpinnerStyles> = {
    circle: {
      borderWidth: "3px",
      borderRightColor: WorkPointTheme.palette.neutralLight,
      borderLeftColor: WorkPointTheme.palette.neutralLight,
      borderBottomColor: WorkPointTheme.palette.neutralLight
    }
  };

  switch (status) {
    case IStatus.Published:
      iconName = "SkypeCheck";
      backgroundColor = WorkPointTheme.palette.green;
      break;
    case IStatus.Warning:
      iconName = "Important";
      backgroundColor = WorkPointTheme.palette.red;
      break;
    case IStatus.Pending:
      backgroundColor = WorkPointTheme.palette.neutralLight;
      break;
    case IStatus.Deleting:
      return <Spinner styles={spinnerStyle} size={SpinnerSize.small} />;
  }

  return (
    <FontIcon
      iconName={iconName}
      style={{
        height: "10px",
        width: "10px",
        fontSize: "10px",
        color: WorkPointTheme.palette.white,
        borderRadius: "100%",
        backgroundColor: backgroundColor,
        padding: "4px"
      }}
    />
  );
};

const feature: IBMFeature = {
  title: "Staging",
  status: IStatus.Published
};

const feature2: IBMFeature = {
  title: "Field Setup",
  status: IStatus.Published
};

const feature3: IBMFeature = {
  title: "Security",
  status: IStatus.Warning
};

const feature4: IBMFeature = {
  title: "Aggregration",
  status: IStatus.Published
};

const feature5: IBMFeature = {
  title: "Inheritance",
  status: IStatus.Pending
};

const feature6: IBMFeature = {
  title: "Template",
  status: IStatus.Pending
};

const mockBusinessModule: IBusinessModule = {
  title: "Projects",
  status: IStatus.Deleting,
  iconName: "Document",
  features: [feature, feature2, feature3, feature4, feature5, feature6],
  lastSynced: new Date(),
  lastModified: new Date(),
  siteCollectionBehavior: SiteCollectionBehavior.OneSite,
  entities: 9999
};
