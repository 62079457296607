import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { FormControlProps } from "./ControlledTextField";
import {
  RoosterjsField,
  RoosterjsFieldProps
} from "@workpoint/components/lib/components/form/roosterjs/controls/RoosterjsField";
import { Callout, Label } from "@fluentui/react";
import RibbonButtonType from "@workpoint/components/lib/components/form/roosterjs/controls/ribbon/RibbonButtonType";
import { ContextPanel } from "../parametersForm/ContextPanel";
import { UseFormSetValue } from "react-hook-form";
import { useState } from "react";
import { ExpressionPlugin } from "../ExpressionPlugin";
import { ContextBrowser } from "../ContextBrowser";
import { ProcessParameterProperty } from "@workpoint/components/lib/models/ProcessConfiguration";
import { v4 as uuid } from "uuid";
import { FieldErrorMessage } from "@workpoint/components/lib/components/form/inputs/FieldErrorMessage";
import { useIntl } from "react-intl";

type ControlledRichTextProps = FormControlProps &
  RoosterjsFieldProps & {
    label?: string;
    disabled?: boolean;
    required?: any;
    isPlainText?: boolean;
    setValue: UseFormSetValue<any>;
    contextPanelName: string;
    onLoadValue?: (value: string) => string;
    onResolveChangedValue?: (value: string) => string;
  };

export const ControlledRichText: FC<ControlledRichTextProps> = (props) => {
  const [isOpenContextPanel, setOpenContextPanel] = useState<boolean>(false);
  const [oldExpressionTag, setOldExpressionTag] = useState<string>("");

  const [expressionTag, setExpressionTag] = useState<string>("");
  const [initialExpressionValue, setInitialExpressionValue] = useState<string>();

  const [isContextBrowserVisible, setIsContextBrowserVisible] = useState<boolean>(false);

  const intl = useIntl();

  const onClose = (oldValue?: string) => {
    if (oldValue) setExpressionTag(oldValue);

    setOpenContextPanel(false);
  };

  const onUpdate = (newValue: string) => {
    setExpressionTag(newValue);
    setOpenContextPanel(false);
  };

  const onClick = (expression: string): void => {
    setOldExpressionTag(expression);
    setIsContextBrowserVisible(true);
  };

  const onOpenPanel = async (expression: string, initialValue?: string) => {
    setOldExpressionTag(expression);
    setInitialExpressionValue(initialValue);
    setOpenContextPanel(true);
  };

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || ""}
      render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => {
        const loadingValue = props.onLoadValue ? props.onLoadValue(value) : value;

        let addtionalPlugins: any = {
          expression: new ExpressionPlugin(
            onClick,
            onOpenPanel,
            loadingValue,
            (newValue: any) => {
              const savingValue = props.onResolveChangedValue
                ? props.onResolveChangedValue(newValue)
                : newValue;

              onChange(savingValue);
              if (props.onChange) props.onChange(savingValue);
            },
            () => setIsContextBrowserVisible(false),
            props.rows === 1,
            props.isPlainText
          )
        };

        let buttons: { [key: string]: RibbonButtonType } = props.isPlainText
          ? {}
          : {
              expression: {
                title: "Add expression",
                image: "CodeEdit",
                onClick: (editor, value) => {
                  onOpenPanel("");
                }
              }
            };

        const targetId = `targetId-${uuid()}`;

        return (
          <div style={{ width: "100%", flex: "1" }}>
            {props.label && (
              <Label htmlFor={props.name} required={props.required}>
                {props.label}
              </Label>
            )}
            {/* <DefaultButton id="targetID" /> */}
            <div
              id={targetId}
              style={props.disabled ? { pointerEvents: "none", opacity: "0.4" } : {}}
            >
              <RoosterjsField
                id={props.name}
                value={loadingValue}
                onBlur={onBlur}
                text={expressionTag}
                onChange={(newValue: any) => {
                  const savingValue = props.onResolveChangedValue
                    ? props.onResolveChangedValue(newValue)
                    : newValue;

                  onChange(savingValue);
                  if (props.onChange) props.onChange(savingValue);

                  setExpressionTag("");
                }}
                additionalPlugins={!props.disabled && (addtionalPlugins ?? [])}
                buttons={buttons}
                isPlainText={props.isPlainText}
                rows={props.rows ?? 5}
                disabled={props.disabled}
                intl={intl as any}
              />
            </div>
            {isContextBrowserVisible && (
              <Callout
                role="dialog"
                gapSpace={0}
                target={`#${targetId}`}
                onDismiss={() => setIsContextBrowserVisible(false)}
              >
                <ContextBrowser
                  name={props.name}
                  control={props.control}
                  trigger={{} as any}
                  defaultValue={props.defaultValue}
                  isLookupType={false}
                  allowMulti={false}
                  definition={{} as any}
                  options={undefined}
                  propertyType={ProcessParameterProperty.Value}
                  required={false}
                  setValue={props.setValue}
                  SearchResults={{} as any}
                  onUpdate={onUpdate}
                  onOpenPanel={onOpenPanel}
                  showAdvancedTab
                />
              </Callout>
            )}

            {isOpenContextPanel && (
              <ContextPanel
                name={props.contextPanelName}
                control={props.control}
                onClose={onClose}
                setValue={props.setValue}
                onUpdate={onUpdate}
                oldValue={oldExpressionTag}
                initialValue={initialExpressionValue}
              />
            )}
            {error && error.message && <FieldErrorMessage>{error.message}</FieldErrorMessage>}
          </div>
        );
      }}
    />
  );
};
