import {
  DetailsList,
  DetailsRow,
  IDetailsListProps,
  IDragDropContext,
  IDragDropEvents,
  SelectionMode,
  Selection,
  IColumn
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { evaluateProcessExpressionForDisplay } from "@workpoint/components/lib/helpers/expressionUtils";
import {
  ProcessStepConfiguration,
  ProcessTrigger
} from "@workpoint/components/lib/models/ProcessConfiguration";
import { ProcessValidationMessage } from "../../../../models/ProcessValidation";
import { useAppSelector } from "../../../../store/hooks";
import { processSelector } from "../../../../store/processReducer";
import { RowType } from "../../../../utils/processUtils";
import { AddStepButton } from "./AddStepButton";
import { Step } from "./Step";

export interface IItemListProps {
  items: ProcessStepConfiguration[] | ProcessTrigger[];
  updateItems(steps: ProcessStepConfiguration[] | ProcessTrigger[]): void;
  openNewItemPanel: (index: string) => void;
  editItem: (index: string) => void;
  rowType: RowType;
}

export const ListBase = (props: IItemListProps) => {
  const { rowIndex } = useParams() as any;
  const selection: Selection = new Selection();
  const { items, updateItems: updateSteps, openNewItemPanel, editItem, rowType } = props;
  const [activeStep, setActiveStep] = useState<string | undefined>(undefined);
  const { validationMessages, process } = useAppSelector(processSelector);
  const location = useLocation();

  let draggedStep: ProcessStepConfiguration | undefined;
  let _draggedIndex: number;

  useEffect(() => {
    setActiveStep(rowIndex);
  }, [rowIndex]);

  const getDragDropEvents = (
    items: any[],
    selection: Selection,
    updateSteps: (steps: ProcessStepConfiguration[]) => void
  ): IDragDropEvents => {
    return {
      canDrop: (dropContext?: IDragDropContext, dragContext?: IDragDropContext) => {
        return true;
      },
      canDrag: (item?: any) => {
        return true;
      },
      onDragEnter: (item?: any, event?: DragEvent) => {
        // return string is the css classes that will be added to the entering element.
        return "";
      },
      onDragLeave: (item?: any, event?: DragEvent) => {
        return;
      },
      onDrop: (item?: any, event?: DragEvent) => {
        if (draggedStep) {
          _insertBeforeItem(items, item, selection, updateSteps);
        }
      },
      onDragStart: (item?: any, itemIndex?: number, selectedItems?: any[], event?: MouseEvent) => {
        draggedStep = item;
        _draggedIndex = itemIndex!;
      },
      onDragEnd: (item?: any, event?: DragEvent) => {
        draggedStep = undefined;
        _draggedIndex = -1;
      }
    };
  };

  const _insertBeforeItem = (
    items: any[],
    step: any,
    selection: Selection,
    updateSteps: (steps: ProcessStepConfiguration[]) => void
  ): void => {
    const draggeditems = selection.isIndexSelected(_draggedIndex)
      ? (selection.getSelection() as ProcessStepConfiguration[])
      : [draggedStep!];

    const insertIndex = items!.indexOf(step);
    const steps = items?.filter((itm) => draggeditems.indexOf(itm) === -1);

    steps?.splice(insertIndex, 0, ...draggeditems);

    updateSteps(steps);
  };

  const _dragDropEvent: IDragDropEvents = getDragDropEvents(items, selection, updateSteps);

  const column: IColumn = {
    key: "",
    name: "",
    minWidth: 300
  };

  const _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    if (props) {
      props.dragDropEvents = getDragDropEvents(items, selection, updateSteps);
      props.styles = {
        cell: {
          paddingTop: 0,
          paddingBottom: 0
        },
        root: {
          backgroundColor: "transparent !important"
        }
      };
    }

    return (
      <DetailsRow
        {...props}
        item={props?.item}
        itemIndex={props?.itemIndex!}
        onRenderItemColumn={_onRenderItemColumn}
      />
    );
  };

  const _onRenderItemColumn = (
    stepConfig: ProcessStepConfiguration | ProcessTrigger,
    index?: number,
    column?: IColumn
  ) => {
    // Use from swagger?
    let subText: string | undefined;
    if (rowType === RowType.Trigger) {
      subText = process.configuration.triggers?.[index!]?.type;
    } else {
      subText = evaluateProcessExpressionForDisplay(
        process.configuration.steps?.[index!]?.description
      );
    }
    return (
      <Step
        stepConfig={stepConfig}
        index={index!}
        lastItem={items.length === index! + 1}
        addStep={() => openNewItemPanel(index!.toString())}
        editStep={() => editItem(index!.toString())}
        active={index?.toString() === activeStep && location.pathname.includes(rowType)}
        hasError={
          validationMessages.findIndex(
            (m: ProcessValidationMessage) => m.stepId === stepConfig.id
          ) >= 0
        }
        rowType={rowType}
        subText={subText}
      />
    );
  };

  return items?.length > 0 ? (
    <DetailsList
      compact={true}
      columns={[column]}
      items={items}
      setKey="Items"
      onRenderRow={_onRenderRow}
      selectionMode={SelectionMode.none}
      dragDropEvents={_dragDropEvent}
      isHeaderVisible={false}
    />
  ) : (
    <AddStepButton openNewStepModal={() => openNewItemPanel("0")} rowType={rowType} />
  );
};
