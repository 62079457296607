import { OpenAPIV2 } from "openapi-types";
import * as React from "react";
import { Link, Text } from "@fluentui/react";
import styled from "styled-components";
import { WorkPointTheme } from "@workpoint/components/lib/constants";
import { getStepType } from "../../../../utils/processUtils";
import { getArticleBody } from "../../../../utils/guidanceUtils";
import { useEffect, useState } from "react";
import { parseHtmlForNewTabLinks } from "@workpoint/components/lib/helpers/expressionUtils";

interface INewAddStepDetailProps {
  item: OpenAPIV2.OperationObject;
}

export const NewAddStepDetails = (props: INewAddStepDetailProps) => {
  const [body, setBody] = useState<any>();

  useEffect(() => {
    async function fetchBody() {
      const articleBody = await getArticleBody(props.item?.externalDocs?.url);
      setBody(parseHtmlForNewTabLinks(articleBody));
    }

    fetchBody();
  });

  return (
    <>
      <StyledContainer>
        <StyledStepInfo
          title={props.item.summary}
          subTitle={getStepType((props.item as any)["x-workpoint-type"])}
        />
        <>
          {body === undefined && props.item.description && (
            <Text variant={"medium"}>{props.item.description}</Text>
          )}
          {body === undefined && props.item.externalDocs !== undefined && (
            <p>
              <Link href={props.item.externalDocs!.url} target="_blank">
                {props.item.externalDocs.description ?? props.item.externalDocs.url}
              </Link>
            </p>
          )}
          {body !== undefined && <div dangerouslySetInnerHTML={{ __html: body }}></div>}
        </>
      </StyledContainer>
    </>
  );
};

const StyledStepText = ({ className, title, subTitle }: any) => (
  <div className={className}>
    <Text variant={"xLarge"} className={"title"}>
      {title}
    </Text>
    <Text variant={"large"} className={"type"}>
      {subTitle}
    </Text>
  </div>
);

const StyledStepInfo = styled(StyledStepText)`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  .title {
    color: ${WorkPointTheme.palette.blue};
    margin: 0;
  }

  .type {
    color: ${WorkPointTheme.palette.neutralTertiary};
    margin: 0;
  }
`;

const StyledContainer = styled.div`
  background-color: ${WorkPointTheme.palette.neutralLighterAlt};
  padding: 0px 32px;
  height: 100%;
  div > * {
    margin-bottom: 16px;
  }
`;
