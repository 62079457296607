import React from "react";
import { useIntl } from "react-intl";
import { ContentPadding } from "./AppChrome";

export const NotFound: React.FC = () => {
  const intl = useIntl();
  return (
    <ContentPadding>
      <p>
        {intl.formatMessage({
          id: "notfound",
          defaultMessage: "The page you requested cannt be found."
        })}
      </p>
    </ContentPadding>
  );
};
