import { CommandButton, IDropdownOption, Pivot, PivotItem, Stack } from "@fluentui/react";
import { useApiClient } from "@workpoint/components/lib/clients/ApiProvider";
import { ParameterPropertyControlParams } from "./parametersForm/ParameterPropertyControl";
import { useWatch } from "react-hook-form";
import theme from "@workpoint/components/lib/constants";
import { ApiClient } from "@workpoint/components/lib/clients/ApiClient";
import { ObjectBrowser } from "./parametersForm/ObjectBrowser";
import { OptionsLink } from "./parametersForm/ControlledValuePicker";
import { globalSelector } from "../../store/globalReducer";
import { useAppSelector } from "../../store/hooks";
import { ConditionsAdvanced } from "./parametersForm/CondtionsAdvanced";
import { PropertyExpressionType } from "./parametersForm/ParametersForm";
import { useRef } from "react";

interface ContextBrowserProps {
  isLookupType: boolean;
  allowMulti: boolean;
  options: IDropdownOption[] | undefined;
  SearchResults: (props: {
    name: string;
    field: any;
    apiClient: ApiClient;
    onClick: (ITag: any) => void;
  }) => JSX.Element;
  onUpdate?: (input: string) => any;
  onOpenPanel?: (input: string, initialValue?: string) => void;
  showAdvancedTab?: boolean;
  contextExtension?: any;
}

export const ContextBrowser = (props: ParameterPropertyControlParams & ContextBrowserProps) => {
  const {
    name,
    control,
    setValue,
    definition,
    hideContextBrowser,
    isLookupType,
    allowMulti,
    options,
    SearchResults,
    showAdvancedTab,
    contextExtension
  } = props;
  const { apiClient } = useApiClient();
  const controlValue: any = useWatch({ control, name });
  const fieldType = definition["x-workpoint-field"]?.type ?? definition.dataType;
  const { context, loadingContext } = useAppSelector(globalSelector);
  const valueRef = useRef<string>();
  return (
    <div style={{ width: "300px", height: "400px" }}>
      <Pivot
        styles={{
          root: {
            backgroundColor: theme.palette.blue
          },
          link: {
            color: theme.palette.white
          },
          linkIsSelected: {
            color: theme.palette.white
          }
        }}
      >
        {isLookupType && (
          <PivotItem headerText="Value">
            <Stack style={{ maxHeight: "356px", overflow: "auto" }}>
              <SearchResults
                name={definition.name}
                apiClient={apiClient}
                field={definition["x-workpoint-field"] ?? { type: fieldType }}
                onClick={(value: IDropdownOption) => {
                  props.setValue(
                    name,
                    allowMulti
                      ? [
                          ...(Array.isArray(controlValue)
                            ? controlValue.filter((v) => v !== value)
                            : []),
                          value
                        ]
                      : value,
                    {
                      shouldValidate: true
                    }
                  );
                }}
              />
            </Stack>
          </PivotItem>
        )}
        {options !== undefined && (
          <PivotItem headerText="Options">
            <Stack style={{ height: "356px", overflow: "auto" }}>
              {options!.map((option) => {
                return (
                  <OptionsLink
                    key={option.key}
                    onClick={() => {
                      setValue(
                        name,
                        allowMulti
                          ? [
                              ...(Array.isArray(controlValue)
                                ? controlValue.filter((v) => v !== option.key)
                                : []),
                              option.key
                            ]
                          : option.key,
                        {
                          shouldValidate: true
                        }
                      );
                    }}
                    title={option.text}
                  >
                    {option.text}
                  </OptionsLink>
                );
              })}
            </Stack>
          </PivotItem>
        )}
        {!hideContextBrowser && (
          <PivotItem headerText="Context Browser">
            <ObjectBrowser
              object={contextExtension ? { ...context, ...contextExtension } : context}
              loading={loadingContext}
              onSelect={(value: string, type?: string) => {
                let valToSet: any = value;
                if (allowMulti) {
                  valToSet = [
                    ...(Array.isArray(controlValue) ? controlValue.filter((v) => v !== value) : [])
                  ];

                  if (type === "Array") {
                    valToSet = value as any;
                  } else {
                    valToSet.push(value);
                  }
                }

                if (props.onUpdate) {
                  props.onUpdate(
                    `<span data-wpexpression="${valToSet}" contenteditable="false"><button /></span>`
                  );
                } else if (props.setValue) {
                  props.setValue(
                    name,
                    valToSet, //[{ key: value, name: value }],
                    {
                      shouldValidate: true
                    }
                  );
                }
              }}
            />
          </PivotItem>
        )}
        {showAdvancedTab && (
          <PivotItem headerText="Advanced">
            <CommandButton
              iconProps={{ iconName: "BullseyeTargetEdit" }}
              text="Update"
              onClick={() => {
                if (valueRef.current) {
                  const newValue = `<span data-wpexpression="${valueRef.current.replaceAll("\"", "'")}" contenteditable="false"><button /></span>`;
                  props.onUpdate && props.onUpdate(newValue);
                }
              }}
              // disabled={!valueRef.current}
            />
            <CommandButton
              iconProps={{ iconName: "MiniExpand" }}
              text="Expand"
              onClick={() => {
                props.onOpenPanel && props.onOpenPanel("", valueRef.current);
              }}
            />
            <ConditionsAdvanced
              initialValue={""}
              onChange={(myValue) => {
                valueRef.current = myValue;
              }}
              contextExtension={contextExtension}
            />
          </PivotItem>
        )}
      </Pivot>
    </div>
  );
};
