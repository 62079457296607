export const getArticleBody = async (url?: string) => {
  if (url) {
    try {
      if (url.toLowerCase().indexOf("/articles/") > 0) {
        const articleId = url.toLowerCase().split("/articles/")[1].split("-")[0];

        const articleApi = "https://support.workpoint.dk/api/v2/help_center/en-us/articles/";
        const resp = await fetch(articleApi + articleId);
        const data = await resp.json();
        return data.article.body;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  } else {
    return undefined;
  }
};
