import "codemirror/lib/codemirror.css"
import { UnControlled as CodeMirror } from "react-codemirror2"
import styled from "styled-components"

export const DemoPayload: React.FC<{ value: string }> = ({ value }) => {
    return (
        <>
            <style
                dangerouslySetInnerHTML={{
                    __html: ".demo-payload .CodeMirror { background-color: #efefef; } }"
                }}
            ></style>
            <StyledCodeMirror
                value={value}
                className="demo-payload"
                options={{
                    mode: "application/json",
                    lineNumbers: true,
                    lineWrapping: true,
                    tabMode: "indent",
                    matchBrackets: true,
                    indentUnit: 4,
                    line: true,
                    readOnly: true
                }}
            />
        </>
    )
}

const StyledCodeMirror = styled(CodeMirror)`
    border: 1px solid #edebe9;
`
