import { Link } from "@fluentui/react/lib/components/Link";
import { IOverflowSetItemProps, OverflowSet } from "@fluentui/react/lib/components/OverflowSet";
import { SearchBox } from "@fluentui/react/lib/components/SearchBox";
import { Spinner } from "@fluentui/react/lib/components/Spinner";
import { useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { useNavigation } from "../../hooks/useNavigation";
import { pageHeaderHeight, searchFilterHeight, sideNavWidth, tabHeight } from "./AppChrome";
import { RouterNav } from "./RouterNav";

const UnstyledSideNav: React.FC<{ className?: string }> = (props) => {
  const intl = useIntl();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [tab, setTab] = useState<string>("all");

  const { menuItems, flattenedMenuItems, menuIsLoading, menuReady, navStack } = useNavigation();

  let inEffectMenuItems = menuItems;

  const searchQueryInEffect = typeof searchQuery === "string" && searchQuery.trim() !== "";

  // Favorite view
  if (tab === "favorites") inEffectMenuItems = flattenedMenuItems.filter((fmi) => fmi.isFavorite);

  if (searchQueryInEffect) {
    inEffectMenuItems = flattenedMenuItems.filter((fmi) =>
      fmi.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  return (
    <div className={props.className}>
      <SearchBox
        placeholder={intl.formatMessage({
          id: "filter-navigation",
          defaultMessage: "Filter in navigation"
        })}
        iconProps={{ iconName: "Search" }}
        underlined={true}
        value={searchQuery}
        styles={{
          root: {
            height: searchFilterHeight,
            fontSize: 13,
            borderBottomColor: "#eee",
            paddingLeft: 20
          }
        }}
        onChange={(e, newValue) => setSearchQuery(newValue as string)}
      />
      <OverflowSet
        items={[
          {
            key: "all",
            name: intl.formatMessage({ id: "all", defaultMessage: "All" })
          },
          {
            key: "favorites",
            name: intl.formatMessage({
              id: "favorites",
              defaultMessage: "Favorites"
            })
          }
        ]}
        onRenderItem={(item: IOverflowSetItemProps): JSX.Element => {
          const active = item.key === tab;
          return (
            <Link
              role="menuitem"
              styles={{
                root: {
                  marginRight: 20,
                  fontSize: 12,
                  color: active ? "#333" : "grey"
                }
              }}
              onClick={(ev) => {
                setSearchQuery("");
                setTab(item.key);
              }}
            >
              {item.name}
            </Link>
          );
        }}
        onRenderOverflowButton={() => null}
        styles={{
          root: {
            boxSizing: "border-box",
            borderBottom: "1px solid",
            borderBottomColor: "#eee",
            padding: "10px 14px",
            paddingLeft: 30,
            height: tabHeight
          }
        }}
      />
      {!menuReady && menuIsLoading && <Spinner styles={{ root: { marginTop: 15 } }} />}
      {menuReady && searchQueryInEffect && inEffectMenuItems.length < 1 && (
        <NoResults>
          {intl.formatMessage({
            id: "noResults",
            defaultMessage: "No results"
          })}
        </NoResults>
      )}
      {menuReady && inEffectMenuItems.length > 0 && (
        <RouterNav menuItems={inEffectMenuItems} navStack={navStack} />
      )}
    </div>
  );
};

const NoResults = styled.p`
  text-align: center;
`;

export const SideNav = styled(UnstyledSideNav)`
  box-sizing: border-box;
  width: ${sideNavWidth}px;
  border-right: 1px solid #eee;
  position: sticky;
  top: 0;
  max-height: calc(100vh - ${pageHeaderHeight}px);
  overflow-y: auto;
`;
