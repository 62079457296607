import { RowType } from "../utils/processUtils";

export enum ValidationMessageType {
  Error = "Error",
  Warning = "Warning"
}

export interface ProcessValidationMessage {
  type: ValidationMessageType;
  message: string;
  stepId: string;
  rowType: RowType;
  parameterName?: string;
  formFieldName?: string;
}
