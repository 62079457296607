import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { DialogProps } from "../components/common/Dialog";
import { MessageBarType } from "@fluentui/react";

export type DialogState = {
  hidden: boolean;
  dialogProps?: DialogProps;
  message?: { type: MessageBarType; text: string };
};

export const initialState: DialogState = {
  hidden: true
};

const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    showDialog: (state, action: PayloadAction<DialogProps>) => {
      state.hidden = false;
      state.dialogProps = action.payload;
    },
    hideDialog: (state) => {
      state.hidden = true;
      state.dialogProps = undefined;
    },
    showMessage: (state, action: PayloadAction<{ type: MessageBarType; text: string }>) => {
      state.message = action.payload;
    },
    hideMessage: (state) => {
      state.message = undefined;
    }
  }
});

export const { showDialog, hideDialog, showMessage, hideMessage } = dialogSlice.actions;

export const dialogSelector = (state: RootState) => state.dialog;
const dialogReducer = dialogSlice.reducer;
export default dialogReducer;
