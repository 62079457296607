import {
  IColumn,
  DetailsListLayoutMode,
  CheckboxVisibility,
  DetailsRow
} from "@fluentui/react/lib/DetailsList";
import { Selection, Text, Stack, ShimmeredDetailsList } from "@fluentui/react";
import { useConst } from "@fluentui/react-hooks";
import React, { useEffect, useState } from "react";
import { IStatus, renderStatusIcon, SiteCollectionBehavior } from "./TileCard";
import { sortBusinessModulesByHierarchy } from "../../utils/commonUtils";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { globalSelector } from "../../store/globalReducer";
import { ToRoute } from "../../routes";

export const HierarchicalList = (props: { businessModules: any[] }) => {
  const [items, setItems] = useState<any[]>([]);
  const navigate = useNavigate();
  const { spHostUrl } = useAppSelector(globalSelector);

  useEffect(() => {
    /**
     * Missing data: Status, Last Synced, Last Modified, Site Collection Behavior
     */
    const tempMockPropertiess: any = {
      Status: IStatus.Published,
      LastSynced: new Date().toLocaleTimeString(),
      LastModified: new Date().toLocaleTimeString(),
      SiteCollectionBehavior: SiteCollectionBehavior.OneSite
    };

    const businessModules = props.businessModules.map((bm) => {
      return { ...bm, ...tempMockPropertiess };
    });
    const sortedModules = sortBusinessModulesByHierarchy(businessModules);

    setItems(sortedModules);
  }, [props.businessModules]);

  function _renderItemColumn(item?: any, index?: number, column?: IColumn) {
    switch (column!.key) {
      case "status":
        return (
          <Stack
            key={`${item.Title}`}
            horizontal
            tokens={{ childrenGap: 8 }}
            style={{ alignItems: "center" }}
          >
            {renderStatusIcon(item.Status)}
            <Text variant={"smallPlus"}>{item.Status}</Text>
          </Stack>
        );
      case "title":
        return (
          <span
            style={
              item.Indentation > 0
                ? { marginLeft: item["Indentation"] ? `${item.Indentation * 24}px` : "0px" }
                : { fontWeight: "bold" }
            }
          >
            {item[column?.fieldName!]}
          </span>
        );

      case "lastSynced":
      case "lastModified":
        return <span>{item[column?.fieldName!]}</span>;
      default:
        return <span>{item[column?.fieldName!]}</span>;
    }
  }

  const onRowClick = (item: any) => {
    /**
     * @todo: add necessary logic for dynamic paramters in NavigationEntry URLs and uncomment.
     */
    // navigate({
    //   pathname: `${ToRoute.adminBusinessModulePage("2")}`,
    //   search: `SPHostUrl=${spHostUrl}`
    // });
  };

  const selection = useConst(() => {
    const s = new Selection();
    s.setItems(items, true);
    return s;
  });

  return (
    <div>
      <ShimmeredDetailsList
        items={items}
        columns={columns}
        isHeaderVisible={true}
        onRenderRow={(props: any) => {
          return (
            <DetailsRow
              {...props}
              onClick={() => onRowClick(props.item)}
              styles={{ root: { cursor: "pointer" } }}
            />
          );
        }}
        checkboxVisibility={CheckboxVisibility.hidden}
        onRenderItemColumn={_renderItemColumn}
        setKey="HierarchyBMList"
        layoutMode={DetailsListLayoutMode.justified}
        selection={selection}
        selectionPreservedOnEmptyClick={true}
      />
    </div>
  );
};

const columns = [
  {
    key: "title",
    name: "Title",
    fieldName: "Title",
    minWidth: 100,
    maxWidth: 500,
    isResizable: true
  },
  {
    key: "status",
    name: "Status",
    fieldName: "Status",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true
  },
  {
    key: "siteCollectionBehavior",
    name: "Site Collection Behavior",
    fieldName: "SiteCollectionBehavior",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true
  },
  {
    key: "lastSynced",
    name: "Last Synced",
    fieldName: "LastSynced",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true
  },
  {
    key: "lastModified",
    name: "Last Modified",
    fieldName: "LastModified",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true
  }
];
