import React, { useEffect, useRef, useState } from "react";
import {
  CheckboxVisibility,
  ConstrainMode,
  DetailsListLayoutMode,
  ICommandBarItemProps,
  ITextField,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  Text,
  Selection,
  IContextualMenuItem,
  Spinner,
  SpinnerSize
} from "@fluentui/react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ProcessStatus } from "@workpoint/components/lib/models/ProcessConfiguration";
import { runDeleteDialog, runDisableDialog, runSaveAsDialog } from "../../../utils/processUtils";
import { ListView, ListViewActionHandlers } from "../../common/listView/ListView";
import { AppRoutes, ToRoute } from "../../../routes";
import { useIntl } from "react-intl";
import { Route, Routes, useNavigate, useParams } from "react-router";
import { adminSelector, loadPageItems } from "../../../store/adminReducer";
import { AdminParametersPanel } from "../details/AdminParametersPanel";
import { PageProps } from "../ConfigurePageControl";
import { useApiClient } from "@workpoint/components/lib/clients/ApiProvider";
import styled from "styled-components";
import { globalSelector } from "../../../store/globalReducer";

export const AdminList = (props: PageProps) => {
  const { pageId } = useParams();
  const dispatch = useAppDispatch();
  const { pageList, pageOpId } = useAppSelector(adminSelector);
  const { apiClient } = useApiClient();
  const inputEl = useRef<ITextField>(null);
  const intl = useIntl();
  const navigate = useNavigate();
  const { spHostUrl } = useAppSelector(globalSelector);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [searchText, setSearchText] = useState<string | undefined>();
  const { pageConfig } = props;

  const selection = new Selection({
    onSelectionChanged: () => {
      setSelectedItems(selection.getSelection());
    }
  });

  useEffect(() => {
    if (pageId && pageId !== pageOpId) {
      dispatch(loadPageItems(pageId));
    }
  }, [pageId]);

  const getContextualMenuItems = (actionHandlers: ListViewActionHandlers, item: any) => {
    const menuItems: IContextualMenuItem[] = [
      {
        key: "edit",
        iconProps: { iconName: "Edit" },
        name: intl.formatMessage({ id: "option-edit", defaultMessage: "Edit" }),
        data: item,
        onClick: _onClickEdit
      },
      {
        key: "delete",
        iconProps: { iconName: "Delete" },
        name: intl.formatMessage({ id: "option-delete", defaultMessage: "Delete" }),
        data: item,
        onClick: (
          ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
          item?: IContextualMenuItem
        ) => runDeleteDialog(dispatch, [item?.data], navigate, actionHandlers.onDelete)
      }
    ];

    return menuItems;
  };

  function _onClickEdit(
    ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    item?: IContextualMenuItem
  ) {
    if (pageId && item && pageConfig.operations?.get) {
      navigate({
        pathname: ToRoute.adminSubPageDetails(
          pageId,
          pageConfig.operations.get.operationId,
          item.data.Id
        ),
        search: `SPHostUrl=${spHostUrl}`
      });
    }
  }

  function _onClickNewButton(ev?: any) {
    if (pageId && pageConfig.operations?.new) {
      navigate({
        pathname: ToRoute.adminSubPageDetailsNew(pageId, pageConfig.operations.new.operationId),
        search: `SPHostUrl=${spHostUrl}`
      });
    }
  }

  function _onClickEditButton(ev?: any) {
    if (pageId && selectedItems.length === 1 && pageConfig.operations?.get) {
      navigate({
        pathname: ToRoute.adminSubPageDetails(
          pageId,
          pageConfig.operations.get.operationId,
          selectedItems[0].Id
        ),
        search: `SPHostUrl=${spHostUrl}`
      });
    }
  }

  const getCmdItemsMoreSelected = (
    actionHandlers: ListViewActionHandlers
  ): ICommandBarItemProps[] => {
    return [
      {
        key: "delete",
        iconProps: { iconName: "Delete" },
        text: intl.formatMessage({
          id: "button-delete",
          defaultMessage: "Delete"
        }),
        onClick: () => runDeleteDialog(dispatch, selectedItems, navigate, actionHandlers.onDelete)
      }
    ];
  };

  const getCmdItems = (actionHandlers: ListViewActionHandlers): ICommandBarItemProps[] => {
    const items: ICommandBarItemProps[] = [
      {
        key: "add",
        onRender: () => (
          <PrimaryButton
            iconProps={{ iconName: "Add" }}
            onClick={_onClickNewButton}
            styles={{ root: { marginRight: "8px" } }}
          >
            {intl.formatMessage({
              id: "button-new",
              defaultMessage: "New"
            })}
          </PrimaryButton>
        )
      }
    ];

    if (selectedItems.length === 1) {
      items.push(
        ...[
          {
            key: "edit",
            iconProps: { iconName: "Edit" },
            text: intl.formatMessage({
              id: "button-edit",
              defaultMessage: "Edit"
            }),
            onClick: _onClickEditButton
          },
          {
            key: "enable",
            iconProps: {
              iconName:
                selectedItems[0]?.wpProcessStatus === ProcessStatus.Disabled
                  ? "Refresh"
                  : "DisableUpdates"
            },
            text:
              selectedItems[0]?.wpProcessStatus === ProcessStatus.Disabled ? "Enable" : "Disable", // CTRLLANG
            onClick: () => runDisableDialog(dispatch, selectedItems[0])
          },
          {
            key: "save",
            iconProps: { iconName: "Save" },
            text: intl.formatMessage({ id: "button-save-as", defaultMessage: "Save As" }),
            onClick: () =>
              runSaveAsDialog(dispatch, inputEl, selectedItems[0], actionHandlers.onAdd)
          }
        ]
      );
    }

    if (selectedItems.length >= 1) {
      items.push(...getCmdItemsMoreSelected(actionHandlers));
    }

    return items;
  };

  const numberSelected: ICommandBarItemProps = {
    key: "selected",
    onRender: () => (
      <>
        {selectedItems.length > 0 && (
          <Text styles={{ root: { width: "100%", alignSelf: "center", marginRight: "10px" } }}>
            {selectedItems.length + " Selected"}
          </Text>
        )}
      </>
    )
  };

  const searchBox: ICommandBarItemProps = {
    key: "search",

    onRender: () => (
      <SearchBox
        styles={{ root: { width: "100%", alignSelf: "center" } }}
        placeholder="Search"
        onChange={_onSearch}
      />
    )
  };

  const _onSearch = (ev?: React.ChangeEvent<HTMLInputElement>, value?: string) => {
    setSearchText(value);
  };

  return (
    <>
      {pageList === undefined ? (
        <Spinner size={SpinnerSize.large} style={{ marginTop: "100px" }} />
      ) : (
        <ListView
          apiClient={apiClient}
          emptyMessage={intl.formatMessage({
            id: "no-items-message",
            defaultMessage:
              "No items are retrieved. Please click on the new button to create a new item."
          })}
          items={pageList.data}
          columns={pageConfig.columns}
          searchText={searchText}
          selection={selection}
          getContextualMenuItems={getContextualMenuItems}
          getCommandBarItems={getCmdItems}
          getCommandBarFarItems={() => {
            return [numberSelected, searchBox];
          }}
          selectionMode={SelectionMode.multiple}
          isHeaderVisible={true}
          layoutMode={DetailsListLayoutMode.justified}
          constrainMode={ConstrainMode.unconstrained}
          checkboxVisibility={CheckboxVisibility.onHover}
          onTitleLinkClick={(item?: any) => {
            if (pageId && item && pageConfig.operations?.get) {
              ToRoute.adminSubPageDetails(pageId, pageConfig.operations.get.operationId, item.Id);
            }
          }}
        />
      )}
    </>
  );
};
