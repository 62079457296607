import React, { useEffect } from "react";
import {
  CheckboxVisibility,
  ConstrainMode,
  DetailsListLayoutMode,
  ICommandBarItemProps,
  SelectionMode,
  Spinner
} from "@fluentui/react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { useApiClient } from "@workpoint/components/lib/clients/ApiProvider";
import { ToRoute } from "../../../routes";
import { useAppSelector } from "../../../store/hooks";
import { processSelector } from "../../../store/processReducer";
import { ListView } from "../../common/listView/ListView";

export const ProcessHistory = () => {
  const navigate = useNavigate();
  const { processConfigurationId } = useParams() as any;
  const { hasErrors } = useAppSelector(processSelector);
  const { apiClient } = useApiClient();
  const intl = useIntl();
  const [camlQuery, setCamlQuery] = React.useState<string>();

  useEffect(() => {
    (async () => {
      const query = new Map<string, string>();
      query.set("FilterField1", "wpProcessId");
      query.set("FilterValue1", processConfigurationId);

      const item = await apiClient.getListItems({
        listName: "lists/WorkPointProcess",
        renderListDataParameters: { AddAllFields: true },
        renderListDataOverrideParams: {},
        renderListDataQuery: query
      });

      let publishedItem;
      if (item?.items?.[0]?.wpProcessParent?.[0]?.lookupId) {
        publishedItem = await apiClient.getListItem({
          listName: "lists/WorkPointProcess",
          id: item.items[0].wpProcessParent[0].lookupId
        });
      }

      const hasPublishedVersion = !!publishedItem?.wpProcessId;
      setCamlQuery(
        `${
          hasPublishedVersion ? "<Or>" : ""
        }<Eq><FieldRef Name='wpProcessInstProcessId'/><Value Type='Text'>${
          processConfigurationId ?? "_"
        }</Value></Eq>${
          hasPublishedVersion
            ? `<Eq><FieldRef Name='wpProcessInstProcessId'/><Value Type='Text'>${publishedItem.wpProcessId}</Value></Eq></Or>`
            : ""
        }`
      );
    })();
  }, []);

  if (hasErrors) {
    navigate(ToRoute.home());
  }

  const getCommandBarItems = (): ICommandBarItemProps[] => {
    return [
      {
        key: "back",
        iconProps: { iconName: "Back" },
        name: "BACK",
        onClick: () => navigate(ToRoute.processes()),
        buttonStyles: { root: { height: "40px" } }
      }
    ];
  };

  return camlQuery ? (
    <ListView
      apiClient={apiClient}
      listName="lists/WorkPointProcessInstances"
      emptyMessage={intl.formatMessage({
        id: "no-instances-message",
        defaultMessage:
          "No instances have been started. Please start a process to see the process instance history here."
      })}
      viewName=""
      camlFilter={camlQuery}
      selectionMode={SelectionMode.none}
      isHeaderVisible={true}
      layoutMode={DetailsListLayoutMode.justified}
      constrainMode={ConstrainMode.unconstrained}
      checkboxVisibility={CheckboxVisibility.hidden}
      getCommandBarItems={getCommandBarItems}
    />
  ) : (
    <Spinner />
  );
};
