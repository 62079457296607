import { ITermInfo } from "@pnp/sp/taxonomy";
import { DefaultButton, IChoiceGroupOption, Label, PrimaryButton, Text } from "@fluentui/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import styled from "styled-components";
import {
  ProcessConfiguration,
  ProcessGroup,
  ProcessType
} from "@workpoint/components/lib/models/ProcessConfiguration";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getTerms, processSelector } from "../../../../store/processReducer";
import { ControlledChoiceGroup } from "../../../common/form/ControlledChoiceGroup";
import { ControlledTagPicker } from "../../../common/form/ControlledTagPicker";
import { ControlledTextField } from "../../../common/form/ControlledTextField";
import { evaluateExpressionForDisplay } from "@workpoint/components/lib/helpers/expressionUtils";

interface INewProcessDetails {
  template?: ProcessConfiguration;
  onPreviousClick(): void;
  onBeginClick(type: ProcessType, title: string, group?: ProcessGroup): void;
}

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const StyledButton = styled(PrimaryButton)`
  margin: 0 16px;
`;

export const NewProcessDetails = (props: INewProcessDetails) => {
  const intl = useIntl();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      Title: props.template?.title.defaultText
        ? evaluateExpressionForDisplay(props.template.title.defaultText, {})
        : "",
      Type: props.template?.type ?? undefined,
      Group: undefined as any
    }
  });
  const watchAllFields = watch();
  const [formControls, setFormControls] = useState<JSX.Element[]>([]);
  const { groups } = useAppSelector(processSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getControls();
    if (!groups) {
      dispatch(getTerms());
    }
  }, []);

  const options: IChoiceGroupOption[] = [
    { key: ProcessType.System, text: "System Process" },
    { key: ProcessType.User, text: "User Process" }
  ];

  const getControls = () => {
    const controls: JSX.Element[] = [];
    controls.push(
      <ControlledTextField
        key={"title"}
        name={"Title"}
        label={"Title"}
        placeholder={intl.formatMessage({
          id: "dialog-title-placeholder",
          defaultMessage: "Enter title for your process"
        })}
        control={control}
        required={true}
        autoFocus
      />
    );
    controls.push(
      <ControlledChoiceGroup
        key={"type"}
        name={"Type"}
        label={"Type"}
        control={control}
        options={options}
        styles={{ label: { marginTop: "16px" } }}
      />
    );
    setFormControls(controls);
  };

  const onBeginClick = () => {
    handleSubmit((data) => {
      props.onBeginClick(data.Type ?? ProcessType.User, data.Title, {
        id: data.Group?.[0]?.key as string,
        title: data.Group?.[0]?.name
      });
    })();
  };

  return (
    <>
      <form>
        {formControls}
        <Label>Select Group • Optional</Label>
        <ControlledTagPicker
          control={control}
          name={"Group"}
          itemLimit={1}
          onResolveSuggestions={(filter: string) => {
            return (
              groups?.items
                .filter((item: ITermInfo) => {
                  return item.labels[0].name.toLowerCase().includes(filter.toLowerCase());
                })
                .map((item: ITermInfo) => {
                  return { key: item.id, name: item.labels[0].name };
                }) ?? []
            );
          }}
          onEmptyResolveSuggestions={() => {
            return (
              groups?.items.map((i: ITermInfo) => {
                return { key: i.id, name: i.labels[0].name };
              }) ?? []
            );
          }}
          onLoadValue={(value: any) => {
            return value.length > 0 ? [value[0]] : value;
          }}
        />
      </form>
      {watchAllFields.Type === "System" && (
        <StyledTextContainer>
          <Text variant="large">
            {intl.formatMessage({
              id: "dialog-system-process",
              defaultMessage: "System Process"
            })}
          </Text>
          <Text variant="medium">
            {intl.formatMessage({
              id: "dialog-system-description",
              defaultMessage:
                "A system process is executed when a specific event happens in WorkPoint, dependent on the kinds of triggers used in the process."
            })}
          </Text>
        </StyledTextContainer>
      )}
      {watchAllFields.Type === "User" && (
        <StyledTextContainer>
          <Text variant="large">
            {intl.formatMessage({
              id: "dialog-user-process",
              defaultMessage: "User Process"
            })}
          </Text>
          <Text variant="medium">
            {intl.formatMessage({
              id: "dialog-user-description",
              defaultMessage:
                "A user process is initialized by a user by clicking a button, e.g. in the My Tools panel or the Send To menu of a list/library. They commonly require input from the users."
            })}
          </Text>
        </StyledTextContainer>
      )}
      <StyledButtonContainer>
        <DefaultButton
          text="Back"
          onClick={() => props.onPreviousClick()}
          iconProps={{ iconName: "Back" }}
        />
        <StyledButton
          text="Begin"
          onClick={onBeginClick}
          iconProps={{ iconName: "CheckMark" }}
          disabled={!watchAllFields.Title || !watchAllFields.Type}
        />
      </StyledButtonContainer>
    </>
  );
};
