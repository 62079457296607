import React from "react";
import { OpenAPIV2 } from "openapi-types";
import { getDefinitionByOperationId } from "../../../utils/swaggerUtils";
import { Guidance } from "../Guidance";

interface GuidancePanelProps {
  document?: OpenAPIV2.Document;
  operationId?: string;
}
export const GuidancePanel = (props: GuidancePanelProps) => {
  const { document, operationId } = props;

  let swaggerOperation: any;
  if (document && operationId) {
    swaggerOperation = getDefinitionByOperationId(document, operationId)?.definition;
  }

  return <Guidance swaggerOperation={swaggerOperation}></Guidance>;
};

