import {
  configureStore,
  ThunkAction,
  Action,
  ThunkDispatch,
  AnyAction,
  Dispatch
} from "@reduxjs/toolkit";
import adminReducer, { AdminState } from "./adminReducer";
import dialogReducer, { DialogState } from "./dialogReducer";
import globalReducer, { GlobalState } from "./globalReducer";
import processReducer, { ProcessState } from "./processReducer";
import { ApiClient } from "@workpoint/components/lib/clients/ApiClient";
import notificationReducer, {
  INotificationState
} from "../components/admin/notification/notificationSettingsReducer";
import navigationReducer, { INavigationState } from "./navigationReducer";

export const createStore = (apiClient: ApiClient) => {
  return configureStore({
    reducer: {
      process: processReducer,
      dialog: dialogReducer,
      admin: adminReducer,
      navigation: navigationReducer,
      global: globalReducer,
      notification: notificationReducer
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: {
            apiClient
          }
        },
        serializableCheck: {
          ignoredActions: ["persist/PERSIST"]
        }
      })
  });
};

export type AppDispatch = ThunkDispatch<
  {
    process: ProcessState;
    dialog: DialogState;
    global: GlobalState;
    admin: AdminState;
    notification: INotificationState;
    navigation: INavigationState;
  },
  {
    apiClient: ApiClient;
  },
  AnyAction
> &
  Dispatch<AnyAction>; //typeof store.dispatch;

export type RootState = {
  process: ProcessState;
  dialog: DialogState;
  global: GlobalState;
  admin: AdminState;
  notification: INotificationState;
  navigation: INavigationState;
};
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  { apiClient: ApiClient },
  Action<string>
>;

export type ThunkApiConfig = {
  extra: { apiClient: ApiClient };
};
