import { yupResolver } from "@hookform/resolvers/yup";
import { isEqual } from "lodash";
import {
  DefaultButton,
  Link,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  Stack,
  StackItem
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { defineMessages, useIntl } from "react-intl";
import { Outlet, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { ControlledEnableDisableButton } from "./components/ControlledEnableDisable";
import { ControlledNumber } from "./components/ControlledNumber";
import { ControlledRichText } from "./components/ControlledRichText";
import { useNotificationSettings } from "./useNotificationSettings";
import { globalSelector } from "../../../store/globalReducer";
import {
  clearErrorMessage,
  notificationErrorMessageSelector,
  notificationSaveStatusSelector,
  saveNotificationSettings,
  setSettings
} from "./notificationSettingsReducer";
import { DemoPayload } from "./components/DemoPayload";
import { NotificationEnable } from "./components/NotificationEnable";
import { NotificationProfilesList } from "./components/NotificationProfilesList";
import {
  InitialNotificationSettings,
  INotificationSettings
} from "../../../models/NotificationSettings";
import { RequestState } from "../../../models/RequestState";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ToRoute } from "../../../routes";
import { useApiClient } from "@workpoint/components/lib/clients/ApiProvider";

export const NotificationSettings = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { spHostUrl } = useAppSelector(globalSelector);
  const dispatch = useAppDispatch();
  const { apiClient } = useApiClient();

  const messages = defineMessages({
    CustomizedAdaptiveCardLayoutDescription: {
      id: "custom-adaptive-card-layout-description",
      defaultMessage: `Enchance the user experience of your Notifications by providing a custom Adaptive Card design below. The design must be provided in a JSON format. See and design Adaptive Cards <anchor>here</anchor>.`
    },
    NotificationService: {
      id: "notification-service",
      defaultMessage: "Notification service"
    },
    NotificationProfiles: {
      id: "notification-profiles",
      defaultMessage: "Notification profiles"
    },
    IsAutomaticDeletionEnabled: {
      id: "automatically-delete-notifications",
      defaultMessage: "Automatically delete notifications"
    },
    RetentionPeriodInDays: {
      id: "retention-period-in-days",
      defaultMessage: "Retention period in days"
    },
    IsCustomizedAdaptiveCardLayoutEnabled: {
      id: "customized-adaptive-card-layout-enabled",
      defaultMessage: "Customized Adaptive Card layout"
    },
    CustomizedAdaptiveCardLayoutJson: {
      id: "custom-adaptive-card-layout",
      defaultMessage: "Custom Adaptive Card layout"
    },
    UnexpectedNotificationSettingsLoadError: {
      id: "unexpected-settings-load-error",
      defaultMessage: "The settings could not be loaded. It threw the following error: "
    },
    UnexpectedNotificationSettingsSaveError: {
      id: "unexpected-settings-save-error",
      defaultMessage: "The settings could not be saved. It threw the following error: "
    },
    CustomizedAdaptiveCardLayoutJsonError: {
      id: "custom-adaptive-card-layout-error",
      defaultMessage:
        "Adaptive Card layout must be valid JSON. Please ensure that it is correct by checking the inline messages above."
    },
    TypeString: {
      id: "type",
      defaultMessage: "Type"
    },
    MusBeA: {
      id: "must-be-a",
      defaultMessage: "Must be a"
    },
    AvailableVarialbesInTemplate: {
      id: "available-variables-in-templates",
      defaultMessage: "Available variables for use in template"
    },
    Template: {
      id: "template",
      defaultMessage: "Template"
    },
    TeamsAppTitle: {
      id: "teams-app-title",
      defaultMessage: "Notifications in Microsoft Teams"
    },
    TeamsAppDescription: {
      id: "teams-app-description",
      defaultMessage:
        "Make the most of the WorkPoint Notification Service by installing the Teams App, to enable pushing notifications directly to your users Microsoft Teams Activity Feeds"
    },
    TeamsAppDownloadText: {
      id: "teams-app-download-text",
      defaultMessage: "Download the Teams App Manifest <anchor>here!</anchor>"
    },
    RetentionPeriodHelpText: {
      id: "retention-period-help-text",
      defaultMessage: `Use this field to define a retention period in days. If the date on which a notification was sent to the recipient(s) is older than this number of days, and the status of the notification is "Completed", the notification will be deleted from the Notifications list.`
    },
    RetentionPeriodHelpText2: {
      id: "retention-period-help-text-2",
      defaultMessage: `If this functionality is enabled, you must schedule a "Notification Cleanup" job to run. You can read more about scheduled jobs and how to configure them in this <anchor>article</anchor>.`
    },
    OnlyCleanupCompletedNotifications: {
      id: "only-cleanup-completed-notifications",
      defaultMessage: "Only clean-up notifications with the 'Completed' status."
    }
  });

  yup.setLocale({
    mixed: {
      notType:
        "${label} " +
        intl.formatMessage(messages.MusBeA).toLowerCase() +
        " '${type}' " +
        intl.formatMessage(messages.TypeString).toLowerCase()
    }
  });

  const schema = yup.object({
    Enabled: yup.boolean().label(intl.formatMessage(messages.NotificationService)),
    IsAutomaticDeletionEnabled: yup
      .boolean()
      .label(intl.formatMessage(messages.IsAutomaticDeletionEnabled)),
    RetentionPeriodInDays: yup
      .mixed()
      .when("IsAutomaticDeletionEnabled", {
        is: true,
        then: yup.number().positive().integer().min(1)
      })
      .label(intl.formatMessage(messages.RetentionPeriodInDays)),
    IsCustomizedAdaptiveCardLayoutEnabled: yup
      .boolean()
      .label(intl.formatMessage(messages.IsCustomizedAdaptiveCardLayoutEnabled)),
    CustomizedAdaptiveCardLayoutJson: yup
      .string()
      .when("IsCustomizedAdaptiveCardLayoutEnabled", {
        is: true,
        then: (schema) =>
          schema
            .required()
            .test(
              "valid-json-template",
              intl.formatMessage(messages.CustomizedAdaptiveCardLayoutJsonError),
              (value) => {
                try {
                  JSON.parse(value as string);
                } catch (error) {
                  return false;
                }
                return true;
              }
            )
      })
      .label(intl.formatMessage(messages.CustomizedAdaptiveCardLayoutJson)),
    NotificationProfiles: yup
      .array()
      .ensure()
      .min(1)
      .label(intl.formatMessage(messages.NotificationProfiles))
  });

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<INotificationSettings>({
    mode: "onBlur",
    defaultValues: InitialNotificationSettings,
    /**
     * @warning: Resolver needs to dispatch changes to reducer to persist form when opening notification profiles dialog.
     * yup schema still needs to handle validation, so we merge the calls together.
     */
    resolver: (values: INotificationSettings, context: any, option: any) =>
      ((values, context, option) => {
        dispatch(setSettings(values));
        return yupResolver(schema)(values, context, option);
      })(values, context, option)
  });

  const saveStatus = useAppSelector((state) => notificationSaveStatusSelector(state));
  const errorMessage = useAppSelector((state) => notificationErrorMessageSelector(state));
  const saving = saveStatus === RequestState.pending;

  const { notificationSettings, success, loading, unprovisioned, unlicensed, error } =
    useNotificationSettings();

  const [adaptiveCardDemoPayload, setAdaptiveCardDemoPayload] = useState<string>("");

  const watchIsCustomizedAdaptiveCardLayoutEnabled = watch("IsCustomizedAdaptiveCardLayoutEnabled");

  useEffect(() => {
    if (
      watchIsCustomizedAdaptiveCardLayoutEnabled &&
      typeof notificationSettings!.CustomizedAdaptiveCardLayoutJson === "string" &&
      notificationSettings!.CustomizedAdaptiveCardLayoutJson.trim() === ""
    )
      apiClient
        .getWP(`/Notifications/GetDefaultNotificationAdaptiveCardTemplate`)
        .then((payload) => {
          reset({
            ...notificationSettings,
            CustomizedAdaptiveCardLayoutJson: payload.DefaultNotificationAdaptiveCardTemplate
          });
          setAdaptiveCardDemoPayload(payload.DemoPayload);
        });
  }, [watchIsCustomizedAdaptiveCardLayoutEnabled]);

  const watchIsAutomaticDeletionEnabled = watch("IsAutomaticDeletionEnabled");
  const watchNotificationProfiles = watch("NotificationProfiles");

  useEffect(() => {
    if (success) reset(notificationSettings);
  }, [success]);

  /**
   * @warning: We need to update profiles when they change in the dialogs.
   */
  useEffect(() => {
    if (
      notificationSettings?.NotificationProfiles.length !== watchNotificationProfiles.length ||
      !isEqual(notificationSettings?.NotificationProfiles, watchNotificationProfiles)
    ) {
      reset(notificationSettings);
    }
  }, [notificationSettings]);

  /**
   * Only prompt with dialog, let the dialog do the creation.
   */
  const addProfileClick = () => navigate(ToRoute.newNotificationProfile());

  const onsubmit = (data: INotificationSettings) => {
    // Save live model data
    dispatch(
      saveNotificationSettings({
        newSettings: data as INotificationSettings
      })
    );
  };

  const download = async () => {
    const blob = await apiClient.getBlob("/Notifications/TeamsAppManifest");
    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = "WorkPointTeamsNotificationsApp";
    a.dispatchEvent(new MouseEvent("click"));
  };

  return (
    <div>
      <h1>
        {intl.formatMessage({
          id: "notification-settings",
          defaultMessage: "Notification Settings"
        })}
      </h1>
      {loading && <Spinner />}
      {unprovisioned && (
        <NotificationEnable
          title={intl.formatMessage(messages.NotificationService)}
          actionTitle={intl.formatMessage({
            id: "enable-notification-service",
            defaultMessage: "Enable notification service"
          })}
          description={intl.formatMessage({
            id: "enable-notification-service-description",
            defaultMessage:
              "Enable the notification service to start using rules based messages to groups.\nEnabling can take up to a couple of minutes."
          })}
          provisioningDescription={intl.formatMessage({
            id: "enable-notification-service-provisioning-description",
            defaultMessage:
              "Provisioning is in progress and this can take some time.\nYou will be redirected to the Notification Settings page when this is completed.\nYou can close, or leave this page, and check back later."
          })}
        />
      )}
      {unlicensed && (
        <p>
          {intl.formatMessage({
            id: "notification-service-unlicensed",
            defaultMessage:
              "The Notification Service is not included in you license.\nPlease check with your WorkPoint 365 contact to see what benefits you can draw from this feature."
          })}
        </p>
      )}
      {success && (
        <form onSubmit={handleSubmit(onsubmit)}>
          <Stack tokens={{ childrenGap: 20, padding: 10 }}>
            <StackItem>
              <h4>{intl.formatMessage(messages.NotificationService)}</h4>
              <ControlledEnableDisableButton
                name="Enabled"
                control={control}
                disabled={saving}
                onText={intl.formatMessage({
                  id: "enabled",
                  defaultMessage: "Enabled"
                })}
                offText={intl.formatMessage({
                  id: "disabled",
                  defaultMessage: "Disabled"
                })}
              />
            </StackItem>
            <StackItem>
              <h4>{intl.formatMessage(messages.NotificationProfiles)}</h4>
              <NotificationProfilesList
                profiles={watchNotificationProfiles}
                loading={loading}
                error={!!errors && errors?.["NotificationProfiles"]}
                disabled={saving}
              />
            </StackItem>
            <StackItem>
              <DefaultButton
                onClick={addProfileClick}
                iconProps={{ iconName: "Add" }}
                disabled={saving}
              >
                {intl.formatMessage({
                  id: "create-profile",
                  defaultMessage: "Create a new profile"
                })}
              </DefaultButton>
            </StackItem>
          </Stack>
          <Stack tokens={{ childrenGap: 20, padding: 10, maxWidth: 620 }}>
            <StackItem>
              <h4>{intl.formatMessage(messages.IsAutomaticDeletionEnabled)}</h4>
              <ControlledEnableDisableButton
                name="IsAutomaticDeletionEnabled"
                control={control}
                disabled={saving}
                onText={intl.formatMessage({
                  id: "enabled",
                  defaultMessage: "Enabled"
                })}
                offText={intl.formatMessage({
                  id: "disabled",
                  defaultMessage: "Disabled"
                })}
              />
            </StackItem>

            {watchIsAutomaticDeletionEnabled && (
              <StackItem>
                <label htmlFor="RetentionPeriodInDays">
                  <h4>{intl.formatMessage(messages.RetentionPeriodInDays)}</h4>
                  <p>
                    {intl.formatMessage(messages.RetentionPeriodHelpText2, {
                      anchor: (str) => (
                        <Link
                          target="_blank"
                          href="https://support.workpoint.dk/hc/articles/360033654714"
                        >
                          {str}
                        </Link>
                      )
                    })}
                  </p>
                </label>
                <ControlledNumber
                  name="RetentionPeriodInDays"
                  control={control}
                  disabled={saving}
                />
              </StackItem>
            )}
            {watchIsAutomaticDeletionEnabled && (
              <StackItem>
                <h4>{intl.formatMessage(messages.OnlyCleanupCompletedNotifications)}</h4>
                <ControlledEnableDisableButton
                  name="OnlyCleanupCompletedNotifications"
                  control={control}
                  disabled={saving}
                  onText={intl.formatMessage({
                    id: "enabled",
                    defaultMessage: "Enabled"
                  })}
                  offText={intl.formatMessage({
                    id: "disabled",
                    defaultMessage: "Disabled"
                  })}
                />
              </StackItem>
            )}

            <StackItem>
              <h4>{intl.formatMessage(messages.TeamsAppTitle)}</h4>
              <p>{intl.formatMessage(messages.TeamsAppDescription)}</p>
              {intl.formatMessage(messages.TeamsAppDownloadText, {
                anchor: (str) => (
                  <Link target="_blank" onClick={download}>
                    {str}
                  </Link>
                )
              })}
            </StackItem>

            <StackItem>
              <h4>{intl.formatMessage(messages.IsCustomizedAdaptiveCardLayoutEnabled)}</h4>
              <ControlledEnableDisableButton
                name="IsCustomizedAdaptiveCardLayoutEnabled"
                control={control}
                disabled={saving}
                onText={intl.formatMessage({
                  id: "enabled",
                  defaultMessage: "Enabled"
                })}
                offText={intl.formatMessage({
                  id: "disabled",
                  defaultMessage: "Disabled"
                })}
              />
            </StackItem>
          </Stack>
          <Stack tokens={{ childrenGap: 20, padding: 10 }}>
            {watchIsCustomizedAdaptiveCardLayoutEnabled && (
              <StackItem>
                <label htmlFor="CustomizedAdaptiveCardLayoutJson">
                  <h4>{intl.formatMessage(messages.CustomizedAdaptiveCardLayoutJson)}</h4>
                </label>
                <p>
                  {intl.formatMessage(messages.CustomizedAdaptiveCardLayoutDescription, {
                    anchor: (str) => (
                      <a target="_blank" href="https://adaptivecards.io/designer">
                        {str}
                      </a>
                    )
                  })}
                </p>

                <Stack horizontal wrap tokens={{ maxWidth: "100%" }}>
                  <StackItem grow>
                    <label>
                      <h5>{intl.formatMessage(messages.Template)}</h5>
                    </label>
                    <ControlledRichText
                      control={control}
                      name="CustomizedAdaptiveCardLayoutJson"
                      multiline
                      disabled={saving}
                      placeholder={intl.formatMessage({
                        id: "adaptive-card-layout",
                        defaultMessage: "Adaptive Card layout"
                      })}
                      error={!!errors && errors?.["CustomizedAdaptiveCardLayoutJson"]}
                    />
                  </StackItem>
                  <StackItem grow>
                    <label>
                      <h5>{intl.formatMessage(messages.AvailableVarialbesInTemplate)}</h5>
                    </label>
                    <DemoPayload value={adaptiveCardDemoPayload} />
                  </StackItem>
                </Stack>
              </StackItem>
            )}

            {errorMessage && (
              <StackItem>
                <MessageBar
                  onDismiss={() => dispatch(clearErrorMessage())}
                  messageBarType={MessageBarType.warning}
                >
                  <div>{intl.formatMessage(messages.UnexpectedNotificationSettingsSaveError)}</div>
                  {errorMessage}
                </MessageBar>
              </StackItem>
            )}

            <StackItem>
              <PrimaryButton type="submit" disabled={saving}>
                {intl.formatMessage({
                  id: "save",
                  defaultMessage: "Save"
                })}
              </PrimaryButton>
            </StackItem>
          </Stack>
        </form>
      )}
      {error && errorMessage && (
        <MessageBar
          onDismiss={() => dispatch(clearErrorMessage())}
          messageBarType={MessageBarType.warning}
        >
          <div>{intl.formatMessage(messages.UnexpectedNotificationSettingsLoadError)}</div>
          {errorMessage}
        </MessageBar>
      )}

      {/* Outlet for displaying Notification Profile modals on top of this page. */}
      <Outlet />
    </div>
  );
};
