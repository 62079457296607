import * as React from "react";
import { IconButton, PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import {
  IColumn,
  Selection,
  IDetailsRowProps,
  ColumnActionsMode,
  ISelection,
  IGroup
} from "@fluentui/react/lib/DetailsList";
import { Link } from "@fluentui/react/lib/Link";
import { Checkbox } from "@fluentui/react/lib/Checkbox";
import {
  IContextualMenuProps,
  IContextualMenuItem,
  DirectionalHint,
  ContextualMenu
} from "@fluentui/react/lib/ContextualMenu";
import { Icon } from "@fluentui/react/lib/Icon";
import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import { Spinner } from "@fluentui/react/lib/Spinner";
import VisibilitySensor from "react-visibility-sensor";
import { isEmpty, parseHTML } from "../../../utils/commonUtils";
import {
  ShimmeredDetailsList,
  IShimmeredDetailsListProps,
  CommandBar,
  Separator,
  ICommandBarItemProps,
  Text
} from "@fluentui/react";
import styled from "styled-components";
import { ApiClient } from "@workpoint/components/lib/clients/ApiClient";
import { cloneDeep } from "lodash";

export interface ListViewColumn {
  key: string;
  type: string;
  title: string;
  filterable: boolean;
  sortable: boolean;
}

export interface ListViewFilterOption {
  title: string;
  value: string;
  selected: boolean;
}

export interface ListViewFilter {
  name: string;
  title: string;
  type: string;
  options: ListViewFilterOption[];
}
export interface ListViewActionHandlers {
  onDelete: (ids: number[]) => void;
  onAdd: (item: any) => void;
  onUpdate: (item: any) => void;
}

export type ListViewProps = Omit<IShimmeredDetailsListProps, "items" | "columns"> & {
  apiClient: ApiClient;
  listName?: string;
  emptyMessage: string;
  viewName?: string;
  webUrl?: string;
  columns?: ListViewColumn[];
  showPager?: boolean;
  items?: any[];
  groups?: IGroup[];
  panelComponent?: any;
  getContextualMenuItems?: (
    actionHandlers: ListViewActionHandlers,
    item?: any
  ) => IContextualMenuItem[];
  onTitleLinkClick?: (item?: any) => void;
  searchText?: string;
  camlFilter?: string;
  getCommandBarItems?: (actionHandlers: ListViewActionHandlers) => ICommandBarItemProps[];
  getCommandBarFarItems?: (actionHandlers: ListViewActionHandlers) => ICommandBarItemProps[];
  getItemLink?: (item: any) => React.ReactElement;
  onGetItems?: (state: ListViewData) => void;
  onGroupCollapse?: (group: IGroup) => void;
  order?: { orderBy: string; isOrderDescending: boolean } | null;
  filter?: ListViewFilter[];
  nextHref?: string;
};

export interface ListViewData {
  items: any[];
  groups?: IGroup[];
  order: { orderBy: string; isOrderDescending: boolean } | null;
  filter: ListViewFilter[];
  nextHref?: string;
  searchText?: string;
}
interface ListViewState {
  items: any[];
  groups?: IGroup[];
  columns: IColumn[];
  status?: string;
  showPropsPanel?: boolean;
  showFilterPanel?: boolean;
  showFilterDetailsPanel?: boolean;
  loadingPropsPanel?: boolean;
  loading?: boolean;
  itemMode?: number;
  list?: any;
  selectedItem?: any;
  powerAppLoaded?: boolean;
  appManifest?: any;
  contextualMenuProps?: IContextualMenuProps;
  order: { orderBy: string; isOrderDescending: boolean } | null;
  filter: ListViewFilter[];
  selectedFilter?: ListViewFilter;
  nextHref?: string;
  groupFieldName?: string;
}

export class ListView extends React.Component<ListViewProps, ListViewState> {
  private _selection: ISelection;
  private detailFilterSelectedOptions: string[] = [];
  private frame: HTMLIFrameElement | null = null;
  private frameContainer: HTMLDivElement | null = null;
  private filterCacheKey = "filter-" + this.props.listName ?? "" + this.props.viewName ?? "";
  private collapsedGroupsCacheKey =
    "collapsedGroups-" + this.props.listName ?? "" + this.props.viewName ?? "";

  constructor(props: ListViewProps) {
    super(props);

    this._selection =
      props.selection ??
      new Selection({
        onSelectionChanged: this._onItemsSelectionChanged
      });

    let columns: IColumn[] = [];
    if (props.columns) {
      columns = this.getColumns(props.columns);
    }

    this.state = {
      items: props.items ? props.items : [],
      groups: props.groups,
      columns: columns,
      showPropsPanel: false,
      showFilterPanel: false,
      showFilterDetailsPanel: false,
      loading: props.items && !props.listName ? false : true,
      powerAppLoaded: false,
      order: props.order ?? null,
      filter: props.filter ?? [],
      nextHref: props.nextHref
    };

    this._onVisibilitySensorChange = this._onVisibilitySensorChange.bind(this);
    this.hidePropsPanel = this.hidePropsPanel.bind(this);
    this.reload = this.reload.bind(this);
    (window as any).ProcessListViewReload = this.reload;
  }

  public componentDidMount() {
    window.addEventListener("message", (e) => {
      this.onMessage(e);
    });

    if (this.props.listName) {
      this.getItems(
        this.props.webUrl,
        this.props.listName,
        this.props.viewName,
        this.state.columns,
        this.state.order,
        [],
        false,
        this.props.camlFilter,
        this.props.searchText,
        true
      );
    }
  }

  public componentWillReceiveProps(nextProps: ListViewProps): void {
    if (
      nextProps.listName &&
      (this.props.webUrl !== nextProps.webUrl ||
        this.props.listName !== nextProps.listName ||
        this.props.viewName !== nextProps.viewName ||
        this.props.camlFilter !== nextProps.camlFilter ||
        this.props.searchText !== nextProps.searchText ||
        this.props.columns !== nextProps.columns)
    ) {
      this.getItems(
        nextProps.webUrl,
        nextProps.listName,
        nextProps.viewName,
        this.state.columns,
        this.state.order,
        this.state.filter,
        false,
        nextProps.camlFilter,
        nextProps.searchText,
        this.props.searchText == nextProps.searchText
      );
    } else if (this.props.items !== nextProps.items || this.props.columns !== nextProps.columns) {
      this.setState({
        columns: nextProps.columns ? this.getColumns(nextProps.columns) : this.state.columns ?? [],
        items: nextProps.items ? nextProps.items : this.state.items ?? []
      });
    }
  }

  public componentWillUnmount() {
    if (this.frameContainer && this.frame) {
      this.frameContainer.removeChild(this.frame);
    }
    window.removeEventListener("message", this.onMessage);
  }

  public reload = async () => {
    this.getItems(
      this.props.webUrl,
      this.props.listName,
      this.props.viewName,
      this.state.columns,
      this.state.order,
      this.state.filter,
      false,
      this.props.camlFilter,
      this.props.searchText
    );
  };

  private getItems = async (
    webUrl: any,
    listName: any,
    viewName: any,
    columns: IColumn[],
    order: { orderBy: string; isOrderDescending: boolean } | null,
    filter: ListViewFilter[],
    nextPage: boolean,
    staticFilter?: string,
    searchText?: string,
    firstLoad: boolean = false
  ) => {
    if (filter.length === 0) {
      filter = this.getCachedFilter();
    }

    this.setState({
      loading: true,
      status: "Loading all items..."
    });

    let caml: string[] = [];

    if (staticFilter) {
      caml.push(staticFilter);
    }

    if (filter) {
      const textFilter: string[] = [];
      filter.forEach((f) => {
        if (f.options.length) {
          let camlValue: string[] = [];
          f.options.forEach((o) => {
            if (o.selected) {
              camlValue.push("<Value Type='" + f.type + "'>" + o.title + "</Value>");
            }
          });

          if (camlValue.length) {
            let wrapCaml = caml.length > 0;

            caml.push("<In id='" + f.name + "'><FieldRef Name='" + f.name + "'/><Values>");
            caml.push(camlValue.join(""));
            caml.push("</Values></In>");

            if (wrapCaml) {
              caml.unshift("<And>");
              caml.push("</And>");
            }
          }

          if (searchText) {
            textFilter.push(
              `<Contains><FieldRef Name='${f.name}'/><Value Type='${f.type}'>${searchText}</Value></Contains>`
            );
            if (textFilter.length > 1) {
              textFilter.unshift("<Or>");
              textFilter.push("</Or>");
            }
          }
        }
      });

      if (textFilter.length > 0) {
        let wrapCaml = caml.length > 0;

        caml.push(textFilter.join(""));

        if (wrapCaml) {
          caml.unshift("<And>");
          caml.push("</And>");
        }
      }
    }

    const query = new Map<string, string>();
    if (order) {
      query.set("SortField", order.orderBy);
      query.set("SortDir", order.isOrderDescending ? "Desc" : "Asc");
    }

    const overrideParams: any = {};
    if (viewName) {
      overrideParams.View = viewName;
    }

    var setOnlySchema = firstLoad && this.state.items.length > 0;

    const result = await this.props.apiClient.getListItems({
      webUrl,
      listName,
      renderListDataParameters: {
        AddRequiredFields: true,
        AllowMultipleValueFilterForTaxonomyFields: true,
        RenderOptions: setOnlySchema ? 4 : this.state.columns.length ? 4098 : 5783,
        OverrideViewXml:
          caml.length > 0 ? `<Query><Where>${caml.join("")}</Where></Query>` : undefined,
        Paging: nextPage ? this.state.nextHref?.split("?")[1] : undefined
      },
      renderListDataOverrideParams: overrideParams,
      renderListDataQuery: query
    });

    const renderListDataAsStreamResult = result.renderListDataAsStreamResponse;
    let groups: IGroup[] | undefined = result.groups;
    const fields: any[] =
      renderListDataAsStreamResult.ListSchema?.Field ?? renderListDataAsStreamResult.Field;
    if (!this.state.columns.length && fields) {
      const selectedColumns: ListViewColumn[] = [];
      fields.forEach((f) => {
        if (f.GroupField !== "TRUE") {
          selectedColumns.push({
            key: f.Name,
            type: f.FieldType,
            title: f.DisplayName,
            filterable: f.ShowInFiltersPane === "Auto",
            sortable: true
          });
        }
      });

      columns = this.getColumns(selectedColumns);
    }

    if (setOnlySchema) {
      this.setState({
        columns,
        list: renderListDataAsStreamResult,
        groupFieldName:
          renderListDataAsStreamResult.ListSchema?.group1 ?? renderListDataAsStreamResult.group1
      });
    } else {
      let items = result.items.map((i) => {
        i.key = i.ID;
        return i;
      });

      if (renderListDataAsStreamResult?.ListData) {
        const lvFilter: ListViewFilter[] = [];

        (renderListDataAsStreamResult.ListSchema.Field as any[]).forEach((f) => {
          if (f.GroupField !== "TRUE") {
            const currentFilter = filter?.find((fil) => fil.name === f.Name);

            if (f.Choices) {
              let options: ListViewFilterOption[] = [];
              (f.Choices as string[]).forEach((choice) => {
                let selected =
                  currentFilter?.options.find((o) => o.value === choice)?.selected ?? false;
                options.push({ title: choice, value: choice, selected });
              });

              lvFilter.push({
                name: f.Name,
                title: f.DisplayName,
                type: f.FieldType,
                options: options
              });
            } else if (
              f.FieldType === "Text" ||
              f.FieldType === "Computed" ||
              f.FieldType === "User" ||
              f.FieldType === "Lookup"
            ) {
              let options: ListViewFilterOption[] = [];
              (renderListDataAsStreamResult.ListData.Row as any[]).forEach((item) => {
                if (item[f.RealFieldName]) {
                  let value = item[f.RealFieldName];
                  if (Array.isArray(value)) {
                    if ((value as any[]).length) {
                      if (value[0].title) {
                        value = value[0].title;
                      } else if (value[0].lookupValue) {
                        value = value[0].lookupValue;
                      } else {
                        value = value[0];
                      }
                    }
                  }

                  if (options.filter((o) => o.title === value).length === 0) {
                    const selected = !!filter
                      ?.find((fil) => fil.name === f.Name)
                      ?.options?.find((op) => op.value === value)?.selected;
                    options.push({ title: value, value: value, selected });
                  }
                }
              });

              lvFilter.push({
                name: f.Name,
                title: f.DisplayName,
                type: f.FieldType,
                options: options
              });
            }
          }
        });

        groups && this.getCollapsedGroups(groups);

        this.props.onGetItems &&
          this.props.onGetItems({
            items,
            groups,
            order,
            filter: lvFilter,
            nextHref: renderListDataAsStreamResult.ListData.NextHref,
            searchText
          });

        this.setState({
          items,
          groups,
          status: "",
          loading: false,
          order,
          filter: lvFilter,
          nextHref: renderListDataAsStreamResult.ListData.NextHref,
          columns,
          list: renderListDataAsStreamResult,
          groupFieldName: renderListDataAsStreamResult.ListSchema.group1
        });
      } else {
        if (nextPage) {
          if (this.state.groupFieldName) {
            const orderedItems = [...this.state.items];
            items.forEach((item) => {
              let add = true;
              for (let i = orderedItems.length - 1; i >= 0; i--) {
                if (
                  orderedItems[i][this.state.groupFieldName + ".singleurlencoded"] ===
                  item[this.state.groupFieldName + ".singleurlencoded"]
                ) {
                  orderedItems.splice(i + 1, 0, item);
                  add = false;
                  break;
                }
              }
              if (add) {
                orderedItems.push(item);
              }
            });
            items = orderedItems;
          } else {
            items = this.state.items.concat(items);
          }
        }

        if (this.state.groupFieldName) {
          groups = this.props.apiClient.getItemGroups(items, this.state.groupFieldName);
          if (nextPage) {
            const allCollapsed = !this.state.groups?.some((group) => !group.isCollapsed);
            if (allCollapsed) {
              groups!.forEach((newGrouop) => {
                newGrouop.isCollapsed = true;
              });
            } else {
              this.state.groups?.forEach((currentGroup) => {
                groups!
                  .filter((newGroup) => newGroup.key === currentGroup.key)
                  .forEach((newGrouop) => {
                    if (newGrouop && currentGroup.isCollapsed) {
                      newGrouop.isCollapsed = currentGroup.isCollapsed;
                    }
                  });
              });
            }
          }
        }

        groups && this.getCollapsedGroups(groups);

        this.props.onGetItems &&
          this.props.onGetItems({
            items,
            groups,
            order,
            filter,
            nextHref: renderListDataAsStreamResult?.NextHref,
            searchText
          });

        this.setState({
          items,
          groups,
          status: "",
          loading: false,
          order,
          filter,
          nextHref: renderListDataAsStreamResult?.NextHref,
          columns
        });
      }
    }
  };

  private getPropertiesUrl = async (mode: number, item?: any) => {
    if (!this.state.appManifest) {
      const result = await this.props.apiClient.getListItems({
        webUrl: this.props.webUrl,
        listName: this.props.listName,
        renderListDataParameters: {
          AddRequiredFields: true,
          RenderOptions: 64,
          ViewXml: "<View><ViewFields><FieldRef Name='ID'/></ViewFields></View>"
        }
      });

      const appManifest: any = result.renderListDataAsStreamResponse;
      appManifest.powerAppForm =
        this.state.list.ListContenTypes.length &&
        appManifest.FormRenderModes[this.state.list.ListContenTypes[0].displayName] &&
        appManifest.FormRenderModes[this.state.list.ListContenTypes[0].displayName].Display
          .RenderType === 3 &&
        appManifest.AppId;

      this.setState({ selectedItem: item, appManifest: appManifest });

      if (appManifest.powerAppForm) {
        return this.getPropertiesUrlFromAppManifest(appManifest);
      } else {
        return this.getPropertiesUrlFromList(mode, item);
      }
    } else if (this.state.appManifest.powerAppForm) {
      return this.getPropertiesUrlFromAppManifest(this.state.appManifest);
    } else {
      return this.getPropertiesUrlFromList(mode, item);
    }
  };

  private getPropertiesUrlFromList = (mode: number, item?: any): string => {
    switch (mode) {
      case 1:
        return this.state.list.newFormUrl + "&hideTopNavBar=1&IsDlg=1";
      case 2:
        return this.state.list.editFormUrl + "&ID=" + item.ID + "&hideTopNavBar=1&IsDlg=1";
      default:
        return this.state.list.displayFormUrl + "&ID=" + item.ID + "&hideTopNavBar=1&IsDlg=1";
    }
  };

  private getPropertiesUrlFromAppManifest = (appManifest: any): string => {
    return (
      "https://web.powerapps.com/webplayer/iframeapp?source=iframe&locale=en-US&appId=/providers/Microsoft.PowerApps/apps/" +
      appManifest.AppId +
      "&hideNavBar=true&hideAppSplash=true&sdkVersion=1.3.10&iframeContainerId=iframeContainer&locale=en-US&screenColor=rgb(255,255,255,1)"
    ); // + appManifest.backgroundColor;
  };

  private onMessage = (e: any) => {
    if (
      e &&
      e.data &&
      this.state.appManifest &&
      e.data.appId === "/providers/Microsoft.PowerApps/apps/" + this.state.appManifest.AppId
    ) {
      if (this.frame) {
        if (e.data.api === 1) {
          this.postMessageToPowerApp(this.state.itemMode ?? 0, this.state.selectedItem);
          this.setState({ powerAppLoaded: true, loadingPropsPanel: false });
        } else if (e.data.api === 4) {
          this.hidePropsPanel(this.state.itemMode === 1 || this.state.itemMode === 2);
        }
      }
    }
  };

  private postMessageToPowerApp = (mode: number, item?: any) => {
    let params;
    switch (mode) {
      case 1: //newItem
        params = {
          api: 6
        };
        break;
      case 2: //editItem
        params = {
          api: 7,
          itemId: item.ID
        };
        break;
      default:
        //viewItem
        params = {
          api: 8,
          itemId: item.ID
        };
        break;
    }

    this.frame?.contentWindow?.postMessage(params, "*");
  };

  private openItemPanel = (e: any, mode: number, item?: any) => {
    if (e) {
      e.preventDefault();
    }

    if (this.props.onTitleLinkClick) {
      this.props.onTitleLinkClick(item);
    } else if (this.props.panelComponent) {
      //this.props.newFormComponent.load();
      this.setState({
        showPropsPanel: true,
        itemMode: mode,
        loadingPropsPanel: false,
        selectedItem: item
      });
    } else {
      this.setState({
        showPropsPanel: true,
        itemMode: mode,
        loadingPropsPanel: !this.state.powerAppLoaded,
        selectedItem: item
      });
      this.getPropertiesUrl(mode, item).then((iFrameUrl) => {
        this.setIFrame();

        if (this.frame && this.frame.src !== iFrameUrl) {
          this.frame.src = iFrameUrl;
        }

        if (this.state.powerAppLoaded) {
          this.postMessageToPowerApp(mode, item);
        }
      });
    }
  };

  private setIFrame = () => {
    if (!this.frame) {
      let iframeElement: HTMLIFrameElement = document.createElement("iframe");
      iframeElement.style.display = "none";
      iframeElement.onload = () => {
        if (iframeElement.src.length) {
          iframeElement.style.display = "";
          setTimeout(() => {
            this.setState({ loadingPropsPanel: false });
          }, 100);
        }
        (iframeElement as any)["cancelPopUp"] = (rez: any) => {
          this.hidePropsPanel(false);
        };
        (iframeElement as any)["commitPopup"] = (rez: any) => {
          this.hidePropsPanel(true);
        };
      };
      iframeElement.scrolling = "no";
      iframeElement.setAttribute("allow", "geolocation *; microphone *; camera *");
      //iframeElement.id = iframeId;
      //iframeElement.name = iframeId;

      let width = 660,
        height = 1900;
      if (
        this.state.appManifest &&
        this.state.appManifest.powerAppForm &&
        this.state.appManifest.AppRenderInfo
      ) {
        width = this.state.appManifest.AppRenderInfo.PrimaryDeviceWidth;
        height = this.state.appManifest.AppRenderInfo.PrimaryDeviceHeight;
      }

      iframeElement.setAttribute(
        "style",
        "position:absolute;left:0;right:0;bottom:0;top:0;width:" +
          width +
          "px;height:" +
          height +
          "px"
      );

      if (!navigator.userAgent.match("Edge")) {
        // only add the sandboxing attribute on non Edge browsers.
        // This is because Edge prevents the pop ups of the disambiguation dialog if the sandboxing attribute is present.
        let sandboxProps = [
          "allow-popups",
          "allow-popups-to-escape-sandbox",
          "allow-same-origin",
          "allow-scripts",
          "allow-forms",
          "allow-orientation-lock"
        ];
        if (iframeElement.sandbox && iframeElement.sandbox.add) {
          sandboxProps.forEach(function (prop) {
            return iframeElement.sandbox.add(prop);
          });
        } else {
          iframeElement.setAttribute("sandbox", sandboxProps.join(" "));
        }
      }
      iframeElement.style.boxSizing = "border-box";
      iframeElement.style.border = "none";
      this.frameContainer?.appendChild(iframeElement);

      this.frame = iframeElement;
    }
  };

  private _onItemsSelectionChanged = () => {
    //TODO for classifiers
    // this.setState({
    //   ...
    // });
  };

  private hidePropsPanel = (refreshData?: boolean) => {
    this.setState({ showPropsPanel: false });
    if (this.frame && this.state.appManifest && !this.state.appManifest.powerAppForm) {
      this.frame.src = "about:blank";
    }

    if (refreshData && this.props.listName) {
      if (this.state.selectedItem) {
        getListItemById(
          this.props.apiClient,
          this.props.webUrl,
          this.props.listName,
          this.state.selectedItem.ID
        ).then((queriedItem) => {
          let listItems = this.state.items;
          let listItemsID = listItems.map((item) => {
            return item.ID;
          });
          let index = listItemsID.indexOf(queriedItem.ID);
          listItems[index] = { ...this.state.items[index], ...queriedItem };
          this.setState({ items: listItems });

          this._selection.toggleIndexSelected(index);
          this._selection.toggleIndexSelected(index);
        });
      } else {
        this.getItems(
          this.props.webUrl,
          this.props.listName,
          this.props.viewName,
          this.state.columns,
          this.state.order,
          this.state.filter,
          false,
          this.props.camlFilter,
          this.props.searchText
        );
      }
    }
  };

  private hideFilterPanel = (refreshData?: boolean) => {
    this.setState({ showFilterPanel: false });
  };

  private hideFilterDetailsPanel = (executeFilter?: number) => {
    if (executeFilter) {
      let filter = this.state.filter;
      this.state.selectedFilter?.options.forEach((o) => {
        o.selected = executeFilter === 1 && this.detailFilterSelectedOptions.indexOf(o.value) > -1;
      });

      let columns = this.state.columns!.map((col) => {
        let fs = filter.filter((f) => f.name === col.key);
        col.isFiltered = fs.length > 0 && fs[0].options.filter((o) => o.selected).length > 0;
        return col;
      });

      //this.setCachedFilter(filter);

      this.getItems(
        this.props.webUrl,
        this.props.listName,
        this.props.viewName,
        columns,
        this.state.order,
        filter,
        false,
        this.props.camlFilter,
        this.props.searchText
      );
    }

    this.setState({ showFilterDetailsPanel: false });
  };

  private getIFrameStyles = () => {
    if (
      this.state.appManifest &&
      this.state.appManifest.powerAppForm &&
      this.state.appManifest.AppRenderInfo
    ) {
      return this.state.appManifest.AppRenderInfo.PrimaryDeviceWidth + "px";
    }
    return window.innerWidth < 685 ? `${window.innerWidth}px` : "685px";
  };

  private openFile = (e: any, item: any) => {
    e.preventDefault();
    window.open(item["FileRef"]);
  };

  private _onVisibilitySensorChange = (isVisible: boolean) => {
    if (
      isVisible &&
      this.state.items.length > 0 &&
      !this.state.loading &&
      !isEmpty(this.state.nextHref)
    ) {
      this.getItems(
        this.props.webUrl,
        this.props.listName,
        this.props.viewName,
        this.state.columns,
        this.state.order,
        this.state.filter,
        true,
        this.props.camlFilter,
        this.props.searchText
      );
    }
  };

  private _onRenderMissingItem = (index?: number, rowProps?: IDetailsRowProps): null => {
    return null;
  };

  private getColumns = (selectedColumns: ListViewColumn[]): IColumn[] => {
    const columns: IColumn[] = [];
    selectedColumns.forEach((element) => {
      const column: IColumn = {
        key: element.key,
        name: element.title ? element.title : element.key,
        fieldName: element.key,
        minWidth: 100,
        isResizable: true,
        isMultiline: element.type === "Note",
        data: element,
        onColumnContextMenu: this._onColumnContextMenu
      };

      if (element.key === "LinkTitle") {
        column.onRender = (item: any) => {
          return this.props.getItemLink ? (
            this.props.getItemLink(item)
          ) : (
            <Link onClick={(e) => this.openItemPanel(e, 0, item)}>{item["Title"]}</Link>
          );
        };
      } else if (element.key === "FileLeafRef") {
        column.onRender = (item: any) => {
          return <Link onClick={(e) => this.openFile(e, item)}>{item[element.key]}</Link>;
        };
      } else if (element.type === "DateTime") {
        column.onRender = (item: any) => {
          return item[element.key];
        };
      } else if (element.type === "Lookup" || element.type === "LookupMulti") {
        column.onRender = (item: any) => {
          let textVal = "";
          if (item[element.key]) {
            (item[column.key] as Array<any>).forEach((v) => {
              textVal += (textVal.length ? "; " : "") + v.lookupValue;
            });
          }
          return textVal;
        };
      } else if (element.type === "User" || element.type === "UserMulti") {
        column.onRender = (item: any) => {
          let textVal = "";
          if (item[element.key]) {
            (item[column.key] as Array<any>).forEach((v) => {
              textVal += (textVal.length ? "; " : "") + v.title;
            });
          }
          return textVal;
        };
      } else if (element.type === "TaxonomyFieldType") {
        column.onRender = (item: any) => {
          let textVal = "";
          if (item[element.key]) {
            textVal = item[element.key].Label;
          }
          return textVal;
        };
      } else {
        column.onRender = (item: any) => {
          return item[element.key];
        };
      }

      columns.push(column);

      if (element.key === "LinkTitle" || element.key === "FileLeafRef") {
        columns.push({
          name: "",
          minWidth: 30,
          key: "options",
          isSorted: false,
          isSortedDescending: true,
          onRender: (item: any) => {
            return (
              <IconButton
                text=""
                width="30"
                split={false}
                onClick={(e: any) => this._onItemContextMenu(item, 1, e)}
                iconProps={{ iconName: "MoreVertical" }}
              />
            );
          }
        });
      }
    });

    return columns;
  };

  private _onColumnClick = (ev?: React.MouseEvent<HTMLElement>, column?: IColumn): void => {
    if (column && ev && column.columnActionsMode !== ColumnActionsMode.disabled) {
      this.setState({
        contextualMenuProps: this._getContextualMenuProps(column, ev)
      });
    }
  };

  private _onColumnContextMenu = (column?: IColumn, ev?: React.MouseEvent<HTMLElement>): void => {
    if (column && ev && column.columnActionsMode !== ColumnActionsMode.disabled) {
      this.setState({
        contextualMenuProps: this._getContextualMenuProps(column, ev)
      });
    }
  };

  private _getContextualMenuProps = (
    column: IColumn,
    ev: React.MouseEvent<HTMLElement>
  ): IContextualMenuProps => {
    let items: IContextualMenuItem[] = [];
    let columnProps = column.data as ListViewColumn;
    if (columnProps.sortable) {
      items.push({
        key: "aToZ",
        name:
          columnProps.type === "DateTime"
            ? "Older to newer"
            : columnProps.type === "Number"
            ? "Smaller to larger"
            : columnProps.type === "Text" || columnProps.type === "User"
            ? "A to Z"
            : "Ascending",
        iconProps: { iconName: "SortUp" },
        canCheck: true,
        checked: column.isSorted && !column.isSortedDescending,
        onClick: () => this._onSortColumn(column.key, false)
      });
      items.push({
        key: "zToA",
        name:
          columnProps.type === "DateTime"
            ? "Newer to older"
            : columnProps.type === "Number"
            ? "Larger to smaller"
            : columnProps.type === "Text" || columnProps.type === "User"
            ? "Z to A"
            : "Descending",
        iconProps: { iconName: "SortDown" },
        canCheck: true,
        checked: column.isSorted && column.isSortedDescending,
        onClick: () => this._onSortColumn(column.key, true)
      });
    }
    if (columnProps.filterable) {
      items.push({
        key: "filtrBy",
        name: "Filter by",
        iconProps: { iconName: "Filter" },
        canCheck: true,
        checked: column.isFiltered,
        onClick: () => this._onFilterColumn(columnProps)
      });
      if (column.isFiltered) {
        items.push({
          key: "Clearfilters",
          name: "Clear filters",
          iconProps: { iconName: "ClearFilter" },
          canCheck: true,
          checked: false,
          onClick: () => this._onFilterClearColumn(columnProps)
        });
      }
    }

    return {
      items: items,
      target: ev.currentTarget as HTMLElement,
      directionalHint: DirectionalHint.bottomLeftEdge,
      gapSpace: 10,
      isBeakVisible: true,
      onDismiss: this._onContextualMenuDismissed
    };
  };

  private _onSortColumn = (columnKey: string, isSortedDescending: boolean): void => {
    let columns = this.state.columns!.map((col) => {
      col.isSorted = col.key === columnKey;
      if (col.isSorted) {
        col.isSortedDescending = isSortedDescending;
      }
      return col;
    });

    this.getItems(
      this.props.webUrl,
      this.props.listName,
      this.props.viewName,
      columns,
      { orderBy: columnKey, isOrderDescending: isSortedDescending },
      this.state.filter,
      false,
      this.props.camlFilter,
      this.props.searchText
    );
  };

  private _onFilterColumn = (column: ListViewColumn): void => {
    this.setState({ showFilterPanel: true });
  };

  private _onFilterClearColumn = (column: ListViewColumn): void => {
    let filter = this.state.filter;
    let filterSelected = filter.filter((f) => f.name === column.key);
    if (filterSelected.length) {
      filterSelected[0].options.forEach((o) => {
        o.selected = false;
      });
    }

    let columns = this.state.columns!.map((col) => {
      if (col.key === column.key) {
        col.isFiltered = false;
      }
      return col;
    });

    this.getItems(
      this.props.webUrl,
      this.props.listName,
      this.props.viewName,
      columns,
      this.state.order,
      filter,
      false,
      this.props.camlFilter,
      this.props.searchText
    );
  };

  private _onContextualMenuDismissed = (): void => {
    this.setState({
      contextualMenuProps: undefined
    });
  };

  private _actionHandlers: ListViewActionHandlers = {
    onDelete: (ids: number[]) => {
      const items = [...this.state.items];
      ids.forEach((id) => {
        const itemIndex = items.findIndex((item) => item.ID === id);
        if (itemIndex >= 0) {
          items.splice(itemIndex, 1);
        }
      });
      if (this.state.groupFieldName) {
        const groups = this.props.apiClient.getItemGroups(items, this.state.groupFieldName);
        this.setState({ items, groups });
      } else {
        this.setState({ items });
      }
    },
    onAdd: (item: any) => {
      if (this.props.listName) {
        addItemToList(
          this.props.apiClient,
          this.props.webUrl,
          this.props.listName,
          this.state.items,
          item.ID,
          this.state.groupFieldName
        ).then(({ items, groups }) => {
          if (groups) {
            this.setState({ items, groups });
          } else {
            this.setState({ items });
          }
        });
      }
    },
    onUpdate: (item: any, skipGet?: boolean) => {
      if (this.props.listName) {
        if (skipGet) {
          this.updateItem(item);
        } else {
          getListItemById(
            this.props.apiClient,
            this.props.webUrl,
            this.props.listName,
            item.ID
          ).then((queriedItem) => {
            this.updateItem(queriedItem);
          });
        }
      }
    }
  };

  private updateItem = (item: any) => {
    let listItems = [...this.state.items];
    let index = listItems.findIndex((listItem) => item.ID == listItem.ID);
    if (index > -1) {
      listItems.splice(index, 1, item);
      this.setState({
        items: listItems
      });
    } else {
      this.getItems(
        this.props.webUrl,
        this.props.listName,
        this.props.viewName,
        this.state.columns,
        this.state.order,
        this.state.filter,
        false,
        this.props.camlFilter,
        this.props.searchText
      );
    }
  };

  private _onItemContextMenu = (item?: any, index?: number, ev?: Event): boolean => {
    if (index !== undefined && index > -1) {
      const contextualMenuProps: IContextualMenuProps = {
        target: ev?.target as HTMLElement,
        items: [],
        onDismiss: () => {
          this.setState({
            contextualMenuProps: undefined
          });
        }
      };

      if (this.props.getContextualMenuItems) {
        contextualMenuProps.items = this.props.getContextualMenuItems(this._actionHandlers, item);
      } else {
        contextualMenuProps.items = [
          {
            key: "text",
            name: "View Item",
            onClick: (
              ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
              contItem?: IContextualMenuItem
            ) => {
              this.openItemPanel(null, 0, item);
            }
          },
          {
            key: "text",
            name: "Edit Item",
            onClick: (
              ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
              contItem?: IContextualMenuItem
            ) => {
              this.openItemPanel(null, 2, item);
            }
          }
        ];
      }

      this.setState({
        contextualMenuProps
      });
    }

    return false;
  };

  private openAllOptionsPanel = async (fieldName: string) => {
    this.detailFilterSelectedOptions = [];
    let filter = this.state.filter;
    let filterSelected = filter.filter((f) => f.name === fieldName);
    if (filterSelected.length) {
      let url =
        (this.props.webUrl ? this.props.webUrl : this.props.apiClient.siteUrl) +
        "/_api/web/getList('" +
        this.props.apiClient.serverRelativeUrl +
        "/" +
        this.props.listName +
        "')/RenderListFilterData?&FieldInternalName='" +
        fieldName +
        "'&ViewId='" +
        this.state.list.ViewMetadata.Id +
        "'";

      const response = await this.props.apiClient.spClient.fetch(url, { method: "POST" });
      let responseText = await response.text();
      let selectObj = parseHTML(responseText);

      let currentOptions = filterSelected[0].options;
      let options: ListViewFilterOption[] = [];
      let optionsObj = selectObj.querySelectorAll("option");
      for (let i = 0; i < optionsObj.length; i++) {
        if (optionsObj[i].text !== "(All)") {
          let value = optionsObj[i].text;
          if (value === "(Empty)") {
            value = "";
          }

          let option = {
            title: optionsObj[i].text,
            value: value,
            selected:
              currentOptions.filter((o) => o.title === optionsObj[i].text && o.selected).length > 0
          };
          options.push(option);

          if (option.selected) {
            this.detailFilterSelectedOptions.push(option.value);
          }
        }
      }

      filterSelected[0].options = options;
      this.setState({
        filter,
        showFilterDetailsPanel: true,
        selectedFilter: filterSelected[0]
      });
    }
  };

  private _onFilterChange = (
    fieldName: string,
    fieldType: string,
    value: string,
    isChecked: boolean
  ): void => {
    let filter = cloneDeep(this.state.filter);
    let changedFilter = filter.filter((f) => f.name === fieldName);
    if (changedFilter.length) {
      let changedOption = changedFilter[0].options.filter((o) => o.title === value);
      if (changedOption.length) {
        changedOption[0].selected = isChecked;
      }
    }

    let columns = this.state.columns!.map((col) => {
      let fs = filter.filter((f) => f.name === col.key);
      col.isFiltered =
        fs.length > 0 && fs[0].options.filter((o) => o.title === value && o.selected).length > 0;
      return col;
    });

    //this.setCachedFilter(filter);

    this.getItems(
      this.props.webUrl,
      this.props.listName,
      this.props.viewName,
      columns,
      this.state.order,
      filter,
      false,
      this.props.camlFilter,
      this.props.searchText
    );
  };

  private _onDetailFilterChange = (
    fieldName: string,
    fieldType: string,
    value: string,
    isChecked: boolean
  ): void => {
    let selectedValueIndex = this.detailFilterSelectedOptions.indexOf(value);
    if (selectedValueIndex > -1 && !isChecked) {
      this.detailFilterSelectedOptions.splice(selectedValueIndex, 1);
    } else if (selectedValueIndex === -1 && isChecked) {
      this.detailFilterSelectedOptions.push(value);
    }
  };

  private _onRenderFilterDetailsPanelFooter = (): JSX.Element => {
    return (
      <div>
        <PrimaryButton
          onClick={(e) => {
            this.hideFilterDetailsPanel(1);
          }}
          style={{ marginRight: "8px" }}
        >
          Apply
        </PrimaryButton>
        <DefaultButton
          onClick={(e) => {
            this.hideFilterDetailsPanel(2);
          }}
        >
          Clear all
        </DefaultButton>
      </div>
    );
  };

  private getCheckedValue = (fieldName: string, value: string) => {
    let filterSearch = this.state.filter.filter((f) => f.name === fieldName);
    return (
      filterSearch.length > 0 &&
      filterSearch[0].options.filter((o) => o.title === value && o.selected).length > 0
    );
  };

  private isFiltered = () => {
    for (let filter of this.state.filter) {
      for (let option of filter.options) {
        if (option.selected === true) {
          return true;
        }
      }
    }
    return false;
  };

  private getDetailCheckedValue = (fieldName: string, value: string) => {
    return this.detailFilterSelectedOptions.indexOf(value) > -1;
  };

  private getFilters = () => {
    let filterComponents: any[] = [];

    this.state.filter.forEach((f, fi) => {
      //f.FieldType === "Text" || f.FieldType === "Computed" || f.FieldType === "User" || f.FieldType === "Lookup"
      if (f.options.length) {
        let footer;
        if (f.type !== "Choice") {
          footer = (
            <div>
              <FilterSeeAll
                onClick={() => {
                  this.openAllOptionsPanel(f.name);
                }}
              >
                See all
              </FilterSeeAll>
            </div>
          );
        }
        filterComponents.push(
          <FilterContainer key={fi}>
            <FilterTitle>{f.title}</FilterTitle>
            {f.options.map((c, index) => {
              if (f.type === "Choice" || index < 6) {
                return (
                  <FilterCheckbox
                    key={index}
                    label={c.title}
                    checked={this.getCheckedValue(f.name, c.value)}
                    onChange={(ev?: React.FormEvent<HTMLElement>, isChecked?: boolean) => {
                      this._onFilterChange(f.name, f.type, c.value, isChecked === true);
                    }}
                  />
                );
              }

              return null;
            })}
            {footer}
          </FilterContainer>
        );
      }
    });

    return filterComponents;
  };

  private getCachedFilter = () => {
    const cachedFilters = window.sessionStorage.getItem(this.filterCacheKey);
    if (cachedFilters) return JSON.parse(cachedFilters);
  };

  private setCachedFilter = (filter: any) => {
    window.sessionStorage.setItem(this.filterCacheKey, JSON.stringify(filter));
  };

  private getCollapsedGroups = (groups: IGroup[]) => {
    const collapsedGroupsItem = window.sessionStorage.getItem(this.collapsedGroupsCacheKey);
    if (collapsedGroupsItem) {
      const collapsedGroups: string[] = JSON.parse(collapsedGroupsItem);
      groups.forEach((g) => {
        if (collapsedGroups.indexOf(g.key) > -1) {
          g.isCollapsed = true;
        }
      });
    }
  };

  private setCollapsedGroups = (group: string, isCollapsed: boolean) => {
    const collapsedGroupsItem = window.sessionStorage.getItem(this.collapsedGroupsCacheKey);
    const collapsedGroups: string[] = collapsedGroupsItem ? JSON.parse(collapsedGroupsItem) : [];

    const groupIndex = collapsedGroups.indexOf(group);
    if (isCollapsed) {
      if (groupIndex < 0) {
        collapsedGroups.push(group);
      }
    } else {
      if (groupIndex > -1) {
        collapsedGroups.splice(groupIndex, 1);
      }
    }
    //window.sessionStorage.setItem(this.collapsedGroupsCacheKey, JSON.stringify(collapsedGroups));
  };

  private onGroupCollapse = (group: IGroup) => {
    if (this.props.onGroupCollapse) {
      this.props.onGroupCollapse(group);
    }
  };

  private getDetailFilter = () => {
    if (this.state.showFilterDetailsPanel && this.state.selectedFilter) {
      return (
        <FilterContainer>
          <FilterTitle>{this.state.selectedFilter.title}</FilterTitle>
          {this.state.selectedFilter.options.map((c) => (
            <FilterCheckbox
              label={c.title}
              defaultChecked={this.getDetailCheckedValue(
                this.state.selectedFilter?.name ?? "",
                c.value
              )}
              onChange={(ev?: React.FormEvent<HTMLElement>, isChecked?: boolean) => {
                this._onDetailFilterChange(
                  this.state.selectedFilter?.name ?? "",
                  this.state.selectedFilter?.type ?? "",
                  c.value,
                  isChecked === true
                );
              }}
            />
          ))}
        </FilterContainer>
      );
    }

    return null;
  };

  public render(): JSX.Element {
    let PanelComponent;
    if (this.props.panelComponent) {
      let PanelWPComponent = this.props.panelComponent;
      PanelComponent = (
        <PanelWPComponent itemMode={this.state.itemMode} hidePanel={this.hidePropsPanel} />
      );
    } else {
      PanelComponent = <div id="iframeContainer" ref={(div) => (this.frameContainer = div)}></div>;
    }

    let { items, groups, columns, loading } = this.state;
    return (
      <>
        <CommandBar
          items={
            this.props.getCommandBarItems ? this.props.getCommandBarItems(this._actionHandlers) : []
          }
          farItems={
            this.props.getCommandBarFarItems
              ? this.props.getCommandBarFarItems(this._actionHandlers)
              : []
          }
          styles={{ root: { alignItems: "center" } }}
        />
        <Separator styles={{ root: { padding: "1px", height: "1px" } }} />
        <ShimmeredDetailsList
          {...this.props}
          items={items}
          groups={groups}
          columns={columns}
          enableShimmer={(this.props.enableShimmer || this.state.loading) && items.length === 0}
          onColumnHeaderClick={this._onColumnClick}
          onItemContextMenu={this._onItemContextMenu}
          selectionZoneProps={{
            selection: this._selection,
            disableAutoSelectOnInputElements: true
          }}
          groupProps={{
            showEmptyGroups: false,
            headerProps: {
              onToggleCollapse: (g) => {
                //this.setCollapsedGroups(g.key, !g.isCollapsed);
                this.onGroupCollapse(g);
              }
            }
          }}
          onRenderMissingItem={this._onRenderMissingItem}
          setKey={"processListKey"}
        />
        {items.length === 0 && !loading && (
          <NoItemsMessage>{this.props.emptyMessage}</NoItemsMessage>
        )}
        {this.state.contextualMenuProps && <ContextualMenu {...this.state.contextualMenuProps} />}
        {!this.state.loading && (
          <VisibilitySensor onChange={this._onVisibilitySensorChange} active={this.props.showPager}>
            <span>&nbsp;</span>
          </VisibilitySensor>
        )}

        <Panel
          isLightDismiss={true}
          isOpen={this.state.showPropsPanel}
          type={PanelType.custom}
          customWidth={this.getIFrameStyles()}
          onDismiss={() => this.hidePropsPanel(false)}
          closeButtonAriaLabel="Close"
          isBlocking={true}
          isHiddenOnDismiss={true}
        >
          {this.state.loadingPropsPanel && <Spinner label="Loading properties form..." />}
          <div style={{ display: this.state.loadingPropsPanel ? "none" : "block" }}>
            {PanelComponent}
          </div>
        </Panel>
        <Panel
          isLightDismiss={true}
          isOpen={this.state.showFilterPanel}
          type={PanelType.smallFixedFar}
          onDismiss={() => this.hideFilterPanel(false)}
          closeButtonAriaLabel="Close"
          isBlocking={false}
          isHiddenOnDismiss={true}
        >
          <FilterHeader>
            <span>Filters</span>
            <Icon iconName="Filter"></Icon>
          </FilterHeader>

          {this.getFilters()}

          <Panel
            isLightDismiss={true}
            isOpen={this.state.showFilterDetailsPanel}
            type={PanelType.smallFixedFar}
            onDismiss={() => this.hideFilterDetailsPanel()}
            closeButtonAriaLabel="Close"
            isBlocking={false}
            isHiddenOnDismiss={true}
            isFooterAtBottom={true}
            onRenderFooterContent={this._onRenderFilterDetailsPanelFooter}
          >
            {this.getDetailFilter()}
          </Panel>
        </Panel>
      </>
    );
  }
}

export const getListItemById = async (
  apiClient: ApiClient,
  webUrl: string = "",
  listName: string,
  itemId: Number
) => {
  const query = new Map<string, string>();
  query.set("FilterField1", "ID");
  query.set("FilterValue1", itemId.toString());

  const result = await apiClient.getListItems({
    webUrl,
    listName,
    renderListDataParameters: { AddAllFields: true },
    renderListDataOverrideParams: {},
    renderListDataQuery: query
  });

  if (result.items.length > 0) {
    return result.items[0];
  } else {
    return null;
  }
};

export const addItemToList = async (
  apiClient: ApiClient,
  webUrl: string | undefined,
  listName: any,
  stateItems: any[],
  itemId: Number,
  groupFieldName?: any,
  itemIdToReplace?: number
): Promise<{ items: any; groups?: any }> => {
  const queriedItem = await getListItemById(apiClient, webUrl, listName, itemId);
  if (!queriedItem.wpProcessGroups) {
    queriedItem.wpProcessGroups = "";
  }
  const items = [...stateItems];
  let itemToReplaceIndex = itemIdToReplace
    ? items.findIndex((i) => i["ID"] == itemIdToReplace)
    : -1;

  if (itemToReplaceIndex >= 0) {
    if (
      !groupFieldName ||
      items[itemToReplaceIndex][groupFieldName + ".singleurlencoded"] ===
        queriedItem[groupFieldName + ".singleurlencoded"]
    ) {
      items.splice(itemToReplaceIndex, 1, queriedItem);
    } else {
      items.splice(itemToReplaceIndex, 1);
      const index = items.findIndex(
        (i) =>
          i[groupFieldName + ".singleurlencoded"] ===
          queriedItem[groupFieldName + ".singleurlencoded"]
      );
      items.splice(index, 0, queriedItem);
    }
  } else if (groupFieldName) {
    const index = items.findIndex(
      (i) =>
        i[groupFieldName + ".singleurlencoded"] ===
        queriedItem[groupFieldName + ".singleurlencoded"]
    );
    items.splice(index, 0, queriedItem);
  } else {
    items.push(queriedItem);
  }

  if (groupFieldName) {
    const groups = apiClient.getItemGroups(items, groupFieldName);
    return { items, groups };
  } else {
    return { items };
  }
};

const NoItemsMessage = styled(Text)`
  margin: 50px;
  display: flex;
  justify-content: center;
`;

const FilterHeader = styled(({ className, children }) => (
  <div className={className}>
    <span>Filters</span>
    <Icon iconName="Filter"></Icon>
  </div>
))`
  margin-bottom: 18px;

  & span {
    font-size: 21px;
    font-weight: 100;
  }

  & i {
    float: right;
  }
`;

const FilterContainer = styled.div`
  margin-bottom: 15px;
`;

const FilterTitle = styled.div`
  padding: 6px 0;
`;

const FilterCheckbox = styled(Checkbox)`
  padding: 8px 0;
`;

const FilterSeeAll = styled(Link)`
  font-size: 14px;
  font-weight: 400;
  color: #94979c;
`;
