import { useNavigate, useParams } from "react-router-dom";
import { ProcessTrigger } from "@workpoint/components/lib/models/ProcessConfiguration";
import { ToRoute } from "../../../../routes";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { processSelector, updateConfiguration } from "../../../../store/processReducer";
import { RowType } from "../../../../utils/processUtils";
import { ListBase } from "./ListBase";

export const TriggerList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { process } = useAppSelector(processSelector);

  const _updateTrigggers = (triggers: ProcessTrigger[]) => {
    const newConfig = { ...process.configuration, triggers };
    dispatch(updateConfiguration(newConfig));
  };

  const _openNewTriggerPanel = (index: string) => {
    navigate(ToRoute.processNewTrigger(index));
  };

  const _editTrigger = (index: string) => {
    navigate(ToRoute.processTrigger(process, index));
  };

  return (
    <ListBase
      items={process?.configuration?.triggers ?? []}
      openNewItemPanel={_openNewTriggerPanel}
      editItem={_editTrigger}
      updateItems={_updateTrigggers}
      rowType={RowType.Trigger}
    />
  );
};

