import { Breadcrumb, IBreadcrumbItem, Stack, Toggle } from "@fluentui/react";
import styled from "styled-components";
import { WorkPointTheme, backendPool, Version } from "@workpoint/components/lib/constants";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { globalSelector, setShowGuidance } from "../../store/globalReducer";
import { Link as RouterLink, useLocation, useNavigate, useResolvedPath } from "react-router-dom";
import { UserMenu } from "./user/UserMenu";
import { pageHeaderHeight, sideNavWidth } from "./AppChrome";
import { INavigationEntry } from "../../models/Navigation";
import { AppRoutes } from "../../routes";
import { useNavigation } from "../../hooks/useNavigation";

interface FullPageHeaderProps {
  title: string;
  showGuidanceToggle?: boolean;
}

/**
 * @todo: Improve with named/localized routes
 * @todo: Improve with blacklisting unusable routes, eg. /notification/profile/edit/
 */
const buildBreadcrumbs = (pathname: string, navStack: INavigationEntry[]): IBreadcrumbItem[] => {
  let breadcrumbs: { text: string; key: string; href: string }[] = [];

  const parts = pathname.split("/");

  let partsLength = parts.length;

  /**
   * If path is an admin path, create the breadcrumbs based on the navigation stack.
   */
  if (navStack.length > 0) {
    breadcrumbs = navStack.map((nav) => {
      return {
        text: nav.name,
        href: nav.url,
        key: nav.name
      };
    });
    return breadcrumbs;
  }

  while (partsLength--) {
    let part = parts[partsLength];
    if (!!part)
      breadcrumbs.push({
        text: part.charAt(0).toUpperCase() + part.slice(1),
        key: part,
        href: parts.slice(0, partsLength + 1).join("/")
      });
  }

  breadcrumbs.push({
    text: "Solution",
    key: "/",
    href: "/"
  });

  return breadcrumbs.reverse();
};

export const FullPageHeader = ({ title, showGuidanceToggle }: FullPageHeaderProps) => {
  const { showGuidance } = useAppSelector(globalSelector);
  const { navStack } = useNavigation();
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const location = useLocation();
  // const breadcrumbItems = buildBreadcrumbs(location.pathname, navStack);

  return (
    <HeaderStack horizontal>
      {/* Branding */}
      <Stack.Item disableShrink>
        <BrandingStack horizontal tokens={{ maxWidth: sideNavWidth }}>
          <NonStyledLink to="/">
            <Image src={`${process.env.PUBLIC_URL}/assets/wpLogo.png`} alt="WorkPoint Logo" />
          </NonStyledLink>
          <NonStyledLink to="/">
            <Stack>
              <Title id="title">{title.toUpperCase()}</Title>
              <VersionSpan id="version">
                v{process.env.REACT_APP_VERSION} (v{Version})
              </VersionSpan>
            </Stack>
          </NonStyledLink>
        </BrandingStack>
      </Stack.Item>

      {/* Breadcrumb */}
      {/* <Stack.Item grow>
        <StyledBreadcrumb
          styles={{
            listItem: {
              ":last-child": {
                a: {
                  color: "white!important",
                  fontWeight: "normal!important",
                  fontSize: "16px!important"
                }
              }
            },
            chevron: {
              color: "white",
              fontWeight: "bold",
              fontSize: "12px"
            },
            itemLink: {
              color: "white",
              fontWeight: "normal",
              fontSize: "16px"
            }
          }}
          items={breadcrumbItems.map((bi) => {
            return {
              ...bi,
              onClick: (event: any, item: any) => {
                if (item?.href) {
                  event?.preventDefault();
                  navigate(item?.href as string, { replace: true });
                }
              }
            };
          })}
          maxDisplayedItems={10}
          ariaLabel="Breadcrumb with items rendered as buttons"
          overflowAriaLabel="More links"
        />
      </Stack.Item> */}

      <Stack.Item grow shrink>
        {" "}
      </Stack.Item>
      {showGuidanceToggle && (
        <Toggle
          label="Guidance"
          inlineLabel={true}
          styles={{
            root: { marginBottom: "0px", marginRight: "10px" },
            label: { color: "white" }
          }}
          checked={showGuidance}
          onChange={(e, checked) => {
            dispatch(setShowGuidance(checked ?? false));
          }}
        />
      )}
      <Stack.Item disableShrink align="center">
        <UserMenu />
      </Stack.Item>
    </HeaderStack>
  );
};

const Image = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 30px;
  margin-right: 12px;
`;

const Title = styled.span`
  color: white;
  font-family: Calibri;
  font-size: 16px;
  font-weight: bold;
`;

const VersionSpan = styled.span`
  color: #858585;
  font-family: Calibri;
  font-size: 10px;
`;

const BrandingStack = styled(Stack)`
  background-color: #123c59;
  height: ${pageHeaderHeight}px;
  display: flex;
  align-items: center;
  width: ${sideNavWidth}px;
`;

const HeaderStack = styled(Stack)`
  background-color: ${WorkPointTheme.palette.blue};
  height: ${pageHeaderHeight}px;
  display: flex;
  align-items: center;
`;

const NonStyledLink = styled(RouterLink)`
  text-decoration: none;
`;

const StyledBreadcrumb = styled(Breadcrumb)`
  color: white !important;
  margin: 0;
  margin-left: 16px;
  a: {
    color: white !important;
  }
`;
