import { Icon } from "@fluentui/react";
import { INavLink, INavStyles, Nav } from "@fluentui/react/lib/components/Nav";
import React, { useEffect } from "react";
import { useState } from "react";
import { NavigateFunction } from "react-router-dom";
import styled from "styled-components";
import { useApiClient } from "@workpoint/components/lib/clients/ApiProvider";
import { INavigationEntry } from "../../models/Navigation";
import { globalSelector } from "../../store/globalReducer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { clearNavigation } from "../../store/navigationReducer";
import { pageHeaderHeight, searchFilterHeight, tabHeight } from "./AppChrome";
import { withRouter } from "./withRouter";

export interface INavProps {
  navigate: NavigateFunction;
  menuItems: INavLink[];
  navStack: INavigationEntry[];
}

/**
 * Navigation wrapper to ensure SPA behavior with FluentUI <Nav> component, which triggers browser navigation.
 * @param props
 * @returns
 */
const UnstyledRouterNav = withRouter((props: INavProps) => {
  const { menuItems, navigate } = props;

  const dispatch = useAppDispatch();
  const { spHostUrl } = useAppSelector(globalSelector);
  const { apiClient } = useApiClient();
  const [selectedKey, setSelectedKey] = useState<string>();

  const toggleFavorite = async (key: string, exists: boolean) => {
    await (exists
      ? apiClient.deleteWP(`/Users/AdminFavorites/${key}`)
      : apiClient.postWP(`/Users/AdminFavorites`, JSON.stringify(key)));
    dispatch(clearNavigation());
  };

  const navStyles: Partial<INavStyles> = {
    root: {
      overflowY: "auto",
      height: `calc(100vh - ${pageHeaderHeight + searchFilterHeight + tabHeight}px)`
    }
  };

  const getNavKey = () => {
    const navs = props.navStack;
    for (let i = 0; i < navs.length; i++) {
      if (navs[i].url === window.location.pathname) {
        return navs[i].key;
      }

      if (navs[i].pivots) {
        const match = navs[i].pivots?.findIndex((nav) => nav.url === window.location.pathname);
        if (match) {
          return navs[i].key;
        }
      }
    }
  };

  useEffect(() => {
    setSelectedKey(getNavKey());
  }, [props.navStack]);

  return (
    <Nav
      styles={navStyles}
      selectedKey={selectedKey}
      onLinkClick={(event, element) => {
        if (element?.url) {
          event?.preventDefault();
          /**
           * External links are handled pretty basic here. This could potentially cause problems.
           */
          setSelectedKey(element.key);
          if (element.url.toLowerCase().trim().indexOf("http") === 0)
            window.location.replace(element.url);
          else {
            navigate(
              { pathname: element?.url as string, search: `SPHostUrl=${spHostUrl}` },
              { replace: true }
            );
          }
        }
      }}
      groups={[{ links: menuItems }]}
      onRenderLink={(props, defaultRender) => {
        const extendedNavlinkProps = props as any;

        if (extendedNavlinkProps.secondaryText)
          return (
            <NavigationRow
              showFavorite
              linkKey={props?.key}
              isFavorite={extendedNavlinkProps.isFavorite}
              toggleFavorite={toggleFavorite}
            >
              <UnstyledTwoLineResult
                primary={extendedNavlinkProps.name}
                secondary={extendedNavlinkProps.secondaryText}
              />
            </NavigationRow>
          );

        // Do not let groups be favouritable.
        if (!!(extendedNavlinkProps as INavLink).links)
          return (
            <NavigationRow linkKey={props?.key}>
              {(defaultRender as DefaultRenderFunction)(extendedNavlinkProps)}
            </NavigationRow>
          );

        return (
          <NavigationRow
            showFavorite
            linkKey={props?.key}
            isFavorite={extendedNavlinkProps.isFavorite}
            toggleFavorite={toggleFavorite}
          >
            {(defaultRender as DefaultRenderFunction)(extendedNavlinkProps)}
          </NavigationRow>
        );
      }}
    />
  );
});

type DefaultRenderFunction = (props?: INavLink | undefined) => JSX.Element | null;

export const RouterNav = styled(UnstyledRouterNav)`
  overflow-y: auto;
`;

interface INavigationRowProps {
  linkKey?: string;
  isFavorite?: boolean;
  showFavorite?: boolean;
  toggleFavorite?(key: string, exists: boolean): Promise<any>;
}

const NavigationRow: React.FC<INavigationRowProps> = (props) => (
  <div style={{ position: "relative", width: "100%" }}>
    <div style={{ width: "85%" }}>{props.children}</div>
    {props.showFavorite && (
      <StyledIconButton
        iconName={props.isFavorite ? "FavoriteStarFill" : "FavoriteStar"}
        onClick={(event) => {
          event?.preventDefault();

          event.stopPropagation();
          event.nativeEvent.stopImmediatePropagation();
          props?.toggleFavorite?.(props.linkKey as string, props.isFavorite as boolean);
        }}
      />
    )}
  </div>
);

const UnstyledTwoLineResult = (props: any) => (
  <TwoLineResult>
    <PrimaryText>{props.primary}</PrimaryText>
    <SecondaryText>{props.secondary}</SecondaryText>
  </TwoLineResult>
);

const TwoLineResult = styled.div`
  height: 44px;
`;

const PrimaryText = styled.div`
  font-size: 14px;
  text-align: left;
  height: 26px;
  line-height: 32px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const SecondaryText = styled.div`
  text-align: left;
  font-size: 11px;
  height: 16px;
  line-height: 12px;
  color: grey;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledIconButton = styled(Icon)`
  position: absolute;
  right: 0;
  top: 0;
`;
