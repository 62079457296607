import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { ITag, ITagPickerProps, Label, TagPicker } from "@fluentui/react";
import { FormControlProps } from "./ControlledTextField";
import { FieldErrorMessage } from "@workpoint/components/lib/components/form/inputs/FieldErrorMessage";

// export type ControlledTagPickerType = FormControlProps &
//   Omit<ITagPickerProps, "onResolveSuggestions"> & { label?: string; required?: boolean };

export type ControlledTagPickerType = FormControlProps &
  ITagPickerProps & {
    label?: string;
    required?: boolean;
    onLoadValue?: (value: any) => ITag[];
    onResolveChangedValue?: (items?: ITag[]) => any;
  };

export const ControlledTagPicker: FC<ControlledTagPickerType> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue ? props.defaultValue : ""}
      render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => {
        const selectedItems: ITag[] = props.onLoadValue
          ? props.onLoadValue(value)
          : Array.isArray(value)
          ? value
          : [{ key: value, name: value }];

        return (
          <>
            <Label htmlFor={props.name} required={props.required}>
              {props.label}
            </Label>
            <TagPicker
              {...props}
              onChange={(items?: ITag[]) => {
                const val = props.onResolveChangedValue
                  ? props.onResolveChangedValue(items)
                  : items;
                onChange(val);
                if (props.onChange) props.onChange(val);
                if (props.control._options.mode === "onBlur" && items && items.length > 0) {
                  onBlur();
                }
              }}
              selectedItems={selectedItems}
              onBlur={onBlur}
              inputProps={{
                id: props.name
              }}
            />
            {error && error.message && <FieldErrorMessage>{error.message}</FieldErrorMessage>}
          </>
        );
      }}
    />
  );
};
