import { v4 as uuid } from "uuid";

/**
 * @todo: Extract all these interface to be loaded from WorkPointComponents/src/models/notification/INotification.ts
 */

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

export interface INotificationSettings {
  Enabled: boolean;
  IsAutomaticDeletionEnabled: boolean;
  RetentionPeriodInDays: number;
  IsCustomizedAdaptiveCardLayoutEnabled: boolean;
  CustomizedAdaptiveCardLayoutJson: string;
  NotificationProfiles: INotificationProfile[];
  OnlyCleanupCompletedNotifications: boolean;
}

export interface INotificationType {
  ManagedMetadataName: string;
  ManagedMetadataId: string;
  ParentManagedMetadataName: string;
  ParentManagedMetadataId: string;
  Enable: boolean;
  EnableEmail: boolean;
  EnableTeams: boolean;
  IsLocked: boolean;
}

export type INotificationTypeOptions = "Enable" | "EnableEmail" | "EnableTeams" | "IsLocked";

export enum NotificationEmailSchedule {
  SendImmediatetly,
  SendDaily,
  SendWeekly
}

export interface INotificationProfile {
  Id: string;
  Name: string;
  EmailSchedule: NotificationEmailSchedule;
  DeliveryDayOfWeek: DayOfWeek;
  SpecificDeliveryTime: string | undefined;
  NotificationTypes: INotificationType[];
  Target: INotificationProfileTarget | undefined;
}

export interface INotificationProfileTarget {
  Identifier: string;
  Name: string;
}

// Defaults

export const InitialNotificationSettings: INotificationSettings = {
  Enabled: false,
  IsAutomaticDeletionEnabled: false,
  RetentionPeriodInDays: 0,
  IsCustomizedAdaptiveCardLayoutEnabled: false,
  CustomizedAdaptiveCardLayoutJson: "",
  NotificationProfiles: [],
  OnlyCleanupCompletedNotifications: false
};

export const InitialNotificationProfile = (): INotificationProfile => ({
  Id: uuid(),
  Name: "",
  EmailSchedule: NotificationEmailSchedule.SendImmediatetly,
  DeliveryDayOfWeek: DayOfWeek.Monday,
  SpecificDeliveryTime: undefined,
  NotificationTypes: [],
  Target: undefined
});

export interface INotificationAdaptiveCardTemplatePackage {
  DefaultNotificationAdaptiveCardTemplate: string;
  DemoPayload: string;
}
