import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./index.css";
import ReactDOM from "react-dom";
import { App } from "./components/App";
import reportWebVitals from "./utils/reportWebVitals";
import { Provider } from "react-redux";
import { IntlProvider } from "react-intl";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { ThemeProvider } from "@fluentui/react";
import { createStore } from "./store/store";
import messagesEN from "./locales/en";
import { WorkPointTheme } from "@workpoint/components/lib/constants";
import { PersistGate } from "redux-persist/integration/react";
import { InteractionType } from "@azure/msal-browser";
import { getApiClient, msalInstance } from "./clients/apiClientSetup";
import { ApiProvider } from "@workpoint/components/lib/clients/ApiProvider";
import { persistStore } from "redux-persist";

const apiClient = getApiClient();
const store = createStore(apiClient!);
const persistor = persistStore(store);

ReactDOM.render(
  // <React.StrictMode>
  <IntlProvider locale="en" messages={messagesEN} defaultLocale="en">
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={{ scopes: [process.env.REACT_APP_WPAPI_SCOPES as string] }}
      >
        <ApiProvider client={apiClient!}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ThemeProvider theme={WorkPointTheme}>
                <App />
              </ThemeProvider>
            </PersistGate>
          </Provider>
        </ApiProvider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </IntlProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
