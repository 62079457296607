import React, { useEffect } from "react";
import {
  ProcessParameterProperty,
  ProcessStepConfiguration,
  ProcessStepParameterConfiguration,
  ProcessTrigger
} from "@workpoint/components/lib/models/ProcessConfiguration";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { processSelector } from "../../../../store/processReducer";
import {
  ParametersForm,
  ParametersFormProps as ParametersFormPropsBase
} from "../../../common/parametersForm/ParametersForm";
import { get, merge, set } from "lodash";
import {
  defaultExpression,
  defaultDataSource,
  internalParameters,
  RowType,
  ContextType,
  defaultContext,
  getRowTypeFromPath,
  formDefaultParameters,
  FormParamType
} from "../../../../utils/processUtils";
import { globalSelector, setContext, startLoadingContext } from "../../../../store/globalReducer";
import { useApiClient } from "@workpoint/components/lib/clients/ApiProvider";
import { useLocation } from "react-router";

interface ProcessParametersFormProps
  extends Omit<
    ParametersFormPropsBase,
    | "processConfiguration"
    | "validationMessages"
    | "dynamicData"
    | "getDefaultFormData"
    | "contextInit"
  > {}

export const ProcessParametersForm = (props: ProcessParametersFormProps) => {
  const dispatch = useAppDispatch();
  const { apiClient } = useApiClient();
  const { process, document, validationMessages } = useAppSelector(processSelector);
  const { dynamicData, selectedPivot } = useAppSelector(globalSelector);
  const location = useLocation();
  let rowType = getRowTypeFromPath(location.pathname);

  const contextInit = (fieldValues: Record<string, any>, changedPivot?: string) => {
    const contextType =
      rowType === RowType.None &&
      (selectedPivot === "ProcessStart" || selectedPivot === "ProcessPermissions")
        ? ContextType.Start
        : ContextType.Default;

    if (
      changedPivot &&
      (rowType !== RowType.None ||
        (changedPivot !== "ProcessStart" &&
          changedPivot !== "ProcessPermissions" &&
          changedPivot !== "ProcessFinish"))
    ) {
      return;
    }

    dispatch(startLoadingContext());
    defaultContext(
      apiClient,
      dispatch,
      process.configuration,
      document,
      props.configuration as ProcessStepConfiguration | ProcessTrigger,
      {
        ...dynamicData,
        ...fieldValues
      },
      contextType
    ).then((ctx) => {
      dispatch(setContext(ctx));
    });
  };

  const _getDefaultFormData = (configuration?: ProcessStepConfiguration | ProcessTrigger | any) => {
    const fieldValues: any = {};
    if (configuration) {
      Object.values(formDefaultParameters).forEach((fdp) => {
        if (fdp.type === FormParamType.Expression && fdp.panels.indexOf(rowType) >= 0) {
          fieldValues[fdp.formKey] = merge(
            defaultExpression(),
            get(configuration as any, fdp.objectKey)
          );
        } else if (fdp.formKey === formDefaultParameters.buttonTitle.formKey) {
          fieldValues[fdp.formKey] = merge(
            { id: "buttonTitle", defaultText: "" },
            get(configuration as any, fdp.objectKey)
          );
        } else {
          fieldValues[fdp.formKey] = get(configuration as any, fdp.objectKey);
        }
      });

      if (rowType === RowType.Trigger) {
        fieldValues["businessModuleId"] = {
          value: {
            data: (configuration as ProcessTrigger).businessModuleId
              ? `'${(configuration as ProcessTrigger).businessModuleId}'`
              : undefined
          }
        };
        fieldValues["list"] = {
          value: {
            data: (configuration as ProcessTrigger).list
              ? `'${(configuration as ProcessTrigger).list}'`
              : undefined
          }
        };
        fieldValues["enabled"] = !(configuration as ProcessTrigger).disabled;
        fieldValues["includeProperties"] = {
          value: {
            data: (configuration as ProcessTrigger).includeProperties
              ? (configuration as ProcessTrigger).includeProperties?.map(
                  (property) => `'${property}'`
                )
              : undefined
          }
        };
        fieldValues["conditions"] = merge(
          defaultExpression(),
          (configuration as ProcessTrigger).conditions
        );
      } else {
        configuration.parameters?.forEach((p: ProcessStepParameterConfiguration) => {
          let fieldValue: any = {};
          if (internalParameters.indexOf(p.name) < 0) {
            set(fieldValues, (p.parent ? p.parent + "." : "") + p.name, fieldValue);

            if (p.title || p.name || p.description || p.dataType || p.order) {
              fieldValue[ProcessParameterProperty.Description] = merge(
                defaultExpression(),
                p.description
              );
              fieldValue[ProcessParameterProperty.Title] = merge(defaultExpression(), p.title);
              fieldValue[ProcessParameterProperty.Name] = p.name;
              fieldValue[ProcessParameterProperty.Order] = p.order;
              fieldValue[ProcessParameterProperty.DataType] = p.dataType;
            }

            fieldValue["type"] = p.type;
            fieldValue["parent"] = p.parent;
            fieldValue[ProcessParameterProperty.Value] = merge(defaultExpression(), p.value);
            fieldValue[ProcessParameterProperty.Readonly] = merge(defaultExpression(), p.readonly);
            fieldValue[ProcessParameterProperty.Required] = merge(defaultExpression(), p.required);
            fieldValue[ProcessParameterProperty.Valid] = merge(defaultExpression(), p.valid);
            fieldValue[ProcessParameterProperty.Hidden] = merge(defaultExpression(), p.hidden);
            fieldValue[ProcessParameterProperty.ValidMessage] = merge(
              defaultExpression(),
              p.validMessage
            );
            fieldValue[ProcessParameterProperty.DataSource] = merge(
              defaultDataSource(
                `${configuration.id}-${(p.parent ? p.parent + "-" : "") + p.name}-${
                  ProcessParameterProperty.DataSource
                }-title`,
                `${configuration.id}-${(p.parent ? p.parent + "-" : "") + p.name}-${
                  ProcessParameterProperty.DataSource
                }-errorMessage`
              ),
              p.dataSource
            );
            fieldValue[ProcessParameterProperty.Format] = p.format;
            fieldValue[ProcessParameterProperty.Rows] = p.rows;
            fieldValue[ProcessParameterProperty.AllowSelectionOf] = p.allowSelectionOf;
            fieldValue[ProcessParameterProperty.Filter] = p.filter;
          }
        });
      }
    }
    return fieldValues;
  };

  return (
    <ParametersForm
      {...props}
      document={document}
      dynamicData={dynamicData}
      validationMessages={validationMessages}
      getDefaultFormData={_getDefaultFormData}
      contextInit={contextInit}
    />
  );
};
