import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash";
import persistReducer from "redux-persist/lib/persistReducer";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import sessionStorage from "redux-persist/lib/storage/session";
import { PersistConfig } from "redux-persist/lib/types";
import { ProcessContext } from "@workpoint/components/lib/models/ProcessInstance";
import { INavigationEntry } from "../models/Navigation";
import { getSPHostUrl } from "../utils/commonUtils";
import { RootState } from "./store";

export type GlobalState = {
  spHostUrl?: string;
  showGuidance?: boolean;
  selectedPivot?: string;
  context?: ProcessContext;
  loadingContext: boolean;
  dynamicData?: any;
};

export const initialState: GlobalState = {
  showGuidance: false,
  selectedPivot: "Overview",
  loadingContext: false,
  spHostUrl: getSPHostUrl()
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setShowGuidance: (state, action: PayloadAction<boolean>) => {
      state.showGuidance = action.payload;
    },
    setSelectedPivot: (state, action: PayloadAction<string>) => {
      state.selectedPivot = action.payload;
    },
    startLoadingContext: (state) => {
      state.loadingContext = true;
    },
    setContext: (state, action: PayloadAction<ProcessContext>) => {
      state.context = action.payload;
      state.loadingContext = false;
    },
    setspHostUrl: (state, action: PayloadAction<string>) => {
      state.spHostUrl = action.payload;
    },
    setDynamicData: (state, action: PayloadAction<{ stepId: string; name: string; data: any }>) => {
      if (!state.dynamicData) {
        state.dynamicData = {};
      }
      set(
        state.dynamicData,
        `${action.payload.stepId}.${action.payload.name}`,
        action.payload.data
      );
    }
  }
});

const globalPersistConfig: PersistConfig<any> = {
  key: "global",
  version: 1,
  storage: sessionStorage,
  stateReconciler: autoMergeLevel2,
  throttle: 500,
  whitelist: ["spHostUrl"]
};

const nonPersistedGlobalReducer = globalSlice.reducer;
const globalReducer = persistReducer(globalPersistConfig, nonPersistedGlobalReducer);

export const {
  setShowGuidance,
  setSelectedPivot,
  startLoadingContext,
  setContext,
  setDynamicData,
  setspHostUrl
} = globalSlice.actions;

export const globalSelector = (state: RootState) => state.global;
export default globalReducer;
