import { Icon, Stack, Text } from "@fluentui/react";
import React from "react";
import styled from "styled-components";
import WorkPointTheme from "@workpoint/components/lib/constants";
import { evaluateProcessExpressionForDisplay } from "@workpoint/components/lib/helpers/expressionUtils";
import {
  ProcessStepConfiguration,
  ProcessTrigger
} from "@workpoint/components/lib/models/ProcessConfiguration";
import { stripHtml } from "@workpoint/components/lib/helpers/helper";
import { RowType } from "../../../../utils/processUtils";

const theme = WorkPointTheme;
const arrowColor = "grey";

interface ListItemProps {
  className?: any;
  stepConfig: ProcessStepConfiguration | ProcessTrigger;
  index: number;
  lastItem: boolean;
  addStep: () => void;
  editStep: () => void;
  active: boolean;
  hasError: boolean;
  rowType: RowType;
  subText?: string;
}

const StepInner = ({
  className,
  stepConfig,
  index,
  lastItem,
  addStep,
  editStep,
  active,
  hasError,
  rowType,
  subText
}: ListItemProps) => {
  let triggerDisabled = (stepConfig as ProcessTrigger).disabled ?? false;
  const title = evaluateProcessExpressionForDisplay(stepConfig.title, {});
  return (
    <Stack className={className}>
      <StepStack editStep={editStep} active={active}>
        <ColumnOne>{index + 1}</ColumnOne>
        <ColumnTwo>
          <StepIcon
            iconName={
              rowType === RowType.Trigger
                ? "TriggerAuto"
                : stepConfig.type === "user"
                ? "UserEvent"
                : "SetAction"
            }
            disabled={triggerDisabled}
          />
          <StatusIcon
            iconName={hasError ? "StatusErrorFull" : "CompletedSolid"}
            color={hasError ? "#a4262c" : "green"}
          />
        </ColumnTwo>
        <ColumnThree>
          <Stack>
            <Text>{title}</Text>
            <SubText>{stripHtml(subText ?? "")}</SubText>
          </Stack>
        </ColumnThree>
        <ColumnFour>
          <Icon className="openArrow" iconName="ChevronRightMed" />
        </ColumnFour>
      </StepStack>
      <Stack horizontal gap="10px">
        <ColumnOne />
        {!lastItem ? (
          <ColumnTwo className="arrow" onClick={() => addStep()}>
            <ArrowLine />
            <AddIcon className="plusIcon" iconName="Add" />
            <ArrowHead iconName="ChevronDownSmall" />
          </ColumnTwo>
        ) : (
          <ColumnTwo className="arrow" onClick={() => addStep()}>
            <ArrowLine />
            <LargeAddIcon iconName="Add" />
          </ColumnTwo>
        )}
        <ColumnThree />
        <ColumnFour />
      </Stack>
    </Stack>
  );
};

const ColumnOne = styled.span`
  width: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ColumnTwo = styled.span`
  display: flex;
  width: 40px;
  justify-content: center;
`;

const ColumnThree = styled.span`
  width: 100%;
`;

const ColumnFour = styled.span`
  display: flex;
  width: 30px;
  justify-content: flex-end;
  align-items: center;
`;

const StepStackInner = ({ className, children, editStep }: any) => (
  <Stack className={className} horizontal gap="10px" onClick={editStep}>
    {children}
  </Stack>
);

const StepStack = styled(StepStackInner)`
  padding: 10px;
  margin: 0px -10px -10px -10px;
  background-color: ${(props) => (props.active ? "white" : "transparent")};

  :hover {
    background-color: white;
    cursor: pointer;

    .openArrow {
      display: flex;
    }
  }
`;

const StepIcon = styled(Icon)<{ disabled: boolean }>`
  font-size: 15px;
  color: white;
  background-color: ${(props) => (props.disabled ? "grey" : theme.palette.blue)};
  border: 9px solid ${(props) => (props.disabled ? "grey" : theme.palette.blue)};
  border-radius: 100px;
`;

const StatusIcon = styled(Icon)<{ color: string }>`
  position: absolute;
  transform: translate(12px, -3px);
  color: ${(props) => props.color};
  font-size: 10px;
  background-color: white;
  border: 1.5px solid white;
  border-radius: 100px;
`;

const ArrowLine = styled.span`
  height: 59px;
  border-left: 1px solid ${arrowColor};
  border-right: 1px solid ${arrowColor};
  background-color: ${arrowColor};
`;

const AddIcon = styled(Icon)`
  font-size: 7px;
  color: white;
  background-color: grey;
  border: 3px solid grey;
  border-radius: 100px;
  position: absolute;
  align-self: center;
  transform: translateY(-3px);
`;

const LargeAddIcon = styled(Icon)`
  font-size: 15px;
  color: ${theme.palette.white};
  background-color: ${theme.palette.themePrimary};
  border: 5px solid ${theme.palette.themePrimary};
  border-radius: 100px;
  position: absolute;
  align-self: flex-end;
`;

const ArrowHead = styled(Icon)`
  color: ${arrowColor};
  position: absolute;
  align-self: flex-end;
  transform: translateY(3px);
`;

const SubText = styled(Text)`
  font-size: 10px;
  color: gray;
`;

// TODO Refactor
export const Step = styled(StepInner)`
  display: flex;

  .openArrow {
    display: none;
  }

  .arrow:hover {
    cursor: pointer;
  }

  .arrow:hover .plusIcon {
    background-color: ${theme.palette.themePrimary};
    border-color: ${theme.palette.themePrimary};
  }
`;
