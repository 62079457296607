import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { AppRoutes } from "../../routes";
import { dialogSelector, hideMessage } from "../../store/dialogReducer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ProcessDetail } from "./detail";
import { ProcessHistory } from "./history";
import { ProcessList } from "./list";
import { NewProcessDialog } from "./list/newProcess/newProcessDialog";

export const ProcessPage = () => {
  const { message } = useAppSelector(dialogSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 7000);
    }
  }, [message]);

  return (
    <Routes>
      <Route path="/" element={<ProcessList />}>
        <Route path={AppRoutes.NewProcessDialog} element={<NewProcessDialog />} />
      </Route>
      <Route path={AppRoutes.Process + "/*"} element={<ProcessDetail />} />
      <Route path={AppRoutes.NewProcess + "/*"} element={<ProcessDetail />} />
      <Route path={AppRoutes.ProcessHistory} element={<ProcessHistory />} />
    </Routes>
  );
};
