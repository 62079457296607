import { Control, FieldValues, UseFormSetValue, UseFormTrigger } from "react-hook-form";
import { ParameterPropertyControl } from "./ParameterPropertyControl";
import { ProcessParameterProperty } from "@workpoint/components/lib/models/ProcessConfiguration";
import { FormControlType } from "./ParametersForm";
import { OpenAPIV2 } from "openapi-types";
import { LoadingIndicator } from "./ParameterControl";
import { useAppSelector } from "../../../store/hooks";
import { processSelector } from "../../../store/processReducer";
import { formDefaultParameters } from "../../../utils/processUtils";

interface ControlledAccountPickerProps {
  control: Control<FieldValues>;
  setValue: UseFormSetValue<any>;
  trigger: UseFormTrigger<any>;
  name: string;
  label: string;
  required: boolean;
  skipPropertyTypeInName: boolean;
  hideConditions: boolean;
  hideContextBrowser: boolean;
}

export const AccountPicker = (props: ControlledAccountPickerProps) => {
  const { accounts, loadingAccounts } = useAppSelector(processSelector, (a, b) => {
    if (a.loadingAccounts !== b.loadingAccounts) {
      return false;
    } else {
      return true;
    }
  });
  return (
    <>
      <ParameterPropertyControl
        {...props}
        defaultValue={undefined}
        propertyType={ProcessParameterProperty.DataType}
        controlType={FormControlType.ValuePicker}
        definition={{
          name: formDefaultParameters.permissionExecuteAs.formKey,
          in: "",
          type: "string",
          "x-workpoint-control": { type: "valuePicker" },
          "x-workpoint-field": { type: "Account" }
        }}
        options={
          accounts?.accounts?.map((acc) => {
            return { key: acc, text: acc };
          }) ?? []
        }
      />
      {loadingAccounts && <LoadingIndicator />}
    </>
  );
};
