import React, { FC } from "react";
import { Controller } from "react-hook-form";
import {
  IComboBoxProps,
  ComboBox,
  IComboBox,
  IComboBoxOption,
  IComboBoxStyles
} from "@fluentui/react";
import { FormControlProps } from "./ControlledTextField";

const comboBoxStyle: Partial<IComboBoxStyles> = {
  optionsContainer: {
    maxHeight: "300px"
  }
};

export const ControlledComboBox: FC<FormControlProps & IComboBoxProps> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || ""}
      render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => (
        <ComboBox
          {...props}
          onChange={(
            event: React.FormEvent<IComboBox>,
            option?: IComboBoxOption,
            index?: number
          ) => {
            if (props.multiSelect) {
              if (Array.isArray(value)) {
                if (option?.selected) {
                  value.push(option?.key);
                } else {
                  var itemIndex = value.indexOf(option?.key);
                  if (itemIndex !== -1) {
                    value.splice(itemIndex, 1);
                  }
                }
                onChange(value);
              } else {
                onChange([option?.key]);
              }
            } else {
              onChange(option?.key);
            }
            if (props.onChange) props.onChange(event, option, index);
          }}
          onBlur={onBlur}
          errorMessage={error && error.message}
          selectedKey={value}
          placeholder="Select an option"
          useComboBoxAsMenuWidth={true}
          styles={comboBoxStyle}

          // options={props.options.map(option => { return { ...option, selected: option.key === value }; }) ?? []}
          // name={fieldName}
          // value={value}
          // label="Dropdown"
          // options={options}
          // onChange={(event, option) => onChange(option)}
        />
      )}
    />
  );
};
