import {
  DetailsList,
  IColumn,
  IconButton,
  IContextualMenuItem,
  IDetailsListProps
} from "@fluentui/react";
import * as React from "react";
import { useIntl } from "react-intl";
import { useAppDispatch } from "../../../../store/hooks";
import { deleteAdditionalLanguage, downloadLanguagePack } from "../../../../store/processReducer";

export const LanguageList = (props: IDetailsListProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const columns: IColumn[] = [
    {
      name: intl.formatMessage({ id: "column-language", defaultMessage: "Language" }),
      minWidth: 200,
      key: "language",
      fieldName: "language",
      isSorted: false,
      isSortedDescending: true
    },
    {
      name: "",
      minWidth: 30,
      maxWidth: 30,
      key: "options",
      isSorted: false,
      isSortedDescending: true
    }
  ];

  const _renderItemColumn = (item?: any, index?: number, column?: IColumn) => {
    const fieldContent = item[column?.fieldName ?? ""];

    switch (column?.key) {
      case "options":
        const items: IContextualMenuItem[] = [
          {
            key: "export",
            name: intl.formatMessage({ id: "option-export", defaultMessage: "Export" }),
            data: item,
            onClick: () => {
              dispatch(downloadLanguagePack(item.messages, item.language ?? "defaultTemplate"));
            }
          },
          {
            key: "delete",
            name: intl.formatMessage({ id: "option-delete", defaultMessage: "Delete" }),
            data: item,
            onClick: () => {
              dispatch(deleteAdditionalLanguage(item));
            }
          }
        ];

        return (
          <IconButton
            role="menuitem"
            title="More options"
            menuIconProps={{ iconName: "MoreVertical" }}
            menuProps={{ items }}
          />
        );
      default:
        return fieldContent;
    }
  };

  return (
    <DetailsList
      {...props}
      items={props.items}
      columns={columns}
      onRenderItemColumn={_renderItemColumn}
    />
  );
};
