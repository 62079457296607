import { OpenAPIV2 } from "openapi-types";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { newStep, processSelector } from "../../../../store/processReducer";
import { getAllOperations } from "../../../../utils/swaggerUtils";
import NewRowPanelBase from "./NewRowPanelBase";
import { v4 as uuid } from "uuid";
import {
  ProcessStepParameterConfiguration,
  ConfigurationParameterType,
  ProcessStepType
} from "@workpoint/components/lib/models/ProcessConfiguration";
import { useParams } from "react-router-dom";
import { FormControlType } from "../../../common/parametersForm/ParametersForm";
import { RowType } from "../../../../utils/processUtils";

export const NewStepPanel = () => {
  const [allItems, setAllItems] = useState<OpenAPIV2.OperationObject[]>();
  const { insertIndex } = useParams() as any;

  const { document } = useAppSelector(processSelector);
  const dispatch = useAppDispatch();

  const _addNewStep = (item: OpenAPIV2.OperationObject) => {
    const id = uuid();
    dispatch(
      newStep({
        conf: {
          id,
          name: item.operationId!,
          type: (item as any)["x-workpoint-type"] ?? ProcessStepType.Action,
          title: { id: `${id}-title`, defaultText: item.summary ?? "" },
          description: {
            id: `${id}-description`,
            defaultText: item.description ?? ""
          },
          definition: item.operationId!,
          icon: (item as any)["x-workpoint-icon"] ?? "",
          parameters: item
            .parameters!.filter(
              (p: any) =>
                (p["x-ms-visibility"] !== "internal" && !p["x-workpoint-visibility"]) ||
                (p["x-workpoint-visibility"] && p["x-workpoint-visibility"] !== "internal")
            )
            .map((p) => {
              const definition = p as OpenAPIV2.Parameter;

              if (
                (!definition.schema || !definition.schema["x-ms-dynamic-schema"]) &&
                !definition["x-ms-dynamic-schema"] &&
                definition["x-workpoint-control"]?.type !== FormControlType.DynamicSchema
              ) {
                const paramConfig: ProcessStepParameterConfiguration = {
                  name: definition.name,
                  title: {
                    id: `${id}-${definition.name}-title`,
                    defaultText: definition["x-ms-summary"] ?? ""
                  },
                  description: {
                    id: `${id}-${definition.name}-description`,
                    defaultText: definition.description ?? ""
                  },
                  type: ConfigurationParameterType.Static,
                  // dataSource: {},
                  value: { rules: [], expression: "" },
                  hidden: { rules: [], expression: "" },
                  readonly: { rules: [], expression: "" },
                  required: { rules: [], expression: "" },
                  valid: { rules: [], expression: "" }
                };

                return paramConfig;
              }

              return undefined;
            })
            .filter((param) => param !== undefined) as any,
          valid: {
            conditions: { rules: [], expression: "" },
            message: { id: `${id}-validMessage`, defaultText: "", rules: [], expression: "" } as any
          }
        },
        rowIndex: insertIndex
      })
    );
  };

  useEffect(() => {
    const actions = getAllOperations(document!).filter(
      (step: OpenAPIV2.OperationObject) =>
        !step["tags"]?.includes("Hidden") &&
        (step as any)["x-workpoint-type"] !== "trigger" &&
        (((step as any)["x-ms-visibility"] !== "internal" &&
          !(step as any)["x-workpoint-visibility"]) ||
          ((step as any)["x-workpoint-visibility"] &&
            (step as any)["x-workpoint-visibility"] !== "internal"))
    );
    setAllItems(actions);
  }, [document]);

  return <NewRowPanelBase allItems={allItems} addNewItem={_addNewStep} type={RowType.Step} />;
};
