import { cloneDeep } from "lodash";
import {
  CommandBar,
  IButtonStyles,
  ICommandBarStyles,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack
} from "@fluentui/react";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import styled from "styled-components";
import {
  AdminConfiguration,
  AdminParameterConfiguration
} from "@workpoint/components/lib/models/AdminConfiguration";
import { useNavigation } from "../../../hooks/useNavigation";
import { NavLinkType } from "../../../models/Navigation";
import {
  adminSelector,
  loadPageItem,
  savePageItem,
  updatePageItem
} from "../../../store/adminReducer";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { PageProps } from "../ConfigurePageControl";
import { AdminParametersForm } from "./AdminParametersForm";

export const AdminParametersPage = (props: PageProps) => {
  const { pageId, subPageId } = useParams();
  const { pageItem, hasErrors, pageOpId, subPageOpId, saving, document } =
    useAppSelector(adminSelector);
  const dispatch = useAppDispatch();
  const { activeNav } = useNavigation();
  const intl = useIntl();

  const item =
    !hasErrors && pageId && subPageId && (pageId !== pageOpId || subPageId !== subPageOpId)
      ? undefined
      : pageItem;

  useEffect(() => {
    /**
     * Made else if without subpageId, as using details type directly won't have a subId
     * NOTE: Work in progress, don't push
     * NOTE 2: For some methods in the backend can return null, they should probably throw an exception instead.
     */
    if (!hasErrors && pageId && subPageId && (pageId !== pageOpId || subPageId !== subPageOpId)) {
      dispatch(loadPageItem(pageId, subPageId));
    } else if (
      !hasErrors &&
      pageId &&
      pageId !== pageOpId &&
      activeNav?.url.toLowerCase().endsWith("/" + pageId.toLowerCase()) &&
      activeNav.linkType === NavLinkType.ParametersPage
    ) {
      dispatch(loadPageItem(pageId));
    }
  }, [pageId, subPageId]);

  const _update = (data: any) => {
    const configuration: AdminConfiguration = cloneDeep(pageItem?.data);

    configuration.parameters?.forEach((p: AdminParameterConfiguration) => {
      p.value = cloneDeep(data[p.name]?.value);
    });
    dispatch(updatePageItem({ item: configuration }));

    return [];
  };

  return (
    <>
      {item === undefined || props.operation === undefined ? (
        <Spinner size={SpinnerSize.large} style={{ marginTop: "100px" }} />
      ) : (
        <>
          {activeNav?.description && (
            <div
              style={{ marginLeft: "23px" }}
              dangerouslySetInnerHTML={{ __html: activeNav?.description ?? "" }}
            ></div>
          )}
          <CommandBarContainer>
            <CommandBar
              styles={commandBar}
              items={[
                {
                  key: "save",
                  onRender: () => (
                    <PrimaryButton
                      text="Save"
                      styles={saveButton}
                      onClick={() => {
                        if (props?.pageConfig.operations?.save && pageItem) {
                          /**
                           * @todo: If the backend has returned the pageItem.data as null, the pageItem can't be saved.
                           */
                          dispatch(savePageItem(props.pageConfig.operations!.save!, pageItem.data));
                        }
                      }}
                      disabled={saving}
                    >
                      {saving && <Spinner styles={{ root: { marginLeft: "8px" } }}></Spinner>}
                    </PrimaryButton>
                  )
                }
              ]}
            />
          </CommandBarContainer>

          <AdminParametersForm {...props} close={() => {}} update={_update} />
        </>
      )}
    </>
  );
};

const CommandBarContainer = styled(Stack)`
  height: 45px;
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid;
  border-bottom-color: #eee;
  align-items: center;
`;

const saveButton: Partial<IButtonStyles> = {
  root: {
    // margin: "8px 0px"
  }
};

const commandBar: Partial<ICommandBarStyles> = {
  root: {
    padding: "0px",
    height: "min-content"
  }
};
