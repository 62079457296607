import { Customizer, LayerHost } from "@fluentui/react";
import { OpenAPIV2 } from "openapi-types";
import React from "react";
import { matchPath, Route, Routes, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useNavigation } from "../../../hooks/useNavigation";
import { INavigationEntry, NavLinkType } from "../../../models/Navigation";
import { AppRoutes } from "../../../routes";
import { GuidancePanel } from "../../common/guidance/GuidancePanel";
import { AdminParametersPanel } from "../details/AdminParametersPanel";

interface IAdminPanelLayerHost {
  showGuidance: boolean | undefined;
  document: OpenAPIV2.Document | undefined;
  operationId: string | undefined;
}

export const AdminPanelLayerHost = (props: IAdminPanelLayerHost): JSX.Element => {
  const { activeNav } = useNavigation();
  const location = useLocation();

  return (
    <>
      {activeNav &&
        activeNav?.linkType !== NavLinkType.SettingsPage &&
        activeNav?.linkType !== NavLinkType.CustomPage && (
          <>
            <Customizer scopedSettings={{ Layer: { hostId: "adminPanelLayerHost" } }}>
              <Routes>
                <Route
                  path={AppRoutes.ConfigureSettingsParameterPanel}
                  element={<AdminParametersPanel />}
                />
                <Route
                  path={AppRoutes.ConfigureSubPageDetails}
                  element={<AdminParametersPanel />}
                />
                <Route
                  path={AppRoutes.ConfigureSubPageDetailsNew}
                  element={<AdminParametersPanel />}
                />
              </Routes>
            </Customizer>
            {!props.showGuidance && shouldRenderAdminLayerHost(activeNav, location.pathname) && (
              <LayerHost id={"adminPanelLayerHost"} style={layerHostStyle}></LayerHost>
            )}
            {props.showGuidance && shouldRenderAdminLayerHost(activeNav, location.pathname) && (
              <LayerHost id={"adminPanelLayerHost"} style={layerHostStyleWithGuidance}></LayerHost>
            )}
            {props.showGuidance && (
              <>
                <Customizer scopedSettings={{ Layer: { hostId: "guidanceLayerHostId" } }}>
                  <GuidancePanel document={props.document} operationId={props.operationId} />
                </Customizer>
                <GuidanceLayerHost id={"guidanceLayerHostId"} />
              </>
            )}
          </>
        )}
    </>
  );
};

const shouldRenderAdminLayerHost = (nav: INavigationEntry, path: string) => {
  let renderLayerHost = false;
  if (nav?.linkType === NavLinkType.ParametersPanel) {
    renderLayerHost = true;
  }

  /**
   * @todo: How do we deal with these dynamic panels that do not rely on a NavLink/NavigationEntry?
   * Currently matches path to avoid rendering a transparent layer with no content when the panel is closed.
   */
  if (
    nav?.linkType === NavLinkType.ListViewPage &&
    (matchPath(
      `${AppRoutes.Configure}/${AppRoutes.ConfigureSubPage}/${AppRoutes.ConfigureSubPageDetails}`,
      path
    ) ||
      matchPath(
        `${AppRoutes.Configure}/${AppRoutes.ConfigureSubPage}/${AppRoutes.ConfigureSubPageDetailsNew}`,
        path
      ))
  ) {
    renderLayerHost = true;
  }
  return renderLayerHost;
};

const layerHostStyle: React.CSSProperties = {
  position: "absolute",
  top: "0px",
  bottom: "0px",
  right: "0px",
  left: "60%"
};

const layerHostStyleWithGuidance: React.CSSProperties = {
  position: "absolute",
  top: "0px",
  bottom: "0px",
  right: "500px",
  left: "calc(60% - 500px)"
};

const GuidanceLayerHost = styled(LayerHost)`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 500px;
`;
