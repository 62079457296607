import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Stack, StackItem } from "@fluentui/react/lib/components/Stack";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { SignOutButton } from "./SignOutButton ";

export const UserMenu: React.FC = (props) => {
  const { accounts } = useMsal();
  const intl = useIntl();
  return (
    <AuthenticatedTemplate>
      <Stack horizontal>
        <StackItem align="center">
          <SignedInUserMessage>
            {intl.formatMessage(
              { id: "hello-username", defaultMessage: "Hello {username}" },
              { username: accounts?.[0]?.name }
            )}
          </SignedInUserMessage>
        </StackItem>
        <SignOutButton />
      </Stack>
    </AuthenticatedTemplate>
  );
};

const SignedInUserMessage = styled.span`
  color: white;
`;
