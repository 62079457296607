import React, { useRef } from "react";
import {
  CommandBar,
  CommandBarButton,
  DefaultButton,
  ICommandBarItemProps,
  Icon,
  IconButton,
  IContextualMenuItem,
  IContextualMenuProps,
  ITextField,
  PrimaryButton,
  Stack,
  Text,
  TooltipHost,
  Spinner
} from "@fluentui/react";
import styled from "styled-components";
import WorkPointTheme from "@workpoint/components/lib/constants";
import { ToRoute } from "../../../../routes";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  downloadProcess,
  processSelector,
  save,
  toggleProcessTemplate,
  fetchProcess,
  updateSaving,
  saveProcessCopy,
  discardChanges
} from "../../../../store/processReducer";
import { showDialog } from "../../../../store/dialogReducer";
import { DialogResult, DialogType } from "../../../common/Dialog";
import { ProcessStatus } from "@workpoint/components/lib/models/ProcessConfiguration";
import { runDeleteDialog, runDisableDialog, runSaveAsDialog } from "../../../../utils/processUtils";
import { ErrorDropdown } from "./ErrorDropdown";
import { ValidationMessageType } from "../../../../models/ProcessValidation";
import { hasPublishedVersion, isDraftVersion } from "../../../../utils/processUtils";
import { detailsCommandBarHeight } from "../../../common/AppChrome";
import { useApiClient } from "@workpoint/components/lib/clients/ApiProvider";

const borderColor = "#E5E5E5";
const borderWidth = "1px";
const borderStyle = "solid";
const disabledColor = "#f3f2f1";

const WPDetailsCommandBar = ({ className }: any) => {
  const { process, validationMessages, saving, buttonName } = useAppSelector(processSelector);
  const dispatch = useAppDispatch();
  const { processId } = useParams() as any;
  let location = useLocation();
  const inputEl = useRef<ITextField>(null);
  const navigate = useNavigate();
  const { apiClient } = useApiClient();

  const getItems = (): ICommandBarItemProps[] => {
    return [
      {
        key: "back",
        onRender: () => (
          <LeftButton
            iconProps={{
              iconName: "Back"
            }}
            text="BACK"
            onClick={() => navigate(ToRoute.processes())}
          />
        )
      },
      {
        key: "configure",
        onRender: () => (
          <LeftButton
            iconProps={{
              iconName: "Settings"
            }}
            text={"Settings"}
            onClick={openPropertiesPanel}
          />
        )
      }
    ];
  };

  const getFarItems = (): ICommandBarItemProps[] => {
    return [
      {
        key: "errors",
        onRender: () => (
          <RightItemStack horizontal>
            <ErrorDropdown validationMessages={validationMessages} />
          </RightItemStack>
        )
      },
      {
        key: "publish",
        onRender: () => (
          <RightItemStack horizontal>
            <Icon
              iconName={
                process?.status !== ProcessStatus.Disabled ? "SkypeCircleCheck" : "DisableUpdates"
              }
              style={{ color: getStatusColor() }}
            />
            <Text style={{ color: getStatusColor(), padding: "10px" }}>
              V. {process?.version} ({process?.status.toUpperCase()})
            </Text>
            <TooltipHost
              content={
                process?.status !== ProcessStatus.Draft
                  ? "Only drafts can be published"
                  : validationMessages.filter((msg) => msg.type === ValidationMessageType.Error)
                      .length !== 0
                  ? "Errors need to be resolved before publishing"
                  : undefined
              }
            >
              <PublishButton
                text={hasPublishedVersion(process) ? "Republish" : "Publish"}
                disabled={
                  saving ||
                  process?.status !== ProcessStatus.Draft ||
                  validationMessages.length !== 0
                }
                onClick={() => {
                  if (process.status === ProcessStatus.Draft) {
                    dispatch(
                      showDialog({
                        type: DialogType.YesNo,
                        title: "Publish", // CTRLLANG
                        subText: "Are you sure?", // CTRLLANG
                        onClick: (result: DialogResult) => {
                          if (result === DialogResult.Ok) {
                            dispatch(
                              save(
                                ProcessStatus.Published,
                                navigate,
                                location.pathname
                                  .split("/")
                                  .filter((value, index) => index > 2)
                                  .join("/")
                              )
                            );
                          }
                        }
                      })
                    );
                  }
                }}
              >
                {saving && buttonName === "Publish" && (
                  <Spinner styles={{ root: { marginLeft: "-20px" } }}></Spinner>
                )}
              </PublishButton>
            </TooltipHost>
          </RightItemStack>
        )
      },
      {
        key: "save",
        onRender: () => (
          <RightItemStack horizontal gap="10px">
            <DefaultButton
              text="Save as draft & Exit"
              onClick={() => {
                dispatch(save(ProcessStatus.Draft, navigate, ToRoute.processes()));
              }}
              disabled={saving}
            >
              {saving && buttonName === "SaveAsDraftAndExit" && (
                <Spinner styles={{ root: { marginLeft: "-20px" } }}></Spinner>
              )}
            </DefaultButton>
            <PrimaryButton
              text="Save as draft"
              onClick={async () => {
                dispatch(updateSaving(true));
                const updatedProcess = processId && (await fetchProcess(apiClient, processId));
                if (processId && process?.modified !== updatedProcess?.modified) {
                  dispatch(
                    showDialog({
                      type: DialogType.Overwrite,
                      title: "The process could not be saved",
                      subText:
                        "The process has been modified from somewhere else. Click overwrite to keep your changes, or click save as a copy to keep the existing version.",
                      onClick: (result: DialogResult) => {
                        if (result === DialogResult.Ok) {
                          dispatch(
                            save(
                              ProcessStatus.Draft,
                              navigate,
                              location.pathname
                                .split("/")
                                .filter((value, index) => index > 2)
                                .join("/")
                            )
                          );
                        } else if (result === DialogResult.Alternative) {
                          dispatch(saveProcessCopy(process, navigate));
                        } else {
                          dispatch(updateSaving(false));
                        }
                      }
                    })
                  );
                } else {
                  dispatch(
                    save(
                      ProcessStatus.Draft,
                      navigate,
                      location.pathname
                        .split("/")
                        .filter((value, index) => index > 2)
                        .join("/")
                    )
                  );
                }
              }}
              disabled={saving}
            >
              {saving && buttonName === "SaveAsDraft" && (
                <Spinner styles={{ root: { marginLeft: "-20px" } }}></Spinner>
              )}
            </PrimaryButton>
            <IconButton menuIconProps={{ iconName: "MoreVertical" }} menuProps={getMoreOptions()} />
          </RightItemStack>
        )
      }
    ];
  };

  const getMoreOptions = (): IContextualMenuProps => {
    const items: IContextualMenuItem[] = [];
    if (
      process.itemId &&
      process?.parentId &&
      hasPublishedVersion(process) &&
      isDraftVersion(process)
    ) {
      items.push({
        key: "discard",
        iconProps: { iconName: "Undo" },
        text: "Discard changes",
        onClick: () => {
          dispatch(
            showDialog({
              type: DialogType.YesNo,
              title: "Discard changes",
              subText: "Are you sure?",
              onClick: (result: DialogResult) => {
                if (result === DialogResult.Ok) {
                  dispatch(
                    discardChanges(
                      process.itemId!,
                      navigate,
                      ToRoute.process(process.parentId!.toString())
                    )
                  );
                }
              }
            })
          );
        }
      });
    }

    items.push(
      ...[
        {
          key: "save",
          iconProps: { iconName: "Save" },
          text: "Save as",
          onClick: () => {
            runSaveAsDialog(dispatch, inputEl, process);
          }
        },
        {
          key: "editProperties",
          iconProps: { iconName: "Settings" },
          text: "Edit properties",
          onClick: openPropertiesPanel
        },
        {
          key: "localization",
          iconProps: { iconName: "Dictionary" },
          text: "Localization",
          onClick: openLocalizationPanel
        },
        {
          key: "viewHistory",
          iconProps: { iconName: "TimeEntry" },
          text: "History",
          onClick: () => {
            navigate(ToRoute.processHistory(process.configuration.id));
          }
        },
        {
          key: "disable",
          iconProps: { iconName: "DisableUpdates" },
          text: "Disable",
          disabled: process?.status === ProcessStatus.Disabled,
          onClick: () => {
            runDisableDialog(dispatch, process);
          }
        },
        {
          key: "export",
          iconProps: { iconName: "Export" },
          text: "Export",
          onClick: () => {
            dispatch(downloadProcess(process.configuration.id));
          }
        }
      ]
    );

    if (process.itemId) {
      items.push(
        {
          key: "template",
          iconProps: { iconName: "SaveTemplate" },
          text: process.isTemplate ? "Unpin template" : "Pin as template",
          onClick: () => {
            dispatch(toggleProcessTemplate(process.itemId!));
          }
        },
        {
          key: "delete",
          iconProps: { iconName: "Pause" },
          text: "Delete",
          onClick: () => {
            runDeleteDialog(dispatch, [process], navigate);
          }
        }
      );
    }

    return {
      items
    };
  };

  const openPropertiesPanel = () => {
    navigate(ToRoute.processProperties());
  };

  const openLocalizationPanel = () => {
    navigate(ToRoute.processLocalization());
  };

  const getStatusColor = () => {
    switch (process?.status) {
      case ProcessStatus.Disabled:
        return "#a4262c";
      case ProcessStatus.Draft:
        return "rgba(0, 0, 0, 0.54)";
      case ProcessStatus.Published:
        return "#107C10";
    }
  };

  return <CommandBar className={className} items={getItems()} farItems={getFarItems()} />;
};

const DetailsCommandBar = styled(WPDetailsCommandBar)`
  border-color: ${borderColor};
  border-width: ${borderWidth};
  border-bottom-style: ${borderStyle};
  box-sizing: border-box;
  height: ${detailsCommandBarHeight}px;
`;

export const LeftButton = styled(CommandBarButton)`
  padding: 0px 30px;
  border-color: ${borderColor};
  border-width: ${borderWidth};
  border-right-style: ${borderStyle};
`;

const RightItemStack = styled(Stack)`
  padding: 0px 30px;
  align-items: center;
  border-color: ${borderColor};
  border-width: ${borderWidth};
  border-left-style: ${borderStyle};
`;

const PublishButton = styled(PrimaryButton)`
  background-color: ${WorkPointTheme.palette.themeTertiary};
  border-color: ${WorkPointTheme.palette.themeTertiary};

  :hover {
    background-color: ${WorkPointTheme.palette.themeSecondary};
    border-color: ${WorkPointTheme.palette.themeSecondary};
  }

  :active {
    background-color: ${WorkPointTheme.palette.themePrimary};
    border-color: ${WorkPointTheme.palette.themePrimary};
  }

  :disabled {
    background-color: ${disabledColor};
    border-color: ${disabledColor};
  }
`;

export default DetailsCommandBar;
