import { DefaultButton, IButtonProps, IButtonStyles, Icon } from "@fluentui/react";
import React from "react";

export const EmailScheduleButton: React.FC<IButtonProps> = (props) => {
  const styles: IButtonStyles = {
    root: {
      backgroundColor: "#ebebeb;",
      color: "#474747",
      border: "none",
      outline: "transparent"
    },

    rootCheckedHovered: {
      backgroundColor: "#F7941E",
      color: "white",
      fontWeight: "bold"
    },

    rootChecked: {
      backgroundColor: "#F7941E",
      color: "white",
      fontWeight: "bold"
    }
  };

  return <DefaultButton styles={styles} {...props} onRenderText={iconRendering} />;
};
const iconRendering = (props: any) => {
  return (
    <div>
      {props.text}
      {props.checked && "   "}
      {props.checked && <Icon iconName="Accept" />}
    </div>
  );
};
