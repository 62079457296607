import { Icon, Label, Stack } from "@fluentui/react";
import * as React from "react";
import styled from "styled-components";
import theme from "@workpoint/components/lib/constants";

interface IFieldInfoProps {
  label1: string;
  label2?: string;
  iconName?: string;
  children?: JSX.Element;
}

const FieldInfo = (props: IFieldInfoProps) => {
  return (
    <FieldInfoRow horizontal>
      <FieldName>{props.label1}</FieldName>
      {props.iconName && <FieldIcon iconName={props.iconName} />}
      {props.label2 && <FieldValue>{props.label2}</FieldValue>}
      {props.children}
    </FieldInfoRow>
  );
};

const FieldInfoRow = styled(Stack)`
  align-items: center;
  padding: 0px 30px;
  border-bottom: 1px solid rgb(243, 242, 241);
  & > *:not(:nth-child(1)) {
    margin-left: 10px;
  }
`;

const FieldName = styled(Label)`
  color: grey;
  width: 100px;
  max-width: 100x;
  margin: 10px 0px;
`;

const FieldIcon = styled(Icon)`
  color: ${theme.palette.blue};
`;

const FieldValue = styled(Label)`
  color: ${theme.palette.blue};
`;

export default FieldInfo;
