import { ITextFieldProps, Stack, StackItem } from "@fluentui/react";
import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { FormControlProps } from "./ControlledTextField";
import { ErrorMessage } from "./ErrorMessage";

export interface TimePickerProps extends FormControlProps {
  register: any;
}

export const ControlledTimePicker: FC<TimePickerProps & ITextFieldProps> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => (
        <Stack>
          <StackItem>
            <input type="time" id={fieldName} {...props.register("SpecificDeliveryTime")} />
            {error && error?.message && (
              <StackItem>
                <ErrorMessage>{error?.message}</ErrorMessage>
              </StackItem>
            )}
          </StackItem>
        </Stack>
      )}
    />
  );
};
