import React, { FC } from "react";
import { Control, Controller, UseControllerProps } from "react-hook-form";
import { ITextFieldProps, TextField } from "@fluentui/react";

export interface FormControlProps {
  control: Control<any>;
  name: string;
  rules?: UseControllerProps["rules"];
  defaultValue?: any;
}

export const ControlledTextField: FC<FormControlProps & ITextFieldProps> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || ""}
      render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => (
        <TextField
          {...props}
          onChange={(
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
          ) => {
            onChange(newValue);
            if (props.onChange) props.onChange(event, newValue);
          }}
          value={value}
          onBlur={onBlur}
          name={fieldName}
          errorMessage={error && error.message}
          defaultValue={undefined}
        />
      )}
    />
  );
};
