import {
  getTheme,
  IconButton,
  TooltipHost,
  Text,
  IDetailsListProps,
  DetailsList,
  ConstrainMode,
  Icon
} from "@fluentui/react";
import * as React from "react";
import styled from "styled-components";
import { WorkPointTheme } from "@workpoint/components/lib/constants";
import { ProcessConfiguration } from "@workpoint/components/lib/models/ProcessConfiguration";
import { getJsonObjectFromFile } from "../../../../utils/commonUtils";
import { useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getCustomTemplates, processSelector } from "../../../../store/processReducer";
import { useEffect } from "react";
import { evaluateExpressionForDisplay } from "@workpoint/components/lib/helpers/expressionUtils";

const theme = getTheme();

interface TemplateItemProps {
  item?: ProcessConfiguration;
  onClick(item?: ProcessConfiguration): void;
  new?: boolean;
  custom?: boolean;
}

interface NewProcessTemplatesProps {
  onClick(item?: ProcessConfiguration): void;
}

const StyledProcessTemplate = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${theme.palette.neutralLighter};
  }
`;

const StyledInfoButton = styled(IconButton)`
  color: ${theme.palette.themePrimary};
  margin: 15px 10px;
  height: 30px;
  width: 30px;
  font-size: 30px;
`;

const StyledItemContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1 1;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 100%;
`;

const StyledTemplateText = ({ className, children, title, subtitle }: any) => (
  <div className={className}>
    <Text variant={"medium"} className={"title"}>
      {title}
    </Text>
    <Text variant={"medium"} className={"subtitle"}>
      {subtitle}
    </Text>
  </div>
);

const StyledTemplateInfo = styled(StyledTemplateText)`
  display: flex;
  flex-direction: column;
  .title {
    color: ${WorkPointTheme.palette.blue};
    margin: 0;
  }

  .subtitle {
    color: ${WorkPointTheme.palette.neutralTertiary};
    margin: 0;
  }
`;

const StyledItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const TemplateIcon = styled(Icon)<{ blue?: boolean }>`
  margin: 15px 10px;
  font-size: 23px;
  border-radius: 100%;
  color: ${WorkPointTheme.palette.white};
  border: 10px solid
    ${(props) => (props.blue ? WorkPointTheme.palette.blue : WorkPointTheme.palette.themePrimary)};
  background-color: ${(props) =>
    props.blue ? WorkPointTheme.palette.blue : WorkPointTheme.palette.themePrimary};
`;

const TemplateItem = (props: TemplateItemProps) => {
  return (
    <StyledProcessTemplate onClick={() => props.onClick(props.item)}>
      <StyledItemContainer>
        <TemplateIcon
          iconName={props.new ? "Document" : "FileTemplate"}
          blue={props.custom || props.new}
        />
        <StyledTemplateInfo
          title={
            props.item
              ? evaluateExpressionForDisplay(props.item.title.defaultText, {})
              : "Start from scratch"
          }
          subtitle={props.new ? "" : props.custom ? "Custom" : "WorkPoint"}
        />
      </StyledItemContainer>
      <StyledItem>
        <TooltipHost
          content={
            props.item?.description?.defaultText
              ? evaluateExpressionForDisplay(props.item.description.defaultText, {})
              : ""
          }
          directionalHint={12}
        >
          <StyledInfoButton
            iconProps={{ iconName: "ChevronRight" }}
            onClick={() => props.onClick(props.item)}
          />
        </TooltipHost>
      </StyledItem>
    </StyledProcessTemplate>
  );
};

export const NewProcessTemplates = (props: NewProcessTemplatesProps) => {
  const intl = useIntl();
  const [allTemplates, setAllTemplates] = React.useState<any[]>([]);

  const dispatch = useAppDispatch();
  const { templates } = useAppSelector(processSelector);

  useEffect(() => {
    dispatch(getCustomTemplates());
  }, []);

  useEffect(() => {
    if (templates) {
      const allTemps = []; //getJsonObjectFromFile(`${process.env.PUBLIC_URL}/assets/process.json`);
      allTemps.push(
        ...templates.map((temp) => ({
          ...temp,
          custom: true
        }))
      );
      setAllTemplates(allTemps);
    }
  }, [templates]);

  const _onRenderRow: IDetailsListProps["onRenderRow"] = (rowProps) => {
    return (
      <TemplateItem item={rowProps?.item} onClick={props.onClick} custom={rowProps?.item.custom} />
    );
  };

  return (
    <div>
      <Text variant={"large"}>
        {intl.formatMessage({
          id: "dialog-start-from-scratch",
          defaultMessage: "Start from scratch"
        })}
      </Text>
      <TemplateItem onClick={props.onClick} new />
      <DetailsList
        items={allTemplates}
        onRenderRow={_onRenderRow}
        styles={{ contentWrapper: { overflow: "auto", maxHeight: "350px" } }}
        constrainMode={ConstrainMode.horizontalConstrained}
        onRenderDetailsHeader={() => {
          return (
            <div>
              <Text variant={"large"}>
                {intl.formatMessage({
                  id: "dialog-select-template",
                  defaultMessage: "Select a template"
                })}
              </Text>
            </div>
          );
        }}
      />
    </div>
  );
};
