import React from "react";
import { useIntl } from "react-intl";
import { AppContainer, ContentPadding } from "./AppChrome";
import { FullPageHeader } from "./FullPageHeader";

export const LoggedOut: React.FC = () => {
  const intl = useIntl();
  return (
    <AppContainer>
      <FullPageHeader
        title={intl.formatMessage({
          id: "admin-portal",
          defaultMessage: "Admin Portal"
        })}
      />
      <ContentPadding>
        <p>
          {intl.formatMessage({
            id: "logged-out",
            defaultMessage: "You are logged out. Sign in in the top right corner."
          })}
        </p>
      </ContentPadding>
    </AppContainer>
  );
};
