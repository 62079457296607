import React from "react";
import { useIntl } from "react-intl";
import { AppContainer, ContentPadding } from "./AppChrome";
import { FullPageHeader } from "./FullPageHeader";

export const Unauthorized: React.FC = () => {
  const intl = useIntl();
  return (
    <AppContainer>
      <FullPageHeader
        title={intl.formatMessage({
          id: "admin-portal",
          defaultMessage: "Admin Portal"
        })}
      />
      <ContentPadding>
        <p>
          {intl.formatMessage({
            id: "unauthorized",
            defaultMessage:
              "You need to be an administrator to access the WorkPoint administration."
          })}
        </p>
      </ContentPadding>
    </AppContainer>
  );
};
