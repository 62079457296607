import { DefaultButton } from "@fluentui/react";
import { OpenAPIV2 } from "openapi-types";
import { useFieldArray, useWatch } from "react-hook-form";
import {
  LinkBehavior,
  OrderDirection,
  ProcessParameterProperty
} from "@workpoint/components/lib/models/ProcessConfiguration";
import { AccordionControl, AccordionListContainer } from "./AccordionControl";
import { AddFieldContainer } from "./ParameterControl";
import { ParameterPropertyControl } from "./ParameterPropertyControl";
import { FormControlType } from "../parametersForm/ParametersForm";

interface AutoExecuteFieldProps {
  control: any;
  setValue: any;
  trigger: any;
  definition: { name: string };
}

export const AutoExecuteField = (props: AutoExecuteFieldProps) => {
  useWatch({ control: props.control, name: `${props.definition.name}` }); //Triggers a rerender on button title change

  const fieldArray = useFieldArray({
    control: props.control,
    name: props.definition.name
  });

  const onAdd = () => {
    const obj: any = {
      description: {
        defaultText: "'New auto execute'"
      }
    };
    fieldArray.append(obj);
  };

  const onDelete = (id: string, index: number): void => {
    fieldArray.remove(index);
  };

  const onOrderChange = (id: string, direction: OrderDirection, index: number): void => {
    if (direction === OrderDirection.Up) {
      if (index > 0) {
        fieldArray.move(index, index - 1);
      }
    } else {
      if (index < fieldArray.fields.length - 1) {
        fieldArray.move(index, index + 1);
      }
    }
  };

  return (
    <>
      <AddFieldContainer>
        <DefaultButton text="New auto execute" iconProps={{ iconName: "Add" }} onClick={onAdd} />
      </AddFieldContainer>
      <AccordionListContainer>
        {fieldArray?.fields?.map((field: any, index: number) => {
          return (
            <AccordionControl
              definition={props.definition as OpenAPIV2.Parameter}
              expanded={false}
              modified={false}
              onDelete={(id) => onDelete(id, index)}
              onOrderChange={(id: string, direction: OrderDirection) =>
                onOrderChange(id, direction, index)
              }
              title={field.description?.defaultText ?? field.description?.data ?? field.url?.data}
              showDelete={true}
              showSorting={true}
            >
              <ParameterPropertyControl
                control={props.control}
                setValue={props.setValue}
                trigger={props.trigger}
                name={`${props.definition.name}[${index}].description`}
                label="Title"
                description="The title of the auto execute configuration."
                defaultValue={undefined}
                propertyType={ProcessParameterProperty.Value}
                controlType={FormControlType.ValuePicker}
                required={true}
                definition={{ name: "" } as any}
                dynamic
                skipPropertyTypeInName
                enableLocalization
              />
              <ParameterPropertyControl
                control={props.control}
                setValue={props.setValue}
                trigger={props.trigger}
                name={`${props.definition.name}[${index}].url`}
                label="Link/Item"
                description="The link that you will be lead to"
                defaultValue={undefined}
                propertyType={ProcessParameterProperty.Value}
                controlType={FormControlType.ValuePicker}
                required={true}
                definition={{ name: "" } as any}
                dynamic
                skipPropertyTypeInName
              />
              <ParameterPropertyControl
                control={props.control}
                setValue={props.setValue}
                trigger={props.trigger}
                name={`${props.definition.name}[${index}].behavior`}
                label="Action"
                description="How to open your link."
                defaultValue={undefined}
                propertyType={ProcessParameterProperty.Value}
                controlType={FormControlType.ValuePicker}
                required={true}
                definition={{ name: "" } as any}
                options={[
                  { key: `'${LinkBehavior.Redirect}'`, text: "Close and open in the current tab" },
                  { key: `'${LinkBehavior.OpenInBrowser}'`, text: "Close and open a new tab" },
                  { key: `'${LinkBehavior.OpenInApp}'`, text: "Close and open in app" }
                ]}
                dynamic
                skipPropertyTypeInName
              />
            </AccordionControl>
          );
        })}
      </AccordionListContainer>
    </>
  );
};
