import { useEffect, useState } from "react";
import {
  Breadcrumb,
  DefaultButton,
  IBreadcrumbItem,
  IBreadcrumbStyles,
  IButtonStyles,
  Pivot,
  PivotItem,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  Text
} from "@fluentui/react";
import { useAppSelector } from "../../store/hooks";
import { ListViewColumn } from "../common/listView/ListView";
import { useParams } from "react-router";
import { adminSelector } from "../../store/adminReducer";
import { getDefinitionByOperationId, OperationDefinition } from "../../utils/swaggerUtils";
import { AdminList } from "./list/AdminList";
import { AdminParametersPage } from "./details/AdminParametersPage";
import { useNavigation } from "../../hooks/useNavigation";
import { INavigationEntry, NavLinkType } from "../../models/Navigation";
import React from "react";
import { matchPath, NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AdminSettingPage } from "./list/AdminSettingPage";
import { globalSelector } from "../../store/globalReducer";
import { AdminPanelLayerHost } from "./list/AdminPanelLayerHost";
import { Dashboard } from "./Dashboard";
import { AppRoutes } from "../../routes";

export interface AdminOperation {
  operationId: string;
}

export interface AdminPageConfig {
  type: AdminPageType;
  operations?: {
    get?: AdminOperation;
    save?: AdminOperation;
    delete?: AdminOperation;
    new?: AdminOperation;
  };
  parametersMapping?: {
    [key: string]: { parameter: string };
  };
  columns?: ListViewColumn[];
}

export interface PageProps {
  operation: OperationDefinition;
  pageConfig: AdminPageConfig;
}

export enum AdminPageType {
  List = "list",
  Details = "details"
}

export const ConfigurePageControl = () => {
  const { pageId } = useParams();
  const { spHostUrl } = useAppSelector(globalSelector);
  const { document, pageOpId, subPageOpId } = useAppSelector(adminSelector);
  const { activeNav, navStack } = useNavigation();
  const navigate = useNavigate();
  const [pageProps, setPageProps] = useState<PageProps>();
  const [selectedPivot, setSelectedPivot] = useState<string>();
  const { showGuidance } = useAppSelector(globalSelector);
  const location = useLocation();

  const handlePivotClick = (
    item?: PivotItem | undefined,
    ev?: React.MouseEvent<HTMLElement, MouseEvent> | undefined
  ) => {
    if (item && item?.props.itemKey) {
      setSelectedPivot(item.props.itemKey);
      navigate({
        pathname: `${item.props.itemKey}`,
        search: `SPHostUrl=${spHostUrl}`
      });
    }
  };

  const breadcrumbItems = buildBreadcrumbs(navStack, spHostUrl!);

  const handlePivots = (navEntry: INavigationEntry) => {
    if (!navEntry?.pivots) return;
    const pivotElement = (
      <Pivot onLinkClick={handlePivotClick} selectedKey={selectedPivot}>
        {navEntry.pivots?.map((pivot, index) => {
          return (
            <PivotItem
              key={pivot.name + index}
              itemKey={pivot.url}
              headerText={pivot.name}
            ></PivotItem>
          );
        })}
      </Pivot>
    );
    return pivotElement;
  };

  const getRouteElement = (page: PageProps, navLink?: INavigationEntry): JSX.Element => {
    const navEntry = navLink ?? activeNav;

    switch (navEntry?.linkType) {
      case NavLinkType.ParametersPage:
        return <AdminParametersPage {...page} />;
      case NavLinkType.ListViewPage:
        return <AdminList {...page} />;
      case NavLinkType.ParametersPanel:
        return getRouteElement(page, navEntry.parent);
      case NavLinkType.SettingsPage:
        return <AdminSettingPage {...page} />;
      case NavLinkType.CustomPage:
        /**
         * @todo: This custom page routing is placeholder and will change with the business module route changes
         */
        return (
          <div>
            {matchPath(
              `${AppRoutes.Configure}/${AppRoutes.BusinessModulePage}`,
              location.pathname
            ) ? (
              <div>BusinessModulePage</div>
            ) : (
              <Dashboard />
            )}
          </div>
        );
      default:
        return <>Not found...</>;
    }
  };

  useEffect(() => {
    if (document && pageId && (pageId !== pageOpId || pageProps === undefined)) {
      const operation = getDefinitionByOperationId(document, pageId);
      if (operation?.definition && (operation.definition as any)["x-workpoint-pageconfig"]) {
        const pageConfig: AdminPageConfig = (operation.definition as any)["x-workpoint-pageconfig"];
        setPageProps({ operation, pageConfig });
      } else if (pageProps === undefined) {
        setPageProps({} as any);
      }
    }
  }, [document, pageId]);

  useEffect(() => {
    if (activeNav) {
      setSelectedPivot(getPivotKeyByLinkType(activeNav));
    }
  }, [activeNav]);

  let routeElement: JSX.Element;
  if (pageProps && activeNav && spHostUrl) {
    const headerNav = getHeaderNav(activeNav);
    routeElement = (
      <>
        <Stack
          style={{
            width:
              showGuidance &&
              (activeNav.linkType === NavLinkType.ParametersPage ||
                activeNav.linkType === NavLinkType.ListViewPage)
                ? "calc(100% - 500px)"
                : "100%"
          }}
        >
          <BreadcrumbContainer grow>
            <Breadcrumb maxDisplayedItems={10} items={breadcrumbItems} styles={breadcrumbStyles} />
          </BreadcrumbContainer>
          <PivotContainer horizontal>
            {headerNav.parent && (
              <BackButton
                backUrl={headerNav.pivots ? headerNav.parent.url : headerNav.url}
                navigate={navigate}
                spHostUrl={spHostUrl}
              />
            )}
            <Text variant={"xLarge"} style={{ whiteSpace: "nowrap" }}>
              {headerNav.pivots ? headerNav.name : activeNav.name}
            </Text>
            {handlePivots(headerNav)}
          </PivotContainer>
          {getRouteElement(pageProps)}
        </Stack>
        <AdminPanelLayerHost
          showGuidance={showGuidance}
          document={document}
          operationId={activeNav.linkType === NavLinkType.ParametersPage ? pageOpId : subPageOpId}
        />
      </>
    );
  } else {
    routeElement = <Spinner size={SpinnerSize.large} style={{ marginTop: "100px" }} />;
  }

  return <>{routeElement}</>;
};

const BackButton = (props: { backUrl: string; navigate: NavigateFunction; spHostUrl: string }) => {
  const backButtonStyle: Partial<IButtonStyles> = {
    root: {
      minWidth: "36px",
      height: "26px",
      margin: "6px 8px",
      padding: "2px"
    }
  };

  return (
    <DefaultButton
      iconProps={{ iconName: "Back", styles: { root: { fontSize: "16px" } } }}
      onClick={() => {
        props.navigate({
          pathname: props.backUrl,
          search: `SPHostUrl=${props.spHostUrl}`
        });
      }}
      styles={backButtonStyle}
    />
  );
};

/**
 * Gets the INavigationEntry required to render headers area for the page such as Breadcrumbs, title, pivot and back button.
 * @param activeNav the currently active navigation
 */
const getHeaderNav = (activeNav: INavigationEntry): INavigationEntry => {
  let nav: INavigationEntry;
  switch (activeNav.linkType) {
    case NavLinkType.ParametersPanel:
      nav = activeNav.parent?.parent!;
      break;
    default:
      nav = activeNav.parent!;
      break;
  }
  return nav;
};

const getPivotKeyByLinkType = (activeNav: INavigationEntry) => {
  if (activeNav?.linkType === NavLinkType.ParametersPanel) {
    return activeNav.parent?.url ?? "";
  }
  return activeNav?.url ?? "";
};

const buildBreadcrumbs = (navStack: INavigationEntry[], spHostUrl: string): IBreadcrumbItem[] => {
  let breadcrumbs: IBreadcrumbItem[] = [];

  if (navStack.length > 0) {
    for (let i = 0; i < navStack.length; i++) {
      const nav = navStack[i];
      if (
        (i > 0 &&
          nav.linkType !== NavLinkType.ParametersPanel &&
          nav.url !== navStack[i - 1].url) ||
        i === 0
      ) {
        breadcrumbs.push({
          text: nav.name,
          href: `${nav.url}?SPHostUrl=${spHostUrl}`,
          key: nav.name
        });
      }
    }
  }

  return breadcrumbs;
};

const breadcrumbStyles: Partial<IBreadcrumbStyles> = {
  chevron: {
    fontWeight: "bold",
    fontSize: "12px"
  },
  itemLink: {
    fontWeight: "normal",
    fontSize: "16px"
  },
  root: { margin: "5px 0px" }
};

const BreadcrumbContainer = styled(StackItem)`
  height: 47px;
  padding: 0px 7px;
  box-sizing: border-box;
  border-bottom: 1px solid;
  border-bottom-color: #eee;
`;

const PivotContainer = styled(Stack)`
  height: 45px;
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid;
  border-bottom-color: #eee;
  align-items: baseline;
`;
