import {
  ConstrainMode,
  DetailsList,
  IDetailsListProps,
  mergeStyleSets,
  Panel,
  PanelType,
  Pivot,
  PivotItem,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  Text,
  ActionButton
} from "@fluentui/react";
import { OpenAPIV2 } from "openapi-types";
import styled from "styled-components";
import { WorkPointTheme } from "@workpoint/components/lib/constants";
import { processSelector } from "../../../../store/processReducer";
import { ToRoute } from "../../../../routes";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { NewAddStepDetails } from "./NewAddStepDetails";
import { NewStepItem } from "./NewStepItem";
import { RowType } from "../../../../utils/processUtils";

interface NewItemPanelProps {
  allItems: any;
  type: RowType;
  addNewItem: (item: any) => void;
}

const NewRowPanelBase = (props: NewItemPanelProps) => {
  const intl = useIntl();
  const { processId, rowType, rowIndex, insertIndex } = useParams() as any;
  const navigate = useNavigate();
  const { allItems, type, addNewItem } = props;
  const [selectedPivot, setPivotSelection] = useState<string>("All");
  const [pivots, setPivots] = useState<string[]>([]);
  const { process } = useAppSelector(processSelector);

  const [selectedItem, setSelectedItem] = useState<OpenAPIV2.OperationObject>();
  const [searchFilter, setSearchFilter] = useState<string>("");

  useEffect(() => {
    allItems && allItems.length > 0 && setCategories(allItems);
  }, [allItems]);

  const _closePanel = (goToNewRow?: boolean) => {
    if (goToNewRow) {
      navigate(
        type === RowType.Step
          ? ToRoute.processStep(
              process,
              (process.configuration.steps.length > 0 ? parseInt(insertIndex) + 1 : 0).toString()
            )
          : ToRoute.processTrigger(
              process,
              (process.configuration.triggers && process.configuration.triggers.length > 0
                ? parseInt(insertIndex) + 1
                : 0
              ).toString()
            )
      );
    } else if (rowType && rowIndex && process.configuration.steps.length > 0) {
      navigate(
        rowType === RowType.Step
          ? ToRoute.processStep(process, rowIndex)
          : ToRoute.processTrigger(process, rowIndex)
      );
    } else {
      navigate(ToRoute.process(processId ?? "new"));
    }
  };

  const _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    let icon;
    if (type === RowType.Step) {
      icon = (props?.item as any)["x-workpoint-type"] === "user" ? "UserEvent" : "SetAction";
    } else if (type === RowType.Trigger) {
      icon = "TriggerAuto";
    }
    return (
      <NewStepItem
        item={props?.item}
        onDetailClick={onItemDetailClick}
        isSelected={selectedItem?.operationId === props?.item["operationId"]}
        icon={icon}
      />
    );
  };

  const _addNewItem = (item: OpenAPIV2.OperationObject) => {
    addNewItem(item);

    _closePanel(true);
  };

  const onItemDetailClick = (item: OpenAPIV2.OperationObject) => {
    setSelectedItem(item);
  };

  const _onChangeText = async (
    ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    text?: string
  ): Promise<void> => {
    setSearchFilter(text?.toLowerCase() ?? "");
  };

  const onRenderFooterContent = () => {
    return (
      <>
        {selectedItem && (
          <StyledFooter>
            <Text variant={"mediumPlus"}>
              {type === RowType.Step
                ? intl.formatMessage({
                    id: "detail-to-add-step",
                    defaultMessage: "Would you like to add this step?"
                  })
                : intl.formatMessage({
                    id: "detail-to-add-trigger",
                    defaultMessage: "Would you like to add this trigger?"
                  })}
            </Text>
            <PrimaryButton
              iconProps={{
                iconName: "CheckMark"
              }}
              text={
                type === RowType.Step
                  ? intl.formatMessage({ id: "detail-add-step", defaultMessage: "Add step" })
                  : intl.formatMessage({ id: "detail-add-trigger", defaultMessage: "Add trigger" })
              }
              onClick={() => _addNewItem(selectedItem)}
            />
          </StyledFooter>
        )}
      </>
    );
  };

  const searchBoxStyles = mergeStyleSets({
    root: {
      backgroundColor: "#45718E",
      border: `0px solid ${WorkPointTheme.palette.white} !important`,
      selectors: {
        ":after": {
          border: "0px"
        }
      }
    },
    icon: { color: WorkPointTheme.palette.white },
    field: {
      color: WorkPointTheme.palette.white,
      padding: "8px",
      input: {
        "&::placeholder": {
          color: `${WorkPointTheme.palette.neutralQuaternaryAlt}`
        }
      }
    }
  });

  const setCategories = (items: OpenAPIV2.OperationObject[]) => {
    const categories = items
      ?.filter((item: any) => item["x-workpoint-categories"])
      .flatMap((item: any) => item["x-workpoint-categories"])
      .filter((value: string, index: number, self: string[]) => self.indexOf(value) === index)
      .sort();
    setPivots(["All", ...categories]);
  };

  const items =
    allItems?.filter(
      (step: any) =>
        step["x-workpoint-license"] === "standard" &&
        (selectedPivot === "All" || step["x-workpoint-categories"]?.includes(selectedPivot)) &&
        (searchFilter
          ? (step["summary"] as string)?.toLowerCase().indexOf(searchFilter) > -1
          : true)
    ) ?? [];

  const premiumItems =
    allItems?.filter(
      (step: any) =>
        step["x-workpoint-license"] === "premium" &&
        (step["x-workpoint-categories"]?.includes(selectedPivot) || selectedPivot === "All")
    ) ?? [];

  return (
    <Panel
      isOpen={true}
      onDismiss={() => {
        _closePanel();
      }}
      customWidth={selectedItem ? "60%" : "30%"}
      type={PanelType.customNear}
      closeButtonAriaLabel="Close"
      hasCloseButton={false}
      isLightDismiss
      styles={{
        content: {
          padding: "0px",
          height: selectedItem ? "calc(100% - 230px)" : "calc(100% - 196px)"
        },
        commands: { margin: "0px", padding: "0px" }
      }}
      onRenderFooterContent={onRenderFooterContent}
      onRenderHeader={() => {
        return (
          <div>
            <StyledPanelHeader rowType={type}>
              <CloseButton
                iconProps={{
                  iconName: "Cancel",
                  style: { color: WorkPointTheme.palette.white }
                }}
                onClick={() => {
                  _closePanel();
                }}
              >
                Close
              </CloseButton>
            </StyledPanelHeader>
            <StyledSearchContainer>
              <SearchBox
                placeholder={
                  type === RowType.Step
                    ? intl.formatMessage({
                        id: "process-step-modal-search",
                        defaultMessage: "Search process steps and actions"
                      })
                    : intl.formatMessage({
                        id: "process-trigger-modal-search",
                        defaultMessage: "Search process triggers"
                      })
                }
                onChange={_onChangeText}
                clearButtonProps={{
                  iconProps: {
                    iconName: "Cancel",
                    style: { color: WorkPointTheme.palette.white, fontSize: "20px" }
                  }
                }}
                styles={searchBoxStyles}
                autoFocus
              />
            </StyledSearchContainer>
            <StyledPivot
              onLinkClick={(item?: PivotItem) => setPivotSelection(item?.props.headerText ?? "All")}
            >
              {pivots.map((pivot) => (
                <PivotItem key={pivot} headerText={pivot}></PivotItem>
              ))}
            </StyledPivot>
          </div>
        );
      }}
    >
      <StyledPanelBody>
        <StyledPanelBodyInner minWidth={selectedItem ? "50%" : "100%"}>
          <div>
            <DetailsList
              items={items}
              selectionMode={SelectionMode.single}
              onRenderRow={_onRenderRow}
              isHeaderVisible={false}
              styles={{
                contentWrapper: { overflow: "auto" }
              }}
              onShouldVirtualize={() => false}
              constrainMode={ConstrainMode.unconstrained}
            />
          </div>
          {type === RowType.Step && searchFilter.length <= 0 && (
            <div>
              <DetailsList
                onRenderDetailsHeader={() => {
                  return (
                    <StyledListHeader>
                      <Text variant={"large"}>
                        {intl.formatMessage({
                          id: "detail-licensed-steps",
                          defaultMessage: "Licensed Steps"
                        })}
                      </Text>
                    </StyledListHeader>
                  );
                }}
                items={premiumItems}
                selectionMode={SelectionMode.none}
                onRenderRow={_onRenderRow}
                styles={{
                  contentWrapper: { overflow: "auto" }
                }}
                onShouldVirtualize={() => false}
                constrainMode={ConstrainMode.unconstrained}
              />
            </div>
          )}
        </StyledPanelBodyInner>
        {selectedItem && (
          <StyledPanelBodyInner>
            <NewAddStepDetails item={selectedItem} />
          </StyledPanelBodyInner>
        )}
      </StyledPanelBody>
    </Panel>
  );
};

const PanelHeader = ({ className, children, iconName, rowType }: any) => (
  <div className={className}>
    <Text className={"title"} variant={"xLarge"} style={{ color: "white" }}>
      Add a {rowType === RowType.Step ? "step" : "trigger"}
    </Text>
    {children}
  </div>
);

const StyledPanelHeader = styled(PanelHeader)`
  display: flex;
  align-items: center;
  background-color: ${WorkPointTheme.palette.blue};
  color: ${WorkPointTheme.palette.white};
  height: 55px;
  .title {
    display: flex;
    flex-basis: 100%;
    margin-left: 32px;
  }
`;

const CloseButton = styled(ActionButton)`
  flex: content;
  color: ${WorkPointTheme.palette.white};

  &:hover {
    color: ${WorkPointTheme.palette.white};
  }
`;

const StyledSearchContainer = styled.div`
  padding: 16px 32px;
  background-color: #45718e;
`;

const PanelBodyInner = ({ className, children, minWidth }: any) => (
  <div className={className} style={{ minWidth: minWidth ? minWidth : "50%" }}>
    {children}
  </div>
);

const StyledPanelBody = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  padding-left: 16px;
`;

const StyledPanelBodyInner = styled(PanelBodyInner)`
  min-width: 50%;
  overflow: auto;
`;

const StyledListHeader = styled.div`
  margin: 32px 16px 16px;
`;

const StyledPivot = styled(Pivot)`
  padding-left: 32px;
`;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-left: 50%;
`;

export default NewRowPanelBase;
