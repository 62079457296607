import { Dialog, DialogType, IDialogContentProps, mergeStyles } from "@fluentui/react";
import * as React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
  ProcessConfiguration,
  ProcessGroup,
  ProcessType
} from "@workpoint/components/lib/models/ProcessConfiguration";
import { ToRoute } from "../../../../routes";
import { useAppDispatch } from "../../../../store/hooks";
import { newProcess } from "../../../../store/processReducer";
import { NewProcessTemplates } from "./newProcessTemplates";
import { v4 as uuid } from "uuid";
import { useState } from "react";
import { NewProcessDetails } from "./newProcessDetails";
import { defaultProcessConfiguration } from "../../../../utils/processUtils";
import { cloneDeep } from "lodash";

export const NewProcessDialog = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [template, setTemplate] = useState<ProcessConfiguration | undefined>();

  const dialogContentProps: IDialogContentProps = {
    type: DialogType.normal,
    title: intl.formatMessage({ id: "dialog-create-new", defaultMessage: "Create new" }),
    closeButtonAriaLabel: "Close",
    styles: { inner: { overflow: "auto", maxHeight: "600px" } },
    showCloseButton: true
  };

  const scrollableClass = mergeStyles({
    overflow: "hidden",
    flexGrow: 1,
    maxHeight: "100vh"
  });

  const onCloseClick = () => {
    navigate(ToRoute.processes());
  };

  const onPreviousClick = () => {
    setTemplate(undefined);
  };

  const onTemplateClick = (item?: ProcessConfiguration) => {
    if (item) {
      const template = cloneDeep(item);
      template.id = uuid();
      template.steps.forEach((step) => {
        step.id = uuid();
      });
      setTemplate(template);
    } else {
      setTemplate(defaultProcessConfiguration(uuid()));
    }
  };

  const onBeginClick = (type: ProcessType, title: string, group: ProcessGroup) => {
    if (template) {
      dispatch(newProcess(template, type, title, group, navigate));
    }
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={dialogContentProps}
      onDismiss={onCloseClick}
      minWidth={500}
      modalProps={{
        scrollableContentClassName: scrollableClass
      }}
      styles={{ main: { maxHeight: "600px", overflow: "hidden" } }}
    >
      <div>
        {template ? (
          <NewProcessDetails
            onPreviousClick={onPreviousClick}
            onBeginClick={onBeginClick}
            template={template}
          />
        ) : (
          <NewProcessTemplates onClick={onTemplateClick}></NewProcessTemplates>
        )}
      </div>
    </Dialog>
  );
};
