import * as React from "react";
import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { processSelector, getStep, updateStep, deleteStep } from "../../../../store/processReducer";
import { useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { ToRoute } from "../../../../routes";
import {
  ProcessParameterProperty,
  ProcessStepConfiguration,
  ProcessStepParameterConfiguration,
  ConfigurationParameterType,
  ProcessType,
  ProcessStepType
} from "@workpoint/components/lib/models/ProcessConfiguration";
import { CommandButton, IDropdownOption, Panel, PanelType, PivotItem } from "@fluentui/react";
import { showDialog } from "../../../../store/dialogReducer";
import { DialogResult, DialogType } from "../../../common/Dialog";
import { PanelHeader } from "../../../common/PanelHeader";
import {
  StyledControlledTextField,
  FormControlType,
  StyledFieldContainer
} from "../../../common/parametersForm/ParametersForm";
import { ProcessParametersForm } from "./ProcessParametersForm";
import { Control, FieldValues, UseFormSetValue, UseFormTrigger } from "react-hook-form";
import FieldInfo from "../../../common/FieldInfo";
import { ControlledConditions } from "../../../common/parametersForm/ControlledConditions";
import { ParameterPropertyControl } from "../../../common/parametersForm/ParameterPropertyControl";
import {
  formDefaultParameters,
  RowType,
  validateProcessStep
} from "../../../../utils/processUtils";
import { isObjectEqual } from "../../../../utils/commonUtils";
import { cloneDeep, set, get } from "lodash";
import { OpenAPIV2 } from "openapi-types";
import { ProcessContext } from "@workpoint/components/lib/models/ProcessInstance";
import { getSPHostUrl } from "../../../../utils/commonUtils";
import { ParameterControl } from "../../../common/parametersForm/ParameterControl";
import { getTransitionDefinition } from "../../processConstants";
import { evaluateExpressionForDisplay } from "@workpoint/components/lib/helpers/expressionUtils";
import { AccountPicker } from "../../../common/parametersForm/AccountPicker";
import { isSameOrder } from "../../../../utils/commonUtils";

export const StepParametersPanel = () => {
  const { processId, rowIndex } = useParams() as any;
  const navigate = useNavigate();
  const { document, process, step } = useAppSelector(
    processSelector,
    (a, b) =>
      isObjectEqual(a.step?.configuration, b.step?.configuration) &&
      isSameOrder(a.process?.configuration?.steps, b.process?.configuration?.steps)
  );
  const dispatch = useAppDispatch();
  const intl = useIntl();

  useEffect(() => {
    if (rowIndex !== undefined && !isNaN(rowIndex)) {
      if (parseInt(rowIndex) >= 0 && parseInt(rowIndex) < process.configuration.steps.length) {
        dispatch(getStep(rowIndex));
      } else {
        navigate(ToRoute.process(processId));
      }
    }
  }, [navigate, dispatch, process, processId, rowIndex]);

  const isUserStep = (step?.definition as any)?.["x-workpoint-type"] === ProcessStepType.User;

  const _closeParametersPanel = () => {
    if (processId) {
      navigate(ToRoute.process(processId));
    } else {
      navigate(ToRoute.newProcess());
    }
  };

  const _getUpdateValue = (
    name: string,
    paramType: ConfigurationParameterType,
    data: any,
    parameters: ProcessStepParameterConfiguration[],
    paramDefinition?: OpenAPIV2.Parameter | OpenAPIV2.SchemaObject,
    parent?: string
  ) => {
    const value = get(data, (parent ? parent + "." : "") + name);

    const dynamicParameters: ProcessStepParameterConfiguration[] = [];
    let config: ProcessStepParameterConfiguration = {
      name,
      type: paramType
    };

    if (
      (paramDefinition?.schema && paramDefinition.schema["x-ms-dynamic-schema"]) ||
      (paramDefinition as any)?.["x-ms-dynamic-schema"]
    ) {
      if (data[name]) {
        Object.keys(data[name]).forEach((paramName) => {
          if (data[name][paramName]) {
            const val = _getUpdateValue(
              paramName,
              data[name][paramName].type ?? ConfigurationParameterType.Dynamic,
              data,
              parameters,
              undefined,
              name
            );
            if (
              val.config.value ||
              val.config.hidden ||
              val.config.readonly ||
              val.config.required ||
              val.config.valid ||
              val.config.order
            ) {
              val.config.parent = name;
              dynamicParameters.push(val.config);
            }
          }
        });
      }
    } else if (value?.name) {
      config = { ...cloneDeep(value), type: paramType };
    } else {
      const paramConfig = parameters.find((param) => param.name === config.name);
      if (paramType === ConfigurationParameterType.Static && paramDefinition) {
        config.title = {
          id: paramConfig?.title?.id ?? `${step?.configuration?.id}-${config.name}-title`,
          defaultText:
            paramConfig?.title?.defaultText ??
            paramDefinition["x-ms-summary"] ??
            paramDefinition.name
        };
        config.description = {
          id:
            paramConfig?.description?.id ?? `${step?.configuration?.id}-${config.name}-description`,
          defaultText: paramConfig?.description?.defaultText ?? paramDefinition.description ?? ""
        };
      }

      if (value) {
        Object.values(ProcessParameterProperty).forEach((propName) => {
          if (value[propName]) {
            //propName in value &&
            set(config, propName, cloneDeep(value[propName]));
          }
        });
      }
    }

    return { config, dynamicParameters };
  };

  const _updateStep = (data: any, context?: ProcessContext, closePanel?: boolean) => {
    const updatedConfig = cloneDeep(step?.configuration) as ProcessStepConfiguration;

    Object.values(formDefaultParameters)
      .filter((dfp) => dfp.panels.indexOf(RowType.Step) >= 0)
      .forEach((fdp) => {
        if (fdp.formKey in data) {
          set(updatedConfig, fdp.objectKey, cloneDeep(data[fdp.formKey]));
        }
      });

    const parameters: ProcessStepParameterConfiguration[] = [];
    step?.definition?.parameters?.forEach((param) => {
      const definition = param as OpenAPIV2.Parameter;

      if (definition.name === "WorkPoint365Url") {
        parameters.push({
          name: definition.name,
          type: ConfigurationParameterType.Static,
          value: { data: `'${getSPHostUrl()}'` }
        });
      } else if (
        definition.schema &&
        definition.schema.properties &&
        Object.keys(definition.schema.properties).length > 0
      ) {
        const paramRef = definition.schema as OpenAPIV2.SchemaObject;
        Object.keys(paramRef.properties!).forEach((paramRefName) => {
          const { config, dynamicParameters } = _getUpdateValue(
            paramRefName,
            ConfigurationParameterType.Static,
            data,
            updatedConfig.parameters,
            paramRef.properties![paramRefName]
          );
          parameters.push(config);
          parameters.push(...dynamicParameters);
        });
      } else if (
        (definition.schema && definition.schema["x-ms-dynamic-schema"]) ||
        definition["x-ms-dynamic-schema"]
      ) {
        if (data[definition.name]) {
          Object.keys(data[definition.name]).forEach((paramName) => {
            const { config } = _getUpdateValue(
              paramName,
              data[definition.name][paramName].type ?? ConfigurationParameterType.Dynamic,
              data,
              updatedConfig.parameters,
              undefined,
              definition.name
            );
            if (
              config.value ||
              config.hidden ||
              config.readonly ||
              config.required ||
              config.valid ||
              config.order
            ) {
              config.parent = definition.name;
              parameters.push(config);
            }
          });
        }
      } else if (definition["x-workpoint-control"]?.type === FormControlType.DynamicSchema) {
        if (data[definition.name]) {
          Object.keys(data[definition.name]).forEach((paramName) => {
            if (data[definition.name][paramName]?.dataType) {
              const { config, dynamicParameters } = _getUpdateValue(
                paramName,
                ConfigurationParameterType.Custom,
                data,
                updatedConfig.parameters,
                undefined,
                definition.name
              );
              config.parent = definition.name;
              parameters.push(config);
              //parameters.push(...dynamicParameters);
            }
          });
        }
      } else {
        const { config, dynamicParameters } = _getUpdateValue(
          definition.name,
          ConfigurationParameterType.Static,
          data,
          updatedConfig.parameters,
          definition
        );
        parameters.push(config);
        //parameters.push(...dynamicParameters);

        if (definition["x-workpoint-control"]?.type === FormControlType.SubProcess) {
          (updatedConfig as ProcessStepConfiguration).subProcessConfigurationId = data[
            definition.name
          ]?.value
            ? cloneDeep(data[definition.name]?.value)
            : undefined;
        }
      }
    });

    updatedConfig.parameters = parameters;

    dispatch(updateStep(updatedConfig));

    if (closePanel) {
      _closeParametersPanel();
    }

    return validateProcessStep(document, process.configuration, updatedConfig);
  };

  const getContextOptions = () => {
    const options: IDropdownOption[] = [];
    options.push({
      key: "'Entity'",
      text: "Business Module Entity"
    });
    options.push({
      key: "'Items'",
      text: "Selected Items"
    });
    if (process.type === ProcessType.System) {
      options.push({
        key: "'Item'",
        text: "Triggered Item"
      });
    }

    for (let i = 0; i < process?.configuration.steps.length; i++) {
      const s = process?.configuration.steps[i];
      if (s.id === step?.configuration?.id) {
        break;
      }

      options.push({
        key: `'${s.name}'`,
        text: `Output from '${evaluateExpressionForDisplay(s.title.defaultText, {})}'`
      });
    }

    return options;
  };

  const _onRenderHeader = (): JSX.Element => {
    const additionalButtons: JSX.Element[] = [
      <CommandButton
        iconProps={{ iconName: "Delete" }}
        text={intl.formatMessage({ id: "option-delete", defaultMessage: "Delete" })}
        onClick={() => {
          if (step?.configuration) {
            dispatch(
              showDialog({
                type: DialogType.DeleteCancel,
                title: "Delete?", // CTRLLANG
                subText: "Are you sure?", // CTRLLANG
                onClick: (result: DialogResult) => {
                  if (result === DialogResult.Ok) {
                    dispatch(
                      deleteStep(step.configuration! as unknown as ProcessStepConfiguration)
                    );
                    _closeParametersPanel();
                  }
                }
              })
            );
          }
        }}
      />
    ];

    return (
      <PanelHeader
        title={intl.formatMessage({
          id: "step-panel-properties",
          defaultMessage: "Step Properties"
        })}
        additionalButtons={additionalButtons}
        closePanel={_closeParametersPanel}
      >
        <FieldInfo
          label1={"Step"}
          label2={step?.definition?.summary}
          iconName={step?.configuration?.type === "user" ? "UserEvent" : "SetAction"}
        />
      </PanelHeader>
    );
  };

  return (
    <Panel
      isOpen={rowIndex !== undefined && !isNaN(rowIndex)}
      type={PanelType.smallFluid}
      closeButtonAriaLabel="Close"
      hasCloseButton={false}
      focusTrapZoneProps={{
        isClickableOutsideFocusTrap: true,
        forceFocusInsideTrap: false
      }}
      isLightDismiss={false}
      styles={{
        root: {
          width: "100%",
          borderLeftWidth: "1px",
          borderLeftStyle: "solid",
          borderLeftColor: "#E5E5E5"
        },
        main: { boxShadow: "0px 0px 0px 0px", overflow: "hidden" },
        commands: { display: "none" }
      }}
      onRenderHeader={_onRenderHeader}
    >
      {step !== undefined && step.configuration !== undefined && step.definition !== undefined && (
        <ProcessParametersForm
          formTitle={step?.configuration?.title?.defaultText ?? ""}
          close={_closeParametersPanel}
          update={_updateStep}
          document={document!}
          definition={step?.definition}
          configuration={step?.configuration}
          onRender={(pivots, control, openLocalizationPanel, setValue, trigger, context) => {
            pivots["General"].splice(
              0,
              0,
              <StyledFieldContainer key={formDefaultParameters.title.formKey}>
                <ParameterPropertyControl
                  key={formDefaultParameters.title.formKey}
                  control={control}
                  setValue={setValue}
                  trigger={trigger}
                  name={`${formDefaultParameters.title.formKey}`}
                  label="Title"
                  description="The title of the step"
                  defaultValue={undefined}
                  propertyType={ProcessParameterProperty.Title}
                  controlType={FormControlType.PlainText}
                  required={true}
                  definition={{
                    in: "",
                    name: `${formDefaultParameters.title.formKey}`,
                    type: ""
                  }}
                  rows={1}
                  enableLocalization
                  skipPropertyTypeInName
                />
              </StyledFieldContainer>
            );
            pivots.General.splice(
              1,
              0,
              <StyledFieldContainer key={formDefaultParameters.description.formKey}>
                <ParameterPropertyControl
                  key={formDefaultParameters.description.formKey}
                  control={control}
                  setValue={setValue}
                  trigger={trigger}
                  name={`${formDefaultParameters.description.formKey}`}
                  label="Description"
                  description="The description of the step"
                  defaultValue={undefined}
                  propertyType={ProcessParameterProperty.Description}
                  controlType={FormControlType.RichText}
                  required={false}
                  definition={{
                    in: "",
                    name: `${formDefaultParameters.description.formKey}`,
                    type: ""
                  }}
                  rows={2}
                  enableLocalization
                  skipPropertyTypeInName
                />
              </StyledFieldContainer>
            );
            pivots["General"].splice(
              2,
              0,
              <StyledControlledTextField
                key={formDefaultParameters.name.formKey}
                name={`${formDefaultParameters.name.formKey}`}
                label="Name"
                placeholder={intl.formatMessage({
                  id: "panel-description-placeholder",
                  defaultMessage: "Enter a context object name"
                })}
                control={control}
                required={false}
                defaultValue={undefined}
              />
            );
            isUserStep &&
              pivots["General"].splice(
                3,
                0,
                <StyledFieldContainer key={formDefaultParameters.continueButtonTitle.formKey}>
                  <ParameterPropertyControl
                    key={formDefaultParameters.continueButtonTitle.formKey}
                    control={control}
                    setValue={setValue}
                    trigger={trigger}
                    name={`${formDefaultParameters.continueButtonTitle.formKey}`}
                    label="Continue button title"
                    description="The title of the continue button"
                    defaultValue={undefined}
                    propertyType={ProcessParameterProperty.Title}
                    controlType={FormControlType.PlainText}
                    required={false}
                    definition={{
                      in: "",
                      name: `${formDefaultParameters.continueButtonTitle.formKey}`,
                      type: ""
                    }}
                    rows={1}
                    enableLocalization
                    skipPropertyTypeInName
                  />
                </StyledFieldContainer>
              );
            pivots["General"].splice(
              isUserStep ? 4 : 3,
              0,
              <ParameterPropertyControl
                key={formDefaultParameters.contextObjectName.formKey}
                control={control}
                setValue={(name, value, options) => {
                  if (
                    typeof value === "string" &&
                    value.length > 0 &&
                    !value.startsWith("'") &&
                    !value.endsWith("'")
                  ) {
                    setValue(name, `'${value}'` as any, options);
                  } else if (Array.isArray(value)) {
                    setValue(
                      name,
                      value.map((v: any) =>
                        typeof v === "string" &&
                        v.length > 0 &&
                        !v.startsWith("'") &&
                        !v.endsWith("'")
                          ? `'${v}'`
                          : v
                      ),
                      options
                    );
                  } else {
                    setValue(name, value, options);
                  }
                }}
                options={getContextOptions()}
                trigger={trigger}
                name={`${formDefaultParameters.contextObjectName.formKey}`}
                label="Step Input"
                description=""
                defaultValue={undefined}
                propertyType={ProcessParameterProperty.Value}
                controlType={FormControlType.ValuePicker}
                required={false}
                definition={{
                  in: "",
                  name: `${formDefaultParameters.contextObjectName.formKey}`,
                  schema: {},
                  type: "",
                  "x-workpoint-multi":
                    (step?.definition as any)?.["x-workpoint-input"]?.type === "array" ||
                    (step?.definition as any)?.["x-workpoint-input"]?.type === "object"
                }}
                skipPropertyTypeInName
              />
            );
          }}
          additionalPivots={(
            control: Control<FieldValues>,
            openLocalizationPanel: (name: string, title: string) => void,
            setValue: UseFormSetValue<any>,
            trigger: UseFormTrigger<any>,
            getValues: any,
            register: any,
            unregister: any
          ) => {
            const pivots = [];

            if (isUserStep) {
              pivots.push(
                <PivotItem
                  key={"StepValidation"}
                  headerText={"Validation"}
                  itemKey={"StepValidation"}
                  alwaysRender={true}
                >
                  <ParameterPropertyControl
                    control={control}
                    setValue={setValue}
                    trigger={trigger}
                    name={formDefaultParameters.validMessage.formKey}
                    label={"Message"}
                    defaultValue={undefined}
                    propertyType={ProcessParameterProperty.Title}
                    controlType={FormControlType.RichText}
                    required={false}
                    definition={{
                      name: formDefaultParameters.validMessage.formKey,
                      in: "",
                      type: "string",
                      "x-workpoint-control": { type: "richText" }
                    }}
                    skipPropertyTypeInName
                    enableLocalization
                  />
                  <ControlledConditions
                    control={control}
                    name={formDefaultParameters.valid.formKey}
                    setValue={setValue}
                    trigger={trigger}
                    showConditionsTitle
                  />
                </PivotItem>
              );
            }
            pivots.push(
              <PivotItem
                key={"StepRules"}
                headerText={"Rules"}
                itemKey={"StepRules"}
                alwaysRender={true}
              >
                <ParameterControl
                  definition={
                    {
                      "x-ms-summary": "Disable context reload",
                      type: "boolean",
                      "x-workpoint-control": {
                        type: "boolean"
                      }
                    } as any
                  }
                  control={control}
                  document={document}
                  getValues={getValues}
                  register={register}
                  setValue={setValue}
                  trigger={trigger}
                  unregister={unregister}
                  name={formDefaultParameters.disableContextReload.formKey}
                  required={false}
                  fieldValues={{} as any}
                  skipPropertyTypeInName={true}
                  stepConfiguration={step.configuration}
                />
                <ParameterControl
                  definition={
                    {
                      "x-ms-summary": "Disable output data load",
                      type: "boolean",
                      "x-workpoint-control": {
                        type: "boolean"
                      }
                    } as any
                  }
                  control={control}
                  document={document}
                  getValues={getValues}
                  register={register}
                  setValue={setValue}
                  trigger={trigger}
                  unregister={unregister}
                  name={formDefaultParameters.disableOutputLoad.formKey}
                  required={false}
                  fieldValues={{} as any}
                  skipPropertyTypeInName={true}
                  stepConfiguration={step.configuration}
                />
                <ParameterControl
                  definition={
                    {
                      "x-ms-summary": "Pre-evaluation title",
                      "x-workpoint-control": {
                        type: "plainText",
                        translatable: true,
                        propertyType: ProcessParameterProperty.Title
                      }
                    } as any
                  }
                  control={control}
                  document={document}
                  getValues={getValues}
                  register={register}
                  setValue={setValue}
                  trigger={trigger}
                  unregister={unregister}
                  name={formDefaultParameters.executionMessage.formKey}
                  required={false}
                  fieldValues={{} as any}
                  skipValueInName={true}
                  stepConfiguration={step.configuration}
                  rows={1}
                />
                <ParameterControl
                  definition={
                    {
                      "x-ms-summary": "Pre-evaluation message",
                      "x-workpoint-control": {
                        type: "richText",
                        translatable: true,
                        propertyType: ProcessParameterProperty.Description
                      }
                    } as any
                  }
                  control={control}
                  document={document}
                  getValues={getValues}
                  register={register}
                  setValue={setValue}
                  trigger={trigger}
                  unregister={unregister}
                  name={formDefaultParameters.executionMessage.formKey}
                  required={false}
                  fieldValues={{} as any}
                  skipValueInName={true}
                  stepConfiguration={step.configuration}
                  rows={3}
                />
                {(step.definition as any)?.["x-workpoint-type"] === ProcessStepType.Action && (
                  <ParameterPropertyControl
                    control={control}
                    setValue={setValue}
                    trigger={trigger}
                    name={formDefaultParameters.disableInteraction.formKey}
                    label={"Disable interaction"}
                    defaultValue={undefined}
                    propertyType={ProcessParameterProperty.Value}
                    controlType={FormControlType.Boolean}
                    required={false}
                    definition={{
                      name: formDefaultParameters.disableInteraction.formKey,
                      in: "",
                      type: ""
                    }}
                    skipPropertyTypeInName
                  />
                )}
                <ParameterControl
                  definition={getTransitionDefinition(
                    (step?.definition as any)?.["x-workpoint-type"] === "action",
                    process.configuration.steps,
                    rowIndex
                  )}
                  control={control}
                  document={document}
                  getValues={getValues}
                  register={register}
                  setValue={setValue}
                  trigger={trigger}
                  unregister={unregister}
                  name={formDefaultParameters.execution.formKey}
                  required={false}
                  fieldValues={{} as any}
                  skipValueInName={true}
                  stepConfiguration={step.configuration}
                />
              </PivotItem>
            );
            pivots.push(
              <PivotItem
                key={"StepPermissions"}
                headerText={"Permissions"}
                itemKey={"StepPermissions"}
                alwaysRender={true}
              >
                {(step?.definition as any)?.["x-workpoint-type"] === "action" && (
                  <AccountPicker
                    control={control}
                    setValue={setValue}
                    trigger={trigger}
                    name={formDefaultParameters.permissionExecuteAs.formKey}
                    label={"Execute as"}
                    required={false}
                    skipPropertyTypeInName
                    hideConditions
                    hideContextBrowser
                  />
                )}
                <ParameterPropertyControl
                  control={control}
                  setValue={setValue}
                  trigger={trigger}
                  name={formDefaultParameters.permissionMessageTitle.formKey}
                  label={"Permissions message title"}
                  defaultValue={undefined}
                  propertyType={ProcessParameterProperty.Title}
                  controlType={FormControlType.PlainText}
                  required={false}
                  definition={{
                    name: formDefaultParameters.permissionMessageTitle.formKey,
                    in: "",
                    type: "string",
                    "x-workpoint-control": { type: "plainText" }
                  }}
                  rows={1}
                  skipPropertyTypeInName
                  enableLocalization
                  stepConfiguration={step.configuration as ProcessStepConfiguration}
                />
                <ParameterPropertyControl
                  control={control}
                  setValue={setValue}
                  trigger={trigger}
                  name={formDefaultParameters.permissionMessageBody.formKey}
                  label={"Permissions message"}
                  defaultValue={undefined}
                  propertyType={ProcessParameterProperty.Description}
                  controlType={FormControlType.RichText}
                  required={false}
                  definition={{
                    name: formDefaultParameters.permissionMessageBody.formKey,
                    in: "",
                    type: "string",
                    "x-workpoint-control": { type: "richText" }
                  }}
                  skipPropertyTypeInName
                  enableLocalization
                  stepConfiguration={step.configuration as ProcessStepConfiguration}
                />
                <ControlledConditions
                  control={control}
                  name={formDefaultParameters.permissionConditions.formKey}
                  setValue={setValue}
                  trigger={trigger}
                  showConditionsTitle
                />
              </PivotItem>
            );

            return pivots;
          }}
        />
      )}
    </Panel>
  );
};
