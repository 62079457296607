import { IButtonStyles, IconButton, Stack, Text } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import WorkPointTheme from "@workpoint/components/lib/constants";
import { IQuickLinkProps, QuickLink } from "./QuickLink";

interface IQuickLinkGroupProps {
  groupName: string;
  quickLinks: IQuickLinkProps[];
  linksPerRow?: number;
}

export const QuickLinkGroup = (props: IQuickLinkGroupProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
      background: WorkPointTheme.palette.white,
      borderRadius: "2px",
      color: WorkPointTheme.palette.black
    },
    rootHovered: {
      color: WorkPointTheme.palette.black
    }
  };

  const openAccordion: React.CSSProperties = {
    maxHeight: "500px",
    overflowY: "auto",
    transition: "max-height 1s ease-in-out"
  };
  const closeAccordion: React.CSSProperties = {
    maxHeight: "0px",
    transition: "max-height 1.4s cubic-bezier(0, 1, 0, 1)"
  };

  return (
    <AccordionContainer horizontal={false}>
      <AccordionHeader horizontal={true}>
        <IconButton
          styles={iconButtonStyles}
          onClick={() => setIsOpen(!isOpen)}
          iconProps={{
            iconName: isOpen ? "ChevronUp" : "ChevronDown"
          }}
        />
        <Text variant={"medium"}>{props.groupName}</Text>
      </AccordionHeader>
      <AccordionContentContainer style={isOpen ? openAccordion : closeAccordion}>
        <Stack horizontal wrap tokens={{ childrenGap: "2%" }} styles={{ inner: { margin: "0" } }}>
          {props.quickLinks.length > 0 && (
            <>
              {props.quickLinks.map((link: IQuickLinkProps) => {
                return (
                  <QuickLink
                    key={link.title}
                    {...link}
                    view={"compact"}
                    linksPerRow={props.linksPerRow}
                  />
                );
              })}
            </>
          )}
        </Stack>
      </AccordionContentContainer>
    </AccordionContainer>
  );
};

export const AccordionContainer = styled(Stack)`
  background: ${WorkPointTheme.palette.white};
`;

export const AccordionHeader = styled(Stack)`
  background: ${WorkPointTheme.palette.white};
  align-items: center;
  > * {
    margin-right: 8px;
  }
`;

export const AccordionContentContainer = styled(Stack)`
  overflow: hidden;
`;

export const HeaderActions = styled(Stack)`
  flex: auto;
  align-items: center;
  justify-content: flex-end;
`;
