import { ITextFieldProps, StackItem } from "@fluentui/react";
import { FC } from "react";
import { Controller, FieldError } from "react-hook-form";
import { FormControlProps } from "./ControlledTextField";
import "codemirror/lib/codemirror.css";
import { Controlled as CodeMirror } from "react-codemirror2";
import styled from "styled-components";
import { unpackErrors } from "./ErrorMessage";
import "codemirror/addon/lint/lint.css";
require("codemirror/mode/javascript/javascript.js");
require("codemirror/addon/lint/lint.js");
require("codemirror/addon/lint/json-lint.js");

// @warning: Dirty hack to make linting work as required.
//@ts-ignore
const jsonlint = require("jsonlint-mod");
//@ts-ignore
window.jsonlint = jsonlint;

export interface IControlledRichTextProps extends ITextFieldProps {
  error: FieldError | any;
}

export const ControlledRichText: FC<FormControlProps & IControlledRichTextProps> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || ""}
      render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => (
        <>
          <StyledCodeMirror
            value={value}
            options={{
              mode: "application/json",
              lineNumbers: true,
              lineWrapping: true,
              tabMode: "indent",
              matchBrackets: true,
              indentUnit: 4,
              gutters: ["CodeMirror-lint-markers"],
              lint: true,
              line: true
            }}
            onBlur={onBlur}
            onBeforeChange={(editor, data, value) => {
              onChange(value);
            }}
          />
          {error && <StackItem>{unpackErrors(error)}</StackItem>}
        </>
      )}
    />
  );
};

export const StyledCodeMirror = styled(CodeMirror)`
  border: 1px solid #edebe9;
`;
