import { Spinner, SpinnerSize } from "@fluentui/react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { processSelector, getProcess, clearErrors } from "../../../store/processReducer";
import { ToRoute } from "../../../routes";
import DetailsCommandBar from "./commandBar/DetailsCommandBar";
import RightSide from "./rightSide/RightSide";
import LeftSide from "./leftSide/LeftSide";

export const ProcessDetail = () => {
  const { processId } = useParams() as any;
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { process, hasErrors } = useAppSelector(
    processSelector,
    (a, b) => !!a?.process === !!b?.process && a.hasErrors === b.hasErrors
  );

  useEffect(() => {
    if (!process && !processId) {
      navigate(ToRoute.processes() + "/" + ToRoute.newProcessDialog());
    } else if (processId && (!process || process.itemId?.toString() !== processId)) {
      dispatch(getProcess(processId, navigate));
    }
  }, [dispatch, navigate, process, processId]);

  useEffect(() => {
    if (hasErrors) {
      dispatch(clearErrors());
      navigate(ToRoute.home());
    }
  }, [hasErrors]);

  return (
    <>
      {typeof process === "undefined" && typeof processId !== "undefined" ? (
        <Spinner size={SpinnerSize.large} style={{ marginTop: "100px" }} />
      ) : (
        process && (
          <>
            <DetailsCommandBar />
            <LeftSide />
            <RightSide />
          </>
        )
      )}
    </>
  );
};
