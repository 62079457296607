import { LambdaFetchClient } from "@workpoint/components/lib/helpers/clientHelper";
import {
  BrowserCacheLocation,
  Configuration,
  PublicClientApplication,
  RedirectRequest
} from "@azure/msal-browser";
import { LogLevel } from "@azure/msal-browser";
import { WPClient } from "@workpoint/components/lib/models/WPClient";
import { ApiClient, getWpApi } from "@workpoint/components/lib/clients/ApiClient";
import { initApplicationInsights } from "@workpoint/components/lib/helpers/insights";
import { getSPHostUrl } from "../utils/commonUtils";
import { ISPFXContext } from "@pnp/sp";

const spHostUrl = getSPHostUrl() ?? "";
const wpApiDefinition = getWpApi();

initApplicationInsights(
  wpApiDefinition.insightsKey,
  "ProcessBuiler",
  process.env.REACT_APP_VERSION!,
  getSPHostUrl(),
  {
    enableAutoRouteTracking: true
  }
);

const msalConfig: Configuration = {
  auth: {
    clientId: wpApiDefinition.clientId,
    authority: "https://login.microsoftonline.com/common",
    redirectUri: document.location.origin,
    postLogoutRedirectUri: `${document.location.origin}/loggedout`
  },
  cache: {
    storeAuthStateInCookie: false,
    cacheLocation: BrowserCacheLocation.LocalStorage
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: any, containsPii: boolean) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      }
    }
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const getToken = async (scopes: string[]) => {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */

    const authPayload: RedirectRequest = {
      scopes,
      state: spHostUrl
    };

    await msalInstance.acquireTokenRedirect(authPayload);

    return "";
  }

  const authResult = await msalInstance.acquireTokenSilent({
    scopes,
    account: activeAccount || accounts[0]
  });

  return authResult.accessToken;
};

export const spClient = new LambdaFetchClient(
  async () => await getToken([`https://${new URL(getSPHostUrl() ?? "").hostname}/.default`])
);

const wp = new WPClient(async () => {
  return await getToken(wpApiDefinition.scopes.split(";"));
});

const pnPjsSetup = {
  spConfig: {
    aadTokenProviderFactory: {
      getTokenProvider: async () => ({
        getToken: async (resource: string) => {
          return await getToken([`https://${new URL(getSPHostUrl() ?? "").hostname}/.default`]);
        }
      })
    },
    pageContext: {
      web: { absoluteUrl: "" },
      legacyPageContext: {
        formDigestTimeoutSeconds: 0,
        formDigestValue: ""
      }
    }
  } as ISPFXContext,
  graphConfig: {
    aadTokenProviderFactory: {
      getTokenProvider: async () => ({
        getToken: async (resource: string) => {
          return await getToken(process.env.REACT_APP_GRAPH_SCOPES!.split(";"));
        }
      })
    },
    pageContext: {
      web: { absoluteUrl: "" },
      legacyPageContext: {
        formDigestTimeoutSeconds: 0,
        formDigestValue: ""
      }
    }
  } as ISPFXContext
};

export const getApiClient = () => {
  const spHostUrl = getSPHostUrl();

  return typeof spHostUrl === "string" && spHostUrl !== ""
    ? new ApiClient(spHostUrl, spHostUrl, wpApiDefinition, wp, pnPjsSetup, spClient as any)
    : null;
};

// public async getProcesses(solutionUrl?: string): Promise<any[]> {
//     const rez = await this.fetch(`${WpApiDefinition.origin}/api/Favorites/GetFavorites`, { headers: { "WorkPoint365Url": getSPHostUrl(solutionUrl) } });
//     const json = await rez.json();
//     return json;
// }
// public async get(endpoint: string, solutionUrl?: string): Promise<any> {
//     if (!solutionUrl) {
//         const urlParams = new URLSearchParams(window.location.search);
//         solutionUrl = urlParams.get('SPHostUrl') ?? "";
//     }

//     const url = `${endpoint}?SPHostUrl=${solutionUrl}`;
//     try {
//         const rez = await axios.get(url, { headers: { "WorkPoint365Url": solutionUrl } });
//         return rez.data;
//     } catch (error) {
//         return await this.handleError(error, solutionUrl, async () => { return await this.getWizards(solutionUrl); });
//     }
// }
// public async getWizards(solutionUrl?: string): Promise<any> {
//     return this.get("/ProcessBuilder/GetWizards", solutionUrl);
// }
// public async getSearchResults(): Promise<any> {
//     const rootSiteCollectionUrl = "https://wp365dev.sharepoint.com/sites/SLdev";
//     try {
//         const content = { "QueryText": "ListId:d477a099-bf7e-4c94-b647-6c01b6dea311 ListId:c33fd335-da00-412f-b2fc-a710290787ce ListId:9134b938-1050-42ee-a749-f05598f55bf2", "PageStart": 0, "SortCollection": [], "SelectProperties": ["WorkPointIcon", "Title", "ContentType"], "Refiners": [], "PageSize": 30, "Scope": { "Expression": "", "Title": "Everything", "Type": "EverywhereScope" }, "StartsWithSearch": true, "ResultGroup": { "Name": "All", "Title": "All", "TitleResources": { "en-US": "", "da-DK": "", "nb-NO": "", "nn-NO": "", "nl-NL": "" }, "Id": 1, "Sorting": 1, "BusinessModules": ["d477a099-bf7e-4c94-b647-6c01b6dea311", "c33fd335-da00-412f-b2fc-a710290787ce", "9134b938-1050-42ee-a749-f05598f55bf2"], "ContentTypes": null, "ColumnGroupId": 1, "AdditionalRefiners": null, "Indentation": 0, "TenantWideSearch": false, "SearchScope": null, "DefaultScope": null }, "TermSetFilterId": null, "TermFilterId": null, "TermFilterManagedProperty": null, "TrimDuplicates": false, "TenantWideSearch": false };
//         const fetchUrl = `/Search/SearchEntities?SPHostUrl=${rootSiteCollectionUrl}`;
//         const rez = await axios.put(fetchUrl, content, { headers: { "WorkPoint365Url": rootSiteCollectionUrl } });
//         return rez.data;
//     } catch (error) {
//         return this.handleError(error, rootSiteCollectionUrl, async () => { return await this.getSearchResults(); });
//     }
// }
// private async handleError(error: any, solutionUrl: string, delegate?: () => Promise<any>) {
//     if (axios.isAxiosError(error)) {
//         const axiosError: AxiosError = error;
//         console.log(axiosError.toJSON());
//         if (axiosError.response) {
//             // The request was made and the server responded with a status code
//             // that falls out of the range of 2xx
//             console.log(axiosError.response.data);
//             console.log(axiosError.response.status);
//             console.log(axiosError.response.headers);
//             Promise.resolve(null);
//         } else if (axiosError.request) {
//             // The request was made but no response was received
//             // `axiosError.request` is an instance of XMLHttpRequest in the browser and an instance of
//             // http.ClientRequest in node.js
//             console.log(axiosError.request);

//             if (window.location.hostname === "localhost") {
//                 let iframe = document.getElementById("wpapp") as HTMLIFrameElement;
//                 if (iframe !== null) {
//                     iframe.remove();
//                 }

//                 iframe = document.createElement('iframe');
//                 iframe.id = "wpapp";
//                 iframe.setAttribute("style", "display: none;");
//                 iframe.src = `${process.env.REACT_APP_PROXY}?SPHostUrl=${solutionUrl}`;
//                 iframe.onload = () => {
//                     if (delegate) {
//                         delegate().then(rez => Promise.resolve(rez));
//                     }
//                 };
//                 document.body.appendChild(iframe);
//             }
//         } else {
//             // Something happened in setting up the request that triggered an Error
//             console.log('Error', axiosError.message);
//             Promise.resolve(null);
//         }
//     } else {
//         console.log(error);
//         return null;
//     }
// }
