import {
  IDropdownOption,
  IChoiceGroupOption,
  mergeStyleSets,
  IconButton,
  Text,
  DefaultButton,
  DayOfWeek
} from "@fluentui/react";
import React, { useEffect, useReducer, useState } from "react";
import {
  Control,
  FieldValues,
  UseFieldArrayReturn,
  UseFormSetValue,
  UseFormTrigger,
  useWatch
} from "react-hook-form";
import { useIntl } from "react-intl";
import styled, { css } from "styled-components";
import {
  ExpressionDescriptorValueType,
  JoinOperator,
  ComparisonOperator,
  ProcessParameterProperty
} from "@workpoint/components/lib/models/ProcessConfiguration";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { defaultExpressionRule } from "../../../utils/processUtils";
import { isObjectEqual } from "../../../utils/commonUtils";
import { ControlledChoiceGroup } from "../form/ControlledChoiceGroup";
import { ControlledDropdown } from "../form/ControlledDropdown";
import { ControlledTextField } from "../form/ControlledTextField";
import { ConditionsSettings } from "./ConditionsPanel";
import { ControlledValuePicker } from "./ControlledValuePicker";
import { ControlledBoolean } from "../form/ControlledBoolean";
import { ControlledDatePicker } from "../form/ControlledDatePicker";
import { ControlledNumber } from "../form/ControlledNumber";
import { getDynamicData } from "../../../utils/swaggerUtils";
import { useApiClient } from "@workpoint/components/lib/clients/ApiProvider";
import { processSelector } from "../../../store/processReducer";

interface ConditionsBasicProps {
  name: string;
  fieldArray: UseFieldArrayReturn<FieldValues, string, "id">;
  control: Control<FieldValues>;
  setValue: UseFormSetValue<any>;
  trigger: UseFormTrigger<any>;
  settings?: ConditionsSettings;
  rules: any;
  definition?: any;
  fieldValues?: any;
  contextExtension?: any;
}

interface ConditionsBasicState {
  contextOptions?: IDropdownOption[];
  comparatorOptions?: IDropdownOption[];
  joinOptions?: IChoiceGroupOption[];
}

export const ConditionsBasic = (props: ConditionsBasicProps) => {
  const intl = useIntl();
  const { fieldArray, contextExtension } = props;
  const [state, setState] = useReducer(
    (state: ConditionsBasicState, newState: ConditionsBasicState) => ({ ...state, ...newState }),
    {
      comparatorOptions: [],
      contextOptions: [],
      joinOptions: []
    }
  );
  const setData = async () => {
    setState({
      contextOptions: getContextDropDownOptions(props.settings),
      comparatorOptions: getOperatorDropdownOptions(),
      joinOptions: getJoinOperatorOptions()
    });
  };
  useEffect(() => {
    setData();
  }, []);

  const clearValue = (name: string, type: ExpressionDescriptorValueType) => {
    if (type) {
      let value;
      switch (type) {
        case ExpressionDescriptorValueType.Boolean:
          value = false;
          break;
        case ExpressionDescriptorValueType.DateTime:
          value = new Date(new Date().setHours(0, 0, 0, 0));
          break;
        default:
          value = "";
      }
      props.setValue(name, value, { shouldDirty: true, shouldValidate: true });
    }
  };

  return (
    <>
      {props.rules.map((rule: any, index: number) => {
        return (
          <div key={rule.id}>
            <RuleBlock>
              <RulesContainer>
                <Rule
                  text={intl.formatMessage({
                    id: "conditions-type1",
                    defaultMessage: "Type"
                  })}
                >
                  <Dropdown
                    key={`${props.name}.${index}.type1`}
                    name={`${props.name}.${index}.type1`}
                    control={props.control}
                    options={state.contextOptions ?? []}
                    onChange={(event, option) =>
                      clearValue(
                        `${props.name}.${index}.value1`,
                        option?.key as ExpressionDescriptorValueType
                      )
                    }
                  />
                </Rule>
                <ConditionalInput
                  name={props.name}
                  control={props.control}
                  setValue={props.setValue}
                  trigger={props.trigger}
                  index={index}
                  field={rule}
                  contextOptions={state.contextOptions}
                  saveName="value1"
                  watchName="type1"
                  label={intl.formatMessage({
                    id: "conditions-value",
                    defaultMessage: "Value"
                  })}
                  definition={props.definition}
                  fieldValues={props.fieldValues}
                  contextExtension={contextExtension}
                />
                {!props.settings?.hideComparison && (
                  <>
                    <Rule
                      text={intl.formatMessage({
                        id: "conditions-operator",
                        defaultMessage: "Operator"
                      })}
                    >
                      <Dropdown
                        key={`${props.name}.${index}.comparisonOperator`}
                        name={`${props.name}.${index}.comparisonOperator`}
                        control={props.control}
                        options={state.comparatorOptions ?? []}
                      />
                    </Rule>
                    {rule.comparisonOperator !== ComparisonOperator.Empty && (
                      <>
                        <Rule
                          text={intl.formatMessage({
                            id: "conditions-target-type",
                            defaultMessage: "Target Type"
                          })}
                        >
                          <Dropdown
                            key={`${props.name}.${index}.type2`}
                            name={`${props.name}.${index}.type2`}
                            control={props.control}
                            options={state.contextOptions ?? []}
                            onChange={(event, option) =>
                              clearValue(
                                `${props.name}.${index}.value2`,
                                option?.key as ExpressionDescriptorValueType
                              )
                            }
                          />
                        </Rule>
                        <ConditionalInput
                          name={props.name}
                          control={props.control}
                          setValue={props.setValue}
                          trigger={props.trigger}
                          index={index}
                          field={rule}
                          contextOptions={state.contextOptions}
                          saveName="value2"
                          watchName="type2"
                          label={intl.formatMessage({
                            id: "conditions-target-value",
                            defaultMessage: "Target Value"
                          })}
                          definition={props.definition}
                          fieldValues={props.fieldValues}
                          contextExtension={contextExtension}
                        />
                      </>
                    )}
                  </>
                )}
              </RulesContainer>
              {(!props.settings?.hideComparison || fieldArray.fields.length < 1) && (
                <DeleteButton
                  onClick={() => {
                    fieldArray.remove(index);
                    props.trigger();
                  }}
                  iconProps={{ iconName: "Delete" }}
                />
              )}
            </RuleBlock>
            {fieldArray.fields.length - 1 > index && (
              <StyledJoinOperator>
                <RuleText variant="medium">
                  {intl.formatMessage({
                    id: "conditions-join-operator",
                    defaultMessage: "Join Operator"
                  })}
                </RuleText>
                <ControlledChoiceGroup
                  key={`${props.name}.${index}.joinOperator`}
                  name={`${props.name}.${index}.joinOperator`}
                  control={props.control}
                  options={state.joinOptions ?? []}
                  styles={joinOperatorStyles}
                />
              </StyledJoinOperator>
            )}
          </div>
        );
      })}
      {(!props.settings?.hideComparison || fieldArray.fields.length < 1) && (
        <AddButton
          onClick={() => {
            fieldArray.append(
              defaultExpressionRule(
                props.settings?.hideComparison ? ComparisonOperator.None : undefined
              )
            );
            props.trigger(props.name);
          }}
        >
          {intl.formatMessage({ id: "conditions-add-more", defaultMessage: "Add More" })}
        </AddButton>
      )}
    </>
  );
};

interface ConditionalInputProps {
  name: string;
  watchName: string;
  saveName: string;
  control: Control<FieldValues>;
  setValue: UseFormSetValue<any>;
  trigger: UseFormTrigger<any>;
  index: number;
  contextOptions?: IDropdownOption[];
  field: any;
  label: string;
  definition: any;
  fieldValues?: any;
  contextExtension?: any;
}

const ConditionalInput = (props: ConditionalInputProps) => {
  const dispatch = useAppDispatch();
  const { document } = useAppSelector(
    processSelector,
    (a, b) =>
      isObjectEqual(a.step?.configuration, b.step?.configuration) &&
      isObjectEqual(a.process?.configuration, b.process?.configuration)
  );
  const { apiClient } = useApiClient();
  const watchValue = useWatch({
    name: `${props.name}.${props.index}.${props.watchName}`,
    control: props.control
  });

  const [fieldOptions, setFieldOptions] = useState<{ key: string; text: string }[]>();

  useEffect(() => {
    if (
      watchValue === "Field" &&
      props.fieldValues?.[props.definition?.["x-workpoint-control"]?.list]
    ) {
      let options: { key: string; text: string }[] = [];
      getDynamicData(
        apiClient,
        dispatch,
        document!,
        {
          operationId: "List_GetFieldSchema",
          parameters: {
            WorkPoint365Url: {
              parameter: "WorkPoint365Url"
            },
            listId: {
              parameter: "listId"
            },
            contentType: {
              parameter: "contentType"
            }
          },
          "x-ms-dynamic-schema": {
            operationId: "List_GetFieldSchema",
            parameters: {
              WorkPoint365Url: {
                parameter: "WorkPoint365Url"
              },
              listId: {
                parameter: "listId"
              },
              contentType: {
                parameter: "contentType"
              },
              "value-path": "jsonItems"
            }
          }
        } as any,
        {
          ...props.fieldValues,
          listId: props.fieldValues[props.definition["x-workpoint-control"].list]
        }
      ).then((result) => {
        for (const [key, value] of Object.entries(result.data.jsonItems.properties)) {
          options.push({ key, text: (value as any).title });
        }
        setFieldOptions(options);
      });
    }
  }, [watchValue]);

  if (watchValue === "SearchQuery") {
    return null;
  }

  if (watchValue === ExpressionDescriptorValueType.Context) {
    return (
      <Rule text={props.label}>
        <ValuePicker
          name={`${props.name}.${props.index}.${props.saveName}`}
          control={props.control}
          setValue={props.setValue}
          trigger={props.trigger}
          propertyType={ProcessParameterProperty.Value}
          defaultValue={undefined}
          definition={
            {
              "x-workpoint-field": {
                type: "Context"
              }
            } as any
          }
          required={false}
          hideConditions
          dynamic
          contextExtension={props.contextExtension}
        />
      </Rule>
    );
  } else if (watchValue === ExpressionDescriptorValueType.User) {
    return (
      <Rule text={props.label}>
        <ValuePicker
          name={`${props.name}.${props.index}.${props.saveName}`}
          control={props.control}
          setValue={props.setValue}
          trigger={props.trigger}
          propertyType={ProcessParameterProperty.Value}
          defaultValue={undefined}
          definition={{
            name: "",
            in: "",
            type: "",
            "x-workpoint-field": {
              type: "User"
            }
          }}
          required={false}
          hideConditions
          dynamic
        />
      </Rule>
    );
  } else if (watchValue === ExpressionDescriptorValueType.Boolean) {
    return (
      <Rule text={props.label}>
        <BooleanValue
          name={`${props.name}.${props.index}.${props.saveName}`}
          control={props.control}
          defaultValue={undefined}
          offText={"No"}
          onText={"Yes"}
        />
      </Rule>
    );
  } else if (watchValue === ExpressionDescriptorValueType.DateTime) {
    return (
      <Rule text={props.label}>
        <DatePicker
          control={props.control}
          name={`${props.name}.${props.index}.${props.saveName}`}
          firstDayOfWeek={DayOfWeek.Monday}
          placeholder="Select a date..."
          ariaLabel="Select a date"
        />
      </Rule>
    );
  } else if (watchValue === ExpressionDescriptorValueType.Number) {
    return (
      <Rule text={props.label}>
        <NumberField
          control={props.control}
          name={`${props.name}.${props.index}.${props.saveName}`}
        />
      </Rule>
    );
  } else if (watchValue === "Field") {
    return (
      <Rule text={props.label}>
        <ValuePicker
          name={`${props.name}.${props.index}.${props.saveName}`}
          control={props.control}
          setValue={props.setValue}
          trigger={props.trigger}
          propertyType={ProcessParameterProperty.Value}
          defaultValue={undefined}
          required={false}
          hideConditions
          dynamic
          definition={{} as any}
          options={fieldOptions}
          hideContextBrowser
        />
      </Rule>
    );
  }

  return (
    <Rule text={props.label}>
      <TextField
        key={`${props.name}.${props.index}.${props.saveName}`}
        name={`${props.name}.${props.index}.${props.saveName}`}
        control={props.control}
        placeholder={"Enter a value"}
      />
    </Rule>
  );
};

const getContextDropDownOptions = (settings?: ConditionsSettings) => {
  const contextDropdownOptions: IDropdownOption[] = [];

  for (const valueType in ExpressionDescriptorValueType) {
    contextDropdownOptions.push({
      key: valueType,
      text:
        valueType === ExpressionDescriptorValueType.User
          ? "User or Group"
          : valueType === ExpressionDescriptorValueType.Boolean
          ? "Yes/No"
          : valueType === ExpressionDescriptorValueType.DateTime
          ? "Date"
          : valueType
    });
  }
  if (settings?.addSearchQuery) {
    contextDropdownOptions.push({
      key: "SearchQuery",
      text: "Search Query"
    });
  }
  if (settings?.isFilter) {
    contextDropdownOptions.push({
      key: "Field",
      text: "Field"
    });
  }

  return contextDropdownOptions;
};

const getJoinOperatorOptions = () => {
  const joinOptions: IChoiceGroupOption[] = [];

  for (const operator in JoinOperator) {
    joinOptions.push({
      key: (JoinOperator as any)[operator],
      text: operator,
      styles: joinOperatorOptionsStyles
    });
  }

  return joinOptions;
};

const getOperatorDropdownOptions = () => {
  const operatorDropdownOptions: IDropdownOption[] = [];

  Object.keys(ComparisonOperator).forEach((value) => {
    if (value !== "None") {
      operatorDropdownOptions.push({
        key: ComparisonOperator[value as keyof typeof ComparisonOperator],
        text: value.replace(/([A-Z])/g, " $1").trim()
      });
    }
  });

  return operatorDropdownOptions;
};

const RuleContent = ({ className, children, text }: any) => (
  <div className={className}>
    <Text variant={"medium"} style={{ fontWeight: 600 }}>
      {text}
    </Text>
    {children}
  </div>
);

const AddButton = styled(DefaultButton)`
  margin: 8px 24px;
  float: right;
`;

const RulesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
`;

const RuleBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px 32px;
  padding: 16px;
  border: 1px solid #d8d8d8;
`;

const Rule = styled(RuleContent)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;
`;

const StyledJoinOperator = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
`;

const DeleteButton = styled(IconButton)`
  margin-left: auto;
`;

const inputFieldStyles = css`
  margin-right: 40px;
  width: 70%;
  min-width: 70%;
`;

const TextField = styled(ControlledTextField)`
  ${inputFieldStyles}
`;

const ObjectBrowserContainer = styled.span`
  ${inputFieldStyles}
`;

const ValuePicker = styled(ControlledValuePicker)`
  ${inputFieldStyles}
`;

const BooleanValue = styled(ControlledBoolean)`
  ${inputFieldStyles}
`;

const DatePicker = styled(ControlledDatePicker)`
  ${inputFieldStyles}
`;

const NumberField = styled(ControlledNumber)`
  ${inputFieldStyles}
`;

const Dropdown = styled(ControlledDropdown)`
  ${inputFieldStyles}
`;

const RuleText = styled(Text)`
  font-weight: 600;
`;

const joinOperatorStyles = mergeStyleSets({
  root: { display: "flex", justifyContent: "center", alignItems: "center" },
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});

const joinOperatorOptionsStyles = mergeStyleSets({
  choiceFieldWrapper: { margin: "0px 15px" },
  root: { margin: "0px" }
});
