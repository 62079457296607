import React, { useEffect, useState } from "react";
import { DefaultButton, Icon, IDropdownOption, Label, ProgressIndicator } from "@fluentui/react";
import styled from "styled-components";
import { getDynamicData } from "../../../utils/swaggerUtils";
import {
  ParameterPropertyControl,
  ParameterPropertyControlParams
} from "./ParameterPropertyControl";
import { FormControlType, DynamicData, DynamicDataType } from "../parametersForm/ParametersForm";
import {
  ExpressionDescriptor,
  DataSource,
  ProcessParameterProperty,
  ProcessStepConfiguration,
  ProcessStepType,
  ConfigurationParameterDataType,
  ConfigurationParameterType,
  ProcessStepParameterConfiguration,
  OrderDirection,
  ProcessTrigger,
  ParameterFormat,
  UserFieldAllowSelectionOf
} from "@workpoint/components/lib/models/ProcessConfiguration";
import theme, { WorkPointTheme } from "@workpoint/components/lib/constants";
import { AccordionControl, AccordionListContainer } from "./AccordionControl";
import {
  Control,
  FieldValues,
  UseFormSetValue,
  UseFormTrigger,
  UseFormUnregister,
  UseFormRegister,
  useWatch
} from "react-hook-form";
import { OpenAPIV2 } from "openapi-types";
import { useParams } from "react-router";
import { cloneDeep, get } from "lodash";
import { useAppDispatch } from "../../../store/hooks";
import { setDynamicData } from "../../../store/globalReducer";
import {
  defaultExpression,
  defaultDataSource,
  getDefaultValueByParameterProperty,
  isConditionsEmpty,
  isDataSourceEmpty,
  isExpressionEmpty
} from "../../../utils/processUtils";
import { v4 as uuid } from "uuid";
import { DynamicColumnFields } from "./DynamicColumnFields";
import { DynamicTabs } from "./DynamicTabs";
import { AdminConfiguration } from "@workpoint/components/lib/models/AdminConfiguration";
import { useApiClient } from "@workpoint/components/lib/clients/ApiProvider";
import { FieldErrorMessage } from "@workpoint/components/lib/components/form/inputs/FieldErrorMessage";

export interface ParameterControlProps {
  control: Control<FieldValues>;
  setValue: UseFormSetValue<any>;
  trigger: UseFormTrigger<any>;
  register: UseFormRegister<any>;
  unregister: UseFormUnregister<any>;
  definition: OpenAPIV2.Parameter;
  fieldValues: any;
  required: boolean;
  document?: OpenAPIV2.Document;
  stepConfiguration?: ProcessStepConfiguration | ProcessTrigger | AdminConfiguration;
  getValues: (controlName: string) => any;
  dynamic?: boolean;
  name?: string;
  skipPropertyTypeInName?: boolean;
  getCustomizedControlParams?: (
    definition: OpenAPIV2.Parameter,
    name: string,
    propertyType: ProcessParameterProperty
  ) => Partial<ParameterPropertyControlParams>;
  skipValueInName?: boolean;
  rows?: number;
  hideConditions?: boolean;
  customControls?: { [key: string]: (props: ParameterPropertyControlParams) => JSX.Element };
}

export const ParameterControl = (props: ParameterControlProps) => {
  const {
    definition,
    fieldValues,
    required,
    control,
    stepConfiguration,
    setValue,
    trigger,
    register,
    unregister,
    getValues
  } = props;
  const { parameterName } = useParams() as any;
  const dispatch = useAppDispatch();
  const { apiClient } = useApiClient();
  const [controlsData, setControlsData] = useState<DynamicData>();
  const [advancedToggled, setAdvancedToggled] = useState<boolean>(true);
  const [dynamicToggled, setDynamicToggled] = useState<boolean>(true);

  const watchAllFields: any = useWatch({ control: props.control });

  let stepId = stepConfiguration?.id!;

  const watchParameter: any = useWatch({ control: props.control, name: definition.name });

  useEffect(() => {
    (async () => {
      if (definition["x-ms-dynamic-values"]) {
        // definition["x-ms-dynamic-list"]
        setControlsData({
          name: definition.name,
          data: undefined,
          parameters: [],
          type: DynamicDataType.Values,
          loading: true
        });
        const result = await getDynamicData(
          apiClient,
          dispatch,
          props.document!,
          definition["x-ms-dynamic-values"],
          fieldValues
        );
        dispatch(
          setDynamicData({
            stepId,
            name: definition.name,
            data: result.data
          })
        );
        setControlsData({
          ...result,
          name: definition.name,
          type: DynamicDataType.Values,
          loading: false
        });
      } else if (
        (definition.schema && definition.schema["x-ms-dynamic-schema"]) ||
        definition["x-ms-dynamic-schema"]
      ) {
        // get fields for item, etc...
        setControlsData({
          name: definition.name,
          type: DynamicDataType.Schema,
          data: undefined,
          parameters: [],
          loading: true
        });
        const result = await getDynamicData(
          apiClient,
          dispatch,
          props.document!,
          definition["x-ms-dynamic-schema"] ?? definition.schema["x-ms-dynamic-schema"],
          fieldValues
        );
        const data =
          result.data === undefined && controlsData?.data
            ? controlsData.data
            : result.data?.jsonItems?.properties;
        dispatch(
          setDynamicData({
            stepId,
            name: definition.name,
            data: { ...data }
          })
        );

        let data2: any = cloneDeep(data);
        if (data2) {
          for (const fieldName of Object.keys(data2)) {
            if (data[fieldName]["x-ms-dynamic-values"]) {
              const result2 = await getDynamicData(
                apiClient,
                dispatch,
                props.document!,
                data2[fieldName]["x-ms-dynamic-values"],
                fieldValues
              );
              dispatch(
                setDynamicData({
                  stepId,
                  name: fieldName,
                  data: result2.data
                })
              );
              data2[fieldName] = { ...data2[fieldName], data: result2.data };
            }
          }
        }

        if (
          data2 &&
          !controlsData?.data &&
          watchParameter &&
          Object.keys(watchParameter).length > 0
        ) {
          Object.keys(watchParameter).forEach((key) => {
            if (watchParameter[key]?.type && !data2[key]) {
              data2[key] = {
                name: key,
                title: key,
                type: "string",
                "x-ms-visibility": "important",
                "x-workpoint-deprecated": true
              };
            }
          });
        }

        if (
          controlsData?.data &&
          Object.keys(controlsData.data).length > 0 &&
          watchParameter &&
          Object.keys(watchParameter).length > 0
        ) {
          const keys = Object.keys(controlsData.data);
          Object.keys(watchParameter)
            .filter((fieldName) => keys.indexOf(fieldName) < 0)
            .forEach((fieldName) => {
              unregister(`${definition.name}.${fieldName}`, { keepValue: false });
            });
        }

        setControlsData({
          data: data2,
          parameters: result.parameters,
          name: definition.name,
          type: DynamicDataType.Schema,
          loading: false
        });
      } else if (definition["x-workpoint-control"]?.type === FormControlType.DynamicSchema) {
        setControlsData({
          name: definition.name,
          type: DynamicDataType.Schema,
          data: undefined,
          parameters: [],
          loading: false
        });
      }
    })();
  }, [stepConfiguration, definition, fieldValues]);

  const _getDefaultValue = (name: string, type: ProcessParameterProperty) => {
    let expression: ExpressionDescriptor | DataSource | undefined;
    if (watchAllFields) {
      expression = get(
        watchAllFields,
        `${name}` + (!props.skipPropertyTypeInName ? `.${type}` : "")
      );
      // if (watchAllFields[name]) {
      //   expression = watchAllFields[name][type];
      // }
    }

    return expression;
  };

  // let valueControlType = FormControlType.Text;
  // if (definition["x-workpoint-control"]?.type) {
  //   valueControlType = definition["x-workpoint-control"]?.type;
  // } else {
  //   switch (definition.type) {
  //     case "number":
  //       valueControlType = FormControlType.Number;
  //       break;
  //     case "integer":
  //       valueControlType = FormControlType.Number;
  //       break;
  //     case "boolean":
  //       valueControlType = FormControlType.Boolean;
  //       break;
  //     case "array":
  //       break;
  //     case "object":
  //       break;
  //   }
  // }

  const getFormPropertyType = (definition: OpenAPIV2.Parameter) => {
    if (definition?.["x-workpoint-control"]?.propertyType) {
      return definition["x-workpoint-control"].propertyType;
    } else if (definition?.["x-workpoint-control"]?.type === FormControlType.DataSource) {
      return ProcessParameterProperty.DataSource;
    }

    return ProcessParameterProperty.Value;
  };

  const getFormControlType = (definition: OpenAPIV2.Parameter) => {
    if (
      definition?.["x-workpoint-control"]?.type === FormControlType.RichText ||
      definition?.["x-workpoint-control"]?.type === FormControlType.PlainText ||
      definition?.["x-workpoint-control"]?.type === FormControlType.Boolean ||
      definition?.["x-workpoint-control"]?.type === FormControlType.QueryFilter ||
      definition?.["x-workpoint-control"]?.type === FormControlType.DataSource ||
      definition?.["x-workpoint-control"]?.type === FormControlType.Dropdown ||
      definition?.["x-workpoint-control"]?.type === FormControlType.Conditions
    ) {
      return definition["x-workpoint-control"].type;
    }

    return FormControlType.ValuePicker;
  };

  const getCustomizedControlParams = (
    definition: OpenAPIV2.Parameter,
    name: string,
    propertyType: ProcessParameterProperty
  ) => {
    if (props.getCustomizedControlParams) {
      return props.getCustomizedControlParams(definition, name, propertyType);
    }

    return undefined as any;
  };

  const propertyType = getFormPropertyType(definition);
  const controlType = getFormControlType(definition);
  const customizedControlParams = getCustomizedControlParams(
    definition,
    props.name ?? definition.name,
    propertyType
  );

  let options: IDropdownOption[] | undefined = undefined;
  if (definition.enum) {
    const enumStrings: string[] = definition["x-enum-names"] ?? definition.enum;
    options = [];
    for (let i = 0; i < enumStrings.length; i++) {
      options.push({
        key:
          (customizedControlParams?.controlType &&
            customizedControlParams.controlType !== FormControlType.ValuePicker) ||
          propertyType === ProcessParameterProperty.DataType
            ? definition.enum[i]
            : `'${definition.enum[i]}'`,
        text: enumStrings[i]
      });
    }
    // options.splice(0, 0, {
    //   key: "",
    //   text: ""
    // });
  }

  if (definition["x-ms-dynamic-values"]) {
    //options = [];
    if (controlsData && controlsData.data && Array.isArray(controlsData.data)) {
      options = controlsData.data.map((dv: any) => {
        return {
          key:
            customizedControlParams?.controlType &&
            customizedControlParams.controlType !== FormControlType.ValuePicker
              ? dv[definition["x-ms-dynamic-values"]["value-path"]]
              : `'${dv[definition["x-ms-dynamic-values"]["value-path"]]}'`,
          text: dv[definition["x-ms-dynamic-values"]["value-title"]]
        };
      });
      // options.splice(0, 0, {
      //   key: "",
      //   text: ""
      // });
    } else {
      options = [];
    }

    // if (definition["x-ms-dynamic-list"]) {
    // }
  }

  const getFields = () => {
    let fields: { [key: string]: ProcessStepParameterConfiguration } =
      cloneDeep(watchParameter) ?? {};

    let fieldValues = Object.values(fields) as any[];
    fieldValues.sort((a: any, b: any) => a.order - b.order);

    // Here we check for duplicate order and correct it if there is duplicate
    for (let i = 1; i < fieldValues.length; i++) {
      if (fieldValues[i].order <= fieldValues[i - 1].order) {
        const correctOrder = fieldValues[i - 1].order + 1;
        fieldValues[i].order = correctOrder;
      }
    }

    return fields;
  };

  const onDelete = (fieldName: string): void => {
    const fields: { [key: string]: ProcessStepParameterConfiguration } = getFields();

    const currentFieldOrder = fields[fieldName].order;
    delete fields[fieldName];
    if (currentFieldOrder) {
      Object.entries(fields).forEach(([key, field]) => {
        if (field?.order !== undefined && field.order > currentFieldOrder)
          field.order = field.order - 1;
      });
    }
    unregister(`${definition.name}.${fieldName}`, { keepValue: false });
    setValue(definition.name, fields, { shouldValidate: false });
    trigger(definition.name);
  };

  const onOrderChange = (fieldName: string, direction: OrderDirection): void => {
    let fields: { [key: string]: ProcessStepParameterConfiguration } = getFields();
    if (controlsData?.data) {
      Object.entries(controlsData?.data).forEach(([key, field]: [string, any], index) => {
        if (!fields?.[key]) {
          const data: ProcessStepParameterConfiguration = {
            name: field?.name ?? key,
            type: ConfigurationParameterType.Dynamic,
            parent: definition.name,
            order: (field?.order as number) ?? index
          };

          fields[key] = data;
        } else if (!fields[key]?.order || typeof fields[key].order === "string") {
          fields[key].order = (fields[key]?.order as number) ?? index;
        }

        if (fields[key]?.order && typeof fields[key]?.order !== "number") {
          //@ts-ignore
          fields[key].order = +fields[key]?.order;
        }
      });
    }

    if (
      Object.values(fields).findIndex((f) => f.order && f.order >= Object.keys(fields).length) > -1
    ) {
      const keys = Object.keys(fields).sort((a, b) => fields[a].order! - fields[b].order!);

      keys.forEach((key, index) => {
        fields[key].order = index;
      });
    }

    const currentFieldOrder = fields?.[fieldName]?.order ?? 0;
    let newCurrentFieldOrder: number | undefined;
    let fieldsEntries = Object.entries(fields);
    if (direction === OrderDirection.Up) {
      if (currentFieldOrder > 0) {
        newCurrentFieldOrder = currentFieldOrder - 1;
      }
    } else if (direction === OrderDirection.Down) {
      if (fieldsEntries.length > currentFieldOrder + 1) {
        newCurrentFieldOrder = currentFieldOrder + 1;
      }
    }

    if (newCurrentFieldOrder !== undefined) {
      const entry = fieldsEntries.find(
        ([key, field]) =>
          (field as any)?.order !== undefined && (field as any).order === newCurrentFieldOrder
      );
      if (entry) {
        (entry[1] as any).order = currentFieldOrder;
      }
      fields[fieldName].order = newCurrentFieldOrder;
    }

    const sortedFields = fieldsEntries
      .sort(([x, y]: [string, any], [a, b]: [string, any]) => y.order - b.order)
      .reduce((r, [e, v]) => ({ ...r, [e]: v }), {});

    setValue(definition.name, sortedFields, { shouldValidate: false });

    trigger(definition.name);
  };

  const addField = (dataType: string): void => {
    const order = watchParameter ? Object.keys(watchParameter).length : 0;
    let name = uuid({}).replaceAll("-", "");
    name = name.replace(name[0], String.fromCharCode(Math.random() * 6 + 97)); // Ensures it starts with a letter

    const field = {
      name,
      type: ConfigurationParameterType.Custom,
      dataType,
      title: { defaultText: "My field", id: `${props.stepConfiguration?.id}-${name}-title` },
      parent: definition.name,
      order,
      description: {
        id: `${props.stepConfiguration?.id}-${name}-description`,
        defaultText: ""
      },
      value: defaultExpression(),
      readonly: defaultExpression(),
      required: defaultExpression(),
      valid: defaultExpression(),
      hidden: defaultExpression(),
      dataSource: defaultDataSource(
        `${props.stepConfiguration?.id}-${name}-${ProcessParameterProperty.DataSource}-title`,
        `${props.stepConfiguration?.id}-${name}-${ProcessParameterProperty.DataSource}-errorMessage`
      )
    } as ProcessStepParameterConfiguration;

    register(`${definition.name}.${name}.type`);
    register(`${definition.name}.${name}.parent`);
    register(`${definition.name}.${name}.order`);
    setValue(`${definition.name}.${name}`, field, { shouldValidate: false });
    trigger(`${definition.name}.${name}`);
  };

  const checkModified = (name: string) => {
    for (const m of Object.values(ProcessParameterProperty)) {
      const val = _getDefaultValue(name, m);
      const modified =
        m === ProcessParameterProperty.DataSource
          ? !isDataSourceEmpty(val as DataSource)
          : !isExpressionEmpty(val as ExpressionDescriptor, getDefaultValueByParameterProperty(m));
      if (modified) {
        return true;
      }
    }
    return false;
  };

  const isDynamicSchema =
    (definition.schema && definition.schema["x-ms-dynamic-schema"]) ||
    definition["x-ms-dynamic-schema"] ||
    definition["x-workpoint-control"]?.type === FormControlType.DynamicSchema;

  if (isDynamicSchema) {
    // get fields for item, etc...
    const dynamicControls: JSX.Element[] = [];
    const fields: { paramName: string; definition: OpenAPIV2.Parameter }[] = [];
    if (controlsData?.data) {
      const propertyNames = Object.getOwnPropertyNames(controlsData.data);
      propertyNames
        .sort((a, b) => {
          if (watchParameter?.[a] && watchParameter?.[b]) {
            return watchParameter[a]?.order - watchParameter[b]?.order;
          } else if (controlsData?.data[a]?.order && controlsData?.data[b]?.order) {
            return controlsData.data[a]?.order ?? 0 - controlsData.data[b]?.order ?? 0;
          }
          return 0 - 0;
        })
        .forEach((paramName: string) => {
          if (
            !controlsData?.data[paramName]?.["x-workpoint-deprecated"] ||
            watchParameter?.[paramName]
          ) {
            fields.push({
              paramName,
              definition: controlsData.data[paramName]
            });
          }
        });
    }
    if (watchParameter) {
      Object.entries<ProcessStepParameterConfiguration>(watchParameter)
        .filter(
          (entry) =>
            (entry[1]?.type === ConfigurationParameterType.Custom ||
              (controlsData?.data === undefined &&
                entry[1]?.type === ConfigurationParameterType.Dynamic)) &&
            entry[1]?.parent === definition.name
        )
        .sort((entry1, entry2) => (entry1[1].order ?? 0) - (entry2[1].order ?? 0))
        .forEach((entry) => {
          const paramName = entry[0];
          const field = entry[1];
          const dynamicParamDefinition: any = {
            ...field,
            name: paramName
          };
          if (fields.some((x) => x.paramName === paramName)) {
            return;
          }
          if (typeof dynamicParamDefinition.title === "object") {
            dynamicParamDefinition.title =
              entry[1]?.type === ConfigurationParameterType.Dynamic
                ? entry[0]
                : dynamicParamDefinition.title.defaultText ?? "";
          }
          if (
            typeof dynamicParamDefinition.description === "object" &&
            (dynamicParamDefinition as any).description.defaultText
          ) {
            dynamicParamDefinition.description = (
              dynamicParamDefinition as any
            ).description.defaultText;
          }

          fields.push({ paramName, definition: dynamicParamDefinition });
        });
    }

    const mappingProperties =
      definition?.schema?.["x-workpoint-mapping"] &&
      typeof definition.schema["x-workpoint-mapping"] === "object"
        ? definition.schema["x-workpoint-mapping"]?.["properties"]
        : definition?.["x-ms-dynamic-schema"] &&
          typeof definition["x-workpoint-mapping"] === "object"
        ? definition["x-workpoint-mapping"]["properties"]
        : undefined;
    const contextExtension = mappingProperties ? { Element: mappingProperties } : undefined;

    fields.forEach((field) => {
      const dynamicParamDefinition = field.definition;
      const paramName = field.paramName;
      const name = `${definition.name}.${paramName}`;
      let dynamicOptions: IDropdownOption[] | undefined;
      if (dynamicParamDefinition.enum) {
        dynamicOptions = dynamicParamDefinition.enum.map((ev: any) => {
          return { key: `'${ev}'`, text: ev };
        });
      } else if (dynamicParamDefinition.data) {
        dynamicOptions = dynamicParamDefinition.data.map((dv: any) => {
          return {
            key: `'${
              dynamicParamDefinition["x-ms-dynamic-values"]?.["value-path"]
                ? dv[dynamicParamDefinition["x-ms-dynamic-values"]["value-path"]]
                : dv.valuePath
            }'`,
            text: dynamicParamDefinition["x-ms-dynamic-values"]?.["value-title"]
              ? dv[dynamicParamDefinition["x-ms-dynamic-values"]["value-title"]]
              : dv.valueTitle
          };
        });
      }
      //make enum from dynamic data

      const isUserStep =
        props.stepConfiguration !== undefined &&
        "type" in props.stepConfiguration &&
        props.stepConfiguration?.type === ProcessStepType.User;

      dynamicControls.push(
        <AccordionControl
          definition={dynamicParamDefinition}
          key={name}
          expanded={parameterName === paramName}
          modified={checkModified(name)}
          showDelete={
            definition["x-workpoint-control"]?.type === FormControlType.DynamicSchema ||
            dynamicParamDefinition?.type === ConfigurationParameterType.Dynamic ||
            dynamicParamDefinition["x-workpoint-deprecated"]
          }
          showSorting={isDynamicSchema}
          onDelete={onDelete}
          onOrderChange={(name, direction) => {
            onOrderChange(paramName, direction);
          }}
        >
          {definition["x-workpoint-control"]?.type === FormControlType.DynamicSchema && (
            <>
              <ParameterPropertyControl
                control={control}
                setValue={props.setValue}
                trigger={props.trigger}
                name={name}
                label="Name"
                description="The unique name for your field"
                defaultValue={_getDefaultValue(name, ProcessParameterProperty.Name)}
                propertyType={ProcessParameterProperty.Name}
                controlType={FormControlType.Text}
                required={true}
                definition={dynamicParamDefinition}
                fieldValues={fieldValues}
                dynamic
                hideConditions
                contextExtension={contextExtension}
                {...getCustomizedControlParams(
                  dynamicParamDefinition,
                  name,
                  ProcessParameterProperty.Name
                )}
              />

              <ParameterPropertyControl
                control={control}
                setValue={props.setValue}
                trigger={props.trigger}
                name={name}
                label="Type"
                description="The type of your field"
                defaultValue={_getDefaultValue(name, ProcessParameterProperty.DataType)}
                propertyType={ProcessParameterProperty.DataType}
                controlType={FormControlType.Dropdown}
                required={true}
                definition={dynamicParamDefinition}
                fieldValues={fieldValues}
                dynamic
                options={Object.keys(ConfigurationParameterDataType).map((pspdt) => ({
                  key: pspdt,
                  text: pspdt
                }))}
                hideConditions
                contextExtension={contextExtension}
                {...getCustomizedControlParams(
                  dynamicParamDefinition,
                  name,
                  ProcessParameterProperty.DataType
                )}
              />
              {(dynamicParamDefinition.dataType === ConfigurationParameterDataType.Choice ||
                dynamicParamDefinition.dataType === ConfigurationParameterDataType.MultiChoice) && (
                <>
                  <ParameterPropertyControl
                    control={control}
                    setValue={props.setValue}
                    trigger={props.trigger}
                    name={name}
                    label={"Choices"}
                    description="The dropdown options for your field"
                    defaultValue={_getDefaultValue(
                      name,
                      (ProcessParameterProperty.DataSource + ".choices") as any
                    )}
                    propertyType={(ProcessParameterProperty.DataSource + ".choices") as any}
                    controlType={FormControlType.ValuePicker}
                    required={false}
                    options={[]}
                    definition={{ ...dynamicParamDefinition, type: "array" }}
                    fieldValues={fieldValues}
                    dynamic
                    contextExtension={contextExtension}
                    {...getCustomizedControlParams(
                      { ...dynamicParamDefinition, type: "array" },
                      name,
                      (ProcessParameterProperty.DataSource + ".choices") as any
                    )}
                    disabled={
                      !isConditionsEmpty(
                        _getDefaultValue(
                          name,
                          (ProcessParameterProperty.DataSource + ".choices") as any
                        ) as ExpressionDescriptor
                      )
                    }
                  />
                  {/* <ParameterPropertyControl
                    control={control}
                    setValue={props.setValue}
                    trigger={props.trigger}
                    name={name}
                    label={"Multi"}
                    defaultValue={undefined}
                    propertyType={(ProcessParameterProperty.DataSource + ".multi") as any}
                    controlType={FormControlType.Boolean}
                    required={false}
                    onText={"Multiple values enabled"}
                    offText={"Multiple values disabled"}
                    definition={dynamicParamDefinition}
                    dynamic
                    disabled={
                      !isConditionsEmpty(
                        _getDefaultValue(
                          name,
                          ProcessParameterProperty.Hidden
                        ) as ExpressionDescriptor
                      )
                    }
                    hideConditions
                    {...getCustomizedControlParams(definition)}
                  /> */}
                </>
              )}
              {(dynamicParamDefinition.dataType === ConfigurationParameterDataType.Note ||
                dynamicParamDefinition.dataType === ConfigurationParameterDataType.DateTime) && (
                <>
                  <ParameterPropertyControl
                    control={control}
                    setValue={props.setValue}
                    trigger={props.trigger}
                    name={name}
                    label={"Field options"}
                    description="The dropdown options for your field"
                    defaultValue={undefined}
                    propertyType={ProcessParameterProperty.Format}
                    controlType={FormControlType.Dropdown}
                    required={false}
                    options={Object.values(ParameterFormat)
                      .filter(
                        (v) =>
                          (dynamicParamDefinition.dataType ===
                            ConfigurationParameterDataType.Note &&
                            (v === ParameterFormat.Compatible || v === ParameterFormat.FullHtml)) ||
                          (dynamicParamDefinition.dataType ===
                            ConfigurationParameterDataType.DateTime &&
                            v === ParameterFormat.DateTime)
                      )
                      .map((v) => {
                        return { key: v, text: v };
                      })}
                    definition={dynamicParamDefinition}
                    dynamic
                    disabled={
                      !isConditionsEmpty(
                        _getDefaultValue(
                          name,
                          ProcessParameterProperty.Value
                        ) as ExpressionDescriptor
                      )
                    }
                    hideConditions
                    contextExtension={contextExtension}
                    {...getCustomizedControlParams(
                      dynamicParamDefinition,
                      name,
                      ProcessParameterProperty.Format
                    )}
                  />
                </>
              )}
              {dynamicParamDefinition.dataType === ConfigurationParameterDataType.Note && (
                <ParameterPropertyControl
                  control={control}
                  setValue={props.setValue}
                  trigger={props.trigger}
                  name={name}
                  label={"Number of lines for editing"}
                  description="The default number of lines to display in the editing field"
                  defaultValue={undefined}
                  propertyType={ProcessParameterProperty.Rows}
                  controlType={FormControlType.Number}
                  required={false}
                  definition={dynamicParamDefinition}
                  dynamic
                  disabled={
                    !isConditionsEmpty(
                      _getDefaultValue(name, ProcessParameterProperty.Value) as ExpressionDescriptor
                    )
                  }
                  hideConditions
                  contextExtension={contextExtension}
                  {...getCustomizedControlParams(
                    dynamicParamDefinition,
                    name,
                    ProcessParameterProperty.Rows
                  )}
                />
              )}
            </>
          )}
          {(definition["x-workpoint-control"]?.type === FormControlType.DynamicSchema ||
            (isUserStep && !dynamicParamDefinition["x-workpoint-properties"])) && (
            <>
              <ParameterPropertyControl
                control={control}
                setValue={props.setValue}
                trigger={props.trigger}
                name={name}
                label="Title"
                description="The title that will be used to display your field"
                defaultValue={_getDefaultValue(name, ProcessParameterProperty.Title)}
                propertyType={ProcessParameterProperty.Title}
                controlType={FormControlType.PlainText}
                rows={1}
                required={false}
                definition={dynamicParamDefinition}
                fieldValues={fieldValues}
                dynamic
                enableLocalization
                contextExtension={contextExtension}
                {...getCustomizedControlParams(
                  dynamicParamDefinition,
                  name,
                  ProcessParameterProperty.Title
                )}
                stepConfiguration={props.stepConfiguration}
              />
              <ParameterPropertyControl
                control={control}
                setValue={props.setValue}
                trigger={props.trigger}
                name={name}
                label="Description"
                description="The description of your field"
                defaultValue={_getDefaultValue(name, ProcessParameterProperty.Description)}
                propertyType={ProcessParameterProperty.Description}
                controlType={FormControlType.RichText}
                rows={2}
                required={false}
                definition={dynamicParamDefinition}
                fieldValues={fieldValues}
                dynamic
                enableLocalization
                contextExtension={contextExtension}
                {...getCustomizedControlParams(
                  dynamicParamDefinition,
                  name,
                  ProcessParameterProperty.Description
                )}
                stepConfiguration={props.stepConfiguration}
              />
            </>
          )}
          {((isUserStep && !dynamicParamDefinition["x-workpoint-properties"]) ||
            (dynamicParamDefinition["x-workpoint-properties"] as string[])?.includes(
              ProcessParameterProperty.DataSource
            )) && (
            <ParameterPropertyControl
              control={control}
              setValue={props.setValue}
              trigger={props.trigger}
              name={name}
              label={"Data source"}
              description=" "
              defaultValue={_getDefaultValue(name, ProcessParameterProperty.DataSource)}
              propertyType={ProcessParameterProperty.DataSource}
              required={false}
              definition={dynamicParamDefinition}
              fieldValues={fieldValues}
              dynamic
              contextExtension={contextExtension}
              disabled={
                !isDataSourceEmpty(
                  _getDefaultValue(name, ProcessParameterProperty.DataSource) as DataSource
                )
              }
              {...getCustomizedControlParams(
                dynamicParamDefinition,
                name,
                ProcessParameterProperty.DataSource
              )}
            />
          )}
          <ParameterPropertyControl
            control={control}
            setValue={props.setValue}
            trigger={props.trigger}
            name={name}
            label={"Value"}
            description="The default value"
            defaultValue={_getDefaultValue(name, ProcessParameterProperty.Value)}
            propertyType={ProcessParameterProperty.Value}
            controlType={controlType}
            required={required}
            options={dynamicOptions}
            definition={dynamicParamDefinition}
            fieldValues={fieldValues}
            dynamic
            contextExtension={contextExtension}
            disabled={
              !isConditionsEmpty(
                _getDefaultValue(name, ProcessParameterProperty.Value) as ExpressionDescriptor
              )
            }
            {...getCustomizedControlParams(
              dynamicParamDefinition,
              name,
              ProcessParameterProperty.Value
            )}
          />

          {(dynamicParamDefinition?.dataType === ConfigurationParameterDataType.User ||
            dynamicParamDefinition?.dataType === ConfigurationParameterDataType.UserMulti ||
            dynamicParamDefinition?.["x-workpoint-field"]?.type === "User" ||
            dynamicParamDefinition?.["x-workpoint-field"]?.type === "UserMulti") && (
            <ParameterPropertyControl
              control={control}
              setValue={props.setValue}
              trigger={props.trigger}
              name={name}
              label={"Allow selection of"}
              description="States whether the user field search should include groups as well or just users"
              defaultValue={_getDefaultValue(name, ProcessParameterProperty.AllowSelectionOf)}
              propertyType={ProcessParameterProperty.AllowSelectionOf}
              controlType={FormControlType.Dropdown}
              required={false}
              options={[
                { key: "", value: "Default" },
                ...Object.values(UserFieldAllowSelectionOf).map((v) => {
                  switch (v) {
                    case UserFieldAllowSelectionOf.PeopleOnly:
                      return { key: v, text: "People only" };
                    case UserFieldAllowSelectionOf.PeopleAndGroups:
                      return { key: v, text: "People and groups" };
                    default:
                      return { key: v, text: v };
                  }
                })
              ]}
              definition={dynamicParamDefinition}
              dynamic
              disabled={
                !isConditionsEmpty(
                  _getDefaultValue(
                    name,
                    ProcessParameterProperty.AllowSelectionOf
                  ) as ExpressionDescriptor
                )
              }
              hideConditions
              contextExtension={contextExtension}
              {...getCustomizedControlParams(
                dynamicParamDefinition,
                name,
                ProcessParameterProperty.AllowSelectionOf
              )}
            />
          )}

          {((isUserStep && !dynamicParamDefinition["x-workpoint-properties"]) ||
            (dynamicParamDefinition["x-workpoint-properties"] as string[])?.includes(
              ProcessParameterProperty.Hidden
            )) && (
            <ParameterPropertyControl
              control={control}
              setValue={props.setValue}
              trigger={props.trigger}
              name={name}
              label={"Hidden"}
              description="Whether this field should be hidden or not"
              defaultValue={_getDefaultValue(name, ProcessParameterProperty.Hidden)}
              propertyType={ProcessParameterProperty.Hidden}
              controlType={FormControlType.Boolean}
              required={false}
              onText={"Field is hidden"}
              offText={"Field is visible"}
              definition={dynamicParamDefinition}
              fieldValues={fieldValues}
              dynamic
              contextExtension={contextExtension}
              disabled={
                !isConditionsEmpty(
                  _getDefaultValue(name, ProcessParameterProperty.Hidden) as ExpressionDescriptor
                )
              }
              {...getCustomizedControlParams(
                dynamicParamDefinition,
                name,
                ProcessParameterProperty.Hidden
              )}
            />
          )}
          {((isUserStep && !dynamicParamDefinition["x-workpoint-properties"]) ||
            (dynamicParamDefinition["x-workpoint-properties"] as string[])?.includes(
              ProcessParameterProperty.Readonly
            )) && (
            <ParameterPropertyControl
              control={control}
              setValue={props.setValue}
              trigger={props.trigger}
              name={name}
              label={"Readonly"}
              description="Whether the field should be read-only or editable"
              defaultValue={_getDefaultValue(name, ProcessParameterProperty.Readonly)}
              propertyType={ProcessParameterProperty.Readonly}
              controlType={FormControlType.Boolean}
              required={false}
              onText={"Field is read-only"}
              offText={"Field is editable"}
              definition={dynamicParamDefinition}
              fieldValues={fieldValues}
              dynamic
              contextExtension={contextExtension}
              disabled={
                !isConditionsEmpty(
                  _getDefaultValue(name, ProcessParameterProperty.Readonly) as ExpressionDescriptor
                )
              }
              {...getCustomizedControlParams(
                dynamicParamDefinition,
                name,
                ProcessParameterProperty.Readonly
              )}
            />
          )}
          {((isUserStep && !dynamicParamDefinition["x-workpoint-properties"]) ||
            (dynamicParamDefinition["x-workpoint-properties"] as string[])?.includes(
              ProcessParameterProperty.Required
            )) && (
            <ParameterPropertyControl
              control={control}
              setValue={props.setValue}
              trigger={props.trigger}
              name={name}
              label={"Required"}
              description="Whether the field should be required"
              defaultValue={_getDefaultValue(name, ProcessParameterProperty.Required)}
              propertyType={ProcessParameterProperty.Required}
              controlType={FormControlType.Boolean}
              required={false}
              onText={"Field is required"}
              offText={"Field is optional"}
              definition={dynamicParamDefinition}
              fieldValues={fieldValues}
              dynamic
              contextExtension={contextExtension}
              disabled={
                !isConditionsEmpty(
                  _getDefaultValue(name, ProcessParameterProperty.Required) as ExpressionDescriptor
                )
              }
              {...getCustomizedControlParams(
                dynamicParamDefinition,
                name,
                ProcessParameterProperty.Required
              )}
            />
          )}
          {((isUserStep && !dynamicParamDefinition["x-workpoint-properties"]) ||
            (dynamicParamDefinition["x-workpoint-properties"] as string[])?.includes(
              ProcessParameterProperty.Valid
            )) && (
            <>
              <ParameterPropertyControl
                control={control}
                setValue={props.setValue}
                trigger={props.trigger}
                name={name}
                label={"Validation"}
                description="Defines the rules to validate the field"
                defaultValue={_getDefaultValue(name, ProcessParameterProperty.Valid)}
                propertyType={ProcessParameterProperty.Valid}
                required={false}
                definition={dynamicParamDefinition}
                fieldValues={fieldValues}
                dynamic
                contextExtension={contextExtension}
                disabled={
                  !isConditionsEmpty(
                    _getDefaultValue(name, ProcessParameterProperty.Valid) as ExpressionDescriptor
                  )
                }
                {...getCustomizedControlParams(
                  dynamicParamDefinition,
                  name,
                  ProcessParameterProperty.Valid
                )}
              />
              <ParameterPropertyControl
                control={control}
                setValue={props.setValue}
                trigger={props.trigger}
                name={name}
                label={"Validation message"}
                description="The message to be displayed in case validation fails"
                defaultValue={_getDefaultValue(name, ProcessParameterProperty.ValidMessage)}
                propertyType={ProcessParameterProperty.ValidMessage}
                controlType={FormControlType.PlainText}
                required={false}
                definition={dynamicParamDefinition}
                fieldValues={fieldValues}
                dynamic
                contextExtension={contextExtension}
                disabled={
                  !isConditionsEmpty(
                    _getDefaultValue(
                      name,
                      ProcessParameterProperty.ValidMessage
                    ) as ExpressionDescriptor
                  )
                }
                {...getCustomizedControlParams(
                  dynamicParamDefinition,
                  name,
                  ProcessParameterProperty.ValidMessage
                )}
                rows={1}
                enableLocalization
                stepConfiguration={props.stepConfiguration}
              />
            </>
          )}
        </AccordionControl>
      );
    });

    return (
      <div key={definition.name}>
        <AdvSettingsButton
          toggle
          checked={advancedToggled}
          onClick={() => setAdvancedToggled(!advancedToggled)}
          styles={{ flexContainer: { width: "fit-content" } }}
        >
          <Label>{definition.title ?? definition["x-ms-summary"] ?? definition.name}</Label>
          <Icon
            iconName={advancedToggled ? "ChevronUp" : "ChevronDown"}
            style={{ marginLeft: "10px" }}
          />
        </AdvSettingsButton>

        {definition["x-workpoint-control"]?.type === FormControlType.DynamicSchema && (
          <AddFieldContainer>
            <DefaultButton
              text="New field"
              iconProps={{ iconName: "Add" }}
              menuProps={{
                items: Object.keys(ConfigurationParameterDataType).map((pspdt) => ({
                  key: pspdt,
                  text: pspdt,
                  onClick: () => addField(pspdt)
                }))
              }}
            />
          </AddFieldContainer>
        )}
        {advancedToggled && dynamicControls.length > 0 && (
          <AccordionListContainer>{dynamicControls}</AccordionListContainer>
        )}
      </div>
    );
  }

  if (definition["x-workpoint-dynamic-columns"]) {
    return (
      <>
        {controlsData?.loading && <LoadingIndicator />}
        <AdvSettingsButton
          toggle
          checked={dynamicToggled}
          onClick={() => setDynamicToggled(!dynamicToggled)}
          styles={{ flexContainer: { width: "fit-content" } }}
        >
          <Label>{definition.title ?? definition["x-ms-summary"] ?? definition.name}</Label>
          <Icon
            iconName={dynamicToggled ? "ChevronUp" : "ChevronDown"}
            style={{ marginLeft: "10px" }}
          />
        </AdvSettingsButton>
        {dynamicToggled && (
          <DynamicColumnFields
            controlsData={controlsData}
            definition={definition}
            onDelete={onDelete}
            onOrderChange={onOrderChange}
            control={control}
            setValue={setValue}
            trigger={props.trigger}
            getValues={getValues}
            register={props.register}
            unregister={props.unregister}
            fieldValues={props.fieldValues}
            document={props.document}
          />
        )}
      </>
    );
  } else if (definition["x-workpoint-dynamic-configuration"]) {
    return (
      <>
        {controlsData?.loading && <LoadingIndicator />}
        <AdvSettingsButton styles={{ flexContainer: { width: "fit-content" } }}>
          <Label>{definition.title ?? definition["x-ms-summary"] ?? definition.name}</Label>
        </AdvSettingsButton>
        <DynamicTabs
          name={props.name}
          controlsData={controlsData}
          definition={definition}
          control={control}
          setValue={setValue}
          trigger={props.trigger}
          getValues={getValues}
          register={props.register}
          unregister={props.unregister}
          fieldValues={props.fieldValues}
          document={props.document}
          localizationIdPrefix={stepId}
          getCustomizedControlParams={getCustomizedControlParams}
          skipValueInName={props.skipValueInName}
          stepConfiguration={props.stepConfiguration as ProcessStepConfiguration}
        />
      </>
    );
  }

  if (
    definition["x-workpoint-control"]?.type &&
    props.customControls?.[definition["x-workpoint-control"]?.type]
  ) {
    const CustomControl = props.customControls?.[definition["x-workpoint-control"]?.type];

    return (
      <>
        <CustomControl
          control={control}
          setValue={props.setValue}
          trigger={props.trigger}
          name={props.name ?? definition.name}
          label={definition.title ?? definition["x-ms-summary"] ?? definition.name}
          defaultValue={_getDefaultValue(props.name ?? definition.name, propertyType)}
          propertyType={propertyType}
          controlType={controlType}
          required={required}
          options={options}
          definition={definition}
          document={props.document}
          fieldValues={fieldValues}
          dynamic={props.dynamic}
          skipPropertyTypeInName={props.skipPropertyTypeInName}
          enableLocalization={!!definition["x-workpoint-control"]?.translatable}
          rows={props.rows}
          hideConditions={props.hideConditions}
          {...customizedControlParams}
        />
      </>
    );
  }

  return (
    <div key={definition.name}>
      <ParameterPropertyControl
        control={control}
        setValue={props.setValue}
        trigger={props.trigger}
        name={props.name ?? definition.name}
        label={definition.title ?? definition["x-ms-summary"] ?? definition.name}
        defaultValue={_getDefaultValue(props.name ?? definition.name, propertyType)}
        propertyType={propertyType}
        controlType={controlType}
        required={required}
        options={options}
        definition={definition}
        fieldValues={fieldValues}
        dynamic={props.dynamic}
        skipPropertyTypeInName={props.skipPropertyTypeInName}
        enableLocalization={!!definition["x-workpoint-control"]?.translatable}
        stepConfiguration={
          definition["x-workpoint-control"]?.translatable ? stepConfiguration : undefined
        }
        rows={props.rows}
        hideConditions={props.hideConditions}
        {...customizedControlParams}
      />
      {controlsData?.loading && <LoadingIndicator />}
    </div>
  );
};

export const LoadingIndicator = () => (
  <ProgressIndicator
    label=""
    description=""
    styles={{
      itemProgress: {
        marginBottom: "-2px",
        padding: "0px",
        width: "90%"
      },
      progressTrack: { backgroundColor: "rgba(0,0,0,0)" },
      progressBar: {
        background: `linear-gradient(to right, rgba(0,0,0,0) 0%, ${WorkPointTheme.palette.themePrimary} 40%, ${WorkPointTheme.palette.themePrimary} 60%, rgba(0,0,0,0) 100%)`
      }
    }}
  />
);

export const AdvSettingsButton = styled(DefaultButton)`
  margin: 10px 0px;
  background-color: ${theme.palette.white};
  border: 0px;
  padding: 0px;
  :hover {
    background-color: ${theme.palette.white};
  }
`;

export const AddFieldContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;
