import { FontIcon, IconButton, TooltipHost, Text, IImageProps, Image } from "@fluentui/react";
import * as React from "react";
import styled from "styled-components";
import { OpenAPIV2 } from "openapi-types";
import { WorkPointTheme } from "@workpoint/components/lib/constants";
import { getStepType } from "../../../../utils/processUtils";

interface IStepItemProps {
  item: OpenAPIV2.OperationObject;
  onDetailClick(item: OpenAPIV2.OperationObject): void;
  isSelected: boolean;
  icon?: string;
}

const StepItem = ({ className, children, selected, onClick }: any) => (
  <div
    className={className}
    onClick={onClick}
    style={{ backgroundColor: selected ? `${WorkPointTheme.palette.neutralLighter}` : "" }}
  >
    {children}
  </div>
);

const StyledStepItem = styled(StepItem)`
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${WorkPointTheme.palette.neutralLighter};
  }
`;

const StyledInfoButton = styled(IconButton)`
  color: ${WorkPointTheme.palette.themePrimary};
  margin: 15px 10px;
  height: 30px;
  width: 30px;
  font-size: 30px;
`;

const StyledItemContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1 1;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 100%;
`;

const StyledStepText = ({ className, children, title, type }: any) => (
  <div className={className}>
    <Text variant={"medium"} className={"title"}>
      {title}
    </Text>
    <Text variant={"medium"} className={"type"}>
      {type}
    </Text>
  </div>
);

const StyledStepInfo = styled(StyledStepText)`
  display: flex;
  flex-direction: column;
  min-width: 150px;
  .title {
    color: ${WorkPointTheme.palette.blue};
    margin: 0;
  }

  .type {
    color: ${WorkPointTheme.palette.neutralTertiary};
    margin: 0;
  }
`;

const StyledItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Tag = ({ className, children, color, backgroundColor }: any) => (
  <div className={className} style={{ color: color, backgroundColor: backgroundColor }}>
    {children}
  </div>
);

const StyledTag = styled(Tag)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: max-content;
  padding: 0px 5px;
  margin: 0px 2px;
  color: ${WorkPointTheme.palette.themePrimary};
  background-color: ${WorkPointTheme.palette.themeLighter};
`;

const StyledTypeIcon = styled(FontIcon)`
  margin: 15px 10px;
  font-size: 20px;
  border: 10px solid ${WorkPointTheme.palette.blue};
  background-color: ${WorkPointTheme.palette.blue};
  border-radius: 100%;
  color: ${WorkPointTheme.palette.white};
`;

export const NewStepItem = (props: IStepItemProps) => {
  const getTags = () => {
    const tags: any[] = [];
    const step: any = props.item as any;
    if (step["x-workpoint-license"] === "premium") {
      tags.push(
        <StyledTag
          key={1}
          color={`${WorkPointTheme.palette.themePrimary}`}
          backgroundColor={`${WorkPointTheme.palette.themeLighter}`}
        >
          <Text>PREMIUM</Text>
        </StyledTag>
      );
    }

    if (step["tags"].includes("New")) {
      tags.push(
        <StyledTag key={2} color={`${WorkPointTheme.palette.blue}`} backgroundColor="#D9EFFF">
          <Text>New</Text>
        </StyledTag>
      );
    }
    if (step["tags"].includes("Updated")) {
      tags.push(
        <StyledTag key={3} color={`${WorkPointTheme.palette.blue}`} backgroundColor="#D9EFFF">
          <Text>Updated</Text>
        </StyledTag>
      );
    }
    if (step["tags"].includes("Beta")) {
      tags.push(
        <StyledTag key={3} color={`${WorkPointTheme.palette.blue}`} backgroundColor="#D9EFFF">
          <Text>Beta</Text>
        </StyledTag>
      );
    }

    return tags;
  };

  const imageProps: Partial<IImageProps> = {
    src: (props.item as any)["x-workpoint-icon"],
    styles: (props) => ({
      root: { minWidth: "40px", minHeight: "40px", borderRadius: "100%", margin: "15px 10px" }
    })
  };

  return (
    <StyledStepItem onClick={() => props.onDetailClick(props.item)} selected={props.isSelected}>
      <StyledItemContainer>
        <StyledTypeIcon iconName={props.icon} />
        <StyledStepInfo
          title={props.item.summary}
          type={getStepType((props.item as any)["x-workpoint-type"])}
        />
      </StyledItemContainer>
      {props.item.tags && getTags()}
      <StyledItem>
        <TooltipHost content={props.item.description} directionalHint={12}>
          <StyledInfoButton
            iconProps={{ iconName: "ChevronRight" }}
            onClick={() => props.onDetailClick(props.item)}
          />
        </TooltipHost>
      </StyledItem>
    </StyledStepItem>
  );
};
