import { yupResolver } from "@hookform/resolvers/yup";
import { useId } from "@fluentui/react-hooks";
import {
  ActionButton,
  DayOfWeek,
  DefaultButton,
  FontWeights,
  IconButton,
  IOverflowSetItemProps,
  mergeStyleSets,
  Modal,
  OverflowSet,
  PrimaryButton,
  Stack,
  StackItem
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { Link, useNavigate, useParams } from "react-router-dom";
import WorkPointTheme from "@workpoint/components/lib/constants";
import * as yup from "yup";
import { ControlledDropdown } from "./ControlledDropdown";
import { ControlledHorizontalPicker } from "./ControlledHorizontalPicker";
import { ControlledPeoplePicker } from "./ControlledPeoplePicker";
import { ControlledTextField } from "./ControlledTextField";
import { ControlledTimePicker } from "./ControlledTimePicker";
import {
  notificationStatusSelector,
  notificationProfileSelector,
  editProfile,
  addProfile,
  removeProfile
} from "../notificationSettingsReducer";
import { ControlledNotificationTypesPicker } from "./ControlledNotificationTypesPicker";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  NotificationEmailSchedule,
  INotificationProfile,
  InitialNotificationProfile
} from "../../../../models/NotificationSettings";
import { RequestState } from "../../../../models/RequestState";
import { globalSelector } from "../../../../store/globalReducer";
import { RootState } from "../../../../store/store";
import { AppRoutes } from "../../../../routes";

export const ProfileModalForm: React.FunctionComponent = () => {
  const titleId = useId("title");
  const params = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const profileStatus = useAppSelector((state: RootState) => notificationStatusSelector(state));
  const { spHostUrl } = useAppSelector(globalSelector);

  const profileEditId = params.notificationId;

  const fieldNames = {
    Name: intl.formatMessage({
      id: "name",
      defaultMessage: "Name"
    }),
    Target: intl.formatMessage({
      id: "target",
      defaultMessage: "Target"
    }),
    EmailSchedule: intl.formatMessage({
      id: "email-schedule",
      defaultMessage: "Email schedule"
    }),
    DeliveryDayOfWeek: intl.formatMessage({
      id: "delivery-day",
      defaultMessage: "Delivery day"
    }),
    NotificationTypes: intl.formatMessage({
      id: "notification-types",
      defaultMessage: "Notification Types"
    }),
    SpecificDeliveryTime: intl.formatMessage({
      id: "specific-delivery-time",
      defaultMessage: "Specific delivery time"
    }),
    Monday: intl.formatMessage({ id: "monday", defaultMessage: "Monday" }),
    Tuesday: intl.formatMessage({ id: "tuesday", defaultMessage: "Tuesday" }),
    Wednesday: intl.formatMessage({ id: "wednesday", defaultMessage: "Wednesday" }),
    Thursday: intl.formatMessage({ id: "thursday", defaultMessage: "Thursday" }),
    Friday: intl.formatMessage({ id: "friday", defaultMessage: "Friday" }),
    Saturday: intl.formatMessage({ id: "saturday", defaultMessage: "Saturday" }),
    Sunday: intl.formatMessage({ id: "sunday", defaultMessage: "Sunday" })
  };

  const schema = yup.object({
    Name: yup.string().required().label(fieldNames.Name),
    Target: yup
      .object({
        Name: yup.string().required(),
        Identifier: yup.string().required()
      })
      .label(fieldNames.Target),
    EmailSchedule: yup.number().default(0).required().label(fieldNames.EmailSchedule),
    SpecificDeliveryTime: yup
      .mixed()
      .when("EmailSchedule", {
        is: (value: number) =>
          value === NotificationEmailSchedule.SendDaily ||
          value === NotificationEmailSchedule.SendWeekly,
        then: yup.string().required()
      })
      .label(fieldNames.SpecificDeliveryTime),
    DeliveryDayOfWeek: yup
      .mixed()
      .when("EmailSchedule", {
        is: (value: number) => value === NotificationEmailSchedule.SendWeekly,
        then: yup.number().required()
      })
      .label(fieldNames.DeliveryDayOfWeek),
    NotificationTypes: yup.array().ensure().label(fieldNames.NotificationTypes)
  });

  const existingProfile = useAppSelector((state: RootState) =>
    notificationProfileSelector(state, profileEditId as string)
  );

  const [tab, setTab] = useState<string>("settings");

  const {
    control,
    watch,
    handleSubmit,
    reset,
    register,
    formState: { errors }
  } = useForm<INotificationProfile>({
    mode: "onBlur",
    defaultValues: InitialNotificationProfile(),
    resolver: yupResolver(schema)
  });

  /**
   * Load the profile values into the form, if editing an existing profile.
   */
  useEffect(() => {
    if (profileStatus === RequestState.fulfilled) {
      reset(existingProfile);
    }
  }, [profileStatus]);

  const onsubmit = (data: any) => {
    // Edit flow
    if (profileEditId) dispatch(editProfile(data));
    // Create flow
    else dispatch(addProfile(data));

    navigate(AppRoutes.Notification);
  };

  const onRemoveClick = () => {
    dispatch(removeProfile(profileEditId as string));
    navigate(AppRoutes.Notification);
  };

  const deliveryDayWatch = watch("EmailSchedule");

  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={true}
        onDismiss={() => false}
        isBlocking={false}
        containerClassName={contentStyles.container}
        allowTouchBodyScroll
        topOffsetFixed
        styles={{
          scrollableContent: { width: 500 },
          main: { top: "10%" }
        }}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>Notification Profiles</span>
          <Link to={AppRoutes.Notification}>
            <IconButton
              styles={{ root: { color: "white" } }}
              iconProps={{ iconName: "Cancel" }}
              ariaLabel="Close popup modal"
            />
          </Link>
        </div>
        <OverflowSet
          items={[
            {
              key: "settings",
              name: intl.formatMessage({
                id: "settings",
                defaultMessage: "Settings"
              })
            },
            {
              key: "notification-types",
              name: fieldNames.NotificationTypes
            }
          ]}
          onRenderItem={(item: IOverflowSetItemProps): JSX.Element => {
            const active = item.key === tab;
            return (
              <span
                onClick={(ev) => setTab(item.key)}
                style={{
                  marginRight: 20,
                  fontSize: 12,
                  color: active ? "#333" : "grey",
                  height: 42,
                  padding: "12px 10px 8px 10px",
                  borderBottom: active ? `#f7941e 2px solid` : "",
                  boxSizing: "border-box",
                  fontWeight: "bold"
                }}
              >
                {item.name}
              </span>
            );
          }}
          onRenderOverflowButton={() => null}
          styles={{
            root: {
              borderBottom: "2px solid #eee",
              paddingLeft: 30
            },
            item: {
              height: 40,
              cursor: "pointer"
            }
          }}
        />
        <div className={contentStyles.body}>
          <form noValidate onSubmit={handleSubmit(onsubmit)}>
            <Stack
              styles={{ root: { display: tab === "settings" ? "block" : "none" } }}
              tokens={{ childrenGap: 20, padding: 10 }}
            >
              <StackItem>
                <label htmlFor="Name">
                  <h4 className="text">{fieldNames.Name}</h4>
                </label>

                <ControlledTextField
                  control={control}
                  name="Name"
                  placeholder={intl.formatMessage({
                    id: "name-your-profile",
                    defaultMessage: "Name your profile"
                  })}
                />
              </StackItem>
              <StackItem>
                <label htmlFor="Target">
                  <h4 className="text">{fieldNames.Target}</h4>
                </label>
                <ControlledPeoplePicker control={control} name="Target" solutionUrl={spHostUrl!} />
              </StackItem>
              <StackItem>
                <h4 className="text">{fieldNames.EmailSchedule}</h4>

                <ControlledHorizontalPicker
                  control={control}
                  name="EmailSchedule"
                  options={[
                    {
                      key: NotificationEmailSchedule.SendImmediatetly,
                      text: intl.formatMessage({
                        id: "send-immediately",
                        defaultMessage: "Send immediately"
                      })
                    },
                    {
                      key: NotificationEmailSchedule.SendDaily,
                      text: intl.formatMessage({
                        id: "send-daily",
                        defaultMessage: "Send daily"
                      })
                    },
                    {
                      key: NotificationEmailSchedule.SendWeekly,
                      text: intl.formatMessage({
                        id: "send-weekly",
                        defaultMessage: "Send weekly"
                      })
                    }
                  ]}
                />
                {deliveryDayWatch === NotificationEmailSchedule.SendWeekly && (
                  <div>
                    <h4 className="text">{fieldNames.DeliveryDayOfWeek}</h4>
                    <ControlledDropdown
                      control={control}
                      name="DeliveryDayOfWeek"
                      placeholder={intl.formatMessage({
                        id: "select-delivery-day",
                        defaultMessage: "Select delivery day"
                      })}
                      options={[
                        { key: DayOfWeek.Monday, text: fieldNames.Monday },
                        {
                          key: DayOfWeek.Tuesday,
                          text: fieldNames.Tuesday
                        },
                        {
                          key: DayOfWeek.Wednesday,
                          text: fieldNames.Wednesday
                        },
                        {
                          key: DayOfWeek.Thursday,
                          text: fieldNames.Thursday
                        },
                        { key: DayOfWeek.Friday, text: fieldNames.Friday },
                        {
                          key: DayOfWeek.Saturday,
                          text: fieldNames.Saturday
                        },
                        { key: DayOfWeek.Sunday, text: fieldNames.Sunday }
                      ]}
                    />
                  </div>
                )}
                {(deliveryDayWatch === NotificationEmailSchedule.SendDaily ||
                  deliveryDayWatch === NotificationEmailSchedule.SendWeekly) && (
                  <div>
                    <label htmlFor="SpecificDeliveryTime">
                      <h4 className="text">{fieldNames.SpecificDeliveryTime}</h4>
                    </label>
                    <ControlledTimePicker
                      control={control}
                      register={register}
                      name="SpecificDeliveryTime"
                    />
                  </div>
                )}
              </StackItem>
            </Stack>

            <Stack
              styles={{
                root: { display: tab === "notification-types" ? "block" : "none" }
              }}
              tokens={{ childrenGap: 20, padding: 10 }}
            >
              <StackItem>
                <ControlledNotificationTypesPicker control={control} name="NotificationTypes" />
              </StackItem>
            </Stack>

            {/* Footer */}
            <Stack horizontal horizontalAlign="end">
              {profileEditId && (
                <StackItem tokens={{ padding: "0 0 0 5px" }}>
                  <ActionButton
                    text={intl.formatMessage({
                      id: "delete-this-profile",
                      defaultMessage: "Delete this profile"
                    })}
                    styles={{ root: { height: 32 } }}
                    onClick={onRemoveClick}
                    iconProps={{ iconName: "Delete" }}
                  />
                </StackItem>
              )}
              <StackItem tokens={{ padding: "0 0 0 5px" }}>
                <Link to={AppRoutes.Notification}>
                  <DefaultButton
                    text={intl.formatMessage({
                      id: "cancel",
                      defaultMessage: "Cancel"
                    })}
                  />
                </Link>
              </StackItem>
              <StackItem tokens={{ padding: "0 0 0 5px" }}>
                <PrimaryButton
                  text={intl.formatMessage({
                    id: "confirm",
                    defaultMessage: "Confirm"
                  })}
                  type="submit"
                  iconProps={{ iconName: "Accept" }}
                />
              </StackItem>
            </Stack>
          </form>
        </div>
      </Modal>
    </div>
  );
};
const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch"
  },
  header: [
    // eslint-disable-next-line
    WorkPointTheme.fonts.xLargePlus,
    {
      flex: "1 1 auto",
      backgroundColor: `${WorkPointTheme.palette.blue}`,
      color: WorkPointTheme.palette.white,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px"
    }
  ],
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    maxHeight: "60vh",
    overflowY: "auto",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 }
    }
  }
});
