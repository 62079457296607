import React, { useEffect, useRef, useState } from "react";
import { cloneDeep, merge, set } from "lodash";
import {
  DefaultButton,
  ITextField,
  Label,
  Panel,
  PanelType,
  PivotItem,
  Separator,
  TextField
} from "@fluentui/react";
import { useNavigate, useParams } from "react-router-dom";
import { ToRoute } from "../../../../routes";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { createTerm, processSelector, updateProcess } from "../../../../store/processReducer";
import {
  FormControlType,
  StyledControlledNumber,
  StyledControlledDropdown,
  StyledFieldContainer
} from "../../../common/parametersForm/ParametersForm";
import { ProcessParametersForm } from "./ProcessParametersForm";
import { PanelHeader } from "../../../common/PanelHeader";
import { Control, FieldValues, UseFormSetValue, UseFormTrigger } from "react-hook-form";
import ControlledGroupField from "./ControlledGroupField";
import { showDialog } from "../../../../store/dialogReducer";
import { DialogResult, DialogType } from "../../../common/Dialog";
import { ParameterPropertyControl } from "../../../common/parametersForm/ParameterPropertyControl";
import {
  ProcessConfiguration,
  ProcessItem,
  ProcessParameterProperty
} from "@workpoint/components/lib/models/ProcessConfiguration";
import { CustomButtonsField } from "../../../common/parametersForm/CustomButtonsField";
import styled from "styled-components";
import { ControlledConditions } from "../../../common/parametersForm/ControlledConditions";
import { formDefaultParameters, RowType } from "../../../../utils/processUtils";
import { evaluateProcessExpressionForDisplay } from "@workpoint/components/lib/helpers/expressionUtils";
import { AutoExecuteField } from "../../../common/parametersForm/AutoExecuteField";

export const ProcessParametersPanel = () => {
  const { processId } = useParams() as any;
  const navigate = useNavigate();
  const { process, groups, loading, document } = useAppSelector(processSelector);
  const dispatch = useAppDispatch();
  const inputEl = useRef<ITextField>(null);
  const [configuration, setConfiguration] = useState<ProcessConfiguration | undefined>(undefined);

  useEffect(() => {
    process && setConfiguration(process.configuration);
  }, [process]);

  const _closeProcessPanel = () => {
    if (processId) {
      navigate(ToRoute.process(processId));
    } else {
      navigate(ToRoute.newProcess());
    }
  };

  const _updateProcess = (data: any) => {
    const data2Update: any = {};
    Object.values(formDefaultParameters)
      .filter((dfp) => dfp.panels.indexOf(RowType.None) >= 0)
      .forEach((fdp) => {
        if (fdp.formKey in data) {
          set(data2Update, fdp.objectKey, data[fdp.formKey]);
        }
      });

    const processToUpdate: ProcessItem = cloneDeep(process);
    processToUpdate.configuration = merge(processToUpdate.configuration, data2Update);
    processToUpdate.title = data2Update.title?.defaultText;
    processToUpdate.description = data2Update.description?.defaultText;
    processToUpdate.groups = [...data2Update.groups];
    if (processToUpdate.configuration.finish?.sharePoint?.customButtons) {
      processToUpdate.configuration.finish.sharePoint.customButtons =
        cloneDeep(data2Update.finish?.sharePoint?.customButtons) ?? [];
    }
    if (processToUpdate.configuration.finish?.sharePoint?.autoExecute) {
      processToUpdate.configuration.finish.sharePoint.autoExecute =
        cloneDeep(data2Update.finish?.sharePoint?.autoExecute) ?? [];
    }
    if (processToUpdate.configuration.permissions?.conditions) {
      processToUpdate.configuration.permissions.conditions =
        cloneDeep(data2Update.permissions?.conditions) ?? [];
    }

    dispatch(updateProcess(processToUpdate));

    return [];
  };

  const _onRenderHeader = () => {
    return (
      <PanelHeader
        title={evaluateProcessExpressionForDisplay(process.configuration.title, {}) + " Settings"}
        closePanel={_closeProcessPanel}
      />
    );
  };

  return (
    <Panel
      isOpen={true}
      type={PanelType.smallFluid}
      hasCloseButton={false}
      focusTrapZoneProps={{
        isClickableOutsideFocusTrap: true,
        forceFocusInsideTrap: false
      }}
      styles={{
        root: {
          width: "100%",
          borderLeftWidth: "1px",
          borderLeftStyle: "solid",
          borderLeftColor: "#E5E5E5"
        },
        main: { boxShadow: "0px 0px 0px 0px" },
        commands: { display: "none" }
      }}
      onRenderHeader={_onRenderHeader}
    >
      <ProcessParametersForm
        key={(!groups || loading).toString()}
        formTitle={"Process"}
        close={_closeProcessPanel}
        update={_updateProcess}
        configuration={configuration as any}
        document={document}
        onRender={(pivots, control, openLocalizationPanel, setValue, trigger, context) => {
          pivots["General"].splice(
            0,
            0,
            <StyledFieldContainer key={formDefaultParameters.title.formKey}>
              <ParameterPropertyControl
                key={formDefaultParameters.title.formKey}
                control={control}
                setValue={setValue}
                trigger={trigger}
                name={`${formDefaultParameters.title.formKey}`}
                label="Title"
                description="Enter a name for your process/step."
                defaultValue={undefined}
                propertyType={ProcessParameterProperty.Title}
                controlType={FormControlType.PlainText}
                required={true}
                definition={{
                  in: "",
                  name: `${formDefaultParameters.title.formKey}`,
                  type: ""
                }}
                rows={1}
                enableLocalization
                skipPropertyTypeInName
              />
            </StyledFieldContainer>,
            <StyledFieldContainer key={formDefaultParameters.description.formKey}>
              <ParameterPropertyControl
                key={formDefaultParameters.description.formKey}
                control={control}
                setValue={setValue}
                trigger={trigger}
                name={`${formDefaultParameters.description.formKey}`}
                label="Description"
                description="Enter a description for your process"
                defaultValue={undefined}
                propertyType={ProcessParameterProperty.Description}
                controlType={FormControlType.RichText}
                required={false}
                definition={{
                  in: "",
                  name: `${formDefaultParameters.description.formKey}`,
                  type: ""
                }}
                rows={2}
                enableLocalization
                skipPropertyTypeInName
              />
            </StyledFieldContainer>,
            <StyledFieldContainer key={formDefaultParameters.processRetention.formKey}>
              <StyledControlledNumber
                name={formDefaultParameters.processRetention.formKey}
                label="Instances retention period (days)"
                placeholder={"Days to keep process history"}
                control={control}
                defaultValue={undefined}
              />
            </StyledFieldContainer>,
            <StyledFieldContainer key={formDefaultParameters.instanceStorage.formKey}>
              <StyledControlledDropdown
                name={formDefaultParameters.instanceStorage.formKey}
                label="Instance storage"
                placeholder={"Process instance storage type"}
                control={control}
                defaultValue={"SharePointList"}
                options={[
                  { key: "SharePointList", text: "SharePoint list" },
                  { key: "TemporaryMemory", text: "Temporary memory" }
                ]}
              />
            </StyledFieldContainer>
          );
          pivots["General"].push(
            <div key={formDefaultParameters.groups.formKey}>
              <DefaultButton
                disabled={!groups || loading}
                iconProps={{ iconName: "Add" }}
                onClick={() =>
                  dispatch(
                    showDialog({
                      type: DialogType.SaveCancel,
                      title: "Create a Group",
                      subText: "",
                      onRenderDialog: () => {
                        return <TextField label="Group Name" componentRef={inputEl} />;
                      },
                      onClick: (result: DialogResult) => {
                        if (result === DialogResult.Ok && inputEl?.current?.value) {
                          dispatch(createTerm(inputEl.current.value));
                        }
                      }
                    })
                  )
                }
                style={{ margin: "10px 0px" }}
              >
                Create Group
              </DefaultButton>
              <ControlledGroupField
                name={formDefaultParameters.groups.formKey}
                control={control}
                defaultValue={process?.groups}
              />
            </div>
          );
        }}
        additionalPivots={(
          control: Control<FieldValues>,
          openLocalizationPanel: (name: string, title: string) => void,
          setValue: UseFormSetValue<any>,
          trigger: UseFormTrigger<any>
        ) => {
          return [
            <PivotItem
              key={"ProcessStart"}
              headerText={"Start"}
              itemKey={"ProcessStart"}
              alwaysRender={true}
            >
              <ParameterPropertyControl
                control={control}
                setValue={setValue}
                trigger={trigger}
                name={formDefaultParameters.disableSelectedLoad.formKey}
                label={"Disable selected items load"}
                defaultValue={undefined}
                propertyType={ProcessParameterProperty.Value}
                controlType={FormControlType.Boolean}
                required={false}
                definition={{
                  name: formDefaultParameters.disableSelectedLoad.formKey,
                  in: "",
                  type: ""
                }}
                skipPropertyTypeInName
              />
              <StyledFieldContainer key={formDefaultParameters.buttonTitle.formKey}>
                <ParameterPropertyControl
                  key={formDefaultParameters.buttonTitle.formKey}
                  control={control}
                  setValue={setValue}
                  trigger={trigger}
                  name={`${formDefaultParameters.buttonTitle.formKey}`}
                  label="Process Button Title"
                  description="Enter a title for your process button."
                  defaultValue={undefined}
                  propertyType={ProcessParameterProperty.Title}
                  controlType={FormControlType.PlainText}
                  required={true}
                  definition={{
                    in: "",
                    name: `${formDefaultParameters.buttonTitle.formKey}`,
                    type: ""
                  }}
                  rows={1}
                  enableLocalization
                  skipPropertyTypeInName
                />
              </StyledFieldContainer>
              <ParameterPropertyControl
                control={control}
                setValue={setValue}
                trigger={trigger}
                name={formDefaultParameters.startMessage.formKey}
                label={"Message"}
                defaultValue={undefined}
                propertyType={ProcessParameterProperty.Value}
                controlType={FormControlType.RichText}
                required={false}
                definition={{
                  name: formDefaultParameters.startMessage.formKey,
                  in: "",
                  type: "string",
                  "x-workpoint-control": { type: "richText" }
                }}
                skipPropertyTypeInName
                enableLocalization
              />
              <Divider>
                <Label>Sharepoint</Label>
              </Divider>
              <ParameterPropertyControl
                control={control}
                setValue={setValue}
                trigger={trigger}
                name={formDefaultParameters.skipStartStep.formKey}
                label={"Skip start step"}
                defaultValue={undefined}
                propertyType={ProcessParameterProperty.Value}
                controlType={FormControlType.Boolean}
                required={false}
                definition={{
                  name: formDefaultParameters.skipStartStep.formKey,
                  in: "",
                  type: ""
                }}
                skipPropertyTypeInName
              />
            </PivotItem>,
            <PivotItem
              key={"ProcessFinish"}
              headerText={"Finish"}
              itemKey={"ProcessFinish"}
              alwaysRender={true}
            >
              <ParameterPropertyControl
                control={control}
                setValue={setValue}
                trigger={trigger}
                name={formDefaultParameters.finishMessage.formKey}
                label={"Message"}
                defaultValue={undefined}
                propertyType={ProcessParameterProperty.Value}
                controlType={FormControlType.RichText}
                required={false}
                definition={{
                  name: formDefaultParameters.finishMessage.formKey,
                  in: "",
                  type: "string",
                  "x-workpoint-control": { type: "richText" }
                }}
                skipPropertyTypeInName
                enableLocalization
              />
              <Divider>
                <Label>Sharepoint</Label>
              </Divider>
              <ParameterPropertyControl
                control={control}
                setValue={setValue}
                trigger={trigger}
                name={formDefaultParameters.showRunAgainButton.formKey}
                label={"Show run again button"}
                defaultValue={undefined}
                propertyType={ProcessParameterProperty.Value}
                controlType={FormControlType.Boolean}
                required={false}
                definition={{
                  name: formDefaultParameters.showRunAgainButton.formKey,
                  in: "",
                  type: ""
                }}
                skipPropertyTypeInName
              />
              <ParameterPropertyControl
                control={control}
                setValue={setValue}
                trigger={trigger}
                name={formDefaultParameters.reloadOnClose.formKey}
                label={"Reload page on process dialog close"}
                defaultValue={undefined}
                propertyType={ProcessParameterProperty.Value}
                controlType={FormControlType.Boolean}
                required={false}
                definition={{
                  name: formDefaultParameters.reloadOnClose.formKey,
                  in: "",
                  type: ""
                }}
                skipPropertyTypeInName
              />
              <ParameterPropertyControl
                control={control}
                setValue={setValue}
                trigger={trigger}
                name={formDefaultParameters.hideCloseButton.formKey}
                label={"Hide close button"}
                defaultValue={undefined}
                propertyType={ProcessParameterProperty.Value}
                controlType={FormControlType.Boolean}
                required={false}
                definition={{
                  name: formDefaultParameters.hideCloseButton.formKey,
                  in: "",
                  type: ""
                }}
                skipPropertyTypeInName
              />
              {/* <Divider /> */}
              <ExecutionLabel>Custom buttons</ExecutionLabel>
              <CustomButtonsField
                control={control}
                setValue={setValue}
                trigger={trigger}
                definition={{
                  name: formDefaultParameters.customButtons.formKey
                }}
              />
              {/* <Divider /> */}
              <ExecutionLabel>Auto execute</ExecutionLabel>
              <AutoExecuteField
                control={control}
                setValue={setValue}
                trigger={trigger}
                definition={{
                  name: formDefaultParameters.autoExecute.formKey
                }}
              />
            </PivotItem>,
            <PivotItem
              key={"ProcessPermissions"}
              headerText={"Permissions"}
              itemKey={"ProcessPermissions"}
              alwaysRender={true}
            >
              <ParameterPropertyControl
                control={control}
                setValue={setValue}
                trigger={trigger}
                name={formDefaultParameters.permissionMessageTitle.formKey}
                label={"Permissions message title"}
                defaultValue={undefined}
                propertyType={ProcessParameterProperty.Title}
                controlType={FormControlType.PlainText}
                required={false}
                definition={{
                  name: formDefaultParameters.permissionMessageTitle.formKey,
                  in: "",
                  type: "string",
                  "x-workpoint-control": { type: "plainText" }
                }}
                rows={1}
                skipPropertyTypeInName
                enableLocalization
              />
              <ParameterPropertyControl
                control={control}
                setValue={setValue}
                trigger={trigger}
                name={formDefaultParameters.permissionMessageBody.formKey}
                label={"Permissions message"}
                defaultValue={undefined}
                propertyType={ProcessParameterProperty.Description}
                controlType={FormControlType.RichText}
                required={false}
                definition={{
                  name: formDefaultParameters.permissionMessageBody.formKey,
                  in: "",
                  type: "string",
                  "x-workpoint-control": { type: "richText" }
                }}
                skipPropertyTypeInName
                enableLocalization
              />
              <ControlledConditions
                control={control}
                name={formDefaultParameters.permissionConditions.formKey}
                setValue={setValue}
                trigger={trigger}
                showConditionsTitle
              />
            </PivotItem>
          ];
        }}
      />
    </Panel>
  );
};

const Divider = styled(Separator)`
  margin: 30px 0px 20px 0px;
`;

const ExecutionLabel = styled(Label)`
  margin: 30px 0px 5px 0px;
`;
