import React, { useImperativeHandle } from "react";
import { useRef } from "react";

interface FileUploadProps {
  onUpload: (result: string | ArrayBuffer | null) => void;
}

export interface FileUploadRef {
  open: () => void;
}

export const FileUpload = React.forwardRef<FileUploadRef, FileUploadProps>((props, ref) => {
  const inputFile = useRef<HTMLInputElement>(null);

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileToRead = event.target.files![0];
    const reader = new FileReader();
    reader.readAsText(fileToRead);
    reader.onloadend = () => {
      props.onUpload(reader.result);
      if (inputFile.current) inputFile.current.value = "";
    };
  };

  useImperativeHandle(ref, () => ({
    open() {
      inputFile.current!.click();
    }
  }));

  return <input type="file" ref={inputFile} onChange={onFileChange} style={{ display: "none" }} />;
});
