import { IPanelProps, Label, Link, Panel, PanelType, Pivot, PivotItem } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { globalSelector, setSelectedPivot, setShowGuidance } from "../../store/globalReducer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { PanelHeader } from "./PanelHeader";
import { getArticleBody } from "../../utils/guidanceUtils";
import { OpenAPIV2 } from "openapi-types";
import { parseHtmlForNewTabLinks } from "@workpoint/components/lib/helpers/expressionUtils";

export interface GuidanceProps extends IPanelProps {
  swaggerOperation: OpenAPIV2.OperationObject & any;
}

export const Guidance = (props: GuidanceProps) => {
  const dispatch = useAppDispatch();
  const { selectedPivot } = useAppSelector(globalSelector);
  const [articles, setArticles] =
    useState<{ name: string; url: string; description?: string; html: string }[]>();
  const { swaggerOperation } = props;

  useEffect(() => {
    async function fetchArticles() {
      const parameterGroups = swaggerOperation?.["x-workpoint-parameter-groups"];
      let articles: { name: string; url: string; description?: string; html: string }[] = [];
      swaggerOperation?.externalDocs?.url &&
        articles.push({
          name: "Overview",
          url: swaggerOperation.externalDocs.url,
          description: swaggerOperation.externalDocs.description,
          html: parseHtmlForNewTabLinks(await getArticleBody(swaggerOperation?.externalDocs?.url))
        });
      if (parameterGroups) {
        for (let key of Object.keys(parameterGroups)) {
          parameterGroups[key]?.url &&
            articles.push({
              name: key,
              url: parameterGroups[key].url,
              description: parameterGroups[key].description,
              html: parseHtmlForNewTabLinks(await getArticleBody(parameterGroups[key].url))
            });
        }
      }
      setArticles(articles);
    }

    fetchArticles();
  }, [swaggerOperation]);

  const _onRenderHeader = (dispatch: (input: any) => void) => (
    <PanelHeader
      title={`Guide for ${
        swaggerOperation?.summary ?? swaggerOperation?.["x-ms-summary"] ?? swaggerOperation?.name
      }`}
      closePanel={() => dispatch(setShowGuidance(false))}
    />
  );

  return (
    <Panel
      isOpen
      focusTrapZoneProps={{
        isClickableOutsideFocusTrap: true,
        forceFocusInsideTrap: false
      }}
      type={PanelType.smallFluid}
      onRenderHeader={() => _onRenderHeader(dispatch)}
      hasCloseButton={false}
      styles={{
        commands: { display: "none" },
        root: {
          borderLeftWidth: "1px",
          borderLeftStyle: "solid",
          borderLeftColor: "#E5E5E5"
        },
        main: { boxShadow: "0px 0px 0px 0px" }
      }}
      {...props}
    >
      {articles !== undefined && articles.length > 0 ? (
        <Pivot
          selectedKey={selectedPivot}
          onLinkClick={(item) => dispatch(setSelectedPivot(item?.props.itemKey ?? ""))}
        >
          {articles.map((article) => (
            <PivotItem key={article.name} headerText={article.name} itemKey={article.name}>
              {article.name === undefined && (
                <Link href={article.url} target="_blank">
                  {article.description ?? article.url}
                </Link>
              )}
              {article.name !== undefined && (
                <div dangerouslySetInnerHTML={{ __html: article.html }}></div>
              )}
            </PivotItem>
          ))}
        </Pivot>
      ) : (
        <NoGuidanceText>
          At this time, no guidance exists for this location. Please check back later. We apologize
          for the inconvenience
        </NoGuidanceText>
      )}
    </Panel>
  );
};

const NoGuidanceText = styled(Label)`
  margin: 50px 20px;
`;
