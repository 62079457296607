import { IconButton, Label, Link, Panel, PanelType, Spinner, Stack } from "@fluentui/react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useApiClient } from "@workpoint/components/lib/clients/ApiProvider";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  loadApplicationConsent,
  loadConsentedAccounts,
  processSelector
} from "../../../store/processReducer";
import { isEmpty } from "../../../utils/commonUtils";
import { PanelHeader } from "../../common/PanelHeader";

interface ConnectionPanelProps {
  isOpen: boolean;
  onClose: () => void;
  grantExtendedPermissionError?: string;
  grantingExtededPermissions?: boolean;
}

const ConnectionsPanel = (props: ConnectionPanelProps) => {
  const dispatch = useAppDispatch();
  const { isOpen, onClose } = props;
  const {
    accounts,
    loadingAccounts,
    loadingApplicationConsent,
    applicationConsent,
    delegatedConsent,
    consentUrlForSavingTeamsChatMessages
  } = useAppSelector(processSelector);
  const { apiClient } = useApiClient();

  const [extendedConsentError, setExtendedConsentError] = useState("");
  const [grantingExtededPermissions, setGrantingExtendedPermissions] = useState(false);

  useEffect(() => {
    if (!accounts) {
      dispatch(loadConsentedAccounts());
      dispatch(loadApplicationConsent());
    }
  }, []);

  const addExtendedConsent = (e: any) => {
    e.preventDefault();
    setGrantingExtendedPermissions(true);
    apiClient
      .fetchWP("/Consent/WorkPoint/Permissions/Application/ExtendedGraphGroupify", "POST")
      .then((r) => {
        setGrantingExtendedPermissions(false);
        if (r.ok) {
          if (extendedConsentError !== "") {
            setExtendedConsentError("");
          }
        } else {
          r.json().then((err: any) => {
            setExtendedConsentError(err.ExceptionMessage);
          });
        }
      })
      .catch((e) => {
        setGrantingExtendedPermissions(false);
      });

    return false;
  };

  const addConsentUrlForSavingTeamsChatMessages = (e: any) => {
    e.preventDefault();
    let win = window.open(
      consentUrlForSavingTeamsChatMessages +
        "&redirect_uri=" +
        window.location.origin +
        window.location.pathname,
      "_blank",
      "toolbar=0,location=0,directories=0,status=1,menubar=0,titlebar=0,scrollbars=1,resizable=1,width=600,height=800"
    );
    if (win) {
      let timer = setInterval(function () {
        if (!win || win?.closed) {
          clearInterval(timer);
          dispatch(loadApplicationConsent());
        } else {
          try {
            if (win.location.href.toLowerCase().indexOf("admin_consent=true") > -1) {
              clearInterval(timer);
              if (win && !win?.closed) {
                win.close();
              }
              dispatch(loadApplicationConsent());
            }
          } catch (e) {}
        }
      }, 1000);
    }
  };

  return (
    <Panel
      isLightDismiss={true}
      isOpen={isOpen}
      type={PanelType.medium}
      onDismiss={onClose}
      hasCloseButton={false}
      isHiddenOnDismiss={true}
      isFooterAtBottom={true}
      styles={{
        root: {
          width: "100%",
          borderLeftWidth: "1px",
          borderLeftStyle: "solid",
          borderLeftColor: "#E5E5E5"
        },
        commands: { display: "none" }
      }}
      onRenderHeader={() => (
        <PanelHeader iconName={"PlugDisconnected"} title={"Connections"} closePanel={onClose} />
      )}
    >
      <ConnectionsWrapper>
        {loadingAccounts || loadingApplicationConsent ? (
          <Spinner />
        ) : (
          <div>
            <IconButton
              iconProps={{ iconName: "Refresh" }}
              onClick={() => {
                dispatch(loadConsentedAccounts());
              }}
            ></IconButton>
            <Label>Connection for system processes</Label>
            <div style={{ marginBottom: "15px" }}>
              To run processes a service account is required. This account must be a WorkPoint
              solution administrator. Please sign in below with the account to be used for process
              execution.
            </div>

            <Stack horizontal style={{ marginBottom: "20px" }}>
              <Stack.Item grow={1}>
                {!isEmpty(accounts?.accounts?.[0])
                  ? accounts!.accounts[0]
                  : "There aren't any connections for this process."}{" "}
              </Stack.Item>
              {!isEmpty(accounts?.consentUrl) && (
                <Stack.Item>
                  Click{" "}
                  <Link href={accounts?.consentUrl} target={"_blank"}>
                    here
                  </Link>{" "}
                  to {accounts?.accounts?.[0] ? "re-" : ""}authorize
                </Stack.Item>
              )}
            </Stack>
            <Label>Connection for Microsoft Teams integration</Label>
            <div style={{ marginBottom: "15px" }}>
              To use the "Create Team" action a service account is required. This account must be a
              SharePoint administrator and a Site Collection Administrator on the WorkPoint
              Solution. Please sign in below with the account to be used for the integration.
              Extended consent is required. Follow the link and sign in with an Microsoft 365 tenant
              administrator to give the necessary permissions to the WorkPoint App.
            </div>

            <Stack horizontal style={{ marginBottom: "15px" }}>
              <Stack.Item grow={1}>
                {!isEmpty(accounts?.serviceUserAccount)
                  ? accounts!.serviceUserAccount
                  : "No service user account added."}{" "}
              </Stack.Item>
              {!isEmpty(accounts?.serviceUserConsentUrl) && (
                <Stack.Item>
                  Click{" "}
                  <Link href={accounts?.serviceUserConsentUrl} target={"_blank"}>
                    here
                  </Link>{" "}
                  to {accounts?.serviceUserAccount ? "re-" : ""}authorize
                </Stack.Item>
              )}
            </Stack>
            <Stack>
              <Stack.Item>
                {applicationConsent?.some(
                  (consent) => consent.indexOf("Group.ReadWrite.All") > -1
                ) ? (
                  "Extended consent has been given."
                ) : (
                  <>
                    <Link href="" onClick={addExtendedConsent}>
                      Add extended consent
                    </Link>
                    {grantingExtededPermissions ? <Spinner /> : ""}
                  </>
                )}
              </Stack.Item>
              {extendedConsentError !== "" ? (
                <Stack.Item>
                  <span style={{ color: "red" }}>{extendedConsentError}</span>
                </Stack.Item>
              ) : (
                ""
              )}
            </Stack>
            <Label style={{ marginTop: "15px" }}>Consent for saving Teams chat messages</Label>
            <div style={{ marginBottom: "15px" }}>
              To use the save Microsoft Teams chats steps an additional consent for delegated
              permissions is required. Follow the link and sign in with an Microsoft 365 tenant
              administrator to give the necessary permissions on behalf of your organization.
            </div>
            {consentUrlForSavingTeamsChatMessages && (
              <Stack>
                <Stack.Item>
                  {applicationConsent?.some(
                    (consent) =>
                      delegatedConsent?.some(
                        (consent) => consent.indexOf("ChannelSettings.Read.All") > -1
                      ) &&
                      delegatedConsent?.some((consent) => consent.indexOf("Chat.Read") > -1) &&
                      delegatedConsent?.some(
                        (consent) => consent.indexOf("ChannelMember.Read.All") > -1
                      ) &&
                      delegatedConsent?.some(
                        (consent) => consent.indexOf("ChannelMessage.Read.All") > -1
                      ) &&
                      delegatedConsent?.some(
                        (consent) => consent.indexOf("Team.ReadBasic.All") > -1
                      )
                  ) ? (
                    "Consent for saving Teams chat messages has been given."
                  ) : (
                    <>
                      <Link href="" onClick={addConsentUrlForSavingTeamsChatMessages}>
                        Add a consent for saving Teams chat messages
                      </Link>
                    </>
                  )}
                </Stack.Item>
              </Stack>
            )}
          </div>
        )}
      </ConnectionsWrapper>
    </Panel>
  );
};

const ConnectionsWrapper = styled.div`
  margin-top: 30px;
`;

export default ConnectionsPanel;
