import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep, merge } from "lodash";
import { OpenAPIV2 } from "openapi-types";
import { v4 as uuid } from "uuid";
import { backendPool } from "@workpoint/components/lib/constants";
import { evaluateProcessExpressionLimited } from "@workpoint/components/lib/helpers/expressionUtils";
import { AdminConfiguration } from "@workpoint/components/lib/models/AdminConfiguration";
import {
  ConfigurationParameterType,
  ExpressionDescriptor,
  ExpressionDescriptorRule
} from "@workpoint/components/lib/models/ProcessConfiguration";
import { AdminOperation } from "../components/admin/ConfigurePageControl";
import { FormControlType } from "../components/common/parametersForm/ParametersForm";
import { getFormControlType } from "../utils/adminUtils";
import { defaultExpression, isProcessExpressionModel } from "../utils/processUtils";
import { getDefinitionByOperationId, getDynamicData, getSwagger } from "../utils/swaggerUtils";
import { AppDispatch, AppThunk, RootState } from "./store";

export type AdminState = {
  loading?: boolean;
  document?: OpenAPIV2.Document;
  pageList?: { data: any[]; opId: string };
  pageItem?: { data: any; opId: string; id: string };
  pageOpId?: string;
  subPageOpId?: string;
  opRowId?: string;
  hasErrors: boolean;
  saving?: boolean;
};

export const initialState: AdminState = {
  loading: true,
  hasErrors: false
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true;
    },
    loadSwaggerSuccess: (state, action: PayloadAction<OpenAPIV2.Document>) => {
      state.document = action.payload;
    },
    loadSwaggerFailure: (state, action: PayloadAction<any>) => {
      state.hasErrors = true;
    },
    loadPageItemsSuccess: (state, action: PayloadAction<{ pageId: string; items: any[] }>) => {
      state.pageList = { data: action.payload.items, opId: action.payload.pageId };
      state.pageOpId = action.payload.pageId;
    },
    loadPageItemsFailure: (state, action: PayloadAction<any>) => {
      state.hasErrors = true;
    },
    loadPageItemSuccess: (
      state,
      action: PayloadAction<{ pageId: string; subPageId?: string; rowId?: string; item: any }>
    ) => {
      state.pageItem = {
        data: action.payload.item,
        opId:
          action.payload.rowId && action.payload.subPageId
            ? action.payload.subPageId
            : action.payload.pageId,
        id: action.payload.rowId
          ? action.payload.rowId
          : action.payload.subPageId
          ? action.payload.subPageId
          : action.payload.pageId
      };
      state.pageOpId = action.payload.pageId;
      state.subPageOpId = action.payload.subPageId;
      state.opRowId = action.payload.rowId;
    },
    loadPageItemFailure: (state, action: PayloadAction<any>) => {
      state.hasErrors = true;
    },
    savePageItemSuccess: (state, action: PayloadAction<{ item: any }>) => {
      state.saving = false;
    },
    savePageItemFailure: (state, action: PayloadAction<any>) => {
      state.hasErrors = true;
      state.saving = false;
    },
    updatePageItem: (state, action: PayloadAction<{ item: any }>) => {
      state.pageItem = { ...state.pageItem!, data: cloneDeep(action.payload.item) };
    },
    savePageItemRunning: (state) => {
      state.saving = true;
    }
  }
});

export const {
  startLoading,
  loadSwaggerSuccess,
  loadSwaggerFailure,
  loadPageItemsSuccess,
  loadPageItemsFailure,
  loadPageItemSuccess,
  loadPageItemFailure,
  savePageItemSuccess,
  savePageItemFailure,
  updatePageItem,
  savePageItemRunning
} = adminSlice.actions;

export const adminSelector = (state: RootState) => state.admin;
const adminReducer = adminSlice.reducer;
export default adminReducer;

export const loadSwagger = (): AppThunk => async (dispatch: AppDispatch, getState) => {
  try {
    const document = await getSwagger(
      `${process.env.PUBLIC_URL}/assets/swaggerAdmin.json${backendPool}`
    );

    dispatch(loadSwaggerSuccess(document));
  } catch (error) {
    dispatch(loadSwaggerFailure(error));
  }
};

export const loadPageItems =
  (operationId: string): AppThunk =>
  async (dispatch: AppDispatch, getState, { apiClient }) => {
    try {
      const { admin } = getState();
      if (admin.document) {
        const results = await getDynamicData(
          apiClient,
          dispatch,
          admin.document,
          {
            operationId,
            parameters: {}
          } as any,
          {}
        );
        dispatch(loadPageItemsSuccess({ pageId: operationId, items: results.data }));
      }
    } catch (error) {
      dispatch(loadPageItemsFailure(error));
    }
  };

export const savePageItem =
  (dynamicOperation: AdminOperation, data: any, rowId?: string): AppThunk =>
  async (dispatch: AppDispatch, getState, { apiClient }) => {
    try {
      dispatch(savePageItemRunning());

      const { admin } = getState();

      if (admin.document) {
        const actionDefinition = getDefinitionByOperationId(
          admin.document,
          dynamicOperation.operationId
        );

        let parameters;
        parameters = mapToBackendModel(actionDefinition!.definition, data);

        const results = await getDynamicData(
          apiClient,
          dispatch,
          admin.document,
          {
            operationId: dynamicOperation.operationId,
            "value-path": "",
            "value-title": "",
            parameters: {}
          },
          parameters
        );
        dispatch(savePageItemSuccess({ item: results.data }));
      }
    } catch (error) {
      dispatch(savePageItemFailure(error));
    }
  };

export const deletePageItem =
  (dynamicOperation: AdminOperation, rowId: string): AppThunk =>
  async (dispatch: AppDispatch, getState, { apiClient }) => {
    try {
      const { admin } = getState();

      if (admin.document) {
        // const actionDefinition = getDefinitionByOperationId(
        //   admin.document,
        //   dynamicOperation.operationId
        // );

        const parameters = { Id: rowId };

        const results = await getDynamicData(
          apiClient,
          dispatch,
          admin.document,
          {
            operationId: dynamicOperation.operationId,
            "value-path": "",
            "value-title": "",
            parameters: {}
          },
          parameters
        );
        dispatch(savePageItemSuccess({ item: results.data }));
      }
    } catch (error) {
      dispatch(savePageItemFailure(error));
    }
  };

export const loadPageItem =
  (pageId: string, subPageId?: string, rowId?: string): AppThunk =>
  async (dispatch: AppDispatch, getState, { apiClient }) => {
    try {
      const { admin } = getState();
      if (admin.document) {
        if (rowId && subPageId) {
          const results = await getDynamicData(
            apiClient,
            dispatch,
            admin.document,
            {
              operationId: subPageId,
              "value-path": "",
              "value-title": "",
              parameters: {}
            },
            { Id: rowId }
          );
          dispatch(
            loadPageItemSuccess({
              pageId: pageId,
              subPageId: subPageId,
              rowId,
              item: mapToClientModel(admin.document, subPageId, results)
            })
          );
        } else if (subPageId) {
          /**
           * @todo: Modified to allow page load from different operation. Changed to 'subPageId'
           */
          const results = await getDynamicData(
            apiClient,
            dispatch,
            admin.document,
            {
              operationId: subPageId,
              "value-path": "",
              "value-title": "",
              parameters: {}
            },
            { Id: subPageId }
          );
          dispatch(
            loadPageItemSuccess({
              pageId,
              subPageId: subPageId,
              item: mapToClientModel(admin.document, subPageId, results)
            })
          );
        } else {
          const results = await getDynamicData(
            apiClient,
            dispatch,
            admin.document,
            { operationId: pageId, "value-path": "", "value-title": "", parameters: {} },
            { Id: pageId }
          );
          dispatch(
            loadPageItemSuccess({
              pageId,
              item: mapToClientModel(admin.document, pageId, results)
            })
          );
        }
      }
    } catch (error) {
      dispatch(loadPageItemFailure(error));
    }
  };

const handleDynamicConfig = (param: any) => {
  const cleanData = param?.value.data.map((parameter: any) => {
    const cleanParam: any = {};
    Object.entries(parameter).forEach(([key, value]) => {
      if (key === "type" || key === "title") {
        cleanParam[key] = value;
      } else if ((value as any).value) {
        cleanParam[key] = handleDynamicConfig(parameter[key]);
      } else if (typeof value === "object") {
        cleanParam[key] = { data: (value as any).data };
      }
    });
    return cleanParam;
  });
  let cleanParam = { ...param, value: { data: cleanData } };
  return cleanParam;
};

const mapToBackendModel = (
  definition: OpenAPIV2.OperationObject,
  data: { definition: string; id: string; parameters: { name: string; type: string; value: any }[] }
) => {
  let parameterSchemas: { name: string; schema: any }[] = (definition.parameters as any[]).filter(
    (p: any) => p.in === "body"
  );
  let payload: any = {};

  /**
   * Iterate payloads, eg. url, path, body.
   */
  parameterSchemas.forEach((ps) => {
    /**
     * Iterate 'properties', members of the desired payload.
     */
    const params: { name: string; value: any }[] = recursiveParameterMapping(
      ps.schema.properties,
      data
    );

    payload = recursivePayloadMapping(ps.schema, ps.name, params);
  });

  return payload;
};

const recursivePayloadMapping = (
  paramSchema: any,
  paramSchemaName: string,
  params: { name: string; value: any }[]
) => {
  let payload: any = {};
  payload[paramSchemaName] = params.reduce<any>((collector, value) => {
    /**
     * In the form, a property that is an object, has all it's properties stored at the same level of the hierarchy.
     * Therefore we need to add this recursively, by calling this method again, with the object's schema, but same data object.
     */
    if (Array.isArray(value.value) && paramSchema.properties[value.name].type === "object") {
      collector[value.name] = recursivePayloadMapping(
        paramSchema.properties[value.name],
        value.name,
        value.value
      )[value.name];
    } else {
      collector[value.name] = value.value;
    }
    return collector;
  }, {});

  return payload;
};

const recursiveParameterMapping = (
  properties: any,
  data: { definition: string; id: string; parameters: { name: string; type: string; value: any }[] }
): any => {
  const params = Object.keys(properties).map((paramName: string) => {
    if (
      properties[paramName].type === "object" &&
      properties[paramName].properties &&
      !isProcessExpressionModel(properties[paramName])
    ) {
      return {
        name: paramName,
        value: recursiveParameterMapping(properties[paramName].properties, data)
      };
    }
    const paramIndex = data.parameters.findIndex((p) => p.name === paramName);

    return {
      name: paramName,
      value: getParameterValue(data.parameters[paramIndex].value, properties[paramName], paramName)
    };
  });

  return params;
};

const mapToClientModel = (
  document: OpenAPIV2.Document<{}>,
  operationId: string,
  results: {
    data: any;
    parameters: string[];
    endpoint?: string | undefined;
  }
) => {
  const operation = getDefinitionByOperationId(document, operationId);
  if (operation && !results.data?.["parameters"] && !Array.isArray(results.data)) {
    const configuration: AdminConfiguration = {
      id: uuid(),
      definition: operationId,
      parameters: []
    };

    const responseObj = operation.definition.responses.default as OpenAPIV2.ResponseObject;
    const properties = (responseObj?.schema as any)?.properties;
    Object.keys(properties!).forEach((paramName) => {
      const refParam = properties![paramName];
      if (
        refParam.type === "object" &&
        refParam["x-workpoint-model-type"] !== "dictionary" &&
        !isProcessExpressionModel(refParam)
      ) {
        Object.keys(refParam.properties).forEach((pName) => {
          setParameterValue(
            pName,
            refParam.properties as OpenAPIV2.Parameter,
            results.data[paramName],
            configuration
          );
        });
      } else {
        setParameterValue(paramName, refParam as OpenAPIV2.Parameter, results.data, configuration);
      }
    });

    return configuration;
  }

  // Returns json configuration from backend
  return results.data;
};

const setParameterValue = (
  name: string,
  parameter: OpenAPIV2.Parameter,
  data: any,
  configuration?: AdminConfiguration
) => {
  const type = getFormControlType(parameter);

  let value: any = null;
  let isExp: boolean = isProcessExpressionModel(parameter);

  if (isExp) {
    value = data[name];
  } else if (parameter["x-workpoint-model-type"] === "dictionary") {
    /**
     * Dictionary cases
     */
    let pseudoArrayValue = [];
    if (data) {
      for (const [key, value] of Object.entries(data[name])) {
        pseudoArrayValue.push({ key, value });
      }

      value = pseudoArrayValue.map((obj: any) => {
        return {
          value1: { data: obj.key },
          value2: { data: obj.value },
          type: name
        };
      });
    }
  } else if (
    parameter["x-workpoint-dynamic-configuration"] &&
    parameter?.items?.type === "object"
  ) {
    value = data?.[name]?.map((element: any) => {
      const preparedElement: any = {};
      /**
       * If multiple dynamic configs are possibles, the type property must be defined in the swagger.
       * If no type property is available, the first dynamic configuration property is always chosen.
       */
      preparedElement["type"] =
        element.type ?? Object.keys(parameter["x-workpoint-dynamic-configuration"])[0];

      Object.entries(element).forEach(([key, value]) => {
        if (value) {
          const refParameter = parameter["x-workpoint-dynamic-configuration"][
            preparedElement.type
          ].parameters.find((p: any) => p.name === key);
          if (!refParameter) return;
          const paramValue = setParameterValue(
            key,
            refParameter as OpenAPIV2.Parameter,
            element,
            undefined
          );
          preparedElement[key] = refParameter["x-workpoint-dynamic-configuration"]
            ? { value: { data: paramValue }, rules: [] }
            : { data: paramValue, rules: [] };
        }
      });

      return preparedElement;
    });
  } else if (parameter["x-ms-dynamic-values"]) {
    value =
      !Array.isArray(data?.[name]) && typeof data?.[name] !== "object"
        ? `${data?.[name]}`
        : data?.[name];
  } else if (Array.isArray(data?.[name])) {
    value = data[name]?.map((item: any) => {
      if (typeof item === "string") {
        return item;
      }
      return {
        ...item,
        name: item.name ?? item.key,
        key: item.key ?? item.name
      };
    });
  } else if (parameter.type === "object") {
    value = {};
    Object.entries(parameter?.properties).forEach(([k, v]) => {
      value[k] = { value: { data: setParameterValue(k, v as any, data[name], undefined) } };
    });
  } else if (type === FormControlType.Dropdown) {
    if ((parameter as any).properties) {
      const properties = (parameter as any).properties;
      Object.keys(properties!).forEach((paramName) => {
        const refParam = properties![paramName];
        setParameterValue(paramName, refParam as OpenAPIV2.Parameter, data, configuration);
      });
      return;
    } else if ((parameter as any).enum) {
      /**
       * It is required that the enum is converted to its name string value.
       */
      value = `${data?.[name]}`;
    } else {
      let normalizedValue = handleFormEmptyValue(data?.[name]);
      value = normalizedValue ? `${normalizedValue}` : normalizedValue;
    }
  } else {
    value = data?.[name];
  }

  if (!configuration) return value;
  configuration.parameters.push({
    name,
    type: ConfigurationParameterType.Static,
    value: isExp
      ? value
      : {
          data: value
        }
  });
};

/**
 * Handles certain 'empty' cases, such as:
 * - Non-initialized Guid
 * - Null
 * - Undefined
 *
 * @param value form initial value from data source
 * @returns defined value or undefined
 */
const handleFormEmptyValue = (value: any): any | undefined => {
  /**
   * Handle Nullish values
   */
  const definedValue = value ?? undefined;

  if (!!definedValue) {
    /**
     * Empty Guid handling
     */
    if (definedValue === "00000000-0000-0000-0000-000000000000") return undefined;
  }

  return definedValue;
};

export const getParameterValue = (
  value: ExpressionDescriptor,
  parameter: OpenAPIV2.Parameter,
  parameterName: string
) => {
  const type: FormControlType = getFormControlType(parameter);
  if (isProcessExpressionModel(parameter)) {
    return value;
  } else if (parameter["x-workpoint-model-type"] === "dictionary") {
    /**
     * Dictionary handling, where value1 is always the identifier.
     */
    let returnValue: any = {};

    const [param1, param2] = parameter["x-workpoint-dynamic-configuration"][parameterName][
      "parameters"
    ].reduce((collector: string[], current: any) => {
      collector.push(current.name);
      return collector;
    }, []);

    for (const kv of value.data) {
      returnValue[kv[param1].data] = kv[param2].data;
    }

    return returnValue;
  }

  if (Array.isArray(value.data)) {
    return value.data?.map((object: any) => {
      if (!parameter?.items?.properties) {
        return evaluateProcessExpressionLimited(
          ensureSingleQuotesValueBeforeEvaluation(object.name ?? object)
        );
      } else {
        /**
         * When array contains an object with mutliple expressions,
         * we have to ensure that each property has single qoutes so it can be evaluated.
         */
        const cleanedExp: any = cleanParamValue(parameter.items.properties, object);
        const preparedExp: any = {};
        Object.entries(cleanedExp).forEach(([key, value]) => {
          preparedExp[key] = evaluateProcessExpressionLimited(
            ensureSingleQuotesValueBeforeEvaluation(value as any)
          );
        });
        return preparedExp;
      }
    });
  }

  if (!value.data) {
    return getDefaultValue(parameter.type);
  }

  return value.data;
};

const getDefaultValue = (type: string) => {
  switch (type) {
    case "array":
      return [];
    case "boolean":
      return false;
    default:
      return "";
  }
};

/**
 * Returns an object that has removed any properties that are not part of the model.
 * @param model model from swagger, which we want to save as.
 * @param paramValue value to clean so that it fits the model.
 */
const cleanParamValue = (model: any, paramValue: any) => {
  let cleanedData: any = {};

  Object.entries(model).forEach(([key, value]) => {
    if (paramValue[key]) {
      switch ((value as any).type) {
        case "array":
          if ((value as any).items.type === "object") {
            let cleanArray: any[] = [];
            paramValue[key]?.value?.data?.forEach((p: any) => {
              cleanArray.push(cleanParamValue((value as any).items.properties, p));
            });
            cleanedData[key] = cleanArray;
          } else {
            cleanedData[key] = paramValue[key];
          }
          break;
        default:
          cleanedData[key] = paramValue[key];
          break;
      }
    }
  });
  return cleanedData;
};

const ensureSingleQuotesValueBeforeEvaluation = (expressionCandidate: {
  data?: any;
  expression?: any;
  rules?: ExpressionDescriptorRule[];
}) => {
  let outputValue = expressionCandidate.data ?? expressionCandidate;

  if (
    typeof outputValue === "string" &&
    outputValue.indexOf("'") !== 0 &&
    outputValue.lastIndexOf("'") !== outputValue.length - 1
  ) {
    return { ...expressionCandidate, data: `'${outputValue}'` };
  } else if (typeof outputValue === "string") {
    return { ...expressionCandidate, data: `${outputValue}` };
  }

  /**
   * Ensure single qoutes for data values when using x-workpoint-dynamic-configuration
   * that contains text fields.
   */
  if (Array.isArray(outputValue) && typeof outputValue[0] === "object") {
    outputValue = outputValue.map((val) => {
      let singleQoutes: any = {};
      Object.entries(val).forEach(([key, value]) => {
        singleQoutes[key] = ensureSingleQuotesValueBeforeEvaluation(value as any);
      });
      return singleQoutes;
    });
  }

  return { ...expressionCandidate, data: outputValue };
};
