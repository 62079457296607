import SwaggerParser from "@apidevtools/swagger-parser";
import $RefParser from "@apidevtools/json-schema-ref-parser";
import { MessageBarType } from "@fluentui/react";
import { OpenAPIV2 } from "openapi-types";
import { showMessage } from "../store/dialogReducer";
import { AppDispatch } from "../store/store";
import { ApiClient } from "@workpoint/components/lib/clients/ApiClient";

export interface OperationDefinition {
  endpoint: string;
  method: string;
  definition: OpenAPIV2.OperationObject;
}

export enum OperationMethod {
  Get = "GET",
  Post = "POST",
  Put = "PUT",
  Delete = "DELETE"
}

export interface DynamicDataDefinition {
  operationId: string;
  "value-path": string;
  "value-title": string;
  parameters: { [key: string]: { parameter: string } };
}

export const getSwagger = async (path: string): Promise<OpenAPIV2.Document> => {
  const parser = new SwaggerParser();
  //const obj = await parser.parse(path) as OpenAPIV2.Document;
  const obj = (await parser.dereference(path, {
    dereference: { circular: "ignore" }
  })) as OpenAPIV2.Document;

  return obj;
};

export const parseSchema = async (schema: any): Promise<$RefParser.JSONSchema> => {
  const obj = await $RefParser.dereference(schema, {
    dereference: { circular: "ignore" }
  });

  return obj;
};

export const getAllOperations = (document: OpenAPIV2.Document) => {
  const actions = [];
  for (const [key] of Object.entries(document.paths)) {
    const value = document.paths[key]!;
    if (value.get) {
      actions.push(value.get);
    }
    if (value.post) {
      actions.push(value.post);
    }
    if (value.put) {
      actions.push(value.put);
    }
    if (value.delete) {
      actions.push(value.delete);
    }
  }

  return actions;
};

export const getDefinitionByOperationId = (
  document: OpenAPIV2.Document,
  operationId: string
): OperationDefinition | undefined => {
  for (const [key] of Object.entries(document.paths)) {
    const value = document.paths[key]!;
    if (value.get && value.get.operationId?.toLowerCase() === operationId.toLowerCase()) {
      return { endpoint: key, method: OperationMethod.Get, definition: value.get as any };
    }
    if (value.post && value.post.operationId?.toLowerCase() === operationId.toLowerCase()) {
      return { endpoint: key, method: OperationMethod.Post, definition: value.post as any };
    }
    if (value.put && value.put.operationId?.toLowerCase() === operationId.toLowerCase()) {
      return { endpoint: key, method: OperationMethod.Put, definition: value.put as any };
    }
    if (value.delete && value.delete.operationId?.toLowerCase() === operationId.toLowerCase()) {
      return { endpoint: key, method: OperationMethod.Delete, definition: value.delete as any };
    }
  }

  return undefined;
};

const apiData: { [key: string]: { data: any; parameters: string[]; endpoint?: string } } = {};

export const getDynamicData = async (
  apiClient: ApiClient,
  dispatch: AppDispatch,
  document: OpenAPIV2.Document,
  dynamicDataDefinition: DynamicDataDefinition,
  data: any,
  solutionUrl?: string
): Promise<{ data: any; parameters: string[]; endpoint?: string }> => {
  const parameters: string[] = [];
  const operation = getDefinitionByOperationId(document, dynamicDataDefinition.operationId);
  if (operation) {
    // TODO post, put, delete
    let endpoint = operation.endpoint;
    if (endpoint.startsWith("/api/")) {
      endpoint = endpoint.substring(4);
    }

    let bodyParam: any = undefined;
    let isParameterValueMissing = false;
    if (operation.definition.parameters) {
      const pathQueryParams: string[] = [];
      for (let i = 0; i < operation.definition.parameters.length; i++) {
        const param = operation.definition.parameters[i] as OpenAPIV2.Parameter;
        const valueParameterName =
          dynamicDataDefinition.parameters[param.name]?.parameter ?? param["x-workpoint-parameter"];
        if (param.in === "path" || param.in === "query") {
          if (valueParameterName) {
            parameters.push(valueParameterName);
            if (param.required && !data[valueParameterName]) {
              isParameterValueMissing = true;
            } else {
              if (endpoint.indexOf(`{${param.name}}`) >= 0) {
                endpoint = endpoint.replace(
                  `{${param.name}}`,
                  encodeURIComponent(data[valueParameterName])
                );
              } else {
                pathQueryParams.push(
                  param.name + "=" + encodeURIComponent(data[valueParameterName])
                );
              }
            }
          }
        } else if (param.in === "body") {
          bodyParam = valueParameterName ? data[valueParameterName] : data[param.name];
        }
      }

      if (pathQueryParams.length > 0) {
        endpoint += "?" + pathQueryParams.join("&");
      }
    }

    if (!isParameterValueMissing) {
      try {
        switch (operation.method.toLowerCase()) {
          case "get":
            if (apiData[endpoint]) {
              return apiData[endpoint];
            }
            const getResult = await apiClient.getWP(endpoint, solutionUrl);
            apiData[endpoint] = { data: getResult, parameters, endpoint };
            return apiData[endpoint];
          case "post":
            const postResult = await apiClient.postWP(endpoint, bodyParam, undefined, solutionUrl);
            return { data: postResult, parameters, endpoint };
          case "put":
            const putResult = await apiClient.putWP(endpoint, bodyParam, undefined, solutionUrl);
            return { data: putResult, parameters, endpoint };
          case "delete":
            const delResult = await apiClient.deleteWP(endpoint, undefined, solutionUrl);
            return { data: delResult, parameters, endpoint };
          default:
            return { data: undefined, parameters, endpoint };
        }
      } catch (error) {
        dispatch(
          showMessage({
            type: MessageBarType.error,
            text: `API call '${endpoint}' failed: ${error.message}`
          })
        );
      }
      return { data: undefined, parameters };
    }
  }

  return { data: undefined, parameters };
};
