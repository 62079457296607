import React, { useEffect, useState } from "react";
import { FieldDefinition } from "./ObjectData";
import { useBoolean } from "@fluentui/react-hooks";
import { Callout, IconButton, INavLink, Nav, ProgressIndicator, Stack } from "@fluentui/react";
import styled from "styled-components";
import { ControlledTextField } from "../form/ControlledTextField";
import { Control, FieldValues, UseFormSetValue } from "react-hook-form";

interface ObjectBrowserProps {
  object: any;
  loading?: boolean;
  onSelect: (propertyPath: string, type?: string) => void;
  fullHeight?: boolean;
}

export const ObjectBrowser = (props: ObjectBrowserProps) => {
  const [groups, setGroups] = useState<INavLink[]>([]);

  useEffect(() => {
    try {
      const dataStructure = FieldDefinition.deriveFrom(props.object);
      const grs = getGroups(dataStructure.children, "", 0);
      setGroups(grs);
    } catch {}
  }, [props.object]);

  const getGroups = (
    fieldDefinitions: FieldDefinition[],
    parentKey: string,
    level: number
  ): INavLink[] => {
    const groups: INavLink[] = [];
    fieldDefinitions?.forEach((fd) => {
      let name = fd.displayName ?? fd.name;
      const type = name.endsWith("[0]") ? "Array" : fd.valueType;
      const group: INavLink = {
        key: `${parentKey}${parentKey ? "." : ""}${
          fd.name.endsWith("[0]") ? fd.name.substring(0, fd.name.length - 3) : fd.name
        }`,
        name: `${name.endsWith("[0]") ? name.substring(0, name.length - 3) : name} [${type}]`,
        url: "",
        links: [],
        data: fd,
        onClick: (
          ev?: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
          item?: INavLink | undefined
        ) => {
          props.onSelect(item?.key ?? "", item?.data.valueType);
        }
      };
      groups.push(group);
      group.links!.push(
        ...getGroups(
          fd.children,
          `${parentKey}${parentKey ? "." : ""}${fd.name}${
            type === "Array" && !fd.name.endsWith("[0]") ? "[0]" : ""
          }`,
          level + 1
        ).sort((a, b) => a.name.localeCompare(b.name))
      );
    });

    return groups;
  };

  return (
    <Container fullHeight={props.fullHeight ? props.fullHeight : false}>
      <LoadingContainer>
        {props.loading && <ProgressIndicator label="" description="" />}
      </LoadingContainer>
      <Nav
        styles={{ root: { width: "300px" } }}
        groups={[
          {
            links: groups
          }
        ]}
      />
    </Container>
  );
};

const LoadingContainer = styled.div`
  height: 2px;
`;

interface ObjectBrowserButtonProps {
  control: Control<FieldValues>;
  setValue: UseFormSetValue<any>;
  name: string;
  label: string;
  object: any;
  loading?: boolean;
}

export const ControlledObjectBrowser = (props: ObjectBrowserButtonProps) => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);

  const calloutTargetId = `calloutTarget-${props.name.replaceAll(/\[|]|\./g, "-")}`;
  return (
    <ControlledObjectBrowserStack horizontal>
      <Stack.Item grow>
        <ControlledTextField
          name={props.name}
          label={props.label}
          control={props.control}
          required={false}
        />
      </Stack.Item>
      <Stack.Item>
        <IconButton
          id={`${calloutTargetId}`}
          iconProps={{ iconName: "Dataflows" }}
          onClick={toggleIsCalloutVisible}
        />
        {isCalloutVisible && (
          <Callout target={`#${calloutTargetId}`} onDismiss={toggleIsCalloutVisible}>
            <div style={{ maxWidth: 400, maxHeight: 400 }}>
              <ObjectBrowser
                object={props.object}
                loading={props.loading}
                onSelect={(value: string) => {
                  props.setValue(props.name, value, {
                    shouldValidate: true
                  });
                  toggleIsCalloutVisible();
                }}
              />
            </div>
          </Callout>
        )}
      </Stack.Item>
    </ControlledObjectBrowserStack>
  );
};

type ContainerCustomProps = {
  fullHeight: boolean;
};

const ControlledObjectBrowserStack = styled(Stack)`
  display: flex;
  align-items: flex-end;
`;

const Container = styled.div<ContainerCustomProps>`
  height: ${(fullHeight) => (fullHeight ? "100%" : "356px")};
  overflow: auto;
`;
