import {
  DefaultButton,
  IButtonProps,
  IconButton,
  IRenderFunction,
  Stack,
  Text
} from "@fluentui/react";
import { OpenAPIV2 } from "openapi-types";
import { ReactNode, useState } from "react";
import styled from "styled-components";
import theme from "@workpoint/components/lib/constants";
import { evaluateExpressionForDisplay } from "@workpoint/components/lib/helpers/expressionUtils";
import { OrderDirection } from "@workpoint/components/lib/models/ProcessConfiguration";

const borderColor = "#edebe9";

export interface AccordionControlProps {
  definition: OpenAPIV2.Parameter;
  children?: ReactNode | ReactNode[];
  expanded?: boolean;
  modified?: boolean;
  showDelete?: boolean;
  showSorting?: boolean;
  onDelete?(id: string): void;
  onOrderChange?(id: string, direction: OrderDirection): void;
  title?: string;
}

export const AccordionControl = (props: AccordionControlProps) => {
  const {
    definition,
    children,
    modified,
    showDelete,
    showSorting,
    onDelete,
    onOrderChange,
    title
  } = props;
  const [expanded, setExpanded] = useState<boolean>(props.expanded ?? false);
  const buttonTitle = title ?? definition.title ?? definition["x-ms-summary"] ?? definition.name;
  const evaluatedTitle = evaluateExpressionForDisplay(buttonTitle, {});

  const _renderText = (): IRenderFunction<IButtonProps> => {
    return () => {
      return (
        <Stack horizontal style={{ width: "100%", alignItems: "center" }}>
          <Stack.Item>
            <Text style={{ fontWeight: "bold" }}>{evaluatedTitle}</Text>
          </Stack.Item>
          <Stack.Item grow> </Stack.Item>
          {modified && (
            <Stack.Item>
              <ModifiedTag>{"</>"} Modified</ModifiedTag>
            </Stack.Item>
          )}
        </Stack>
      );
    };
  };

  return (
    <>
      <Container expanded={expanded}>
        <AccordionButton
          toggle
          checked={expanded}
          text={buttonTitle}
          iconProps={
            expanded
              ? {
                  iconName: "ChevronUp",
                  style: {
                    backgroundColor: theme.palette.themePrimary,
                    padding: "10px",
                    color: theme.palette.white,
                    borderRadius: "4px",
                    height: "auto"
                  }
                }
              : { iconName: "ChevronDown", style: { padding: "10px", height: "auto" } }
          }
          onClick={() => {
            setExpanded(!expanded);
          }}
          expanded={expanded}
          onRenderText={_renderText()}
          styles={{ flexContainer: { width: "100%" }, textContainer: { marginLeft: "3px" } }}
        />
        {showDelete && (
          <IconButton
            iconProps={{ iconName: "Delete" }}
            onClick={() => onDelete!(definition.name)}
          />
        )}
        {showSorting && (
          <>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <SortingButton
                styles={{ icon: { fontSize: "10px" } }}
                iconProps={{ iconName: "ChevronUp" }}
                onClick={() => onOrderChange!(definition.name, OrderDirection.Up)}
              />
              <SortingButton
                styles={{ icon: { fontSize: "10px" } }}
                iconProps={{ iconName: "ChevronDown" }}
                onClick={() => onOrderChange!(definition.name, OrderDirection.Down)}
              />
            </div>
          </>
        )}
      </Container>
      {expanded && children}
    </>
  );
};

const SortingButton = styled(IconButton)``;

const Container = styled.div<{ expanded: boolean }>`
  width: 100%;
  border: 1px solid ${borderColor};
  border-left: 0px;
  border-right: 0px;
  border-bottom: ${(props) => (props.expanded ? "0px" : "1px")};
  display: flex;
  justify-content: flex-start;
  height: 50px;
  color: ${theme.palette.blue};
  align-items: center;
`;

const AccordionButton = styled(DefaultButton)<{ expanded: boolean }>`
  border: 0px;
  width: 100%;
  height: 50px;
  padding-left: 5px;

  :enabled {
    background-color: white;
  }

  :hover {
    color: ${theme.palette.blue};
  }
`;

export const ModifiedTag = styled(Text)`
  color: ${theme.palette.themePrimary};
  border-radius: 4px;
  background-color: rgba(247, 148, 30, 0.4);
  margin-right: 3px;
  padding: 5px;
`;

export const AccordionListContainer = styled.div`
  margin-top: 20px;
  border: 1px solid ${borderColor};
  border-top: 0px;
`;
