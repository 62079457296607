import { Icon, IStyleFunctionOrObject, Stack, StackItem } from "@fluentui/react";
import { IPersonaProps } from "@fluentui/react/lib/Persona";
import {
  IBasePickerStyleProps,
  IBasePickerStyles,
  IBasePickerSuggestionsProps,
  NormalPeoplePicker
} from "@fluentui/react/lib/Pickers";
import * as React from "react";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { ApiClient } from "@workpoint/components/lib/clients/ApiClient";
import { useApiClient } from "@workpoint/components/lib/clients/ApiProvider";
import { FormControlProps } from "./ControlledTextField";
import { unpackErrors } from "./ErrorMessage";

export interface IControlledPeoplePickerProps {
  solutionUrl: string;
}

const onFilterChanged = async (
  apiClient: ApiClient,
  filterText?: string,
  solutionUrl?: string
): Promise<IPersonaProps[]> => {
  if (filterText) {
    let peopleResults = await apiClient.getPeople({
      webUrl: solutionUrl,
      principalType: 6,
      maximumEntitySuggestions: 10,
      queryString: filterText
    });

    // Filtering away the "Everyone except external users group", since we cannot resolve this group via graph. See #33576 for details.
    peopleResults = peopleResults.filter(
      (people) => !people["Key"].startsWith("c:0-.f|rolemanager|spo-grid-all-users")
    );

    let personas: IPersonaProps[] = peopleResults.map((p) => ({
      text: p.DisplayName,
      key: p.Key
    }));

    return personas;
  } else {
    return [];
  }
};

const getDefaultValue = (value: any): IPersonaProps[] => {
  if (value) {
    let valueAsArray = value;

    if (!Array.isArray(value)) valueAsArray = [value];

    return valueAsArray.map((v: any) => ({ key: v.Identifier, text: v.Name }));
  }

  return [];
};

const mapSingularValue = (items: any[]) => {
  return items?.map((i) => ({ Name: i.text, Identifier: i.key }))[0];
};

export const ControlledPeoplePicker: React.FC<FormControlProps & IControlledPeoplePickerProps> = (
  props
) => {
  const picker = React.useRef(null);
  const intl = useIntl();

  const suggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: intl.formatMessage({
      id: "suggested-groups",
      defaultMessage: "Suggested groups"
    }),
    noResultsFoundText: intl.formatMessage({
      id: "no-results-found",
      defaultMessage: "No results found"
    }),
    loadingText: intl.formatMessage({
      id: "loading",
      defaultMessage: "Loading"
    }),
    showRemoveButtons: true
  };

  const { apiClient } = useApiClient();

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || undefined}
      render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => {
        const changeCallback = (items?: IPersonaProps[]) => {
          const singularValue = mapSingularValue(items as IPersonaProps[]);
          onChange(singularValue);
          onBlur();
        };

        const hasError = !!error;

        const errorStyle: IStyleFunctionOrObject<IBasePickerStyleProps, IBasePickerStyles> = {
          text: {
            borderColor: "#bababa",
            selectors: {
              hover: {
                borderColor: "#323130"
              }
            }
          },
          ...(hasError && {
            text: {
              borderColor: "#a4262c;"
            }
          })
        };

        return (
          <Stack>
            <StackItem>
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <StackItem align="center">
                  <label htmlFor={props.name}>
                    <Icon iconName="Group" />
                  </label>
                </StackItem>
                <StackItem grow>
                  <NormalPeoplePicker
                    styles={errorStyle}
                    onChange={changeCallback}
                    onResolveSuggestions={(filterText?: string) =>
                      onFilterChanged(apiClient, filterText, props.solutionUrl)
                    }
                    getTextFromItem={getTextFromItem}
                    pickerSuggestionsProps={suggestionProps}
                    defaultSelectedItems={getDefaultValue(value)}
                    className={"ms-PeoplePicker"}
                    key={props.name}
                    inputProps={{
                      id: props.name
                    }}
                    onBlur={onBlur}
                    componentRef={picker}
                    resolveDelay={300}
                    itemLimit={1}
                  />
                </StackItem>
              </Stack>
            </StackItem>

            {error && <StackItem>{unpackErrors(error)}</StackItem>}
          </Stack>
        );
      }}
    />
  );
};

function getTextFromItem(persona: IPersonaProps): string {
  return persona.text as string;
}
