import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncModelState } from "../../../models/AsyncModelState";
import { INotificationSettings, INotificationProfile } from "../../../models/NotificationSettings";
import { RequestState } from "../../../models/RequestState";
import { RootState, ThunkApiConfig } from "../../../store/store";

export interface INotificationState extends AsyncModelState {
  settings: INotificationSettings | undefined;
  saveStatus: RequestState | undefined;
  errorMessage: string | undefined;
}

const initialNotificationState: INotificationState = {
  settings: undefined,
  status: undefined,
  saveStatus: undefined,
  errorMessage: undefined
};

export const fetchNotificationSettingsForSolution = createAsyncThunk<
  INotificationSettings,
  void,
  ThunkApiConfig
>("notificationSettings/fetchForSolution", async (_, { rejectWithValue, extra: { apiClient } }) => {
  const notificationSettings = await apiClient.getWP(`/Notifications/NotificationSettings`);

  if (!notificationSettings) {
    rejectWithValue(notificationSettings);
  }

  return notificationSettings;
});

export const saveNotificationSettings = createAsyncThunk<
  INotificationSettings,
  { newSettings: INotificationSettings },
  ThunkApiConfig
>(
  "notificationSettings/saveForSolution",
  async ({ newSettings }, { rejectWithValue, extra: { apiClient } }) => {
    const response = await apiClient.putWP(
      `/Notifications/NotificationSettings`,
      JSON.stringify(newSettings)
    );

    if (!response) {
      rejectWithValue(response);
    }

    return response;
  }
);

const notificationSettingsSlice = createSlice({
  name: "notificationSettings",
  initialState: initialNotificationState,
  reducers: {
    setSettings: (state, action: PayloadAction<INotificationSettings>) => {
      state.settings = { ...action.payload };
    },
    /**
     * Set on 'save' on a notification profile modal with the set values.
     */
    addProfile: (state, action: PayloadAction<INotificationProfile>) => {
      state.settings?.NotificationProfiles.push(action.payload);
    },
    editProfile: (state, action: PayloadAction<INotificationProfile>) => {
      const profile = state.settings?.NotificationProfiles.find((p) => p.Id === action.payload.Id);
      if (profile) {
        Object.keys(action.payload).forEach((key: string) => {
          (profile as any)[key] = (action.payload as any)[key];
        });
      }
    },
    removeProfile: (state, action: PayloadAction<string>) => {
      if (state.settings) {
        state.settings.NotificationProfiles = state.settings?.NotificationProfiles.filter(
          (profile) => profile.Id !== action.payload
        );
      }
    },
    clearErrorMessage: (state) => {
      state.errorMessage = undefined;
    },
    /**
     * Reset the status, forcing Notification Settings page to reload the settings.
     */
    resetSettings: (state) => {
      state.status = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotificationSettingsForSolution.pending, (state, action) => {
      state.status = RequestState.pending;
    });
    builder.addCase(fetchNotificationSettingsForSolution.fulfilled, (state, action) => {
      state.status = RequestState.fulfilled;
      state.settings = action.payload;
    });
    builder.addCase(fetchNotificationSettingsForSolution.rejected, (state, action) => {
      if (action.error.message === "unprovisioned") state.status = RequestState.unprovisioned;
      else if (action.error.message === "unlicensed") state.status = RequestState.unlicensed;
      else state.status = RequestState.rejected;
      /**
       * Append additional error message.
       */
      if (typeof action.error?.message === "string" && action.error.message !== "")
        state.errorMessage = action.error.message;
    });
    builder.addCase(saveNotificationSettings.pending, (state, action) => {
      state.errorMessage = undefined;
      state.saveStatus = RequestState.pending;
    });
    builder.addCase(saveNotificationSettings.fulfilled, (state, { payload }) => {
      state.errorMessage = undefined;
      state.saveStatus = RequestState.fulfilled;
    });
    builder.addCase(saveNotificationSettings.rejected, (state, action) => {
      state.saveStatus = RequestState.rejected;
      state.errorMessage = action.error.message;
    });
  }
});

export const notificationSelector = (state: RootState) => state.notification.settings;
export const notificationStatusSelector = (state: RootState) => state.notification.status;
export const notificationSaveStatusSelector = (state: RootState) => state.notification.saveStatus;
export const notificationErrorMessageSelector = (state: RootState) =>
  state.notification.errorMessage;

export const notificationProfileSelector = (state: RootState, profileId: string) =>
  state.notification.settings?.NotificationProfiles.find((p) => p.Id === profileId);

export const {
  setSettings,
  addProfile,
  editProfile,
  removeProfile,
  clearErrorMessage,
  resetSettings
} = notificationSettingsSlice.actions;

const notificationReducer = notificationSettingsSlice.reducer;

export default notificationReducer;
