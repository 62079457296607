import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TermsResult } from "@workpoint/components/lib/models/DataRequests";
import {
  ProcessConfiguration,
  ProcessItem,
  ProcessLanguage,
  ProcessLocalization,
  ProcessStepConfiguration,
  ProcessStatus,
  ProcessParameterProperty,
  ExpressionDescriptorRule,
  ProcessType,
  ExpressionDescriptorValueType,
  ComparisonOperator,
  ProcessGroup,
  ProcessTrigger
} from "@workpoint/components/lib/models/ProcessConfiguration";
import { RootState, AppThunk, AppDispatch } from "./store";
import { backendPool, Lists } from "@workpoint/components/lib/constants";
import { OpenAPIV2 } from "openapi-types";
import { getDefinitionByOperationId, getSwagger, OperationDefinition } from "../utils/swaggerUtils";
import { v4 as uuid } from "uuid";
import { ToRoute } from "../routes";
import { ProcessValidationMessage } from "../models/ProcessValidation";
import {
  defaultProcessItem,
  hasPublishedVersion,
  isRulesEmpty,
  validateProcess
} from "../utils/processUtils";
import { cloneDeep } from "lodash";
import { ITermInfo } from "@pnp/sp/taxonomy";
import { IGroup, MessageBarType } from "@fluentui/react";
import { getJsonObjectFromFile, isEmpty } from "../utils/commonUtils";
import { trackEvent } from "@workpoint/components/lib/helpers/insights";
import { showDialog, showMessage } from "./dialogReducer";
import { NavigateFunction } from "react-router-dom";
import { ApiClient } from "@workpoint/components/lib/clients/ApiClient";
import {
  evaluateExpressionForDisplay,
  evaluateProcessExpressionForDisplay
} from "@workpoint/components/lib/helpers/expressionUtils";
import {
  DefaultErrorMessage,
  DialogResult,
  DialogType,
  WorkingOnIt
} from "../components/common/Dialog";
import { addItemToList, ListViewData } from "../components/common/listView/ListView";

export type ProcessState = {
  loading: boolean;
  saving: boolean;
  buttonName?: string;
  deleting: boolean;
  downloading: boolean;
  hasErrors: boolean;
  process: ProcessItem;
  step?: {
    processId: string;
    configuration: ProcessStepConfiguration | ProcessTrigger | undefined;
    definition: OpenAPIV2.OperationObject | undefined;
  };
  document: OpenAPIV2.Document;
  validationMessages: ProcessValidationMessage[];
  groups?: { groups: IGroup[]; items: ITermInfo[] };
  templates?: ProcessConfiguration[];
  termGroupId?: string;
  termSetId?: string;
  accounts?: ProcessAccounts;
  loadingAccounts?: boolean;
  spLocaleId?: number;
  processList?: ListViewData;
  applicationConsent?: string[];
  delegatedConsent?: string[];
  loadingApplicationConsent?: boolean;
  consentUrlForSavingTeamsChatMessages?: string;
};

export interface ProcessAccounts {
  consentUrl: string;
  serviceUserAccount: string;
  serviceUserConsentUrl: string;
  accounts: string[];
}

export const initialState: ProcessState = {
  saving: false,
  loading: false,
  deleting: false,
  downloading: false,
  hasErrors: false,
  process: undefined as any,
  document: undefined as any,
  validationMessages: []
};

// A slice
const processSlice = createSlice({
  name: "process",
  initialState,
  reducers: {
    updateProcess: (state, action: PayloadAction<ProcessItem>) => {
      state.process = action.payload;
    },
    clearErrors: (state) => {
      state.hasErrors = false;
    },
    startLoading: (state) => {
      state.loading = true;
    },
    startGetProcess: (state) => {
      state.loading = true;
      state.process = undefined as any;
      state.validationMessages = [];
      state.hasErrors = false;
    },
    getProcessSuccess: (
      state,
      action: PayloadAction<{
        item: ProcessItem;
        document: OpenAPIV2.Document;
      }>
    ) => {
      state.process = action.payload.item;
      state.document = action.payload.document;
      state.validationMessages = validateProcess(
        state.document,
        state.process.configuration,
        state.accounts,
        state.loadingAccounts
      );
      state.loading = false;
      state.hasErrors = false;
    },
    getProcessFailure: (state) => {
      state.process = undefined as any;
      state.loading = false;
      state.hasErrors = true;
    },
    getAccountSuccess: (
      state,
      action: PayloadAction<{
        accounts: ProcessAccounts;
      }>
    ) => {
      state.accounts = action.payload.accounts;
      state.loadingAccounts = false;
    },
    getAccountFailure: (state) => {
      state.loadingAccounts = false;
    },
    saveProcessSuccess: (state, action: PayloadAction<ProcessItem>) => {
      state.saving = false;
      state.hasErrors = false;
      state.process = action.payload;
    },
    startSaving: (state, action: PayloadAction<string>) => {
      state.saving = true;
      state.buttonName = action.payload;
    },
    savingSuccess: (state) => {
      state.saving = false;
    },
    saveProcessFailure: (state) => {
      state.saving = false;
      state.hasErrors = true;
    },
    startDeleting: (state) => {
      state.deleting = true;
    },
    deleteSuccess: (state) => {
      state.deleting = false;
      state.hasErrors = false;
    },
    deletionFailure: (state) => {
      state.deleting = false;
      state.hasErrors = true;
    },
    getStep: (state, action: PayloadAction<string>) => {
      //if (!state.step || )
      const configuration = state.process?.configuration?.steps[parseInt(action.payload)];
      let operation: OperationDefinition | undefined;
      if (configuration) {
        operation = getDefinitionByOperationId(state.document!, configuration.definition);
      }

      state.step = {
        processId: state.process.configuration.id,
        configuration,
        definition: operation?.definition
      };
    },
    newStep: (
      state,
      action: PayloadAction<{ conf: ProcessStepConfiguration; rowIndex: string }>
    ) => {
      state.process?.configuration?.steps.splice(
        +action.payload.rowIndex + 1,
        0,
        action.payload.conf
      );
      state.validationMessages = validateProcess(
        state.document,
        state.process.configuration,
        state.accounts,
        state.loadingAccounts
      );
    },
    updateStep: (state, action: PayloadAction<ProcessStepConfiguration>) => {
      const rowIndex = state.process.configuration.steps.findIndex(
        (s) => s.id === action.payload.id
      );
      if (rowIndex >= 0) {
        state.process.configuration.steps[rowIndex] = action.payload;
      }
      state.step!.configuration = action.payload;
      state.validationMessages = validateProcess(
        state.document,
        state.process.configuration,
        state.accounts,
        state.loadingAccounts
      );
    },
    deleteStep: (state, action: PayloadAction<ProcessStepConfiguration>) => {
      const rowIndex = state.process.configuration.steps.findIndex(
        (s) => s.id === action.payload.id
      );
      if (rowIndex >= 0) {
        state.process.configuration.steps.splice(rowIndex, 1);
      }
      state.validationMessages = validateProcess(
        state.document,
        state.process.configuration,
        state.accounts,
        state.loadingAccounts
      );
    },
    getTrigger: (state, action: PayloadAction<string>) => {
      const configuration = state.process?.configuration?.triggers?.[parseInt(action.payload)];
      let operation: OperationDefinition | undefined;
      if (configuration) {
        operation = getDefinitionByOperationId(state.document!, configuration.type);
      }

      state.step = {
        processId: state.process.configuration.id,
        configuration,
        definition: operation?.definition
      };
    },
    newTrigger: (state, action: PayloadAction<{ trigger: ProcessTrigger; rowIndex: string }>) => {
      if (state.process?.configuration) {
        state.process?.configuration?.triggers
          ? state.process?.configuration?.triggers?.splice(
              +action.payload.rowIndex + 1,
              0,
              action.payload.trigger
            )
          : (state.process.configuration.triggers = [action.payload.trigger]);
        state.validationMessages = validateProcess(
          state.document,
          state.process.configuration,
          state.accounts,
          state.loadingAccounts
        );
      }
    },
    updateTrigger: (state, action: PayloadAction<ProcessTrigger>) => {
      const rowIndex = state.process.configuration.triggers!.findIndex(
        (s) => s.id === action.payload.id
      );
      if (rowIndex >= 0 && state.process.configuration.triggers) {
        state.process.configuration.triggers[rowIndex] = action.payload;
      }
      state.step!.configuration = action.payload;
      state.validationMessages = validateProcess(
        state.document,
        state.process.configuration,
        state.accounts,
        state.loadingAccounts
      );
    },
    deleteTrigger: (state, action: PayloadAction<ProcessTrigger>) => {
      const rowIndex = state.process.configuration.triggers!.findIndex(
        (s) => s.id === action.payload.id
      );
      if (rowIndex >= 0) {
        state.process.configuration.triggers!.splice(rowIndex, 1);
      }
      state.validationMessages = validateProcess(
        state.document,
        state.process.configuration,
        state.accounts,
        state.loadingAccounts
      );
    },
    updateConfiguration: (state, action: PayloadAction<ProcessConfiguration>) => {
      state.process.configuration = action.payload;
      state.validationMessages = validateProcess(
        state.document,
        state.process.configuration,
        state.accounts,
        state.loadingAccounts
      );
    },
    updateLocalization: (state, action: PayloadAction<ProcessLocalization>) => {
      state.process.configuration.localization = action.payload;
    },
    startDownload: (state) => {
      state.downloading = true;
    },
    downloadSucess: (state) => {
      state.downloading = false;
      state.hasErrors = false;
    },
    downloadFailure: (state) => {
      state.downloading = false;
      state.hasErrors = true;
    },
    addNewLanguage: (state, action: PayloadAction<ProcessLanguage>) => {
      const indexIfExisting =
        state.process.configuration.localization!.additionalLanguages.findIndex(
          (lang) => lang.language === action.payload.language
        );
      if (indexIfExisting !== -1) {
        state.process.configuration.localization!.additionalLanguages.splice(
          indexIfExisting,
          1,
          action.payload
        );
      } else {
        state.process.configuration.localization?.additionalLanguages.push(action.payload);
      }
    },
    deleteAdditionalLanguage: (state, action: PayloadAction<ProcessLanguage>) => {
      const languageIndex =
        state.process.configuration.localization?.additionalLanguages.findIndex(
          (lang) => lang.language === action.payload.language
        ) ?? -1;
      if (languageIndex >= 0) {
        state.process.configuration.localization?.additionalLanguages.splice(languageIndex, 1);
      }
    },
    setValidationMessages: (state, action: PayloadAction<ProcessValidationMessage[]>) => {
      state.validationMessages = action.payload;
    },
    addFieldTranslation: (
      state,
      action: PayloadAction<{ fieldTranslationId: string; translations: { [key: string]: string } }>
    ) => {
      let localization = state.process.configuration.localization;
      const translations = action.payload.translations;

      if (!localization) {
        localization = {
          defaultLanguage: "",
          additionalLanguages: []
        };
      }

      for (const [key, value] of Object.entries(translations)) {
        let langIndex = localization.additionalLanguages.findIndex((lang) => lang.language === key);
        if (langIndex < 0) {
          const translationToAdd: ProcessLanguage = {
            language: key,
            messages: {}
          };
          langIndex = localization.additionalLanguages.push(translationToAdd) - 1;
        }
        localization.additionalLanguages[langIndex].messages[action.payload.fieldTranslationId] =
          cloneDeep(value);
      }
    },
    getTermsSuccess: (state, action: PayloadAction<any>) => {
      state.groups = action.payload.groupsAndItems;
      state.termGroupId = action.payload.termGroupId;
      state.termSetId = action.payload.termSetId;
      state.spLocaleId = action.payload.spLocaleId;
      state.loading = false;
      state.hasErrors = false;
    },
    getTermsFailure: (state) => {
      state.groups = undefined as any;
      state.loading = false;
      // state.hasErrors = true;
    },
    getTemplatesSuccess: (state, action: PayloadAction<ProcessConfiguration[]>) => {
      state.templates = action.payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getTemplatesFailure: (state) => {
      state.templates = [];
      state.loading = false;
      // state.hasErrors = true;
    },
    startGetAccount: (state) => {
      state.loadingAccounts = true;
    },
    getConsentedAccountsSuccess: (state, action: PayloadAction<any>) => {
      state.accounts = action.payload;
      state.loadingAccounts = false;
    },
    setProcessListState: (state, action: PayloadAction<ListViewData>) => {
      state.processList = action.payload as any;
    },
    updateSaving: (state, action: PayloadAction<boolean>) => {
      state.saving = action.payload;
    },
    startGetApplicationConsent: (state) => {
      state.loadingApplicationConsent = true;
    },
    getApplicationConsentSuccess: (
      state,
      action: PayloadAction<{
        applicationConsent: any[];
        delegatedConsent: any[];
        consentUrlForSavingTeamsChatMessages: string;
      }>
    ) => {
      state.applicationConsent = action.payload.applicationConsent;
      state.delegatedConsent = action.payload.delegatedConsent;
      state.consentUrlForSavingTeamsChatMessages =
        action.payload.consentUrlForSavingTeamsChatMessages;
      state.loadingApplicationConsent = false;
    }
  }
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(incrementAsync.pending, (state) => {
  //       state.status = 'loading';
  //     })
  //     .addCase(incrementAsync.fulfilled, (state, action) => {
  //       state.status = 'idle';
  //       state.value += action.payload;
  //     });
  // },
});

// Actions generated from the slice
export const {
  clearErrors,
  updateProcess,
  startLoading,
  startGetProcess,
  getProcessFailure,
  getProcessSuccess,
  saveProcessSuccess,
  getAccountSuccess,
  getAccountFailure,
  saveProcessFailure,
  startSaving,
  startDeleting,
  deleteSuccess,
  deletionFailure,
  getStep,
  newStep,
  updateStep,
  deleteStep,
  getTrigger,
  newTrigger,
  updateTrigger,
  deleteTrigger,
  updateConfiguration,
  updateLocalization,
  startDownload,
  downloadSucess,
  downloadFailure,
  addNewLanguage,
  deleteAdditionalLanguage,
  setValidationMessages,
  addFieldTranslation,
  getTermsSuccess,
  getTermsFailure,
  getTemplatesSuccess,
  getTemplatesFailure,
  getConsentedAccountsSuccess,
  setProcessListState,
  startGetAccount,
  updateSaving,
  startGetApplicationConsent,
  getApplicationConsentSuccess,
  savingSuccess
} = processSlice.actions;

// export user selector to get the slice in any component
export const processSelector = (state: RootState) => state.process;

// export The reducer
const processReducer = processSlice.reducer;
export default processReducer;

export const createTerm =
  (termName: string, termId?: string): AppThunk =>
  async (dispatch: AppDispatch, getState, { apiClient }) => {
    try {
      dispatch(startLoading());
      const state = getState();
      let termSetId = state.process.termSetId;
      let termGroupId = state.process.termGroupId;
      if (!termSetId || !termGroupId) {
        const termsInfo = await getTermsInfo(apiClient);
        termSetId = termsInfo.termSetId;
        termGroupId = termsInfo.groupId;
      }
      await apiClient.postWP(
        `/TermGroup/TermSet/Terms/Create?termsStoreId=00000000-0000-0000-0000-000000000000&termsGroupId=${termGroupId}&termsSetId=${
          termId ?? termSetId
        }&termName=${termName}&termId=null"
        &availableForTagging=true`,
        {}
      );
      dispatch(getTerms());
    } catch (err) {
      dispatch(getTermsFailure());
    }
  };

export const deleteTerm =
  (termId: string): AppThunk =>
  async (dispatch: AppDispatch, getState, { apiClient }) => {
    try {
      dispatch(startLoading());
      const state = getState();
      let termSetId = state.process.termSetId;
      if (!termSetId) {
        const termsInfo = await getTermsInfo(apiClient, undefined, state.process.termGroupId);
        termSetId = termsInfo.termSetId;
      }
      await apiClient.deleteWP(
        `/TermGroup/TermSet/Terms/Delete?termsGroupId=00000000-0000-0000-0000-000000000000&termsSetId=${termSetId}&termId=${termId}`
      );
      dispatch(getTerms());
    } catch (err) {
      dispatch(getTermsFailure());
    }
  };

export const updateTermName =
  (termId: string, newName: string): AppThunk =>
  async (dispatch: AppDispatch, getState, { apiClient }) => {
    try {
      dispatch(startLoading());
      const state = getState();
      let termSetId = state.process.termSetId;
      if (!termSetId) {
        const termsInfo = await getTermsInfo(apiClient, undefined, state.process.termGroupId);
        termSetId = termsInfo.termSetId;
      }
      await apiClient.putWP(
        `/TermGroup/TermSet/Terms/UpdateName?termsSetId=${termSetId}&termId=${termId}&newName=${newName}` +
          (state.process.spLocaleId ? `&lcid=${state.process.spLocaleId}` : "")
      );
      dispatch(getTerms());
    } catch (err) {
      dispatch(getTermsFailure());
    }
  };

export const newProcess =
  (
    template: ProcessConfiguration,
    type: ProcessType,
    title: string,
    group?: ProcessGroup,
    navigate?: NavigateFunction
  ): AppThunk =>
  async (dispatch: AppDispatch, getState, { apiClient }) => {
    try {
      const item: ProcessItem = {
        description: "",
        title: title,
        type: type,
        status: ProcessStatus.Draft,
        version: "0.0",
        configuration: {
          ...template,
          type,
          title: {
            ...template.title,
            defaultText: title
          },
          version: "0.0"
        },
        isTemplate: false,
        editor: { id: "", title: "" },
        author: { id: "", title: "" },
        created: new Date().toLocaleString(),
        modified: new Date().toLocaleString(),
        groups: group ? [group] : []
      };

      const state = getState();

      const document = state.process.document ? state.process.document : await getSwaggerDocument();

      dispatch(getProcessAccount());

      dispatch(getProcessSuccess({ item, document }));
      if (navigate) {
        navigate(ToRoute.newProcess());
      }
    } catch (error) {
      dispatch(getProcessFailure());
    }
  };

export const getProcess =
  (id?: number, navigate?: NavigateFunction): AppThunk =>
  async (dispatch: AppDispatch, getState, { apiClient }) => {
    try {
      let redirectId;
      dispatch(startGetProcess());
      let item: ProcessItem;
      if (id) {
        item = await fetchProcess(apiClient, id);
        if (item.itemId && !item.parentId && navigate) {
          const childItems = await apiClient.getListItems({
            listName: Lists.Process,
            filter: "wpProcessParentId eq " + item.itemId,
            rowLimit: 1
          });
          if (childItems.items.length > 0) {
            redirectId = childItems.items[0].Id;
          }
        }
      } else {
        item = defaultProcessItem(uuid());
      }

      const state = getState();

      const document = state.process.document ? state.process.document : await getSwaggerDocument();

      dispatch(getProcessAccount());

      dispatch(getProcessSuccess({ item, document }));

      if (navigate && redirectId) {
        navigate(ToRoute.process(redirectId));
      }
    } catch (error) {
      dispatch(getProcessFailure());
    }
  };

export const getProcessAccount =
  (): AppThunk =>
  async (dispatch: AppDispatch, getState, { apiClient }) => {
    try {
      dispatch(startGetAccount());
      const state = getState();
      const accounts = state.process.accounts
        ? state.process.accounts
        : await getConsentedAccounts(dispatch, apiClient);
      dispatch(getAccountSuccess({ accounts }));
    } catch {
      dispatch(getAccountFailure());
    }
  };

export const getSwaggerDocument = async () => {
  const document = await getSwagger(`${process.env.PUBLIC_URL}/assets/swagger.json${backendPool}`);
  return document;
};

export const getCustomTemplates =
  (): AppThunk =>
  async (dispatch: AppDispatch, getState, { apiClient }) => {
    try {
      dispatch(startLoading());
      const data = await apiClient.getListItems({
        listName: Lists.Process,
        filter: "wpProcessIsTemplate eq 1 and wpProcessShowInList eq 1"
      });
      const processItems = data.items.map((d: any) => mapDataToModel(d, true));
      dispatch(getTemplatesSuccess(processItems.map((item) => item.configuration)));
    } catch (error) {
      dispatch(getTemplatesFailure());
    }
  };

export const toggleProcessEnable =
  (id: number, postDisableHandler?: (item: any, skipGet?: boolean) => void): AppThunk =>
  async (dispatch: AppDispatch, getState, { apiClient }) => {
    try {
      dispatch(startSaving("SaveAsDraft"));
      const process = await fetchProcess(apiClient, id);
      process.status =
        process.status === ProcessStatus.Disabled ? ProcessStatus.Draft : ProcessStatus.Disabled;
      const savedProcess = await dispatch(saveProcess(process));
      if (postDisableHandler) {
        postDisableHandler(mapModelToData(savedProcess as ProcessItem), false);
      }
    } catch (error) {
      dispatch(saveProcessFailure());
    }
  };

export const toggleProcessTemplate =
  (id: number): AppThunk =>
  async (dispatch: AppDispatch, getState, { apiClient }) => {
    try {
      dispatch(startSaving("SaveAsDraft"));
      const process = await fetchProcess(apiClient, id);
      process.isTemplate = !process.isTemplate;
      dispatch(saveProcess(process, undefined, undefined, true));
    } catch (error) {
      dispatch(saveProcessFailure());
    }
  };

export const saveProcessCopy =
  (
    process: ProcessItem,
    navigate: NavigateFunction,
    postSaveAsHandler?: (item: any) => void
  ): AppThunk =>
  async (dispatch: AppDispatch, getState, { apiClient }) => {
    try {
      dispatch(startSaving("SaveAsDraft"));
      const processToSave = cloneDeep(process);
      processToSave.itemId = undefined;
      processToSave.parentId = undefined;
      processToSave.title = "Copy of - " + processToSave.title;
      processToSave.isTemplate = false;
      processToSave.configuration.id = uuid();
      processToSave.configuration.title.defaultText = processToSave.title;
      processToSave.status = ProcessStatus.Draft;
      dispatch(saveProcess(processToSave, navigate, undefined, undefined, postSaveAsHandler));
    } catch (error) {
      console.log(error);
      dispatch(saveProcessFailure());
    }
  };

export const saveProcessAs =
  (id: number, title: string, postSaveAsHandler?: (item: any) => void): AppThunk =>
  async (dispatch: AppDispatch, getState, { apiClient }) => {
    try {
      dispatch(startSaving("SaveAsDraft"));
      const process = await fetchProcess(apiClient, id);
      process.itemId = undefined;
      process.parentId = undefined;
      process.title = title;
      process.isTemplate = false;
      process.configuration.id = uuid();
      process.configuration.title.defaultText = title;
      process.status = ProcessStatus.Draft;
      process.version = "0.0";
      process.configuration.version = "0.0";
      dispatch(saveProcess(process, undefined, undefined, undefined, postSaveAsHandler));
    } catch (error) {
      dispatch(saveProcessFailure());
    }
  };

export const save =
  (status: ProcessStatus, navigate?: NavigateFunction, route?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    try {
      dispatch(startSaving(""));
      const state = getState();
      const processItem = { ...state.process.process, status };
      dispatch(saveProcess(processItem, navigate, route));
    } catch {
      dispatch(saveProcessFailure());
    }
  };

export const saveProcess =
  (
    processItem: ProcessItem,
    navigate?: NavigateFunction,
    route?: string,
    keepCurrentVersion?: boolean,
    postSaveHandler?: (item: any) => void
  ): AppThunk<Promise<ProcessItem>> =>
  async (dispatch: AppDispatch, getState, { apiClient }) => {
    try {
      dispatch(
        startSaving(
          processItem.status === ProcessStatus.Published
            ? "Publish"
            : route === ToRoute.processes()
            ? "SaveAsDraftAndExit"
            : "SaveAsDraft"
        )
      );

      let doRedirect = processItem.itemId === undefined;
      const savingProcessItem = cloneDeep(processItem);

      if (isTemplateId(savingProcessItem.configuration.id)) {
        savingProcessItem.configuration.id = uuid();
      }

      savingProcessItem.title = evaluateProcessExpressionForDisplay(
        savingProcessItem.configuration.title
      );
      let deleteItem = false;
      let hideParentInList = false;
      let publishedItem: any;
      if (savingProcessItem.status === ProcessStatus.Published) {
        if (savingProcessItem.parentId) {
          // publishing draft - updating published, deleting draft
          publishedItem = await fetchProcess(apiClient, savingProcessItem.parentId);
          savingProcessItem.itemId = publishedItem.itemId;
          savingProcessItem.parentId = undefined;
          savingProcessItem.configuration.id = publishedItem.configuration.id;
          deleteItem = true;
          doRedirect = true;
        }
      } else if (savingProcessItem.status === ProcessStatus.Disabled) {
        if (savingProcessItem.parentId) {
          // disable pubished item and current item
          publishedItem = await apiClient.saveListItem({
            listName: Lists.Process,
            properties: {
              Id: savingProcessItem.parentId,
              wpProcessStatus: savingProcessItem.status,
              wpProcessIsTemplate: savingProcessItem.isTemplate
            }
          });
        }
      } else {
        if (!savingProcessItem.parentId) {
          if (hasPublishedVersion(savingProcessItem)) {
            // saving published as draft - creating new draft item with referent to parent
            savingProcessItem.parentId = savingProcessItem.itemId;
            savingProcessItem.itemId = undefined;
            savingProcessItem.configuration.id = uuid();
            doRedirect = true;
            hideParentInList = true;
          }
        } else {
          await apiClient.saveListItem({
            listName: Lists.Process,
            properties: {
              Id: savingProcessItem.parentId,
              wpProcessIsTemplate: savingProcessItem.isTemplate
            }
          });
        }
      }

      !keepCurrentVersion && incrementVersion(savingProcessItem);

      const properties = mapModelToData(savingProcessItem);
      const savedData = await apiClient.saveListItem({ listName: Lists.Process, properties });
      const savedItem = await fetchProcess(apiClient, savedData.Id);

      if (deleteItem) {
        await apiClient.deleteListItem({ listName: Lists.Process, id: processItem.itemId });
      }
      if (hideParentInList && processItem.itemId) {
        await apiClient.saveListItem({
          listName: Lists.Process,
          properties: {
            Id: processItem.itemId,
            wpProcessShowInList: false
          }
        });
      }

      trackEvent(`ProcessBuilder/${savingProcessItem.status}`, {
        processId: savingProcessItem.configuration.id,
        processType: savingProcessItem.configuration.type,
        steps: savingProcessItem.configuration.steps.map((step) => step.name).join(", ")
      });

      if (
        savingProcessItem.status === ProcessStatus.Published ||
        savingProcessItem.status === ProcessStatus.Disabled
      ) {
        await apiClient.postWP("/Process/UpdateConfigurationItemsCache", {
          Action: savingProcessItem.status === ProcessStatus.Published ? "Publish" : "Remove",
          Processes: publishedItem?.wpProcessId
            ? [savingProcessItem.configuration.id, publishedItem.wpProcessId]
            : [savingProcessItem.configuration.id]
        });
      }

      const item = doRedirect ? processItem : savedItem;
      if (postSaveHandler) {
        postSaveHandler(mapModelToData(savedItem));
      }
      dispatch(saveProcessSuccess(item));

      const processListState = cloneDeep(getState().process.processList);
      if (savedItem.itemId && processListState) {
        var { items, groups } = await addItemToList(
          apiClient,
          apiClient.siteUrl,
          "lists/WorkPointProcess",
          processListState?.items,
          savedItem.itemId,
          "wpProcessGroups",
          processItem.itemId
        );
        processListState.items = items;
        if (groups) processListState.groups = groups;
        dispatch(setProcessListState(processListState));
      }

      if (navigate) {
        if (route?.startsWith("/")) {
          navigate(route);
        } else if (doRedirect && savedItem.itemId) {
          navigate(ToRoute.process(savedItem.itemId.toString()) + (route ? "/" + route : ""));
        }
      }

      return savedItem;
    } catch (error: any) {
      dispatch(saveProcessFailure());
      dispatch(
        showDialog({
          type: DialogType.Ok,
          title: "Error: The process couldn't be saved",
          subText: "",
          onClick: (dialogResult: DialogResult) => {},
          onRenderDialog: () => {
            return DefaultErrorMessage(error.message);
          }
        })
      );
      return processItem;
    }
  };

export const exportProcessConfiguration = async (
  apiClient: ApiClient,
  guidId: string | undefined
): Promise<any> => {
  const process = await apiClient.getWP(`/ProcessConfigurations?processConfigurationId=${guidId}`);
  return process[0];
};

export const fetchProcess = async (
  apiClient: ApiClient,
  id: number | undefined
): Promise<ProcessItem> => {
  const data = await apiClient.getListItem({
    listName: Lists.Process,
    id,
    select: ["*", "Editor/Title", "Editor/Id", "Author/Title", "Author/Id"],
    expand: ["Editor", "Author", "wpProcessGroups"],
    viewXml: `<View><Query><Where><Eq><FieldRef Name='ID' /><Value Type='Number'>${id}</Value></Eq></Where></Query></View>`
  });
  return mapDataToModel(data, true);
};

export const deleteProcess =
  (id: number, navigate?: NavigateFunction, route?: string): AppThunk =>
  async (dispatch: AppDispatch, getState, { apiClient }) => {
    try {
      dispatch(startDeleting());
      const item = await apiClient.getListItem({ listName: Lists.Process, id });
      if (item) {
        await apiClient.deleteListItem({ listName: Lists.Process, id });
        const Processes = [item.wpProcessId];
        if (item.wpProcessParentId) {
          const publishedItem = await apiClient.getListItem({
            listName: Lists.Process,
            id: item.wpProcessParentId
          });
          if (publishedItem) {
            await apiClient.deleteListItem({ listName: Lists.Process, id: publishedItem.ID });
            Processes.push(publishedItem.wpProcessId);
          }
        }

        await apiClient.postWP("/Process/UpdateConfigurationItemsCache", {
          Action: "Remove",
          Processes
        });
      }
      dispatch(deleteSuccess());

      navigate?.(route ?? ToRoute.processes());
    } catch (error) {
      dispatch(deletionFailure());
    }
  };

export const discardChanges =
  (id: number, navigate?: NavigateFunction, route?: string): AppThunk =>
  async (dispatch: AppDispatch, getState, { apiClient }) => {
    try {
      dispatch(startSaving(""));
      const item = await apiClient.getListItem({ listName: Lists.Process, id });
      if (item && item.wpProcessStatus === ProcessStatus.Draft) {
        await apiClient.deleteListItem({ listName: Lists.Process, id });
        if (item.wpProcessParentId) {
          const publishedItem = await apiClient.getListItem({
            listName: Lists.Process,
            id: item.wpProcessParentId
          });
          if (publishedItem) {
            await apiClient.saveListItem({
              listName: Lists.Process,
              properties: { Id: item.wpProcessParentId, wpProcessShowInList: true }
            });
          }
        }
      }
      dispatch(savingSuccess());

      navigate?.(route ?? ToRoute.processes());
    } catch (error) {
      dispatch(saveProcessFailure());
    }
  };

export const downloadProcess =
  (guidId: string | undefined): AppThunk =>
  async (dispatch: AppDispatch, getState, { apiClient }) => {
    try {
      dispatch(startDownload());
      const process = await exportProcessConfiguration(apiClient, guidId);
      downloadObjectAsJson(
        process,
        evaluateExpressionForDisplay(
          process.ProcessConfiguration?.title?.defaultText ?? "process-export",
          {}
        )
      );
      dispatch(downloadSucess());
    } catch (error) {
      dispatch(downloadFailure());
    }
  };

export const importProcessConfiguration =
  (
    process: any,
    navigate: NavigateFunction,
    ignoreVersionConflict: boolean = false,
    replaceExisting: boolean = false
  ): AppThunk =>
  async (dispatch: AppDispatch, getState, { apiClient }) => {
    try {
      // Show working on it dialog
      dispatch(
        showDialog({
          type: DialogType.Working,
          title: "Importing process",
          subText: "",
          onClick: (dialogResult: DialogResult) => {},
          onRenderDialog: WorkingOnIt
        })
      );

      const processResponse = await apiClient.fetchWP(
        `/ProcessConfigurations?ignoreConflictingVersions=${ignoreVersionConflict}&overrideExisting=${replaceExisting}`,
        "POST",
        [],
        undefined,
        process
      );

      if (!processResponse.ok) {
        // Version already exists
        if (processResponse.status === 409) {
          let validationError = await processResponse.json();

          if (typeof validationError === "string") validationError += "\n\nOverride existing?";

          dispatch(
            showDialog({
              type: DialogType.YesNo,
              title: "Process already exists",
              subText: "",
              onClick: (dialogResult: DialogResult) => {
                if (dialogResult === DialogResult.Ok) {
                  dispatch(
                    importProcessConfiguration(process, navigate, ignoreVersionConflict, true)
                  );
                }
              },
              onRenderDialog: () => DefaultErrorMessage(validationError)
            })
          );
          // WorkPoint Version mismatch
        } else if (processResponse.status === 400) {
          let validationError = await processResponse.json();

          if (typeof validationError === "string") validationError += "\n\nIgnore and upload?";

          dispatch(
            showDialog({
              type: DialogType.YesNo,
              title: "WorkPoint Version mismatch",
              subText: "",
              onClick: (dialogResult: DialogResult) => {
                if (dialogResult === DialogResult.Ok) {
                  dispatch(importProcessConfiguration(process, navigate, true, replaceExisting));
                }
              },
              onRenderDialog: () => DefaultErrorMessage(validationError)
            })
          );
        } else {
          // Unknown error

          const serverError = await processResponse.json();

          throw Error(serverError);
        }
      } else {
        // Success!

        (window as any)?.ProcessListViewReload?.();

        dispatch(
          showDialog({
            type: DialogType.Ok,
            title: "Process Imported successfully",
            subText: "",
            onClick: (dialogResult: DialogResult) => {},
            onRenderDialog: () => {
              return DefaultErrorMessage("Process Imported successfully");
            }
          })
        );
      }
    } catch (error: any) {
      dispatch(
        showDialog({
          type: DialogType.Ok,
          title: "Error: The process couldn't be saved",
          subText: "",
          onClick: (dialogResult: DialogResult) => {},
          onRenderDialog: () => {
            return DefaultErrorMessage(error?.message ?? error);
          }
        })
      );
    }
  };

export const downloadLanguagePack =
  (obj: any, exportName: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(startDownload());
      downloadObjectAsJson(obj, exportName);
      dispatch(downloadSucess());
    } catch (error) {
      dispatch(downloadFailure());
    }
  };

const downloadObjectAsJson = (obj: any, title: string) => {
  const dataStr =
    "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(obj, undefined, 4));
  const downloadAnchorNode = document.createElement("a");
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", title + ".json");
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

const mapDataToModel = (data: any, parseProcess: boolean): ProcessItem => {
  let processConfig: ProcessConfiguration = undefined as any;
  if (parseProcess) {
    try {
      processConfig = JSON.parse(data.wpProcessConfiguration);
    } catch {}
  }

  if (!processConfig) {
    processConfig = defaultProcessItem(uuid()).configuration;
  }

  const processItem: ProcessItem = {
    itemId: data.Id ?? data.ID,
    title: data.Title,
    parentId:
      data.wpProcessParentId ??
      (Array.isArray(data.wpProcessParent) ? data.wpProcessParent[0].lookupId : undefined),
    description: "", // Description doesn't exist as a column yet
    type: data.wpProcessType,
    status: data.wpProcessStatus,
    version: data.wpProcessVersion,
    groups:
      data.wpProcessGroups && data.wpProcessGroups.TermGuid
        ? [
            {
              id: data.wpProcessGroups.TermGuid,
              title: data.wpProcessGroups.Label
            }
          ]
        : [],
    configuration: processConfig,
    isTemplate: data.wpProcessIsTemplate ? true : false,
    created: (data.Created as Date).toLocaleString(),
    modified: (data.Modified as Date).toLocaleString(),
    author: {
      id: data.Author?.Id ?? (Array.isArray(data.Author) ? data.Author[0].id : undefined),
      title: data.Author?.Title ?? (Array.isArray(data.Author) ? data.Author[0].title : undefined)
    },
    editor: {
      id: data.Editor?.Id ?? (Array.isArray(data.Editor) ? data.Editor[0].id : undefined),
      title: data.Editor?.Title ?? (Array.isArray(data.Editor) ? data.Editor[0].title : undefined)
    }
  };

  return processItem;
};

const cleanupExpression = (obj: any, propName?: string) => {
  if (obj) {
    const objToClean = propName ? obj[propName] : obj;
    if (objToClean) {
      ["data", "expression", "rules"].forEach((key) => {
        if (isEmpty(objToClean[key])) {
          delete objToClean[key];
        }
      });
      if (objToClean.rules && objToClean.rules.length === 1) {
        const rule: ExpressionDescriptorRule = objToClean.rules[0];
        if (
          (rule.comparisonOperator === ComparisonOperator.None &&
            rule.type1 === ExpressionDescriptorValueType.Text &&
            isEmpty(rule.value1)) ||
          (rule.comparisonOperator === ComparisonOperator.EqualTo &&
            rule.type1 === ExpressionDescriptorValueType.Text &&
            isEmpty(rule.value1) &&
            rule.type2 === ExpressionDescriptorValueType.Text &&
            isEmpty(rule.value2))
        ) {
          delete objToClean.rules;
        }
      }
      if (propName && isEmpty(obj[propName])) {
        delete obj[propName];
      }
    }
  }
};

const removeEmptyProperties = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(removeEmptyProperties).filter((value) => !isEmpty(value)); // Filter out empty values from the array
  } else if (typeof obj === "object") {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (key === "rules") {
        if (!isRulesEmpty(value as ExpressionDescriptorRule[])) {
          acc[key] = value;
        }
      } else if (key === "localization" || (key === "steps" && (value as any).length === 0)) {
        acc[key] = value;
      } else {
        const cleanedValue = removeEmptyProperties(value);
        if (!isEmpty(cleanedValue)) {
          acc[key] = cleanedValue; // Only assign if the cleaned value is not empty
        }
      }
      return acc;
    }, {} as { [key: string]: any });
  }

  return obj; // Return the value directly if it's not an array or object
};

const cleanupConfiguration = (processItem: ProcessItem) => {
  processItem = removeEmptyProperties(processItem);

  const localizationIds: string[] = [];
  localizationIds.push(processItem.configuration.title.id);
  localizationIds.push(processItem.configuration.description.id);
  processItem.configuration.start?.message?.id &&
    localizationIds.push(processItem.configuration.start.message.id);
  processItem.configuration.finish?.message?.id &&
    localizationIds.push(processItem.configuration.finish.message.id);
  processItem.configuration.start?.buttonTitle &&
    localizationIds.push(processItem.configuration.start.buttonTitle.id);
  processItem.configuration.permissions?.messageTitle &&
    localizationIds.push(processItem.configuration.permissions.messageTitle.id);
  processItem.configuration.permissions?.messageBody &&
    localizationIds.push(processItem.configuration.permissions.messageBody.id);

  if (
    processItem.configuration.finish?.message?.defaultText &&
    processItem.configuration.finish?.message?.data
  ) {
    delete processItem.configuration.finish.message.data;
  }

  if (
    processItem.configuration.start?.message?.defaultText &&
    processItem.configuration.start?.message?.data
  ) {
    delete processItem.configuration.start.message.data;
  }
  processItem.configuration.finish?.sharePoint?.customButtons?.forEach((button) => {
    if (button.description.id) {
      localizationIds.push(button.description.id);
    }
  });

  if (
    processItem.configuration.finish?.message?.defaultText &&
    processItem.configuration.finish?.message?.data
  ) {
    delete processItem.configuration.finish.message.data;
  }

  processItem.configuration.steps.forEach((step) => {
    localizationIds.push(step.title.id);
    localizationIds.push(step.description.id);
    step.continueButtonTitle?.id && localizationIds.push(step.continueButtonTitle.id);
    step.permissions?.messageTitle?.id && localizationIds.push(step.permissions.messageTitle.id);
    step.permissions?.messageBody?.id && localizationIds.push(step.permissions.messageBody.id);
    step.valid?.message?.id && localizationIds.push(step.valid.message.id);
    step.parameters.forEach((stepParam) => {
      const dataSourceParam = stepParam[ProcessParameterProperty.DataSource];
      if (dataSourceParam) {
        dataSourceParam.parameters?.forEach((dataSourceParam) => {
          if (dataSourceParam.title) {
            localizationIds.push(dataSourceParam.title.id);
          }
          if (dataSourceParam.description) {
            localizationIds.push(dataSourceParam.description.id);
          }
        });
        if (!dataSourceParam.itemDisplayTemplate?.template) {
          delete dataSourceParam.itemDisplayTemplate;
        }
        if (!dataSourceParam.multi) {
          delete dataSourceParam.multi;
        }
        if (dataSourceParam.typeahead) {
          delete dataSourceParam.typeahead;
        }
        if (Object.keys(dataSourceParam).length === 1 && !dataSourceParam.title?.defaultText) {
          delete stepParam[ProcessParameterProperty.DataSource];
        }
      }

      if (stepParam.title) {
        localizationIds.push(stepParam.title.id);
      }
      if (stepParam.description) {
        localizationIds.push(stepParam.description.id);
      }
      if (stepParam.value && (stepParam.value as any).id) {
        localizationIds.push((stepParam.value as any).id);
      }
      if (stepParam.validMessage && stepParam.validMessage.id) {
        localizationIds.push(stepParam.validMessage.id);
      }
      if (stepParam.dataSource) {
        if (stepParam.dataSource.title) {
          localizationIds.push(stepParam.dataSource.title.id);
        }
        if (stepParam.dataSource.errorMessage) {
          localizationIds.push(stepParam.dataSource.errorMessage.id);
        }
        stepParam.dataSource.tabs?.forEach((tab) => {
          if (tab.title) {
            localizationIds.push(tab.title.id);
          }
        });
      }
      if (Array.isArray(stepParam.value?.data)) {
        stepParam.value?.data.forEach((dataItem: any) => {
          Object.values(dataItem).forEach((dataItemProp: any) => {
            if (dataItemProp.id && dataItemProp.defaultText) {
              localizationIds.push(dataItemProp.id);
            }
          });
        });
      }
    });

    if (step.execution?.preEvalMessage?.title) {
      localizationIds.push(step.execution?.preEvalMessage?.title?.id);
    }
    if (step.execution?.preEvalMessage?.description) {
      localizationIds.push(step.execution?.preEvalMessage?.description?.id);
    }

    step.execution?.transitions?.forEach((t) => {
      if (t.title) {
        localizationIds.push(t.title.id);
      }
      if (t.responseTitle) {
        localizationIds.push(t.responseTitle.id);
      }
      if (t.responseMessage) {
        localizationIds.push(t.responseMessage.id);
      }
    });
  });

  processItem.configuration.localization?.additionalLanguages?.forEach((lang) => {
    const messageKeys = Object.keys(lang.messages);
    messageKeys.forEach((key) => {
      if (localizationIds.indexOf(key) < 0) {
        delete lang.messages[key];
      }
    });
  });

  return processItem;
};

const incrementVersion = (processItem: ProcessItem): void => {
  const versionSplit = processItem.version?.split(".");
  if (processItem.status === ProcessStatus.Draft) {
    if (versionSplit.length > 1) {
      processItem.version = versionSplit[0] + "." + (parseInt(versionSplit[1]) + 1).toString();
    } else if (versionSplit.length === 1) {
      processItem.version = versionSplit[0] + ".1";
    } else {
      processItem.version = "0.1";
    }
  } else if (processItem.status === ProcessStatus.Published) {
    processItem.version = `${parseInt(versionSplit[0]) + 1}.0`;
  }
  processItem.configuration.version = processItem.version;
};

const mapModelToData = (processItem: ProcessItem): Record<string, any> => {
  processItem = cleanupConfiguration(processItem);

  const data: Record<string, any> = {
    Id: processItem.itemId,
    ID: processItem.itemId,
    Title: processItem.title,
    wpProcessId: processItem.configuration.id,
    wpProcessStatus: processItem.status,
    wpProcessConfiguration: JSON.stringify(processItem.configuration),
    wpProcessIsTemplate: processItem.isTemplate,
    wpProcessType: processItem.type,
    wpProcessVersion: processItem.version,
    wpProcessShowInList: true
  };

  data.wpProcessGroups =
    processItem.groups && processItem.groups.length > 0 && processItem.groups[0].id
      ? {
          Label: processItem.groups[0].title,
          TermGuid: processItem.groups[0].id,
          WssId: "-1"
        }
      : null;

  if (processItem.parentId) {
    data.wpProcessParentId = processItem.parentId;
  }

  return data;
};

// const mapInstanceToModel = (data: any): ProcessInstance => {
//   const inst: ProcessInstance = {
//     id: data.wpProcessInstId,
//     processId: data.wpProcessInstProcessId,
//     context: data.wpProcessInstContext,
//     currentStep: data.wpProcessInstStep,
//     currentStepIndex: data.wpProcessInstStepIndex,
//     graphData: data.wpProcessInstGraphData,
//     message: data.wpProcessInstMessage,
//     configuration: data.wpProcessConfiguration,
//     availableTriggers: [],
//     processLocation: {
//       description: data.wpProcessInstLocation?.Description ?? "",
//       url: data.wpProcessInstLocation?.Url ?? ""
//     },
//     status: data.wpProcessInstStatus,
//     title: data.Title
//   };
//   return inst;
// };

export const getTerms =
  (): AppThunk =>
  async (dispatch: AppDispatch, getState, { apiClient }) => {
    try {
      dispatch(startLoading());
      const state = getState();

      const [allTerms, dataStreamResult] = await Promise.all([
        getTermsInfo(apiClient, state.process.termSetId, state.process.termGroupId, true),
        apiClient.getListItems({
          listName: "lists/WorkPointProcess",
          renderListDataParameters: { RenderOptions: 4 }
        })
      ]);

      const termsWithChilrdenPromisses: Promise<TermsResult>[] = [];
      termsWithChilrdenPromisses.push(
        apiClient.getTerms({
          groupId: allTerms.groupId,
          termSetId: allTerms.termSetId
        })
      );
      for (const term of allTerms.terms) {
        termsWithChilrdenPromisses.push(
          apiClient.getTerms({
            groupId: allTerms.groupId,
            termSetId: allTerms.termSetId,
            termId: term.id
          })
        );
      }

      const termsWithChilrden = await Promise.all(termsWithChilrdenPromisses);

      const items: ITermInfo[] = [];
      const result = getChildren(termsWithChilrden[0].terms, termsWithChilrden, items, 0);

      dispatch(
        getTermsSuccess({
          groupsAndItems: { groups: result.groups, items },
          groupId: allTerms.groupId,
          termSetId: allTerms.termSetId,
          spLocaleId: (dataStreamResult as any).renderListDataAsStreamResponse.LCID
        })
      );
    } catch (error) {
      dispatch(getTermsFailure());
    }
  };

const isTemplateId = (id: string): boolean => {
  const templates = getJsonObjectFromFile(`${process.env.PUBLIC_URL}/assets/process.json`);
  for (const template of templates) {
    if (template.id === id) {
      return true;
    }
  }
  return false;
};

const getTermsInfo = async (
  apiClient: ApiClient,
  termSetId?: string,
  termGroupId?: string,
  getAllTerms?: boolean
) => {
  const termsInfo = await apiClient.getTerms({
    siteCollectionGroup: true,
    termSetId: termSetId ?? "WorkPoint365 Process Groups",
    all: getAllTerms,
    groupId: termGroupId
  });
  return termsInfo;
};

const getChildrenRecursive = (
  items: ITermInfo[],
  termId: string,
  termsWithChilrden: TermsResult[],
  level: number
): { itemCount: number; groups: IGroup[] } => {
  for (let i = 1; i < termsWithChilrden.length; i++) {
    if (termsWithChilrden[i].termId === termId) {
      const result = getChildren(termsWithChilrden[i].terms, termsWithChilrden, items, level);
      return result;
    }
  }

  return { itemCount: 0, groups: [] };
};

const getChildren = (
  terms: ITermInfo[],
  termsWithChilrden: TermsResult[],
  items: ITermInfo[],
  level: number
): { itemCount: number; groups: IGroup[] } => {
  const result: { itemCount: number; groups: IGroup[] } = { itemCount: 0, groups: [] };
  const startIndex = items.length;
  let hasChildren = false;
  terms
    .filter((term) => term.childrenCount === 0)
    .forEach((term) => {
      items.push(term);
      result.itemCount++;
      hasChildren = true;
    });
  if (hasChildren) {
    result.groups.push({
      key: "0" + level.toString(),
      name: "Undefined",
      startIndex,
      count: result.itemCount,
      children: [],
      level: level
    });
  }
  terms
    .filter((term) => term.childrenCount > 0)
    .forEach((term) => {
      const startIndex = items.length;
      const groupChildern = getChildrenRecursive(items, term.id, termsWithChilrden, level + 1);
      result.itemCount = result.itemCount + groupChildern.itemCount;
      result.groups.push({
        key: term.id,
        name: term.labels[0].name,
        startIndex,
        count: groupChildern.itemCount,
        children: [...groupChildern.groups],
        level: level
      });
    });

  return result;
};

const getConsentedAccounts = async (dispatch: AppDispatch, apiClient: ApiClient) => {
  try {
    const result = await apiClient.getWP("/Process/Account");
    return result;
  } catch (error) {
    dispatch(
      showMessage({
        type: MessageBarType.error,
        text: `API call '/Process/Account' failed: ${(error as any).message}`
      })
    );
  }

  return [];
};

export const loadConsentedAccounts =
  (): AppThunk =>
  async (dispatch: AppDispatch, getState, { apiClient }) => {
    dispatch(startGetAccount());
    const accounts = await getConsentedAccounts(dispatch, apiClient);
    dispatch(getConsentedAccountsSuccess(accounts));
  };

export const loadApplicationConsent =
  (): AppThunk =>
  async (dispatch: AppDispatch, getState, { apiClient }) => {
    dispatch(startGetApplicationConsent());
    const response = await apiClient.fetchWP("/Consent/Permissions/Application", "GET");
    var applicationConsent = await response.json();
    const delegatedConsentResponse = await apiClient.fetchWP(
      "/Consent/Permissions/Delegated",
      "GET"
    );
    var delegatedConsent = await delegatedConsentResponse.json();

    var consentUrlForSavingTeamsChatMessages = await apiClient.getWP(
      "/Consent/WorkPoint/Permissions/Application/GetConsentUrlForSavingTeamsChatMessages"
    );

    dispatch(
      getApplicationConsentSuccess({
        applicationConsent,
        delegatedConsent,
        consentUrlForSavingTeamsChatMessages
      })
    );
  };

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const incrementAsync = createAsyncThunk(
//   'counter/fetchCount',
//   async (amount: number) => {
//     const response: any = await fetch({} as any);
//     // The value we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (dispatch, getState) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };
