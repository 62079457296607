import React, { useRef, useState } from "react";
import {
  CheckboxVisibility,
  ConstrainMode,
  DetailsListLayoutMode,
  ICommandBarItemProps,
  ITextField,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  Text,
  Selection,
  IContextualMenuItem,
  IGroup
} from "@fluentui/react";
import {
  downloadProcess,
  setProcessListState,
  processSelector,
  importProcessConfiguration
} from "../../../store/processReducer";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ProcessStatus } from "@workpoint/components/lib/models/ProcessConfiguration";
import { runDeleteDialog, runDisableDialog, runSaveAsDialog } from "../../../utils/processUtils";
import { ListView, ListViewActionHandlers, ListViewData } from "../../common/listView/ListView";
import { ToRoute } from "../../../routes";
import { useIntl } from "react-intl";
import { Outlet, useNavigate, Link } from "react-router-dom";
import { FileUpload, FileUploadRef } from "../../common/FileUpload";
import { tryParseJson } from "../../../utils/commonUtils";
import ConnectionsPanel from "./ConnectionsPanel";
import { useApiClient } from "@workpoint/components/lib/clients/ApiProvider";
import WorkPointTheme from "@workpoint/components/lib/constants";
import { cloneDeep } from "lodash";

export const ProcessList = () => {
  const dispatch = useAppDispatch();
  const { apiClient } = useApiClient();
  const inputEl = useRef<ITextField>(null);
  const intl = useIntl();
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [isConnectionsPanelOpen, setIsConnectionsPanelOpen] = useState<boolean>(false);
  const uploadRef = useRef<FileUploadRef>(null);
  const { processList } = useAppSelector(processSelector);
  const [listViewData, setListViewData] = useState<ListViewData>(cloneDeep(processList) as any);
  const [searchText, setSearchText] = useState<string | undefined>(processList?.searchText);

  const selection = new Selection({
    onSelectionChanged: () => {
      setSelectedItems(selection.getSelection());
    }
  });

  const exportProcess = (guid: string) => {
    dispatch(downloadProcess(guid));
  };

  const getContextualMenuItems = (actionHandlers: ListViewActionHandlers, item: any) => {
    const menuItems: IContextualMenuItem[] = [
      {
        key: "edit",
        iconProps: { iconName: "Edit" },
        name: intl.formatMessage({ id: "option-edit", defaultMessage: "Edit" }),
        data: item,
        onClick: _onClickEdit
      },
      {
        key: "history",
        iconProps: { iconName: "TimeEntry" },
        name: intl.formatMessage({ id: "option-history", defaultMessage: "History" }),
        data: item,
        onClick: _onClickHistory
      },
      {
        key: "disable",
        iconProps: {
          iconName: item.wpProcessStatus === ProcessStatus.Disabled ? "Refresh" : "DisableUpdates"
        },
        name:
          item.wpProcessStatus === ProcessStatus.Disabled
            ? intl.formatMessage({ id: "option-enable", defaultMessage: "Enable" })
            : intl.formatMessage({ id: "option-disable", defaultMessage: "Disable" }),
        data: item,
        onClick: (
          ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
          item?: IContextualMenuItem
        ) => runDisableDialog(dispatch, item?.data, actionHandlers.onUpdate)
      },
      {
        key: "export",
        iconProps: { iconName: "Export" },
        name: intl.formatMessage({ id: "option-export", defaultMessage: "Export" }),
        data: item,
        onClick: (
          ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
          item?: IContextualMenuItem
        ) => exportProcess(item?.data.wpProcessId)
      },
      {
        key: "saveAs",
        iconProps: { iconName: "Save" },
        name: intl.formatMessage({ id: "option-save-as", defaultMessage: "Save as" }),
        data: item,
        onClick: (
          ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
          item?: IContextualMenuItem
        ) => runSaveAsDialog(dispatch, inputEl, item?.data, actionHandlers.onAdd)
      },
      {
        key: "delete",
        iconProps: { iconName: "Delete" },
        name: intl.formatMessage({ id: "option-delete", defaultMessage: "Delete" }),
        data: item,
        onClick: (
          ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
          item?: IContextualMenuItem
        ) => runDeleteDialog(dispatch, [item?.data], navigate, actionHandlers.onDelete)
      }
    ];

    return menuItems;
  };

  function _onClickHistory(
    ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    item?: IContextualMenuItem
  ) {
    if (item) {
      navigate(ToRoute.processHistory(item.data.wpProcessId));
    }
  }

  function _onClickEdit(
    ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    item?: IContextualMenuItem
  ) {
    if (item) {
      navigate(ToRoute.process(item.data.itemId ?? item.data.ID));
    }
  }

  function _onClickNewButton(ev?: any) {
    navigate(ToRoute.newProcessDialog());
  }

  function _onClickHistoryButton(ev?: any) {
    if (selectedItems.length === 1) {
      navigate(ToRoute.processHistory(selectedItems[0].wpProcessId));
    }
  }

  function _onClickEditButton(ev?: any) {
    if (selectedItems.length === 1) {
      navigate(ToRoute.process(selectedItems[0].itemId ?? selectedItems[0].ID));
    }
  }

  const onImportClick = () => {
    uploadRef.current!.open();
  };

  const onFileUpload = (data: string | ArrayBuffer | null) => {
    const json = tryParseJson(data as string);

    dispatch(importProcessConfiguration(json, navigate));
  };

  const getCmdItemsMoreSelected = (
    actionHandlers: ListViewActionHandlers
  ): ICommandBarItemProps[] => {
    return [
      {
        key: "delete",
        iconProps: { iconName: "Delete" },
        text: intl.formatMessage({
          id: "button-delete",
          defaultMessage: "Delete"
        }),
        onClick: () => runDeleteDialog(dispatch, selectedItems, navigate, actionHandlers.onDelete)
      }
    ];
  };

  const getCmdItems = (actionHandlers: ListViewActionHandlers): ICommandBarItemProps[] => {
    const items: ICommandBarItemProps[] = [
      {
        key: "add",
        onRender: () => (
          <PrimaryButton
            iconProps={{ iconName: "Add" }}
            onClick={_onClickNewButton}
            styles={{ root: { marginRight: "8px" } }}
          >
            {intl.formatMessage({
              id: "button-new",
              defaultMessage: "New"
            })}
          </PrimaryButton>
        )
      },
      {
        key: "import",
        iconProps: { iconName: "Import" },
        name: intl.formatMessage({ id: "option-import", defaultMessage: "Import" }),
        onClick: onImportClick
      },
      {
        key: "connections",
        iconProps: { iconName: "PlugDisconnected" },
        name: intl.formatMessage({ id: "option-connections", defaultMessage: "Connections" }),
        onClick: () => setIsConnectionsPanelOpen(true)
      }
    ];

    if (selectedItems.length === 1) {
      items.push(
        ...[
          {
            key: "edit",
            iconProps: { iconName: "Edit" },
            text: intl.formatMessage({
              id: "button-edit",
              defaultMessage: "Edit"
            }),
            onClick: _onClickEditButton
          },
          {
            key: "history",
            iconProps: { iconName: "TimeEntry" },
            name: intl.formatMessage({ id: "option-history", defaultMessage: "History" }),
            onClick: _onClickHistoryButton
          },
          {
            key: "enable",
            iconProps: {
              iconName:
                selectedItems[0]?.wpProcessStatus === ProcessStatus.Disabled
                  ? "Refresh"
                  : "DisableUpdates"
            },
            text:
              selectedItems[0]?.wpProcessStatus === ProcessStatus.Disabled ? "Enable" : "Disable", // CTRLLANG
            onClick: () => runDisableDialog(dispatch, selectedItems[0])
          },
          {
            key: "export",
            iconProps: { iconName: "Export" },
            name: intl.formatMessage({ id: "option-export", defaultMessage: "Export" }),
            onClick: () => exportProcess(selectedItems[0].wpProcessId)
          },
          {
            key: "save",
            iconProps: { iconName: "Save" },
            text: intl.formatMessage({ id: "button-save-as", defaultMessage: "Save As" }),
            onClick: () =>
              runSaveAsDialog(dispatch, inputEl, selectedItems[0], actionHandlers.onAdd)
          }
        ]
      );
    }

    if (selectedItems.length >= 1) {
      items.push(...getCmdItemsMoreSelected(actionHandlers));
    }

    return items;
  };

  const numberSelected: ICommandBarItemProps = {
    key: "selected",
    onRender: () => (
      <>
        {selectedItems.length > 0 && (
          <Text styles={{ root: { width: "100%", alignSelf: "center", marginRight: "10px" } }}>
            {selectedItems.length + " Selected"}
          </Text>
        )}
      </>
    )
  };

  const searchBox: ICommandBarItemProps = {
    key: "search",

    onRender: () => (
      <SearchBox
        styles={{ root: { width: "100%", alignSelf: "center" } }}
        placeholder="Search"
        onChange={_onSearch}
        value={searchText}
      />
    )
  };

  const _onSearch = (ev?: React.ChangeEvent<HTMLInputElement>, value?: string) => {
    setSearchText(value);
  };

  const setGroupCollapse = (groups: IGroup[], group: IGroup) => {
    groups.forEach((g: IGroup) => {
      if (g.key === group.key) {
        g.isCollapsed = !group.isCollapsed;
      } else if (g.children) {
        setGroupCollapse(g.children, group);
      }
    });
  };

  return (
    <>
      <FileUpload onUpload={onFileUpload} ref={uploadRef} />
      <ListView
        apiClient={apiClient}
        listName="lists/WorkPointProcess"
        emptyMessage={intl.formatMessage({
          id: "no-processes-message",
          defaultMessage:
            "No processes is configured. Please click on the new button to create a new process."
        })}
        viewName=""
        selection={selection}
        getContextualMenuItems={getContextualMenuItems}
        getCommandBarItems={getCmdItems}
        getCommandBarFarItems={() => {
          return [numberSelected, searchBox];
        }}
        selectionMode={SelectionMode.multiple}
        isHeaderVisible={true}
        layoutMode={DetailsListLayoutMode.justified}
        constrainMode={ConstrainMode.unconstrained}
        checkboxVisibility={CheckboxVisibility.onHover}
        onTitleLinkClick={(item?: any) => {
          if (item) {
            navigate(ToRoute.process(item.itemId ?? item.ID));
          }
        }}
        getItemLink={(item: any) => (
          <Link
            to={ToRoute.process(item.itemId ?? item.ID)}
            style={{ color: WorkPointTheme.palette.themePrimary }}
          >
            {item["Title"]}
          </Link>
        )}
        onGetItems={(state: ListViewData) => {
          dispatch(setProcessListState(cloneDeep(state)));
        }}
        onGroupCollapse={(group: IGroup) => {
          if (processList?.groups) {
            const groups = cloneDeep(processList.groups);
            setGroupCollapse(groups, group);
            dispatch(setProcessListState({ ...processList, groups }));
          }
        }}
        {...listViewData}
        searchText={searchText}
      />
      {isConnectionsPanelOpen && (
        <ConnectionsPanel isOpen={true} onClose={() => setIsConnectionsPanelOpen(false)} />
      )}
      <Outlet />
    </>
  );
};
