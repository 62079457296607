import React, { RefObject } from "react";
import {
  ProcessStepConfiguration,
  ProcessConfiguration,
  ProcessStepParameterConfiguration,
  ProcessItem,
  ComparisonOperator,
  DataSource,
  JoinOperator,
  ExpressionDescriptor,
  ExpressionDescriptorRule,
  ExpressionDescriptorValueType,
  ProcessParameterProperty,
  ProcessType,
  ProcessStatus,
  DisplayTemplateType,
  DisplayTemplate,
  ConfigurationParameterType,
  ProcessTrigger,
  ProcessStepType,
  ProcessTriggerType,
  DataSourceType,
  TextDescriptor,
  TransitionType
} from "@workpoint/components/lib/models/ProcessConfiguration";
import { ProcessValidationMessage, ValidationMessageType } from "../models/ProcessValidation";
import { OpenAPIV2 } from "openapi-types";
import { getSPHostUrl, isEmpty } from "./commonUtils";
import { getDefinitionByOperationId, getDynamicData, parseSchema } from "./swaggerUtils";
import { validateExpression } from "@workpoint/components/lib/helpers/expressionUtils";
import { evaluateProcessExpressionLimited } from "@workpoint/components/lib/helpers/expressionUtils";
import Ajv from "ajv";
import { ITextField, TextField } from "@fluentui/react";
import { showDialog } from "../store/dialogReducer";
import { DialogResult, DialogType } from "../components/common/Dialog";
import {
  deleteProcess,
  ProcessAccounts,
  saveProcessAs,
  toggleProcessEnable
} from "../store/processReducer";
import { FormControlType } from "../components/common/parametersForm/ParametersForm";
import { ProcessContext } from "@workpoint/components/lib/models/ProcessInstance";
import { set, isEqual, cloneDeep, get } from "lodash";
import { AppDispatch } from "../store/store";
import { NavigateFunction } from "react-router-dom";
import { ApiClient } from "@workpoint/components/lib/clients/ApiClient";

export enum RowType {
  Trigger = "triggers",
  Step = "steps",
  None = "none"
}

export const internalParameters = ["WorkPoint365Url"];

export const hasPublishedVersion = (process: ProcessItem) => {
  return parseInt(process?.version?.split(".")[0]) > 0;
};

export const isDraftVersion = (process: ProcessItem) => {
  const versionSlipt = process?.version?.split(".");
  return versionSlipt.length > 1 && parseInt(versionSlipt[1]) > 0;
};

export const getStepType = (type: string) => {
  if (type === ProcessStepType.User) return "Input Step";
  else if (type === ProcessStepType.Action) return "Process Step";

  return "";
};

export const isTranslationEmpty = (value: TextDescriptor) => {
  return isEmpty(value.defaultText) && isConditionsEmpty(value);
};

export const isExpressionEmpty = (value?: ExpressionDescriptor, defaultData?: any) => {
  return (isEmpty(value?.data) || value?.data === defaultData) && isConditionsEmpty(value);
};

export const isConditionsEmpty = (value?: ExpressionDescriptor) => {
  return isEmpty(value?.expression) && isRulesEmpty(value?.rules);
};

export const getDefaultValueByParameterProperty = (type: ProcessParameterProperty) => {
  switch (type) {
    case ProcessParameterProperty.Value:
      return undefined;
    case ProcessParameterProperty.Readonly:
      return false;
    case ProcessParameterProperty.Required:
      return false;
    case ProcessParameterProperty.Hidden:
      return false;
  }

  return undefined;
};

export const isRulesEmpty = (rules?: ExpressionDescriptorRule[]) => {
  if (isEmpty(rules)) {
    return true;
  }

  if (rules!.length === 1) {
    const rule: ExpressionDescriptorRule = rules![0];

    if (
      rule.type1 === ExpressionDescriptorValueType.Text &&
      isEmpty(rule.value1) &&
      (rule.comparisonOperator === ComparisonOperator.None ||
        (rule.comparisonOperator === ComparisonOperator.EqualTo &&
          rule.type2 === ExpressionDescriptorValueType.Text &&
          isEmpty(rule.value2)))
    ) {
      return true;
    }
  }
  return false;
};

export const isDataSourceEmpty = (value?: DataSource) => {
  if (value && (value.type || (value.tabs && value.tabs.length > 0))) {
    return false;
  }
  return true;
};

export const defaultProcessItem = (id: string): ProcessItem => {
  return {
    description: "",
    title: "",
    type: ProcessType.User,
    status: ProcessStatus.Draft,
    version: "0.0",
    configuration: defaultProcessConfiguration(id),
    isTemplate: false,
    editor: { id: "", title: "" },
    author: { id: "", title: "" },
    created: new Date().toLocaleString(),
    modified: new Date().toLocaleString()
  };
};

export const defaultProcessConfiguration = (id: string): ProcessConfiguration => {
  return {
    id,
    title: { id: `${id}-title`, defaultText: "" },
    description: { id: `${id}-description`, defaultText: "" },
    type: ProcessType.User,
    version: "0.0",
    steps: [],
    localization: { defaultLanguage: "en", additionalLanguages: [] },
    start: {
      buttonTitle: { id: `${id}-buttonTitle`, defaultText: "" },
      message: { id: `${id}-startMessage`, defaultText: "" },
      sharePoint: {},
      express: {},
      teams: {}
    },
    finish: {
      message: { id: `${id}-finishMessage`, defaultText: "" },
      sharePoint: {},
      express: {},
      teams: {}
    }
  };
};

export const defaultExpression = (): ExpressionDescriptor => {
  return {
    data: undefined, //we need this undefined because of x-workpoint-dynamic-columns uses field array
    rules: [],
    expression: undefined
  };
};

export const defaultTextDesciptor = (id: string): TextDescriptor => {
  return {
    id,
    defaultText: ""
  };
};

export const defaultDisplayTemplate = (): DisplayTemplate => {
  return { type: DisplayTemplateType.AdaptiveCard, template: "" };
};

export const defaultDataSource = (
  titleId?: string,
  errorId?: string,
  title?: string,
  error?: string
): DataSource => {
  return {
    type: DataSourceType.Default,
    title: {
      id: titleId ?? "",
      defaultText: title ?? ""
    },
    errorMessage: {
      id: errorId ?? "",
      defaultText: error ?? ""
    },
    endpoint: undefined,
    parameters: [],
    path: defaultExpression(),
    valueProperty: defaultExpression(),
    titleProperty: defaultExpression(),
    descriptionProperty: defaultExpression(),
    itemDisplayTemplate: defaultDisplayTemplate(),
    filter: defaultExpression(),
    anchor: defaultExpression(),
    multi: false,
    typeahead: true,
    tabs: []
  };
};

export const defaultExpressionRule = (
  comparisonOperator?: ComparisonOperator
): ExpressionDescriptorRule => {
  return {
    type1: ExpressionDescriptorValueType.Text,
    value1: "",
    comparisonOperator: comparisonOperator ?? ComparisonOperator.EqualTo,
    type2: ExpressionDescriptorValueType.Text,
    value2: "",
    joinOperator: JoinOperator.And,
    closeBracket: false,
    openBracket: false
  };
};

export enum ContextType {
  Default = "default",
  Start = "start"
}

export const defaultContext = async (
  apiClient: ApiClient,
  dispatch: AppDispatch,
  process: ProcessConfiguration,
  document: OpenAPIV2.Document,
  step?: ProcessStepConfiguration | ProcessTrigger,
  dynamicData?: { [key: string]: any },
  contextType?: ContextType
) => {
  const context: ProcessContext = {
    CurrentUser: { Id: 0, Title: "", UserPrincipalName: "", Email: "" },
    Solution: { Url: "" },
    BusinessModule: { Id: "", Name: "", Title: "", EntityName: "", EntityTitle: "" },
    Entity: {
      ID: 0,
      Title: "",
      ContentType: "",
      ContentTypeId: "",
      wpSite: "",
      Author: {},
      Editor: {},
      Created: "",
      Modified: ""
    },
    Process: { Id: "", Name: "", Version: "" },
    Instance: { Id: "", Url: "", Status: "", Parent: { Context: {} } },
    Parameters: {
      BusinessModuleId: "",
      BusinessModuleContentType: "",
      EntitySiteList: "",
      EntitySiteListContentType: "",
      EntitySiteListFolder: ""
    },
    Steps: {},
    TeamsActivity: {
      ConversationId: "",
      ConversationType: "",
      TenantId: "",
      MessageId: "",
      ParentMessageId: "",
      TeamId: "",
      ChannelId: ""
    }
  };

  context["Instance"]["Parent"] = cloneDeep(context);

  if (process.type === ProcessType.User) {
    context["Items"] = [{ ID: 0, Title: "", ContentType: "", ContentTypeId: "" }];
    context["Location"] = {
      WebUrl: "",
      List: "",
      Folder: ""
    };
  } else if (process.type === ProcessType.System) {
    // context["List"] = "";
    context["Trigger"] = {
      Type: "",
      WebUrl: "",
      ListId: "",
      ItemId: 0
    };
    if (
      process.triggers?.some((trigger) => trigger.type.startsWith("Item")) &&
      (Object.values(ProcessTriggerType).indexOf((step as ProcessTrigger).type) < 0 ||
        (step as ProcessTrigger).type.startsWith("Item"))
    ) {
      context["Item"] = {
        ID: 0,
        Title: "",
        ContentType: "",
        ContentTypeId: "",
        Author: {},
        Editor: {},
        Created: "",
        Modified: ""
      };
    }
  }

  if (Object.values(ProcessTriggerType).indexOf((step as ProcessTrigger).type) >= 0) {
    await setTriggerDetailsOnContext(
      apiClient,
      dispatch,
      document,
      step as ProcessTrigger,
      context,
      true
    );
  } else {
    if (process.triggers) {
      for await (const trigger of process.triggers) {
        await setTriggerDetailsOnContext(apiClient, dispatch, document, trigger, context, true);
      }
    }
  }

  if (contextType === ContextType.Start) {
    return context;
  }

  for (let i = 0; i < process.steps.length; i++) {
    const s = process.steps[i];
    if (s === step && s.type === "action") {
      break;
    }
    context[s.name] = {
      // Settings: {
      //   id: "",
      //   name: "",
      //   type: "",
      //   title: "",
      //   description: "",
      //   definition: "",
      //   subProcessConfigurationId: "",
      //   icon: "",
      //   contextObjectName: ""
      // }
    };

    s.parameters.forEach((parameter) => {
      if (parameter.parent) {
        context[s.name][parameter.name] = {};
      }
    });

    if (dynamicData?.[s.id]) {
      Object.keys(dynamicData[s.id]).forEach((name) => {
        if (
          dynamicData[s.id]?.[name] &&
          typeof dynamicData[s.id]?.[name] === "object" &&
          !Array.isArray(dynamicData[s.id]?.[name])
        ) {
          Object.keys(dynamicData[s.id]?.[name]).forEach((key) => {
            set(context, `${s.name}.${key}`, {});
          });
        }
      });

      // to support multi dynamic properties
      // s.parameters.forEach((parameter) => {
      //   if (parameter.parent) {
      //     if (!context[s.name][parameter.parent]) {
      //       context[s.name][parameter.parent] = {};
      //     }
      //     context[s.name][parameter.parent][parameter.name] = {};
      //   } else {
      //     // context[s.name][parameter.name] = {};
      //   }
      // });

      // if (dynamicData?.[s.id]) {
      //   Object.keys(dynamicData[s.id]).forEach((name) => {
      //     if (dynamicData[s.id]?.[name]?.jsonItems?.properties) {
      //       Object.keys(dynamicData[s.id]?.[name]?.jsonItems?.properties).forEach((key) => {
      //         set(context, `${s.name}.${name}.${key}`, {});
      //       });
      //     }
      //     // const data = dynamicData[s.id]?.[name]?.jsonItems?.properties ?? dynamicData[s.id]?.[name];
      //     // if (data) {
      //     //   context[s.name][name] = merge(context[s.name][name], data);
      //     // }
      //   });
    }

    if (s === step) {
      break;
    }

    context.Steps[s.name] = { Status: "", Headline: "", Message: "" };

    //context[s.name].Responses = {};
    const operation = getDefinitionByOperationId(document, s.definition);
    if (operation && operation.definition.responses["200"]) {
      const responseObj = operation.definition.responses["200"] as OpenAPIV2.ResponseObject;
      if (responseObj.schema) {
        const fieldValues: any = {};
        s.parameters.forEach((p) => {
          fieldValues[p.name] = evaluateProcessExpressionLimited(p.value, dynamicData);
        });
        const obj = await getSwaggerSchemaObject(
          apiClient,
          dispatch,
          document,
          fieldValues,
          responseObj.schema,
          s.name
        );
        if (obj.name!.indexOf("]") > -1) {
          delete context[s.name];
        }
        context[obj.name!] = obj.data;

        if (
          operation.definition.parameters?.find(
            (param: any) => param["x-workpoint-control"]?.type === FormControlType.DynamicSchema
          )
        ) {
          s.parameters.forEach((parameter) => {
            if (parameter.parent) {
              const data = getDefaultValueFromType(parameter.dataType ?? "");
              context[s.name][parameter.name] = data;
            }
          });
        }
      }
    }
  }

  return context;
};

const setTriggerDetailsOnContext = async (
  apiClient: ApiClient,
  dispatch: AppDispatch,
  document: OpenAPIV2.Document,
  trigger: ProcessTrigger,
  context: ProcessContext,
  addBeforeProperties?: Boolean
) => {
  const operation = getDefinitionByOperationId(document, trigger.type);
  if (operation && operation.definition.responses["200"]) {
    const responseObj = operation.definition.responses["200"] as OpenAPIV2.ResponseObject;
    if (responseObj.schema) {
      const fieldValues: any = {
        WorkPoint365Url: getSPHostUrl(),
        businessModuleId: trigger.businessModuleId,
        list: trigger.list
      };
      const obj = await getSwaggerSchemaObject(
        apiClient,
        dispatch,
        document,
        fieldValues,
        responseObj.schema,
        "Item"
      );
      if (trigger.type.startsWith("Item")) {
        context.Item = { ...context.Item, ...obj.data };

        const entityOperation = getDefinitionByOperationId(document, "EntityAdded");
        if (entityOperation && entityOperation.definition.responses["200"]) {
          const entityResponseObj = entityOperation.definition.responses
            .default as OpenAPIV2.ResponseObject;
          if (entityResponseObj.schema) {
            const entityObj = await getSwaggerSchemaObject(
              apiClient,
              dispatch,
              document,
              fieldValues,
              entityResponseObj.schema,
              "Item"
            );
            context.Entity = { ...context.Entity, ...entityObj.data };
          }
        }
      } else if (
        trigger.type.startsWith("Entity") ||
        trigger.type === ProcessTriggerType.StageChanged
      ) {
        context.Entity = { ...context.Entity, ...obj.data };
      }
      if (
        addBeforeProperties &&
        (trigger.type === ProcessTriggerType.EntityUpdated ||
          trigger.type === ProcessTriggerType.ItemUpdated)
      ) {
        if (trigger.type === ProcessTriggerType.ItemUpdated) {
          Object.keys(context.Item ?? {}).forEach((key) => {
            if (!key.endsWith("_before")) context.Item[`${key}_before`] = context.Item[key];
          });
        } else if (trigger.type === ProcessTriggerType.EntityUpdated) {
          Object.keys(context.Entity ?? {}).forEach((key) => {
            if (!key.endsWith("_before")) context.Entity![`${key}_before`] = context.Entity![key];
          });
        }
      }
    }
  }
};

export const getSwaggerSchemaObject = async (
  apiClient: ApiClient,
  dispatch: AppDispatch,
  document: OpenAPIV2.Document,
  fieldValues: any,
  schema: OpenAPIV2.SchemaObject,
  name?: string
) => {
  let data: any = {};

  switch (schema.type) {
    case "array":
      if (name) {
        name = `${name}[0]`;
      }
      if (schema.items) {
        const itemsObj: any = schema.items as OpenAPIV2.ItemsObject;
        if (itemsObj.properties) {
          for (const propertyName of Object.keys(itemsObj.properties)) {
            const obj = await getSwaggerSchemaObject(
              apiClient,
              dispatch,
              document,
              fieldValues,
              itemsObj.properties[propertyName],
              propertyName
            );
            if (obj.name) {
              data[obj.name] = obj.data;
            } else {
              data = { ...data, ...obj.data };
            }
          }
        }
      }
      break;
    case "object":
      let properties: { [name: string]: any } = {};
      if (schema["x-ms-dynamic-schema"]) {
        const dynamicSchema = await getDynamicData(
          apiClient,
          dispatch,
          document,
          schema["x-ms-dynamic-schema"],
          fieldValues
        );
        if (dynamicSchema.data?.jsonItems) {
          if (dynamicSchema.data.jsonItems["$schema"]) {
            const jsonSchema: any = await parseSchema(dynamicSchema.data.jsonItems);
            const obj = await getSwaggerSchemaObject(
              apiClient,
              dispatch,
              document,
              fieldValues,
              jsonSchema,
              name
            );
            if (obj.name) {
              name = obj.name;
              data = obj.data;
            } else {
              data = { ...data, ...obj.data };
            }
          } else if (dynamicSchema.data.jsonItems.properties) {
            properties = dynamicSchema.data.jsonItems.properties;
          }
        }
      } else if (schema["x-ms-dynamic-values"]) {
        const dynamicSchema = await getDynamicData(
          apiClient,
          dispatch,
          document,
          schema["x-ms-dynamic-values"],
          fieldValues
        );
        properties = dynamicSchema.data?.jsonItems?.properties ?? dynamicSchema.data ?? {};
      } else if (schema.properties) {
        properties = schema.properties;
      }

      if (properties) {
        for (const propertyName of Object.keys(properties)) {
          const obj = await getSwaggerSchemaObject(
            apiClient,
            dispatch,
            document,
            fieldValues,
            properties[propertyName],
            propertyName
          );
          if (obj.name) {
            data[obj.name] = obj.data;
          } else {
            data = { ...data, ...obj.data };
          }
        }
      }

      break;
    default:
      const type = schema["x-workpoint-field"]?.type ?? schema.type;
      data = getDefaultValueFromType(type);

      break;
  }

  return { data, name };
};

const getDefaultValueFromType = (type: string) => {
  switch (type) {
    case "LookupMulti":
    case "TaxonomyFieldTypeMulti":
    case "UserMulti":
    case "MultiChoice":
      return [];
    case "Integer":
    case "Number":
    case "Counter":
    case "Currency":
    case "number":
    case "integer":
      return 0;
    case "Choice":
    case "Note":
    case "Text":
    case "string":
      return "";
    case "Lookup":
    case "TaxonomyFieldType":
    case "User":
    case "DateTime":
      return new Date();
    case "Boolean":
      return false;
    default:
      return {};
  }
};

export const getSchemaObject = (property: any, name?: string) => {
  let data: any = {};
  switch (property.type) {
    case "array":
      if (name) {
        name = `${name}[0]`;
      }
      if (property.items?.properties) {
        Object.keys(property.items.properties).forEach((propertyName) => {
          const obj = getSchemaObject(property.items.properties[propertyName], propertyName);
          if (obj.name) {
            data[obj.name] = obj.data;
          } else {
            data = { ...data, ...obj.data };
          }
        });
      }
      break;
    case "object":
      if (property.properties) {
        Object.keys(property.properties).forEach((propertyName) => {
          const obj = getSchemaObject(property.properties[propertyName], propertyName);
          if (obj.name) {
            data[obj.name] = obj.data;
          } else {
            data = { ...data, ...obj.data };
          }
        });
      }
      break;
    default:
      break;
  }

  return { data, name };
};

export const validateProcess = (
  document: OpenAPIV2.Document,
  config: ProcessConfiguration,
  accounts?: ProcessAccounts,
  loadingAccounts?: boolean
): ProcessValidationMessage[] => {
  let messages: ProcessValidationMessage[] = [];
  config.steps.forEach((step) => {
    messages = [...messages, ...validateProcessStep(document, config, step)];
  });
  config.triggers?.forEach((trigger) => {
    messages = [...messages, ...validateProcessTrigger(document, config, trigger)];
  });
  if (
    !loadingAccounts &&
    config.triggers &&
    config.triggers.length > 0 &&
    (!accounts?.accounts || accounts.accounts.length === 0)
  ) {
    messages.push({
      type: ValidationMessageType.Error,
      message: "Process connection is not configured.",
      stepId: config.triggers[0].id,
      rowType: RowType.Trigger
    });
  }
  return messages;
};

export const validateProcessTrigger = (
  document: OpenAPIV2.Document,
  config: ProcessConfiguration,
  trigger: ProcessTrigger
) => {
  const messages = [];

  if (isEmpty(trigger.title.defaultText)) {
    messages.push({
      type: ValidationMessageType.Error,
      message: "Trigger title is required.",
      stepId: trigger.id,
      rowType: RowType.Trigger,
      formFieldName: `${formDefaultParameters.title.formKey}.defaultText`
    });
  }

  const operation = getDefinitionByOperationId(document, trigger.type);
  operation?.definition.parameters?.forEach((p) => {
    const param = p as OpenAPIV2.Parameter;
    if (
      internalParameters.indexOf(param.name) < 0 &&
      param.required &&
      !(trigger as any)[param.name]
    ) {
      messages.push({
        type: ValidationMessageType.Error,
        message: `${param["x-ms-summary"]} is required.`,
        stepId: trigger.id,
        rowType: RowType.Trigger,
        formFieldName: `${param.name}.${ProcessParameterProperty.Value}.data`
      });
    }
  });

  return messages;
};

export const validateProcessStep = (
  document: OpenAPIV2.Document,
  config: ProcessConfiguration,
  step: ProcessStepConfiguration | any
) => {
  const messages: ProcessValidationMessage[] = [];
  const checkRequired = isExpressionEmpty(step.contextObjectName);

  if (isEmpty(step.title.defaultText)) {
    messages.push({
      type: ValidationMessageType.Error,
      message: "Step title is required.",
      stepId: step.id,
      rowType: RowType.Step,
      formFieldName: `${formDefaultParameters.title.formKey}.defaultText`
    });
  }

  if (isEmpty(step.name)) {
    messages.push({
      type: ValidationMessageType.Error,
      message: "Step name is required.",
      stepId: step.id,
      rowType: RowType.Step,
      formFieldName: `${formDefaultParameters.name.formKey}`
    });
  } else {
    const duplicatedNameStep = config.steps.find((s) => s.name === step.name && s.id !== step.id);
    if (duplicatedNameStep) {
      messages.push({
        stepId: step.id,
        parameterName: "name",
        type: ValidationMessageType.Error,
        message: `Name '${duplicatedNameStep.name}' is already used in step '${duplicatedNameStep.title.defaultText}'`,
        rowType: RowType.Step,
        formFieldName: `${formDefaultParameters.name.formKey}`
      });
    }

    // regex for spaces and special characters except _ and -
    if (step.name.match(/^[a-zA-Z0-9_-]*$/g) === null) {
      messages.push({
        stepId: step.id,
        parameterName: "name",
        type: ValidationMessageType.Error,
        message: "Name should not contain spaces or special characters",
        rowType: RowType.Step,
        formFieldName: `${formDefaultParameters.name.formKey}`
      });
    }
  }

  const operation = getDefinitionByOperationId(document, step.definition);
  operation?.definition.parameters?.forEach((p) => {
    const param = p as OpenAPIV2.Parameter;
    if (
      internalParameters.indexOf(param.name) < 0 &&
      (!param.schema || !param.schema["x-ms-dynamic-schema"])
    ) {
      const paramConfig = step.parameters.find((sp: any) => sp.name === param.name);
      if (
        param.schema &&
        param.schema.properties &&
        Object.keys(param.schema.properties).length > 0
      ) {
        const paramRef = param.schema as OpenAPIV2.SchemaObject;
        Object.keys(paramRef.properties!).forEach((paramName) => {
          const refParam = paramRef.properties![paramName];
          const refParamConfig = step.parameters.find((sp: any) => sp.name === paramName);
          const required =
            paramRef.required !== undefined && paramRef.required?.indexOf(paramName) > -1;
          if (required) {
            if (
              (checkRequired || refParamConfig?.type === ConfigurationParameterType.Static) &&
              (!refParamConfig || isExpressionEmpty(refParamConfig.value))
            ) {
              messages.push({
                type: ValidationMessageType.Error,
                message: `Parameter "${
                  paramRef.properties![paramName]["x-ms-summary"] ??
                  paramRef.properties![paramName].title ??
                  paramName
                }" value is required.`,
                stepId: step.id,
                parameterName: paramName,
                rowType: RowType.Step,
                formFieldName: `${param.name}.${ProcessParameterProperty.Value}.data`
              });
            }
          }
        });
      } else if (param.required) {
        if (
          (checkRequired || paramConfig?.type === ConfigurationParameterType.Static) &&
          (!paramConfig || isExpressionEmpty(paramConfig.value))
        ) {
          messages.push({
            type: ValidationMessageType.Error,
            message: `Parameter "${
              param["x-ms-summary"] ?? param.title ?? param.name
            }" value configuration is required.`,
            stepId: step.id,
            parameterName: param.name,
            rowType: RowType.Step,
            formFieldName: `${param.name}.${ProcessParameterProperty.Value}.data`
          });
        }
      }
    }
  });

  for (let param of step.parameters) {
    if (param.type === ConfigurationParameterType.Custom) {
      if ((param.name as string).match(/[^a-zA-Z0-9_]|^[^a-zA-Z]/g) !== null) {
        messages.push({
          type: ValidationMessageType.Error,
          message: `Name on "${param.title.defaultText}" must start with a letter and contain only letters, numbers and underscores.`,
          stepId: step.id,
          rowType: RowType.Step,
          formFieldName: `Fields.${param.name}.name`
        });
      }
    }
  }

  const customValidationParams = ["dataType", "name"];

  step.parameters.forEach((paramConfig: any, index: number) => {
    validateParameter(step, messages, paramConfig);
    if (paramConfig.type === ConfigurationParameterType.Custom) {
      customValidationParams.forEach((validParam) => {
        if (isEmpty(get(paramConfig, validParam))) {
          messages.push({
            type: ValidationMessageType.Error,
            message: `Missing ${validParam.split(".")[0]} in step '${
              step.title.defaultText
            }' for field ${index}.`,
            stepId: step.id,
            rowType: RowType.Step,
            formFieldName: `Fields.${paramConfig.name}.${validParam}`
          });
        }
      });
    }
    if (paramConfig.name === "searchConfiguration") {
      validateSearchConfig(step, messages, paramConfig);
    } else if (paramConfig.name === "BuilderRelations") {
      validateRelation(step, messages, paramConfig);
    }
  });

  (step as ProcessStepConfiguration).execution?.transitions?.forEach((t, index) => {
    const executionValidationParams = ["title.defaultText", "type"];
    if (t.type === TransitionType.Before) {
      executionValidationParams.push("action");
    }
    executionValidationParams.forEach((param) => {
      if (isEmpty(get(t, param))) {
        messages.push({
          type: ValidationMessageType.Error,
          message: `Missing ${param.split(".")[0]} in step '${
            step.title.defaultText
          }' for execution rule number ${index + 1}.`,
          stepId: step.id,
          rowType: RowType.Step,
          formFieldName: `${formDefaultParameters.execution.formKey}.transitions[${index}].${param}`
        });
      }
    });
  });

  return messages;
};

const validateSearchConfig = (
  step: ProcessStepConfiguration,
  messages: ProcessValidationMessage[],
  config: ProcessStepParameterConfiguration
) => {
  config.value?.data?.forEach((d: any, index: number) => {
    const searchValidationParams = [];
    if (d.type === "Search") {
      searchValidationParams.push("searchResultGroup", "searchSettings");
    } else if (d.type === "Templates") {
      searchValidationParams.push("templateList");
    } else if (d.type === "ListItems") {
      searchValidationParams.push("businessModuleId", "entityId", "list");
    } else if (d.type === "EntityItems") {
      searchValidationParams.push("list");
    }
    if (isEmpty(get(d, "title.defaultText"))) {
      messages.push({
        type: ValidationMessageType.Error,
        message: `Missing title in step '${step.title.defaultText}' for search configuration ${
          index + 1
        }.`,
        stepId: step.id,
        rowType: RowType.Step,
        formFieldName: `searchConfiguration.value.data[${index}].title.defaultText`
      });
    }
    searchValidationParams.forEach((param) => {
      if (isExpressionEmpty(get(d, param), "")) {
        messages.push({
          type: ValidationMessageType.Error,
          message: `Missing ${param.split(".")[0]} in step '${step.title.defaultText}' for ${
            config.value?.data?.[index]?.title?.defaultText
          }.`,
          stepId: step.id,
          rowType: RowType.Step,
          formFieldName: `searchConfiguration.value.data[${index}].${param}.data`
        });
      }
    });
  });
};

const validateRelation = (
  step: ProcessStepConfiguration,
  messages: ProcessValidationMessage[],
  config: ProcessStepParameterConfiguration
) => {
  config.value?.data?.forEach((d: any, index: number) => {
    if (isEmpty(get(d, "title.defaultText"))) {
      messages.push({
        type: ValidationMessageType.Error,
        message: `Missing title in step '${step.title.defaultText}' for relation ${index + 1}.`,
        stepId: step.id,
        rowType: RowType.Step,
        formFieldName: `BuilderRelations.value.data[${index}].title.defaultText`
      });
    }
    ["ListId", "EntityId"].forEach((param) => {
      if (isExpressionEmpty(get(d, param), "")) {
        messages.push({
          type: ValidationMessageType.Error,
          message: `Missing ${param.split(".")[0]} in step '${step.title.defaultText}' for ${
            d.title.defaultText
          }.`,
          stepId: step.id,
          rowType: RowType.Step,
          formFieldName: `BuilderRelations.value.data[${index}].${param}.data`
        });
      }
    });
  });
};

const validateParameter = (
  step: ProcessStepConfiguration,
  messages: ProcessValidationMessage[],
  config: ProcessStepParameterConfiguration
) => {
  if (config.dataSource) {
    // TODO param.dataSource.type
  }
  validateProcessExpression(step, messages, config, ProcessParameterProperty.Value, config.value);
  validateProcessExpression(
    step,
    messages,
    config,
    ProcessParameterProperty.Required,
    config.required
  );
  validateProcessExpression(step, messages, config, ProcessParameterProperty.Hidden, config.hidden);
  validateProcessExpression(
    step,
    messages,
    config,
    ProcessParameterProperty.Readonly,
    config.readonly
  );
  validateProcessExpression(step, messages, config, ProcessParameterProperty.Valid, config.valid);
};

const validateProcessExpression = (
  step: ProcessStepConfiguration,
  messages: ProcessValidationMessage[],
  config: ProcessStepParameterConfiguration,
  propType: string,
  se?: ExpressionDescriptor
) => {
  if (se) {
    if (se.expression) {
      let isValid = false;
      try {
        validateExpression(se.expression);
        isValid = true;
      } catch (error) {
        const err = error;
        console.log(err);
      }

      if (!isValid) {
        messages.push({
          type: ValidationMessageType.Error,
          message: `Parameter "${
            config.title?.defaultText ?? config.name
          }" ${propType} expression is invalid.`,
          stepId: step.id,
          rowType: RowType.Step,
          parameterName: config.name,
          formFieldName: `${config.name}.${propType}.data`
        });
      }
    }

    // se.rules?.forEach((rule) => {
    //   validateProcessExpression(step, param, messages, "Rule Value 1", rule.value1);
    //   validateProcessExpression(step, param, messages, "Rule Value 2", rule.value2);
    // });
  }
};

export const validateProcessWithSchema = (objectToValidate: any): any[] => {
  const ajv = new Ajv({ allErrors: true });
  const validate = ajv.compile(processSchema);
  const valid = validate(objectToValidate);
  if (valid) {
    return [];
  } else {
    return validate.errors ?? [];
  }
};

const processSchema = {
  type: "object",
  nullable: true,
  definitions: {
    stepExpression: {
      type: "object",
      properties: {
        data: {},
        rules: {
          type: "array",
          nullable: true,
          items: { $ref: "#/definitions/rule" }
        },
        expression: { type: "string", nullable: true }
      },
      required: []
    },
    rule: {
      type: "object",
      properties: {
        openBracket: { type: "boolean" },
        type1: { type: "string" },
        value1: {},
        comparisonOperator: { type: "string" },
        type2: { type: "string" },
        value2: {},
        closeBracket: { type: "boolean" },
        joinOperator: { type: "string" }
      },
      required: []
    },
    group: {
      type: "object",
      properties: {
        id: { type: "string" },
        title: { type: "string" }
      },
      required: []
    },
    person: {
      type: "object",
      properties: {
        id: { type: "number" },
        title: { type: "string" }
      },
      required: []
    },
    configuration: {
      type: "object",
      properties: {
        id: { type: "string" },
        title: { $ref: "#/definitions/text" },
        description: { $ref: "#/definitions/textNullable" },
        buttonTitle: { $ref: "#/definitions/textNullable" },
        type: { type: "string" },
        steps: {
          type: "array",
          items: { $ref: "#/definitions/step" }
        },
        localization: { $ref: "#/definitions/localization" }
      },
      required: []
    },
    text: {
      type: "object",
      properties: {
        id: { type: "string" },
        defaultText: { type: "string" }
      },
      required: ["id", "defaultText"]
    },
    textNullable: {
      type: "object",
      nullable: true,
      properties: {
        id: { type: "string" },
        defaultText: { type: "string" }
      },
      required: []
    },
    step: {
      type: "object",
      properties: {
        id: { type: "string" },
        name: { type: "string" },
        title: { $ref: "#/definitions/text" },
        description: { $ref: "#/definitions/text" },
        definition: { type: "string" },
        subProcessConfigurationId: { $ref: "#/definitions/stepExpression" },
        icon: { type: "string", nullable: true },
        parameters: {
          type: "array",
          items: { $ref: "#/definitions/stepParameter" }
        },
        contextObjectName: { $ref: "#/definitions/stepExpression" },
        valid: { $ref: "#/definitions/stepExpression" }
      },
      required: []
    },
    localization: {
      type: "object",
      nullable: true,
      properties: {
        defaultLanguage: { type: "string" },
        additionalLanguages: {
          type: "array",
          items: {
            type: "object",
            properties: {
              language: { type: "string" },
              messages: { type: "object", required: [] } //fix
            },
            required: []
          }
        }
      },
      required: []
    },
    stepParameter: {
      type: "object",
      properties: {
        name: { type: "string" },
        type: { type: "string" },
        title: { $ref: "#/definitions/textNullable" },
        description: { $ref: "#/definitions/textNullable" },
        parent: { type: "string", nullable: true },
        dataSource: { $ref: "#/definitions/datasource" },
        value: { $ref: "#/definitions/stepExpression" },
        visible: { $ref: "#/definitions/stepExpression" },
        readonly: { $ref: "#/definitions/stepExpression" },
        required: { $ref: "#/definitions/stepExpression" },
        valid: { $ref: "#/definitions/stepExpression" }
      },
      required: []
    },
    datasource: {
      type: "object",
      nullable: true,
      properties: {
        type: { type: "string" },
        title: { $ref: "#/definitions/textNullable" },
        endpoint: { type: "string", nullable: true },
        property: { $ref: "#/definitions/stepExpression" },
        filter: { $ref: "#/definitions/stepExpression" },
        anchor: { $ref: "#/definitions/stepExpression" },
        multi: { type: "boolean", nullable: true },
        tabs: {
          type: "array",
          nullable: true,
          items: { $ref: "#/definitions/datasource" },
          required: []
        }
      },
      required: []
    }
  },
  properties: {
    itemId: { type: "number", nullable: true },
    parentId: { type: "number", nullable: true },
    title: { type: "string" },
    description: { type: "string" },
    type: { type: "string" },
    status: { type: "string" },
    version: { type: "string" },
    groups: {
      type: "array",
      nullable: true,
      items: { $ref: "#/definitions/group" }
    },
    configuration: { $ref: "#/definitions/configuration" },
    created: { type: "string", required: [] }, //fix
    modified: { type: "string", required: [] }, //fix
    author: { $ref: "#/definitions/person" },
    editor: { $ref: "#/definitions/person" }
  },
  required: [
    "title",
    "description",
    "type",
    "status",
    "version",
    "configuration",
    "created",
    "modified",
    "author",
    "editor"
  ]
};

export const runDeleteDialog = (
  dispatch: AppDispatch,
  items: any[],
  navigate: NavigateFunction,
  postDeleteHandler?: (ids: number[]) => void
) => {
  dispatch(
    showDialog({
      type: DialogType.DeleteCancel,
      title: "Delete?", // CTRLLANG
      subText: "Are you sure?", // CTRLLANG
      onClick: (result: DialogResult) => {
        if (result === DialogResult.Ok) {
          items.forEach((item) => {
            dispatch(deleteProcess(item.itemId ?? item.ID, navigate));
          });
          if (postDeleteHandler) {
            postDeleteHandler(items.map((item) => item.itemId ?? item.ID));
          }
        }
      }
    })
  );
};

export const runDisableDialog = (
  dispatch: AppDispatch,
  item: any,
  postDisableHandler?: (item: any, skipGet?: boolean) => void
) => {
  const status = item.wpProcessStatus ?? item.status;
  dispatch(
    showDialog({
      type: DialogType.YesNo,
      title: status === ProcessStatus.Disabled ? "Enable?" : "Disable?", // CTRLLANG
      subText: "Are you sure?", // CTRLLANG
      onClick: (result: DialogResult) => {
        if (result === DialogResult.Ok) {
          dispatch(toggleProcessEnable(item.itemId ?? item.ID, postDisableHandler));
        }
      }
    })
  );
};

export const runSaveAsDialog = (
  dispatch: AppDispatch,
  inputEl: RefObject<ITextField>,
  item: any,
  postSaveAsHandler?: (item: any) => void
) => {
  dispatch(
    showDialog({
      type: DialogType.SaveCancel,
      title: "Create a copy of this process", // CTRLLANG
      subText:
        "We'll create a copy of this process and add it to your process list. You can rename it first if you want. It’ll be saved as a draft.", // CTRLLANG
      onRenderDialog: () => {
        return (
          <TextField
            componentRef={inputEl}
            defaultValue={`Copy of - ${item.title ?? item.Title}`}
          ></TextField>
        );
      },
      onClick: (result: DialogResult) => {
        if (result === DialogResult.Ok && inputEl?.current?.value) {
          dispatch(saveProcessAs(item.itemId ?? item.ID, inputEl.current.value, postSaveAsHandler));
        }
      }
    })
  );
};

export const getRowTypeFromPath = (locationPath: string) => {
  const path = locationPath.toLowerCase();
  if (path.indexOf(`/${RowType.Step}/`) >= 0) {
    return RowType.Step;
  } else if (path.indexOf(`/${RowType.Trigger}/`) >= 0) {
    return RowType.Trigger;
  } else {
    return RowType.None;
  }
};

export enum FormParamType {
  Default,
  Expression
}

export const formDefaultParameters = {
  title: {
    formKey: "__title_",
    objectKey: "title",
    type: FormParamType.Default,
    panels: [RowType.None, RowType.Step, RowType.Trigger]
  },
  description: {
    formKey: "__description_",
    objectKey: "description",
    type: FormParamType.Default,
    panels: [RowType.None, RowType.Step]
  },
  name: {
    formKey: "__name_",
    objectKey: "name",
    type: FormParamType.Default,
    panels: [RowType.Step]
  },
  continueButtonTitle: {
    formKey: "__continueButtonTitle_",
    objectKey: "continueButtonTitle",
    type: FormParamType.Default,
    panels: [RowType.Step]
  },
  contextObjectName: {
    formKey: "__context_",
    objectKey: "contextObjectName",
    type: FormParamType.Expression,
    panels: [RowType.Step]
  },
  buttonTitle: {
    formKey: "__buttonTitle_",
    objectKey: "start.buttonTitle",
    type: FormParamType.Default,
    panels: [RowType.None]
  },
  groups: {
    formKey: "__groups_",
    objectKey: "groups",
    type: FormParamType.Default,
    panels: [RowType.None]
  },
  skipStartStep: {
    formKey: "__skipStartStep_",
    objectKey: "start.sharePoint.skipStartStep",
    type: FormParamType.Expression,
    panels: [RowType.None]
  },
  disableSelectedLoad: {
    formKey: "__disableSelectedLoad_",
    objectKey: "start.disableSelectedLoad",
    type: FormParamType.Expression,
    panels: [RowType.None]
  },
  startMessage: {
    formKey: "__startMessage_",
    objectKey: "start.message",
    type: FormParamType.Expression,
    panels: [RowType.None]
  },
  finishMessage: {
    formKey: "__finishMessage_",
    objectKey: "finish.message",
    type: FormParamType.Expression,
    panels: [RowType.None]
  },
  showRunAgainButton: {
    formKey: "__showRunAgainButton_",
    objectKey: "finish.sharePoint.showRunAgainButton",
    type: FormParamType.Expression,
    panels: [RowType.None]
  },
  reloadOnClose: {
    formKey: "__reloadOnClose_",
    objectKey: "finish.sharePoint.reloadOnClose",
    type: FormParamType.Expression,
    panels: [RowType.None]
  },
  hideCloseButton: {
    formKey: "__hideCloseButton_",
    objectKey: "finish.sharePoint.hideCloseButton",
    type: FormParamType.Expression,
    panels: [RowType.None]
  },
  customButtons: {
    formKey: "__customButtons_",
    objectKey: "finish.sharePoint.customButtons",
    type: FormParamType.Default,
    panels: [RowType.None]
  },
  autoExecute: {
    formKey: "__autoExecute_",
    objectKey: "finish.sharePoint.autoExecute",
    type: FormParamType.Default,
    panels: [RowType.None]
  },
  permissionConditions: {
    formKey: "__permissionConditions_",
    objectKey: "permissions.conditions",
    type: FormParamType.Expression,
    panels: [RowType.None, RowType.Step]
  },
  permissionMessageTitle: {
    formKey: "__permissionMessageTitle_",
    objectKey: "permissions.messageTitle",
    type: FormParamType.Default,
    panels: [RowType.None, RowType.Step]
  },
  permissionMessageBody: {
    formKey: "__permissionMessageBody_",
    objectKey: "permissions.messageBody",
    type: FormParamType.Default,
    panels: [RowType.None, RowType.Step]
  },
  permissionExecuteAs: {
    formKey: "__permissionExecuteAs_",
    objectKey: "permissions.connection",
    type: FormParamType.Default,
    panels: [RowType.Step]
  },
  valid: {
    formKey: "__validConditions_",
    objectKey: "valid.conditions",
    type: FormParamType.Expression,
    panels: [RowType.Step]
  },
  validMessage: {
    formKey: "__validMessage_",
    objectKey: "valid.message",
    type: FormParamType.Expression,
    panels: [RowType.Step]
  },
  processRetention: {
    formKey: "__processRetention_",
    objectKey: "retention.period",
    type: FormParamType.Default,
    panels: [RowType.None]
  },
  instanceStorage: {
    formKey: "__instanceStorage_",
    objectKey: "instanceStorage",
    type: FormParamType.Default,
    panels: [RowType.None]
  },
  execution: {
    formKey: "__execution_",
    objectKey: "execution",
    type: FormParamType.Default,
    panels: [RowType.Step]
  },
  executionMessage: {
    formKey: "__executionPreEvalMessage_",
    objectKey: "execution.preEvalMessage",
    type: FormParamType.Default,
    panels: [RowType.Step]
  },
  disableInteraction: {
    formKey: "__disableInteraction_",
    objectKey: "execution.disableInteraction",
    type: FormParamType.Default,
    panels: [RowType.Step]
  },
  disableContextReload: {
    formKey: "__disableContextReload_",
    objectKey: "execution.disableContextReload",
    type: FormParamType.Default,
    panels: [RowType.Step]
  },
  disableOutputLoad: {
    formKey: "__disableOutputLoad_",
    objectKey: "execution.disableOutputLoad",
    type: FormParamType.Default,
    panels: [RowType.Step]
  }
};

export const isProcessExpressionModel = (obj: any): boolean => {
  return (
    obj.properties !== undefined &&
    typeof obj.properties === "object" &&
    "Expression" in obj.properties &&
    "Rules" in obj.properties &&
    "Data" in obj.properties
  );
};
