import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { ChoiceGroup, IChoiceGroupOption, IChoiceGroupProps } from "@fluentui/react";
import { FormControlProps } from "./ControlledTextField";

export const ControlledChoiceGroup: FC<FormControlProps & IChoiceGroupProps> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || ""}
      render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => (
        <ChoiceGroup
          {...props}
          onChange={(
            event?: React.FormEvent<HTMLElement | HTMLInputElement>,
            option?: IChoiceGroupOption
          ) => {
            onChange(option?.key);
            if (props.onChange) props.onChange(event, option);
          }}
          onBlur={onBlur}
          selectedKey={value}
        />
      )}
    />
  );
};
