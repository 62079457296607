import { cloneDeep } from "lodash";
import { CommandButton, Panel, PanelType, Spinner, SpinnerSize } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router";
import {
  AdminConfiguration,
  AdminParameterConfiguration
} from "@workpoint/components/lib/models/AdminConfiguration";
import { useNavigation } from "../../../hooks/useNavigation";
import { NavLinkType } from "../../../models/Navigation";
import {
  adminSelector,
  deletePageItem,
  loadPageItem,
  savePageItem,
  updatePageItem
} from "../../../store/adminReducer";
import { showDialog } from "../../../store/dialogReducer";
import { globalSelector } from "../../../store/globalReducer";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getDefinitionByOperationId } from "../../../utils/swaggerUtils";
import { DialogResult, DialogType } from "../../common/Dialog";
import { PanelHeader } from "../../common/PanelHeader";
import { AdminPageConfig, PageProps } from "../ConfigurePageControl";
import { AdminParametersForm } from "./AdminParametersForm";

export const AdminParametersPanel = () => {
  const { pageId, subPageId, rowId } = useParams();
  const { spHostUrl } = useAppSelector(globalSelector);
  const { document, pageItem, pageOpId, subPageOpId, opRowId, hasErrors } =
    useAppSelector(adminSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { activeNav } = useNavigation();
  const intl = useIntl();
  const [pageProps, setPageProps] = useState<PageProps>();

  const item =
    !pageProps || pageId !== pageOpId || subPageId !== subPageOpId || rowId !== opRowId
      ? undefined
      : pageItem;

  useEffect(() => {
    if (
      !hasErrors &&
      document &&
      pageId &&
      subPageId &&
      rowId &&
      (!pageProps || pageId !== pageOpId || subPageId !== subPageOpId || rowId !== opRowId)
    ) {
      const operation = getDefinitionByOperationId(document, subPageId);
      if (operation?.definition) {
        const pageConfig: AdminPageConfig =
          (operation.definition as any)["x-workpoint-pageconfig"] ?? ({} as any);
        setPageProps({ operation, pageConfig });

        dispatch(loadPageItem(pageId, subPageId, rowId));
      }
    } else if (window.location.href.indexOf("/new") !== -1) {
      /**
       * @todo: New item should be determined by a concrete route
       */
      const operation = getDefinitionByOperationId(document!, subPageId!);
      if (operation?.definition) {
        const pageConfig: AdminPageConfig =
          (operation.definition as any)["x-workpoint-pageconfig"] ?? ({} as any);
        setPageProps({ operation, pageConfig });

        dispatch(loadPageItem(pageId!, subPageId!, rowId));
      }
    } else if (document && activeNav?.linkType === NavLinkType.ParametersPanel && subPageId) {
      const operation = getDefinitionByOperationId(document!, subPageId!);
      if (operation?.definition) {
        const pageConfig: AdminPageConfig =
          (operation.definition as any)["x-workpoint-pageconfig"] ?? ({} as any);
        setPageProps({ operation, pageConfig });

        dispatch(loadPageItem(pageId!, subPageId!));
      }
    }
  }, [document, pageId, subPageId, rowId, activeNav]);

  const _closeParametersPanel = () => {
    navigate({
      pathname: activeNav?.parent?.url,
      search: `SPHostUrl=${spHostUrl}`
    });
  };

  const _update = (data: any) => {
    const configuration: AdminConfiguration = cloneDeep(pageItem?.data);
    configuration.parameters?.forEach((p: AdminParameterConfiguration) => {
      p.value = cloneDeep(data[p.name]?.value);
    });
    dispatch(updatePageItem({ item: configuration }));

    return [];
  };

  const _onRenderHeader = (): JSX.Element => {
    const additionalButtons: JSX.Element[] = [];
    if (pageProps?.pageConfig.operations?.delete) {
      additionalButtons.push(
        <CommandButton
          iconProps={{ iconName: "Delete" }}
          text={intl.formatMessage({ id: "option-delete", defaultMessage: "Delete" })}
          onClick={() => {
            dispatch(
              showDialog({
                type: DialogType.DeleteCancel,
                title: "Delete?", // CTRLLANG
                subText: "Are you sure?", // CTRLLANG
                onClick: (result: DialogResult) => {
                  if (result === DialogResult.Ok) {
                    dispatch(deletePageItem(pageProps.pageConfig.operations!.delete!, rowId!));
                    _closeParametersPanel();
                  }
                }
              })
            );
          }}
        />
      );
    }
    if (pageProps?.pageConfig.operations?.save && pageItem) {
      additionalButtons.push(
        <CommandButton
          iconProps={{ iconName: "Save" }}
          text={intl.formatMessage({ id: "option-save", defaultMessage: "Save" })}
          onClick={() => {
            /**
             * @todo: Dispatch saves row item or empty string for new creation
             */
            dispatch(
              savePageItem(
                pageProps.pageConfig.operations!.save!,
                pageItem.data,
                !!rowId ? rowId : ""
              )
            );
            _closeParametersPanel();
          }}
        />
      );
    }

    return (
      <PanelHeader
        title={intl.formatMessage({
          id: "properties",
          defaultMessage: "Properties"
        })}
        additionalButtons={additionalButtons}
        closePanel={_closeParametersPanel}
      ></PanelHeader>
    );
  };

  return (
    <Panel
      isOpen={true}
      type={PanelType.smallFluid}
      closeButtonAriaLabel="Close"
      hasCloseButton={false}
      focusTrapZoneProps={{
        isClickableOutsideFocusTrap: true,
        forceFocusInsideTrap: false
      }}
      isLightDismiss
      isBlocking={false}
      styles={{
        commands: { display: "none" }
      }}
      onRenderHeader={_onRenderHeader}
      onDismiss={_closeParametersPanel}
    >
      {pageProps === undefined || item === undefined ? (
        <Spinner size={SpinnerSize.large} style={{ marginTop: "100px" }} />
      ) : (
        <>
          {activeNav?.description && (
            <div
              style={{ marginLeft: "23px" }}
              dangerouslySetInnerHTML={{ __html: activeNav?.description ?? "" }}
            ></div>
          )}
          <AdminParametersForm {...pageProps} close={_closeParametersPanel} update={_update} />
        </>
      )}
    </Panel>
  );
};
