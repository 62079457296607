import { Controller } from "react-hook-form";
import { INotificationType } from "../../../../models/NotificationSettings";
import { FormControlProps } from "./ControlledTextField";
import { NotificationTypeList } from "./NotificationTypeList";

export const ControlledNotificationTypesPicker: React.FC<FormControlProps> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || []}
      render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => {
        const changeCallback = (notificationTypes: INotificationType[]) => {
          onChange(notificationTypes);
          onBlur();
        };

        return <NotificationTypeList notificationTypes={value} changeCallback={changeCallback} />;
      }}
    />
  );
};
