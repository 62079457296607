import * as React from "react";
import { IPanelProps, Panel, PanelType } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { addFieldTranslation, processSelector } from "../../../../store/processReducer";
import { getLocalizationLanguages } from "./configLanguages";
import { PanelHeader } from "../../../common/PanelHeader";
import FieldInfo from "../../../common/FieldInfo";
import {
  ProcessConfiguration,
  ProcessParameterProperty
} from "@workpoint/components/lib/models/ProcessConfiguration";
import { isObjectEqual } from "../../../../utils/commonUtils";
import { ParameterPropertyControl } from "../../../common/parametersForm/ParameterPropertyControl";
import { FormControlType } from "../../../common/parametersForm/ParametersForm";
import { cloneDeep } from "lodash";

export interface ParameterLocalizationPanelProps {
  localizationId: string;
  parameterTitle: string;
  onClose?: () => void;
  richText?: boolean;
}

export const ParameterLocalizationPanel = (
  props: ParameterLocalizationPanelProps & IPanelProps
) => {
  const intl = useIntl();
  const { process } = useAppSelector(processSelector);

  const defaultValues = getDefaultLocalizationValues(process.configuration, props.localizationId);
  const {
    control,
    formState: { errors },
    setValue,
    trigger
  } = useForm({
    mode: "onBlur",
    defaultValues,
    resolver: (values: any, context?: object, options?: Object) => {
      if (!isObjectEqual(values, defaultValues)) {
        _update(values);
      }
      return { values, errors: [] };
    }
  });
  const [formControls, setFormControls] = useState<JSX.Element[]>([]);
  const dispatch = useAppDispatch();
  //const watchAll = useWatch({ control });
  //console.log("Localization watch:", watchAll);

  useEffect(() => {
    getControls();
  }, []);

  const getControls = () => {
    const controls: JSX.Element[] = [];
    getLocalizationLanguages().forEach((language) => {
      controls.push(
        <ParameterPropertyControl
          key={language.nativeLanguageCode}
          control={control}
          setValue={setValue}
          trigger={trigger}
          name={`${language.languageCode}-${language.regionCode}`}
          label={language.languageName}
          description={`The translation for ${language.languageName}`}
          defaultValue={undefined}
          propertyType={ProcessParameterProperty.Title}
          controlType={props.richText ? FormControlType.RichText : FormControlType.PlainText}
          required={false}
          definition={{
            in: "",
            name: "",
            type: ""
          }}
          rows={props.richText ? 5 : 1}
          skipPropertyTypeInName
        />
      );
    });

    setFormControls(controls);
  };

  const _update = (data: any) => {
    dispatch(
      addFieldTranslation({
        fieldTranslationId: props.localizationId,
        translations: data
      })
    );
  };

  const _onRenderHeader = () => {
    return (
      <PanelHeader
        title={`Field Localization ${intl.formatMessage({
          id: "panel-properties",
          defaultMessage: "Properties"
        })}`}
        closePanel={props.onClose!}
      >
        <FieldInfo label1={"Localization for"} label2={props.parameterTitle} />
      </PanelHeader>
    );
  };

  return (
    <Panel
      isOpen={true}
      type={PanelType.smallFluid}
      hasCloseButton={false}
      isLightDismiss={false}
      onDismiss={() => {}}
      onRenderHeader={_onRenderHeader}
      styles={{
        root: { width: "100%" },
        main: { boxShadow: "0px 0px 0px 0px", overflow: "hidden" },
        commands: { display: "none" }
      }}
      focusTrapZoneProps={{
        isClickableOutsideFocusTrap: true,
        forceFocusInsideTrap: false
      }}
      {...props}
    >
      <form>{formControls}</form>
    </Panel>
  );
};

export const getDefaultLocalizationValues = (
  configuration: ProcessConfiguration,
  localizationId: string
) => {
  const fieldValues: any = {};
  configuration.localization?.additionalLanguages.forEach((lang) => {
    let message = lang.messages[localizationId];
    if (message) {
      // Required to convert old translations to the new one
      if (typeof message === "string") {
        message = { defaultText: `'${message}'` };
      }
      fieldValues[lang.language] = cloneDeep(message);
    }
  });

  return fieldValues;
};
