import React, { useEffect, useState } from "react";
import {
  CheckboxVisibility,
  ConstrainMode,
  DetailsListLayoutMode,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Customizer,
  LayerHost,
  IColumn,
  DetailsRow,
  ShimmeredDetailsList,
  Icon,
  IDetailsRowStyles
} from "@fluentui/react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { AppRoutes } from "../../../routes";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router";
import { adminSelector, loadPageItems } from "../../../store/adminReducer";
import { AdminParametersPanel } from "../details/AdminParametersPanel";
import { PageProps } from "../ConfigurePageControl";
import styled from "styled-components";
import { useNavigation } from "../../../hooks/useNavigation";
import { INavigationEntry, NavLinkType } from "../../../models/Navigation";
import { globalSelector } from "../../../store/globalReducer";

export const AdminSettingPage = (props: PageProps) => {
  const { pageId } = useParams();
  const { spHostUrl } = useAppSelector(globalSelector);
  const dispatch = useAppDispatch();
  const { pageList, pageOpId } = useAppSelector(adminSelector);
  const navigate = useNavigate();
  const [settingsNav, setSettingsNav] = useState<INavigationEntry>();
  const { activeNav } = useNavigation();

  useEffect(() => {
    if (pageId && pageId !== pageOpId) {
      dispatch(loadPageItems(pageId));
    }
  }, [pageId]);

  useEffect(() => {
    const settingsNav =
      activeNav?.linkType === NavLinkType.ParametersPanel ? activeNav.parent : activeNav;
    setSettingsNav(settingsNav);
  }, [activeNav?.url]);

  const columns: IColumn[] = [
    {
      key: "icon",
      name: "Icon",
      minWidth: 16,
      maxWidth: 20
    },
    {
      key: "name",
      name: "Name",
      minWidth: 24,
      maxWidth: 300
    },
    {
      key: "description",
      name: "Description",
      minWidth: 24
    }
  ];

  const onRowClick = (item: INavigationEntry) => {
    if (item.linkType === "ParametersPage") {
      navigate({
        pathname: `${item.url}`,
        search: `SPHostUrl=${spHostUrl}`
      });
    } else {
      navigate({
        pathname: `${item.url}/panel`,
        search: `SPHostUrl=${spHostUrl}`
      });
    }
  };

  const _renderItemColumn = (
    item: INavigationEntry,
    index?: number | undefined,
    column?: IColumn | undefined
  ): React.ReactNode => {
    switch (column!.key) {
      case "description":
        return <div dangerouslySetInnerHTML={{ __html: item["description"] }}></div>;
      case "icon":
        return <Icon {...item.iconProps} style={{ fontSize: "18px" }} />;
      default:
        return <span>{item[column!.key as keyof INavigationEntry]}</span>;
    }
  };

  const getDetailsRowStyle = (index: number) => {
    const detailsRowStyle: Partial<IDetailsRowStyles> = {
      root: {
        cursor: "pointer",
        paddingLeft: "32px",
        borderTop: index === 0 ? "1px solid rgb(243, 242, 241)" : ""
      },
      cell: {
        padding: "0px 8px 0px 0px",
        minHeight: "0px"
      },
      fields: {
        alignItems: "center"
      }
    };

    return detailsRowStyle;
  };

  return (
    <>
      {pageList === undefined ? (
        <Spinner size={SpinnerSize.large} style={{ marginTop: "100px" }} />
      ) : (
        <>
          {settingsNav?.description && (
            <div
              style={{ margin: "23px" }}
              dangerouslySetInnerHTML={{ __html: settingsNav?.description ?? "" }}
            ></div>
          )}
          <ShimmeredDetailsList
            items={settingsNav?.links ?? []}
            columns={columns}
            onRenderRow={(props: any) => {
              return (
                <DetailsRow
                  {...props}
                  styles={getDetailsRowStyle(props.itemIndex)}
                  onClick={() => onRowClick(props.item)}
                />
              );
            }}
            selectionMode={SelectionMode.single}
            isHeaderVisible={false}
            layoutMode={DetailsListLayoutMode.justified}
            constrainMode={ConstrainMode.unconstrained}
            checkboxVisibility={CheckboxVisibility.hidden}
            onRenderItemColumn={_renderItemColumn}
            setKey={"settingsPageListKey"}
          />
        </>
      )}
    </>
  );
};
