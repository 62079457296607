import { ProcessParameterProperty } from "@workpoint/components/lib/models/ProcessConfiguration";
import { FormControlType } from "../common/parametersForm/ParametersForm";
import { evaluateExpressionForDisplay } from "@workpoint/components/lib/helpers/expressionUtils";

export const getTransitionDefinition = (isAction: boolean, steps: any, rowIndex: string) => {
  const enums = [""];
  const enumNames = [""];

  const stepNum = parseInt(rowIndex ?? "");
  if (!isNaN(stepNum)) {
    for (let i = stepNum + 1; i < steps.length; i++) {
      enums.push(steps[i].name);
      enumNames.push(evaluateExpressionForDisplay(steps[i].title.defaultText, {}));
    }
  }

  const dynamicConfigObj: any = {
    Before: {
      parameters: [
        {
          name: "action",
          description: "When to trigger the execution",
          required: true,
          type: "uuid",
          "x-ms-url-encoding": "single",
          "x-ms-summary": "Action",
          enum: ["Hold", "Skip", "Cancel", "Fail"],
          "x-workpoint-control": {
            type: FormControlType.Dropdown,
            propertyType: ProcessParameterProperty.DataType
          }
        },
        {
          name: "conditions",
          in: "path",
          description: "The conditions to trigger the execution",
          required: false,
          type: "string",
          "x-ms-summary": "Condition(s)",
          "x-workpoint-control": { type: FormControlType.Conditions }
        },
        {
          name: "responseTitle",
          description: "The response title when the execution is triggered",
          required: false,
          type: "string",
          "x-ms-visibility": "important",
          "x-ms-summary": "Response title",
          "x-workpoint-control": {
            type: "plainText",
            translatable: true,
            rows: 1
          }
        },
        {
          name: "responseMessage",
          description: "The response when the execution is triggered",
          required: false,
          type: "string",
          "x-ms-visibility": "important",
          "x-ms-summary": "Response message",
          "x-workpoint-control": {
            type: "richText",
            translatable: true,
            rows: 3
          }
        }
      ],
      title: "Before execution"
    }
  };

  if (isAction) {
    dynamicConfigObj.Parallel = {
      parameters: [
        {
          name: "controllerExecution",
          in: "path",
          description:
            "Defines if the execution is done by the controller, only for quick actions, might improve performance",
          required: false,
          type: "boolean",
          "x-ms-summary": "Controller execution"
        },
        {
          name: "dependentStep",
          in: "path",
          description: "An optional step to wait for",
          required: false,
          type: "string",
          "x-ms-summary": "Dependent step",
          enum: enums,
          "x-enum-names": enumNames,
          "x-workpoint-control": {
            type: FormControlType.Dropdown,
            propertyType: ProcessParameterProperty.DataType
          }
        },
        {
          name: "conditions",
          in: "path",
          description: "The conditions to trigger the execution",
          required: false,
          type: "string",
          "x-ms-summary": "Condition(s)",
          "x-workpoint-control": { type: FormControlType.Conditions }
        },
        {
          name: "responseTitle",
          description: "The response title when the execution is triggered",
          required: false,
          type: "string",
          "x-ms-visibility": "important",
          "x-ms-summary": "Response title",
          "x-workpoint-control": {
            type: "plainText",
            translatable: true,
            rows: 1
          }
        },
        {
          name: "responseMessage",
          description: "The response when the execution is triggered",
          required: false,
          type: "string",
          "x-ms-visibility": "important",
          "x-ms-summary": "Response message",
          "x-workpoint-control": {
            type: "richText",
            translatable: true,
            rows: 3
          }
        }
      ],
      title: "Parallel execution"
    };
  }

  return {
    name: "transitions",
    in: "body",
    description: "Configure rules",
    required: false,
    schema: {
      type: "object"
    },
    "x-ms-summary": "Execution rules",
    "x-workpoint-dynamic-configuration": dynamicConfigObj,
    "x-workpoint-parameter-group": "Options"
  };
};
