import { ITermInfo } from "@pnp/sp/taxonomy";
import {
  CollapseAllVisibility,
  DetailsList,
  IColumn,
  IDetailsGroupDividerProps,
  IRenderFunction,
  SelectionMode,
  Selection,
  Label,
  DetailsListLayoutMode,
  Spinner,
  IconButton,
  Stack,
  ContextualMenu,
  TextField,
  ITextField,
  IContextualMenuItem
} from "@fluentui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { ProcessGroup } from "@workpoint/components/lib/models/ProcessConfiguration";
import { showDialog } from "../../../../store/dialogReducer";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  createTerm,
  deleteTerm,
  getTerms,
  processSelector,
  updateTermName
} from "../../../../store/processReducer";
import { DialogResult, DialogType } from "../../../common/Dialog";
import { FormControlProps } from "../../../common/form/ControlledTextField";
import { getLocaleFromLCID } from "@workpoint/components/lib/models/LocaleContext";

const ControlledGroupField = (props: FormControlProps) => {
  const dispatch = useAppDispatch();
  const { groups, loading, spLocaleId } = useAppSelector(processSelector);
  const onValueChange = useRef<(...event: any[]) => void>();

  const selection = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          const groupsToUpdate = selection.getSelection().map((selected: any) => {
            return {
              title: selected.labels[0].name,
              id: selected.id
            };
          });
          if (onValueChange.current) {
            onValueChange.current(groupsToUpdate);
          }
        }
      }),
    []
  );

  useEffect(() => {
    if (!groups) {
      dispatch(getTerms());
    }
  }, [groups, dispatch]);

  useEffect(() => {
    if (groups && groups.items.length > 0) {
      selection.setItems(
        groups.items.map((item: any) => {
          return {
            ...item,
            key: item.id
          };
        })
      );
    }
    if (props.defaultValue?.length > 0 && selection.count === 0) {
      props.defaultValue.forEach((group: ProcessGroup) => {
        selection.setKeySelected(group.id, true, false);
      });
    }
  }, [selection, groups, props.defaultValue]);

  const _onRenderItemColumn = (
    item?: ITermInfo,
    index?: number | undefined,
    column?: IColumn | undefined
  ) => {
    const locale = getLocaleFromLCID(spLocaleId ?? 1033);
    const localTerms = item?.labels.filter((l) => l.languageTag.toLowerCase() === locale);
    const termName =
      localTerms && localTerms.length > 0
        ? localTerms.filter((l) => l.isDefault)[0].name
        : item?.labels[0].name;
    return (
      <Stack horizontal>
        <Stack.Item grow>{termName}</Stack.Item>
        <ContextualMenuButton termId={item?.id} termName={termName} />
      </Stack>
    );
  };

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || ""}
      render={({ field: { onChange, onBlur, name, value }, fieldState: { error } }) => {
        onValueChange.current = onChange;

        return (
          <div onBlur={onBlur}>
            <Label>Process Groups</Label>
            {/* CTRLLANG */}
            {
              loading ? (
                <Spinner />
              ) : groups?.items && groups.items.length > 0 ? (
                <DetailsList
                  items={groups?.items ?? []}
                  groups={groups?.groups ?? []}
                  columns={columns}
                  groupProps={{
                    onRenderHeader: _onRenderGroupHeader,
                    collapseAllVisibility: CollapseAllVisibility.visible
                  }}
                  selectionMode={SelectionMode.single}
                  onRenderItemColumn={_onRenderItemColumn}
                  // onRenderRow={_onRenderRow}
                  selection={selection}
                  compact
                  isHeaderVisible={false}
                  layoutMode={DetailsListLayoutMode.fixedColumns}
                  selectionPreservedOnEmptyClick={true}
                />
              ) : (
                "No groups have been created"
              ) // CTRLLANG
            }
          </div>
        );
      }}
    />
  );
};

export default ControlledGroupField;

const _onRenderGroupHeader = (
  props?: IDetailsGroupDividerProps,
  _defaultRender?: IRenderFunction<IDetailsGroupDividerProps>
): JSX.Element => {
  if (!props || !_defaultRender) {
    return <>Error</>;
  }
  // for fake groups - return empty element
  if (props.group!.name === "Undefined") {
    return <></>;
  }

  // default rendering for "real" groups
  return <CustomGroupHeader {...props} />; //_defaultRender(props) as any;
};

const CustomGroupHeader = (props: IDetailsGroupDividerProps) => {
  return (
    <Stack horizontal>
      <Stack.Item grow styles={{ root: { marginLeft: (props?.groupLevel ?? 0) * 30 + 100 } }}>
        <Label>{props.group?.name}</Label>
      </Stack.Item>
      <ContextualMenuButton termId={props.group?.key} termName={props.group?.name} />
    </Stack>
  );
};

const ContextualMenuButton = ({ termId, termName }: { termId?: string; termName?: string }) => {
  const [showContextualMenu, setShowContextualMenu] = useState<boolean>(false);
  const linkRef = React.useRef(null);
  const dispatch = useAppDispatch();
  const inputEl = useRef<ITextField>(null);
  const inputEl2 = useRef<ITextField>(null);

  const items: IContextualMenuItem[] = [
    {
      key: "addGroup",
      text: "Add Group",
      onClick: () => {
        dispatch(
          showDialog({
            type: DialogType.SaveCancel,
            title: "Create a Group", // CTRLLANG
            subText: "",
            onRenderDialog: () => {
              return <TextField label="Group Name" componentRef={inputEl} />;
            },
            onClick: (result: DialogResult) => {
              if (result === DialogResult.Ok && inputEl?.current?.value) {
                dispatch(createTerm(inputEl.current.value ?? "", termId));
              }
            }
          })
        );
      }
    },
    {
      key: "edit",
      text: "Edit",
      onClick: () => {
        dispatch(
          showDialog({
            type: DialogType.SaveCancel,
            title: "Edit the Group Name",
            subText: "",
            onRenderDialog: () => {
              return (
                <TextField label="Group Name" componentRef={inputEl2} defaultValue={termName} />
              );
            },
            onClick: (result: DialogResult) => {
              if (result === DialogResult.Ok && inputEl2?.current?.value) {
                dispatch(updateTermName(termId!, inputEl2.current.value));
              }
            }
          })
        );
      }
    },
    {
      key: "delete",
      text: "Delete",
      onClick: () => {
        dispatch(
          showDialog({
            type: DialogType.DeleteCancel,
            title: "Delete group?",
            subText: "This cannot be undone",
            onClick: (result: DialogResult) => {
              if (result === DialogResult.Ok && termId) {
                dispatch(deleteTerm(termId));
              }
            }
          })
        );
      }
    }
  ];
  return (
    <Stack.Item>
      <div ref={linkRef}>
        <IconButton
          text=""
          width="30"
          split={false}
          iconProps={{ iconName: "MoreVertical" }}
          onClick={() => setShowContextualMenu(true)}
        />
      </div>
      <ContextualMenu
        items={items}
        hidden={!showContextualMenu}
        target={linkRef}
        onItemClick={() => setShowContextualMenu(false)}
        onDismiss={() => setShowContextualMenu(false)}
      />
    </Stack.Item>
  );
};

const columns: IColumn[] = [
  {
    name: "Title",
    minWidth: 200,
    key: "title",
    fieldName: "title"
  }
];
