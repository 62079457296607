import styled from "styled-components"

export const ErrorMessage = styled.p`
    animation-name: css-0, css-13;
    animation-duration: 0.367s;
    animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
    animation-fill-mode: both;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system,
        BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 12px;
    font-weight: 400;
    color: rgb(164, 38, 44);
    margin: 0px !important;
    padding-top: 5px;
    display: flex;
    align-items: center;
`

export const unpackErrors = (errorObject: any): JSX.Element => {
    if (errorObject.hasOwnProperty("message"))
        return <ErrorMessage key={errorObject.type}>{errorObject.message}</ErrorMessage>

    return (
        <>
            {Object.entries(errorObject).map(([type, fieldError], index) => (
                <ErrorMessage key={`${(fieldError as any).type}-${index}`}>
                    {(fieldError as any).message}
                </ErrorMessage>
            ))}
        </>
    )
}
