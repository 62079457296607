import { cloneDeep } from "lodash";
import { CommandButton, Panel, PanelType } from "@fluentui/react";
import { OpenAPIV2 } from "openapi-types";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { evaluateProcessExpressionLimited } from "@workpoint/components/lib/helpers/expressionUtils";
import { ProcessTrigger } from "@workpoint/components/lib/models/ProcessConfiguration";
import { ProcessContext } from "@workpoint/components/lib/models/ProcessInstance";
import { ToRoute } from "../../../../routes";
import { showDialog } from "../../../../store/dialogReducer";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  deleteTrigger,
  getTrigger,
  processSelector,
  updateTrigger
} from "../../../../store/processReducer";
import {
  formDefaultParameters,
  internalParameters,
  validateProcessTrigger
} from "../../../../utils/processUtils";
import { DialogResult, DialogType } from "../../../common/Dialog";
import FieldInfo from "../../../common/FieldInfo";
import { ControlledBoolean } from "../../../common/form/ControlledBoolean";
import { PanelHeader } from "../../../common/PanelHeader";
import {
  StyledControlledTextField,
  StyledFieldContainer
} from "../../../common/parametersForm/ParametersForm";
import { ControlledConditions } from "../../../common/parametersForm/ControlledConditions";
import { ProcessParametersForm } from "./ProcessParametersForm";

export const TriggerParametersPanel = () => {
  const { step } = useAppSelector(processSelector);
  const { processId, rowIndex } = useParams() as any;
  const navigate = useNavigate();
  const { document, process } = useAppSelector(processSelector);
  const dispatch = useAppDispatch();
  const intl = useIntl();

  useEffect(() => {
    if (rowIndex !== undefined && !isNaN(rowIndex)) {
      if (
        process.configuration.triggers &&
        parseInt(rowIndex) >= 0 &&
        parseInt(rowIndex) < process.configuration.triggers.length
      ) {
        dispatch(getTrigger(rowIndex));
      } else if (processId) {
        navigate(ToRoute.process(processId));
      }
    }
  }, [navigate, dispatch, process, processId, rowIndex]);

  const _closeParametersPanel = () => {
    if (processId) {
      navigate(ToRoute.process(processId));
    } else {
      navigate(ToRoute.newProcess());
    }
  };

  const _update = (data: any, context?: ProcessContext, closePanel?: boolean) => {
    const updatedConfig = {
      ...cloneDeep(step?.configuration),
      list: undefined,
      businessModuleId: "",
      disabled: !data.enabled ?? false,
      title: data[formDefaultParameters.title.formKey]
    } as ProcessTrigger;

    step?.definition?.parameters?.forEach((param) => {
      const definition = param as OpenAPIV2.Parameter;
      if (internalParameters.indexOf(definition.name) < 0) {
        (updatedConfig as any)[definition.name] = evaluateProcessExpressionLimited(
          data[definition.name]["value"],
          context
        );
      }
    });
    updatedConfig.conditions = cloneDeep(data.conditions);
    updatedConfig.parameters = [];

    dispatch(updateTrigger(cloneDeep(updatedConfig)));

    if (closePanel) {
      _closeParametersPanel();
    }

    return validateProcessTrigger(document, process.configuration, updatedConfig);
  };

  const _onRenderHeader = (): JSX.Element => {
    const additionalButtons: JSX.Element[] = [
      <CommandButton
        iconProps={{ iconName: "Delete" }}
        text={intl.formatMessage({ id: "option-delete", defaultMessage: "Delete" })}
        onClick={() => {
          if (step?.configuration) {
            dispatch(
              showDialog({
                type: DialogType.DeleteCancel,
                title: "Delete?", // CTRLLANG
                subText: "Are you sure?", // CTRLLANG
                onClick: (result: DialogResult) => {
                  if (result === DialogResult.Ok) {
                    dispatch(deleteTrigger(step?.configuration! as unknown as ProcessTrigger));
                    _closeParametersPanel();
                  }
                }
              })
            );
          }
        }}
      />
    ];

    return (
      <PanelHeader
        title={intl.formatMessage({
          id: "trigger-panel-properties",
          defaultMessage: "Trigger Properties"
        })}
        additionalButtons={additionalButtons}
        closePanel={_closeParametersPanel}
      >
        <FieldInfo label1={"Trigger"} label2={step?.definition?.summary} iconName={"TriggerAuto"} />
      </PanelHeader>
    );
  };

  return (
    <Panel
      isOpen={rowIndex !== undefined && !isNaN(rowIndex)}
      type={PanelType.smallFluid}
      closeButtonAriaLabel="Close"
      hasCloseButton={false}
      focusTrapZoneProps={{
        isClickableOutsideFocusTrap: true,
        forceFocusInsideTrap: false
      }}
      isLightDismiss={false}
      styles={{
        root: {
          width: "100%",
          borderLeftWidth: "1px",
          borderLeftStyle: "solid",
          borderLeftColor: "#E5E5E5"
        },
        main: { boxShadow: "0px 0px 0px 0px", overflow: "hidden" },
        commands: { display: "none" }
      }}
      onRenderHeader={_onRenderHeader}
    >
      {step !== undefined && step.configuration !== undefined && step.definition !== undefined && (
        <>
          <ProcessParametersForm
            formTitle={step?.configuration?.title.defaultText ?? ""}
            close={_closeParametersPanel}
            update={_update}
            document={document!}
            definition={step?.definition}
            configuration={step?.configuration}
            onRender={(pivots, control, openLocalizationPanel, setValue, trigger, context) => {
              pivots["General"].splice(
                0,
                0,
                <StyledFieldContainer key={formDefaultParameters.title.formKey}>
                  <StyledControlledTextField
                    name={`${formDefaultParameters.title.formKey}.defaultText`}
                    label="Title"
                    placeholder={intl.formatMessage({
                      id: "panel-name-placeholder",
                      defaultMessage: "Enter a name for your process/step."
                    })}
                    control={control}
                    required={true}
                    rules={{ required: "This field is required" }}
                    defaultValue={step?.configuration?.title?.defaultText}
                  />
                </StyledFieldContainer>
              );
              pivots["General"].push(
                <ControlledBoolean
                  key={"enabled"}
                  name={"enabled"}
                  label={intl.formatMessage({
                    id: "trigger-status-label",
                    defaultMessage: "Trigger status"
                  })}
                  control={control}
                  onText={intl.formatMessage({
                    id: "trigger-enabled",
                    defaultMessage: "Trigger enabled"
                  })}
                  offText={intl.formatMessage({
                    id: "trigger-disabled",
                    defaultMessage: "Trigger disabled"
                  })}
                />
              );
              pivots["General"].push(
                <div key={"conditions"}>
                  <ControlledConditions
                    control={control}
                    name={"conditions"}
                    setValue={setValue}
                    trigger={trigger}
                    showConditionsTitle
                  />
                </div>
              );
            }}
          />
        </>
      )}
    </Panel>
  );
};
