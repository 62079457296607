import {
  Label,
  mergeStyleSets,
  Pivot,
  PivotItem,
  PivotLinkFormat,
  PivotLinkSize,
  Stack
} from "@fluentui/react";
import { useEffect, useState } from "react";
import {
  Control,
  FieldValues,
  useFieldArray,
  UseFormSetValue,
  UseFormTrigger,
  useWatch
} from "react-hook-form";
import { useIntl } from "react-intl";
import { isEmpty } from "../../../utils/commonUtils";
import {
  ComparisonOperator,
  ExpressionDescriptor,
  ExpressionEvaluators
} from "@workpoint/components/lib/models/ProcessConfiguration";
import { showDialog } from "../../../store/dialogReducer";
import { useAppDispatch } from "../../../store/hooks";
import { defaultExpressionRule, isRulesEmpty } from "../../../utils/processUtils";
import { DialogResult, DialogType } from "../Dialog";
import FieldInfo from "../FieldInfo";
import { ConditionsBasic } from "./ConditionsBasic";
import { ConditionsAdvanced } from "./CondtionsAdvanced";
import { PropertyExpressionType } from "./ParametersForm";
import theme from "@workpoint/components/lib/constants";
import { ConditionsSettings } from "./ConditionsPanel";
import styled from "styled-components";
import { ModifiedTag } from "./AccordionControl";

export enum Views {
  Basic = "Basic",
  Advanced = "Advanced"
}

interface ControlledConditionsProps {
  name: string;
  control: Control<FieldValues>;
  setValue: UseFormSetValue<any>;
  trigger: UseFormTrigger<any>;
  settings?: ConditionsSettings;
  definition?: any;
  fieldValues?: any;
  isFilter?: boolean;
  showConditionsTitle?: boolean;
  contextExtension?: any;
}

export const ControlledConditions = (props: ControlledConditionsProps) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const rulesFieldArray = useFieldArray({
    name: `${props.name}.${PropertyExpressionType.Basic}` as string,
    control: props.control
  });

  const expressionValue: ExpressionDescriptor = useWatch({
    name: `${props.name}`,
    control: props.control
  });

  const advancedValue: string = useWatch({
    name: `${props.name}.${PropertyExpressionType.Advanced}`,
    control: props.control
  });

  const basicValue =
    useWatch({
      name: `${props.name}.${PropertyExpressionType.Basic}`,
      control: props.control
    }) ?? [];

  const rules = (rulesFieldArray.fields.length > 0 ? rulesFieldArray.fields : []).map(
    (field, index) => {
      return {
        ...field,
        ...basicValue[index]
      };
    }
  );

  const [view, setView] = useState<Views>(
    props.isFilter || !isEmpty(advancedValue) ? Views.Advanced : Views.Basic
  );

  useEffect(() => {
    if (view === Views.Basic && rules.length === 0) {
      rulesFieldArray.append(
        defaultExpressionRule(props.settings?.hideComparison ? ComparisonOperator.None : undefined)
      );
    }
    if (props.isFilter && !expressionValue?.evaluator) {
      props.setValue(`${props.name}.evaluator`, ExpressionEvaluators.Caml, {
        shouldDirty: true,
        shouldValidate: true
      });
    }
  }, []);

  const onPivotSelect = (
    item?: PivotItem | undefined,
    ev?: React.MouseEvent<HTMLElement, MouseEvent> | undefined
  ) => {
    if (
      (item?.props.itemKey !== Views.Advanced && !isEmpty(advancedValue)) ||
      (item?.props.itemKey !== Views.Basic && !isRulesEmpty(basicValue))
    ) {
      dispatch(
        showDialog({
          type: DialogType.OkCancel,
          title: "Change View?", // CTRLLANG
          subText: `Changing to the ${view} view, will reset all of your current settings.`, // CTRLLANG
          onClick: (result: DialogResult) => {
            if (result === DialogResult.Ok) {
              props.setValue(
                props.name + "." + PropertyExpressionType.Basic,
                [
                  defaultExpressionRule(
                    props.settings?.hideComparison ? ComparisonOperator.None : undefined
                  )
                ],
                {
                  shouldDirty: true,
                  shouldValidate: true
                }
              );
              props.setValue(props.name + "." + PropertyExpressionType.Advanced, undefined, {
                shouldDirty: true,
                shouldValidate: true
              });

              setView(item!.props.itemKey as any);
            }
          }
        })
      );
    } else if (item?.props.itemKey !== view) {
      setView(item!.props.itemKey as any);
    }
  };

  return (
    <>
      {props.showConditionsTitle && (
        <ConditionsLabelContainer>
          <ConditionsLabel>Conditions</ConditionsLabel>
        </ConditionsLabelContainer>
      )}
      <FieldInfo
        label1={intl.formatMessage({ id: "condition-view", defaultMessage: "View" })}
        children={
          <>
            <Stack.Item>
              <Pivot
                linkSize="large"
                linkFormat="tabs"
                styles={pivotStyles}
                onLinkClick={onPivotSelect}
                selectedKey={view}
              >
                {!props.isFilter && (
                  <PivotItem
                    itemKey={Views.Basic}
                    headerText={Views.Basic}
                    itemIcon={view === Views.Basic ? "CheckMark" : ""}
                  />
                )}
                <PivotItem
                  itemKey={Views.Advanced}
                  headerText={Views.Advanced}
                  itemIcon={view === Views.Advanced ? "CheckMark" : ""}
                />
              </Pivot>
            </Stack.Item>
            <Stack.Item grow> </Stack.Item>
            {(!isEmpty(advancedValue) || !isRulesEmpty(basicValue)) && (
              <Stack.Item align="center">
                <ModifiedTag>{"</>"} Modified</ModifiedTag>
              </Stack.Item>
            )}
          </>
        }
      />
      {view === Views.Advanced ? (
        <ConditionsAdvanced
          name={props.name + "." + PropertyExpressionType.Advanced}
          control={props.control}
          contextExtension={props.contextExtension}
        />
      ) : (
        <ConditionsBasic
          name={props.name + "." + PropertyExpressionType.Basic}
          fieldArray={rulesFieldArray}
          rules={rules}
          control={props.control}
          setValue={props.setValue}
          trigger={props.trigger}
          settings={props.settings}
          definition={props.definition}
          fieldValues={props.fieldValues}
          contextExtension={props.contextExtension}
        />
      )}
    </>
  );
};

const pivotStyles = mergeStyleSets({
  linkIsSelected: {
    borderRadius: "25px",
    padding: "0px 15px",
    color: `white`,
    fontSize: "14px",
    height: "30px"
  },
  root: {
    backgroundColor: theme.palette.neutralLighter,
    borderRadius: "25px"
  },
  link: {
    backgroundColor: "transparent",
    padding: "0px 15px",
    selectors: {
      ":hover": {
        borderRadius: "25px",
        backgroundColor: "transparent",
        color: `-internal-light-dark(black, white)`
      }
    },
    fontSize: "14px",
    height: "30px"
  }
});

const ConditionsLabel = styled(Label)`
  margin-right: 5px;
`;

const ConditionsLabelContainer = styled.div`
  display: flex;
  margin-top: 5px;
`;
