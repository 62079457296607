import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { AppRoutes } from "../../routes";
import { FullPageHeader } from "../common/FullPageHeader";
import { useIntl } from "react-intl";
import { MessageBar, Spinner, SpinnerSize } from "@fluentui/react";
import { dialogSelector, hideMessage } from "../../store/dialogReducer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { AppContainer, Container, Content } from "../common/AppChrome";
import { SideNav } from "../common/SideNav";
import { ProcessPage } from "../process";
import { NotFound } from "../common/NotFound";
import { NotificationPage } from "./notification/NotificationPage";
import { adminSelector, loadSwagger } from "../../store/adminReducer";
import { ConfigureApp } from "./ConfigureApp";

export const AuthenticatedApp = () => {
  const { message } = useAppSelector(dialogSelector);
  const { document } = useAppSelector(adminSelector);
  const dispatch = useAppDispatch();
  const intl = useIntl();

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 7000);
    }
  }, [message]);

  useEffect(() => {
    if (!document) {
      dispatch(loadSwagger());
    }
  }, [document]);

  return (
    <AppContainer>
      <FullPageHeader
        showGuidanceToggle={true}
        title={intl.formatMessage({
          id: "admin-portal",
          defaultMessage: "Admin Portal"
        })}
      />
      {message !== undefined && (
        <MessageBar
          messageBarType={message.type}
          isMultiline={false}
          truncated={true}
          overflowButtonAriaLabel="See more"
          onDismiss={() => dispatch(hideMessage())}
          dismissButtonAriaLabel="Close"
        >
          {message.text}
        </MessageBar>
      )}
      <Container>
        <SideNav />
        <Content>
          {typeof document === "undefined" ? (
            <Spinner size={SpinnerSize.large} style={{ marginTop: "100px" }} />
          ) : (
            <Routes>
              <Route path={AppRoutes.Processes + "/*"} element={<ProcessPage />} />
              <Route path={AppRoutes.Notification + "/*"} element={<NotificationPage />} />
              <Route path={AppRoutes.Configure + "/*"} element={<ConfigureApp />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          )}
        </Content>
      </Container>
    </AppContainer>
  );
};

