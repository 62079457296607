export interface INavigationEntry {
  name: string;
  description: string;
  linkType: NavLinkType;
  url: string;
  key: string;
  iconProps?: {
    iconName?: string;
    imageProps?: {
      src: string;
    };
  };
  links?: INavigationEntry[];
  pivots?: INavigationEntry[];
  isFavorite: boolean;
  parent?: INavigationEntry;
}

export interface IAdminMenu {
  menuItems: INavigationEntry[];
  rootLink: INavigationEntry;
}

export enum NavLinkType {
  Default = "Default",
  ListViewPage = "ListViewPage",
  SettingsPage = "SettingsPage",
  ParametersPage = "ParametersPage",
  ParametersPanel = "ParametersPanel",
  CustomPage = "CustomPage"
}
