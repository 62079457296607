import { useMsal } from "@azure/msal-react";
import { IconButton } from "@fluentui/react/lib/components/Button";
import { useIntl } from "react-intl";

function handleLogout(instance: any) {
  instance.logoutRedirect().catch((e: any) => {
    console.error(e);
  });
}

/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
export const SignOutButton = () => {
  const { instance } = useMsal();
  const intl = useIntl();

  return (
    <IconButton
      iconProps={{ iconName: "SignOut", styles: { root: { color: "white" } } }}
      onClick={() => handleLogout(instance)}
      title={intl.formatMessage({
        id: "sign-out",
        defaultMessage: "Sign out"
      })}
    />
  );
};
