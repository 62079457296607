import { CSSProperties, useRef, useState } from "react";
import { Control, FieldValues, UseFormSetValue, UseFormTrigger, useWatch } from "react-hook-form";
import { FormControlType } from "./ParametersForm";
import {
  Callout,
  FontWeights,
  Icon,
  IconButton,
  IDropdownOption,
  Label,
  mergeStyleSets,
  Stack,
  Text,
  TooltipHost
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { OpenAPIV2 } from "openapi-types";
import { ControlledTextField } from "../form/ControlledTextField";
import { ControlledBoolean } from "../form/ControlledBoolean";
import { ControlledComboBox } from "../form/ControlledComboBox";
import { ConditionsPanel } from "./ConditionsPanel";
import {
  ExpressionDescriptor,
  DataSource,
  ProcessParameterProperty,
  ProcessStepConfiguration
} from "@workpoint/components/lib/models/ProcessConfiguration";
import theme from "@workpoint/components/lib/constants";
import styled from "styled-components";
import DataSourcePanel from "./DataSourcePanel";
import { ControlledNumber } from "../form/ControlledNumber";
import {
  formDefaultParameters,
  getRowTypeFromPath,
  isConditionsEmpty,
  isDataSourceEmpty,
  isTranslationEmpty,
  RowType
} from "../../../utils/processUtils";
import { ControlledValuePicker } from "./ControlledValuePicker";
import {
  getDefaultLocalizationValues,
  ParameterLocalizationPanel
} from "../../process/detail/localization/parameterLocalizationPanel";
import { ControlledRichText } from "../form/ControlledRichText";
import { useLocation } from "react-router-dom";
import { processSelector, updateProcess } from "../../../store/processReducer";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { cloneDeep, get, set } from "lodash";
import { evaluateProcessExpressionForDisplay } from "@workpoint/components/lib/helpers/expressionUtils";

export interface ParameterPropertyControlParams {
  control: Control<FieldValues>;
  setValue: UseFormSetValue<any>;
  trigger: UseFormTrigger<any>;
  name: string;
  label?: string;
  propertyType: ProcessParameterProperty;
  controlType?: FormControlType;
  required: boolean;
  defaultValue: ExpressionDescriptor | DataSource | undefined;
  options?: IDropdownOption[];
  dynamic?: boolean;
  definition: OpenAPIV2.Parameter;
  disabled?: boolean;
  enableLocalization?: boolean;
  hideContextBrowser?: boolean;
  hideConditions?: boolean;
  description?: string;
  skipPropertyTypeInName?: boolean;
  // Only for boolean control types
  onText?: string;
  offText?: string;
  fieldValues?: any;
  rows?: number;
  stepConfiguration?: ProcessStepConfiguration;
  contextExtension?: any;
}

export const ParameterPropertyControl = (props: ParameterPropertyControlParams) => {
  const {
    name,
    control,
    label,
    setValue,
    propertyType,
    controlType,
    required,
    defaultValue,
    dynamic,
    onText,
    offText,
    disabled,
    enableLocalization,
    hideConditions,
    description,
    skipPropertyTypeInName,
    contextExtension
  } = props;
  const location = useLocation();
  const rowType = getRowTypeFromPath(location.pathname);
  const [isOpenConditionsPanel, setOpenConditionsPanel] = useState(false);
  const [localizationPanelProps, setLocalizationPanelProps] = useState<
    { name: string; title: string } | undefined
  >(undefined);
  const [
    isOpenDataSourcePanel,
    { setTrue: openDataSourcePanel, setFalse: dismissDataSourcePanel }
  ] = useBoolean(false);
  const { process } = useAppSelector(processSelector);

  const watch = useWatch({ control, name });

  let watchValue: any;
  if (propertyType === ProcessParameterProperty.Title && watch?.title) {
    watchValue = watch?.title;
  } else {
    watchValue = watch;
  }

  const infoRef = useRef<any>(null);
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);

  const getMessage = () => {
    let message = <div>Error: Property type does not exist</div>;
    if (propertyType === ProcessParameterProperty.DataSource) {
      message = !isDataSourceEmpty(defaultValue as DataSource) ? (
        <div style={{ color: theme.palette.themePrimary }}>
          <Icon iconName="Error" /> Data source defined
        </div>
      ) : (
        <div>Data source not defined</div>
      );
    } else if (propertyType === ProcessParameterProperty.Valid) {
      message = !isConditionsEmpty(defaultValue as ExpressionDescriptor) ? (
        <div style={{ color: theme.palette.themePrimary }}>
          <Icon iconName="Error" /> Validation defined
        </div>
      ) : (
        <div>Validation not defined</div>
      );
    } else if (propertyType === ProcessParameterProperty.QueryConditions) {
      message = (
        defaultValue !== undefined
          ? !isConditionsEmpty(defaultValue as ExpressionDescriptor)
          : !isConditionsEmpty(
              watchValue?.[ProcessParameterProperty.QueryConditions] as ExpressionDescriptor
            )
      ) ? (
        <div style={{ color: theme.palette.themePrimary }}>
          <Icon iconName="Error" /> Query conditions defined
        </div>
      ) : (
        <div>Query conditions not defined</div>
      );
    } else if (controlType === FormControlType.QueryFilter) {
      message = !isConditionsEmpty(defaultValue as ExpressionDescriptor) ? (
        <div style={{ color: theme.palette.themePrimary }}>
          <Icon iconName="Error" /> Filter defined
        </div>
      ) : (
        <div>Filter not defined</div>
      );
    } else if (controlType === FormControlType.Conditions) {
      message = !isConditionsEmpty(defaultValue as ExpressionDescriptor) ? (
        <div style={{ color: theme.palette.themePrimary }}>
          {evaluateProcessExpressionForDisplay(defaultValue as ExpressionDescriptor)}
        </div>
      ) : (
        <div>No conditions are defined</div>
      );
    }

    return message;
  };

  const rules: any = {};
  // if (required && controlType !== FormControlType.Boolean) {
  //   rules.required = "This field is required";
  // }
  let controledComponent: JSX.Element;
  const dataPropName =
    name +
    (!skipPropertyTypeInName ? "." + propertyType : "") +
    (propertyType === ProcessParameterProperty.Name ||
    propertyType === ProcessParameterProperty.DataType ||
    propertyType === `${ProcessParameterProperty.DataSource}.multi` ||
    propertyType === ProcessParameterProperty.Format ||
    propertyType === ProcessParameterProperty.AllowSelectionOf ||
    propertyType === ProcessParameterProperty.Filter ||
    propertyType === ProcessParameterProperty.Rows
      ? ""
      : propertyType === ProcessParameterProperty.Title ||
        propertyType === ProcessParameterProperty.Description ||
        propertyType === ProcessParameterProperty.ValidMessage ||
        enableLocalization
      ? ".defaultText"
      : ".data");
  const value = defaultValue
    ? typeof defaultValue == "object" &&
      ("data" in defaultValue || "rules" in defaultValue || "expression" in defaultValue)
      ? defaultValue.data
      : typeof defaultValue == "object" && "defaultText" in defaultValue
      ? (defaultValue as any).defaultText
      : defaultValue
    : undefined;
  switch (controlType) {
    case FormControlType.Dropdown:
      controledComponent = (
        <ControlledComboBox
          name={dataPropName}
          label={dynamic ? undefined : label}
          control={control}
          required={required}
          defaultValue={value}
          rules={rules}
          allowFreeform={true}
          autoComplete={"on"}
          options={props.options ?? []}
          disabled={disabled}
          multiSelect={propertyType === ProcessParameterProperty.Format}
        />
      );
      break;
    case FormControlType.Boolean:
      controledComponent = (
        <ControlledBoolean
          name={dataPropName}
          label={dynamic ? undefined : label}
          control={control}
          defaultValue={value}
          rules={rules}
          onText={onText}
          offText={offText}
          disabled={disabled}
        />
      );
      break;
    case FormControlType.Text:
      controledComponent = (
        <ControlledTextField
          name={dataPropName}
          label={dynamic ? undefined : label}
          control={control}
          required={required}
          defaultValue={value}
          rules={rules}
          disabled={disabled}
        />
      );
      break;
    case FormControlType.Template:
      controledComponent = (
        <ControlledTextField
          name={dataPropName}
          label={dynamic ? undefined : label}
          control={control}
          required={required}
          multiline={true}
          resizable
          defaultValue={value}
          rules={rules}
          disabled={disabled}
        />
      );
      break;
    case FormControlType.Number:
      controledComponent = (
        <ControlledNumber
          name={dataPropName}
          label={dynamic ? undefined : label}
          control={control}
          required={required}
          defaultValue={value}
          rules={rules}
          disabled={disabled}
        />
      );
      break;
    case FormControlType.ValuePicker:
      controledComponent = (
        <ControlledValuePicker
          name={dataPropName}
          label={dynamic ? undefined : label}
          control={control}
          required={dynamic ? false : required}
          defaultValue={value}
          disabled={disabled}
          setValue={setValue}
          trigger={props.trigger}
          propertyType={props.propertyType}
          controlType={props.controlType}
          options={props.options}
          definition={props.definition}
          hideContextBrowser={rowType === RowType.Trigger || props.hideContextBrowser}
          contextExtension={contextExtension}
        />
      );
      break;
    case FormControlType.QueryFilter:
    case FormControlType.RichText:
    case FormControlType.PlainText:
      controledComponent = (
        <ControlledRichText
          control={control}
          label={dynamic ? undefined : label}
          disabled={!isConditionsEmpty(watchValue)}
          required={required}
          name={dataPropName}
          defaultValue={defaultValue && "data" in defaultValue ? defaultValue.data : undefined}
          rules={rules}
          isPlainText={
            controlType === FormControlType.PlainText || controlType === FormControlType.QueryFilter
          }
          setValue={setValue}
          contextPanelName={name}
          rows={props.rows ? props.rows : 5}
          onLoadValue={(value: string) => {
            if (value) {
              let loadingValue = value.replaceAll("\\'", "'");
              if (loadingValue.startsWith("'")) {
                loadingValue = loadingValue.substring(1);
              }
              if (loadingValue.endsWith("'")) {
                loadingValue = loadingValue.substring(0, loadingValue.length - 1);
              }

              return loadingValue;
            }
            return "";
          }}
          onResolveChangedValue={(value: string) => {
            if (value) {
              return `'${value.replaceAll("'", "\\'")}'`;
            }
            return "";
          }}
        />
      );
      break;
    case FormControlType.DataSource:
    case FormControlType.Conditions:
      controledComponent = (
        <>
          {!dynamic && <Label>{label}</Label>}
          {getMessage()}
        </>
      );
      break;
    default:
      controledComponent = getMessage();
  }

  controledComponent = (
    <TooltipHost content={disabled ? "Conditions have been modified!" : ""}>
      {controledComponent}
    </TooltipHost>
  );

  let iconName: string = "";
  switch (propertyType) {
    case ProcessParameterProperty.DataSource:
      iconName = "Database";
      break;
    case ProcessParameterProperty.Readonly:
      iconName = "FieldReadOnly";
      break;
    case ProcessParameterProperty.Required:
      iconName = "FieldRequired";
      break;
    case ProcessParameterProperty.Valid:
      iconName = "FieldRequired";
      break;
    case ProcessParameterProperty.Value:
      iconName = "InsertTextBox";
      break;
    case ProcessParameterProperty.Hidden:
      iconName = "RedEye";
      break;
    case ProcessParameterProperty.Title:
      iconName = "InsertTextBox";
      break;
    case ProcessParameterProperty.DataType:
      iconName = "InsertTextBox";
      break;
    case ProcessParameterProperty.Description:
      iconName = "InsertTextBox";
      break;
    case ProcessParameterProperty.Name:
      iconName = "InsertTextBox";
      break;
    default:
      iconName = "InsertTextBox";
      break;
  }

  const getStyle = (isTranslation?: boolean): CSSProperties => {
    let value;
    if (defaultValue) {
      value = defaultValue;
    } else {
      value = watchValue;
    }

    let style: CSSProperties = { alignSelf: "flex-end", marginRight: "3px" };

    if (props.dynamic) {
      style = {
        ...style,
        alignSelf: "center"
      };
    }

    if (
      (isTranslation && hasTranslatedValue(value?.id)) ||
      (!isTranslation &&
        ((propertyType === ProcessParameterProperty.DataSource &&
          !isDataSourceEmpty(value as DataSource)) ||
          !isConditionsEmpty(value as ExpressionDescriptor) ||
          (value?.[propertyType] &&
            !isConditionsEmpty(value[propertyType] as ExpressionDescriptor))))
    ) {
      style = {
        ...style,
        borderRadius: "4px",
        backgroundColor: "rgba(247, 148, 30, 0.4)"
      };
    }

    return style;
  };

  const hasTranslatedValue = (localizationId: string) => {
    if (process) {
      const translations = getDefaultLocalizationValues(process.configuration, localizationId);
      const keys = Object.keys(translations);
      for (let key of keys) {
        if (!isTranslationEmpty(translations[key])) {
          return true;
        }
      }
    }

    return false;
  };

  const openParameterLocalizationPanel = (name: string, title: string) => {
    setLocalizationPanelProps({ name, title });
  };

  return (
    <StyledStack horizontal dynamic={dynamic ?? false}>
      {dynamic ? (
        <>
          <Icon iconName={iconName} style={{ padding: "10px" }} />
          <Label style={{ width: "100px" }} required={required}>
            {label}
          </Label>
          <Stack.Item grow styles={{ root: { alignItems: "center", padding: "10px" } }}>
            {controledComponent}
          </Stack.Item>
        </>
      ) : (
        <Stack.Item grow>{controledComponent}</Stack.Item>
      )}
      <Stack.Item
        styles={{
          root: {
            alignItems: "center",
            display: "flex",
            height: 60,
            justifyContent: "center",
            overflow: "hidden"
          }
        }}
      >
        <InfoButton
          iconProps={{ iconName: "Info" }}
          title="Info"
          ariaLabel="Info"
          onClick={toggleIsCalloutVisible}
          dynamic={dynamic ?? false}
        />
        <span ref={(ref) => (infoRef.current = ref)}></span>
        {isCalloutVisible && (
          <Callout
            gapSpace={0}
            target={infoRef}
            onDismiss={toggleIsCalloutVisible}
            className={styles.callout}
            setInitialFocus
          >
            <div className={styles.header}>
              <Text className={styles.title}>{props.label}</Text>
            </div>
            <div className={styles.inner}>
              <Text className={styles.subtext}>{description ?? props.definition?.description}</Text>
              {/* <div className={styles.actions}>
              <Link className={styles.link} href="http://microsoft.com" target="_blank">
                Go to microsoft
              </Link>
            </div> */}
            </div>
          </Callout>
        )}
        {(rowType === RowType.Step || rowType === RowType.None) &&
          (propertyType === ProcessParameterProperty.DataSource ? (
            <IconButton
              iconProps={{ iconName: "LinkedDatabase" }}
              title="Data source"
              ariaLabel="Data source"
              onClick={openDataSourcePanel}
              style={getStyle()}
            />
          ) : (
            !hideConditions && (
              <IconButton
                iconProps={{ iconName: "CodeEdit" }}
                title="Advanced"
                ariaLabel="Advanced"
                onClick={() => setOpenConditionsPanel(true)}
                style={getStyle()}
              />
            )
          ))}

        {enableLocalization ? (
          <IconButton
            onClick={() => {
              let localizationName = get(watch, propertyType + ".id") ?? watch?.id;
              if (!localizationName) {
                if (props.stepConfiguration) {
                  const id =
                    props.stepConfiguration.id +
                    "-" +
                    name.replace(/_/gm, "") +
                    (skipPropertyTypeInName ? "" : "-" + propertyType);
                  setValue(name + (skipPropertyTypeInName ? "" : "." + propertyType) + ".id", id, {
                    shouldValidate: true
                  });
                  localizationName = id;
                } else if (!watch?.id) {
                  // Required for backwards-compatibility
                  const id = process.configuration.id + "-" + props.name.replace(/_/gm, "");
                  setValue(name + ".id", id, { shouldValidate: true });
                  localizationName = id;
                }
              }
              openParameterLocalizationPanel(
                localizationName ?? watch.id,
                props.label ?? props.definition["x-ms-summary"] ?? props.definition.name
              );
            }}
            iconProps={{ iconName: "Translate" }}
            title="Translation"
            ariaLabel="Translation"
            style={getStyle(true)}
          />
        ) : (
          <div style={{ alignSelf: "center", marginRight: "3px", width: "30px" }}></div>
        )}
      </Stack.Item>

      {localizationPanelProps !== undefined && (
        <ParameterLocalizationPanel
          localizationId={localizationPanelProps.name}
          parameterTitle={localizationPanelProps.title}
          onClose={() => {
            setLocalizationPanelProps(undefined);
          }}
          richText={props.controlType === FormControlType.RichText}
        />
      )}

      {isOpenConditionsPanel && (
        <ConditionsPanel
          name={name + (!skipPropertyTypeInName ? "." + propertyType : "")}
          propertyIconName={iconName}
          propertyDescription={label}
          onClose={() => {
            setOpenConditionsPanel(false);
            props.trigger(
              name +
                (!skipPropertyTypeInName ? "." + ProcessParameterProperty.Value : "") +
                ".data",
              {
                shouldFocus: true
              }
            );
          }}
          fieldValues={props.fieldValues}
          control={control}
          setValue={setValue}
          trigger={props.trigger}
          fieldDescription={
            props.definition["title"] ??
            props.definition["x-ms-summary"] ??
            props.definition.name ??
            props.name
          }
          settings={{
            hideComparison:
              propertyType === ProcessParameterProperty.Title ||
              propertyType === ProcessParameterProperty.Description ||
              (propertyType === ProcessParameterProperty.Value &&
                controlType !== FormControlType.QueryFilter &&
                controlType !== FormControlType.Conditions &&
                controlType !== FormControlType.Boolean),
            isFilter: controlType === FormControlType.QueryFilter
          }}
          definition={props.definition}
          contextExtension={contextExtension}
        />
      )}
      {isOpenDataSourcePanel && (
        <DataSourcePanel
          name={name + "." + ProcessParameterProperty.DataSource}
          onClose={dismissDataSourcePanel}
          control={control}
          dataSource={defaultValue as DataSource | undefined}
          setValue={setValue}
          trigger={props.trigger}
          fieldDescription={
            props.definition["title"] ?? props.definition["x-ms-summary"] ?? props.definition.name
          }
          definition={props.definition}
        />
      )}
    </StyledStack>
  );
};

const StyledStack = styled(Stack)<{ dynamic: boolean }>`
  display: flex;
  justify-content: center;
  align-items: ${(props) => (props.dynamic ? "center" : "flex-start")};
  background-color: ${(props) => (props.dynamic ? "rgb(0, 0, 0, 0.03)" : "white")};
  border-top: 2px solid white;
  width: 100%;
`;

const InfoButton = styled(IconButton)<{ dynamic: boolean }>`
  align-self: ${(props) => (props.dynamic ? "center" : "flex-end")};
`;

const styles = mergeStyleSets({
  callout: {
    maxWidth: 300
  },
  header: {
    padding: "18px 24px 12px"
  },
  title: [
    theme.fonts.xLarge,
    {
      margin: 0,
      fontWeight: FontWeights.semilight
    }
  ],
  inner: {
    padding: "0 24px 20px"
  },
  actions: {
    position: "relative",
    marginTop: 20,
    width: "100%",
    whiteSpace: "nowrap"
  },
  subtext: [
    theme.fonts.small,
    {
      margin: 0,
      fontWeight: FontWeights.semilight
    }
  ],
  link: [
    theme.fonts.medium,
    {
      color: theme.palette.neutralPrimary
    }
  ]
});
