import {
  CommandButton,
  mergeStyleSets,
  Panel,
  PanelType,
  Pivot,
  PivotItem,
  PivotLinkFormat,
  PivotLinkSize
} from "@fluentui/react";
import React, { FC, useRef } from "react";
import { useIntl } from "react-intl";
import { PanelHeader } from "../PanelHeader";
import { useState } from "react";
import { Control, UseFormSetValue } from "react-hook-form";
import { ConditionsAdvanced } from "./CondtionsAdvanced";
import { ObjectBrowser } from "./ObjectBrowser";
import { ConditionsSettings } from "./ConditionsPanel";
import { useAppSelector } from "../../../store/hooks";
import { globalSelector } from "../../../store/globalReducer";

enum Views {
  ContextBrowser = "Context Browser",
  Advanced = "Advanced"
}

export interface ContextPanelProps {
  onClose: (oldValue?: string) => void;
  name: string;
  control: Control;
  setValue: UseFormSetValue<any>;
  settings?: ConditionsSettings;
  onUpdate: (newValue: string) => void;
  oldValue?: string;
  initialValue?: string;
}

export const ContextPanel: FC<ContextPanelProps> = (props) => {
  const { context, loadingContext } = useAppSelector(globalSelector);
  const [view, setView] = useState<Views>(Views.Advanced);
  const intl = useIntl();
  const valueRef = useRef<string | undefined>(props.initialValue);

  const closePanel = () => {
    props.onClose(props.oldValue);
  };

  const onRenderHeader = () => {
    return (
      <PanelHeader
        title={intl.formatMessage({
          id: "context-context",
          defaultMessage: "Context"
        })}
        closePanel={closePanel}
        iconName="CodeEdit"
        additionalButtons={[
          //   <CommandButton
          //     iconProps={{ iconName: "Delete" }}
          //     text="Clear"
          //     onClick={() => {
          //       props.setValue(props.name + "." + PropertyExpressionType.Advanced, undefined, {
          //         shouldDirty: true,
          //         shouldValidate: true
          //       });
          //     }}
          //   />,
          <CommandButton
            iconProps={{ iconName: "BullseyeTargetEdit" }}
            text="Update"
            onClick={() => {
              if (valueRef.current) {
                const newValue = `<span data-wpexpression="${valueRef.current.replaceAll(
                  '"',
                  "'"
                )}" contenteditable="false"><button /></span>`;
                props.onUpdate(newValue);
              }
            }}
            // disabled={!valueRef.current}
          />
        ]}
      >
        <Pivot
          linkSize="large"
          linkFormat="tabs"
          styles={pivotStyles}
          selectedKey={view}
          onLinkClick={(item) => setView(item!.props.itemKey as any)}
        >
          <PivotItem itemKey={Views.ContextBrowser} headerText={Views.ContextBrowser}></PivotItem>
          <PivotItem itemKey={Views.Advanced} headerText={Views.Advanced}></PivotItem>
        </Pivot>
      </PanelHeader>
    );
  };

  return (
    <Panel
      isOpen={true}
      onRenderHeader={onRenderHeader}
      hasCloseButton={false}
      type={PanelType.smallFluid}
      isBlocking={false}
      onDismiss={() => {}}
      styles={panelStyles}
      focusTrapZoneProps={{
        isClickableOutsideFocusTrap: true,
        forceFocusInsideTrap: false
      }}
    >
      {view === Views.Advanced ? (
        <ConditionsAdvanced
          initialValue={props.initialValue}
          onChange={(callbackValue) => {
            valueRef.current = callbackValue;
          }}
        />
      ) : (
        <div>
          <ObjectBrowser
            object={context}
            loading={loadingContext}
            onSelect={(value: string) => {
              const newValue = `<span data-wpexpression="${value}" contenteditable="false"><button /></span>`;
              props.onUpdate(newValue);
            }}
            fullHeight={true}
          />
        </div>
      )}
    </Panel>
  );
};

const panelStyles = mergeStyleSets({
  root: {
    width: "100%",
    borderLeftWidth: "1px",
    borderLeftStyle: "solid",
    borderLeftColor: "#E5E5E5"
  },
  navigation: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0px 14px"
  },
  commands: {
    display: "none"
  },
  main: {
    overflow: "hidden"
  },
  scrollableContent: { height: "100%" },
  content: { height: "100%" }
});

const pivotStyles = mergeStyleSets({
  linkIsSelected: {
    backgroundColor: "transparent",
    padding: "0px 15px",
    fontSize: "14px",
    height: "40px",
    color: "black",
    borderBottom: "3px solid orange"
  },
  root: {
    border: "0px"
  },
  link: {
    backgroundColor: "transparent",
    padding: "0px 15px",
    selectors: {
      ":hover": {
        backgroundColor: "transparent",
        color: `-internal-light-dark(black, white)`
      }
    },
    fontSize: "14px",
    height: "40px"
  }
});
