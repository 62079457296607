import { RootState, ThunkApiConfig } from "./store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestState } from "../models/RequestState";
import { IAdminMenu, INavigationEntry } from "../models/Navigation";

export interface INavigationState {
  navigation: IAdminMenu | undefined;
  status: RequestState | undefined;
  navStack: INavigationEntry[];
}

const initialNavigationState: INavigationState = {
  navigation: undefined,
  status: undefined,
  navStack: []
};

export const fetchNavigationForSolution = createAsyncThunk<IAdminMenu, void, ThunkApiConfig>(
  "navigation/fetchForSolution",
  async (_, { rejectWithValue, extra: { apiClient } }) => {
    try {
      const nav = await apiClient.getWP("/Admin/Navigation");
      if (!nav) {
        rejectWithValue(nav);
      }

      return nav;
    } catch (error: any) {
      if (error?.errorObject?.ExceptionType === "System.UnauthorizedAccessException")
        throw Error("unauthorized");
    }
  }
);

const navigationSlice = createSlice({
  name: "navigation",
  initialState: initialNavigationState,
  reducers: {
    clearNavigation(state) {
      state = initialNavigationState;
      return state;
    },
    setNavStack: (state, action: PayloadAction<INavigationEntry[]>) => {
      state.navStack = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNavigationForSolution.pending, (state, action) => {
      state.status = RequestState.pending;
    });
    builder.addCase(fetchNavigationForSolution.fulfilled, (state, action) => {
      state.status = RequestState.fulfilled;
      state.navigation = action.payload;
    });
    builder.addCase(fetchNavigationForSolution.rejected, (state, action) => {
      if (action.error.message === "unauthorized") state.status = RequestState.unauthorized;
      else state.status = RequestState.rejected;
    });
  }
});

export const { clearNavigation, setNavStack } = navigationSlice.actions;

export const navigationSelector = (state: RootState, solutionAbsoluteUrl: string) =>
  state.navigation.navigation;
export const navigationStatusSelector = (state: RootState, solutionAbsoluteUrl: string) =>
  state.navigation.status;
export const navigationNavStackSelector = (state: RootState, solutionAbsoluteUrl: string) => {
  return state.navigation.navStack;
};
const navigationReducer = navigationSlice.reducer;

export default navigationReducer;
